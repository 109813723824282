<form class="k-form" [formGroup]="formGroup">
  <fieldset class="k-form-fieldset">
    <kendo-formfield>
      <kendo-label [for]="EnergyMeasurementUnitId"
                   text="{{ 'i18n:DATASOURCE.ENERGY_MANAGEMENT.ENERGYMEASUREMENTUNITID' | translate }}"></kendo-label>
      <ats-platform-entity-lookup-textbox #EnergyMeasurementUnitId formControlName="EnergyMeasurementUnitId"
                                          [settings]="measurementUnitSelectionPopupSettings" [formatter]="measurementUnitFormatter">
      </ats-platform-entity-lookup-textbox>
      <kendo-formerror *ngIf="formGroup.controls['EnergyMeasurementUnitId'].errors?.['required']">{{
        'i18n:MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="Co2MeasurementUnitId"
                   text="{{ 'i18n:DATASOURCE.ENERGY_MANAGEMENT.CO2MEASUREMENTUNITID' | translate }}"></kendo-label>
      <ats-platform-entity-lookup-textbox #Co2MeasurementUnitId formControlName="Co2MeasurementUnitId"
                                          [settings]="measurementUnitSelectionPopupSettings" [formatter]="measurementUnitFormatter">
      </ats-platform-entity-lookup-textbox>
      <kendo-formerror *ngIf="formGroup.controls['Co2MeasurementUnitId'].errors?.['required']">{{
        'i18n:MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="GasEmissionFactor"
                   text="{{ 'i18n:DATASOURCE.ENERGY_MANAGEMENT.GASEMISSIONFACTOR' | translate }}"></kendo-label>
      <kendo-numerictextbox #GasEmissionFactor formControlName="GasEmissionFactor" [min]="0"></kendo-numerictextbox>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="GridEmissionFactor"
                   text="{{ 'i18n:DATASOURCE.ENERGY_MANAGEMENT.GRIDEMISSIONFACTOR' | translate }}"></kendo-label>
      <kendo-numerictextbox #GridEmissionFactor formControlName="GridEmissionFactor" [min]="0"></kendo-numerictextbox>
      <kendo-formerror *ngIf="formGroup.controls['GridEmissionFactor'].errors?.['required']">{{
        'i18n:MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="DieselEmissionFactor"
                   text="{{ 'i18n:DATASOURCE.ENERGY_MANAGEMENT.DIESELEMISSIONFACTOR' | translate }}"></kendo-label>
      <kendo-numerictextbox #DieselEmissionFactor formControlName="DieselEmissionFactor" [min]="0">
      </kendo-numerictextbox>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="PriceGas" text="{{ 'DATASOURCE.ENERGY_MANAGEMENT.PRICEGAS' | translate }}"></kendo-label>
      <kendo-numerictextbox #PriceGas formControlName="PriceGas" [min]="0"></kendo-numerictextbox>
      <kendo-formerror *ngIf="formGroup.controls['PriceGas'].errors?.['minError']">{{
        translate.get(
        "i18n:MESSAGE.VALIDATION_MINIMUM",
        formGroup.controls.PriceGas.errors.minError
        )
        }}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="PriceGridConsumption"
                   text="{{ 'DATASOURCE.ENERGY_MANAGEMENT.PRICEGRIDCONSUMPTION' | translate }}"></kendo-label>
      <kendo-numerictextbox #PriceGridConsumption formControlName="PriceGridConsumption" [min]="0">
      </kendo-numerictextbox>
      <kendo-formerror *ngIf="formGroup.controls['PriceGridConsumption'].errors?.['minError']">{{
        translate.get(
        "i18n:MESSAGE.VALIDATION_MINIMUM",
        formGroup.controls.PriceGridConsumption.errors.minError
        )
        }}
      </kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="TagPriceGridConsumptionId" text="{{ 'DATASOURCE.ENERGY_MANAGEMENT.TAGPRICEGRIDCONSUMPTION' | translate }}"></kendo-label>
      <ats-platform-entity-lookup-textbox #TagPriceGridConsumptionId formControlName="TagPriceGridConsumptionId" [settings]="tagSelectionPopupSettings" [formatter]="tagFormatter"></ats-platform-entity-lookup-textbox>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="PriceGridInjection"
                   text="{{ 'DATASOURCE.ENERGY_MANAGEMENT.PRICEGRIDINJECTION' | translate }}"></kendo-label>
      <kendo-numerictextbox #PriceGridInjection formControlName="PriceGridInjection" [min]="0"></kendo-numerictextbox>
      <kendo-formerror *ngIf="formGroup.controls['PriceGridInjection'].errors?.['minError']">{{
        translate.get(
        "i18n:MESSAGE.VALIDATION_MINIMUM",
        formGroup.controls.PriceGridInjection.errors.minError
        )
        }}
      </kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="TagPriceGridInjectionId" text="{{ 'DATASOURCE.ENERGY_MANAGEMENT.TAGPRICEGRIDINJECTION' | translate }}"></kendo-label>
      <ats-platform-entity-lookup-textbox #TagPriceGridInjectionId formControlName="TagPriceGridInjectionId" [settings]="tagSelectionPopupSettings" [formatter]="tagFormatter"></ats-platform-entity-lookup-textbox>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="PriceDiesel" text="{{ 'DATASOURCE.ENERGY_MANAGEMENTT.PRICEDIESEL' | translate }}">
      </kendo-label>
      <kendo-numerictextbox #PriceDiesel formControlName="PriceDiesel" [min]="0"></kendo-numerictextbox>
      <kendo-formerror *ngIf="formGroup.controls['PriceDiesel'].errors?.['minError']">{{
        translate.get(
        "i18n:MESSAGE.VALIDATION_MINIMUM",
        formGroup.controls.PriceDiesel.errors.minError
        )
        }}
      </kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="TagPriceDieselId" text="{{ 'DATASOURCE.ENERGY_MANAGEMENT.TAGPRICEDIESEL' | translate }}"></kendo-label>
      <ats-platform-entity-lookup-textbox #TagPriceDieselId formControlName="TagPriceDieselId" [settings]="tagSelectionPopupSettings" [formatter]="tagFormatter"></ats-platform-entity-lookup-textbox>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="PriceGas" text="{{ 'DATASOURCE.ENERGY_MANAGEMENT.PRICEGAS' | translate }}"></kendo-label>
      <kendo-numerictextbox #PriceGas formControlName="PriceGas" [min]="0"></kendo-numerictextbox>
      <kendo-formerror *ngIf="formGroup.controls['PriceGas'].errors?.['minError']">
        {{
        translate.get(
        "i18n:MESSAGE.VALIDATION_MINIMUM",
        formGroup.controls.PriceGas.errors.minError
        )
        }}
      </kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="TagPriceGasId" text="{{ 'DATASOURCE.ENERGY_MANAGEMENT.TAGPRICEGAS' | translate }}"></kendo-label>
      <ats-platform-entity-lookup-textbox #TagPriceGasId formControlName="TagPriceGasId" [settings]="tagSelectionPopupSettings" [formatter]="tagFormatter"></ats-platform-entity-lookup-textbox>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="GasEnergyFactor" text="{{ 'i18n:DATASOURCE.ENERGY_MANAGEMENT.GASENERGYFACTOR' | translate }}">
      </kendo-label>
      <kendo-numerictextbox #GasEnergyFactor formControlName="GasEnergyFactor" [min]="0"></kendo-numerictextbox>
      <kendo-formerror *ngIf="formGroup.controls['PriceDiesel'].errors?.['minError']">{{
        translate.get(
        "i18n:MESSAGE.VALIDATION_MINIMUM",
        formGroup.controls.GasEnergyFactor.errors.minError
        )
        }}
      </kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="DieselEnergyFactor" text="{{ 'i18n:DATASOURCE.ENERGY_MANAGEMENT.DIESELENERGYFACTOR' | translate }}">
      </kendo-label>
      <kendo-numerictextbox #DieselEnergyFactor formControlName="DieselEnergyFactor" [min]="0"></kendo-numerictextbox>
      <kendo-formerror *ngIf="formGroup.controls['PriceDiesel'].errors?.['minError']">{{
        translate.get(
        "i18n:MESSAGE.VALIDATION_MINIMUM",
        formGroup.controls.DieselEnergyFactor.errors.minError
        )
        }}
      </kendo-formerror>
    </kendo-formfield>
  </fieldset>

  <kendo-tabstrip>
    <kendo-tabstrip-tab [selected]="true" title="{{ 'i18n:DATASOURCE.ENERGY_MANAGEMENT.SOLARPANELS' | translate }}">
      <ng-template kendoTabContent>
        <kendo-grid [data]="solarItems" [height]="410" [selectable]="{ mode: 'multiple', drag: true }"
          kendoGridSelectBy="TagId" [(selectedKeys)]="selectedSolarItemKeys">
          <ng-template kendoGridToolbarTemplate>
            <button kendoButton look="outline" (click)="openTagSelectionPopup(solarItems, 0)">{{
              'i18n:DATASOURCE.TAGS.ADD' |
              translate }}</button>
            <button kendoButton look="outline" [disabled]="selectedSolarItemKeys.length == 0"
              (click)="removeItems(solarItems, 1, selectedSolarItemKeys)">{{
              'i18n:DATASOURCE.TAGS.REMOVE' | translate }}</button>
          </ng-template>

          <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.ENERGY_MANAGEMENT.SOLARTAG')" [field]="'TagId'">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
              {{ getTagDescription(dataItem) }}
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="{{ 'i18n:DATASOURCE.ENERGY_MANAGEMENT.WINDTURBINES' | translate }}">
      <ng-template kendoTabContent>
        <kendo-grid [data]="windItems" [height]="410" [selectable]="{ mode: 'multiple', drag: true }"
          kendoGridSelectBy="TagId" [(selectedKeys)]="selectedWindItemKeys">
          <ng-template kendoGridToolbarTemplate>
            <button kendoButton look="outline" (click)="openTagSelectionPopup(windItems, 1)">{{
              'i18n:DATASOURCE.TAGS.ADD' |
              translate }}</button>
            <button kendoButton look="outline" [disabled]="selectedWindItemKeys.length == 0"
              (click)="removeItems(windItems, 2, selectedWindItemKeys)">{{
              'i18n:DATASOURCE.TAGS.REMOVE' | translate }}</button>
          </ng-template>

          <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.ENERGY_MANAGEMENT.WINDTAG')" [field]="'TagId'">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
              {{ getTagDescription(dataItem) }}
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="{{ 'i18n:DATASOURCE.ENERGY_MANAGEMENT.GRIDCONSUMPTIONMETERS' | translate }}">
      <ng-template kendoTabContent>
        <kendo-grid [data]="gridConsumptionItems" [height]="410" [selectable]="{ mode: 'multiple', drag: true }"
          kendoGridSelectBy="TagId" [(selectedKeys)]="selectedGridConsumptionItemKeys">
          <ng-template kendoGridToolbarTemplate>
            <button kendoButton look="outline" (click)="openTagSelectionPopup(gridConsumptionItems, 2)">{{
              'i18n:DATASOURCE.TAGS.ADD' |
              translate }}</button>
            <button kendoButton look="outline" [disabled]="selectedGridConsumptionItemKeys.length == 0"
              (click)="removeItems(gridConsumptionItems, 3, selectedGridConsumptionItemKeys)">{{
              'i18n:DATASOURCE.TAGS.REMOVE' | translate }}</button>
          </ng-template>

          <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.ENERGY_MANAGEMENT.GRIDCONSUMPTIONTAG')"
            [field]="'TagId'">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
              {{ getTagDescription(dataItem) }}
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="{{ 'i18n:DATASOURCE.ENERGY_MANAGEMENT.GRIDINJECTIONMETERS' | translate }}">
      <ng-template kendoTabContent>
        <kendo-grid [data]="gridInjectionItems" [height]="410" [selectable]="{ mode: 'multiple', drag: true }"
          kendoGridSelectBy="TagId" [(selectedKeys)]="selectedGridInjectionItemKeys">
          <ng-template kendoGridToolbarTemplate>
            <button kendoButton look="outline" (click)="openTagSelectionPopup(gridInjectionItems, 3)">{{
              'i18n:DATASOURCE.TAGS.ADD' |
              translate }}</button>
            <button kendoButton look="outline" [disabled]="selectedGridInjectionItemKeys.length == 0"
              (click)="removeItems(gridInjectionItems, 4, selectedGridInjectionItemKeys)">{{
              'i18n:DATASOURCE.TAGS.REMOVE' | translate }}</button>
          </ng-template>

          <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.ENERGY_MANAGEMENT.GRIDINJECTIONTAG')"
            [field]="'TagId'">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
              {{ getTagDescription(dataItem) }}
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="{{ 'i18n:DATASOURCE.ENERGY_MANAGEMENT.GASMETERS' | translate }}">
      <ng-template kendoTabContent>
        <kendo-grid [data]="gasItems" [height]="410" [selectable]="{ mode: 'multiple', drag: true }"
          kendoGridSelectBy="TagId" [(selectedKeys)]="selectedGasItemKeys">
          <ng-template kendoGridToolbarTemplate>
            <button kendoButton look="outline" (click)="openTagSelectionPopup(gasItems, 4)">{{
              'i18n:DATASOURCE.TAGS.ADD' |
              translate }}</button>
            <button kendoButton look="outline" [disabled]="selectedGasItemKeys.length == 0"
              (click)="removeItems(gasItems, 5, selectedGasItemKeys)">{{
              'i18n:DATASOURCE.TAGS.REMOVE' | translate }}</button>
          </ng-template>

          <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.ENERGY_MANAGEMENT.GASTAG')" [field]="'TagId'">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
              {{ getTagDescription(dataItem) }}
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="{{ 'i18n:DATASOURCE.ENERGY_MANAGEMENT.DIESELGENERATORS' | translate }}">
      <ng-template kendoTabContent>
        <kendo-grid [data]="dieselItems" [height]="410" [selectable]="{ mode: 'multiple', drag: true }"
          kendoGridSelectBy="TagId" [(selectedKeys)]="selectedDieselItemKeys">
          <ng-template kendoGridToolbarTemplate>
            <button kendoButton look="outline" (click)="openTagSelectionPopup(dieselItems, 5)">{{
              'i18n:DATASOURCE.TAGS.ADD' |
              translate }}</button>
            <button kendoButton look="outline" [disabled]="selectedDieselItemKeys.length == 0"
              (click)="removeItems(dieselItems, 6, selectedDieselItemKeys)">{{
              'i18n:DATASOURCE.TAGS.REMOVE' | translate }}</button>
          </ng-template>

          <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.ENERGY_MANAGEMENT.DIESELTAG')" [field]="'TagId'">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
              {{ getTagDescription(dataItem) }}
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="{{ 'i18n:DATASOURCE.ENERGY_MANAGEMENT.EVCHARGERS' | translate }}">
      <ng-template kendoTabContent>
        <kendo-grid [data]="evChargerItems" [height]="410" [selectable]="{ mode: 'multiple', drag: true }"
          kendoGridSelectBy="TagId" [(selectedKeys)]="selectedEVChargerItemKeys">
          <ng-template kendoGridToolbarTemplate>
            <button kendoButton look="outline" (click)="openTagSelectionPopup(evChargerItems, 6)">{{
              'i18n:DATASOURCE.TAGS.ADD' | translate }}</button>
            <button kendoButton look="outline" [disabled]="selectedEVChargerItemKeys.length == 0"
              (click)="removeItems(evChargerItems, 7, selectedEVChargerItemKeys)">{{
              'i18n:DATASOURCE.TAGS.REMOVE' | translate }}</button>
          </ng-template>

          <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.ENERGY_MANAGEMENT.EVCHARGERTAG')"
            [field]="'TagId'">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
              {{ getTagDescription(dataItem) }}
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="{{ 'i18n:DATASOURCE.ENERGY_MANAGEMENT.BATTERYENERGYINMETERS' | translate }}">
      <ng-template kendoTabContent>
        <kendo-grid [data]="batteryEnergyInItems" [height]="410" [selectable]="{ mode: 'multiple', drag: true }"
          kendoGridSelectBy="TagId" [(selectedKeys)]="selectedBatteryEnergyInItemKeys">
          <ng-template kendoGridToolbarTemplate>
            <button kendoButton look="outline" (click)="openTagSelectionPopup(batteryEnergyInItems, 8)">{{
              'i18n:DATASOURCE.TAGS.ADD' |
              translate }}</button>
            <button kendoButton look="outline" [disabled]="selectedBatteryEnergyInItemKeys.length == 0"
              (click)="removeItems(batteryEnergyInItems, 3, selectedBatteryEnergyInItemKeys)">{{
              'i18n:DATASOURCE.TAGS.REMOVE' | translate }}</button>
          </ng-template>

          <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.ENERGY_MANAGEMENT.BATTERYENERGYINTAG')"
            [field]="'TagId'">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
              {{ getTagDescription(dataItem) }}
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="{{ 'i18n:DATASOURCE.ENERGY_MANAGEMENT.BATTERYENERGYOUTMETERS' | translate }}">
      <ng-template kendoTabContent>
        <kendo-grid [data]="batteryEnergyOutItems" [height]="410" [selectable]="{ mode: 'multiple', drag: true }"
          kendoGridSelectBy="TagId" [(selectedKeys)]="selectedBatteryEnergyOutItemKeys">
          <ng-template kendoGridToolbarTemplate>
            <button kendoButton look="outline" (click)="openTagSelectionPopup(batteryEnergyOutItems, 9)">{{
              'i18n:DATASOURCE.TAGS.ADD' |
              translate }}</button>
            <button kendoButton look="outline" [disabled]="selectedBatteryEnergyOutItemKeys.length == 0"
              (click)="removeItems(batteryEnergyOutItems, 4, selectedBatteryEnergyOutItemKeys)">{{
              'i18n:DATASOURCE.TAGS.REMOVE' | translate }}</button>
          </ng-template>

          <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.ENERGY_MANAGEMENT.BATTERYENERGYOUTTAG')"
            [field]="'TagId'">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
              {{ getTagDescription(dataItem) }}
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</form>
