import { Component } from '@angular/core';
import { keyBy } from "lodash-es";
import { v4 as uuidv4 } from 'uuid';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService, CoreService, DashboardWidgetDocument, EntitySelectionPopupSettings, TranslateService, WidgetConfigComponent } from '@ats/ats-platform-dashboard';
import { TagChart } from '../../domain/entities/tagChart';
import { Asset } from '../../domain/entities/asset';

@Component({
  selector: 'ats-smart-tool-chart-widget-config',
  templateUrl: './chart-widget-config.component.html',
  styleUrls: ['./chart-widget-config.component.scss']
})
export class ChartWidgetConfigComponent implements WidgetConfigComponent {
  
  private documentsByKey: { [key: string]: DashboardWidgetDocument };
  
  public chartSelectionPopupSettings: EntitySelectionPopupSettings;
  public chartFormatter = (chart: TagChart) => chart?.Name;

  public assetSelectionPopupSettings: EntitySelectionPopupSettings;
  public assetFormatter = (asset: Asset) => asset?.Path;

  public formGroup: FormGroup;

  public svgEditorOptions = { theme: 'vs', language: 'xml' };
  
  constructor(public core: CoreService, public translate: TranslateService, public api: ApiService) {
    this.formGroup = new FormGroup({
      title: new FormControl(''),
      widgetType: new FormControl(translate.get('CHART_WIDGET')),
      chartId: new FormControl(null, Validators.required),
      assetId: new FormControl(null),
      svgContent: new FormControl(''),
      keepPeriodSelection: new FormControl(false)
    });

    this.chartSelectionPopupSettings = {
      title: translate.get('i18n:SELECT_CHART'),
      entitySet: 'TagCharts',
      includes: 'Folder',
      sort: [{ field: 'Folder.Path', dir: 'asc' },{ field: 'Name', dir: 'asc' }],
      multiSelect: false,
      selectionRequired: true,
      columns: [
        { field: 'Folder.Path', title: this.translate.get('i18n:FOLDER.PATH'), filterable: true },
        { field: 'Name', title: this.translate.get('i18n:CHART.NAME'), filterable: true }
      ],
      placeholder: translate.get('i18n:SELECT_CHART')
    };

    this.assetSelectionPopupSettings = {
      title: translate.get('i18n:SELECT_ASSET'),
      entitySet: 'UserAssets',
      singleEntitySet: 'Assets',
      includes: 'AssetType',
      sort: [{ field: 'Path', dir: 'asc' }],
      multiSelect: false,
      selectionRequired: false,
      columns: [
        { field: 'Path', title: translate.get('i18n:ASSET.PATH'), filterable: true },
        { field: 'AssetType.Name', title: translate.get('i18n:ASSET.TYPE'), filterable: true }
      ],
      placeholder: translate.get('i18n:SELECT_ASSET')
    };
  }

  public isValid(): boolean {
    this.formGroup.markAllAsTouched();
    return this.formGroup.valid;
  }

  public getConfig(): any {
    return {
      title: this.formGroup.get('title').value,
      chartId: this.formGroup.get('chartId').value,
      assetId: this.formGroup.get('assetId').value,
      keepPeriodSelection: this.formGroup.get('keepPeriodSelection').value
    };
  }

  public getDocuments(): DashboardWidgetDocument[] {
    let result: DashboardWidgetDocument[] = [];

    const svgContent: string = this.formGroup.get('svgContent').value;
    if (svgContent && svgContent.length)
    if (this.documentsByKey['svgContent']) {
      this.documentsByKey['svgContent'].Content = svgContent;
      result.push(this.documentsByKey['svgContent']);
    } else {
      result.push({ Id: uuidv4(), Name: 'svgContent', Content: svgContent });
    }

    return result;
  }

  public setData(config: any, documents: DashboardWidgetDocument[]) {
    this.formGroup.get('title').setValue(config?.title ?? '');
    this.formGroup.get('chartId').setValue(config?.chartId);
    this.formGroup.get('assetId').setValue(config?.assetId);
    this.formGroup.get('keepPeriodSelection').setValue(config?.keepPeriodSelection);

    this.documentsByKey = (documents && documents.length) ? keyBy(documents, 'Name') : {};

    if (this.documentsByKey['svgContent']) {
      this.formGroup.get('svgContent').setValue(this.documentsByKey['svgContent'].Content);
    } else {
      this.formGroup.get('svgContent').setValue('');
    }
  }
}
