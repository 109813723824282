import { MeasurementUnit } from "./entities/measurementUnit";

export class Scale {
  id: string;
  measurementUnit: MeasurementUnit;
  measurementUnitId: string;
  text: string;
  min: number;
  max: number;
  showZero: boolean;
  pane: number;

  constructor() {
  }
}
