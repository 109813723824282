export enum EnergyMeterType {
   Solar,
   Wind,
   GridConsumption,
   GridInjection,
   Gas,
   Diesel,
   EVCharger,
   SelfConsumption,
   BatteryEnergyIn,
   BatteryEnergyOut
}