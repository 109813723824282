import { Component } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DialogCloseResult, DialogResult, DialogService } from "@progress/kendo-angular-dialog";
import { CellClickEvent } from "@progress/kendo-angular-grid";
import { ApiService, ApiQueryOptions, CoreService, DataSource, DataSourceConfig, DataSourceComponent, TranslateService } from "@ats/ats-platform-dashboard";

import { find, findIndex, clone, remove } from "lodash-es";
import { v4 as uuidv4 } from 'uuid';
import { BaseTag } from "../../domain/entities/baseTag";
import { MeasurementUnit } from "../../domain/entities/measurementUnit";
import { MeasurementUnitSelectionPopupComponent } from "../measurement-unit-selection-popup/measurement-unit-selection-popup.component";

@Component({
    selector: 'ats-platform-datasource-tags',
    templateUrl: './datasource-tags.component.html',
    styleUrls: ['./datasource-tags.component.scss']
})
export class DataSourceTagsComponent implements DataSourceComponent {

    dataSource: DataSource;

    public formGroup: FormGroup;
    public items: DataSourceTagsItem[];
    public selectedItemKeys: string[] = [];

    constructor(private core: CoreService, public translate: TranslateService, private api: ApiService, private dialogService: DialogService) {
    }

    setDataSource(dataSource: DataSource, formGroup: FormGroup): FormGroup {
        this.dataSource = dataSource;

      if (formGroup) {
        this.formGroup = formGroup;
      } else {
        const tagsConfig = find(this.dataSource?.Configs, (x: DataSourceConfig) => x.Name === 'tags');        
        this.items = tagsConfig ? tagsConfig.Data : []; 

        this.formGroup = new FormGroup({
         
        });
      }

      return this.formGroup;
    }

    getDataSource(): DataSource {
        const dataSource = new DataSource();
        dataSource.Configs = [];

        const tagsConfig = find(this.dataSource?.Configs, (x: DataSourceConfig) => x.Name === 'tags');
        dataSource.Configs.push({ Id: tagsConfig ? tagsConfig.Id : uuidv4(), Name: 'tags', Value: JSON.stringify(this.items.map((item: DataSourceTagsItem) => {
            return { TagId: item.TagId, MeasurementUnitId: item.MeasurementUnitId}; 
        })), Encrypt: false, Encrypted: false, Data: null });
        
        return dataSource;
    }

    markAllAsTouched() {
        this.formGroup.markAllAsTouched();
    }

    isValid(): boolean {
       
        return this.formGroup.valid;
    }

    getTagDescription(item: DataSourceTagsItem): string {
        if (item.Tag) {
            return item.Tag.Asset.Path + ' - ' + item.Tag.Name;
        }
        else if (item.TagId) {
            return this.translate.get('i18n:LABEL.LOADING');
        } else {
            return '';
        }
    }

    getMeasurementUnitDescription(item: DataSourceTagsItem): string {
        if (item.MeasurementUnit) {
            if (item.MeasurementUnit.Symbol && item.MeasurementUnit.Symbol.length) {
                return item.MeasurementUnit.Name + ' (' + item.MeasurementUnit.Symbol + ')';
            } else {
                return item.MeasurementUnit.Name;
            }
        } else if (item.MeasurementUnitId) {
            return this.translate.get('i18n:LABEL.LOADING');
        } else {
            return '';
        }
    }

    openTagSelectionPopup() {
        this.core.showEntitySelectionPopup({
            title: this.translate.get('i18n:LABEL.TAG_SELECTION'),
            entitySet: 'UserBaseTags',
            singleEntitySet: 'BaseTags',
            includes: 'Asset.AssetType,MeasurementUnit.Measurement',
            sort: [{ field: 'Asset.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
            multiSelect: true,
            selectionRequired: false,
            columns: [
                { field: 'Asset.Path', title: this.translate.get('i18n:TAG.ASSET'), filterable: true },
                { field: 'Name', title: this.translate.get('i18n:TAG.NAME'), filterable: true },
                { field: 'MeasurementUnit.Name', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT'), filterable: true },
                { field: 'MeasurementUnit.Symbol', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT.SYMBOL'), filterable: true }
            ]
        }).subscribe(result => {
            if (result && result.length) {
                result.forEach((tag: BaseTag) => {
                    if (findIndex(this.items, (item: DataSourceTagsItem) => item.TagId == tag.Id) == -1) {
                        this.items.push({
                            TagId: tag.Id,
                            Tag: tag,
                            MeasurementUnitId: tag.MeasurementUnitId,
                            MeasurementUnit: tag.MeasurementUnit
                        });
                    }
                });
            }
        });
    }

    removeItems() {
        if (this.items && this.items.length && this.selectedItemKeys && this.selectedItemKeys.length) {
            const tagIds: string[] = clone(this.selectedItemKeys);
            tagIds.forEach((tagId: string) => {
                remove(this.items, (item: DataSourceTagsItem) => item.TagId == tagId);
            });
            this.selectedItemKeys = [];
        }
    }

    clearMeasurementUnit(item: DataSourceTagsItem) {
        item.MeasurementUnitId = null;
        item.MeasurementUnit = null;
    }

    openMeasurementSelectionPopup(item: DataSourceTagsItem) {
        const dialogRef = this.dialogService.open({
            content: MeasurementUnitSelectionPopupComponent,
            title: this.translate.get('i18n:LABEL.MEASUREMENT_UNIT_SELECTION'),
            minHeight: 250,
            minWidth: 450,
            height: '90vh',
            width: '90vw',
            actions: [{ text: this.translate.get('i18n:LABEL.CANCEL') }, { text: this.translate.get('i18n:LABEL.OK'), themeColor: 'primary' }],
            preventAction: (ev, dialog) => {
                if ((ev instanceof DialogCloseResult) || (ev.themeColor != 'primary'))
                    return false;

                const instance: MeasurementUnitSelectionPopupComponent = dialogRef.content.instance;
                if (instance.selectedEntities && instance.selectedEntities.length == 1) {
                    return false;
                }

                return true;
            }
        });

        const instance: MeasurementUnitSelectionPopupComponent = dialogRef.content.instance;
        instance.multiSelect = false;

        dialogRef.result.subscribe({
            next: (r: DialogResult) => {
                if (!(r instanceof DialogCloseResult) && r.themeColor == 'primary') {
                    if (instance.selectedEntities && instance.selectedEntities.length == 1) {
                        item.MeasurementUnit = instance.selectedEntities[0];
                        item.MeasurementUnitId = item.MeasurementUnit.Id;
                    }
                }
            }
        });
    }
}

export class DataSourceTagsItem {
    TagId: string;
    Tag: BaseTag;
    MeasurementUnitId: string;
    MeasurementUnit: MeasurementUnit;
}