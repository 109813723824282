import { Injectable } from "@angular/core";
import { AbstractDataSourceExporter, DataSourceInstance, CoreService } from "@ats/ats-platform-dashboard";


@Injectable()
export class BuildingEnergyDataSourceExporter extends AbstractDataSourceExporter {

  constructor(public core: CoreService) {
    super();
  }
  public getAvailableExtensions(dataSourceInstance: DataSourceInstance): { Key: string, Display: string }[] {
    if (dataSourceInstance && dataSourceInstance.result) {
      switch (dataSourceInstance.result.contentType) {
        case 'application/json':
          return [{ Key: 'json', Display: 'json' }];
      }
    }
    return null;
  }

  public export(dataSourceInstance: DataSourceInstance, fileName: string, extension: string) {
    let data: Uint8Array;

    if (dataSourceInstance && dataSourceInstance.result) {
      const result = dataSourceInstance.result;

      switch (extension) {
        case 'json': {
          const str = JSON.stringify(result.data, null, 2);
          data = new Uint8Array(str.length);
          data.forEach((value, index, array) => { array[index] = str.charCodeAt(index); });
          break;
        }
      }

      if (!data) {
        throw 'No data to export.';
      }

      this.core.generateDownload(data, fileName, extension, result.contentType);
    } else {
      throw 'No data to export.';
    }
  }
}