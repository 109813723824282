import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Type, ViewChild } from "@angular/core";
import { DashboardService, DataSourceInstance, WidgetComponent, WidgetInstance, SvgContainerComponent, WidgetUpdater, ItemValueChangedEventArgs, TranslateService } from "@ats/ats-platform-dashboard";
import { Subscription } from "rxjs";
import { find, forEach, inRange, isEmpty } from 'lodash-es';
 
import { select, pointer, scaleLinear, axisLeft } from 'd3';
import { HubConnection } from "@aspnet/signalr";
import { EVChargingConstraintData } from "../../domain/dataModels/evChargingConstraintData";
import { EVChargingConstraintDataPoint } from "../../domain/dataModels/evChargingConstraintDataPoint";

@Component({
    selector: 'ats-smart-tool-ev-charging-constraint-widget',
    templateUrl: './ev-charging-constraint-widget.component.html',
    styleUrls: ['./ev-charging-constraint-widget.component.scss']
})
export class EVChargingConstraintWidgetComponent implements WidgetComponent, OnInit, AfterViewInit, OnDestroy {

    @Input() hubConnection: HubConnection;

    public widgetInstance: WidgetInstance;
    public isLoading = false;
    public error: string;

    private widgetConfigSubscription: Subscription;
    private widgetUpdaters: WidgetUpdater[];
    private svg: SVGSVGElement;
    private div: HTMLDivElement;

    public svgContainerType: Type<SvgContainerComponent> = SvgContainerComponent;

    private constraints: { [id: string]: ConstraintInfo} = {};

    @ViewChild('svgContainer', { static: false }) svgContainer: ElementRef;

    constructor(private dashboardService: DashboardService, public translate: TranslateService) {
    }

    public setWidgetInstance(widgetInstance: WidgetInstance) {
        this.widgetInstance = widgetInstance;
    }

    ngOnInit() {
        this.widgetConfigSubscription = this.widgetInstance.changed.subscribe((instance: WidgetInstance) => {
            this.updateData(instance);
        })
    }

    ngAfterViewInit() {
        setTimeout(() => { this.updateData(this.widgetInstance); }, 0);
    }

    ngOnDestroy() {
        if (this.widgetConfigSubscription) {
            this.widgetConfigSubscription.unsubscribe();
            this.widgetConfigSubscription = null;
        }

        if (this.widgetUpdaters) {
            this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
            this.widgetUpdaters = null;
        }
    }

    protected updateData(instance: WidgetInstance) {
        instance.widgetData = {
            title: instance.widgetConfig.title
        };

        if (!this.svgContainer)
            return;

        this.div = this.svgContainer.nativeElement;
        const svgs = this.div.getElementsByTagName('svg');
        this.svg = svgs && svgs.length ? svgs.item(0) : null;
        if (!this.svg)
            return;

        if (this.widgetUpdaters) {
            this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
        }

        const dataSourceInstance = find(this.dashboardService.dataSourceInstances, (dataSourceInstance: DataSourceInstance) => dataSourceInstance.id == instance.widgetConfig.dataSourceId);

        if (dataSourceInstance) {
            if (isEmpty(instance.widgetData.title))
                instance.widgetData.title = dataSourceInstance.name;

            this.widgetUpdaters = [];
            this.widgetUpdaters.push(new WidgetUpdater(dataSourceInstance, this.svgContainer.nativeElement, this.update, this.updateItem));
        }
    }

    public cancel(e: any) {
        e.preventDefault();
        e.cancelBubble = true;
    }

    protected update = (instance: DataSourceInstance, nativeElement: HTMLElement) => {
        this.isLoading = instance?.result?.isLoading;

        if (!this.widgetInstance.widgetData.title)
            this.widgetInstance.widgetData.title = instance.name;
        
        var margin = { top: 10, right: 10, bottom: 10, left: 10, betweenConstraints: 10, betweenPhases: 10, constraintBottom: 30 };
        var width = this.svg.getBoundingClientRect().width;
        var height = this.svg.getBoundingClientRect().height;

        const d3svg = select(this.svg);
        d3svg.attr('viewBox', '0 0 ' + width + ' ' + height);
        d3svg.selectAll('g').remove();

        if (instance.result && instance.result.data) {
            var constraints: EVChargingConstraintData[] = instance.result.data;

            var constraintWidth = width - margin.left - margin.right;
            var constraintHeight = (height - margin.top - margin.bottom - (constraints.length - 1) * margin.betweenConstraints) / constraints.length;
            var chartHeight = constraintHeight - margin.constraintBottom - 20;
            var barWidth = Math.min(Math.max((constraintWidth - 55) / 3, 20), 50);
            var numOfYTicks = Math.floor((chartHeight - 40) / 20) + 1;

            forEach(constraints, (constraint, i) => {
                var g = d3svg.append('g').attr('class', 'constraint').attr('transform', 'translate(' + (margin.left) + ',' + (margin.top + i * (constraintHeight + margin.betweenConstraints)) + ')');
                
                this.constraints[constraint.Asset.Id] = new ConstraintInfo(g, constraint, chartHeight, numOfYTicks, constraintWidth, barWidth, this);
                this.constraints[constraint.Asset.Id].draw(constraint.LatestPoint);
            });
        }
    };

    protected updateItem = (dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement, event: ItemValueChangedEventArgs) => {
        var constraint = this.constraints[event.id];
        if (constraint) {
            constraint.draw(event.value as EVChargingConstraintDataPoint);
        }
    };
}

export class ConstraintInfo {

    g: d3.Selection<SVGGElement, unknown, null, undefined>;
    constraint: EVChargingConstraintData;
    chartHeight: number;
    numOfYTicks: number;
    constraintWidth: number;
    barWidth: number;
    component: EVChargingConstraintWidgetComponent;

    max: number;
    yMax: number;
    yScale: d3.ScaleLinear<number, number, never>;

    phase1: PhaseInfo;
    phase2: PhaseInfo;
    phase3: PhaseInfo;
    lineMax: d3.Selection<SVGLineElement, unknown, null, undefined>;
    gY: d3.Selection<SVGGElement, unknown, null, undefined>;

    constructor(g: d3.Selection<SVGGElement, unknown, null, undefined>, constraint: EVChargingConstraintData, chartHeight: number, numOfYTicks: number, constraintWidth: number, barWidth: number, component: EVChargingConstraintWidgetComponent) {
        this.g = g;
        this.constraint = constraint;
        this.chartHeight = chartHeight;
        this.numOfYTicks = numOfYTicks;
        this.constraintWidth = constraintWidth;
        this.barWidth = barWidth;
        this.component = component;

        this.g.append('text').attr('font-size', 14).attr('font-family', 'sans-serif').attr('font-weight', 'bold').attr('dominant-baseline', 'hanging').text(this.constraint.Asset.Path);

        var gL1 = this.g.append('g').attr('transform', 'translate(45,20)');
        var gL2 = this.g.append('g').attr('transform', 'translate(' + (50 + this.barWidth) + ',20)');
        var gL3 = this.g.append('g').attr('transform', 'translate(' + (55 + 2 * this.barWidth) + ',20)');

        this.phase1 = new PhaseInfo(gL1, this, 'L1', x => x.CurrentL1, x => x.ChargePointsCurrentL1);
        this.phase2 = new PhaseInfo(gL2, this, 'L2', x => x.CurrentL2, x => x.ChargePointsCurrentL2);
        this.phase3 = new PhaseInfo(gL3, this, 'L3', x => x.CurrentL3, x => x.ChargePointsCurrentL3);

        this.gY = this.g.append('g').attr('transform', 'translate(40,20)');
        this.lineMax = this.g.append('line').attr('x1', 40).attr('x2', (60 + 3 * this.barWidth)).attr('stroke', '#CC2F34');

        const focus = this.g.append('line').attr('x1', 40).attr('x2', 60 + 3 * barWidth).attr('y1', 0).attr('y2', 0).attr('stroke', 'black').attr('visibility', 'hidden');

        const overlay = this.g.append('rect')
            .attr('width', 20 + 3 * barWidth)
            .attr('height', chartHeight)
            .attr('fill', 'white')
            .attr('fill-opacity', 0)
            .attr('transform', 'translate(40,20)');

        overlay.on('mousemove', (e: any) => {
            let d3Pointer = pointer(e);
            let focusY = d3Pointer[1];

            if (inRange(focusY, this.yScale.range()[0], this.yScale.range()[1])) {

                focus.selectAll('.point').remove();

                focus
                    .attr('transform', 'translate(0,' + (focusY + 20) + ')')
                    .attr('visibility', 'visible');

                const current = <number>this.yScale.invert(focusY);

                var tooltipContainer = document.getElementById("tooltipContainer");
                //global style
                tooltipContainer.style.position = 'absolute';
                tooltipContainer.style.borderStyle = 'solid';
                tooltipContainer.style.borderColor = 'lightgrey';
                tooltipContainer.style.borderWidth = '1px';
                tooltipContainer.style.padding = '5px';
                tooltipContainer.style.background = 'white';
                tooltipContainer.style.opacity = '0.95';
                tooltipContainer.style.fontSize = '12px';
                
                //specific style
                tooltipContainer.style.display = 'none';

                if (this.constraint.LatestPoint) {
                    var tooltipHtml = '';
                    tooltipHtml += '<div style="text-align: center"><b>' + current.toFixed(2) + ' A</b></div>';

                    tooltipHtml += '<table>';
                    tooltipHtml += '<tr>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '</td>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div style="text-align: center"><b>L1</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div style="text-align: center"><b>L2</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div style="text-align: center"><b>L3</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '</tr>';
                    tooltipHtml += '<tr>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div>' + this.component.translate.get('i18n:EV_CHARGING_CONSTRAINT.UNCONTROLLABLE_LOAD') + '</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div style="text-align: right">' + (this.constraint.LatestPoint.MaxCurrent - this.constraint.LatestPoint.CurrentL1 + this.constraint.LatestPoint.ChargePointsCurrentL1).toFixed(2) + ' A</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div style="text-align: right">' + (this.constraint.LatestPoint.MaxCurrent - this.constraint.LatestPoint.CurrentL2 + this.constraint.LatestPoint.ChargePointsCurrentL2).toFixed(2) + ' A</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div style="text-align: right">' + (this.constraint.LatestPoint.MaxCurrent - this.constraint.LatestPoint.CurrentL3 + this.constraint.LatestPoint.ChargePointsCurrentL3).toFixed(2) + ' A</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '</tr>';
                    tooltipHtml += '<tr>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div>' + this.component.translate.get('i18n:EV_CHARGING_CONSTRAINT.EV_CHARGERS') + '</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div style="text-align: right">' + (this.constraint.LatestPoint.ChargePointsCurrentL1).toFixed(2) + ' A</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div style="text-align: right">' + (this.constraint.LatestPoint.ChargePointsCurrentL2).toFixed(2) + ' A</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div style="text-align: right">' + (this.constraint.LatestPoint.ChargePointsCurrentL3).toFixed(2) + ' A</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '</tr>';
                    tooltipHtml += '<tr>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div>' + this.component.translate.get('i18n:EV_CHARGING_CONSTRAINT.REMAINING') + '</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div style="text-align: right">' + (this.constraint.LatestPoint.CurrentL1 - this.constraint.LatestPoint.ChargePointsCurrentL1).toFixed(2) + ' A</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div style="text-align: right">' + (this.constraint.LatestPoint.CurrentL2 - this.constraint.LatestPoint.ChargePointsCurrentL2).toFixed(2) + ' A</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div style="text-align: right">' + (this.constraint.LatestPoint.CurrentL3 - this.constraint.LatestPoint.ChargePointsCurrentL3).toFixed(2) + ' A</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '</tr>';
                    tooltipHtml += '</table>';
                    tooltipContainer.innerHTML = tooltipHtml;

                    tooltipContainer.style.display = null;
                    this.handleTooltipPosition(tooltipContainer,e);
                } else {
                    focus
                        .attr('visibility', 'hidden');
                }
            }
        });

        overlay.on('mouseout', (e: any) => {
            focus
                .attr('visibility', 'hidden');

            var tooltipContainer = document.getElementById("tooltipContainer");
            tooltipContainer.style.display = 'none';
        });
    }

    handleTooltipPosition(tooltipContainer, e: any) {
        var tooltipLeft = e.pageX + 10;
        const tooltipRightX = tooltipLeft + tooltipContainer.clientWidth;
        if (tooltipRightX > window.innerWidth) {
            tooltipContainer.style.left = (e.pageX - 10 - tooltipContainer.clientWidth) + 'px';
        } else {
            tooltipContainer.style.left = tooltipLeft  + 'px';
        }
    
        var tooltipTop = e.pageY + 10;
        const tooltipBottomY = tooltipTop + tooltipContainer.clientHeight;
        const tooltipTopYIfAbove = tooltipTop - tooltipContainer.clientHeight;
        const newTooltipTopIfToBig = 75;
        if (tooltipBottomY > window.innerHeight && tooltipTopYIfAbove > 0){
            tooltipContainer.style.top = (e.pageY - 10 - tooltipContainer.clientHeight) + 'px';
        } else if (tooltipBottomY > window.innerHeight && tooltipTopYIfAbove < 0){
            tooltipContainer.style.top = newTooltipTopIfToBig + 'px';
        } else {
            tooltipContainer.style.top = tooltipTop + 'px';
        }
    }

    draw(latestPoint: EVChargingConstraintDataPoint) {
        if (latestPoint && this.max !== latestPoint.MaxCurrent) {
            this.max = latestPoint.MaxCurrent;
            this.yScale = scaleLinear().domain([0, this.max * 1.05]).range([this.chartHeight, 0]);
            var yAxis = axisLeft(this.yScale).ticks(this.numOfYTicks);
            this.gY.call(yAxis);
            this.yMax = this.yScale(latestPoint.MaxCurrent);
            this.lineMax.attr('y1', this.yMax + 20).attr('y2', this.yMax + 20);
        }

        this.phase1.draw(latestPoint);
        this.phase2.draw(latestPoint);
        this.phase3.draw(latestPoint);
    }
}

export class PhaseInfo {
    constraint: ConstraintInfo;
    g: d3.Selection<SVGGElement, unknown, null, undefined>;
    getCurrent: (x: EVChargingConstraintDataPoint) => number;
    getChargePointsCurrent: (x: EVChargingConstraintDataPoint) => number;

    rectUCL: d3.Selection<SVGRectElement, unknown, null, undefined>;
    rectEV: d3.Selection<SVGRectElement, unknown, null, undefined>;
    rectMax: d3.Selection<SVGRectElement, unknown, null, undefined>;

    constructor (g: d3.Selection<SVGGElement, unknown, null, undefined>, constraint: ConstraintInfo, phase: string, getCurrent: (x: EVChargingConstraintDataPoint) => number, getChargePointsCurrent: (x: EVChargingConstraintDataPoint) => number) {
        this.g = g;
        this.constraint = constraint;
        this.getCurrent = getCurrent;
        this.getChargePointsCurrent = getChargePointsCurrent;

        this.g.append('text').attr('font-size', 10).attr('font-family', 'sans-serif').attr('font-weight', 'bold').attr('dominant-baseline', 'hanging')
            .attr('text-anchor', 'middle').attr('transform', 'translate('+ (this.constraint.barWidth / 2) + ',' + (this.constraint.chartHeight + 5) + ')').text(phase);

        this.rectUCL = this.g.append('rect').attr('x', 0).attr('width', this.constraint.barWidth).attr('fill', '#B6D6D5');
        this.rectEV = this.g.append('rect').attr('x', 0).attr('width', this.constraint.barWidth).attr('fill', '#504D91');
        this.rectMax = this.g.append('rect').attr('x', 0).attr('width', this.constraint.barWidth).attr('fill', '#F0F0F0');
    }

    draw(latestPoint: EVChargingConstraintDataPoint) {
        var yUCL = this.constraint.yScale(this.constraint.constraint.LatestPoint.MaxCurrent - this.getCurrent(latestPoint));
        var yEV = this.constraint.yScale(latestPoint.MaxCurrent - this.getCurrent(latestPoint) + this.getChargePointsCurrent(latestPoint));

        this.rectUCL.attr('y', yUCL).attr('height', this.constraint.yScale(0) - yUCL);
        this.rectEV.attr('y', yEV).attr('height', yUCL - yEV);
        this.rectMax.attr('y', this.constraint.yMax).attr('height', yEV - this.constraint.yMax);
    }
}