<form class="k-form" [formGroup]="formGroup">
    <div class="flex-container">
        <div>
            <fieldset class="k-form-fieldset">
                <legend class="k-form-legend">{{ 'LABEL.GENERAL' | translate}}</legend>
                <kendo-formfield>
                    <kendo-label [for]="title" text="{{ 'WIDGET.TITLE' | translate }}"></kendo-label>
                    <kendo-textbox #title formControlName="title"></kendo-textbox>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label [for]="widgetType" text="{{ 'WIDGET.TYPE' | translate }}"></kendo-label>
                    <kendo-textbox #widgetType formControlName="widgetType" [readonly]="true"></kendo-textbox>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label [for]="dataSourceId" text="{{ 'WIDGET.DATASOURCE' | translate }}"></kendo-label>
                    <ats-platform-entity-lookup-textbox #dataSourceId formControlName="dataSourceId" [settings]="dataSourceSelectionPopupSettings" [formatter]="dataSourceFormatter"></ats-platform-entity-lookup-textbox>
                    <kendo-formerror *ngIf="formGroup.controls['dataSourceId'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label [for]="chartId" text="{{ 'CHARTWIDGET.CHART' | translate }}"></kendo-label>
                    <ats-platform-entity-lookup-textbox #chartId formControlName="chartId" [settings]="chartSelectionPopupSettings" [formatter]="chartFormatter"></ats-platform-entity-lookup-textbox>
                </kendo-formfield>
            </fieldset>
        </div>
        <div style="flex: 1">
            <div style="height: calc(100% - 8px); padding-top: 8px;">
                <kendo-label text="{{ 'WIDGET.CUSTOM_SVG_CONTENT' | translate }}"></kendo-label>
                <ngx-monaco-editor [options]="svgEditorOptions" formControlName="svgContent">
                </ngx-monaco-editor>
            </div>
        </div>
    </div>
</form>