import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CellClickEvent, CellCloseEvent } from "@progress/kendo-angular-grid";
import { ApiService, ApiQueryOptions, DataSource, DataSourceConfig, DataSourceComponent, TranslateService, EntitySelectionPopupSettings, CoreService } from "@ats/ats-platform-dashboard";

import { find, findIndex, remove, clone } from "lodash-es";
import { v4 as uuidv4 } from 'uuid';
import { BaseTag } from "../../domain/entities/baseTag";
import { MeasurementUnit } from "../../domain/entities/measurementUnit";

@Component({
    selector: 'ats-smarttool-datasource-production-consumption',
    templateUrl: './datasource-production-consumption.component.html',
    styleUrls: ['./datasource-production-consumption.component.scss']
})
export class DataSourceProductionConsumptionComponent implements DataSourceComponent {

    dataSource: DataSource;

    public formGroup: FormGroup;
    public items: DataSourceProductionConsumptionItemTag[];
    public selectedItemKeys: string[] = [];

    //public cellFormGroup: FormGroup;

    public measurementUnitSelectionPopupSettings: EntitySelectionPopupSettings;
    public measurementUnitFormatter = (task: MeasurementUnit) => task?.Name;

    public gridErr: string=""

    constructor(public translate: TranslateService, private api: ApiService, private core: CoreService) {
        this.measurementUnitSelectionPopupSettings = {
            title: translate.get('i18n:SELECT_MEASUREMENTUNIT'),
            entitySet: 'MeasurementUnits',
            includes: 'Measurement',
            sort: [{ field: 'Measurement.Name', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
            multiSelect: false,
            selectionRequired: true,
            columns: [
                { field: 'Measurement.Name', title: translate.get('i18n:MEASUREMENT_UNIT.MEASUREMENT'), filterable: true },
                { field: 'Name', title: translate.get('i18n:MEASUREMENT_UNIT.NAME'), filterable: true },
                { field: 'Symbol', title: translate.get('i18n:MEASUREMENT_UNIT.SYMBOL'), filterable: true }
            ]
        }
    }

    setDataSource(dataSource: DataSource, formGroup: FormGroup): FormGroup {
        this.dataSource = dataSource;

      if (formGroup) {
        this.formGroup = formGroup;
      } else {
        const configs = this.dataSource?.Configs;
        const measurementUnitIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'MeasurementUnitId');
        const tagsConfig = find(configs, (x: DataSourceConfig) => x.Name === 'Tags');
        this.items = tagsConfig ? tagsConfig.Data : []; 

        this.formGroup = new FormGroup({         
            MeasurementUnitId: new FormControl(measurementUnitIdConfig ? measurementUnitIdConfig.Value : null, Validators.required),
        });
      }

      return this.formGroup;
    }

    getDataSource(): DataSource {
        const dataSource = new DataSource();
       
        dataSource.Configs = [];

        const configs = this.dataSource?.Configs;
        const measurementUnitIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'MeasurementUnitId');
        const tagsConfig = find(configs, (x: DataSourceConfig) => x.Name === 'Tags');

        dataSource.Configs.push({ Id: measurementUnitIdConfig ? measurementUnitIdConfig.Id : uuidv4(), Name: 'MeasurementUnitId', Value: this.formGroup.get('MeasurementUnitId').value, Encrypt: false, Encrypted: false, Data: null });
        dataSource.Configs.push({
            Id: tagsConfig ? tagsConfig.Id : uuidv4(), Name: 'Tags', Value: JSON.stringify(this.items.filter(
                (function (item) {
                    if (item.TagId === null || item.TagId === undefined) { return false; } //Skip empty elements
                    else { return true; }
                })).map((item: DataSourceProductionConsumptionItemTag) => {
                    item.Tag = null;
                    return { TagId: item.TagId, Category: item.Category, Color: item.Color };
                })), Encrypt: false, Encrypted: false, Data: null
        });

        return dataSource;
    }

    markAllAsTouched() {
        this.formGroup.get('MeasurementUnitId').markAllAsTouched();
    }

    isValid(): boolean {     
        return this.formGroup.valid && this.gridErr.length == 0;
    }

    hasGridErr(): boolean {
        this.gridErr = this.getFirstGridErrorLoc();
        return this.gridErr.length > 0;
    }
    getFirstGridErrorLoc(): string {
        var isRequired = ' ' + this.translate.get('i18n:MESSAGE.FIELD_IS_REQUIRED');

        for (let i = 0; i < this.items?.length; ++i) {
            if (this.items[i].Category.trim().length == 0) return this.translate.get('i18n:DATASOURCE.PRODUCTION_CONSUMPTION.CATEGORY') + isRequired;
        }

        return '';
    }

    getTagDescription(item: DataSourceProductionConsumptionItemTag): string {
        if (item.Tag) {
            return item.Tag.Asset.Path + ' - ' + item.Tag.Name;
        }
        else if (item.TagId) {
            return this.translate.get('i18n:LABEL.LOADING');
        } else {
            return '';
        }
    }

    getMeasurementUnitDescription(item: DataSourceProductionConsumptionItem): string {
        if (item.MeasurementUnit) {
            if (item.MeasurementUnit.Symbol && item.MeasurementUnit.Symbol.length) {
                return item.MeasurementUnit.Name + ' (' + item.MeasurementUnit.Symbol + ')';
            } else {
                return item.MeasurementUnit.Name;
            }
        } else if (item.MeasurementUnitId) {
            return this.translate.get('i18n:LABEL.LOADING');
        } else {
            return '';
        }
    }

    openTagSelectionPopup() {

        this.core.showEntitySelectionPopup({
            title: this.translate.get('i18n:LABEL.TAG_SELECTION'),
            entitySet: 'UserBaseTags',
            singleEntitySet: 'BaseTags',
            includes: 'Asset.AssetType,MeasurementUnit.Measurement',
            sort: [{ field: 'Asset.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
            multiSelect: true,
            selectionRequired: false,
            columns: [
                { field: 'Asset.Path', title: this.translate.get('i18n:TAG.ASSET'), filterable: true },
                { field: 'Name', title: this.translate.get('i18n:TAG.NAME'), filterable: true },
                { field: 'MeasurementUnit.Name', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT'), filterable: true },
                { field: 'MeasurementUnit.Symbol', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT.SYMBOL'), filterable: true }
            ]
        }).subscribe({
            next: result => {
                if (result && result.length) {
                    result.forEach((tag: BaseTag) => {
                        if (findIndex(this.items, (item: DataSourceProductionConsumptionItemTag) => item.TagId == tag.Id) == -1) {
                            this.items.push({
                                TagId: tag.Id,
                                Tag: tag,
                                Category: '',
                                Color: '#000000'
                            });
                        }
                    });
                }
            }
        });
    }

    removeItems() {
        if (this.items && this.items.length && this.selectedItemKeys && this.selectedItemKeys.length) {
            const tagIds: string[] = clone(this.selectedItemKeys);
            tagIds.forEach((tagId: string) => {
                remove(this.items, (item: DataSourceProductionConsumptionItemTag) => item.TagId == tagId);
            });
            this.selectedItemKeys = [];
        }
    }

    public cellClick(event: CellClickEvent) {
        if (!event.isEdited) {
            event.sender.editCell(event.rowIndex, event.columnIndex, this.createFormGroup(event.dataItem));
        }
    }

    public cellClose(event: CellCloseEvent) {
        const formControl: FormControl = event.formGroup.get(event.column.field);
        if (formControl) {
            if (!formControl.valid) {
                event.preventDefault();
            } else if (formControl.dirty) {
                event.dataItem[event.column.field] = formControl.value;
            }
        }
    }

    public createFormGroup(dataItem: DataSourceProductionConsumptionItemTag) {
        return new FormGroup({
            Category: new FormControl(dataItem.Category, Validators.required),
            Color: new FormControl(dataItem.Color, Validators.required)
        });
    }
}

export class DataSourceProductionConsumptionItem {
    MeasurementUnitId: string;
    MeasurementUnit: MeasurementUnit;
    Tags: DataSourceProductionConsumptionItemTag[];
}

export class DataSourceProductionConsumptionItemTag {
    TagId: string;
    Tag: BaseTag;
    Category: string;
    Color: string;
}