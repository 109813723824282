import { Report } from "./report";
import { ReportParameter } from "./reportParameter";

export class ReportParameterValue {
    Id: string;
    ReportId: string;
    ReportParameterId: string;
    Value: string;
    Report: Report;
    ReportParameter: ReportParameter;
}