<form class="k-form" [formGroup]="formGroup">
  <fieldset class="k-form-fieldset">
    <legend class="k-form-legend">{{ "LABEL.GENERAL" | translate }}</legend>
    <kendo-formfield>
      <kendo-label
        [for]="title"
        text="{{ 'WIDGET.TITLE' | translate }}"
      ></kendo-label>
      <input #title formControlName="title" kendoTextBox />
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="widgetType" text="{{ 'WIDGET.TYPE' | translate }}"></kendo-label>
      <kendo-textbox #widgetType formControlName="widgetType" [readonly]="true" ></kendo-textbox>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label
        [for]="dataSourceId"
        text="{{ 'WIDGET.DATASOURCE' | translate }}"
      ></kendo-label>
      <ats-platform-entity-lookup-textbox
        #dataSourceId
        formControlName="dataSourceId"
        [settings]="dataSourceSelectionPopupSettings"
        [formatter]="dataSourceFormatter"
        (entityChange)="onDataSourceChange($event)"
      ></ats-platform-entity-lookup-textbox>
      <kendo-formerror
        *ngIf="formGroup.controls['dataSourceId'].errors?.['required']"
        >{{ "MESSAGE.FIELD_IS_REQUIRED" | translate }}</kendo-formerror
      >
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label
        [for]="buildingIndex"
        text="{{ 'WIDGET.BUILDINGINDEX' | translate }}"
      ></kendo-label>
      <kendo-combobox
        #buildingIndex
        formControlName="buildingIndex"
        [data]="dataSourceItems"
        textField="name"
        valueField="id"
        [valuePrimitive]="true"
        [loading]="dataSourceItemsLoading"
      ></kendo-combobox>
      <kendo-formerror
        *ngIf="formGroup.controls['buildingIndex'].errors?.['required']"
        >{{ "MESSAGE.FIELD_IS_REQUIRED" | translate }}</kendo-formerror
      >
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label
        [for]="valueType"
        text="{{ 'WIDGET.VALUETYPE' | translate }}"
      ></kendo-label>
      <kendo-dropdownlist
        #valueType
        formControlName="valueType"
        [textField]="'Display'"
        [valueField]="'Key'"
        [valuePrimitive]="true"
        [data]="valueTypes"
      ></kendo-dropdownlist>
      <kendo-formerror *ngIf="formGroup.controls['valueType'].errors?.['required']">{{
        "MESSAGE.FIELD_IS_REQUIRED" | translate
      }}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label
        [for]="dayFilterKey"
        text="{{ 'WIDGET.DAYFILTER' | translate }}"
      ></kendo-label>
      <kendo-dropdownlist
        #dayFilterKey
        formControlName="dayFilterKey"
        [textField]="'Display'"
        [valueField]="'Key'"
        [valuePrimitive]="true"
        [data]="dayFilterKeys"
      ></kendo-dropdownlist>
      <kendo-formerror
        *ngIf="formGroup.controls['dayFilterKey'].errors?.['required']"
        >{{ "MESSAGE.FIELD_IS_REQUIRED" | translate }}</kendo-formerror
      >
    </kendo-formfield>
  </fieldset>
  <fieldset class="k-form-fieldset">
    <legend class="k-form-legend">{{ "LABEL.SETTINGS" | translate }}</legend>
    <kendo-formfield>
      <kendo-label
        [for]="leftMargin"
        text="{{ 'WIDGET.LEFTMARGIN' | translate }}"
      ></kendo-label>
      <kendo-numerictextbox
        #leftMargin
        formControlName="leftMargin"
        [min]="1"
        [decimals]="0"
        [format]="'n0'"
      ></kendo-numerictextbox>
      <kendo-formerror *ngIf="formGroup.controls['leftMargin'].errors?.['required']">{{
        "MESSAGE.FIELD_IS_REQUIRED" | translate
      }}</kendo-formerror>
      <kendo-formerror *ngIf="formGroup.controls['leftMargin'].errors?.['minError']">{{
        translate.get(
          "i18n:MESSAGE.VALIDATION_MINIMUM",
          formGroup.controls.leftMargin.errors.minError
        )
      }}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label
        [for]="rightMargin"
        text="{{ 'WIDGET.RIGHTMARGIN' | translate }}"
      ></kendo-label>
      <kendo-numerictextbox
        #rightMargin
        formControlName="rightMargin"
        [min]="1"
        [decimals]="0"
        [format]="'n0'"
      ></kendo-numerictextbox>
      <kendo-formerror
        *ngIf="formGroup.controls['rightMargin'].errors?.['required']"
        >{{ "MESSAGE.FIELD_IS_REQUIRED" | translate }}</kendo-formerror
      >
      <kendo-formerror
        *ngIf="formGroup.controls['rightMargin'].errors?.['minError']"
        >{{
          translate.get(
            "i18n:MESSAGE.VALIDATION_MINIMUM",
            formGroup.controls.rightMargin.errors.minError
          )
        }}</kendo-formerror
      >
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label
        [for]="topMargin"
        text="{{ 'WIDGET.topMargin' | translate }}"
      ></kendo-label>
      <kendo-numerictextbox
        #topMargin
        formControlName="topMargin"
        [min]="1"
        [decimals]="0"
        [format]="'n0'"
      ></kendo-numerictextbox>
      <kendo-formerror *ngIf="formGroup.controls['topMargin'].errors?.['required']">{{
        "MESSAGE.FIELD_IS_REQUIRED" | translate
      }}</kendo-formerror>
      <kendo-formerror *ngIf="formGroup.controls['topMargin'].errors?.['minError']">{{
        translate.get(
          "i18n:MESSAGE.VALIDATION_MINIMUM",
          formGroup.controls.topMargin.errors.minError
        )
      }}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label
        [for]="bottomMargin"
        text="{{ 'WIDGET.bottomMargin' | translate }}"
      ></kendo-label>
      <kendo-numerictextbox
        #bottomMargin
        formControlName="bottomMargin"
        [min]="1"
        [decimals]="0"
        [format]="'n0'"
      ></kendo-numerictextbox>
      <kendo-formerror
        *ngIf="formGroup.controls['bottomMargin'].errors?.['required']"
        >{{ "MESSAGE.FIELD_IS_REQUIRED" | translate }}</kendo-formerror
      >
      <kendo-formerror
        *ngIf="formGroup.controls['bottomMargin'].errors?.['minError']"
        >{{
          translate.get(
            "i18n:MESSAGE.VALIDATION_MINIMUM",
            formGroup.controls.bottomMargin.errors.minError
          )
        }}</kendo-formerror
      >
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label
        [for]="numberOfDecimalsXValue"
        text="{{ 'WIDGET.NUMBEROFDECIMALSXVALUE' | translate }}"
      ></kendo-label>
      <kendo-numerictextbox
        #numberOfDecimalsXValue
        formControlName="numberOfDecimalsXValue"
        [min]="0"
        [decimals]="0"
        [format]="'n0'"
      ></kendo-numerictextbox>
      <kendo-formerror
        *ngIf="formGroup.controls['numberOfDecimalsXValue'].errors?.['minError']"
        >{{
          translate.get(
            "i18n:MESSAGE.VALIDATION_MINIMUM",
            formGroup.controls.numberOfDecimalsXValue.errors.minError
          )
        }}</kendo-formerror
      >
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label
        [for]="numberOfDecimalsYValue"
        text="{{ 'WIDGET.NUMBEROFDECIMALSYVALUE' | translate }}"
      ></kendo-label>
      <kendo-numerictextbox
        #numberOfDecimalsYValue
        formControlName="numberOfDecimalsYValue"
        [min]="0"
        [decimals]="0"
        [format]="'n0'"
      ></kendo-numerictextbox>
      <kendo-formerror
        *ngIf="formGroup.controls['numberOfDecimalsYValue'].errors?.['minError']"
        >{{
          translate.get(
            "i18n:MESSAGE.VALIDATION_MINIMUM",
            formGroup.controls.numberOfDecimalsYValue.errors.minError
          )
        }}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="circleColor" text="{{ 'WIDGET.CIRCLECOLOR' | translate }}"></kendo-label>
      <kendo-colorpicker #circleColor formControlName="circleColor" activeView="palette" [format]="'hex'"></kendo-colorpicker>
      <kendo-formerror *ngIf="formGroup.controls['circleColor'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="regressionLineColor" text="{{ 'WIDGET.REGRESSIONLINECOLOR' | translate }}"></kendo-label>
      <kendo-colorpicker #regressionLineColor formControlName="regressionLineColor" activeView="palette" [format]="'hex'"></kendo-colorpicker>
      <kendo-formerror *ngIf="formGroup.controls['regressionLineColor'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label
        [for]="hideLabels"
        text="{{ 'WIDGET.HIDELABELS' | translate }}"
      ></kendo-label>
      <kendo-switch #hideLabels formControlName="hideLabels"></kendo-switch>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label
        [for]="hideRegressionLine"
        text="{{ 'WIDGET.HIDEREGRESSIONLINE' | translate }}"
      ></kendo-label>
      <kendo-switch #hideRegressionLine formControlName="hideRegressionLine"></kendo-switch>
    </kendo-formfield>
  </fieldset>
</form>
