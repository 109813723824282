import { Injectable } from "@angular/core";
import { forkJoin, Observable, map } from "rxjs";
import { find } from "lodash-es";
import { ApiQueryOptions, DataSource, DataSourceConfig,GenericDataSourceLoader } from "@ats/ats-platform-dashboard";
import { Asset } from "../domain/entities/asset";

@Injectable()
export class AssetsDataSourceLoader extends GenericDataSourceLoader {

    public override loadItems(dataSource: DataSource): Observable<{ id: string; name: string; }[]> {
        const assetConfig: DataSourceConfig = find(dataSource.Configs, (config: DataSourceConfig) => config.Name == 'Buildings');
        const assets = assetConfig && assetConfig.Value && assetConfig.Value.length ? JSON.parse(assetConfig.Value) : [];

        const observables: Observable<Asset>[] = assets.map((asset: any) => {
            return this.api.getSingleResult('assets', asset.AssetId, new ApiQueryOptions({}))
        });

        return forkJoin(observables).pipe(map((assets: Asset[]) => {
            return assets.map((asset: Asset) => {
                return {
                    id: asset.Id,
                    name: asset.Path
                };
            });
        }));
    }

}    