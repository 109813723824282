import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DialogCloseResult, DialogResult, DialogService } from "@progress/kendo-angular-dialog";
import { CellClickEvent, CellCloseEvent } from "@progress/kendo-angular-grid";
import { ApiService, ApiQueryOptions, CoreService, DataSource, DataSourceConfig, DataSourceComponent, TranslateService } from "@ats/ats-platform-dashboard";

import { find, findIndex, clone, remove } from "lodash-es";
import { v4 as uuidv4 } from 'uuid';
import { BaseTag } from "../../domain/entities/baseTag";
import { MeasurementUnit } from "../../domain/entities/measurementUnit";
import { FILLOPTIONTYPES } from "../../domain/enums/fillOptionTypes";
import { FILLTIMEINTERVALTYPES } from "../../domain/enums/fillTimeIntervalTypes";
import { MeasurementUnitSelectionPopupComponent } from "../measurement-unit-selection-popup/measurement-unit-selection-popup.component";

@Component({
    selector: 'ats-platform-datasource-tags-history',
    templateUrl: './datasource-tags-history.component.html',
    styleUrls: ['./datasource-tags-history.component.scss']
})
export class DataSourceTagsHistoryComponent implements DataSourceComponent {

    dataSource: DataSource;

    public formGroup: FormGroup;
    public items: DataSourceTagsHistoryItem[];
    public selectedItemKeys: string[] = [];

    public timeIntervals: { Key: string, Name: string, Display: string }[];
    public fillOptions: { Key: string, Name: string, Display: string }[];

    public gridErr: string="";

    constructor(private core: CoreService, public translate: TranslateService, private api: ApiService, private dialogService: DialogService) {
        this.timeIntervals = core.translateObjectArray(FILLTIMEINTERVALTYPES, 'Display');
        this.fillOptions = core.translateObjectArray(FILLOPTIONTYPES, 'Display');
    }

    setDataSource(dataSource: DataSource, formGroup: FormGroup): FormGroup {
        this.dataSource = dataSource;

        if (formGroup) {
            this.formGroup = formGroup;
        } else {
            const configs = this.dataSource?.Configs;
            const aggregationWindow = find(configs, (x: DataSourceConfig) => x.Name === 'aggregationWindow');
            const maxPoints = find(configs, (x: DataSourceConfig) => x.Name === 'maxPoints');

        const tagsConfig = find(configs, (x: DataSourceConfig) => x.Name === 'tags');
        this.items = tagsConfig ? tagsConfig.Data : []; 
       
        this.formGroup = new FormGroup({
            aggregationWindow: new FormControl(aggregationWindow ? Number.parseInt(aggregationWindow.Value) : 0, Validators.required),
            maxPoints: new FormControl(maxPoints ? Number.parseInt(maxPoints.Value) : 2000, Validators.compose([Validators.required, Validators.min(1), Validators.max(1000000)]))
        });
      }

        return this.formGroup;
    }

    getDataSource(): DataSource {
        const dataSource = new DataSource();
        dataSource.Configs = [];

        const configs = this.dataSource?.Configs;
        const aggregationWindow = find(configs, (x: DataSourceConfig) => x.Name === 'aggregationWindow');
        const maxPoints = find(configs, (x: DataSourceConfig) => x.Name === 'maxPoints');
        const tagsConfig = find(configs, (x: DataSourceConfig) => x.Name === 'tags');

        
        dataSource.Configs.push({ Id: aggregationWindow ? aggregationWindow.Id : uuidv4(), Name: 'aggregationWindow', Value: this.formGroup.get('aggregationWindow').value.toString(), Encrypt: false, Encrypted: false, Data: null });
        dataSource.Configs.push({ Id: maxPoints ? maxPoints.Id : uuidv4(), Name: 'maxPoints', Value: this.formGroup.get('maxPoints').value.toString(), Encrypt: false, Encrypted: false, Data: null });
        dataSource.Configs.push({ Id: tagsConfig ? tagsConfig.Id : uuidv4(), Name: 'tags', Value: JSON.stringify(this.items.map((item: DataSourceTagsHistoryItem) => {
            return { tagId: item.TagId, measurementUnitId: item.MeasurementUnitId, aggregate: item.Aggregate, aggregateFillOption: item.AggregateFillOption, defaultColor: item.DefaultColor}; 
        })), Encrypt: false, Encrypted: false, Data: null });
        
        return dataSource;
    }

    markAllAsTouched() {
        this.formGroup.get('aggregationWindow').markAsTouched();
        this.formGroup.get('maxPoints').markAsTouched();
    }

    isValid(): boolean {
        return this.formGroup.valid && this.gridErr.length == 0;
    }

    hasGridErr(): boolean {
        this.gridErr = this.getFirstGridErrorLoc();
        return this.gridErr.length > 0;
    }

    getFirstGridErrorLoc(): string {
        var isRequired = ' ' + this.translate.get('i18n:MESSAGE.FIELD_IS_REQUIRED');

        for (let i = 0; i < this.items?.length; ++i) {

            if (this.items[i].TagId == null) return this.translate.get('i18n:DATASOURCE.TAGS_HISTORY.TAG') + isRequired;
            if (this.items[i].AggregateFillOption.trim().length == 0) return this.translate.get('i18n:DATASOURCE.TAGS_HISTORY.AGGREGATE_FILL_OPTION') + isRequired;
            if (this.items[i].DefaultColor.trim().length == 0) return this.translate.get('i18n:DATASOURCE.TAGS_HISTORY.DEFAULT_COLOR') + isRequired;
        }
        return '';
    }
    
    getTagDescription(item: DataSourceTagsHistoryItem): string {
        if (item.Tag) {
            return item.Tag.Asset.Path + ' - ' + item.Tag.Name;
        }
        else if (item.TagId) {
            return this.translate.get('i18n:LABEL.LOADING');
        } else {
            return '';
        }
    }

    getMeasurementUnitDescription(item: DataSourceTagsHistoryItem): string {
        if (item.MeasurementUnit) {
            if (item.MeasurementUnit.Symbol && item.MeasurementUnit.Symbol.length) {
                return item.MeasurementUnit.Name + ' (' + item.MeasurementUnit.Symbol + ')';
            } else {
                return item.MeasurementUnit.Name;
            }
        } else if (item.MeasurementUnitId) {
            return this.translate.get('i18n:LABEL.LOADING');
        } else {
            return '';
        }
    }

    getFillOptionDescription(item: DataSourceTagsHistoryItem): string {
        if (item.AggregateFillOption) {
            const fillOption = find(this.fillOptions, (fillOption: {Name: string, Display: string}) => fillOption.Name == item.AggregateFillOption);
            return fillOption?.Display;
        } else {
            return null;
        }
    }

    openTagSelectionPopup() {
        this.core.showEntitySelectionPopup({
            title: this.translate.get('i18n:LABEL.TAG_SELECTION'),
            entitySet: 'UserBaseTags',
            singleEntitySet: 'BaseTags',
            includes: 'Asset.AssetType,MeasurementUnit.Measurement',
            sort: [{ field: 'Asset.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
            multiSelect: true,
            selectionRequired: false,
            columns: [
                { field: 'Asset.Path', title: this.translate.get('i18n:TAG.ASSET'), filterable: true },
                { field: 'Name', title: this.translate.get('i18n:TAG.NAME'), filterable: true },
                { field: 'MeasurementUnit.Name', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT'), filterable: true },
                { field: 'MeasurementUnit.Symbol', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT.SYMBOL'), filterable: true }
            ]
        }).subscribe({
            next: result => {
                if (result && result.length) {
                    result.forEach((tag: BaseTag) => {
                        if (findIndex(this.items, (item: DataSourceTagsHistoryItem) => item.TagId == tag.Id) == -1) {
                            this.items.push({
                                TagId: tag.Id,
                                Tag: tag,
                                MeasurementUnitId: tag.MeasurementUnitId,
                                MeasurementUnit: tag.MeasurementUnit,
                                Aggregate: false,
                                AggregateFillOption: 'none',
                                DefaultColor: '#000000'
                            });
                        }
                    });
                }
            }
        });
    }

    removeItems() {
        if (this.items && this.items.length && this.selectedItemKeys && this.selectedItemKeys.length) {
            const tagIds: string[] = clone(this.selectedItemKeys);
            tagIds.forEach((tagId: string) => {
                remove(this.items, (item: DataSourceTagsHistoryItem) => item.TagId == tagId);
            });
            this.selectedItemKeys = [];
        }
    }

    clearMeasurementUnit(item: DataSourceTagsHistoryItem) {
        item.MeasurementUnitId = null;
        item.MeasurementUnit = null;
    }

    openMeasurementSelectionPopup(item: DataSourceTagsHistoryItem) {
        const dialogRef = this.dialogService.open({
            content: MeasurementUnitSelectionPopupComponent,
            title: this.translate.get('i18n:LABEL.MEASUREMENT_UNIT_SELECTION'),
            minHeight: 250,
            minWidth: 450,
            height: '90vh',
            width: '90vw',
            actions: [{ text: this.translate.get('i18n:LABEL.CANCEL') }, { text: this.translate.get('i18n:LABEL.OK'), themeColor: 'primary' }],
            preventAction: (ev, dialog) => {
                if ((ev instanceof DialogCloseResult) || (ev.themeColor != 'primary'))
                    return false;

                const instance: MeasurementUnitSelectionPopupComponent = dialogRef.content.instance;
                if (instance.selectedEntities && instance.selectedEntities.length == 1) {
                    return false;
                }

                return true;
            }
        });

        const instance: MeasurementUnitSelectionPopupComponent = dialogRef.content.instance;
        instance.multiSelect = false;

        dialogRef.result.subscribe({
            next: (r: DialogResult) => {
                if (!(r instanceof DialogCloseResult) && r.themeColor == 'primary') {
                    if (instance.selectedEntities && instance.selectedEntities.length == 1) {
                        item.MeasurementUnit = instance.selectedEntities[0];
                        item.MeasurementUnitId = item.MeasurementUnit.Id;
                    }
                }
            }
        });
    }

    public cellClick(event: CellClickEvent) {
        if (!event.isEdited) {
            event.sender.editCell(event.rowIndex, event.columnIndex, this.createFormGroup(event.dataItem));
        }
    }

    public cellClose(event: CellCloseEvent) {
        const formControl: FormControl = event.formGroup.get(event.column.field);
        if (formControl) {
            if (!formControl.valid) {
                event.preventDefault();
            } else if (formControl.dirty) {
                event.dataItem[event.column.field] = formControl.value;
            }
        }
    }

    public createFormGroup(dataItem: DataSourceTagsHistoryItem) {
        return new FormGroup({
            Aggregate: new FormControl(dataItem.Aggregate, Validators.required),
            AggregateFillOption: new FormControl(dataItem.AggregateFillOption, Validators.required),
            DefaultColor: new FormControl(dataItem.DefaultColor, Validators.required)
        });
    }
}

export class DataSourceTagsHistoryItem {
    TagId: string;
    Tag: BaseTag;
    MeasurementUnitId: string;
    MeasurementUnit: MeasurementUnit;
    Aggregate: boolean;
    AggregateFillOption: string;
    DefaultColor: string;
}