import { Component, Inject, Injector } from '@angular/core';
import * as _ from 'lodash';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService, CoreService, DashboardWidgetDocument, DataSource, DataSourceType, DATASOURCETYPES, EntitySelectionPopupSettings, TranslateService, WidgetConfigComponent } from '@ats/ats-platform-dashboard';
import { Asset } from '../../domain/entities/asset';
import { TagChart } from '../../domain/entities/tagChart';
 
@Component({
  selector: 'ats-smart-tool-ev-charging-sessions-widget-config',
  templateUrl: './ev-charging-sessions-widget-config.component.html',
  styleUrls: ['./ev-charging-sessions-widget-config.component.scss']
})
 
export class EVChargingSessionsWidgetConfigComponent implements WidgetConfigComponent {

  public assetSelectionPopupSettings: EntitySelectionPopupSettings;
  public assetFormatter = (asset: Asset) => asset ? asset.Path : null;
  public dataSourceSelectionPopupSettings: EntitySelectionPopupSettings;
  public dataSourceFormatter = (dataSource: DataSource) => dataSource ? dataSource.Folder.Path + ' / ' + dataSource.Name : null;
  public formGroup: FormGroup;
  public chartSelectionPopupSettings: EntitySelectionPopupSettings;
  public chartFormatter = (chart: TagChart) => chart?.Name;

  public historyOrActiveItems: { text: string; value: number }[];

  constructor(public core: CoreService, public translate: TranslateService, private api: ApiService, @Inject(DATASOURCETYPES) dataSourceTypes: DataSourceType[][], private injector: Injector) {
    this.formGroup = new FormGroup({
      title: new FormControl(''),
      widgetType: new FormControl(translate.get('EV_CHARGING_SESSIONS')),
      historyOrActive: new FormControl(0, Validators.required),
      assetId: new FormControl(null),
      dataSourceId: new FormControl(null, Validators.required),
      chartId: new FormControl(null)
    });

    this.formGroup.get('dataSourceId').disable();

    this.formGroup.get('historyOrActive').valueChanges.subscribe(newValue => {
      if (newValue === 1) {
        this.formGroup.get('assetId').disable();
        this.formGroup.get('dataSourceId').enable();
      } else  {
        this.formGroup.get('assetId').enable();
        this.formGroup.get('dataSourceId').disable();
      }
    });
    
    this.assetSelectionPopupSettings = {
      title: translate.get('i18n:SELECT_ASSET'),
      entitySet: 'Assets',
      includes: 'AssetType',
      sort: [{ field: 'Path', dir: 'asc' }],
      multiSelect: false,
      selectionRequired: false,
      columns: [
        { field: 'Path', title: translate.get('i18n:ASSET.PATH'), filterable: true },
        { field: 'AssetType.Name', title: translate.get('i18n:ASSET.TYPE'), filterable: true }
      ]
    };
    
    this.dataSourceSelectionPopupSettings = {
      title: translate.get('i18n:SELECT_DATASOURCE'),
      entitySet: 'DataSources',
      filter: {
        logic: 'or',
        filters: [
          { field: 'Type', operator: 'eq', value: 'evChargingSessions' }
        ]
      },
      includes: 'Configs,Folder',
      sort: [{ field: 'Folder.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
      multiSelect: false,
      selectionRequired: true,
      columns: [{ field: 'Folder.Path', title: this.translate.get('i18n:FOLDER.PATH'), filterable: true }, { field: 'Name', title: translate.get('i18n:DATASOURCE.NAME'), filterable: true }],
      info: translate.get('i18n:WIDGET.SUPPORTED_DATATYPES') + ': ' + translate.get('i18n:DATASOURCE.EV_CHARGING_SESSIONS')
    };

    this.chartSelectionPopupSettings = {
      title: translate.get('i18n:SELECT_CHART'),
      entitySet: 'TagCharts',
      includes: 'Folder',
      sort: [{ field: 'Folder.Path', dir: 'asc' },{ field: 'Name', dir: 'asc' }],
      multiSelect: false,
      selectionRequired: false,
      columns: [
        { field: 'Folder.Path', title: this.translate.get('i18n:FOLDER.PATH'), filterable: true },
        { field: 'Name', title: this.translate.get('i18n:CHART.NAME'), filterable: true }
      ],
      placeholder: translate.get('i18n:SELECT_CHART')
    };

    this.historyOrActiveItems = [{
      value: 0,
      text: translate.get('i18n:EV_CHARGING_WIDGET.SHOW_ACTIVE_SESSIONS')
    }, {
      value: 1,
      text: translate.get('i18n:EV_CHARGING_WIDGET.SHOW_HISTORY')
    }];
  }

  public isValid(): boolean {
    this.formGroup.markAllAsTouched();
    return this.formGroup.valid;
  }

  public getConfig(): any {
    return {
      title: this.formGroup.get('title').value,
      historyOrActive: this.formGroup.get('historyOrActive').value,
      assetId: this.formGroup.get('assetId').value,
      dataSourceId: this.formGroup.get('dataSourceId').value,
      chartId: this.formGroup.get('chartId').value
    };
  }

  public getDocuments(): DashboardWidgetDocument[] {
    return null;
  }
  
  public setData(config: any, documents: DashboardWidgetDocument[]) {
    this.formGroup.get('title').setValue(config?.title ?? '');
    this.formGroup.get('historyOrActive').setValue(config?.historyOrActive);
    this.formGroup.get('assetId').setValue(config?.assetId);
    this.formGroup.get('dataSourceId').setValue(config?.dataSourceId);
    this.formGroup.get('chartId').setValue(config?.chartId);
  }
}