export const FILLTIMEINTERVALTYPES: any[] = [
    { Key: -1, Name: 'NONE', Display: 'i18n:VIEW_STATIC_LIST.FILLTIMEINTERVAL.NONE' },
    { Key: 0, Name: 'AUTO', Display: 'i18n:VIEW_STATIC_LIST.FILLTIMEINTERVAL.AUTO' },
    { Key: 1, Name: 'OneSecond', Display: 'i18n:VIEW_STATIC_LIST.FILLTIMEINTERVAL.ONE_SECOND' },
    { Key: 2, Name: 'TwoSeconds', Display: 'i18n:VIEW_STATIC_LIST.FILLTIMEINTERVAL.TWO_SECONDS' },
    { Key: 3, Name: 'ThreeSeconds', Display: 'i18n:VIEW_STATIC_LIST.FILLTIMEINTERVAL.THREE_SECONDS' },
    { Key: 5, Name: 'FiveSeconds', Display: 'i18n:VIEW_STATIC_LIST.FILLTIMEINTERVAL.FIVE_SECONDS' },
    { Key: 10, Name: 'TenSeconds', Display: 'i18n:VIEW_STATIC_LIST.FILLTIMEINTERVAL.TEN_SECONDS' },
    { Key: 15, Name: 'FifteenSeconds', Display: 'i18n:VIEW_STATIC_LIST.FILLTIMEINTERVAL.FIFTEEN_SECONDS' },
    { Key: 20, Name: 'TwenySeconds', Display: 'i18n:VIEW_STATIC_LIST.FILLTIMEINTERVAL.TWENTY_SEONDS' },
    { Key: 30, Name: 'ThirtySeconds', Display: 'i18n:VIEW_STATIC_LIST.FILLTIMEINTERVAL.THIRTY_SEONDS' },

    { Key: 1*60, Name: 'OneMinute', Display: 'i18n:VIEW_STATIC_LIST.FILLTIMEINTERVAL.ONE_MINUTE' },
    { Key: 2*60, Name: 'TwoMinutes', Display: 'i18n:VIEW_STATIC_LIST.FILLTIMEINTERVAL.TWO_MINUTES' },
    { Key: 3*60, Name: 'ThreeMinutes', Display: 'i18n:VIEW_STATIC_LIST.FILLTIMEINTERVAL.THREE_MINUTES' },
    { Key: 5*60, Name: 'FiveMinutes', Display: 'i18n:VIEW_STATIC_LIST.FILLTIMEINTERVAL.FIVE_MINUTES' },
    { Key: 10*60, Name: 'TenMinutes', Display: 'i18n:VIEW_STATIC_LIST.FILLTIMEINTERVAL.TEN_MINUTES' },
    { Key: 15*60, Name: 'FifteenMinutes', Display: 'i18n:VIEW_STATIC_LIST.FILLTIMEINTERVAL.FIFTEEN_MINUTES' },
    { Key: 30*60, Name: 'ThirtyMinutes', Display: 'i18n:VIEW_STATIC_LIST.FILLTIMEINTERVAL.THIRTY_MINUTES' },

    { Key: 1*60*60, Name: 'OneHour', Display: 'i18n:VIEW_STATIC_LIST.FILLTIMEINTERVAL.ONE_HOUR' },
    { Key: 2*60*60, Name: 'TwoHours', Display: 'i18n:VIEW_STATIC_LIST.FILLTIMEINTERVAL.TWO_HOUR' },
    { Key: 3*60*60, Name: 'ThreeHours', Display: 'i18n:VIEW_STATIC_LIST.FILLTIMEINTERVAL.THREE_HOUR' },
    { Key: 4*60*60, Name: 'FourHours', Display: 'i18n:VIEW_STATIC_LIST.FILLTIMEINTERVAL.THREE_HOUR' },
    { Key: 6*60*60, Name: 'SixHours', Display: 'i18n:VIEW_STATIC_LIST.FILLTIMEINTERVAL.FOUR_HOUR' },
    { Key: 8*60*60, Name: 'EightHours', Display: 'i18n:VIEW_STATIC_LIST.FILLTIMEINTERVAL.EIGHT_HOUR' },
    { Key: 12*60*60, Name: 'TwelveHours', Display: 'i18n:VIEW_STATIC_LIST.FILLTIMEINTERVAL.TWELVE_HOUR' },
    { Key: 24*60*60, Name: 'OneDay', Display: 'i18n:VIEW_STATIC_LIST.FILLTIMEINTERVAL.ONE_DAY' }
];