<kendo-grid [data]="assets" [loading]="assetsLoading" [height]="410"
  style="margin-bottom: 16px;" [selectable]="{ mode: 'multiple', drag: true }" kendoGridSelectBy="Id"
  [(selectedKeys)]="selectedAssetIds">
  <ng-template kendoGridToolbarTemplate>
    <button kendoButton look="outline" (click)="addAssets()">{{
      'i18n:ASSET.ADD' | translate }}</button>
    <button kendoButton look="outline" [disabled]="selectedAssetIds.length == 0"
      (click)="removeAssets()">{{ 'i18n:ASSET.REMOVE' | translate
      }}</button>
  </ng-template>
  <kendo-grid-column [title]="translate.get('i18n:ASSET.CODE')"
    [field]="'Code'"></kendo-grid-column>
  <kendo-grid-column [title]="translate.get('i18n:ASSET.PATH')"
    [field]="'Path'"></kendo-grid-column>
</kendo-grid>
