import { Component } from "@angular/core";
import { DataStateChangeEvent, SelectionEvent, RowArgs, GridDataResult } from "@progress/kendo-angular-grid";
import { SelectableSettings } from "@progress/kendo-angular-treeview";
import { State } from "@progress/kendo-data-query";
import { ApiQueryOptions, ApiService, CoreService, PageResult, TranslateService } from "@ats/ats-platform-dashboard";
import { BehaviorSubject, Observable } from "rxjs";

import { find, findIndex, remove } from "lodash-es";
import { MeasurementUnit } from "../../domain/entities/measurementUnit";
import { DialogRef } from "@progress/kendo-angular-dialog";


@Component({
    selector: 'ats-smart-tool-dashboard-measurement-unit-selection-popup',
    templateUrl: './measurement-unit-selection-popup.component.html',
    styleUrls: ['./measurement-unit-selection-popup.component.scss']
})
export class MeasurementUnitSelectionPopupComponent {

    private dataSubject: BehaviorSubject<GridDataResult>;

    public dialogRef: DialogRef;

    public data$: Observable<GridDataResult>;
    public state: State = { skip: 0, take: 100 };
    public selectableSettings: SelectableSettings;
    public loading: boolean = false;

    public set multiSelect(value: boolean) {
        this.selectableSettings.mode = value ? "multiple" : "single";
    }
    public get multiSelect(): boolean {
        return this.selectableSettings.mode == "multiple";
    }

    public selectedKeys: string[] = [];
    public selectedEntities: MeasurementUnit[] = [];

    constructor(private api: ApiService, private core: CoreService, public translate: TranslateService) {
        this.dataSubject = new BehaviorSubject<GridDataResult>(null);
        this.data$ = this.dataSubject.asObservable();
        this.selectableSettings = { mode: "single" };

        this.query(this.state);
    }

    public dataStateChange(state: DataStateChangeEvent): void {
        this.state = state;
        this.query(state);
    }

    public selectionChange(event: SelectionEvent) {
        if (event.deselectedRows && event.deselectedRows.length) {
            event.deselectedRows.forEach((row: RowArgs) => {
                remove(this.selectedEntities, (entity: MeasurementUnit) => entity.Id == (<MeasurementUnit>row.dataItem).Id);
            });
        }

        if (event.selectedRows && event.selectedRows.length) {
            event.selectedRows.forEach((row: RowArgs) => {
                if (findIndex(this.selectedEntities, (tag: MeasurementUnit) => tag.Id == (<MeasurementUnit>row.dataItem).Id) == -1) {
                    this.selectedEntities.push(<MeasurementUnit>row.dataItem);
                }
            });
        }

        this.selectedKeys = this.selectedEntities.map((entity: MeasurementUnit) => entity.Id);
    }

    protected query(state: State) {

        let queryOptions = new ApiQueryOptions({
            include: 'Measurement',
            requireTotalCount: true
        });
        queryOptions.state = state;


        this.loading = true;
        return this.api.getPageResult<MeasurementUnit>('MeasurementUnits', queryOptions).subscribe({
            next: (pageResult: PageResult<MeasurementUnit>) => {
                this.loading = false;
                this.dataSubject.next(<GridDataResult>{
                    data: pageResult.Items,
                    total: pageResult.TotalCount
                });
            }, error: (error: any) => {
                this.loading = false;
                this.core.showErrorDetail(error);
            }
        });
    }
}