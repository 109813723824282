import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Type, ViewChild } from "@angular/core";
import { HubConnection } from "@aspnet/signalr";
import { DashboardService, DataSourceInstance, TranslateService, WidgetComponent, WidgetInstance, SvgContainerComponent, WidgetUpdater, CoreService } from "@ats/ats-platform-dashboard";
import { Subscription } from "rxjs";

import { find, findIndex, first, maxBy, min, minBy } from "lodash-es";
import { select } from 'd3';
import { HeatMap, HeatMapData } from "../../svg/heat-map";
import { BuildingEnergyData, BuildingEnergyDetailData } from "../../domain/dataModels/buildingEnergyData"
import { DayFilter } from "../../domain/enums/dayFilter";
import { BuildingEnergyValueType, BUILDING_ENERGY_VALUE_TYPES } from "../../domain/enums/building-energy-value-types";
import { ProductionConsumptionData } from "../../domain/dataModels/productionConsumptionData";
import { EnergyManagementData } from "../../domain/dataModels/energyManagementData";
import { TopologyDevice } from "../../domain/dataModels/TopologyDevice";

@Component({
  selector: 'ats-smart-tool-heat-map-widget',
  templateUrl: './heat-map-widget.component.html',
  styleUrls: ['./heat-map-widget.component.scss']
})
export class HeatMapWidgetComponent implements WidgetComponent, OnInit, AfterViewInit, OnDestroy {
  @Input() hubConnection: HubConnection;

  public widgetInstance: WidgetInstance;
  public isLoading = false;
  public error: string;

  private widgetConfigSubscription: Subscription;
  private widgetUpdaters: WidgetUpdater[]
  private svg: SVGSVGElement;
  public valueTypes: { Key: BuildingEnergyValueType, Display: string }[];

  private drawer: HeatMap;

  public svgContainerType: Type<SvgContainerComponent> = SvgContainerComponent;

  @ViewChild('svgContainer', { static: false }) svgContainer: ElementRef;

  constructor(private dashboardService: DashboardService, private translate: TranslateService, core: CoreService) {
    this.valueTypes = core.translateObjectArray(BUILDING_ENERGY_VALUE_TYPES, 'Display');
  }

  public setWidgetInstance(widgetInstance: WidgetInstance) {
    this.widgetInstance = widgetInstance;
  }

  ngOnInit() {
    this.widgetConfigSubscription = this.widgetInstance.changed.subscribe({
      next: (instance: WidgetInstance) => {
        this.updateData(instance);
      }
    })
  }

  ngAfterViewInit() {
    setTimeout(() => { this.updateData(this.widgetInstance); }, 0);
  }

  ngOnDestroy() {
    if (this.widgetConfigSubscription) {
      this.widgetConfigSubscription.unsubscribe();
      this.widgetConfigSubscription = null;
    }

    if (this.widgetUpdaters) {
      this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
      this.widgetUpdaters = null;
    }
  }

  protected updateData(instance: WidgetInstance) {
    instance.widgetData = {
      title: instance.widgetConfig.title
    };

    if (!this.svgContainer)
      return;

    const div: HTMLDivElement = this.svgContainer.nativeElement;
    const svgs = div.getElementsByTagName('svg');
    this.svg = svgs && svgs.length ? svgs.item(0) : null;
    if (!this.svg)
      return;

    // init drawer
    this.drawer = new HeatMap();
    this.drawer.left = instance.widgetConfig.leftMargin;
    this.drawer.right = instance.widgetConfig.rightMargin;
    this.drawer.top = instance.widgetConfig.topMargin;
    this.drawer.bottom = instance.widgetConfig.bottomMargin;
    this.drawer.numberOfDecimals = instance.widgetConfig.numberOfDecimals;
    this.drawer.hideLabels = instance.widgetConfig.hideLabels;
    this.drawer.showZero = true;
    this.drawer.posMinColor = instance.widgetConfig.posMinColor ?? '#e1efd9';
    this.drawer.posMaxColor = instance.widgetConfig.posMaxColor ?? '#71ae48';
    this.drawer.negMinColor = instance.widgetConfig.negMinColor ?? '#4371c4';
    this.drawer.negMaxColor = instance.widgetConfig.negMaxColor ?? '#dae1f4';
    this.drawer.showBy = 'quarthour';
    this.drawer.timeOfDayOrientation = instance.widgetConfig.timeOfDayOrientation;

    if (this.widgetUpdaters) {
      this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
      this.widgetUpdaters = null;
    }

    const dataSourceInstance = find(this.dashboardService.dataSourceInstances, (dataSourceInstance: DataSourceInstance) => dataSourceInstance.id == instance.widgetConfig.dataSourceId);
    if (dataSourceInstance) {
      this.widgetUpdaters = [];
      this.widgetUpdaters.push(new WidgetUpdater(dataSourceInstance, div, (dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement) => this.update(dataSourceInstance, nativeElement), null));
    }
  }

  protected update(dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement) {
    this.isLoading = dataSourceInstance?.result.isLoading;

    if (dataSourceInstance.result && dataSourceInstance.result.data) {
      const d3svg = select(this.svg);
      d3svg.attr('viewBox', '0 0 ' + (nativeElement.getBoundingClientRect().width) + ' ' + (nativeElement.getBoundingClientRect().height));

      var data: HeatMapData;

      switch (dataSourceInstance.type.name) {
        case 'buildingEnergy':
          data =  this.getBuildingEnergyHeatMapData(dataSourceInstance.result.data);
          this.drawer.showBy = 'hour';
          break;
        case 'productionConsumption':
          data = this.getProductionConsumptionHeatMapData(dataSourceInstance.result.data);
          this.drawer.showBy = 'quarthour';
          break;
        case 'energyManagement':
          data = this.getEnergyManagementHeatMapData(dataSourceInstance.result.data);
          this.drawer.showBy = 'quarthour';
          break;
        case 'deviceTopology':
          data = this.getDeviceTopologyHeatMapData(dataSourceInstance.result.data);
          this.drawer.showBy = 'quarthour';
          break;
        case 'solarPerformance':
          data = this.getSolarPerformanceHeatMapData(dataSourceInstance.result.data);
          this.drawer.showBy = 'quarthour';
          break;
      }

      // update value
      if (this.drawer && data && data.data) {

        data.domain = {
          min: minBy(data.data, x => x.value).value,
          max: maxBy(data.data, x => x.value).value
        }

        const d3svg = select(this.svg);
        d3svg.selectAll('g').remove();
        d3svg.attr('viewBox', '0 0 ' + nativeElement.getBoundingClientRect().width + ' ' + nativeElement.getBoundingClientRect().height);

        this.drawer.group = d3svg.append('g');
        this.drawer.width = nativeElement.getBoundingClientRect().width;
        this.drawer.height = nativeElement.getBoundingClientRect().height;
        this.drawer.draw(data);
      }
    }
  }

  private getBuildingEnergyHeatMapData(buildingEnergyData: BuildingEnergyData): HeatMapData {
    const data: HeatMapData = new HeatMapData();

    const convertValue = (detailData: BuildingEnergyDetailData, type: BuildingEnergyValueType, floorSurface: number): number => {
      switch (type) {
        case BuildingEnergyValueType.AbsoluteEnergy:
          return detailData["energy"];
        case BuildingEnergyValueType.AbsoluteEmission:
          return detailData["emission"];
        case BuildingEnergyValueType.AbsolutePrimaryEnergy:
          return detailData["primaryEnergy"];
        case BuildingEnergyValueType.RelativeEnergyToFloorSurface:
          if (floorSurface) {
            return detailData["energy"] / floorSurface;
          } else {
            return 0;
          }
        case BuildingEnergyValueType.RelativeEmissionToFloorSurface:
          if (floorSurface) {
            return detailData["emission"] / floorSurface;
          } else {
            return 0;
          }
        case BuildingEnergyValueType.RelativePrimaryEnergyToFloorSurface:
          if (floorSurface) {
            return detailData["primaryEnergy"] / floorSurface;
          } else {
            return 0;
          }
        case BuildingEnergyValueType.RelativeEnergyToDegreeDays:
          if (detailData["degreedays"]) {
            return detailData["energy"] / detailData["degreedays"];
          } else {
            return 0;
          }
        case BuildingEnergyValueType.RelativeEmissionToDegreeDays:
          if (detailData["degreedays"]) {
            return detailData["emission"] / detailData["degreedays"];
          } else {
            return 0;
          }
        case BuildingEnergyValueType.RelativePrimaryEnergyToDegreeDays:
          if (detailData["degreedays"]) {
            return detailData["primaryEnergy"] / detailData["degreedays"];
          } else {
            return 0;
          }
        case BuildingEnergyValueType.RelativeEnergyToFloorSurfaceAndDegreeDays:
          if (floorSurface && detailData["degreedays"]) {
            return detailData["energy"] / floorSurface / detailData["degreedays"];
          } else {
            return 0;
          }
        case BuildingEnergyValueType.RelativeEmissionToFloorSurfaceAndDegreeDays:
          if (floorSurface && detailData["degreedays"]) {
            return detailData["emission"] / floorSurface / detailData["degreedays"];
          } else {
            return 0;
          }
        case BuildingEnergyValueType.RelativePrimaryEnergyToFloorSurfaceAndDegreeDays:
          if (floorSurface && detailData["degreedays"]) {
            return detailData["primaryEnergy"] / floorSurface / detailData["degreedays"];
          } else {
            return 0;
          }
        default:
          return 0;
      }
    };

    switch (this.widgetInstance.widgetConfig.valueSubcategory) {
      case BuildingEnergyValueType.AbsoluteEnergy:
      case BuildingEnergyValueType.AbsolutePrimaryEnergy:
        data.unit = buildingEnergyData.EnergyUnit?.Symbol;
        break;
      case BuildingEnergyValueType.AbsoluteEmission:
        data.unit = buildingEnergyData.EmissionUnit?.Symbol;
        break;
      case BuildingEnergyValueType.RelativeEnergyToFloorSurface:
      case BuildingEnergyValueType.RelativePrimaryEnergyToFloorSurface:
        data.unit = buildingEnergyData.EnergyUnit?.Symbol + ' / ' + buildingEnergyData.SurfaceUnit?.Symbol;
        break;
      case BuildingEnergyValueType.RelativeEmissionToFloorSurface:
        data.unit =  buildingEnergyData.EmissionUnit?.Symbol + ' / ' + buildingEnergyData.SurfaceUnit?.Symbol;
        break;
      case BuildingEnergyValueType.RelativeEnergyToDegreeDays:
      case BuildingEnergyValueType.RelativePrimaryEnergyToDegreeDays:
        data.unit = buildingEnergyData.EnergyUnit?.Symbol + ' / degreedays';
        break;
      case BuildingEnergyValueType.RelativeEmissionToDegreeDays:
        data.unit = buildingEnergyData.EmissionUnit?.Symbol + ' / degreedays';
        break;
      case BuildingEnergyValueType.RelativeEnergyToFloorSurfaceAndDegreeDays:
      case BuildingEnergyValueType.RelativePrimaryEnergyToFloorSurfaceAndDegreeDays:
        data.unit = buildingEnergyData.EnergyUnit?.Symbol + ' / ' + buildingEnergyData.SurfaceUnit?.Symbol + ' / degreedays';
        break;
      case BuildingEnergyValueType.RelativeEmissionToFloorSurfaceAndDegreeDays:
        data.unit = buildingEnergyData.EmissionUnit?.Symbol + ' / ' + buildingEnergyData.SurfaceUnit?.Symbol + ' / degreedays';
        break;
      default:
        data.unit = '';
        break;
    }

    const buildingIndex = findIndex(buildingEnergyData.BuildingData, (buildingData) => { return (buildingData as any).Asset.Id == this.widgetInstance.widgetConfig.valueCategory });
    var minValue = 0;
    var maxValue = 0;
    data.data = buildingEnergyData.BuildingData[buildingIndex].HourData.map(d => {
      let item = { timeStamp: new Date(d.TimeStamp), value: convertValue(d, this.widgetInstance.widgetConfig.valueSubcategory, buildingEnergyData.BuildingData[buildingIndex].FloorSurface) };
      if (item.value > maxValue) {
        maxValue = item.value;
      }
      if (item.value < minValue) {
        minValue = item.value;
      }
      return item;
    });
    data.domain = {
      min: minValue,
      max: maxValue
    };

    return data;
  }

  private getProductionConsumptionHeatMapData(productionConsumptionData: ProductionConsumptionData) {
    const data: HeatMapData = new HeatMapData();
    data.unit = productionConsumptionData.MeasurementUnit?.Symbol ?? productionConsumptionData.MeasurementUnit?.Name;
    data.data = productionConsumptionData.DetailData.map(x => { return { timeStamp: new Date(x.TimeStamp), value: x[this.widgetInstance.widgetConfig.valueCategory] ?? 0 }});
    return data;
  }

  private getEnergyManagementHeatMapData(energyManagementData: EnergyManagementData) {
    const data: HeatMapData = new HeatMapData();
    
    switch (this.widgetInstance.widgetConfig.valueCategory) {
      case 28: // RatioSelfConsumption
        data.unit = '%';
        data.data = energyManagementData.EnergyMeterTypeDetailData.map(x => { return { timeStamp: new Date(x.TimeStamp), value: x.SelfConsumptionRate ?? 0 }});
        break;
      case 8: // PriceEnergyMix
        data.unit = '€';
        data.data = energyManagementData.EnergyMeterTypeDetailData.map(x => { return { timeStamp: new Date(x.TimeStamp), value: x.PriceEnergyMix ?? 0 }});
        break;
      case 9: // PriceElectricityMix
        data.unit = '€';
        data.data = energyManagementData.EnergyMeterTypeDetailData.map(x => { return { timeStamp: new Date(x.TimeStamp), value: x.PriceElectricityMix ?? 0 }});
        break;
      case 10: // PriceElectricityMixWithoutEV
        data.unit = '€';
        data.data = energyManagementData.EnergyMeterTypeDetailData.map(x => { return { timeStamp: new Date(x.TimeStamp), value: x.PriceElectricityMixWithoutEV ?? 0 }});
        break;
      case 11: // PriceGridConsumption
        data.unit = '€';
        data.data = energyManagementData.EnergyMeterTypeDetailData.map(x => { return { timeStamp: new Date(x.TimeStamp), value: x.PriceGridConsumption ?? 0 }});
        break;
      case 12: // PriceGridInjection
        data.unit = '€';
        data.data = energyManagementData.EnergyMeterTypeDetailData.map(x => { return { timeStamp: new Date(x.TimeStamp), value: x.PriceGridInjection ?? 0 }});
        break;
      case 13: // PriceGas
        data.unit = '€';
        data.data = energyManagementData.EnergyMeterTypeDetailData.map(x => { return { timeStamp: new Date(x.TimeStamp), value: x.PriceGas ?? 0 }});
        break;
      case 14: // PriceDiesel
        data.unit = '€';
        data.data = energyManagementData.EnergyMeterTypeDetailData.map(x => { return { timeStamp: new Date(x.TimeStamp), value: x.PriceDiesel ?? 0 }});
        break;
      case 15: // PriceEV
        data.unit = '€';
        data.data = energyManagementData.EnergyMeterTypeDetailData.map(x => { return { timeStamp: new Date(x.TimeStamp), value: x.PriceEV ?? 0 }});
        break;
      case 16: // PriceSaved
        data.unit = '€';
        data.data = energyManagementData.EnergyMeterTypeDetailData.map(x => { return { timeStamp: new Date(x.TimeStamp), value: x.PriceSaved ?? 0 }});
        break;
      case 17: // EnergyEnergyMix
        data.unit = energyManagementData.EnergyMeasurementUnit?.Symbol ?? energyManagementData.EnergyMeasurementUnit?.Name;
        data.data = energyManagementData.EnergyMeterTypeDetailData.map(x => { return { timeStamp: new Date(x.TimeStamp), value: x.EnergyEnergyMix ?? 0 }});
        break;
      case 18: // EnergyElectricityMix
        data.unit = energyManagementData.EnergyMeasurementUnit?.Symbol ?? energyManagementData.EnergyMeasurementUnit?.Name;
        data.data = energyManagementData.EnergyMeterTypeDetailData.map(x => { return { timeStamp: new Date(x.TimeStamp), value: x.EnergyElectricityMix ?? 0 }});
        break;
      case 19: // EnergyElectricityMixWithoutEV
        data.unit = energyManagementData.EnergyMeasurementUnit?.Symbol ?? energyManagementData.EnergyMeasurementUnit?.Name;
        data.data = energyManagementData.EnergyMeterTypeDetailData.map(x => { return { timeStamp: new Date(x.TimeStamp), value: x.EnergyElectricityMixWithoutEV ?? 0 }});
        break;
      case 20: // EnergySolar
        data.unit = energyManagementData.EnergyMeasurementUnit?.Symbol ?? energyManagementData.EnergyMeasurementUnit?.Name;
        data.data = energyManagementData.EnergyMeterTypeDetailData.map(x => { return { timeStamp: new Date(x.TimeStamp), value: x.Solar ?? 0 }});
        break;
      case 21: // EnergyWind
        data.unit = energyManagementData.EnergyMeasurementUnit?.Symbol ?? energyManagementData.EnergyMeasurementUnit?.Name;
        data.data = energyManagementData.EnergyMeterTypeDetailData.map(x => { return { timeStamp: new Date(x.TimeStamp), value: x.Wind ?? 0 }});
        break;
      case 22: // EnergyGridConsumption
        data.unit = energyManagementData.EnergyMeasurementUnit?.Symbol ?? energyManagementData.EnergyMeasurementUnit?.Name;
        data.data = energyManagementData.EnergyMeterTypeDetailData.map(x => { return { timeStamp: new Date(x.TimeStamp), value: x.GridConsumption ?? 0 }});
        break;
      case 23: // EnergyGridInjection
        data.unit = energyManagementData.EnergyMeasurementUnit?.Symbol ?? energyManagementData.EnergyMeasurementUnit?.Name;
        data.data = energyManagementData.EnergyMeterTypeDetailData.map(x => { return { timeStamp: new Date(x.TimeStamp), value: x.GridInjection ?? 0 }});
        break;
      case 24: // EnergyGas
        data.unit = energyManagementData.EnergyMeasurementUnit?.Symbol ?? energyManagementData.EnergyMeasurementUnit?.Name;
        data.data = energyManagementData.EnergyMeterTypeDetailData.map(x => { return { timeStamp: new Date(x.TimeStamp), value: x.Gas ?? 0 }});
        break;
      case 25: // EnergyDiesel
        data.unit = energyManagementData.EnergyMeasurementUnit?.Symbol ?? energyManagementData.EnergyMeasurementUnit?.Name;
        data.data = energyManagementData.EnergyMeterTypeDetailData.map(x => { return { timeStamp: new Date(x.TimeStamp), value: x.Diesel ?? 0 }});
        break;
      case 26: // EnergyEV
        data.unit = energyManagementData.EnergyMeasurementUnit?.Symbol ?? energyManagementData.EnergyMeasurementUnit?.Name;
        data.data = energyManagementData.EnergyMeterTypeDetailData.map(x => { return { timeStamp: new Date(x.TimeStamp), value: x.EVCharger ?? 0 }});
        break;
      case 27: // EnergySelfConsumption
        data.unit = energyManagementData.EnergyMeasurementUnit?.Symbol ?? energyManagementData.EnergyMeasurementUnit?.Name;
        data.data = energyManagementData.EnergyMeterTypeDetailData.map(x => { return { timeStamp: new Date(x.TimeStamp), value: x.SelfConsumption ?? 0 }});
        break;
      case 30: // EnergyBatteryEnergyIn
        data.unit = energyManagementData.EnergyMeasurementUnit?.Symbol ?? energyManagementData.EnergyMeasurementUnit?.Name;
        data.data = energyManagementData.EnergyMeterTypeDetailData.map(x => { return { timeStamp: new Date(x.TimeStamp), value: x.BatteryEnergyIn ?? 0 }});
        break;
      case 31: // EnergyBatteryEnergyOut
        data.unit = energyManagementData.EnergyMeasurementUnit?.Symbol ?? energyManagementData.EnergyMeasurementUnit?.Name;
        data.data = energyManagementData.EnergyMeterTypeDetailData.map(x => { return { timeStamp: new Date(x.TimeStamp), value: x.BatteryEnergyOut ?? 0 }});
        break;
    }

    return data;
  }

  private getDeviceTopologyHeatMapData(deviceTopologyData: TopologyDevice[]) {
    const data: HeatMapData = new HeatMapData();

    const device: TopologyDevice = find(deviceTopologyData, { 'Name': this.widgetInstance.widgetConfig.valueCategory });
    if (device && device.Points) {
      data.unit = this.widgetInstance.widgetConfig.valueCategory == 1 ? device.EnergyOutTag?.MeasurementUnit?.Symbol : device.EnergyInTag?.MeasurementUnit?.Symbol;
      data.data = device.Points.map(x => { return { timeStamp: new Date(x.IntervalStart), value: x[this.widgetInstance.widgetConfig.valueCategory == 1 ? 'EnergyOut' : 'EnergyIn'] ?? 0 }});
    }

    return data;
  }

  private getSolarPerformanceHeatMapData(solarPerformanceData: any) {
    const data: HeatMapData = new HeatMapData();

    let key = this.widgetInstance.widgetConfig.valueCategory + '_';
    switch (this.widgetInstance.widgetConfig.valueSubcategory) {
      case 1:
        key += 'estimatedEnergy';
        break;
      case 2:
        key += 'actualSpecificEnergy';
        break;
      case 3:
        key += 'estimatedSpecificEnergy';
        break;
      default:
        key += 'actualEnergy';
        break;
    }

    data.unit = solarPerformanceData.MeasurementUnit?.Symbol;
    data.data = solarPerformanceData.DetailData.map(x => { return { timeStamp: new Date(x.TimeStamp), value: x[key] ?? 0 }});

    return data;
  }

  public cancel(e: any) {
    e.preventDefault();
    e.cancelBubble = true;
  }
}


export class VisualizationConfig {
  leftMargin: number; //Includes the Y values
  rightMargin: number;
  topMargin: number;
  bottomMargin: number; //Includes the X values
  height: number;
  width: number;

  constructor(leftMargin: number, rightMargin: number, topMargin: number, bottomMargin: number, height: number, width: number) {
    this.leftMargin = leftMargin;
    this.rightMargin = rightMargin;
    this.topMargin = topMargin;
    this.bottomMargin = bottomMargin;
    this.height = height;
    this.width = width;
  }
}