<form class="k-form" [formGroup]="formGroup">
  <fieldset class="k-form-fieldset">
    <legend class="k-form-legend">{{ "LABEL.GENERAL" | translate }}</legend>
    <kendo-formfield>
      <kendo-label [for]="title" text="{{ 'WIDGET.TITLE' | translate }}"></kendo-label>
      <input #title formControlName="title" kendoTextBox />
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="widgetType" text="{{ 'WIDGET.TYPE' | translate }}"></kendo-label>
      <kendo-textbox #widgetType formControlName="widgetType" [readonly]="true"></kendo-textbox>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="dataSourceId" text="{{ 'WIDGET.DATASOURCE' | translate }}"></kendo-label>
      <ats-platform-entity-lookup-textbox #dataSourceId formControlName="dataSourceId" [settings]="dataSourceSelectionPopupSettings" [formatter]="dataSourceFormatter" (entityChange)="onDataSourceChange($event)"></ats-platform-entity-lookup-textbox>
      <kendo-formerror *ngIf="formGroup.controls['dataSourceId'].errors?.['required']">{{ "MESSAGE.FIELD_IS_REQUIRED" | translate }}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="valueCategory" text="{{ 'WIDGET.VALUE_CATEGORY' | translate }}"></kendo-label>
      <kendo-dropdownlist #valueCategory formControlName="valueCategory" [data]="valueCategories" textField="Display" valueField="Key" [valuePrimitive]="true" [loading]="valueCategoriesLoading"></kendo-dropdownlist>
      <kendo-formerror *ngIf="formGroup.controls['valueCategory'].errors?.['required']">{{ "MESSAGE.FIELD_IS_REQUIRED" | translate }}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="valueSubcategory" text="{{ 'WIDGET.VALUE_SUBCATEGORY' | translate }}"></kendo-label>
      <kendo-dropdownlist #valueSubcategory formControlName="valueSubcategory" [textField]="'Display'" [valueField]="'Key'" [valuePrimitive]="true" [data]="valueSubcategories" [filterable]="true"></kendo-dropdownlist>
      <kendo-formerror *ngIf="formGroup.controls['valueSubcategory'].errors?.['required']">{{ "MESSAGE.FIELD_IS_REQUIRED" | translate }}</kendo-formerror>
    </kendo-formfield>
  </fieldset>
  <fieldset class="k-form-fieldset">
    <legend class="k-form-legend">{{ "LABEL.SETTINGS" | translate }}</legend>
    <kendo-formfield>
      <kendo-label [for]="leftMargin" text="{{ 'WIDGET.LEFTMARGIN' | translate }}"></kendo-label>
      <kendo-numerictextbox #leftMargin formControlName="leftMargin" [min]="1" [decimals]="0" [format]="'n0'"></kendo-numerictextbox>
      <kendo-formerror *ngIf="formGroup.controls['leftMargin'].errors?.['required']">{{ "MESSAGE.FIELD_IS_REQUIRED" | translate }}</kendo-formerror>
      <kendo-formerror *ngIf="formGroup.controls['leftMargin'].errors?.['required']">{{ translate.get("i18n:MESSAGE.VALIDATION_MINIMUM", formGroup.controls.leftMargin.errors.minError) }}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="rightMargin" text="{{ 'WIDGET.RIGHTMARGIN' | translate }}"></kendo-label>
      <kendo-numerictextbox #rightMargin formControlName="rightMargin" [min]="1" [decimals]="0" [format]="'n0'"></kendo-numerictextbox>
      <kendo-formerror *ngIf="formGroup.controls['rightMargin'].errors?.['required']">{{ "MESSAGE.FIELD_IS_REQUIRED" | translate }}</kendo-formerror>
      <kendo-formerror *ngIf="formGroup.controls['rightMargin'].errors?.['minError']">{{ translate.get("i18n:MESSAGE.VALIDATION_MINIMUM", formGroup.controls.rightMargin.errors.minError) }}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="topMargin" text="{{ 'WIDGET.topMargin' | translate }}"></kendo-label>
      <kendo-numerictextbox #topMargin formControlName="topMargin" [min]="1" [decimals]="0" [format]="'n0'"></kendo-numerictextbox>
      <kendo-formerror *ngIf="formGroup.controls['topMargin'].errors?.['required']">{{ "MESSAGE.FIELD_IS_REQUIRED" | translate }}</kendo-formerror>
      <kendo-formerror *ngIf="formGroup.controls['topMargin'].errors?.['minError']">{{ translate.get("i18n:MESSAGE.VALIDATION_MINIMUM", formGroup.controls.topMargin.errors.minError) }}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="bottomMargin" text="{{ 'WIDGET.bottomMargin' | translate }}"></kendo-label>
      <kendo-numerictextbox #bottomMargin formControlName="bottomMargin" [min]="1" [decimals]="0" [format]="'n0'"></kendo-numerictextbox>
      <kendo-formerror *ngIf="formGroup.controls['bottomMargin'].errors?.['required']">{{ "MESSAGE.FIELD_IS_REQUIRED" | translate }}</kendo-formerror>
      <kendo-formerror *ngIf="formGroup.controls['bottomMargin'].errors?.['minError']">{{ translate.get("i18n:MESSAGE.VALIDATION_MINIMUM", formGroup.controls.bottomMargin.errors.minError) }}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="numberOfDecimals" text="{{ 'WIDGET.NUMBEROFDECIMALS' | translate }}"></kendo-label>
      <kendo-numerictextbox #numberOfDecimals formControlName="numberOfDecimals" [min]="0" [decimals]="0" [format]="'n0'"></kendo-numerictextbox>
      <kendo-formerror *ngIf="formGroup.controls['numberOfDecimals'].errors?.['minError']">{{ translate.get("i18n:MESSAGE.VALIDATION_MINIMUM", formGroup.controls.numberOfDecimals.errors.minError) }}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="hideLabels" text="{{ 'WIDGET.HIDELABELS' | translate }}"></kendo-label>
      <kendo-switch #hideLabels formControlName="hideLabels"></kendo-switch>
    </kendo-formfield>
    <kendo-formfield>
        <kendo-label [for]="posMinColor" text="{{ 'WIDGET.POS_MIN_COLOR' | translate }}"></kendo-label>
        <kendo-colorpicker #posMinColor formControlName="posMinColor" activeView="palette" [format]="'hex'"></kendo-colorpicker>
    </kendo-formfield>
    <kendo-formfield>
        <kendo-label [for]="posMaxColor" text="{{ 'WIDGET.POS_MAX_COLOR' | translate }}"></kendo-label>
        <kendo-colorpicker #posMaxColor formControlName="posMaxColor" activeView="palette" [format]="'hex'"></kendo-colorpicker>
    </kendo-formfield>
    <kendo-formfield>
        <kendo-label [for]="negMinColor" text="{{ 'WIDGET.NEG_MIN_COLOR' | translate }}"></kendo-label>
        <kendo-colorpicker #negMinColor formControlName="negMinColor" activeView="palette" [format]="'hex'"></kendo-colorpicker>
    </kendo-formfield>
    <kendo-formfield>
        <kendo-label [for]="negMaxColor" text="{{ 'WIDGET.NEG_MAX_COLOR' | translate }}"></kendo-label>
        <kendo-colorpicker #negMaxColor formControlName="negMaxColor" activeView="palette" [format]="'hex'"></kendo-colorpicker>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="timeOfDayOrientation" text="{{ 'HEATMAP.TIME_OF_DAY_ORIENTATION' | translate }}"></kendo-label>
      <kendo-dropdownlist #timeOfDayOrientation formControlName="timeOfDayOrientation" [textField]="'Display'" [valueField]="'Key'" [valuePrimitive]="true" [data]="timeOfDayOrientations" [filterable]="true"></kendo-dropdownlist>
      <kendo-formerror *ngIf="formGroup.controls['timeOfDayOrientation'].errors?.['required']">{{ "MESSAGE.FIELD_IS_REQUIRED" | translate }}</kendo-formerror>
    </kendo-formfield>
  </fieldset>
</form>
