import { Injectable, Inject } from '@angular/core';
import { AbstractHubService, BASE_URL, TranslateService } from '@ats/ats-platform-dashboard';

@Injectable()
export class EVChargingSessionsHubService extends AbstractHubService {

  constructor(@Inject(BASE_URL)  public baseUrl : string, public override translate: TranslateService) {
    super(translate);
    this.init(baseUrl, 'evChargingSessionsHub');
  }
}
