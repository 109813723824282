<form class="k-form" [formGroup]="formGroup">
    <fieldset class="k-form-fieldset">
        <legend class="k-form-legend">{{ 'LABEL.GENERAL' | translate}}</legend>
        <kendo-formfield>
            <kendo-label [for]="title" text="{{ 'WIDGET.TITLE' | translate }}"></kendo-label>
            <input #title formControlName="title" kendoTextBox />
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="widgetType" text="{{ 'WIDGET.TYPE' | translate }}"></kendo-label>
            <kendo-textbox #widgetType formControlName="widgetType" [readonly]="true" ></kendo-textbox>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="dataSourceId" text="{{ 'WIDGET.DATASOURCE' | translate }}"></kendo-label>
            <ats-platform-entity-lookup-textbox #dataSourceId formControlName="dataSourceId" [settings]="dataSourceSelectionPopupSettings" [formatter]="dataSourceFormatter" (entityChange)="onDataSourceChange($event)"></ats-platform-entity-lookup-textbox>
            <kendo-formerror *ngIf="formGroup.controls['dataSourceId'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>   
    </fieldset>
  </form>