import { Component, ViewChild, ViewContainerRef } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DialogCloseResult, DialogResult, DialogService } from "@progress/kendo-angular-dialog";
import { CellClickEvent, CellCloseEvent } from "@progress/kendo-angular-grid";
import { AccessRights, ApiQueryOptions, ApiService, CoreService, EntitySelectionPopupSettings, TranslateService, Folder } from "@ats/ats-platform-dashboard";
import { find, clone, isNumber, map, remove, filter } from "lodash-es";
import { forkJoin, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { v4 as uuidv4 } from 'uuid';
import { AssetType } from "../../domain/entities/assetType";
import { MeasurementUnit } from "../../domain/entities/measurementUnit";
import { TagChart } from "../../domain/entities/tagChart";
import { TagChartItem } from "../../domain/entities/tagChartItem";
import { FILLOPTIONTYPES } from "../../domain/enums/fillOptionTypes";
import { FILLTIMEINTERVALTYPES } from "../../domain/enums/fillTimeIntervalTypes";
import { LineType } from "../../domain/enums/lineType";
import { LINETYPEKEYS } from "../../domain/enums/lineTypeKeys";
import { Scale } from "../../domain/scale";
import { MeasurementUnitSelectionPopupComponent } from "../measurement-unit-selection-popup/measurement-unit-selection-popup.component";
import { FixedValue } from "../../domain/fixedValue";

@Component({
    selector: 'ats-smart-tool-chart-popup',
    templateUrl: './chart-popup.component.html',
    styleUrls: ['./chart-popup.component.scss']
})
export class ChartPopupComponent {

    public chart: TagChart;
    public items: TagChartItem[];
    public scales: Scale[];
    public scalesLoading: boolean = false;
    public fixedValues: FixedValue[];
    public selectedItemKeys: string[] = [];
    public selectedScaleKeys: string[] = [];
    public selectedFixedValuesKeys: string[] = [];
    public isNew: boolean;
    public accessRights: AccessRights[];

    public formGroup: FormGroup;
    public timeIntervals: { Key: string, Name: string, Display: string }[];
    public fillOptions: { Key: string, Name: string, Display: string }[];
    public functions: { Key: string, Name: string }[];
    public lineTypes: { Key: LineType, Display: string }[];

    public assetTypeSelectionPopupSettings: EntitySelectionPopupSettings;
    public assetTypeFormatter = (assetType: AssetType) => assetType?.Name;

    public templateSelectionPopupSettings: EntitySelectionPopupSettings;
    public templateFormatter = (template: TagChart) => template?.Name;

    public folderSelectionPopupSettings: EntitySelectionPopupSettings;
    public folderFormatter = (folder: Folder) => folder?.Path;

    @ViewChild('notificationRef', { read: ViewContainerRef }) public notificationRef: ViewContainerRef;

    constructor(private core: CoreService, public translate: TranslateService, private dialogService: DialogService, private api: ApiService) {
        this.timeIntervals = core.translateObjectArray(FILLTIMEINTERVALTYPES, 'Display');
        this.fillOptions = core.translateObjectArray(FILLOPTIONTYPES, 'Display');
        this.lineTypes = core.translateObjectArray(LINETYPEKEYS, 'Display');

        this.formGroup = new FormGroup({
            name: new FormControl(null, Validators.required),
            folderId: new FormControl(null, Validators.required),
            assetTypeId: new FormControl(null),
            templateId: new FormControl(null),
            aggregationWindow: new FormControl(0, Validators.required),
            maxPoints: new FormControl(2000, Validators.compose([Validators.required, Validators.min(1), Validators.max(1000000)])),
            chartHeight: new FormControl(300, Validators.compose([Validators.required, Validators.min(150), Validators.max(1000)]))
        });

        this.assetTypeSelectionPopupSettings = {
            title: translate.get('i18n:SELECT_ASSET_TYPE'),
            entitySet: 'AssetTypes',
            includes: '',
            sort: [{ field: 'Name', dir: 'asc' }],
            multiSelect: false,
            selectionRequired: false,
            columns: [
                { field: 'Name', title: translate.get('i18n:ASSETTYPE.NAME'), filterable: true }
            ]
        };

        this.templateSelectionPopupSettings = {
            title: translate.get('i18n:SELECT_TEMPLATE'),
            entitySet: 'TagCharts',
            includes: 'Folder',
            sort: [{ field: 'Folder.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
            multiSelect: false,
            selectionRequired: false,
            columns: [{ field: 'Folder.Path', title: this.translate.get('i18n:FOLDER.PATH'), filterable: true }, { field: 'Name', title: translate.get('i18n:TAGCHART.NAME'), filterable: true }
            ]
        };

        this.folderSelectionPopupSettings = {
            title: this.translate.get('i18n:SELECT_FOLDER'),
            entitySet: 'Folders',
            includes: null,
            sort: [{ field: 'Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
            filter: { field: 'Type', operator: 'eq', value: 3 },
            multiSelect: false,
            selectionRequired: false,
            columns: [{ field: 'Path', title: this.translate.get('i18n:FOLDER.PATH'), filterable: true }, { field: 'Name', title: this.translate.get('i18n:FOLDER.NAME'), filterable: true }]
        }
    }

    public init(chart: TagChart, isNew: boolean, folderId: string) {
        this.isNew = isNew;
        this.chart = chart;

        if (chart) {
            this.formGroup.get('name').setValue(chart.Name);
            this.formGroup.get('folderId').setValue(chart.FolderId);
            this.formGroup.get('assetTypeId').setValue(chart.AssetTypeId);
            this.formGroup.get('templateId').setValue(chart.TemplateId);
            this.formGroup.get('aggregationWindow').setValue(chart.FillTimeInterval);

            if (chart.Config) {
                var config = JSON.parse(chart.Config);
                this.formGroup.get('maxPoints').setValue(config.maxPoints ?? 2000);
                this.formGroup.get('chartHeight').setValue(config.chartHeight ?? 300);
                this.scales = config.scales;
                this.fixedValues = config.fixedValues;

                if (this.scales && this.scales.length) {
                    this.scalesLoading = true;

                    this.scales.forEach(scale => {
                    });

                    const observables: Observable<any>[] = [];
                    this.scales.forEach((item: Scale) => {
                        if (!item.id)
                            item.id = uuidv4();

                        if (item.measurementUnitId) {
                            observables.push(this.api.getSingleResult<MeasurementUnit>('MeasurementUnits', item.measurementUnitId, new ApiQueryOptions({ include: 'Measurement' })).pipe(tap((measurementUnit: MeasurementUnit) => {
                                item.measurementUnit = measurementUnit;
                            })));
                        }
                    });

                    if (observables && observables.length) {
                        forkJoin(observables).subscribe({ next: (value: any[]) => this.scalesLoading = false });
                    } else {
                        this.scalesLoading = false;
                    }
                } else {
                    this.scales = [];
                }

                if (!this.fixedValues || !this.fixedValues.length) {
                    this.fixedValues = [];
                }
            }

            this.items = clone(chart.Items);
            this.accessRights = clone(chart.AccessRights);
        } else {
            this.formGroup.get('name').setValue('');
            this.formGroup.get('folderId').setValue(folderId);
            this.formGroup.get('assetTypeId').setValue(null);
            this.formGroup.get('templateId').setValue(null);
            this.formGroup.get('aggregationWindow').setValue(0);
            this.formGroup.get('maxPoints').setValue(2000);
            this.formGroup.get('chartHeight').setValue(300);

            this.items = [];
            this.scales = [];
            this.fixedValues = [];
            this.accessRights = [];
        }
    }

    public isValid(): boolean {
        this.formGroup.get('name').markAsTouched();
        this.formGroup.get('folderId').markAsTouched();
        this.formGroup.get('assetTypeId').markAsTouched();
        this.formGroup.get('templateId').markAsTouched();
        this.formGroup.get('aggregationWindow').markAsTouched();
        this.formGroup.get('maxPoints').markAsTouched();
        this.formGroup.get('chartHeight').markAsTouched();

        return this.formGroup.valid;
    }

    public getTagDescription(item: TagChartItem): string {
        if (item.GenericAssetTagData) {
            return item.GenericAssetTagData.AssetName + ' - ' + item.GenericAssetTagData.Name;
        } else {
            return '';
        }
    }

    getMeasurementUnitDescription(item: TagChartItem): string {
        if (item.MeasurementUnit) {
            if (item.MeasurementUnit.Symbol && item.MeasurementUnit.Symbol.length) {
                return item.MeasurementUnit.Name + ' (' + item.MeasurementUnit.Symbol + ')';
            } else {
                return item.MeasurementUnit.Name;
            }
        } else {
            return '';
        }
    }

    getFunctionDescription(item: TagChartItem): string {
        if (item.Function) {
            return find(this.functions, (func) => func.Key == item.Function)?.Name;
        } else {
            return null;
        }
    }

    getLineTypeDescription(item: TagChartItem): string {
        if (isNumber(item.ChartType)) {
            return find(this.lineTypes, (type) => type.Key == item.ChartType)?.Display;
        } else {
            return null;
        }
    }

    getFillOptionDescription(item: TagChartItem): string {
        if (item.FillOption) {
            const fillOption = find(this.fillOptions, (fillOption: { Name: string, Display: string }) => fillOption.Name == item.FillOption);
            return fillOption?.Display;
        } else {
            return null;
        }
    }

    openTagSelectionPopup() {
        this.core.showEntitySelectionPopup({
            title: this.translate.get('i18n:LABEL.TAG_SELECTION'),
            entitySet: 'UserBaseTags',
            singleEntitySet: 'BaseTags',
            includes: 'Asset.AssetType,MeasurementUnit.Measurement',
            sort: [{ field: 'Asset.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
            multiSelect: true,
            selectionRequired: false,
            columns: [
                { field: 'Asset.Path', title: this.translate.get('i18n:TAG.ASSET'), filterable: true },
                { field: 'Name', title: this.translate.get('i18n:TAG.NAME'), filterable: true },
                { field: 'MeasurementUnit.Name', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT'), filterable: true },
                { field: 'MeasurementUnit.Symbol', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT.SYMBOL'), filterable: true }
            ]
        }).subscribe({
            next: result => {
                if (result && result.length) {
                    this.addChartTagsToDatagridDataSource(map(result, r => r.Id));
                }
            }
        });
    }

    openAssetTypeTagSelectionPopup() {
        this.core.showEntitySelectionPopup({
            title: this.translate.get('i18n:LABEL.ASSET_TYPE_TAG_SELECTION'),
            entitySet: 'AssetTypeBaseTags',
            includes: 'AssetType,MeasurementUnit.Measurement',
            sort: [{ field: 'AssetType.Name', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
            multiSelect: true,
            selectionRequired: false,
            columns: [
                { field: 'AssetType.Name', title: this.translate.get('i18n:TAG.ASSETTYPE'), filterable: true },
                { field: 'Name', title: this.translate.get('i18n:TAG.NAME'), filterable: true },
                { field: 'MeasurementUnit.Name', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT'), filterable: true },
                { field: 'MeasurementUnit.Symbol', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT.SYMBOL'), filterable: true }
            ]
        }).subscribe({
            next: result => {
                if (result && result.length) {
                    this.addChartTypeTagsToDatagridDataSource(map(result, r => r.Id));
                }
            }
        });
    }

    private addChartTagsToDatagridDataSource(selectedTagIds: string[]): void {
        this.core.setBusy(true, this.translate.get("COMMON.LOADING"));  
        const selectedTagIdsFiltered = filter(selectedTagIds, (x: String) => this.items.findIndex(y => y.GenericAssetTagDataId == x) == -1)
        if (selectedTagIdsFiltered.length > 0) {
            this.api.executeUnboundAction("TagChartItems", "GetTagChartItemsByTagIds", selectedTagIdsFiltered, new ApiQueryOptions({ include: "Asset,MeasurementUnit" }))
                .subscribe({
                    next: (result: any) => {
                        let newTagChartItems: TagChartItem[] = result.Items;
                        for (let tagChartItem of newTagChartItems) {
                            this.items.push(tagChartItem);
                            this.addScaleForTag(tagChartItem);
                        }
                        this.core.setBusy(false);
                    }, error: (err: any) => this.core.showErrorDetail(err)
                });
        }
    }

    private addChartTypeTagsToDatagridDataSource(selectedTagIds: string[]): void {
        this.core.setBusy(true, this.translate.get("COMMON.LOADING"));
        const selectedTagIdsFiltered = filter(selectedTagIds, (x: String) => this.items.findIndex(y => y.GenericAssetTagDataId == x) == -1)
        if (selectedTagIdsFiltered.length > 0) {
            this.api.executeUnboundAction("TagChartItems", "GetTagChartItemsByTypeTagIds", selectedTagIds, new ApiQueryOptions({ include: "AssetType,MeasurementUnit" }))
                .subscribe({
                    next: (result: TagChartItem[]) => {
                        for (let tagChartItem of result) {
                            this.items.push(tagChartItem);
                            this.addScaleForTag(tagChartItem);
                        }
                        this.core.setBusy(false);
                    }, error: (err: any) => this.core.showErrorDetail(err)
                });
        }
    }

    removeItems() {
        if (this.items && this.items.length && this.selectedItemKeys && this.selectedItemKeys.length) {
            const ids: string[] = clone(this.selectedItemKeys);
            ids.forEach((id: string) => {
                remove(this.items, (item: TagChartItem) => item.GenericAssetTagDataId == id);
            });
            this.selectedItemKeys = [];
        }
    }

    clearMeasurementUnit(item: TagChartItem) {
        item.MeasurementUnitId = null;
        item.MeasurementUnit = null;
    }

    openMeasurementSelectionPopup(item: TagChartItem) {
        const dialogRef = this.dialogService.open({
            content: MeasurementUnitSelectionPopupComponent,
            title: this.translate.get('i18n:LABEL.MEASUREMENT_UNIT_SELECTION'),
            minHeight: 250,
            minWidth: 450,
            height: '90vh',
            width: '90vw',
            actions: [{ text: this.translate.get('i18n:LABEL.CANCEL') }, { text: this.translate.get('i18n:LABEL.OK'), themeColor: 'primary' }],
            preventAction: (ev, dialog) => {
                if ((ev instanceof DialogCloseResult) || (ev.themeColor != 'primary'))
                    return false;

                const instance: MeasurementUnitSelectionPopupComponent = dialogRef.content.instance;
                if (instance.selectedEntities && instance.selectedEntities.length == 1) {
                    return false;
                }

                return true;
            }
        });

        const instance: MeasurementUnitSelectionPopupComponent = dialogRef.content.instance;
        instance.multiSelect = false;

        dialogRef.result.subscribe({
            next: (r: DialogResult) => {
                if (!(r instanceof DialogCloseResult) && (r.themeColor == 'primary')) {
                    if (instance.selectedEntities && instance.selectedEntities.length == 1) {
                        item.MeasurementUnit = instance.selectedEntities[0];
                        item.MeasurementUnitId = item.MeasurementUnit.Id;
                    }
                }
            }
        });
    }

    public cellClick(event: CellClickEvent) {
        if (!event.isEdited) {
            event.sender.editCell(event.rowIndex, event.columnIndex, this.createFormGroup(event.dataItem));
        }
    }

    public cellClose(event: CellCloseEvent) {
        const formControl: FormControl = event.formGroup.get(event.column.field);
        if (formControl) {
            if (!formControl.valid) {
                event.preventDefault();
            } else if (formControl.dirty) {
                event.dataItem[event.column.field] = formControl.value;
            }
        }
    }

    public createFormGroup(dataItem: TagChartItem) {
        return new FormGroup({
            Function: new FormControl(dataItem.Function),
            ChartType: new FormControl(dataItem.ChartType, Validators.required),
            Scale: new FormControl(dataItem.Scale, Validators.required),
            Color: new FormControl(dataItem.Color, Validators.required),
            Pane: new FormControl(dataItem.Pane),
            Aggregate: new FormControl(dataItem.Aggregate),
            ShowRange: new FormControl(dataItem.ShowRange),
            FillOption: new FormControl(dataItem.FillOption)
        });
    }

    private addScaleForTag(tagChartItem: TagChartItem): void {
        if (!this.scales)
            return;

        let scale: Scale;
        let text: string;

        if (tagChartItem.MeasurementUnit) {
            scale = this.scales.find((x) => x.measurementUnitId === tagChartItem.MeasurementUnitId);
            if (!scale) {
                scale = new Scale();
                scale.id = uuidv4();
                scale.showZero = true;
                scale.measurementUnit = tagChartItem.MeasurementUnit;
                scale.measurementUnitId = tagChartItem.MeasurementUnitId;
                scale.text = tagChartItem.MeasurementUnit.Symbol && tagChartItem.MeasurementUnit.Symbol.trim().length ? tagChartItem.MeasurementUnit.Symbol : tagChartItem.MeasurementUnit.Name;
                this.scales.push(scale);
            }
            tagChartItem.Scale = scale.text;
        } else {
            if (tagChartItem.GenericAssetTagData.DataType === 0) {
                text = "Yes/No"; // todo: translate
            } else {
                text = tagChartItem.GenericAssetTagData.Name;
            }

            scale = this.scales.find((x) => x.text === text);
            if (!scale) {
                scale = new Scale();
                scale.id = uuidv4();
                scale.showZero = true;
                scale.measurementUnitId = tagChartItem.MeasurementUnitId;
                scale.text = text; // todo: translate
                this.scales.push(scale);
            }
            tagChartItem.Scale = scale.text;
        }
    }

    public addScale() {
        const scale = new Scale();
        scale.id = uuidv4();
        scale.text = 'New scale';
        this.scales.push(scale);
    }

    public scaleCellClick(event: CellClickEvent) {
        if (!event.isEdited) {
            event.sender.editCell(event.rowIndex, event.columnIndex, this.createScaleFormGroup(event.dataItem));
        }
    }

    public scaleCellClose(event: CellCloseEvent) {
        const formControl: FormControl = event.formGroup.get(event.column.field);
        if (formControl) {
            if (!formControl.valid) {
                event.preventDefault();
            } else if (formControl.dirty) {
                event.dataItem[event.column.field] = formControl.value;
            }
        }
    }

    public createScaleFormGroup(dataItem: Scale) {
        return new FormGroup({
            min: new FormControl(dataItem.min),
            max: new FormControl(dataItem.max),
            text: new FormControl(dataItem.text, Validators.required),
            showZero: new FormControl(dataItem.showZero, Validators.required)
        });
    }

    removeScales() {
        if (this.scales && this.scales.length && this.selectedScaleKeys && this.selectedScaleKeys.length) {
            const ids: string[] = clone(this.selectedScaleKeys);
            ids.forEach((id: string) => {
                remove(this.scales, (item: Scale) => item.id == id);
            });
            this.selectedScaleKeys = [];
        }
    }

    openScaleMeasurementSelectionPopup(item: Scale) {
        const dialogRef = this.dialogService.open({
            content: MeasurementUnitSelectionPopupComponent,
            title: this.translate.get('i18n:LABEL.MEASUREMENT_UNIT_SELECTION'),
            minHeight: 250,
            minWidth: 450,
            height: '90vh',
            width: '90vw',
            actions: [{ text: this.translate.get('i18n:LABEL.CANCEL') }, { text: this.translate.get('i18n:LABEL.OK'), themeColor: 'primary' }],
            preventAction: (ev, dialog) => {
                if ((ev instanceof DialogCloseResult) || (ev.themeColor != 'primary'))
                    return false;

                const instance: MeasurementUnitSelectionPopupComponent = dialogRef.content.instance;
                if (instance.selectedEntities && instance.selectedEntities.length == 1) {
                    return false;
                }

                return true;
            }
        });

        const instance: MeasurementUnitSelectionPopupComponent = dialogRef.content.instance;
        instance.multiSelect = false;

        dialogRef.result.subscribe({
            next: (r: DialogResult) => {
                if (!(r instanceof DialogCloseResult)) {
                    if (instance.selectedEntities && instance.selectedEntities.length == 1) {
                        item.measurementUnit = instance.selectedEntities[0];
                        item.measurementUnitId = item.measurementUnit.Id;
                    }
                }
            }
        });
    }

    clearScaleMeasurementUnit(item: Scale) {
        item.measurementUnitId = null;
        item.measurementUnit = null;
    }

    getScaleMeasurementUnitDescription(item: Scale): string {
        if (item.measurementUnit) {
            if (item.measurementUnit.Symbol && item.measurementUnit.Symbol.length) {
                return item.measurementUnit.Name + ' (' + item.measurementUnit.Symbol + ')';
            } else {
                return item.measurementUnit.Name;
            }
        } else {
            return '';
        }
    }

    public addFixedValue() {
        const value = new FixedValue();
        value.id = uuidv4();
        value.name = 'New value';
        value.pane = 0;
        this.fixedValues.push(value);
    }

    public fixedValueCellClick(event: CellClickEvent) {
        if (!event.isEdited) {
            event.sender.editCell(event.rowIndex, event.columnIndex, this.createFixedValueFormGroup(event.dataItem));
        }
    }

    public fixedValueCellClose(event: CellCloseEvent) {
        const formControl: FormControl = event.formGroup.get(event.column.field);
        if (formControl) {
            if (!formControl.valid) {
                event.preventDefault();
            } else if (formControl.dirty) {
                event.dataItem[event.column.field] = formControl.value;
            }
        }
    }

    public createFixedValueFormGroup(dataItem: FixedValue) {
        return new FormGroup({
            name: new FormControl(dataItem.name, Validators.required),
            scale: new FormControl(dataItem.scale),
            pane: new FormControl(dataItem.pane),
            value: new FormControl(dataItem.value),
            color: new FormControl(dataItem.color)
        });
    }

    removeFixedValues() {
        if (this.fixedValues && this.fixedValues.length && this.selectedFixedValuesKeys && this.selectedFixedValuesKeys.length) {
            const ids: string[] = clone(this.selectedFixedValuesKeys);
            ids.forEach((id: string) => {
                remove(this.fixedValues, (item: FixedValue) => item.id == id);
            });
            this.selectedFixedValuesKeys = [];
        }
    }
}