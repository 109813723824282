import { BaseSvg } from '@ats/ats-platform-dashboard';
import { Selection, ScaleTime, ScaleLinear, ZoomTransform, min as d3Min, max as d3Max, select, scaleLinear, scaleTime, axisLeft, axisBottom, timeFormat, line, curveNatural, curveStep, curveStepAfter, curveStepBefore, curveLinear, area, pointer, zoom } from 'd3';;
import { find, min, max, orderBy, isNumber, first, inRange, filter } from "lodash-es";
import { DateTime } from 'luxon';
import { TagLineConfigItem } from '../components/tag-line-chart-widget-config/tag-line-chart-widget-config.component';
import { TagLineChartData } from '../domain/dataModels/lineChartData';
import { MeasurementUnit } from '../domain/entities/measurementUnit';
import { LineType } from '../domain/enums/lineType';


//https://observablehq.com/@mmattozzi/dual-y-axis-line-chart-with-mini-chart-below-using-the-same-do
export class LineChart extends BaseSvg<TagLineChartData[]> {

  private focus: Selection<SVGGElement, unknown, HTMLElement, unknown>;
  private xAxisWidth: number;
  private xScale: ScaleTime<number, number, never>;
  private yAxisHeight: number;
  private yAxisDataList: YAxisData[];
  private tagDataList: TagData[];
  private gLabels: Selection<SVGElement, unknown, HTMLElement, unknown>;
  private transform: ZoomTransform;

  public tagConfig: TagLineConfigItem[];
  public from: Date;
  public to: Date;
  public showHorizontalGrid: boolean;
  public showVerticalGrid: boolean;
  public showYAxisMeasurementUnit: boolean;

  public init(group: Selection<SVGGElement, unknown, HTMLElement, unknown>) {
    this.group = group;
  }

  public draw(data: TagLineChartData[]) {
    this.group.selectAll('*').remove();

    this.yAxisDataList = [];
    this.tagDataList = [];

    data.forEach((rawTagData: TagLineChartData) => {
      let tagConfig = find(this.tagConfig, x => x.tagId == rawTagData.TagId);
      if(!tagConfig){
        return;
      }
      tagConfig.tag = rawTagData.Tag;
      let id = rawTagData.MeasurementUnitId ?? rawTagData.TagId;
      let yAxisData = find(this.yAxisDataList, x => x.id == id);
      if (!yAxisData) {
        yAxisData = { id: id };
        this.yAxisDataList.push(yAxisData);

        if (rawTagData.MeasurementUnitId) {
          yAxisData.label = rawTagData.MeasurementUnit?.Symbol ?? rawTagData.MeasurementUnit.Name;
        } else {
          yAxisData.label = rawTagData.Tag?.Name;
        }
      }

      let tagData = this.tranformTagValues(tagConfig, rawTagData);
      this.tagDataList.push(tagData);

      let tagMin = (rawTagData.Tag?.DataType === 0) ? 0 : d3Min(tagData.values, x => x.MinimumValue ?? x.Value);
      yAxisData.min = min([yAxisData.min, tagConfig.minimumValue, tagMin]);

      let tagMax = (rawTagData.Tag?.DataType === 0) ? 1 : d3Max(tagData.values, x => x.MaximumValue ?? x.Value);
      yAxisData.max = max([yAxisData.max, tagConfig.maximumValue, tagMax]);
    });

    this.yAxisDataList = orderBy(this.yAxisDataList, x => x.label);

    this.xAxisWidth = this.width - this.left - this.right;
    this.yAxisHeight = (this.height - this.top - this.bottom + 20) / this.yAxisDataList.length;

    const svg = this.getParentSVG(this.group.node());
    const d3svg = select(svg);
    var defs: Selection<SVGDefsElement, unknown, null, undefined>;
    if (d3svg.select('defs').empty()) {
      defs = d3svg.append('defs');
    } else {
      defs = d3svg.select('defs');
      defs.select('#zoom-clip').remove();
      defs.select('#zoom-clip-labels').remove();
    }
    defs.append('clipPath').attr('id', 'zoom-clip')
      .append('rect').attr('x', 0).attr('y', 0).attr('width', this.xAxisWidth).attr('height', this.height);
    defs.append('clipPath').attr('id', 'zoom-clip-labels')
      .append('rect').attr('x', -30).attr('y', 0).attr('width', this.xAxisWidth + 60).attr('height', this.bottom);

    const g = this.group.append('g')
      .attr('clip-path', 'url(#zoom-clip-labels)')
      .attr('transform', 'translate(' + (this.left) + ',' + (this.height - this.bottom) + ')');

    this.gLabels = g.append('g');

    this.xScale = scaleTime().domain([this.from, this.to]).range([0, this.xAxisWidth]);

    this.yAxisDataList.forEach((yAxisData, index) => {
      yAxisData.top = this.top + index * this.yAxisHeight;
      yAxisData.g = this.group.append('g').attr('transform', 'translate(' + this.left + ',' + yAxisData.top + ')');
      yAxisData.scale = scaleLinear().domain([yAxisData.min, yAxisData.max]).range([this.yAxisHeight - 20, 0]);
      this.drawYAxis(yAxisData);

      const gZoom = yAxisData.g.append('g').attr('clip-path', 'url(#zoom-clip)');
      yAxisData.gX = gZoom.append('g');
      yAxisData.gArea = gZoom.append('g');
      yAxisData.gLine = gZoom.append('g');
      this.drawXAxis(yAxisData, index != this.yAxisDataList.length - 1);
    });

    this.tagDataList.forEach(tagData => {
      const yAxisData = find(this.yAxisDataList, x => x.id == tagData.groupId);
      if (tagData.aggregate && tagData.showRange) {
        this.drawArea(yAxisData, tagData);
      }
      this.drawLine(yAxisData, tagData);
    });

    this.drawFocus(this.xScale);

    this.applyZoom();
  }

  private tranformTagValues(tagConfig: TagLineConfigItem, dataObject: TagLineChartData): TagData {
    if (dataObject.Aggregate === true && dataObject.Tag.DataType != 0) {
      return {
        id: dataObject.TagId,
        groupId: dataObject.MeasurementUnitId ?? dataObject.TagId,
        measurementUnit: dataObject.MeasurementUnit,
        color: dataObject.DefaultColor,
        aggregate: true,
        lineType: tagConfig.lineType,
        showRange: tagConfig.showRange,
        values: dataObject.Values.map((value) => ({
          TimeStamp: new Date(value.TimeStamp),
          MinimumValue: value.MinimumValue,
          Value: value.MeanValue,
          MaximumValue: value.MaximumValue,
          Calculated: !value.Count
        })),
      };
    }
    else if (dataObject.Tag.DataType === 0) {
      //Boolean
      return {
        id: dataObject.TagId,
        groupId: dataObject.MeasurementUnitId ?? dataObject.TagId,
        measurementUnit: dataObject.MeasurementUnit,
        color: dataObject.DefaultColor,
        aggregate: dataObject.Aggregate === true ? true : false,
        lineType: tagConfig.lineType,
        showRange: tagConfig.showRange,
        values: dataObject.Values.map((value) => ({
          TimeStamp: new Date(value.TimeStamp),
          Value: value.BooleanValue ? 1 : 0,
          MinimumValue: null,
          MaximumValue: null,
          Calculated: false
        })),
      };
    }
    else if (dataObject.Tag.DataType === 1 || dataObject.Tag.DataType === 2 || dataObject.Tag.DataType === 8 || dataObject.Tag.DataType === 9) {
      //Short Integer UnsignedSHort UnsignedInteger
      return {
        id: dataObject.TagId,
        groupId: dataObject.MeasurementUnitId ?? dataObject.TagId,
        measurementUnit: dataObject.MeasurementUnit,
        color: dataObject.DefaultColor,
        aggregate: false,
        lineType: tagConfig.lineType,
        showRange: tagConfig.showRange,
        values: dataObject.Values.map((value) => ({
          TimeStamp: new Date(value.TimeStamp),
          Value: value.IntegerValue,
          MinimumValue: null,
          MaximumValue: null,
          Calculated: false
        })),
      };
    }
    else if (dataObject.Tag.DataType === 3) {
      //Float
      return {
        id: dataObject.TagId,
        groupId: dataObject.MeasurementUnitId ?? dataObject.TagId,
        measurementUnit: dataObject.MeasurementUnit,
        color: dataObject.DefaultColor,
        aggregate: false,
        lineType: tagConfig.lineType,
        showRange: tagConfig.showRange,
        values: dataObject.Values.map((value) => ({
          TimeStamp: new Date(value.TimeStamp),
          Value: value.FloatingPointValue,
          MinimumValue: null,
          MaximumValue: null,
          Calculated: false
        })),
      };
    }

    return undefined;
  }

  private drawYAxis(yAxisData: YAxisData) {
    const numberOfTicks = Math.floor(yAxisData.scale.range()[0] / 20) + 1;
    yAxisData.scale.nice(numberOfTicks);

    const yAxis = axisLeft(yAxisData.scale).ticks(numberOfTicks);
    let gYAxis = yAxisData.g.append('g').call(yAxis);

    if (this.showYAxisMeasurementUnit && yAxisData.label) {
      const yAxisWidth = gYAxis.node().getBBox().width;
      const yAxisHeight = gYAxis.node().getBBox().height;

      yAxisData.gLabel = yAxisData.g.append('text')
        .attr('text-anchor', 'middle')
        .text(yAxisData.label)
        .call(this.wrap, yAxisHeight - yAxisHeight * 0.2);

      //const labelWidth = yAxisData.gLabel.node().getBBox().width;
      const labelHeight = yAxisData.gLabel.node().getBBox().height;
      yAxisData.gLabel
        .attr('transform', 'translate(' + ( - labelHeight - yAxisWidth ) + ',' + (yAxisHeight / 2 ) +')rotate(-90)');
    }

    if (this.showHorizontalGrid) {
      yAxisData.scale.ticks(numberOfTicks).forEach((tickValue, index) => {
        if (index > 0) {
          yAxisData.g.append('line')
            .attr('x1', 0)
            .attr('x2', this.xAxisWidth)
            .attr('y1', yAxisData.scale(tickValue))
            .attr('y2', yAxisData.scale(tickValue))
            .attr('stroke', 'lightgray');
        }
      });
    }
  }

  private drawXAxis(yAxisData: YAxisData, hideLabels: boolean) {
    const numberOfXTicks = (this.width - this.left - this.right) / 100;
    this.xScale.nice(numberOfXTicks);

    const xAxis = axisBottom(this.xScale)
      .ticks(numberOfXTicks)
      .tickSizeOuter(0);

    xAxis.tickFormat(x => '');

    yAxisData.gX.append('g')
      .attr('transform', 'translate(0,' + yAxisData.scale.range()[0] + ')')
      .call(xAxis);

    if (this.showVerticalGrid) {
      this.xScale.ticks(numberOfXTicks).forEach((tickValue, index) => {
        if (index > 0) {
          yAxisData.gX.append('line')
            .attr('x1', this.xScale(tickValue))
            .attr('x2', this.xScale(tickValue))
            .attr('y1', yAxisData.scale.range()[0])
            .attr('y2', yAxisData.scale.range()[1])
            .attr('stroke', 'lightgray');
        }
      });
    }

    if (!hideLabels) {
      const format = timeFormat('%d-%m-%Y %H:%M:%S');

      this.xScale.ticks(numberOfXTicks).forEach((tickValue) => {
        const label = format(tickValue).split(' ');
        const text = this.gLabels.append('g')
          .attr('transform', 'translate(' + this.xScale(tickValue) + ',0)')
          .append('text')
          .attr('y', 15)
          .attr('x', -25)
          .attr('font-size', 10)
          .attr('font-family', 'sans-serif')
        text.append('tspan').text(label[0]);
        text.append('tspan').text(label[1])
          .attr('y', 25)
          .attr('dx', '-4.5em');
      });
    }
  }

  private drawLine(yAxisData: YAxisData, tagData: TagData) {
    const lineDrawer = line<TagValue>()
      .defined((d: TagValue) => isNumber(d.Value))
      .x((d: any) => this.xScale(d.TimeStamp))
      .y((d: any) => yAxisData.scale(d.Value));

    switch (tagData.lineType) {
      case LineType.Natural:
        lineDrawer.curve(curveNatural);
        break;
      case LineType.StepLine:
        lineDrawer.curve(curveStep);
        break;
      case LineType.StepLineAfter:
        lineDrawer.curve(curveStepAfter);
        break;
      case LineType.StepLineBefore:
        lineDrawer.curve(curveStepBefore);
        break;
      case LineType.Linear:
      default:
        lineDrawer.curve(curveLinear);
        break;
    }

    yAxisData.gLine.append('path')
      .attr('class', 'zoom-x')
      .attr('stroke', tagData.color)
      .attr('fill', 'none')
      .attr('mix-blend-mode', 'multiply')
      .attr('d', lineDrawer(tagData.values));
  }

  private drawArea(yAxisData: YAxisData, tagData: TagData) {
    const areaDrawer = area<TagValue>()
      .defined(d => isNumber(d.Value) && isNumber(d.MinimumValue) && isNumber(d.MaximumValue))
      .x((d: TagValue) => this.xScale(d.TimeStamp))
      .y0((d: TagValue) => yAxisData.scale(d.MinimumValue))
      .y1((d: TagValue) => yAxisData.scale(d.MaximumValue));

    switch (tagData.lineType) {
      case LineType.Natural:
        areaDrawer.curve(curveNatural);
        break;
      case LineType.StepLine:
        areaDrawer.curve(curveStep);
        break;
      case LineType.StepLineAfter:
        areaDrawer.curve(curveStepAfter);
        break;
      case LineType.StepLineBefore:
        areaDrawer.curve(curveStepBefore);
        break;
      case LineType.Linear:
      default:
        areaDrawer.curve(curveLinear);
        break;
    }

    yAxisData.gArea.append('path')
      .attr('class', 'zoom-x')
      .attr('fill', tagData.color)
      .attr('opacity', 0.1)
      .attr('stroke', 'none')
      .attr('d', areaDrawer(tagData.values));
  }

  private drawFocus(xScale: ScaleTime<number, number, never>) {

    this.focus = this.group.append('g')
      .attr('visibility', 'hidden');

    this.focus.append('line')
      .attr('stroke', 'black')
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('y1', this.top)
      .attr('y2', this.height - this.bottom);

    const overlay = this.group.append('rect')
      .attr('width', this.width - this.left - this.right)
      .attr('height', this.height - this.top - this.bottom)
      .attr('fill', 'white')
      .attr('fill-opacity', 0)
      .attr('transform', 'translate(' + this.left + ',' + this.top + ')');

    overlay.on('mousemove', (e: any) => {
      let d3Pointer = pointer(e);
      let focusX = d3Pointer[0];

      if (this.transform)
        focusX = d3Pointer[0] - this.transform.x;

      if (inRange(focusX, xScale.range()[0], xScale.range()[1])) {

        this.focus.selectAll('.point').remove();

        this.focus
          .attr('transform', 'translate(' + (this.left + d3Pointer[0]) + ',0)')
          .attr('visibility', 'visible');

        const timeStamp = <Date>xScale.invert(focusX);
        const time = timeStamp.getTime();
        const timeStampRange = [<Date>xScale.invert(focusX - 1), <Date>xScale.invert(focusX + 1)];

        var tooltipContainer = document.getElementById("tooltipContainer");
        //global style
        tooltipContainer.style.position = 'absolute';
        tooltipContainer.style.borderStyle = 'solid';
        tooltipContainer.style.borderColor = 'lightgrey';
        tooltipContainer.style.borderWidth = '1px';
        tooltipContainer.style.padding = '5px';
        tooltipContainer.style.background = 'white';
        tooltipContainer.style.opacity = '0.95';
        tooltipContainer.style.fontSize = '12px';
        
        //specific style
        tooltipContainer.style.display = 'none';

        var tooltipHtml = '';
        tooltipHtml += '<div style="text-align: center"><b>' + DateTime.fromJSDate(timeStamp).toFormat('dd-LL-yyyy HH:mm') + '</b></div>';

        tooltipHtml += '<table>';

        this.tagDataList.forEach(tagData => {
          const values = filter(tagData.values, (x: TagValue) => isNumber(x.Value) && x.TimeStamp >= timeStampRange[0] && x.TimeStamp <= timeStampRange[1]);
          const value = first(orderBy(values, [x => Math.abs(time - x.TimeStamp.getTime()), x => x.TimeStamp.getTime()]));
          if (value) {

            tooltipContainer.style.display = null;
            this.handleTooltipPosition(tooltipContainer,e);

            const yAxisData = find(this.yAxisDataList, (x: YAxisData) => x.id == tagData.groupId);

            this.focus.append('circle')
              .attr('class', 'point')
              .attr('cx', 0)
              .attr('cy', yAxisData.top + yAxisData.scale(value.Value))
              .attr('r', 3)
              .attr('stroke', tagData.color)
              .attr('stroke-width', 1)
              .attr('fill', value.Calculated ? 'white' : tagData.color);

            tooltipContainer.innerHTML += '<tr>';
            tooltipHtml += '<tr>';

            var tag = this.tagConfig.filter(x => x.tagId === tagData.id)[0].tag;
            var newStyle = 'height: 15px; width: 15px;border:1px solid WhiteSmoke; background-color: ' + tagData.color;

            tooltipHtml += '<td style="width: 20px;">';
            tooltipHtml += "<div style='" + newStyle + "'></div>";
            tooltipHtml += '</td>'

            tooltipHtml += '<td style="white-space:nowrap;">';
            tooltipHtml += "<div>" + tag.Asset.Path + ' ' + tag.Name + "</div>";
            tooltipHtml += '</td>'

            tooltipHtml += '<td style="white-space:nowrap;">';
            const measurementUnit = find(this.tagDataList, (x: TagData) => x.id === tagData.id).measurementUnit;
            if (measurementUnit) {
              tooltipHtml += "<div>" + value.Value.toFixed(2) + ' ' + yAxisData.label + "</div>";
            } else {
              tooltipHtml += "<div>" + value.Value.toFixed(2) + "</div>";
            }
            tooltipHtml += '</td>'

            tooltipHtml += '</tr>';
          }
        })

        // tooltipContainer.innerHTML += '</table>';
        tooltipHtml += '</table>';

        tooltipContainer.innerHTML = tooltipHtml;
      } else {
        this.focus
          .attr('visibility', 'hidden');
      }
    });

    overlay.on('mouseout', (e: any) => {
      this.focus
        .attr('visibility', 'hidden');

      var tooltipContainer = document.getElementById("tooltipContainer");
      tooltipContainer.style.display = 'none';
    });
  }

  private applyZoom() {
    const d3Zoom = zoom().scaleExtent([1, 10]).translateExtent([[0, 0], [this.width, this.height]]);

    const svg = this.getParentSVG(this.group.node());

    d3Zoom.on('zoom', (e: any) => {
      this.transform = e.transform;

      this.xScale.range([0, this.xAxisWidth * this.transform.k]);

      if (this.gLabels) {
        this.gLabels.selectAll('g').remove();
        this.gLabels.attr('transform', 'translate(' + (this.transform.x) + ',0)');
      }

      this.yAxisDataList.forEach((yAxisData, index) => {
        yAxisData.gX.selectAll('*').remove();
        yAxisData.gArea.selectAll('*').remove();
        yAxisData.gLine.selectAll('*').remove();

        yAxisData.gX.attr('transform', 'translate(' + (this.transform.x) + ',0)');
        yAxisData.gArea.attr('transform', 'translate(' + (this.transform.x) + ',0)');
        yAxisData.gLine.attr('transform', 'translate(' + (this.transform.x) + ',0)');

        this.drawXAxis(yAxisData, index != this.yAxisDataList.length - 1);
      })

      this.tagDataList.forEach(tagData => {
        const yAxisData = find(this.yAxisDataList, x => x.id == tagData.groupId);
        if (tagData.aggregate && tagData.showRange) {
          this.drawArea(yAxisData, tagData);
        }
        this.drawLine(yAxisData, tagData);
      });

    });

    if (svg) {
      select(svg).call(d3Zoom);
    }
  }

  private getParentSVG(e: SVGElement) {
    if (e.parentNode) {
      if (e.parentNode instanceof SVGSVGElement) {
        return e.parentNode;
      } else if (e.parentNode instanceof SVGElement) {
        return this.getParentSVG(e.parentNode);
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}

class YAxisData {
  id: string;
  min?: number;
  max?: number;
  label?: string;
  top?: number;
  g?: Selection<SVGGElement, unknown, HTMLElement, unknown>;
  gLine?: Selection<SVGGElement, unknown, HTMLElement, unknown>;
  gArea?: Selection<SVGGElement, unknown, HTMLElement, unknown>;
  gX?: Selection<SVGGElement, unknown, HTMLElement, unknown>;
  gLabel?: Selection<SVGGElement, unknown, HTMLElement, unknown>;
  scale?: ScaleLinear<number, number, never>;
}

class TagData {
  id: string;
  groupId: string;
  measurementUnit: MeasurementUnit;
  color: string;
  aggregate: boolean;
  showRange: boolean;
  lineType: LineType;
  values: TagValue[];
}

class TagValue {
  TimeStamp: Date;
  Value: number;
  MinimumValue?: number;
  MaximumValue?: number;
  Calculated: boolean;
}
