import { Component, Inject, Injector } from '@angular/core';
import { keyBy, flatten, find, sortedUniq, map, forEach, sortedUniqBy } from "lodash-es";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiQueryOptions, ApiService, CoreService, DashboardWidgetDocument, DataSource, DataSourceConfig, DataSourceType, DATASOURCETYPES, EntitySelectionPopupSettings, TranslateService, WidgetConfigComponent } from '@ats/ats-platform-dashboard';
import { DAYFILTERKEYS } from "../../domain/enums/dayFilterKeys";
import { BuildingEnergyValueType, BUILDING_ENERGY_VALUE_TYPES } from '../../domain/enums/building-energy-value-types';
import { ENERGY_MANAGEMENT_CATEGORIES, ENERGY_MANAGEMENT_VALUE_CATEGORIES } from '../../domain/enums/energy-management-categories';

@Component({
  selector: 'ats-smart-tool-heat-map-widget-config',
  templateUrl: './heat-map-widget-config.component.html',
  styleUrls: ['./heat-map-widget-config.component.scss']
})
export class HeatMapWidgetConfigComponent implements WidgetConfigComponent {

  public dataSourceSelectionPopupSettings: EntitySelectionPopupSettings;
  public dataSourceFormatter = (dataSource: DataSource) => dataSource ? dataSource.Folder.Path + ' / ' + dataSource.Name : null;
  public fg: FormGroup;
  public formGroup: FormGroup;

  public valueCategories: { Key: string, Display: string }[] = [];
  public valueCategoriesLoading: boolean = false;
  public valueSubcategories: { Key: number, Display: string }[];
  public timeOfDayOrientations: { Key: string, Display: string }[];

  private dataSourceTypes: { [type: string]: DataSourceType };

  constructor(public core: CoreService, private api: ApiService, public translate: TranslateService, @Inject(DATASOURCETYPES) dataSourceTypes: DataSourceType[][], private injector: Injector) {

    this.dataSourceTypes = keyBy(flatten(dataSourceTypes), (type: DataSourceType) => type.name);
    this.timeOfDayOrientations = [
      { Key: 'horizontal', Display: translate.get('i18n:HEATMAP.TIME_OF_DAY_ORIENTATION.HORIZONTAL')},
      { Key: 'vertical', Display: translate.get('i18n:HEATMAP.TIME_OF_DAY_ORIENTATION.VERTICAL')}
    ];

    this.formGroup = new FormGroup({
      title: new FormControl(''),
      widgetType: new FormControl(translate.get('i18n:HEAT_MAP_WIDGET')),
      dataSourceId: new FormControl(null, Validators.required),
      valueCategory: new FormControl(null, Validators.required),
      valueSubcategory: new FormControl(null, Validators.required),
      leftMargin: new FormControl(null, Validators.compose([Validators.required, Validators.min(1)])),
      rightMargin: new FormControl(null, Validators.compose([Validators.required, Validators.min(1)])),
      topMargin: new FormControl(null, Validators.compose([Validators.required, Validators.min(1)])),
      bottomMargin: new FormControl(null, Validators.compose([Validators.required, Validators.min(1)])),
      numberOfDecimals: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
      hideLabels: new FormControl(true),
      posMinColor: new FormControl(null),
      posMaxColor: new FormControl(null),
      negMinColor: new FormControl(null),
      negMaxColor: new FormControl(null),
      timeOfDayOrientation: new FormControl(null, Validators.required)
    });

    this.dataSourceSelectionPopupSettings = {
      title: translate.get('i18n:SELECT_DATASOURCE'),
      entitySet: 'DataSources',
      filter: {
        logic: 'or',
        filters: [
          { field: 'Type', operator: 'eq', value: 'buildingEnergy' },
          { field: 'Type', operator: 'eq', value: 'productionConsumption' },
          { field: 'Type', operator: 'eq', value: 'energyManagement' },
          { field: 'Type', operator: 'eq', value: 'deviceTopology' },
          { field: 'Type', operator: 'eq', value: 'solarPerformance' },
        ]
      },
      includes: 'Configs,Folder',
      sort: [{ field: 'Folder.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
      multiSelect: false,
      selectionRequired: true,
      columns: [{ field: 'Folder.Path', title: this.translate.get('i18n:FOLDER.PATH'), filterable: true }, { field: 'Name', title: translate.get('i18n:DATASOURCE.NAME'), filterable: true }],
      info: translate.get('i18n:WIDGET.SUPPORTED_DATATYPES') + ': ' + translate.get('i18n:DATASOURCE.BUILDINGENERGY') + ', ' + translate.get('i18n:DATASOURCE.PRODUCTION_CONSUMPTION')
       + ', ' + translate.get('i18n:DATASOURCE.ENERGY_MANAGEMENT') + ', ' + translate.get('i18n:DATASOURCE.DEVICE_TOPOLOGY') + ', ' + translate.get('i18n:DATASOURCE.SOLAR_PERFORMANCE')
    };
  }

  public onDataSourceChange = (dataSource: DataSource) => {
    if (dataSource) {

      const setValues = dataSource.Id !== this.formGroup.get('dataSourceId').value;

      switch (dataSource.Type)
      {
        case 'buildingEnergy':
          this.loadBuildingEnergyItems(dataSource, setValues);
          break;
        case 'productionConsumption':
          this.loadProductionConsumptionItems(dataSource, setValues);
          break;
        case 'energyManagement':
          this.loadEnergyManagementItems(dataSource, setValues);
          break;
        case 'deviceTopology':
          this.loadDeviceTopologyItems(dataSource, setValues);
          break;
        case 'solarPerformance':
          this.loadSolarPerformanceItems(dataSource, setValues);
          break;
      }
    } else {
      this.valueCategories = [];
      this.valueSubcategories = [];
    }
  };

  private loadBuildingEnergyItems(dataSource: DataSource, setValues: boolean) {
    const arrayToRemove = [
      BuildingEnergyValueType.RelativeEmissionToDegreeDays,
      BuildingEnergyValueType.RelativeEmissionToFloorSurfaceAndDegreeDays,
      BuildingEnergyValueType.RelativeEnergyToDegreeDays,
      BuildingEnergyValueType.RelativeEnergyToFloorSurfaceAndDegreeDays,
      BuildingEnergyValueType.RelativePrimaryEnergyToDegreeDays,
      BuildingEnergyValueType.RelativePrimaryEnergyToFloorSurfaceAndDegreeDays];
    this.valueSubcategories = this.core.translateObjectArray(BUILDING_ENERGY_VALUE_TYPES, 'Display').filter((el) => !arrayToRemove.includes(el.Key));
    if (setValues) this.formGroup.get('valueSubcategory').setValue(this.valueSubcategories[0]?.Key);
    this.formGroup.get('valueSubcategory').setValidators(Validators.required);
    this.formGroup.get('valueSubcategory').enable();

    const dataSourceType: DataSourceType = this.dataSourceTypes[dataSource.Type];
    if (dataSourceType) {
      const loader = this.injector.get(dataSourceType.loader);
      this.valueCategoriesLoading = true;
      loader.loadItems(dataSource).subscribe({
        next: (items: { id: string; name: string }[]) => {
          this.valueCategoriesLoading = false;
          this.valueCategories = map(items, x => { return { Key: x.id, Display: x.name } });
          if (setValues) this.formGroup.get('valueCategory').setValue(this.valueCategories[0]?.Key);
        }, error: (err: any) => {
          this.valueCategoriesLoading = false;
          this.valueCategories = [];
          if (setValues) this.formGroup.get('valueCategory').setValue(this.valueCategories[0]?.Key);
          this.core.showErrorDetail(err);
        }
      });
    } else {
      this.valueCategories = [];
      if (setValues) this.formGroup.get('valueCategory').setValue(this.valueCategories[0]?.Key);
    }
  }

  private loadProductionConsumptionItems(dataSource: DataSource, setValues: boolean) {
    this.valueSubcategories = [];
    if (setValues) this.formGroup.get('valueSubcategory').setValue(this.valueSubcategories[0]?.Key);
    this.formGroup.get('valueSubcategory').clearValidators();
    this.formGroup.get('valueSubcategory').disable();

    this.valueCategoriesLoading = true;
    this.api.executeFunction<DataSource>('DataSources', 'GetWithConfig', dataSource.Id, new ApiQueryOptions({ include: 'Configs' })).subscribe({
      next: (dataSource: DataSource) => {

        const tagsConfig = find(dataSource.Configs, (x: DataSourceConfig) => x.Name === 'Tags');
        var tags = tagsConfig ? tagsConfig.Data : []; 

        this.valueCategories = sortedUniqBy(map(tags, t => { return { Key: 'Total'+ t.Category, Display: t.Category }}), x => x.Key);

        forEach(tags, t => {
          this.valueCategories.push({ Key: t.Tag.Id, Display: t.Tag.Asset.Path + ' / ' + t.Tag.Name });
        });

        if (setValues) this.formGroup.get('valueCategory').setValue(this.valueCategories[0]?.Key);
        this.valueCategoriesLoading = false;
      }, error: (error: any) => {
        this.valueCategories = [];
        if (setValues) this.formGroup.get('valueCategory').setValue(this.valueCategories[0]?.Key);
        this.valueCategoriesLoading = false;
        this.core.showErrorDetail(error)
      }
    });
  }

  private loadEnergyManagementItems(dataSource: DataSource, setValues: boolean) {
    this.valueSubcategories = [];
    if (setValues) this.formGroup.get('valueSubcategory').setValue(this.valueSubcategories[0]?.Key);
    this.formGroup.get('valueSubcategory').clearValidators();
    this.formGroup.get('valueSubcategory').disable();

    this.valueCategories = this.core.translateObjectArray([
      { Key: 8, Name: 'PriceEnergyMix', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICEENERGYMIX' },
      { Key: 9, Name: 'PriceElectricityMix', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICEELECTRICITYMIX' },
      { Key: 10, Name: 'PriceElectricityMixWithoutEV', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICEELECTRICITYMIXWITHOUTEV' }, 
      { Key: 11, Name: 'PriceGridConsumption', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICEGRIDCONSUMPTION' }, 
      { Key: 12, Name: 'PriceGridInjection', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICEGRIDINJECTION' }, 
      { Key: 13, Name: 'PriceGas', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICEGAS' }, 
      { Key: 14, Name: 'PriceDiesel', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICEDIESEL' }, 
      { Key: 15, Name: 'PriceEV', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICEEV' }, 
      { Key: 16, Name: 'PriceSaved', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICESAVED' },
      { Key: 17, Name: 'EnergyEnergyMix', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYENERGYMIX' },
      { Key: 18, Name: 'EnergyElectricityMix', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYELECTRICITYMIX' },
      { Key: 19, Name: 'EnergyElectricityMixWithoutEV', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYELECTRICITYMIXWITHOUTEV' },
      { Key: 20, Name: 'EnergySolar', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYSOLAR' },
      { Key: 21, Name: 'EnergyWind', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYWIND' },
      { Key: 22, Name: 'EnergyGridConsumption', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYGRIDCONSUMPTION' }, 
      { Key: 23, Name: 'EnergyGridInjection', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYGRIDINJECTION' },
      { Key: 24, Name: 'EnergyGas', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYGAS' },
      { Key: 25, Name: 'EnergyDiesel', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYDIESEL' },
      { Key: 26, Name: 'EnergyEV', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYEV' },
      { Key: 27, Name: 'EnergySelfConsumption', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYSELFCONSUMPTION' },
      { Key: 28, Name: 'RatioSelfConsumption', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.RATIOSELFCONSUMPTION' },
      { Key: 30, Name: 'EnergyBatteryEnergyIn', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYBATTERYENERGYIN' }, 
      { Key: 31, Name: 'EnergyBatteryEnergyOut', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYBATTERYENERGYOUT' },
    ], 'Display');
    if (setValues) this.formGroup.get('valueCategory').setValue(this.valueCategories[0]?.Key);

  }

  private loadDeviceTopologyItems(dataSource: DataSource, setValues: boolean) {
    this.valueSubcategories = [
      { 'Key': 0, 'Display': this.translate.get('i18n:DATASOURCE.DEVICE_TOPOLOGY.ENERGYIN')},
      { 'Key': 1, 'Display': this.translate.get('i18n:DATASOURCE.DEVICE_TOPOLOGY.ENERGYOUT')}];
      if (setValues) this.formGroup.get('valueSubcategory').setValue(this.valueSubcategories[0]?.Key);
    this.formGroup.get('valueSubcategory').setValidators(Validators.required);
    this.formGroup.get('valueSubcategory').enable();

    this.valueCategoriesLoading = true;
    this.api.executeFunction<DataSource>('DataSources', 'GetWithConfig', dataSource.Id, new ApiQueryOptions({ include: 'Configs' })).subscribe({
      next: (dataSource: DataSource) => {

        const topologyConfig = find(dataSource.Configs, (x: DataSourceConfig) => x.Name === 'Topology');
        var topology = topologyConfig ? topologyConfig.Data : [];

        this.valueCategories = [];

        forEach(topology, t => {
          if (t.EnergyInTagId || t.EnergyOutTagId)
            this.valueCategories.push({ Key: t.Name, Display: t.Name });
        });

        if (setValues) this.formGroup.get('valueCategory').setValue(this.valueCategories[0]?.Key);
        this.valueCategoriesLoading = false;
      }, error: (error: any) => {
        this.valueCategories = [];
        if (setValues) this.formGroup.get('valueCategory').setValue(this.valueCategories[0]?.Key);
        this.valueCategoriesLoading = false;
        this.core.showErrorDetail(error)
      }
    });
  }

  private loadSolarPerformanceItems(dataSource: DataSource, setValues: boolean) {
    this.valueSubcategories = [
      { 'Key': 0, 'Display': this.translate.get('i18n:DATASOURCE.SOLAR_PERFORMANCE.ACTUAL_ENERGY')},
      { 'Key': 1, 'Display': this.translate.get('i18n:DATASOURCE.SOLAR_PERFORMANCE.ESTIMATED_ENERGY')},
      { 'Key': 2, 'Display': this.translate.get('i18n:DATASOURCE.SOLAR_PERFORMANCE.ACTUAL_SPECIFIC_ENERGY')},
      { 'Key': 3, 'Display': this.translate.get('i18n:DATASOURCE.SOLAR_PERFORMANCE.ESTIMATED_SPECIFIC_ENERGY')}
    ];

    if (setValues) this.formGroup.get('valueSubcategory').setValue(this.valueSubcategories[0]?.Key);
    this.formGroup.get('valueSubcategory').setValidators(Validators.required);
    this.formGroup.get('valueSubcategory').enable();

    this.valueCategoriesLoading = true;
    this.api.executeFunction<DataSource>('DataSources', 'GetWithConfig', dataSource.Id, new ApiQueryOptions({ include: 'Configs' })).subscribe({
      next: (dataSource: DataSource) => {

        const assetsConfig = find(dataSource.Configs, (x: DataSourceConfig) => x.Name === 'Assets');
        var assets = assetsConfig ? assetsConfig.Data : [];

        this.valueCategories = [{ Key: 'total', Display: 'i18n:COMMON.TOTAL' }, ...map(assets, a => { return { Key: a.AssetId, Display: a.Asset.Path } })]

        if (setValues) this.formGroup.get('valueCategory').setValue(this.valueCategories[0]?.Key);
        this.valueCategoriesLoading = false;
      }, error: (error: any) => {
        this.valueCategories = [];
        if (setValues) this.formGroup.get('valueCategory').setValue(this.valueCategories[0]?.Key);
        this.valueCategoriesLoading = false;
        this.core.showErrorDetail(error)
      }
    });
  }

  public isValid(): boolean {
    this.formGroup.markAllAsTouched();
    return this.formGroup.valid;
  }

  public getConfig(): any {
    return {
      title: this.formGroup.get('title').value,
      dataSourceId: this.formGroup.get('dataSourceId').value,
      valueCategory: this.formGroup.get('valueCategory').value,
      valueSubcategory: this.formGroup.get('valueSubcategory').value,
      leftMargin: this.formGroup.get('leftMargin').value,
      rightMargin: this.formGroup.get('rightMargin').value,
      topMargin: this.formGroup.get('topMargin').value,
      bottomMargin: this.formGroup.get('bottomMargin').value,
      numberOfDecimals: this.formGroup.get('numberOfDecimals').value,
      hideLabels: this.formGroup.get('hideLabels').value,
      posMinColor: this.formGroup.get('posMinColor').value,
      posMaxColor: this.formGroup.get('posMaxColor').value,
      negMaxColor: this.formGroup.get('negMaxColor').value,
      negMinColor: this.formGroup.get('negMinColor').value,
      timeOfDayOrientation: this.formGroup.get('timeOfDayOrientation').value
    };
  }

  public getDocuments(): DashboardWidgetDocument[] {
    return null;
  }

  public setData(config: any, documents: DashboardWidgetDocument[]) {
    this.formGroup.get('title').setValue(config?.title ?? '');
    this.formGroup.get('dataSourceId').setValue(config?.dataSourceId);
    this.formGroup.get('valueCategory').setValue(config?.valueCategory);
    this.formGroup.get('valueSubcategory').setValue(config?.valueSubcategory);
    this.formGroup.get('leftMargin').setValue(config?.leftMargin);
    this.formGroup.get('rightMargin').setValue(config?.rightMargin);
    this.formGroup.get('topMargin').setValue(config?.topMargin);
    this.formGroup.get('bottomMargin').setValue(config?.bottomMargin);
    this.formGroup.get('numberOfDecimals').setValue(config?.numberOfDecimals);
    this.formGroup.get('hideLabels').setValue(config?.hideLabels);
    this.formGroup.get('posMinColor').setValue(config?.posMinColor ?? '#e1efd9');
    this.formGroup.get('posMaxColor').setValue(config?.posMaxColor ?? '#71ae48');
    this.formGroup.get('negMaxColor').setValue(config?.negMaxColor ?? '#dae1f4');
    this.formGroup.get('negMinColor').setValue(config?.negMinColor ?? '#4371c4');
    this.formGroup.get('timeOfDayOrientation').setValue(config?.timeOfDayOrientation ?? 'vertical');
  }

}
