import { AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, OnInit, Type, ViewChild } from "@angular/core";
import { HubConnection } from "@aspnet/signalr";
import { DashboardService, DataSourceInstance, WidgetComponent, WidgetInstance, SvgContainerComponent, WidgetUpdater, ItemValueChangedEventArgs, DataSource, ApiService, ApiQueryOptions, DataSourceType, CoreService, DATASOURCETYPES, TranslateService } from "@ats/ats-platform-dashboard";
import { Subscription } from "rxjs";

import { find, keyBy, flatten, startCase, isEmpty } from "lodash-es";
import { scaleOrdinal, schemeCategory10, select } from 'd3';;
import { ProductionConsumptionData } from "../../domain/entities/productionConsumptionData";
import { LegendData } from "../../domain/dataModels/legendData";
import { BuildingEnergyData } from "../../domain/dataModels/buildingEnergyData";
import { TagConfig } from "../../domain/entities/tagConfig";
import { ENERGY_MANAGEMENT_CATEGORIES } from "../../domain/enums/energy-management-categories";
import { EnergyManagementData, EnergyMeterTypeConfig } from "../../domain/entities/energyManagementData";
import { EnergyMeterType } from "../../domain/enums/EnergyMeterType";

@Component({
  selector: 'ats-smart-tool-tag-legend-widget',
  templateUrl: './tag-legend-widget.component.html',
  styleUrls: ['./tag-legend-widget.component.scss']
})
export class TagLegendWidgetComponent implements WidgetComponent, OnInit, AfterViewInit, OnDestroy {
  @Input() hubConnection: HubConnection;

  public widgetInstance: WidgetInstance;
  public isLoading = false;
  public error: string;
  public dataSourceItemsLoading: boolean = false;

  private widgetConfigSubscription: Subscription;
  private widgetUpdaters: WidgetUpdater[];
  private svg: SVGSVGElement;
  private dataSourceTypes: { [type: string]: DataSourceType };

  public svgContainerType: Type<SvgContainerComponent> = SvgContainerComponent;

  @ViewChild('legendContainer', { static: false }) legendContainer: ElementRef;

    constructor(private dashboardService: DashboardService, private api: ApiService, @Inject(DATASOURCETYPES) dataSourceTypes: DataSourceType[][], public core: CoreService, protected translate: TranslateService) {
    this.dataSourceTypes = keyBy(flatten(dataSourceTypes), (type: DataSourceType) => type.name);
  }

  public setWidgetInstance(widgetInstance: WidgetInstance) {
    this.widgetInstance = widgetInstance;
  }

  ngOnInit() {
    this.widgetConfigSubscription = this.widgetInstance.changed.subscribe({
      next: (instance: WidgetInstance) => {
        this.updateData(instance);
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => { this.updateData(this.widgetInstance); }, 0);
  }

  ngOnDestroy() {
    if (this.widgetConfigSubscription) {
      this.widgetConfigSubscription.unsubscribe();
      this.widgetConfigSubscription = null;
    }

    if (this.widgetUpdaters) {
      this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
      this.widgetUpdaters = null;
    }
  }

  protected updateData(instance: WidgetInstance) {
    instance.widgetData = {
      title: instance.widgetConfig.title
    };

    if (!this.legendContainer)
      return;

    if (this.widgetUpdaters) {
      this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
    }

    const dataSourceInstance = find(this.dashboardService.dataSourceInstances, (dataSourceInstance: DataSourceInstance) => dataSourceInstance.id == instance.widgetConfig.dataSourceId);
    if (dataSourceInstance) {
      if (isEmpty(instance.widgetData.title))
        instance.widgetData.title = dataSourceInstance.name;

      this.widgetUpdaters = [];
      this.widgetUpdaters.push(
        new WidgetUpdater(dataSourceInstance, null,
          (dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement) => {
            this.isLoading = dataSourceInstance?.result?.isLoading;

            if (isEmpty(this.widgetInstance.widgetData.title))
                this.widgetInstance.widgetData.title = dataSourceInstance.name;
                
            let seriesToShow: LegendData[];

            if (dataSourceInstance.result.data) {
              switch (dataSourceInstance.type.name) {
                case 'productionConsumption':

                  if (instance.widgetConfig?.selectedCategories && instance.widgetConfig?.selectedCategories.length) {

                    seriesToShow = this.retrieveLegendDataProductionConsumption(dataSourceInstance, instance.widgetConfig?.selectedCategories);
                    this.draw(seriesToShow);

                  } else {
                    this.api.getSingleResult<DataSource>('DataSources', dataSourceInstance.id, new ApiQueryOptions({ include: 'Configs' })).subscribe({
                      next: (dataSource: DataSource) => {

                        this.dataSourceItemsLoading = true;

                        const dataSourceType: DataSourceType = this.dataSourceTypes[dataSource.Type];
                        var tagConfigJson = dataSource.Configs.filter(x => x.Name === "Tags")[0].Value;

                        var tagConfig: TagConfig[] = JSON.parse(tagConfigJson);

                        instance.widgetConfig.selectedCategories = [...new Set(tagConfig.map((item) => item.Category))];

                        seriesToShow = this.retrieveLegendDataProductionConsumption(dataSourceInstance, instance.widgetConfig?.selectedCategories);
                        this.draw(seriesToShow);

                        this.dataSourceItemsLoading = false;
                      }, error: (err: any) => {
                        this.core.showErrorDetail(err);
                        this.dataSourceItemsLoading = false;
                      }
                    });
                  };
                  break;
                // case "energyManagement":
                case 'buildingEnergy':
                  seriesToShow = this.retrieveLegendDataBuildingEnergy(dataSourceInstance);
                  this.draw(seriesToShow);
                  break;
                case 'tagsHistory':
                  seriesToShow = this.retrieveLegendDataTagsHistory(dataSourceInstance);
                  this.draw(seriesToShow);
                break;
                case "energyManagement":
                  let energyManagementData: EnergyManagementData = dataSourceInstance.result.data;
                  const categories = this.core.translateObjectArray(ENERGY_MANAGEMENT_CATEGORIES, 'Display');

                  //SelectedCategories
                  if(!(instance.widgetConfig?.selectedCategories && instance.widgetConfig?.selectedCategories.length && instance.widgetConfig.selectedCategories.filter(x => categories.map(x => x.Name).includes(x)).length > 0)){
                    instance.widgetConfig.selectedCategories = ['electricityMix'];
                  }    
                  
                  seriesToShow = [];

                  energyManagementData.EnergyMeterTypeConfig.forEach(energyMeterTypeConfig => {
                    let tempStackItemInfo = new LegendData();
                    tempStackItemInfo.Category = this.setEnergyMeterTypeCategory (energyMeterTypeConfig, instance.widgetConfig.selectedCategories, categories)
                    if(tempStackItemInfo.Category === instance.widgetConfig.selectedCategories[0]){
                      tempStackItemInfo.Id = EnergyMeterType[energyMeterTypeConfig.EnergyMeterType];
                      tempStackItemInfo.Name = EnergyMeterType[energyMeterTypeConfig.EnergyMeterType];
                      tempStackItemInfo.Path = energyMeterTypeConfig.Path;
                      tempStackItemInfo.Color = energyMeterTypeConfig.Color;

                      seriesToShow.push(tempStackItemInfo);
                    }});
                    this.draw(seriesToShow);
                  break;
                case 'evChargingConstraint':
                  this.draw([{
                    Name: this.translate.get('i18n:EV_CHARGING_CONSTRAINT.UNCONTROLLABLE_LOAD'),
                    Color: '#B6D6D5'
                  }, {
                    Name: this.translate.get('i18n:EV_CHARGING_CONSTRAINT.EV_CHARGERS'),
                    Color: '#504D91'
                  }, {
                    Name: this.translate.get('i18n:EV_CHARGING_CONSTRAINT.REMAINING'),
                    Color: '#F0F0F0'
                  }, {
                    Name: this.translate.get('i18n:EV_CHARGING_CONSTRAINT.CONSTRAINT'),
                    Color: '#CC2F34'
                  }]);
                  break;
                default:
                  console.warn('Datasource unknown');
              }
            }
          },
          (dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement, event: ItemValueChangedEventArgs) => {
            //No need to change if data from item changed
          }));
    }
  }

  public cancel(e: any) {
    e.preventDefault();
    e.cancelBubble = true;
  }

  protected retrieveLegendDataProductionConsumption(dataSourceInstance: DataSourceInstance, selectedCategories: any[]) {
    var parsedResult: ProductionConsumptionData = <ProductionConsumptionData>(dataSourceInstance.result.data);
    var seriesToShow: LegendData[] = [];
    parsedResult.TagConfig.forEach(x =>{
      if(selectedCategories.includes(x.Category)){
        var tagData = parsedResult.Tags.filter(y => y.Id === x.TagId)[0];
        if (tagData)
        { 
          seriesToShow.push(<LegendData>{ Id: x.TagId, Color: x.Color, Category: x.Category, Name: tagData.Name, Path: tagData.Asset.Path});
        }
      }
    });
    return seriesToShow;
  }

  protected retrieveLegendDataBuildingEnergy(dataSourceInstance: DataSourceInstance) {
    var buildingEnergyData: BuildingEnergyData = <BuildingEnergyData>(dataSourceInstance.result.data);
    const zColorScale = scaleOrdinal(schemeCategory10).domain(['0', buildingEnergyData.Categories.length.toString()]);
    var seriesToShow: LegendData[] = buildingEnergyData.Categories.map((category: string, i) => <LegendData>{ Color: zColorScale(i.toString()), Category: category, Name: category, Path: '' });
    return seriesToShow;
  }

  protected retrieveLegendDataTagsHistory(dataSourceInstance: DataSourceInstance) {
    var parsedResult: any = (dataSourceInstance.result.data);
    var seriesToShow: LegendData[] = parsedResult.map(x => <LegendData>{ Id: x.TagId, Color: x.DefaultColor, Category: '', Path:x.Tag.Asset.Path , Name: x.Tag.Name });
    return seriesToShow;
  }

  private draw (seriesToShow: LegendData[]){
    var divLegend = select(this.legendContainer.nativeElement);
    divLegend.selectAll('*').remove()
    divLegend.selectChildren('*').remove()
    divLegend.html("");

    var list = divLegend.append("ul")
      .attr("class", "legendUl");

    var entries = list.selectAll("li")
      .data(seriesToShow)
      .enter()
      .append("li");
      // .on('click', (d: any, legendData: any) => {
      //   this.dashboardService.widgetInstances.forEach(widgetInstance => {
      //     if (widgetInstance.widgetType.name === "tag-grouped-stacked-bar-chart-value" || widgetInstance.widgetType.name === "tag-pie-chart-value") {
      //       let isShown = widgetInstance.widgetConfig.visualizeTags.filter(x => x.TagId === legendData.TagId)[0].Show;
      //       widgetInstance.widgetConfig.visualizeTags.filter(x => x.TagId === legendData.TagId)[0].Show = !isShown;
      //     }
      //   });
      // });

    entries.append("span")
      .attr("class", "legendRect")
      .style("background-color", d => d.Color)

    entries.append("span")
      .attr("class", "legendLabel")
      .html(d => { return (d.Path) ? (d.Path + ' / ' + startCase(d.Name)) : startCase(d.Name); })
  }
  private setEnergyMeterTypeCategory (energyMeterTypeConfig: EnergyMeterTypeConfig, widgetCategory: string[], categories: any) : string{

    const selectedCategory = categories.find(x => x.Name === widgetCategory[0]).Name;
    let categoryMapping = {};

    switch (selectedCategory){
      case 'energyMix':
        for (let energyMeterType in EnergyMeterType){
          switch (energyMeterType){ 
            case EnergyMeterType.Solar.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.Wind.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.GridConsumption.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.GridInjection.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.Gas.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.Diesel.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.EVCharger.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.SelfConsumption.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.BatteryEnergyIn.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.BatteryEnergyOut.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
          }
        }
        break;
      case 'electricityMix':
        for (let energyMeterType in EnergyMeterType){
          switch (energyMeterType){ 
            case EnergyMeterType.Solar.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.Wind.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.GridConsumption.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.GridInjection.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.Gas.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.Diesel.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.EVCharger.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.SelfConsumption.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.BatteryEnergyIn.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.BatteryEnergyOut.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
          }
        }
        break;
      case 'renewableEnergy':
        for (let energyMeterType in EnergyMeterType){
          switch (energyMeterType){ 
            case EnergyMeterType.Solar.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.Wind.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.GridConsumption.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.GridInjection.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.Gas.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.Diesel.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.EVCharger.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.SelfConsumption.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.BatteryEnergyIn.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.BatteryEnergyOut.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
          }
        }
        break;
      case 'gas':
          for (let energyMeterType in EnergyMeterType){
            switch (energyMeterType){ 
              case EnergyMeterType.Solar.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.Wind.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.GridConsumption.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.GridInjection.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.Gas.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
              break;
              case EnergyMeterType.Diesel.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.SelfConsumption.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.BatteryEnergyIn.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.BatteryEnergyOut.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
            }
          }
          break;
      case 'diesel':
        for (let energyMeterType in EnergyMeterType){
          switch (energyMeterType){ 
            case EnergyMeterType.Solar.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.Wind.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.GridConsumption.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.GridInjection.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.Gas.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.Diesel.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.EVCharger.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.SelfConsumption.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.BatteryEnergyIn.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.BatteryEnergyOut.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
          }
        }
        break;
      case 'ev':
        for (let energyMeterType in EnergyMeterType){
          switch (energyMeterType){ 
            case EnergyMeterType.Solar.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.Wind.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.GridConsumption.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.GridInjection.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.Gas.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.Diesel.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.EVCharger.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.SelfConsumption.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.BatteryEnergyIn.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.BatteryEnergyOut.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
          }
        }
        break;       
        case 'battery':
          for (let energyMeterType in EnergyMeterType){
            switch (energyMeterType){ 
              case EnergyMeterType.Solar.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.Wind.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.GridConsumption.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.GridInjection.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.Gas.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.Diesel.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.EVCharger.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.SelfConsumption.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.BatteryEnergyIn.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
              break;
              case EnergyMeterType.BatteryEnergyOut.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
              break;
            }
          }
          break;           
    }
    const energyMeterTypeString = EnergyMeterType[energyMeterTypeConfig.EnergyMeterType];
    return categoryMapping[energyMeterTypeString];   
  }

}