import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, AfterViewInit, Renderer2, Inject } from '@angular/core';
import { HubConnection } from '@aspnet/signalr';
import { Subscription } from 'rxjs';

import { keyBy, find, forEach } from 'lodash-es';

import { ApiService, CoreService, DashboardService, DashboardWidgetDocument, DataSourceInstance, ItemValueChangedEventArgs, TranslateService, WidgetComponent, WidgetInstance, WidgetUpdater } from '@ats/ats-platform-dashboard';
import { D3ZoomEvent, format, select, zoom } from 'd3';
import { TwoSocketEVCharger } from '../../domain/dataModels/twoSocketEVCharger';
import { EVSocketDataPoint } from '../../domain/dataModels/evSocketDataPoint';

@Component({
  selector: 'ats-smart-tool-ev-chargers-map-widget',
  templateUrl: './ev-chargers-map-widget.component.html',
  styleUrls: ['./ev-chargers-map-widget.component.scss']
})
export class EVChargersMapWidgetComponent implements WidgetComponent, OnInit, AfterViewInit, OnDestroy {
  @Input() hubConnection: HubConnection;

  public widgetInstance: WidgetInstance;

  public isLoading = false;
  public error: string;

  private widgetConfigSubscription: Subscription;
  private widgetUpdater: WidgetUpdater;
  private documentsByKey: { [key: string]: DashboardWidgetDocument };
  private svg: SVGSVGElement;

  private sockets: {[key: string]: { g: d3.Selection<SVGGElement, undefined, null, undefined>, data?: EVSocketDataPoint }};

  @ViewChild("svgContainer", { static: false }) svgContainer: ElementRef;


  constructor(public translate: TranslateService, private core: CoreService, private api: ApiService, private renderer: Renderer2, private dashboardService: DashboardService) {
  }

  public setWidgetInstance(widgetInstance: WidgetInstance) {
    this.widgetInstance = widgetInstance;
  }

  ngOnInit() {
    this.widgetConfigSubscription = this.widgetInstance.changed.subscribe({
      next: (instance: WidgetInstance) => {
        this.updateData(instance);
      }
    })
  }

  ngAfterViewInit() {
    setTimeout(() => { this.updateData(this.widgetInstance); }, 0);
  }

  ngOnDestroy() {
    if (this.widgetConfigSubscription) {
      this.widgetConfigSubscription.unsubscribe();
      this.widgetConfigSubscription = null;
    }

    if (this.widgetUpdater) {
      this.widgetUpdater.destroy();
      this.widgetUpdater = null;
    }
  }

  protected updateData(instance: WidgetInstance) {
    instance.widgetData = {
      title: instance.widgetConfig.title
    };

    if (!this.svgContainer)
      return;

    let svgContent: string;

    if (instance.dashboardWidget.Documents && instance.dashboardWidget.Documents.length) {
      this.documentsByKey = keyBy(instance.dashboardWidget.Documents, 'Name');
      svgContent = this.documentsByKey['svgContent'] ? this.documentsByKey['svgContent'].Content : '';
    }

    const div: HTMLDivElement = this.svgContainer.nativeElement;
    div.innerHTML = svgContent;

    const svgs = div.getElementsByTagName('svg');
    this.svg = svgs && svgs.length ? svgs.item(0) : null;
    var d3Svg = select(this.svg);

    var d3Zoom = zoom().on('zoom', (e: D3ZoomEvent<any, undefined>) => {
      d3Svg.select('g.zoomable').attr('transform', e.transform.toString());
    });
    d3Svg.call(d3Zoom);

    var tooltipContainer = document.getElementById("tooltipContainer");
    var formatValue = format('.2f');

    this.sockets = {};
    var socketSelection = d3Svg.selectAll<SVGGElement, unknown>('g.socket');
    forEach(socketSelection.nodes(), (g: SVGGElement) => {
      if (g.id) {
        this.sockets[g.id] =  {
          g: select(g)
        };

        var titleSelection = this.sockets[g.id].g.selectChild<SVGTitleElement>('title');
        var title = titleSelection.empty() ? 'Unkown socket' : this.sockets[g.id].g.selectChild<SVGTitleElement>('title').node().innerHTML;
        titleSelection.remove();

        if (instance.widgetConfig.chartId) {
          this.sockets[g.id].g.on('click', (e: MouseEvent) => {
            var route = ['chart', this.widgetInstance.widgetConfig.chartId, 'asset', g.id];
            
            if (this.dashboardService.config) {
              this.core.navigate(route, {
                state: {
                  periodSelection: {
                    from: this.dashboardService.config.from,
                    to: this.dashboardService.config.to,
                    period: this.dashboardService.config.period
                  }
                }
              });
            } else {
              this.core.navigate(route);
            }
          });
        }

        this.sockets[g.id].g.on('mouseover', (e: MouseEvent) => {

          var socket = this.sockets[g.id];
          if (!socket)
            return;

          var tooltipHtml = '';
          tooltipHtml += '<div style="text-align: center"><b>' + title + '</b></div>';
          tooltipHtml += '<table>';
          tooltipHtml += '<tr>';
          tooltipHtml += '<td style="width: 120px; text-align: right; font-weight: bold;">';
          tooltipHtml += 'State';
          tooltipHtml += '</td>';
          tooltipHtml += '<td style="white-space:nowrap;">';

          if (socket.data?.State === null || socket.data?.State === undefined) {
              tooltipHtml += 'Unknown';
          } else {
              switch (socket.data?.State) {
                  case 0:
                      tooltipHtml += 'Available';
                      break;
                  case 1:
                  case 2:
                  case 4:
                      tooltipHtml += 'Charging';
                      break;
                  case 3:
                      tooltipHtml += 'Paused by EV';
                      break;
                  case 5:
                      tooltipHtml += 'Paused by EVSE';
                      break;
                  case 6:
                      tooltipHtml += 'Fault';
                      break;
                  case 7:
                      tooltipHtml += 'Emergency stop';
                      break;
                  default:
                      tooltipHtml += 'Unknown';
                      break;
              }
          }

          tooltipHtml += '</td>';
          tooltipHtml += '</tr>';
          
          tooltipHtml += '<tr>';
          tooltipHtml += '<td style="width: 120px; text-align: right; font-weight: bold;">';
          tooltipHtml += 'Active power';
          tooltipHtml += '</td>';
          tooltipHtml += '<td style="white-space:nowrap;">';
          tooltipHtml += ((socket.data ? formatValue(socket.data.ActivePower) : '---') + ' kW');
          tooltipHtml += '</td>';
          tooltipHtml += '</tr>';
          
          tooltipHtml += '<tr>';
          tooltipHtml += '<td style="width: 120px; text-align: right; font-weight: bold;">';
          tooltipHtml += 'Charged energy';
          tooltipHtml += '</td>';
          tooltipHtml += '<td style="white-space:nowrap;">';
          tooltipHtml += ((socket.data ? formatValue(socket.data.ChargedEnergy) : '---') + ' kWh');
          tooltipHtml += '</td>';
          tooltipHtml += '</tr>';

          tooltipHtml += '</table>';

          tooltipContainer.style.position = 'absolute';
          tooltipContainer.style.borderStyle = 'solid';
          tooltipContainer.style.borderColor = 'lightgrey';
          tooltipContainer.style.borderWidth = '1px';
          tooltipContainer.style.padding = '3px';
          tooltipContainer.style.background = 'white';
          tooltipContainer.style.fontSize = '12px';
          tooltipContainer.style.display = "inline";
          tooltipContainer.innerHTML = tooltipHtml;
        });

        this.sockets[g.id].g.on('mouseout', (e: MouseEvent) => {
          tooltipContainer.innerHTML = '';
          tooltipContainer.style.display = "none";
        });

        this.sockets[g.id].g.on('mousemove', (e: MouseEvent) => {
          var tooltipLeft = e.pageX + 10;
            const tooltipRightX = tooltipLeft + tooltipContainer.clientWidth;
            if (tooltipRightX > window.innerWidth) {
                tooltipContainer.style.left = (e.pageX - 10 - tooltipContainer.clientWidth) + 'px';
            } else {
                tooltipContainer.style.left = tooltipLeft  + 'px';
            }
        
            var tooltipTop = e.pageY + 10;
            const tooltipBottomY = tooltipTop + tooltipContainer.clientHeight;
            const tooltipTopYIfAbove = tooltipTop - tooltipContainer.clientHeight;
            const newTooltipTopIfToBig = 75;
            if (tooltipBottomY > window.innerHeight && tooltipTopYIfAbove > 0){
                tooltipContainer.style.top = (e.pageY - 10 - tooltipContainer.clientHeight) + 'px';
            } else if (tooltipBottomY > window.innerHeight && tooltipTopYIfAbove < 0){
                tooltipContainer.style.top = newTooltipTopIfToBig + 'px';
            } else {
                tooltipContainer.style.top = tooltipTop + 'px';
            }
        });
      }
    });

    if (this.widgetUpdater) {
      this.widgetUpdater.destroy();
      this.widgetUpdater = null;
    }

    const dataSourceInstance = find(this.dashboardService.dataSourceInstances, (dataSourceInstance: DataSourceInstance) => dataSourceInstance.id == instance.widgetConfig.dataSourceId);
    if (dataSourceInstance) {
      this.widgetUpdater = new WidgetUpdater(dataSourceInstance, this.svgContainer.nativeElement,
        (dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement) => this.update(dataSourceInstance, nativeElement),
        (dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement, e: ItemValueChangedEventArgs) => this.updateValue(dataSourceInstance, nativeElement, e));
    }
  }

  protected update(dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement) {
    this.isLoading = dataSourceInstance?.result?.isLoading;

    if (dataSourceInstance.result && dataSourceInstance.result.data) {

      var data: TwoSocketEVCharger[] = dataSourceInstance.result.data;
      forEach((charger: TwoSocketEVCharger) => {
        if (charger.Socket1)
          this.updateSocket(charger.Socket1.Asset.Id, charger.Socket1.LatestPoint);
        if (charger.Socket2)
          this.updateSocket(charger.Socket2.Asset.Id, charger.Socket2.LatestPoint);
      });
      
      this.isLoading = false;
    }
  }

  protected updateValue(dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement, e: ItemValueChangedEventArgs) {
    this.updateSocket(e.id, e.value);
  }

  protected updateSocket(id: string, point: EVSocketDataPoint) {
    var socket = this.sockets[id];
    if (!socket)
      return;

    socket.data = point;

    if (point.State === null || point.State === undefined) {
      socket.g.attr('fill', 'grey').attr('stroke', 'grey');
    } else {
      switch (point.State) {
        case 0:
          socket.g.attr('fill', 'green').attr('stroke', 'green');
          break;
        case 1:
        case 2:
        case 4:
          socket.g.attr('fill', 'blue').attr('stroke', 'blue');
          break;
        case 3:
        case 5:
          socket.g.attr('fill', 'orange').attr('stroke', 'orange');
          break;
        case 6:
        case 7:
          socket.g.attr('fill', 'red').attr('stroke', 'red');
          break;
        default:
          socket.g.attr('fill', 'grey').attr('stroke', 'grey');
          break;
      }
    }
  }
}
