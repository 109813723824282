<div class="report-menu">
  <kendo-treelist
    [idField]="idField"
    [data]="treeData"
    [loading]="loading"
    [fetchChildren]="fetchChildren"
    [hasChildren]="hasChildren"
    [filterable]="true"
    [filter]="filter"
    (filterChange)="onTreeFilterChange($event)"
    kendoTreeListSelectable
    [selectable]="selectableSettings"
    [(selectedItems)]="selectedItems"
    (selectedItemsChange)="selectedItemsChange($event)"
    kendoTreeListExpandable
    [initiallyExpanded]="true"
    >
    <ng-template kendoTreeListToolbarTemplate>
      <button kendoButton look="flat" icon="folder-add" (click)="addFolder()"></button>
      <button kendoButton look="flat" icon="folder-more" (click)="updateFolder()" [disabled] ="selectedItem.Type != 0"></button>
      <button kendoButton look="flat" icon="delete" (click)="deleteFolder()" [disabled] ="selectedItem.Type != 0"></button>
      <kendo-grid-spacer></kendo-grid-spacer>
      <button kendoButton look="flat" icon="add" (click)="addReport()" [disabled] ="selectedItem.Type != 0"></button>
      <button kendoButton look="flat" icon="edit" (click)="updateReport()" [disabled]="selectedItem.Type != 4"></button>
      <button kendoButton look="flat" icon="trash" (click)="deleteReport()" [disabled]="selectedItem.Type != 4"></button>
      <button kendoButton look="flat" icon="copy" (click)="duplicateReport()" [disabled]="selectedItem.Type != 4"></button>
      <button kendoButton look="flat" icon="download" (click)="downloadReport()" [disabled]="selectedItem.Type != 4"></button>
    </ng-template>

    <kendo-treelist-column
      [expandable]="true"
      field="Name"
      title="translate.get('i18n:REPORT.NAME')"
    >
    <ng-template kendoTreeListCellTemplate let-dataItem>
      <span
        class="k-icon k-i-{{
          dataItem.Type === 0 ? 'folder' : 'file'
        }}"
      ></span>
      {{ dataItem.Name }}
    </ng-template>
    </kendo-treelist-column>
  </kendo-treelist>

  <ng-template #actionsReportTemplate>
      <button kendoButton (click)="closePopup()">{{ 'i18n:BUTTON.CANCEL' | translate }}</button>
      <button kendoButton (click)="saveReport()" [disabled]="!dialogRef.content.instance.isValid()" [primary]="true" [disabled]="actionLoading" [icon]="actionLoading ? 'loading' : null">{{ 'i18n:BUTTON.OK' | translate }}</button>
  </ng-template>

  <ng-template #actionsDownloadReportTemplate>
      <button kendoButton (click)="closePopup()">{{ 'i18n:BUTTON.CANCEL' | translate }}</button>
      <button kendoButton (click)="executeDownloadReport()" [disabled]="!dialogRef.content.instance.isValid()" [primary]="true" [disabled]="actionLoading" [icon]="actionLoading ? 'loading' : null">{{ 'i18n:BUTTON.DOWNLOAD' | translate }}</button>
  </ng-template>
</div>