<form class="k-form" [formGroup]="formGroup">
    <div class="flex-container">
        <div>
            <fieldset class="k-form-fieldset">
                <legend class="k-form-legend">{{ "LABEL.GENERAL" | translate }}</legend>
                <kendo-formfield>
                    <kendo-label [for]="title" text="{{ 'WIDGET.TITLE' | translate }}"></kendo-label>
                    <input #title formControlName="title" kendoTextBox />
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label [for]="widgetType" text="{{ 'WIDGET.TYPE' | translate }}"></kendo-label>
                    <kendo-textbox #widgetType formControlName="widgetType" [readonly]="true"></kendo-textbox>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label [for]="reportId" text="{{ 'REPORTWIDGET.REPORT' | translate }}"></kendo-label>

                    <ats-platform-entity-lookup-textbox #reportId formControlName="reportId" [settings]="reportSelectionPopupSettings" [formatter]="reportFormatter"></ats-platform-entity-lookup-textbox>

                    <kendo-formerror *ngIf="formGroup.controls['reportId'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
                </kendo-formfield>
                <kendo-formfield>
                    <kendo-label class="k-checkbox-label" [for]="useDashboardDateTime" text="{{ 'REPORTWIDGET.USE_DASHBOARD_DATETIME' | translate }}">
                    </kendo-label>
                    <input type="checkbox" formControlName="useDashboardDateTime" #useDashboardDateTime kendoCheckBox />
                </kendo-formfield>
            </fieldset>
        </div>
        <div style="flex: 1">
            <div style="height: calc(100% - 8px); padding-top: 8px;">
                <kendo-label text="{{ 'WIDGET.CUSTOM_SVG_CONTENT' | translate }}"></kendo-label>
                <ngx-monaco-editor [options]="svgEditorOptions" formControlName="svgContent"></ngx-monaco-editor>
            </div>
        </div>
    </div>
</form>