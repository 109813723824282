<form class="k-form" [formGroup]="formGroup">
  <fieldset class="k-form-fieldset">
    <legend class="k-form-legend">{{ 'LABEL.GENERAL' | translate}}</legend>
    <kendo-formfield>
      <kendo-label [for]="title" text="{{ 'WIDGET.TITLE' | translate }}"></kendo-label>
      <input #title formControlName="title" kendoTextBox />
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="widgetType" text="{{ 'WIDGET.TYPE' | translate }}"></kendo-label>
      <kendo-textbox #widgetType formControlName="widgetType" [readonly]="true" ></kendo-textbox>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="dataSourceId" text="{{ 'WIDGET.DATASOURCE' | translate }}"></kendo-label>
      <ats-platform-entity-lookup-textbox #dataSourceId formControlName="dataSourceId"
        [settings]="dataSourceSelectionPopupSettings" [formatter]="dataSourceFormatter"
        ></ats-platform-entity-lookup-textbox>
      <kendo-formerror *ngIf="formGroup.controls['dataSourceId'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' |
        translate }}</kendo-formerror>
    </kendo-formfield>
  </fieldset>
  <fieldset class="k-form-fieldset">
    <legend class="k-form-legend">{{ "LABEL.SETTINGS" | translate }}</legend>
    <kendo-formfield>
      <kendo-label [for]="gridChartId" text="{{ 'WIDGET.GRID_CHART' | translate }}"></kendo-label>
      <ats-platform-entity-lookup-textbox #gridChartId formControlName="gridChartId"
        [settings]="chartSelectionPopupSettings" [formatter]="chartFormatter"
        ></ats-platform-entity-lookup-textbox>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="solarChartId" text="{{ 'WIDGET.SOLAR_CHART' | translate }}"></kendo-label>
      <ats-platform-entity-lookup-textbox #solarChartId formControlName="solarChartId"
        [settings]="chartSelectionPopupSettings" [formatter]="chartFormatter"
        ></ats-platform-entity-lookup-textbox>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="windChartId" text="{{ 'WIDGET.WIND_CHART' | translate }}"></kendo-label>
      <ats-platform-entity-lookup-textbox #windChartId formControlName="windChartId"
        [settings]="chartSelectionPopupSettings" [formatter]="chartFormatter"
        ></ats-platform-entity-lookup-textbox>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="batteryChartId" text="{{ 'WIDGET.BATTERY_CHART' | translate }}"></kendo-label>
      <ats-platform-entity-lookup-textbox #batteryChartId formControlName="batteryChartId"
        [settings]="chartSelectionPopupSettings" [formatter]="chartFormatter"
        ></ats-platform-entity-lookup-textbox>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="evChargerChartId" text="{{ 'WIDGET.EVCHARGER_CHART' | translate }}"></kendo-label>
      <ats-platform-entity-lookup-textbox #evChargerChartId formControlName="evChargerChartId"
        [settings]="chartSelectionPopupSettings" [formatter]="chartFormatter"
        ></ats-platform-entity-lookup-textbox>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="buildingChartId" text="{{ 'WIDGET.BUILDING_CHART' | translate }}"></kendo-label>
      <ats-platform-entity-lookup-textbox #buildingChartId formControlName="buildingChartId"
        [settings]="chartSelectionPopupSettings" [formatter]="chartFormatter"
        ></ats-platform-entity-lookup-textbox>
    </kendo-formfield>
  
  </fieldset>
</form>