export const SMARTTOOL_DASHBOARD_TRANSLATIONS: any = 
{
  "AGGREGATE.FUNCTIONS.COUNT": {
    "en": "Count",
    "nl": "Aantal"
  },
  "AGGREGATE.FUNCTIONS.FIRST": {
    "en": "First",
    "nl": "Eerste"
  },
  "AGGREGATE.FUNCTIONS.LAST": {
    "en": "Last",
    "nl": "Laatste"
  },
  "AGGREGATE.FUNCTIONS.MAX": {
    "en": "Maximum",
    "nl": "Maximum"
  },
  "AGGREGATE.FUNCTIONS.MEAN": {
    "en": "Mean",
    "nl": "Gemiddelde"
  },
  "AGGREGATE.FUNCTIONS.MIN": {
    "en": "Minimum",
    "nl": "Minimum"
  },
  "AGGREGATE.FUNCTIONS.SUM": {
    "en": "Sum",
    "nl": "Som"
  },
  "ASSET.ADD": {
    "en": "Add",
    "nl": "Toevoegen"
  },
  "ASSET.CODE": {
    "en": "Code",
    "nl": "Code"
  },
  "ASSET.DESCRIPTION": {
    "en": "Description",
    "nl": "Omschrijving"
  },
  "ASSET.PATH": {
    "en": "Path",
    "nl": "Pad"
  },
  "ASSET.REMOVE": {
    "en": "Remove",
    "nl": "Verwijder"
  },
  "ASSET.TYPE": {
    "en": "Type",
    "nl": "Type"
  },
  "ASSETTYPE.NAME": {
    "en": "Name",
    "nl": "Naam"
  },
  "BUILDING_ENERGY_HORIZ_STACKED_BAR_CHART_WIDGET": {
    "en": "Building energy - summary",
    "nl": "Gebouw energie - overzicht"
  },
  "BUILDING_ENERGY_LINEAR_REGRESSION_WIDGET": {
    "en": "Building energy - degree days details",
    "nl": "Gebouw energie - graaddagen details"
  },
  "BUILDING_ENERGY_VERT_STACKED_BAR_CHART_WIDGET": {
    "en": "Building energy - daily details",
    "nl": "Gebouw energie - dagelijkse details"
  },
  "BUTTON.DOWNLOAD": {
    "en": "Download",
    "nl": "Download"
  },
  "CHART": {
    "en": "Chart",
    "nl": "Grafiek"
  },
  "CHART.AGGREGATION_WINDOW": {
    "en": "Aggregation window",
    "nl": "Aggregatie venster"
  },
  "CHART.ASSET_TYPE": {
    "en": "Asset type",
    "nl": "Asset type"
  },
  "CHART.DELETE": {
    "en": "Delete",
    "nl": "Verwijder"
  },
  "CHART.FIXED_VALUE.ADD": {
    "en": "Add",
    "nl": "Toevoegen"
  },
  "CHART.FIXED_VALUE.COLOR": {
    "en": "Color",
    "nl": "Kleur"
  },
  "CHART.FIXED_VALUE.NAME": {
    "en": "Name",
    "nl": "Naam"
  },
  "CHART.FIXED_VALUE.PANE": {
    "en": "Pane",
    "nl": "Panel"
  },
  "CHART.FIXED_VALUE.SCALE": {
    "en": "Scale",
    "nl": "Schaal"
  },
  "CHART.FIXED_VALUE.VALUE": {
    "en": "Value",
    "nl": "Waarde"
  },
  "CHART.FIXED_VALUES": {
    "en": "Fixed values",
    "nl": "Vaste waarden"
  },
  "CHART.FIXED_VALUES.REMOVE": {
    "en": "Remove",
    "nl": "Verwijderen"
  },
  "CHART.FOLDERID": {
    "en": "Folder Id",
    "nl": "Map Id"
  },
  "CHART.GENERAL": {
    "en": "General",
    "nl": "Algemeen"
  },
  "CHART.HEIGHT": {
    "en": "Panel height",
    "nl": "Paneel hoogte"
  },
  "CHART.ITEM.AGGREGATE": {
    "en": "Aggregate",
    "nl": "Aggregatie"
  },
  "CHART.ITEM.COLOR": {
    "en": "Color",
    "nl": "Kleur"
  },
  "CHART.ITEM.FILL_OPTION": {
    "en": "Fill option",
    "nl": "Fill option"
  },
  "CHART.ITEM.MEASUREMENT_UNIT": {
    "en": "Measurement unit",
    "nl": "Meeteenheid"
  },
  "CHART.ITEM.PANE": {
    "en": "Pane",
    "nl": "Paneel"
  },
  "CHART.ITEM.SCALE": {
    "en": "Scale",
    "nl": "Schaal"
  },
  "CHART.ITEM.SHOW_RANGE": {
    "en": "Show range",
    "nl": "Toon range"
  },
  "CHART.ITEM.TAG": {
    "en": "Tag",
    "nl": "Tag"
  },
  "CHART.MAX_POINTS": {
    "en": "Maximum data points",
    "nl": "Maximum data punten"
  },
  "CHART.NAME": {
    "en": "Name",
    "nl": "Naam"
  },
  "CHART.SCALE.ADD": {
    "en": "Add",
    "nl": "Toevoegen"
  },
  "CHART.SCALE.MAX": {
    "en": "Maximum",
    "nl": "Maximum"
  },
  "CHART.SCALE.MIN": {
    "en": "Minimum",
    "nl": "Minimum"
  },
  "CHART.SCALE.SHOW_ZERO": {
    "en": "Show 0",
    "nl": "Toon 0"
  },
  "CHART.SCALE.TEXT": {
    "en": "Text",
    "nl": "Tekst"
  },
  "CHART.SCALES": {
    "en": "Scales",
    "nl": "Schalen"
  },
  "CHART.SCALES.REMOVE": {
    "en": "Remove",
    "nl": "Verwijderen"
  },
  "CHART.TAGS": {
    "en": "Tags",
    "nl": "Tags"
  },
  "CHART.TEMPLATE": {
    "en": "Template",
    "nl": "Template"
  },
  "CHART_TO_REPORT": {
    "en": "Charts to report",
    "nl": "Grafieken naar rapport"
  },
  "CHART_WIDGET": {
    "en": "Chart shortcut",
    "nl": "Grafiek snelkoppeling"
  },
  "CHARTWIDGET.ASSET": {
    "en": "Asset",
    "nl": "Asset"
  },
  "CHARTWIDGET.CHART": {
    "en": "Chart",
    "nl": "Grafiek"
  },
  "COMMON.CHART": {
    "en": "Chart",
    "nl": "Grafiek"
  },
  "COMMON.GENERAL": {
    "en": "General",
    "nl": "Algemeen"
  },
  "COMMON.LOADING": {
    "en": "Loading",
    "nl": "Aan het laden"
  },
  "COMMON.NAME": {
    "en": "Name",
    "nl": "Naam"
  },
  "COMMON.TOTAL": {
    "en": "Total",
    "nl": "Totaal"
  },
  "CONFIRMATION.CHART_DELETE": {
    "en": "Are you sure you want to delete the current chart?",
    "nl": "Bent u zeker dat u de huidige grafiek wilt verwijderen?"
  },
  "DASHBOARD.SYSTEMEVENTFILTER.ASSET": {
    "en": "Asset filter",
    "nl": "Asset-filter"
  },
  "DATASOURCE.ASSET": {
    "en": "Asset tags",
    "nl": "Asset tags"
  },
  "DATASOURCE.ASSET.ADD": {
    "en": "Add",
    "nl": "Toevoegen"
  },
  "DATASOURCE.ASSET.REMOVE": {
    "en": "Remove",
    "nl": "Verwijderen"
  },
  "DATASOURCE.ASSET_TAGS.ASSET": {
    "en": "Asset",
    "nl": "Asset"
  },
  "DATASOURCE.ASSET_TAGS.INCLUDE_CHILD_ASSETS": {
    "en": "Include child assets",
    "nl": "Inclusief onderliggende assets"
  },
  "DATASOURCE.ASSET_TYPE_TAGS.ADD": {
    "en": "Add tag types",
    "nl": "Voeg tag types toe"
  },
  "DATASOURCE.BUILDING.ADD_BUILDING": {
    "en": "Add building",
    "nl": "Voeg gebouw toe"
  },
  "DATASOURCE.BUILDING_ENERGY.ASSET": {
    "en": "Asset",
    "nl": "Asset"
  },
  "DATASOURCE.BUILDING_ENERGY.CATEGORY": {
    "en": "Category",
    "nl": "Categorie"
  },
  "DATASOURCE.BUILDING_ENERGY.DEGREE_DAY": {
    "en": "Degree day",
    "nl": "Graaddag"
  },
  "DATASOURCE.BUILDING_ENERGY.EMISSION_FACTOR": {
    "en": "Emission factor",
    "nl": "Uitstoot factor"
  },
  "DATASOURCE.BUILDING_ENERGY.EMISSIONMEASUREMENTUNITID": {
    "en": "Emission measurement unit id",
    "nl": "Uitstoot meeteenheid id"
  },
  "DATASOURCE.BUILDING_ENERGY.ENERGY_FACTOR": {
    "en": "Energy factor",
    "nl": "Energie factor"
  },
  "DATASOURCE.BUILDING_ENERGY.ENERGYMEASUREMENTUNITID": {
    "en": "Energy measurement unit id",
    "nl": "Energie meeteenheid id"
  },
  "DATASOURCE.BUILDING_ENERGY.PRIMARY_FACTOR": {
    "en": "Primary factor",
    "nl": "Primary factor"
  },
  "DATASOURCE.BUILDING_ENERGY.SURFACEMEASUREMENTUNITID": {
    "en": "Surface measurement unit id",
    "nl": "Oppervlakte meeteenheid id"
  },
  "DATASOURCE.BUILDING_ENERGY.SURFACEPARAMETERDEFINITIONID": {
    "en": "Surface parameter definition id",
    "nl": "Oppervlakte parameter definitie id"
  },
  "DATASOURCE.BUILDING_ENERGY.TAG": {
    "en": "Tag",
    "nl": "Tag"
  },
  "DATASOURCE.BUILDINGENERGY": {
    "en": "Building energy",
    "nl": "Gebouwen energie"
  },
  "DATASOURCE.DEVICE_TOPOLOGY": {
    "en": "Energy meter topology",
    "nl": "Energiemeter topologie"
  },
  "DATASOURCE.DEVICE_TOPOLOGY.CHART": {
    "en": "Chart",
    "nl": "Grafiek"
  },
  "DATASOURCE.DEVICE_TOPOLOGY.DEVICE": {
    "en": "Energy meter",
    "nl": "Energiemeter"
  },
  "DATASOURCE.DEVICE_TOPOLOGY.ENERGYIN": {
    "en": "Energy IN",
    "nl": "Energie IN"
  },
  "DATASOURCE.DEVICE_TOPOLOGY.ENERGYINTAG": {
    "en": "Energy IN tag",
    "nl": "Energie IN tag"
  },
  "DATASOURCE.DEVICE_TOPOLOGY.ENERGYOUT": {
    "en": "Energy OUT",
    "nl": "Energie UIT"
  },
  "DATASOURCE.DEVICE_TOPOLOGY.ENERGYOUTTAG": {
    "en": "Energy OUT tag",
    "nl": "Energie UIT tag"
  },
  "DATASOURCE.DEVICE_TOPOLOGY.ICON": {
    "en": "Icon",
    "nl": "Icoon"
  },
  "DATASOURCE.DEVICE_TOPOLOGY.NAME": {
    "en": "Name",
    "nl": "Naam"
  },
  "DATASOURCE.DEVICE_TOPOLOGY.PARENTDEVICE": {
    "en": "Parent meter",
    "nl": "Bovenliggende meter"
  },
  "DATASOURCE.DEVICE_TOPOLOGY.POWERTAG": {
    "en": "Power tag",
    "nl": "Vermogen tag"
  },
  "DATASOURCE.DEVICE_TOPOLOGY.REMOVE": {
    "en": "Remove",
    "nl": "Verwijder"
  },
  "DATASOURCE.DEVICES": {
    "en": "Energy meters",
    "nl": "Energiemeters"
  },
  "DATASOURCE.ENERGY_MANAGEMENT": {
    "en": "Energy management",
    "nl": "Energiebeheer"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.BATTERYENERGYINMETERS": {
    "en": "Battery energy in meters",
    "nl": "Batterij energie-in meters"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.BATTERYENERGYINTAG": {
    "en": "Battery energy in tags",
    "nl": "Batterij energie-in tags"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.BATTERYENERGYOUTMETERS": {
    "en": "Battery energy out meters",
    "nl": "Batterij energie-uit meters"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.BATTERYENERGYOUTTAG": {
    "en": "Battery energy out meter tags",
    "nl": "Batterij energie-uit tags"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.CO2MEASUREMENTUNITID": {
    "en": "CO2 measurement unit id",
    "nl": "CO2 meeteenheid id"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.DIESELEMISSIONFACTOR": {
    "en": "Diesel emission factor",
    "nl": "Diesel-emissiefactor"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.DIESELENERGYFACTOR": {
    "en": "Diesel energy factor",
    "nl": "Diesel-energiefactor"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.DIESELGENERATORS": {
    "en": "Diesel generators",
    "nl": "Diesel generatoren"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.DIESELTAG": {
    "en": "Diesel generator tags",
    "nl": "Diesel generatoren tags"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.ENERGYMEASUREMENTUNITID": {
    "en": "Energy measurement unit id",
    "nl": "Energie meeteenheid id"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.EVCHARGERS": {
    "en": "EV chargers",
    "nl": "EV opladers"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.EVCHARGERTAG": {
    "en": "EV charger tags",
    "nl": "EV oplader tags"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.GASEMISSIONFACTOR": {
    "en": "Gas emission factor",
    "nl": "Gas-emissiefactor"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.GASENERGYFACTOR": {
    "en": "Gas energy factor",
    "nl": "Gas-energiefactor"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.GASMETERS": {
    "en": "Gas meters",
    "nl": "Gasmeters"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.GASTAG": {
    "en": "Gas meter tags",
    "nl": "Gasmeter tags"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.GRIDCONSUMPTIONMETERS": {
    "en": "Grid consumption meters",
    "nl": "Netconsumptiemeters"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.GRIDCONSUMPTIONTAG": {
    "en": "Grid consumption meter tags",
    "nl": "Netconsumptiemeter tags"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.GRIDEMISSIONFACTOR": {
    "en": "Electricity emission factor",
    "nl": "Electiciteit-emissiefactor"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.GRIDINJECTIONMETERS": {
    "en": "Grid injection meters",
    "nl": "Netinjectiemeters"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.GRIDINJECTIONTAG": {
    "en": "Grid injection meter tags",
    "nl": "Netinjectiemeter tags"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.PRICEGAS": {
    "en": "Gas price",
    "nl": "Gasprijs"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.PRICEGRIDCONSUMPTION": {
    "en": "Grid consumption price",
    "nl": "Electriciteit consumptie prijs"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.PRICEGRIDINJECTION": {
    "en": "Grid injection price",
    "nl": "Electriciteit injectie prijs"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.SOLARPANELS": {
    "en": "Solar invertors",
    "nl": "Zonne-omvormers"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.SOLARTAG": {
    "en": "Solar invertor tags",
    "nl": "Zonne-omvormer tags"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.TAGPRICEDIESEL": {
    "en": "Diesel variable price",
    "nl": "Diesel variabele prijs"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.TAGPRICEGAS": {
    "en": "Gas variable price",
    "nl": "Gas variabele prijs"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.TAGPRICEGRIDCONSUMPTION": {
    "en": "Grid consumption variable price",
    "nl": "Electriciteit consumptie variabele prijs"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.TAGPRICEGRIDINJECTION": {
    "en": "Grid injection variable price",
    "nl": "Electriciteit injectie variabele prijs"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.WINDTAG": {
    "en": "Wind turbine tags",
    "nl": "Windturbine tags"
  },
  "DATASOURCE.ENERGY_MANAGEMENT.WINDTURBINES": {
    "en": "Wind turbines",
    "nl": "Windturbines"
  },
  "DATASOURCE.ENERGY_MANAGEMENTT.PRICEDIESEL": {
    "en": "Diesel price",
    "nl": "Prijs diesel"
  },
  "DATASOURCE.EV_CHARGERS": {
    "en": "EV chargers",
    "nl": "EV laders"
  },
  "DATASOURCE.EV_CHARGING_CONSTRAINT": {
    "en": "EV charging constraints",
    "nl": "EV laadbeperkingen"
  },
  "DATASOURCE.EV_CHARGING_CONSTRAINT.ASSET": {
    "en": "Asset",
    "nl": "Asset"
  },
  "DATASOURCE.EV_CHARGING_SESSIONS": {
    "en": "EV charging sessions",
    "nl": "EV laadsessies"
  },
  "DATASOURCE.PRODUCTION_CONSUMPTION": {
    "en": "Production consumption",
    "nl": "Productie consumptie"
  },
  "DATASOURCE.PRODUCTION_CONSUMPTION.CATEGORY": {
    "en": "Category",
    "nl": "Categorie"
  },
  "DATASOURCE.PRODUCTION_CONSUMPTION.COLOR": {
    "en": "Color",
    "nl": "Kleur"
  },
  "DATASOURCE.PRODUCTION_CONSUMPTION.MEASUREMENTUNITID": {
    "en": "Measurement unit id",
    "nl": "Meeteenheid id"
  },
  "DATASOURCE.PRODUCTION_CONSUMPTION.TAG": {
    "en": "Tag",
    "nl": "Tag"
  },
  "DATASOURCE.SOLAR_PERFORMANCE": {
    "en": "Solar performance",
    "nl": "Zonne opbrengst"
  },
  "DATASOURCE.SOLAR_PERFORMANCE.ACTUAL_ENERGY": {
    "en": "Actual energy",
    "nl": "Effectieve energie"
  },
  "DATASOURCE.SOLAR_PERFORMANCE.ACTUAL_IRRADIANCE": {
    "en": "Actual irradiance",
    "nl": "Effectieve irradiantie"
  },
  "DATASOURCE.SOLAR_PERFORMANCE.ACTUAL_SPECIFIC_ENERGY": {
    "en": "Actual specific energy",
    "nl": "Effectieve specifieke energie"
  },
  "DATASOURCE.SOLAR_PERFORMANCE.ASSET": {
    "en": "Asset",
    "nl": "Asset"
  },
  "DATASOURCE.SOLAR_PERFORMANCE.ENERGY_MEASUREMENTUNITID": {
    "en": "Energy measurement unit",
    "nl": "Energie meeteenheid"
  },
  "DATASOURCE.SOLAR_PERFORMANCE.ESTIMATED_ENERGY": {
    "en": "Estimated energy",
    "nl": "Verwachtte energie"
  },
  "DATASOURCE.SOLAR_PERFORMANCE.ESTIMATED_IRRADIANCE": {
    "en": "Estimated irradiance",
    "nl": "Verwachtte irradiantie"
  },
  "DATASOURCE.SOLAR_PERFORMANCE.ESTIMATED_SPECIFIC_ENERGY": {
    "en": "Estimated specific energy",
    "nl": "Verwachte specifieke energie"
  },
  "DATASOURCE.SOLAR_PERFORMANCE.IRRADIANCE_MEASUREMENTUNITID": {
    "en": "Irradiance measurement unit",
    "nl": "Irradiantie meeteenheid"
  },
  "DATASOURCE.SOLAR_PERFORMANCE.PEAKPOWER_MEASUREMENTUNITID": {
    "en": "Peak power measurement unit",
    "nl": "Piek vermogen meeteenheid"
  },
  "DATASOURCE.SOLAR_PERFORMANCE.PEAKPOWERPARAMETERDEFINITIONID": {
    "en": "Peak power parameter definition",
    "nl": "Piek vermogen parameter definitie"
  },
  "DATASOURCE.TAGS": {
    "en": "Tags",
    "nl": "Tags"
  },
  "DATASOURCE.TAGS.ADD": {
    "en": "Add tags",
    "nl": "Tags toevoegen"
  },
  "DATASOURCE.TAGS.MEASUREMENT_UNIT": {
    "en": "Measurement unit",
    "nl": "Meeteenheid"
  },
  "DATASOURCE.TAGS.REMOVE": {
    "en": "Remove tags",
    "nl": "Tags verwijderen"
  },
  "DATASOURCE.TAGS.TAG": {
    "en": "Tags",
    "nl": "Tags"
  },
  "DATASOURCE.TAGS_HISTORY": {
    "en": "Tags history",
    "nl": "Tags historiek"
  },
  "DATASOURCE.TAGS_HISTORY.AGGREGATE": {
    "en": "Aggregate",
    "nl": "Aggregatie"
  },
  "DATASOURCE.TAGS_HISTORY.AGGREGATE_FILL_OPTION": {
    "en": "Fill option",
    "nl": "Fill option"
  },
  "DATASOURCE.TAGS_HISTORY.AGGREGATION_WINDOW": {
    "en": "Aggregation window",
    "nl": "Aggregatievenster"
  },
  "DATASOURCE.TAGS_HISTORY.DEFAULT_COLOR": {
    "en": "Default color",
    "nl": "Standaard kleur"
  },
  "DATASOURCE.TAGS_HISTORY.MAX_POINTS": {
    "en": "Maximum datapoints",
    "nl": "Maximum datapunten"
  },
  "DATASOURCE.TAGS_HISTORY.MEASUREMENT_UNIT": {
    "en": "Measurement unit",
    "nl": "Meeteenheid"
  },
  "DATASOURCE.TAGS_HISTORY.TAG": {
    "en": "Tag",
    "nl": "Tag"
  },
  "DATASOURCE.TIME_IN_COMFORT": {
    "en": "Time in comfort",
    "nl": "Tijd in comfort"
  },
  "DATASOURCE.TIME_IN_COMFORT.ADD_ITEM": {
    "en": "Add zone",
    "nl": "Zone toevoegen"
  },
  "DATASOURCE.TIME_IN_COMFORT.CO2_COMFORT": {
    "en": "CO2",
    "nl": "CO2"
  },
  "DATASOURCE.TIME_IN_COMFORT.HVAC_MODE": {
    "en": "HVAC mode",
    "nl": "HVAC mode"
  },
  "DATASOURCE.TIME_IN_COMFORT.NAME": {
    "en": "Name",
    "nl": "Naam"
  },
  "DATASOURCE.TIME_IN_COMFORT.OVERALL_COMFORT": {
    "en": "Overall",
    "nl": "Algemeen"
  },
  "DATASOURCE.TIME_IN_COMFORT.REL_HUMIDITY_COMFORT": {
    "en": "Relative humidity",
    "nl": "Relatieve vochtigheid"
  },
  "DATASOURCE.TIME_IN_COMFORT.REMOVE_ITEMS": {
    "en": "Remove zone",
    "nl": "Zone verwijderen"
  },
  "DATASOURCE.TIME_IN_COMFORT.THERMAL_COMFORT": {
    "en": "Thermal",
    "nl": "Thermisch"
  },
  "DATASOURCE.TOPOLOGY.ADD_DEVICE": {
    "en": "Add",
    "nl": "Toevoegen"
  },
  "DATASOURCE.WEATHERFORECAST": {
    "en": "Weather forecast",
    "nl": "Weersvoorspelling"
  },
  "DEVICE_TOPOLOGY": {
    "en": "Energy meter topology",
    "nl": "Energiemeter topologie"
  },
  "DEVICE_TOPOLOGY_WIDGET": {
    "en": "Energy meter topology",
    "nl": "Energiemeter topologie"
  },
  "DONUT_CHART_WIDGET": {
    "en": "Donut chart",
    "nl": "Donut grafiek"
  },
  "ENERGY_DISTRIBUTION_WIDGET": {
    "en": "Energy distribution",
    "nl": "Energie schema"
  },
  "EV_CHARGERS": {
    "en": "EV Chargers",
    "nl": "EV laders"
  },
  "EV_CHARGERS_MAP": {
    "en": "EV chargers map",
    "nl": "EV laders kaart"
  },
  "EV_CHARGERS_MAP_WIDGET": {
    "en": "EV chargers map",
    "nl": "EV laders kaart"
  },
  "EV_CHARGING_CONSTRAINT": {
    "en": "EV charging constraint",
    "nl": "EV laadbeperking"
  },
  "EV_CHARGING_CONSTRAINT.CONSTRAINT": {
    "en": "Constraint",
    "nl": "Beperking"
  },
  "EV_CHARGING_CONSTRAINT.EV_CHARGERS": {
    "en": "EV chargers",
    "nl": "EV laders"
  },
  "EV_CHARGING_CONSTRAINT.REMAINING": {
    "en": "Remaining",
    "nl": "Resterend"
  },
  "EV_CHARGING_CONSTRAINT.UNCONTROLLABLE_LOAD": {
    "en": "Uncontrollable load",
    "nl": "Niet controleerbare belasting"
  },
  "EV_CHARGING_CONSTRAINT_CHART": {
    "en": "EV charging constraint chart",
    "nl": "EV laadbeperking grafiek"
  },
  "EV_CHARGING_SESSION.ASSET": {
    "en": "EVSE",
    "nl": "EVSE"
  },
  "EV_CHARGING_SESSION.CHARGED_ENERGY": {
    "en": "Charged energy (kWh)",
    "nl": "Geladen energie (kWh)"
  },
  "EV_CHARGING_SESSION.CHARGING_END_DATE_TIME": {
    "en": "Charging end",
    "nl": "Einde laden"
  },
  "EV_CHARGING_SESSION.CPO_AUTH_ID": {
    "en": "Auth ID",
    "nl": "Auth ID"
  },
  "EV_CHARGING_SESSION.CPO_SESSION_ID": {
    "en": "Session ID",
    "nl": "Sessie ID"
  },
  "EV_CHARGING_SESSION.END_DATE_TIME": {
    "en": "Session end",
    "nl": "Einde sessie"
  },
  "EV_CHARGING_SESSION.ESTIMATED_DEPARTURE": {
    "en": "Estimated departure",
    "nl": "Verwachte vertrektijd"
  },
  "EV_CHARGING_SESSION.EV_USER": {
    "en": "EV user",
    "nl": "EV user"
  },
  "EV_CHARGING_SESSION.EV_USER_DETAILS": {
    "en": "EV user details",
    "nl": "EV gebruiker details"
  },
  "EV_CHARGING_SESSION.EVSE": {
    "en": "EVSE",
    "nl": "EVSE"
  },
  "EV_CHARGING_SESSION.LAST_UPDATED": {
    "en": "Last updated",
    "nl": "Laatst aangepast"
  },
  "EV_CHARGING_SESSION.MAX_CURRENT": {
    "en": "Max. current",
    "nl": "Max. stroom"
  },
  "EV_CHARGING_SESSION.MIN_CURRENT": {
    "en": "Min. current",
    "nl": "Min. stroom"
  },
  "EV_CHARGING_SESSION.PAUSE_DURATION": {
    "en": "Pause duration",
    "nl": "Pauze duurtijd"
  },
  "EV_CHARGING_SESSION.REQUESTED_ENERGY": {
    "en": "Requested energy (kWh)",
    "nl": "Gevraagde energie (kWh)"
  },
  "EV_CHARGING_SESSION.START_DATE_TIME": {
    "en": "Session start",
    "nl": "Begin sessie"
  },
  "EV_CHARGING_SESSION.STATUS": {
    "en": "Status",
    "nl": "Status"
  },
  "EV_CHARGING_SESSION.USE_L1": {
    "en": "Used L1",
    "nl": "Gebruikt L1"
  },
  "EV_CHARGING_SESSION.USE_L2": {
    "en": "Used L2",
    "nl": "Gebruikt L2"
  },
  "EV_CHARGING_SESSION.USE_L3": {
    "en": "Used L3",
    "nl": "Gebruikt L3"
  },
  "EV_CHARGING_SESSIONS": {
    "en": "EV charging sessions",
    "nl": "EV laadsessies"
  },
  "EV_CHARGING_SESSIONS.HISTORY_OR_ACTIVE": {
    "en": "Show",
    "nl": "Show"
  },
  "EV_CHARGING_WIDGET.SHOW_ACTIVE_SESSIONS": {
    "en": "Active sessions",
    "nl": "Actieve sessies"
  },
  "EV_CHARGING_WIDGET.SHOW_HISTORY": {
    "en": "Session history",
    "nl": "Sessie geschiedenis"
  },
  "HEAT_MAP_WIDGET": {
    "en": "Heat map",
    "nl": "Heat map"
  },
  "HEATMAP.TIME_OF_DAY_ORIENTATION": {
    "en": "Time of day orientation",
    "nl": "Oriëntatie tijd van de dag"
  },
  "HEATMAP.TIME_OF_DAY_ORIENTATION.HORIZONTAL": {
    "en": "Horizontal",
    "nl": "Horizontaal"
  },
  "HEATMAP.TIME_OF_DAY_ORIENTATION.VERTICAL": {
    "en": "Vertical",
    "nl": "Verticaal"
  },
  "LABEL.ASSET_SELECTION": {
    "en": "Asset selection",
    "nl": "Asset selectie"
  },
  "LABEL.ASSET_TYPE_TAG_SELECTION": {
    "en": "Asset type selection",
    "nl": "Asset type selectie"
  },
  "LABEL.CHART_SELECTION": {
    "en": "Chart",
    "nl": "Grafiek"
  },
  "LABEL.DOWNLOAD": {
    "en": "Download",
    "nl": "Download"
  },
  "LABEL.LOADING": {
    "en": "Loading ...",
    "nl": "Aan het laden ..."
  },
  "LABEL.MEASUREMENT_UNIT_SELECTION": {
    "en": "Measurement unit selection",
    "nl": "Meeteenheid selectie"
  },
  "LABEL.OK": {
    "en": "OK",
    "nl": "OK"
  },
  "LABEL.SETTINGS": {
    "en": "Settings",
    "nl": "Instellingen"
  },
  "LABEL.TAG_SELECTION": {
    "en": "Tag selection",
    "nl": "Tag selectie"
  },
  "LINETYPES.LINEAR": {
    "en": "Linear",
    "nl": "Linear"
  },
  "LINETYPES.MONOTONEX": {
    "en": "Monotone X",
    "nl": "Monotoon X"
  },
  "LINETYPES.NATURAL": {
    "en": "Natural",
    "nl": "Natuurlijk"
  },
  "LINETYPES.STEPLINE": {
    "en": "Step line",
    "nl": "Step line"
  },
  "LINETYPES.STEPLINEAFTER": {
    "en": "Step line after",
    "nl": "Step line na"
  },
  "LINETYPES.STEPLINEBEFORE": {
    "en": "Step line before",
    "nl": "Step line voor"
  },
  "MEASUREMENT_UNIT.MEASUREMENT": {
    "en": "Measurement",
    "nl": "Meting"
  },
  "MEASUREMENT_UNIT.NAME": {
    "en": "Name",
    "nl": "Naam"
  },
  "MEASUREMENT_UNIT.SYMBOL": {
    "en": "Symbol",
    "nl": "Symbool"
  },
  "MESSAGE.CHART_DELETED": {
    "en": "Chart deleted",
    "nl": "Grafiek verwijderd"
  },
  "MESSAGE.CHART_SAVED": {
    "en": "Chart saved",
    "nl": "Grafiek opgeslagen"
  },
  "MESSAGE.CIRCULAR_REFERENCE": {
    "en": "Circual reference",
    "nl": "Circulaire referentie"
  },
  "MESSAGE.CONFIRM_DELETE_SELECTED_REPORT": {
    "en": "Are you sure you want to delete the selected report?",
    "nl": "Bent u zeker dat u hets geselecteerde rapport wilt verwijderen?"
  },
  "MESSAGE.DELETE_REPORT": {
    "en": "Delete report",
    "nl": "Verwijder rapport"
  },
  "MESSAGE.REPORT_CREATED": {
    "en": "Report created",
    "nl": "Rapport aangemaakt"
  },
  "MESSAGE.REPORT_DELETED": {
    "en": "Report deleted",
    "nl": "Rapport verwijdered"
  },
  "MESSAGE.REPORT_UPDATED": {
    "en": "Report updated",
    "nl": "Rapport aangepast"
  },
  "MESSAGE.SAME_NAME": {
    "en": "Same name",
    "nl": "Gebruikte naam"
  },
  "PARAMETER_DEFINITION.CODE": {
    "en": "Code",
    "nl": "Code"
  },
  "PARAMETER_DEFINITION.DESCRIPTION": {
    "en": "Description",
    "nl": "Omschrijving"
  },
  "REPORT": {
    "en": "Report",
    "nl": "Rapport"
  },
  "REPORT.DATASOURCES": {
    "en": "Data sources",
    "nl": "Databronnen"
  },
  "REPORT.GENERAL": {
    "en": "General",
    "nl": "Algemeen"
  },
  "REPORT.NAME": {
    "en": "Name",
    "nl": "Naam"
  },
  "REPORT.PARAMETERS": {
    "en": "Parameters",
    "nl": "Parameters"
  },
  "REPORT.REPORTDEFINITION": {
    "en": "Report definition",
    "nl": "Rapportdefinitie"
  },
  "REPORT_WIDGET": {
    "en": "Download report",
    "nl": "Download rapport"
  },
  "REPORTWIDGET.REPORT": {
    "en": "Report",
    "nl": "Rapport"
  },
  "REPORTWIDGET.USE_DASHBOARD_DATETIME": {
    "en": "Use dashboard date and time",
    "nl": "Gebruik dashboard datum en tijd"
  },
  "SELECT_ASSET": {
    "en": "Select asset",
    "nl": "Selecteer asset"
  },
  "SELECT_ASSET_TYPE": {
    "en": "Select asset type",
    "nl": "Selecteer asset type"
  },
  "SELECT_CHART": {
    "en": "Select chart",
    "nl": "Selecteer grafiek"
  },
  "SELECT_GRID_CHART": {
    "en": "Grid chart",
    "nl": "Elektriciteitsnet grafiek"
  },
  "SELECT_MEASUREMENTUNIT": {
    "en": "Measurementunit",
    "nl": "Meeteenheid"
  },
  "SELECT_PARAMETERD_DEFINITION": {
    "en": "Select parameter definition",
    "nl": "Selecteer parameter definitie"
  },
  "SELECT_REPORT": {
    "en": "Select report",
    "nl": "Selecteer rapport"
  },
  "SELECT_REPORT_DEFINITION": {
    "en": "Report definition",
    "nl": "Rapport definitie"
  },
  "SELECT_TAG": {
    "en": "Select tag",
    "nl": "Selecteer tag"
  },
  "SELECT_TEMPLATE": {
    "en": "Select template",
    "nl": "Selecteer template"
  },
  "TAG.ASSET": {
    "en": "Asset",
    "nl": "Asset"
  },
  "TAG.ASSETTYPE": {
    "en": "Asset type",
    "nl": "Asset type"
  },
  "TAG.FEEDBACK": {
    "en": "Feedback",
    "nl": "Feedback"
  },
  "TAG.MEASUREMENT_UNIT": {
    "en": "Measurement unit",
    "nl": "Meeteenheid"
  },
  "TAG.MEASUREMENT_UNIT.SYMBOL": {
    "en": "Measurement unit symbol",
    "nl": "Meeteenheid symbool"
  },
  "TAG.NAME": {
    "en": "Name",
    "nl": "Naam"
  },
  "TAG.SETPOINT": {
    "en": "Setpoint",
    "nl": "Setpunt"
  },
  "TAG_CIRCULAR_GAUGE_WIDGET": {
    "en": "Circular gauge",
    "nl": "Circelvormige meter"
  },
  "TAG_GROUPED_STACKED_BAR_CHART_WIDGET": {
    "en": "Bar chart",
    "nl": "Staafdiagram"
  },
  "TAG_LEGEND_WIDGET": {
    "en": "Legend",
    "nl": "Legende"
  },
  "TAG_LINE_CHART_WIDGET": {
    "en": "Line chart",
    "nl": "Lijn grafiek"
  },
  "TAG_LINEAR_GAUGE_WIDGET": {
    "en": "Linear gauge",
    "nl": "Lineaire meter"
  },
  "TAG_VALUE_WIDGET": {
    "en": "Single value",
    "nl": "Enkele waarde"
  },
  "TAG_WIDGET.DIRECTION.BOTTOMTOTOP": {
    "en": "Bottom to top",
    "nl": "Onder naar boven"
  },
  "TAG_WIDGET.DIRECTION.LEFTTORIGHT": {
    "en": "Left to right",
    "nl": "Links naar rechts"
  },
  "TAG_WIDGET.DIRECTION.RIGHTTOLEFT": {
    "en": "Right to left",
    "nl": "Rechts naar links"
  },
  "TAG_WIDGET.DIRECTION.TOPTOBOTTOM": {
    "en": "Top to bottom",
    "nl": "Boven naar onder"
  },
  "TAG_WIDGET.LINE_TYPE": {
    "en": "Line Type",
    "nl": "Lijn type"
  },
  "TAG_WIDGET.MAXIMUM_VALUE": {
    "en": "Default max value",
    "nl": "Standaard max waarde"
  },
  "TAG_WIDGET.MINIMUM_VALUE": {
    "en": "Default min value",
    "nl": "Standaard min waarde"
  },
  "TAG_WIDGET.RANGE.COLOR": {
    "en": "Color",
    "nl": "Kleur"
  },
  "TAG_WIDGET.RANGE.ENDVALUE": {
    "en": "End value",
    "nl": "Eindwaarde"
  },
  "TAG_WIDGET.RANGE.STARTVALUE": {
    "en": "Start value",
    "nl": "Beginwaarde"
  },
  "TAG_WIDGET.RANGES": {
    "en": "Ranges",
    "nl": "Bereiken"
  },
  "TAG_WIDGET.RANGES.ADD": {
    "en": "Add",
    "nl": "Voeg toe"
  },
  "TAG_WIDGET.RANGES.REMOVE": {
    "en": "Remove",
    "nl": "Verwijder"
  },
  "TAG_WIDGET.SCALEPOSITION.BOTTOM": {
    "en": "Bottom",
    "nl": "Onder"
  },
  "TAG_WIDGET.SCALEPOSITION.LEFT": {
    "en": "Left",
    "nl": "Links"
  },
  "TAG_WIDGET.SCALEPOSITION.RIGHT": {
    "en": "Right",
    "nl": "Rechts"
  },
  "TAG_WIDGET.SCALEPOSITION.TOP": {
    "en": "Top",
    "nl": "Boven"
  },
  "TAG_WIDGET.SHOW_RANGE": {
    "en": "Show range",
    "nl": "Toon bereik"
  },
  "TAG_WIDGET.TAG": {
    "en": "Tag",
    "nl": "Tag"
  },
  "TAG_WIDGET.Y_AXIS_MEASUREMENTUNIT": {
    "en": "Show Y axis legend",
    "nl": "Toon Y as legende"
  },
  "TAGCHART.NAME": {
    "en": "Name",
    "nl": "Naam"
  },
  "TAGS_VALUE_WIDGET": {
    "en": "Multiple values",
    "nl": "Meerdere waarden"
  },
  "VIEW_STATIC_LIST.DAYFILTER.ALLDAYS": {
    "en": "All days",
    "nl": "Alle dagen"
  },
  "VIEW_STATIC_LIST.DAYFILTER.WEEKDAYS": {
    "en": "Weekdays",
    "nl": "Weekdagen"
  },
  "VIEW_STATIC_LIST.DAYFILTER.WEEKENDDAYS": {
    "en": "Weekend days",
    "nl": "Weekenddagen"
  },
  "VIEW_STATIC_LIST.ENERGYANALYSISCATEGORY.ELECTRICITYMIX": {
    "en": "Electricity mix",
    "nl": "Electriciteitsmix"
  },
  "VIEW_STATIC_LIST.ENERGYANALYSISCATEGORY.ENERGYMIX": {
    "en": "Energy mix",
    "nl": "Energiemix"
  },
  "VIEW_STATIC_LIST.ENERGYANALYSISCATEGORY.RENEWABLEENERGY": {
    "en": "Renewable energy percentage",
    "nl": "Hernieuwbare energie percentage"
  },
  "VIEW_STATIC_LIST.ENERGYANALYSISCATEGORY.SELFCONSUMPTION": {
    "en": "Self-consumption percentage",
    "nl": "Zelf consumption percentage"
  },
  "VIEW_STATIC_LIST.ENERGYCATEGORY.BATTERY": {
    "en": "Battery",
    "nl": "Batterij"
  },
  "VIEW_STATIC_LIST.ENERGYCATEGORY.DIESEL": {
    "en": "Diesel",
    "nl": "Diesel"
  },
  "VIEW_STATIC_LIST.ENERGYCATEGORY.ELECTRICITYMIX": {
    "en": "Electricity mix",
    "nl": "Electriciteitsmix"
  },
  "VIEW_STATIC_LIST.ENERGYCATEGORY.ENERGYMIX": {
    "en": "Energy mix",
    "nl": "Energiemix"
  },
  "VIEW_STATIC_LIST.ENERGYCATEGORY.EV": {
    "en": "EV chargers",
    "nl": "EV-opladers"
  },
  "VIEW_STATIC_LIST.ENERGYCATEGORY.GAS": {
    "en": "Gas",
    "nl": "Gas"
  },
  "VIEW_STATIC_LIST.ENERGYCATEGORY.RENEWEABLEENERGY": {
    "en": "Renewable energy",
    "nl": "Hernieuwbare energie"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.CO2DIESEL": {
    "en": "CO2 diesel",
    "nl": "CO2 diesel"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.CO2ELECTRICITYMIX": {
    "en": "CO2 electricity mix",
    "nl": "CO2 electriciteitsmix"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.CO2ELECTRICITYMIXWITHOUTEV": {
    "en": "CO2 electricity mix without EV",
    "nl": "CO2 electriciteitsmix zonder EV"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.CO2ENERGYMIX": {
    "en": "CO2 energy mix",
    "nl": "CO2 energiemix"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.CO2EV": {
    "en": "CO2 EV charger",
    "nl": "CO EV-lader"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.CO2GAS": {
    "en": "CO2 gas",
    "nl": "CO2 gas"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.CO2GRID": {
    "en": "CO2 grid",
    "nl": "CO2 electriciteitsnet"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.CO2SAVED": {
    "en": "CO2 avoided",
    "nl": "CO2 vermeden"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYBATTERYENERGYIN": {
    "en": "Battery energy IN",
    "nl": "Energieopname batterij"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYBATTERYENERGYOUT": {
    "en": "Battery energy OUT",
    "nl": "Energieafgifte batterij"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYDIESEL": {
    "en": "Energy diesel",
    "nl": "Energie diesel"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYELECTRICITYMIX": {
    "en": "Energy electricity mix",
    "nl": "Energie electriciteitsmix"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYELECTRICITYMIXWITHOUTEV": {
    "en": "Energy electicity mix without EV",
    "nl": "Energie electiciteitsmix without EV"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYENERGYMIX": {
    "en": "Energy energy mix",
    "nl": "Energy energiemix"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYEV": {
    "en": "Energy EV charger",
    "nl": "Energie EV-lader"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYGAS": {
    "en": "Energy gas",
    "nl": "Energie gas"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYGRIDCONSUMPTION": {
    "en": "Energy grid consumption",
    "nl": "Energie glectriciteit consumptie"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYGRIDINJECTION": {
    "en": "Energy grid injection",
    "nl": "Energie electriciteit injection"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYSELFCONSUMPTION": {
    "en": "Energy self consumption",
    "nl": "Energie zelf-verbruik"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYSOLAR": {
    "en": "Solar energy",
    "nl": "Zonne-energie"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYWIND": {
    "en": "Wind energy",
    "nl": "Windenergie"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICEDIESEL": {
    "en": "Cost price diesel",
    "nl": "Kostprijs diesel"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICEELECTRICITYMIX": {
    "en": "Cost price electricity mix",
    "nl": "kostprijs electriciteitsmix"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICEELECTRICITYMIXWITHOUTEV": {
    "en": "Cost price electricity mix without EV",
    "nl": "kostprijs electriciteitsmix zonder EV"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICEENERGYMIX": {
    "en": "Cost price energy mix",
    "nl": "Kostprijs energiemix"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICEEV": {
    "en": "Cost price EV charger",
    "nl": "Kostprijs EV-lader"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICEGAS": {
    "en": "Cost price gas",
    "nl": "Kostprijs gas"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICEGRIDCONSUMPTION": {
    "en": "Cost price grid consumption",
    "nl": "Kostprijs electiciteitsverbruik"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICEGRIDINJECTION": {
    "en": "Revenue grid injection",
    "nl": "Inkomsten electriciteit injectie"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICESAVED": {
    "en": "Savings self-consumption",
    "nl": "Besparing zelfconsumptie"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.RATIORENEWABLEENERGY": {
    "en": "Ratio renewable energy",
    "nl": "Percentage hernieuwbare energie"
  },
  "VIEW_STATIC_LIST.ENERGYVALUECATEGORY.RATIOSELFCONSUMPTION": {
    "en": "Ratie self-consumption",
    "nl": "Percentage zelfconsumption"
  },
  "VIEW_STATIC_LIST.EV_SESSION_STATUS.ACTIVE": {
    "en": "Active",
    "nl": "Actief"
  },
  "VIEW_STATIC_LIST.EV_SESSION_STATUS.COMPLETE": {
    "en": "Complete",
    "nl": "Voltooid"
  },
  "VIEW_STATIC_LIST.EV_SESSION_STATUS.UNKNOWN": {
    "en": "Unknown",
    "nl": "Onbekend"
  },
  "VIEW_STATIC_LIST.FILLOPTION.LINEAR": {
    "en": "Linear",
    "nl": "Linear"
  },
  "VIEW_STATIC_LIST.FILLOPTION.NONE": {
    "en": "None",
    "nl": "Geen"
  },
  "VIEW_STATIC_LIST.FILLOPTION.NULL": {
    "en": "Null",
    "nl": "Nul"
  },
  "VIEW_STATIC_LIST.FILLOPTION.PREVIOUS": {
    "en": "Previous",
    "nl": "Vorige"
  },
  "VIEW_STATIC_LIST.FILLTIMEINTERVAL.AUTO": {
    "en": "Auto",
    "nl": "Auto"
  },
  "VIEW_STATIC_LIST.FILLTIMEINTERVAL.EIGHT_HOUR": {
    "en": "8h",
    "nl": "8h"
  },
  "VIEW_STATIC_LIST.FILLTIMEINTERVAL.FIFTEEN_MINUTES": {
    "en": "15m",
    "nl": "15m"
  },
  "VIEW_STATIC_LIST.FILLTIMEINTERVAL.FIFTEEN_SECONDS": {
    "en": "15s",
    "nl": "15s"
  },
  "VIEW_STATIC_LIST.FILLTIMEINTERVAL.FIVE_MINUTES": {
    "en": "5m",
    "nl": "5m"
  },
  "VIEW_STATIC_LIST.FILLTIMEINTERVAL.FIVE_SECONDS": {
    "en": "5s",
    "nl": "5s"
  },
  "VIEW_STATIC_LIST.FILLTIMEINTERVAL.FOUR_HOUR": {
    "en": "4h",
    "nl": "4h"
  },
  "VIEW_STATIC_LIST.FILLTIMEINTERVAL.NONE": {
    "en": "None",
    "nl": "Geen"
  },
  "VIEW_STATIC_LIST.FILLTIMEINTERVAL.ONE_DAY": {
    "en": "1d",
    "nl": "1d"
  },
  "VIEW_STATIC_LIST.FILLTIMEINTERVAL.ONE_HOUR": {
    "en": "1h",
    "nl": "1h"
  },
  "VIEW_STATIC_LIST.FILLTIMEINTERVAL.ONE_MINUTE": {
    "en": "1m",
    "nl": "1m"
  },
  "VIEW_STATIC_LIST.FILLTIMEINTERVAL.ONE_SECOND": {
    "en": "1s",
    "nl": "1s"
  },
  "VIEW_STATIC_LIST.FILLTIMEINTERVAL.TEN_MINUTES": {
    "en": "10m",
    "nl": "10m"
  },
  "VIEW_STATIC_LIST.FILLTIMEINTERVAL.TEN_SECONDS": {
    "en": "10s",
    "nl": "10s"
  },
  "VIEW_STATIC_LIST.FILLTIMEINTERVAL.THIRTY_MINUTES": {
    "en": "30m",
    "nl": "30m"
  },
  "VIEW_STATIC_LIST.FILLTIMEINTERVAL.THIRTY_SEONDS": {
    "en": "30s",
    "nl": "30s"
  },
  "VIEW_STATIC_LIST.FILLTIMEINTERVAL.THREE_HOUR": {
    "en": "3h",
    "nl": "3h"
  },
  "VIEW_STATIC_LIST.FILLTIMEINTERVAL.THREE_MINUTES": {
    "en": "3m",
    "nl": "3m"
  },
  "VIEW_STATIC_LIST.FILLTIMEINTERVAL.THREE_SECONDS": {
    "en": "3s",
    "nl": "3s"
  },
  "VIEW_STATIC_LIST.FILLTIMEINTERVAL.TWELVE_HOUR": {
    "en": "12h",
    "nl": "12h"
  },
  "VIEW_STATIC_LIST.FILLTIMEINTERVAL.TWENTY_SEONDS": {
    "en": "20s",
    "nl": "20s"
  },
  "VIEW_STATIC_LIST.FILLTIMEINTERVAL.TWO_HOUR": {
    "en": "2h",
    "nl": "2h"
  },
  "VIEW_STATIC_LIST.FILLTIMEINTERVAL.TWO_MINUTES": {
    "en": "2m",
    "nl": "2m"
  },
  "VIEW_STATIC_LIST.FILLTIMEINTERVAL.TWO_SECONDS": {
    "en": "2s",
    "nl": "2s"
  },
  "VIEW_STATIC_LIST.TIMEUNIT.AUTO": {
    "en": "Auto",
    "nl": "Auto"
  },
  "VIEW_STATIC_LIST.TIMEUNIT.DAY": {
    "en": "Day",
    "nl": "Dag"
  },
  "VIEW_STATIC_LIST.TIMEUNIT.HOUR": {
    "en": "Hour",
    "nl": "Uur"
  },
  "VIEW_STATIC_LIST.TIMEUNIT.MONTH": {
    "en": "Month",
    "nl": "Maand"
  },
  "VIEW_STATIC_LIST.TIMEUNIT.QUARTER": {
    "en": "Quarter",
    "nl": "Kwartaal"
  },
  "VIEW_STATIC_LIST.TIMEUNIT.QUARTERHOUR": {
    "en": "Quarter hour",
    "nl": "Kwartier"
  },
  "VIEW_STATIC_LIST.TIMEUNIT.WEEK": {
    "en": "Week",
    "nl": "Week"
  },
  "VIEW_STATIC_LIST.TIMEUNIT.YEAR": {
    "en": "Year",
    "nl": "Jaar"
  },
  "VIEW_STATIC_LIST.VALUETYPE.ABSOLUTEEMISSION": {
    "en": "Absolute emission",
    "nl": "Absolute uitstoot"
  },
  "VIEW_STATIC_LIST.VALUETYPE.ABSOLUTEENERGY": {
    "en": "Absolute energy",
    "nl": "Absolute energie"
  },
  "VIEW_STATIC_LIST.VALUETYPE.ABSOLUTEPRIMARYENERGY": {
    "en": "Absolute primary energy",
    "nl": "Absolute primaire energie"
  },
  "VIEW_STATIC_LIST.VALUETYPE.RELATIVEEMISSIONTODEGREEDAYS": {
    "en": "Relative emission to degree days",
    "nl": "Relatieve uitstoot tov graaddagen"
  },
  "VIEW_STATIC_LIST.VALUETYPE.RELATIVEEMISSIONTOFLOORSURFACE": {
    "en": "Relative emission to floor surface",
    "nl": "Relatieve uitstoot tov vloeroppervlakte"
  },
  "VIEW_STATIC_LIST.VALUETYPE.RELATIVEEMISSIONTOFLOORSURFACEANDDEGREEDAYS": {
    "en": "Relative emission to floor surface and degree days",
    "nl": "Relatieve uitstoot tov vloeroppervlakte en graaddagen"
  },
  "VIEW_STATIC_LIST.VALUETYPE.RELATIVEENERGYTODEGREEDAYS": {
    "en": "Relative energy to degree days",
    "nl": "Relatieve energie tov graaddagen"
  },
  "VIEW_STATIC_LIST.VALUETYPE.RELATIVEENERGYTOFLOORSURFACE": {
    "en": "Relative energy to floor surface",
    "nl": "Relatieve energie tov vloeroppervlakte"
  },
  "VIEW_STATIC_LIST.VALUETYPE.RELATIVEENERGYTOFLOORSURFACEANDDEGREEDAYS": {
    "en": "Relative energy to floor surface and degree days",
    "nl": "Relatieve energie tov vloeroppervlakte en graaddagen"
  },
  "VIEW_STATIC_LIST.VALUETYPE.RELATIVEPRIMARYENERGYTODEGREEDAYS": {
    "en": "Relative primary energy to degree days",
    "nl": "Relatieve primaire energie tov graaddagen"
  },
  "VIEW_STATIC_LIST.VALUETYPE.RELATIVEPRIMARYENERGYTOFLOORSURFACE": {
    "en": "Relative primary energy to floor surface",
    "nl": "Relatieve primary energie tov vloeroppervlakte"
  },
  "VIEW_STATIC_LIST.VALUETYPE.RELATIVEPRIMARYENERGYTOFLOORSURFACEANDDEGREEDAYS": {
    "en": "Relative primary energy to floor surface and degree days",
    "nl": "Relatieve primaire energie tov vloeroppervlakte en graaddagen"
  },
  "WEATHER_FORECAST_DAILY_WIDGET": {
    "en": "Weather forecast - daily",
    "nl": "Weersvoorspelling - dagelijks"
  },
  "WEATHER_FORECAST_HOURLY_WIDGET": {
    "en": "Weather forecast - hourly",
    "nl": "Weersvoorspelling - uurlijks"
  },
  "WIDGET.ASSET": {
    "en": "Asset",
    "nl": "Asset"
  },
  "WIDGET.BARWIDTH": {
    "en": "Bar width",
    "nl": "Balk breedte"
  },
  "WIDGET.BATTERY_CHART": {
    "en": "Battery chart",
    "nl": "Batterij grafiek"
  },
  "WIDGET.bottomMargin": {
    "en": "Bottom margin",
    "nl": "Onder marge"
  },
  "WIDGET.BUILDING_CHART": {
    "en": "Building chart",
    "nl": "Gebouw grafiek"
  },
  "WIDGET.BUILDINGINDEX": {
    "en": "Building index",
    "nl": "Gebouw index"
  },
  "WIDGET.CATEGORY": {
    "en": "Category",
    "nl": "Categorie"
  },
  "WIDGET.CIRCLECOLOR": {
    "en": "Circle color",
    "nl": "Cirkel kleur"
  },
  "WIDGET.DATETIMEFORMAT": {
    "en": "Date time format",
    "nl": "Tijdsformaat"
  },
  "WIDGET.DAYFILTER": {
    "en": "Day filter",
    "nl": "Dag filter"
  },
  "WIDGET.DEFAULTRANGECOLOR": {
    "en": "Default color",
    "nl": "Standaardkleur"
  },
  "WIDGET.DIRECTION": {
    "en": "Direction",
    "nl": "Richting"
  },
  "WIDGET.ENDANGLE": {
    "en": "End angle",
    "nl": "Eindhoek"
  },
  "WIDGET.EVCHARGER_CHART": {
    "en": "EV-Charger chart",
    "nl": "EV-Lader grafiek"
  },
  "WIDGET.FONTSIZE": {
    "en": "Font size",
    "nl": "Lettergrootte"
  },
  "WIDGET.GRID_CHART": {
    "en": "Grid chart",
    "nl": "Elektriciteitsnet grafiek"
  },
  "WIDGET.HEIGHT": {
    "en": "Height",
    "nl": "Hoogte"
  },
  "WIDGET.HIDELABELS": {
    "en": "Hide labels",
    "nl": "Labels verbergen"
  },
  "WIDGET.HIDEREGRESSIONLINE": {
    "en": "Hide regression line",
    "nl": "Verberg regressie lijn"
  },
  "WIDGET.HORIZONTAL_GRID": {
    "en": "Horizontal grid",
    "nl": "Horizontale grid"
  },
  "WIDGET.INNERRADIUS": {
    "en": "Inner radius",
    "nl": "Binnenstraal"
  },
  "WIDGET.ITEM": {
    "en": "Item",
    "nl": "Item"
  },
  "WIDGET.LEFTMARGIN": {
    "en": "Left margin",
    "nl": "Linker marge"
  },
  "WIDGET.MAJORTICKSCOLOR": {
    "en": "Major ticks color",
    "nl": "Majeur teken kleur"
  },
  "WIDGET.MARGIN_BOTTOM": {
    "en": "Margin bottom",
    "nl": "Onderste marge"
  },
  "WIDGET.MARGIN_LEFT": {
    "en": "Margin left",
    "nl": "Linkse marge"
  },
  "WIDGET.MARGIN_RIGHT": {
    "en": "Margin right",
    "nl": "Rechtse marge"
  },
  "WIDGET.MARGIN_TOP": {
    "en": "Margin top",
    "nl": "Bovenste marge"
  },
  "WIDGET.MAXVALUE": {
    "en": "Maximum value",
    "nl": "Maximum waarde"
  },
  "WIDGET.MINORTICKSCOLOR": {
    "en": "Minor ticks color",
    "nl": "Mineur teken kleur"
  },
  "WIDGET.MINVALUE": {
    "en": "Minimum value",
    "nl": "Minimum waarde"
  },
  "WIDGET.NEEDLECOLOR": {
    "en": "Needle color",
    "nl": "Naald kleur"
  },
  "WIDGET.NEG_MAX_COLOR": {
    "en": "Negative colors: max. value",
    "nl": "Negatieve kleuren: max. waarde"
  },
  "WIDGET.NEG_MIN_COLOR": {
    "en": "Negative colors: min. value",
    "nl": "Negatieve kleuren: min. waarde"
  },
  "WIDGET.NUMBEROFDECIMALS": {
    "en": "Number of decimal digits",
    "nl": "Aantal decimale cijfers"
  },
  "WIDGET.NUMBEROFDECIMALSXVALUE": {
    "en": "Number of decimal digits X value",
    "nl": "Aantal decimale cijfers X waarde"
  },
  "WIDGET.NUMBEROFDECIMALSYVALUE": {
    "en": "Number of decimal digits Y value",
    "nl": "Aantal decimale cijfers Y waarde"
  },
  "WIDGET.NUMBEROFMAJORTICKS": {
    "en": "Number of major ticks",
    "nl": "Aantal majeure tekens"
  },
  "WIDGET.NUMBEROFMINORTICKS": {
    "en": "Number of minor ticks",
    "nl": "Aantal mineure tekens"
  },
  "WIDGET.OUTERRADIUS": {
    "en": "Outer radius",
    "nl": "Buitenstraal"
  },
  "WIDGET.POS_MAX_COLOR": {
    "en": "Positive colors: max. value",
    "nl": "Positieve kleuren: max. waarde"
  },
  "WIDGET.POS_MIN_COLOR": {
    "en": "Positive colors: min. value",
    "nl": "Positieve kleuren: min. value"
  },
  "WIDGET.REGRESSIONLINECOLOR": {
    "en": "Regression line color",
    "nl": "Regressie lijn kleur"
  },
  "WIDGET.RIGHTMARGIN": {
    "en": "Right margin",
    "nl": "Rechter marge"
  },
  "WIDGET.SCALEPOSITION": {
    "en": "Scale position",
    "nl": "Schaal positie"
  },
  "WIDGET.SELECTEDCATEGORIES": {
    "en": "Selected categories",
    "nl": "Geselecteerde categorieën"
  },
  "WIDGET.SETPOINTCOLOR": {
    "en": "Setpoint color",
    "nl": "Setpunt kleur"
  },
  "WIDGET.SOLAR_CHART": {
    "en": "Solar chart",
    "nl": "Zonnepanelen grafiek"
  },
  "WIDGET.STACKED": {
    "en": "Stacked",
    "nl": "Stacked"
  },
  "WIDGET.STARTANGLE": {
    "en": "Start angle",
    "nl": "Starthoek"
  },
  "WIDGET.SUBFONTSIZE": {
    "en": "Font size subline",
    "nl": "Lettergrootte subregel"
  },
  "WIDGET.SUPPORTED_DATATYPES": {
    "_duplicate": true,
    "en": "Supported datatypes",
    "nl": "Ondersteunde datatypes"
  },
  "WIDGET.TAG": {
    "en": "Tag",
    "nl": "Tag"
  },
  "WIDGET.topMargin": {
    "en": "Top margin",
    "nl": "Boven marge"
  },
  "WIDGET.TRENDVIEW": {
    "en": "Trend view",
    "nl": "Trendweergave"
  },
  "WIDGET.VALUE_CATEGORY": {
    "en": "Category",
    "nl": "Categorie"
  },
  "WIDGET.VALUE_SUBCATEGORY": {
    "en": "Subcategory",
    "nl": "Subcategorie"
  },
  "WIDGET.VALUECOLOR": {
    "en": "Value color",
    "nl": "Waarde kleur"
  },
  "WIDGET.VALUEPOINTERCOLOR": {
    "en": "Value pointer color",
    "nl": "Waardewijzer kleur"
  },
  "WIDGET.VALUETYPE": {
    "en": "Value type",
    "nl": "type"
  },
  "WIDGET.VERTICAL_GRID": {
    "en": "Vertical grid",
    "nl": "Verticale grid"
  },
  "WIDGET.WIDTH": {
    "en": "Width",
    "nl": "Breedte"
  },
  "WIDGET.WIND_CHART": {
    "en": "Wind chart",
    "nl": "Windturbine grafiek"
  },
  "WRITE_TAG_VALUE": {
    "en": "Write tag value",
    "nl": "Schrijf tag waarde"
  },
  "WRITE_TAG_VALUE.SUCCESS": {
    "en": "Tag value successful written",
    "nl": "Tag waarde met success geschreven"
  }
};
