<div class="ev-chargers-map-widget">
  <kendo-loader *ngIf="widgetInstance?.context?.isLoading" [type]="'converging-spinner'" [themeColor]="'primary'" [size]="'large'">
  </kendo-loader>

  <div #svgContainer [ngClass]="{ 'hidden': error, 'svg-container': true }">
    
  </div>
  
  <span *ngIf="error && !isLoading" class="widget-error">{{error}}</span>
</div>
