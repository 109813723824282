import { Component, Inject, Injector } from '@angular/core';
import { keyBy, flatten, clone, intersection ,map} from "lodash-es";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CoreService, DashboardWidgetDocument, DataSource, DataSourceType, DATASOURCETYPES, EntitySelectionPopupSettings, TranslateService, WidgetConfigComponent } from '@ats/ats-platform-dashboard';
import { ENERGY_MANAGEMENT_CATEGORIES } from '../../domain/enums/energy-management-categories';
import { TagConfig } from '../../domain/entities/tagConfig';

@Component({
  selector: 'ats-smart-tool-tag-legend-widget-config',
  templateUrl: './tag-legend-widget-config.component.html',
  styleUrls: ['./tag-legend-widget-config.component.scss']
})
export class TagLegendWidgetConfigComponent implements WidgetConfigComponent {

  public dataSourceSelectionPopupSettings: EntitySelectionPopupSettings;
  public dataSourceFormatter = (dataSource: DataSource) => dataSource ? dataSource.Folder.Path + ' / ' + dataSource.Name : null;

  public fg: FormGroup;
  public formGroup: FormGroup;
  public categories: any[] = [];
  public dataSourceItemsLoading: boolean = false;

  private dataSourceTypes: { [type: string]: DataSourceType };
  private currentDataSource: DataSource = null;

  constructor(public core: CoreService, public translate: TranslateService, @Inject(DATASOURCETYPES) dataSourceTypes: DataSourceType[][], private injector: Injector) {

    this.dataSourceTypes = keyBy(flatten(dataSourceTypes), (type: DataSourceType) => type.name);

    this.formGroup = new FormGroup({
      title: new FormControl(''),
      dataSourceId: new FormControl(null, Validators.required),
      selectedCategories: new FormControl(null),
      widgetType: new FormControl(translate.get('TAG_LEGEND_WIDGET'))
    });

    this.dataSourceSelectionPopupSettings = {
      title: translate.get('i18n:SELECT_DATASOURCE'),
      entitySet: 'DataSources',
      filter: {
        logic: 'or',
        filters: [
          { field: 'Type', operator: 'eq', value: 'productionConsumption' }, { field: 'Type', operator: 'eq', value: 'buildingEnergy' }, { field: 'Type', operator: 'eq', value: 'tagsHistory' }, { field: 'Type', operator: 'eq', value: 'energyManagement' },
          { field: 'Type', operator: 'eq', value: 'evChargingConstraint' }
        ]
      },
      includes: 'Configs,Folder',
      sort: [{ field: 'Folder.Path', dir: 'asc' },{ field: 'Name', dir: 'asc' }],
      multiSelect: false,
      selectionRequired: true,
      columns: [{ field: 'Folder.Path', title: this.translate.get('i18n:FOLDER.PATH'), filterable: true },{ field: 'Name', title: translate.get('i18n:DATASOURCE.NAME'), filterable: true }],
      info: translate.get('i18n:WIDGET.SUPPORTED_DATATYPES') +': '+ translate.get('i18n:DATASOURCE.PRODUCTION_CONSUMPTION')+', '+ translate.get('i18n:DATASOURCE.BUILDINGENERGY')+', '
        + translate.get('i18n:DATASOURCE.TAGS_HISTORY')+', '+ translate.get('i18n:DATASOURCE.ENERGY_MANAGEMENT') +': '+ translate.get('i18n:DATASOURCE.EV_CHARGING_CONSTRAINT')
    };
  }
  public onDataSourceChange = (dataSource: DataSource) => {
    if (dataSource) {
      this.currentDataSource = dataSource;
      const dataSourceType: DataSourceType = this.dataSourceTypes[dataSource.Type];
      switch (dataSourceType.name) {
        case "productionConsumption":
          this.dataSourceItemsLoading = true;
          var tagConfigJson = dataSource.Configs.filter(x => x.Name === "Tags")[0].Value;
          var tagConfig: TagConfig[] = JSON.parse(tagConfigJson);
          this.categories = 
            [...new Map(tagConfig.map(x => ({Name: x.Category, Display: x.Category})).map(item =>
            [item["Name"], item])).values()];

          var selectedCategories = this.formGroup.get('selectedCategories').value;
          var allCategories = map(this.categories, c => c.Name);
          if (allCategories && allCategories.length && selectedCategories && selectedCategories.length) {
            selectedCategories = intersection(allCategories, selectedCategories);
            if (!selectedCategories || !selectedCategories.length)
              selectedCategories = clone(allCategories);
          } else {
            selectedCategories = clone(allCategories);
          }
          this.formGroup.get('selectedCategories').setValue(selectedCategories);

          this.dataSourceItemsLoading = false;
          break;
        case "energyManagement":
          this.categories = this.core.translateObjectArray(ENERGY_MANAGEMENT_CATEGORIES, 'Display');

          var selectedCategories = this.formGroup.get('selectedCategories').value;
          var allCategories = map(this.categories, c => c.Name);
          if (allCategories && allCategories.length && selectedCategories && selectedCategories.length) {
            selectedCategories = intersection(allCategories, selectedCategories);
            if (!selectedCategories || !selectedCategories.length)
              selectedCategories = clone(['electricityMix']);
          } else {
            selectedCategories = clone(['electricityMix']);
          }
          this.formGroup.get('selectedCategories').setValue(selectedCategories);
          
          break;
        default:
          this.categories = [];
      }
    } else {
      this.categories = [];
    }
  };

  public isValid(): boolean {
    this.formGroup.markAllAsTouched();
    return this.formGroup.valid;
  }

  public getConfig(): any {
    return {
      title: this.formGroup.get('title').value,
      dataSourceId: this.formGroup.get('dataSourceId').value,
      selectedCategories: this.formGroup.get('selectedCategories').value,
    };
  }

  public getDocuments(): DashboardWidgetDocument[] {
    return null;
  }

  public setData(config: any, documents: DashboardWidgetDocument[]) {
    this.formGroup.get('title').setValue(config?.title ?? '');
    this.formGroup.get('dataSourceId').setValue(config?.dataSourceId);
    this.formGroup.get('selectedCategories').setValue(config?.selectedCategories);
  }

  public onOpen(event: any): void {
    if (this.formGroup.get('selectedCategories').value?.length > 0 && this.currentDataSource.Type === "energyManagement") {
      event.preventDefault();
    }
  }
}
