import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Type, ViewChild } from "@angular/core";
import { DashboardService, DataSourceInstance, WidgetComponent, WidgetInstance, SvgContainerComponent, WidgetUpdater, ItemValueChangedEventArgs, TranslateService } from "@ats/ats-platform-dashboard";
import { Subscription } from "rxjs";
import { find, inRange, forEach, maxBy, filter, first, orderBy, isEmpty } from 'lodash-es';
import { select, scaleLinear, axisLeft, scaleTime, axisBottom, timeFormat, area, line, pointer } from 'd3';
import { HubConnection } from "@aspnet/signalr";
import { EVChargingConstraintData } from "../../domain/dataModels/evChargingConstraintData";
import { EVChargingConstraintDataPoint } from "../../domain/dataModels/evChargingConstraintDataPoint";
import { DateTime } from 'luxon';


@Component({
    selector: 'ats-smart-tool-ev-charging-constraint-chart-widget',
    templateUrl: './ev-charging-constraint-chart-widget.component.html',
    styleUrls: ['./ev-charging-constraint-chart-widget.component.scss']
})
export class EVChargingConstraintChartWidgetComponent implements WidgetComponent, OnInit, AfterViewInit, OnDestroy {

    @Input() hubConnection: HubConnection;

    public widgetInstance: WidgetInstance;
    public isLoading = false;
    public error: string;

    private widgetConfigSubscription: Subscription;
    private widgetUpdaters: WidgetUpdater[];
    private svg: SVGSVGElement;
    private div: HTMLDivElement;

    public svgContainerType: Type<SvgContainerComponent> = SvgContainerComponent;

    @ViewChild('svgContainer', { static: false }) svgContainer: ElementRef;

    constructor(private dashboardService: DashboardService, public translate: TranslateService) {
    }

    public setWidgetInstance(widgetInstance: WidgetInstance) {
        this.widgetInstance = widgetInstance;
    }

    ngOnInit() {
        this.widgetConfigSubscription = this.widgetInstance.changed.subscribe((instance: WidgetInstance) => {
            this.updateData(instance);
        })
    }

    ngAfterViewInit() {
        setTimeout(() => { this.updateData(this.widgetInstance); }, 0);
    }

    ngOnDestroy() {
        if (this.widgetConfigSubscription) {
            this.widgetConfigSubscription.unsubscribe();
            this.widgetConfigSubscription = null;
        }

        if (this.widgetUpdaters) {
            this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
            this.widgetUpdaters = null;
        }
    }

    protected updateData(instance: WidgetInstance) {
        instance.widgetData = {
            title: instance.widgetConfig.title
        };

        if (!this.svgContainer)
            return;

        this.div = this.svgContainer.nativeElement;
        const svgs = this.div.getElementsByTagName('svg');
        this.svg = svgs && svgs.length ? svgs.item(0) : null;
        if (!this.svg)
            return;

        if (this.widgetUpdaters) {
            this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
        }

        const dataSourceInstance = find(this.dashboardService.dataSourceInstances, (dataSourceInstance: DataSourceInstance) => dataSourceInstance.id == instance.widgetConfig.dataSourceId);

        if (dataSourceInstance) {
            if (isEmpty(instance.widgetData.title))
                instance.widgetData.title = dataSourceInstance.name;

            this.widgetUpdaters = [];
            this.widgetUpdaters.push(new WidgetUpdater(dataSourceInstance, this.svgContainer.nativeElement, this.update, this.updateItem));
        }
    }

    public cancel(e: any) {
        e.preventDefault();
        e.cancelBubble = true;
    }

    protected update = (instance: DataSourceInstance, nativeElement: HTMLElement) => {
        this.isLoading = instance?.result?.isLoading;

        if (!this.widgetInstance.widgetData.title)
            this.widgetInstance.widgetData.title = instance.name;
        
        var margin = { top: 10, right: 10, bottom: 10, left: 10, betweenConstraints: 10, betweenPhases: 10, constraintBottom: 30 };
        var width = this.svg.getBoundingClientRect().width;
        var height = this.svg.getBoundingClientRect().height;

        const d3svg = select(this.svg);
        d3svg.attr('viewBox', '0 0 ' + width + ' ' + height);
        d3svg.selectAll('g').remove();

        if (instance.result && instance.result.data) {
            var constraints: EVChargingConstraintData[] = instance.result.data;

            var constraintWidth = width - margin.left - margin.right;
            var constraintHeight = (height - margin.top - margin.bottom - (constraints.length - 1) * margin.betweenConstraints) / constraints.length;
            var chartHeight = (constraintHeight - 2 * margin.betweenPhases - margin.constraintBottom - 20) / 3;
            var chartWidth = constraintWidth - 40;
            var numOfYTicks = Math.floor((chartHeight - 40) / 20) + 1;
            var numOfXTicks = Math.floor((chartWidth - 40) / 100) + 1;

            forEach(constraints, (constraint, i) => {
                var g = d3svg.append('g').attr('class', 'constraint').attr('transform', 'translate(' + (margin.left) + ',' + (margin.top + i * (constraintHeight + margin.betweenConstraints)) + ')');
                g.append('text').attr('font-size', 14).attr('font-family', 'sans-serif').attr('font-weight', 'bold').attr('dominant-baseline', 'hanging').text(constraint.Asset.Path);
        
                var yMax = (maxBy(constraint.Points, (x) => { return x.MaxCurrent; })?.MaxCurrent ?? 0) * 1.05;
                var yScale = scaleLinear().domain([0, yMax]).range([chartHeight, 0]);
                var yAxis = axisLeft(yScale).ticks(numOfYTicks);
        
                var xScale = scaleTime().domain([this.dashboardService.periodFrom, this.dashboardService.periodTo]).range([0, chartWidth]);
                var xAxis = axisBottom(xScale).ticks(numOfXTicks).tickSizeOuter(0).tickFormat(x => '');
                var format = timeFormat('%d-%m-%Y %H:%M:%S');
        
                var drawPhase = (g: d3.Selection<SVGGElement, unknown, null, undefined>, constraint: EVChargingConstraintData, phase: string,
                    current: (x: EVChargingConstraintDataPoint) => number, chargePointsCurrent: (x: EVChargingConstraintDataPoint) => number) => {
            
                    g.append('text').attr('font-size', 10).attr('font-family', 'sans-serif').attr('font-weight', 'bold').attr('dominant-baseline', 'hanging')
                        .attr('text-anchor', 'end').attr('transform', 'rotate(-90) translate(0,-40)').text(phase);

                    var areaUCL = area<EVChargingConstraintDataPoint>()
                        .x(d => xScale(d.TimeStamp))
                        .y0(d => yScale(0))
                        .y1(d => yScale(d.MaxCurrent - current(d)));
            
                    var areaEV = area<EVChargingConstraintDataPoint>()
                        .x(d => xScale(d.TimeStamp))
                        .y0(d => yScale(d.MaxCurrent - current(d)))
                        .y1(d => yScale(d.MaxCurrent - current(d) + chargePointsCurrent(d)));
                    
                    var lineMax = line<EVChargingConstraintDataPoint>()
                        .x(d => xScale(d.TimeStamp))
                        .y(d => yScale(d.MaxCurrent));
            
                    g.append('path').attr('fill', '#B6D6D5').attr('d', areaUCL(constraint.Points));
                    g.append('path').attr('fill', '#504D91').attr('d', areaEV(constraint.Points));
                    g.append('path').attr('stroke', '#CC2F34').attr('fill', 'none').attr('d', lineMax(constraint.Points));

                    var gX = g.append('g').attr('transform', 'translate(0,' + chartHeight + ')');
                    gX.call(xAxis);

                    var gY = g.append('g').attr('transform', 'translate(0,0)');
                    gY.call(yAxis);
                };

                var gL1 = g.append('g').attr('transform', 'translate(40,20)');
                var gL2 = g.append('g').attr('transform', 'translate(40,' + (20 + chartHeight + margin.betweenPhases) + ')');
                var gL3 = g.append('g').attr('transform', 'translate(40,' + (20 + 2 * (chartHeight + margin.betweenPhases)) + ')');
                var gXLabels = g.append('g').attr('transform', 'translate(40,' + (20 + 3 * (chartHeight + margin.betweenPhases)) + ')');

                drawPhase(gL1, constraint, 'L1', x => x.CurrentL1, x => x.ChargePointsCurrentL1);
                drawPhase(gL2, constraint, 'L2', x => x.CurrentL2, x => x.ChargePointsCurrentL2);
                drawPhase(gL3, constraint, 'L3', x => x.CurrentL3, x => x.ChargePointsCurrentL3);
        
                xScale.ticks(numOfXTicks).forEach((tickValue) => {
                    const label = format(tickValue).split(' ');
                    const text = gXLabels.append('g')
                        .attr('transform', 'translate(' + xScale(tickValue) + ',0)')
                        .append('text')
                        .attr('y', 10)
                        .attr('x', -25)
                        .attr('font-size', 10)
                        .attr('font-family', 'sans-serif')
                        .attr('fill', 'currentColor');
                    text.append('tspan').text(label[0]);
                    text.append('tspan').text(label[1])
                        .attr('y', 20)
                        .attr('dx', '-4.5em');
                });

                this.drawFocus(g, margin.top, constraintHeight, margin.left, constraintWidth, xScale, yScale, constraint);
            });
        }
    };

    protected updateItem = (dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement, event: ItemValueChangedEventArgs) => {
        //No need to change if data from item changed
    };

    private drawFocus(g: d3.Selection<SVGGElement, unknown, null, undefined>, top: number, height: number, left: number, width: number, 
        xScale: d3.ScaleTime<number, number, never>, yScale: d3.ScaleLinear<number, number, never>, constraint: EVChargingConstraintData) {

        const focus = g.append('g').attr('visibility', 'hidden');
    
        focus.append('line')
          .attr('stroke', 'black')
          .attr('x1', 0)
          .attr('x2', 0)
          .attr('y1', top + 20)
          .attr('y2', top + height - 40);
    
        const overlay = g.append('rect')
          .attr('width', width)
          .attr('height', height)
          .attr('fill', 'white')
          .attr('fill-opacity', 0)
          .attr('transform', 'translate(0,0)');
    
        overlay.on('mousemove', (e: any) => {
          let d3Pointer = pointer(e);
          let focusX = d3Pointer[0] - 40;
    
          if (inRange(focusX, xScale.range()[0], xScale.range()[1])) {
    
            focus.selectAll('.point').remove();
    
            focus
              .attr('transform', 'translate(' + (d3Pointer[0]) + ',0)')
              .attr('visibility', 'visible');
    
            const timeStamp = <Date>xScale.invert(focusX);
            const time = timeStamp.getTime();
    
            var tooltipContainer = document.getElementById("tooltipContainer");
            tooltipContainer.style.position = 'absolute';
            tooltipContainer.style.borderStyle = 'solid';
            tooltipContainer.style.borderColor = 'lightgrey';
            tooltipContainer.style.borderWidth = '1px';
            tooltipContainer.style.padding = '5px';
            tooltipContainer.style.background = 'white';
            tooltipContainer.style.opacity = '0.95';
            tooltipContainer.style.fontSize = '12px';
            
            //specific style
            tooltipContainer.style.display = null;
    
            var tooltipHtml = '';
            tooltipHtml += '<div style="text-align: center"><b>' + DateTime.fromJSDate(timeStamp).toFormat('dd/MM/yyyy HH:mm') + '</b></div>';
    
            const points = filter(constraint.Points, (x: EVChargingConstraintDataPoint) => x.TimeStamp <= timeStamp);
            const point = first(orderBy(points, [x => Math.abs(time - x.TimeStamp.getTime()), x => x.TimeStamp.getTime()]));
            if (point) {
                tooltipHtml += '<table>';
                    tooltipHtml += '<tr>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '</td>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div style="text-align: center"><b>L1</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div style="text-align: center"><b>L2</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div style="text-align: center"><b>L3</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '</tr>';
                    tooltipHtml += '<tr>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div>' + this.translate.get('i18n:EV_CHARGING_CONSTRAINT.UNCONTROLLABLE_LOAD') + '</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div style="text-align: right">' + (point.MaxCurrent - point.CurrentL1 + point.ChargePointsCurrentL1).toFixed(2) + ' A</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div style="text-align: right">' + (point.MaxCurrent - point.CurrentL2 + point.ChargePointsCurrentL2).toFixed(2) + ' A</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div style="text-align: right">' + (point.MaxCurrent - point.CurrentL3 + point.ChargePointsCurrentL3).toFixed(2) + ' A</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '</tr>';
                    tooltipHtml += '<tr>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div>' + this.translate.get('i18n:EV_CHARGING_CONSTRAINT.EV_CHARGERS') + '</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div style="text-align: right">' + (point.ChargePointsCurrentL1).toFixed(2) + ' A</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div style="text-align: right">' + (point.ChargePointsCurrentL2).toFixed(2) + ' A</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div style="text-align: right">' + (point.ChargePointsCurrentL3).toFixed(2) + ' A</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '</tr>';
                    tooltipHtml += '<tr>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div>' + this.translate.get('i18n:EV_CHARGING_CONSTRAINT.REMAINING') + '</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div style="text-align: right">' + (point.CurrentL1 - point.ChargePointsCurrentL1).toFixed(2) + ' A</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div style="text-align: right">' + (point.CurrentL2 - point.ChargePointsCurrentL2).toFixed(2) + ' A</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '<td style="white-space:nowrap;">';
                    tooltipHtml += '<div style="text-align: right">' + (point.CurrentL3 - point.ChargePointsCurrentL3).toFixed(2) + ' A</div>';
                    tooltipHtml += '</td>';
                    tooltipHtml += '</tr>';
                    tooltipHtml += '</table>';
            }
    
            tooltipContainer.innerHTML = tooltipHtml;
            this.handleTooltipPosition(tooltipContainer,e);
          } else {
            focus
              .attr('visibility', 'hidden');
          }
        });
    
        overlay.on('mouseout', (e: any) => {
          focus
            .attr('visibility', 'hidden');
    
          var tooltipContainer = document.getElementById("tooltipContainer");
          tooltipContainer.style.display = 'none';
        });
    }
        
    protected handleTooltipPosition(tooltipContainer, e: any) {
        var tooltipLeft = e.pageX + 10;
        const tooltipRightX = tooltipLeft + tooltipContainer.clientWidth;
        if (tooltipRightX > window.innerWidth) {
            tooltipContainer.style.left = (e.pageX - 10 - tooltipContainer.clientWidth) + 'px';
        } else {
            tooltipContainer.style.left = tooltipLeft  + 'px';
        }
    
        var tooltipTop = e.pageY + 10;
        const tooltipBottomY = tooltipTop + tooltipContainer.clientHeight;
        const tooltipTopYIfAbove = tooltipTop - tooltipContainer.clientHeight;
        const newTooltipTopIfToBig = 75;
        if (tooltipBottomY > window.innerHeight && tooltipTopYIfAbove > 0){
            tooltipContainer.style.top = (e.pageY - 10 - tooltipContainer.clientHeight) + 'px';
        } else if (tooltipBottomY > window.innerHeight && tooltipTopYIfAbove < 0){
            tooltipContainer.style.top = newTooltipTopIfToBig + 'px';
        } else {
            tooltipContainer.style.top = tooltipTop + 'px';
        }
    }
}