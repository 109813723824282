import { BaseSvg } from "@ats/ats-platform-dashboard";
import { ScaleLinear, ScaleBand, format, scaleLinear, scaleBand, scaleOrdinal, schemeCategory10, axisBottom, axisLeft, stack, Series } from 'd3';;
import { round, sum, findIndex, values, startCase } from "lodash-es";
import { DateTime } from "luxon";

export class VertStackedBarChart extends BaseSvg<StackedBarChartData> {

  public valueTypeLabel: string;
  public numberOfDecimals: number;
  public unit: string = null; // cover in case no unit is passed
  public hideLabels: boolean;
  public categories: string[];

  private valueScale: ScaleLinear<number, number, never>;
  private keyScale: ScaleBand<string>;
  private zColorScale;
  private formatValue: (n: number | { valueOf(): number }
  ) => string;
  private stackedBarChartData: StackedBarChartData;

  constructor() {
    super();
  }

  public draw(StackedBarChartData: StackedBarChartData) {
    if (!StackedBarChartData)
      return;

    this.formatValue = format('.' + this.numberOfDecimals + 'f');
    this.group.selectAll('*').remove();
    this.stackedBarChartData = StackedBarChartData;

    this.createScales();
    this.drawAxis();
    this.drawRect();
  }
  private createScales() {

    this.valueScale = scaleLinear()
      .domain([0, this.stackedBarChartData.maxValue])
      .range([this.height - this.bottom, this.top])
      .nice();

    this.keyScale = scaleBand()
      .domain(this.stackedBarChartData.data.map(d => DateTime.fromISO(d.key, { setZone: true }).toFormat('dd-LL-yyyy')))
      .range([this.left, this.width - this.right])
      .paddingInner(0.3)
      .paddingOuter(0.2);

    this.zColorScale = scaleOrdinal(schemeCategory10).domain(['0', this.stackedBarChartData.categories.length.toString()]);
  }

  private drawAxis() {
    const fontSize: number = 11;
    let valueLabelText: string = '';
    const tickReduction = round(this.keyScale.domain().length / ((this.width - this.left - this.right) / 100), 0);
    const unitPresent = (this.unit) ? valueLabelText = this.valueTypeLabel + ' (' + this.unit + ')' : valueLabelText = this.valueTypeLabel;

    this.group
      .append('g')
      .attr('id', 'BottomAxis')
      .attr('transform', 'translate(0,' + (this.height - this.bottom) + ')')
      .call(
        axisBottom(this.keyScale)
          .tickValues(
            this.keyScale.domain().filter(function (d, i) {
              return !(i % tickReduction);
            })
          )
          .tickPadding(3)
          .tickSizeOuter(0)
      )

    const leftAxis = this.group
      .append('g')
      .attr('id', 'LeftAxis')
      .attr('transform', 'translate(' + this.left + ', 0)')
      .call(
        axisLeft(this.valueScale)
          .ticks((this.height - this.bottom - this.top) / 40)
          .tickPadding(3)
          .tickSizeOuter(0)
      )
    if (!this.hideLabels) {
      leftAxis
        .append('text')
        .attr('transform', 'rotate(-90)')
        .attr('y', - this.left + fontSize)
        .attr('x', - ((this.height) / 2))
        .attr('dy', '0.32em')
        .attr('fill', '#000000')
        .attr('font-weight', 'bold')
        .attr('text-anchor', 'middle')
        .attr('font-size', fontSize)
        .text(valueLabelText)
        .call(this.wrap, (this.height - this.top - this.bottom));
    }
  }

  private drawRect() {

    const stackData = stack()
      .keys(this.stackedBarChartData.categories)
      .value((d, key) => d['values'][key])
      (<any>this.stackedBarChartData.data); //parse to correct datatype

    let stackedBarChartGroup = this.group
      .append('g')
      .attr('id', 'stackedBarChartGroup')
    stackedBarChartGroup
      .selectAll('g')
      .data(stackData)
      .enter()
      .append('g')
      .attr('fill', (d, i) => { return this.zColorScale(i); })
      .selectAll('rect')
      .data((d: any) => d)
      .enter()
      .append('rect')
      .attr('y', d => { return this.valueScale(d[1]); })
      .attr('x', (d: any) =>
        this.keyScale(DateTime.fromISO(d.data.key).toFormat('dd-LL-yyyy')))
      .attr('height', d => this.valueScale(d[0]) - this.valueScale(d[1]))
      .attr('width', this.keyScale.bandwidth())
      .on("mouseover", (e: any, d: any) => {

        // #region tooltip
        const timeStamp = (d as any).data.key;
        const dayIndex = findIndex(this.stackedBarChartData.data, (day: any) => {
          return timeStamp == day.key;
        });
        const categoryIndex = findIndex(stackData, (serie: Series<{ [key: string]: number; }, string>) => {
          return serie[dayIndex] == d;
        });
        const currentCategory = stackData[categoryIndex].key;
        const currentValue = d.data.values[currentCategory];
        const totalValue: number = sum(values(d.data.values));
        let currentValueText = '';
        let totalValueText = '';
        if (this.unit) {
          currentValueText = this.formatValue(currentValue) + ' (' + this.unit + ')';
          totalValueText = this.formatValue(totalValue) + ' (' + this.unit + ')';
        } else {
          currentValueText = "" + this.formatValue(currentValue);
          totalValueText = '' + this.formatValue(totalValue) + '';
        }

        //Remove all child elements
        var tooltipContainer = document.getElementById("tooltipContainer");
        //global style
        tooltipContainer.style.position = 'absolute';
        tooltipContainer.style.borderStyle = 'solid';
        tooltipContainer.style.borderColor = 'lightgrey';
        tooltipContainer.style.borderWidth = '1px';
        tooltipContainer.style.padding = '3px';
        tooltipContainer.style.background = 'white';
        tooltipContainer.style.fontSize = '12px';

        //specific style
        tooltipContainer.style.display = "inline";
        tooltipContainer.className = "valueTooltip";

        var tooltipHtml = '';

        //timestamp or label if present
        tooltipHtml += '<div style="text-align: center"><b>' + DateTime.fromISO(d.data.key, { setZone: true }).toFormat('dd-LL-yyyy HH:mm') + '</b></div>';

        tooltipHtml += '<table>';
        tooltipHtml += '<tr>';

        //color kolom
        var colorRectStyle = 'height: 15px; width: 15px; background-color:' + this.zColorScale(categoryIndex) + '; border:1px solid WhiteSmoke;';
        tooltipHtml += '<td style="width: 20px;">';
        tooltipHtml += "<div style='" + colorRectStyle + "'></div>";
        tooltipHtml += '</td>';
        //property kolom
        tooltipHtml += '<td style="white-space:nowrap;">';
        tooltipHtml += '<div>Ratio: </div>';
        tooltipHtml += '</td>';
        //value & unit kolom
        tooltipHtml += '<td style="white-space:nowrap;">';
        tooltipHtml += '<div>' + round(100 * currentValue / totalValue, 1) + ' %</div>';
        tooltipHtml += '</td>';
        tooltipHtml += '</tr>';

        tooltipHtml += '<tr>';
        //color kolom
        tooltipHtml += '<td style="width: 20px;">';
        tooltipHtml += '</td>';
        //property kolom
        tooltipHtml += '<td style="white-space:nowrap;">';
        tooltipHtml += '<div>' + startCase(currentCategory) + ': </div>';
        tooltipHtml += '</td>';
        //value & unit kolom
        tooltipHtml += '<td style="white-space:nowrap;">';
        tooltipHtml += '<div>' + currentValueText + '</div>';
        tooltipHtml += '</td>';
        tooltipHtml += '</tr>';
        tooltipHtml += '<tr>';

        //color kolom
        tooltipHtml += '<td style="width: 20px;">';
        tooltipHtml += '</td>';
        //property kolom
        tooltipHtml += '<td style="white-space:nowrap;">';
        tooltipHtml += '<div>Total: </div>';
        tooltipHtml += '</td>';
        //value & unit kolom
        tooltipHtml += '<td style="white-space:nowrap;">';
        tooltipHtml += '<div>' + totalValueText + '</div>';
        tooltipHtml += '</td>';
        tooltipHtml += '</tr>';

        tooltipHtml += '</table>';
        tooltipContainer.innerHTML = tooltipHtml;
        // #endregion

      })
      .on("mouseout", (e: any, d: any) => {
        var tooltipContainer = document.getElementById("tooltipContainer");
        tooltipContainer.innerHTML = '';
        tooltipContainer.style.display = "none";
      })
      .on("mousemove", (e: any, d: any) => {
        var tooltipContainer = document.getElementById("tooltipContainer");
        tooltipContainer.setAttribute('transform', 'translate(' + e.pageX + ',' + e.pageY + ')');
        this.handleTooltipPosition(tooltipContainer, e);
        tooltipContainer.style.background = 'white';
        tooltipContainer.style.opacity = '0.95';
      })
  }
}

export class StackedBarChartData {
  categories: string[];
  data: {
    key: string;
    values: { [category: string]: number };
  }[]
  maxValue: number;
}
