import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { HubConnection } from "@aspnet/signalr";
import { DashboardService, DataSourceInstance, DataSourceResult, ItemValueChangedEventArgs, WidgetComponent, WidgetInstance } from "@ats/ats-platform-dashboard";
import { Subscription } from "rxjs";

import { find } from "lodash-es";
import { select } from 'd3';
import { LinearGauge } from "../../svg/linear-gauge";
import { TagDataSourceItem } from "../../services/tagsDataSourceLoader";

@Component({
    selector: 'ats-smart-tool-tag-linear-gauge-widget',
    templateUrl: './tag-linear-gauge-widget.component.html',
    styleUrls: ['./tag-linear-gauge-widget.component.scss']
})
export class TagLinearGaugeWidgetComponent implements WidgetComponent, OnInit, AfterViewInit, OnDestroy {
    @Input() hubConnection: HubConnection;

    public widgetInstance: WidgetInstance;
    public isLoading = false;
    public error: string;

    private widgetConfigSubscription: Subscription;
    private dataSourceSubscriptions: Subscription[];
    private svg: SVGSVGElement;

    private gauge: LinearGauge;

    @ViewChild('svgContainer', { static: false }) svgContainer: ElementRef;

    constructor(private dashboardService: DashboardService) {

    }

    public setWidgetInstance(widgetInstance: WidgetInstance) {
        this.widgetInstance = widgetInstance;
    }

    ngOnInit() {
        this.widgetConfigSubscription = this.widgetInstance.changed.subscribe({
            next: (instance: WidgetInstance) => {
                this.updateData(instance);
            }
        });
    }

    ngAfterViewInit() {
        setTimeout(() => { this.updateData(this.widgetInstance); }, 0);
    }

    ngOnDestroy() {
        if (this.widgetConfigSubscription) {
            this.widgetConfigSubscription.unsubscribe();
            this.widgetConfigSubscription = null;
        }

        if (this.dataSourceSubscriptions) {
            this.dataSourceSubscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
            this.dataSourceSubscriptions = null;
        }
    }

    protected updateData(instance: WidgetInstance) {
        instance.widgetData = {
            title: instance.widgetConfig.title
        };

        if (!this.svgContainer)
            return;

        const div: HTMLDivElement = this.svgContainer.nativeElement;
        const svgs = div.getElementsByTagName('svg');
        this.svg = svgs && svgs.length ? svgs.item(0) : null;
        if (!this.svg)
            return;

        // init gauge
        this.gauge = new LinearGauge({
            width: instance.widgetConfig.width,
            height: instance.widgetConfig.height,
            direction: instance.widgetConfig.direction,
            scalePosition: instance.widgetConfig.scalePosition,
            barWidth: instance.widgetConfig.barWidth,
            minValue: instance.widgetConfig.minValue,
            maxValue: instance.widgetConfig.maxValue,
            numberOfDecimals: instance.widgetConfig.numberOfDecimals,
            numberOfMajorTicks: instance.widgetConfig.numberOfMajorTicks,
            numberOfMinorTicks: instance.widgetConfig.numberOfMinorTicks,
            defaultRangeColor: instance.widgetConfig.defaultRangeColor,
            majorTicksColor: instance.widgetConfig.majorTicksColor,
            minorTicksColor: instance.widgetConfig.minorTicksColor,
            valuePointerColor: instance.widgetConfig.valuePointerColor,
            valueColor: instance.widgetConfig.valueColor,
            setpointColor: instance.widgetConfig.setpointColor,
            ranges: instance.widgetConfig.ranges,
            unit: null
        });

        const d3svg = select(this.svg);
        d3svg.selectAll('g').remove();
        d3svg.attr('viewBox', '0 0 ' + instance.widgetConfig.width + ' ' + instance.widgetConfig.height);

        const g = d3svg.append('g');
        this.gauge.draw(g);

        if (this.dataSourceSubscriptions) {
            this.dataSourceSubscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
            this.dataSourceSubscriptions = null;
        }

        const dataSourceInstance = find(this.dashboardService.dataSourceInstances, (dataSourceInstance: DataSourceInstance) => dataSourceInstance.id == instance.widgetConfig.dataSourceId);
        if (dataSourceInstance) {
            this.dataSourceSubscriptions = [];
            this.dataSourceSubscriptions.push(dataSourceInstance.result$.subscribe({
                next: (result: DataSourceResult) => {
                    this.isLoading = result?.isLoading;

                    if (result && result.data) {
                        const items: TagDataSourceItem[] = result.data;
                        const item: TagDataSourceItem = find(items, (item: TagDataSourceItem) => item.TagId == instance.widgetConfig.itemId);
                        instance.widgetData.item = item;

                        if (!instance.widgetConfig.title)
                            instance.widgetData.title = item.Tag.Asset.Path + ' - ' + item.Tag.Name;

                        // update value
                        if (this.gauge) {
                            this.gauge.settings.unit = item?.MeasurementUnit.Symbol || item.MeasurementUnit.Name;
                            this.gauge.draw(g);

                            this.gauge.setValue(item?.LatestValue?.FloatingPointValue);
                        }

                        const setpointId = item?.Tag?.SetPointId;
                        if (setpointId) {
                            instance.widgetData.setpointItem = find(items, (item: TagDataSourceItem) => item.TagId == setpointId);

                            // update value
                            if (this.gauge)
                                this.gauge.setSetpoint(instance.widgetData.setpointItem?.LatestValue?.FloatingPointValue);
                        }
                    }

                }
            }));

            this.dataSourceSubscriptions.push(dataSourceInstance.itemValueChangedEvent.subscribe({
                next: (e: ItemValueChangedEventArgs) => {
                    if (e.id == instance.widgetData?.item?.TagId) {
                        const item = <TagDataSourceItem>instance.widgetData.item;

                        // update value
                        if (this.gauge)
                            this.gauge.setValue(item?.LatestValue?.FloatingPointValue);
                    } else if (e.id == instance.widgetData?.setpointItem?.TagId) {
                        const item = <TagDataSourceItem>instance.widgetData.setpointItem;

                        // update value
                        if (this.gauge)
                            this.gauge.setSetpoint(item.LatestValue?.FloatingPointValue);
                    }
                }
            }));
        }

    }



}