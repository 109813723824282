<form class="k-form" [formGroup]="formGroup">
    <fieldset class="k-form-fieldset">
        <legend class="k-form-legend">{{ 'LABEL.GENERAL' | translate}}</legend>
        <kendo-formfield>
            <kendo-label [for]="title" text="{{ 'WIDGET.TITLE' | translate }}"></kendo-label>
            <input #title formControlName="title" kendoTextBox />
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="widgetType" text="{{ 'WIDGET.TYPE' | translate }}"></kendo-label>
            <kendo-textbox #widgetType formControlName="widgetType"  [readonly]="true"></kendo-textbox>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="dataSourceId" text="{{ 'WIDGET.DATASOURCE' | translate }}"></kendo-label>
            <ats-platform-entity-lookup-textbox #dataSourceId formControlName="dataSourceId" [settings]="dataSourceSelectionPopupSettings" [formatter]="dataSourceFormatter" (entityChange)="onDataSourceChange($event)"></ats-platform-entity-lookup-textbox>
            <kendo-formerror *ngIf="formGroup.controls['dataSourceId'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
    </fieldset>
    <fieldset class="k-form-fieldset">
        <legend class="k-form-legend">{{ 'LABEL.SETTINGS' | translate}}</legend>
        <kendo-formfield>
            <kendo-label [for]="width" text="{{ 'WIDGET.WIDTH' | translate }}"></kendo-label>
            <kendo-numerictextbox #width formControlName="width" [min]="1" [decimals]="0" [format]="'n0'"></kendo-numerictextbox>
            <kendo-formerror *ngIf="formGroup.controls['width'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
            <kendo-formerror *ngIf="formGroup.controls['width'].errors?.['minError']">{{ translate.get('i18n:MESSAGE.VALIDATION_MINIMUM', formGroup.controls.width.errors.minError) }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="fontSize" text="{{ 'WIDGET.FONTSIZE' | translate }}"></kendo-label>
            <kendo-numerictextbox #fontSize formControlName="fontSize" [min]="1" [decimals]="0" [format]="'n0'"></kendo-numerictextbox>
            <kendo-formerror *ngIf="formGroup.controls['fontSize'].errors?.['minError']">{{ translate.get('i18n:MESSAGE.VALIDATION_MINIMUM', formGroup.controls.fontSize.errors.minError) }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="subFontSize" text="{{ 'WIDGET.SUBFONTSIZE' | translate }}"></kendo-label>
            <kendo-numerictextbox #subFontSize formControlName="subFontSize" [min]="1" [decimals]="0" [format]="'n0'"></kendo-numerictextbox>
            <kendo-formerror *ngIf="formGroup.controls['subFontSize'].errors?.['minError']">{{ translate.get('i18n:MESSAGE.VALIDATION_MINIMUM', formGroup.controls.subFontSize.errors.minError) }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="numberOfDecimals" text="{{ 'WIDGET.NUMBEROFDECIMALS' | translate }}"></kendo-label>
            <kendo-numerictextbox #numberOfDecimals formControlName="numberOfDecimals" [min]="0" [decimals]="0" [format]="'n0'"></kendo-numerictextbox>
            <kendo-formerror *ngIf="formGroup.controls['numberOfDecimals'].errors?.['minError']">{{ translate.get('i18n:MESSAGE.VALIDATION_MINIMUM', formGroup.controls.numberOfDecimals.errors.minError) }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="dateTimeFormat" text="{{ 'WIDGET.DATETIMEFORMAT' | translate }}"></kendo-label>
            <input #dateTimeFormat formControlName="dateTimeFormat" kendoTextBox />
        </kendo-formfield>
    </fieldset>
  </form>