import { CoreService, DashboardService } from '@ats/ats-platform-dashboard';
import * as d3 from 'd3';
import * as _ from 'lodash';
import { EnergyDistributionData } from '../domain/dataModels/energyDistributionData';
import { BaseSvg } from '@ats/ats-platform-dashboard';
//http://bl.ocks.org/caged/6476579
export class EnergyDistribution extends BaseSvg<any> {

  //Public info
  public data: EnergyDistributionData = new EnergyDistributionData();
  public rows: number;
  public columns: number;
  public marginBetweenX: number;
  public marginBetweenY: number;
  public measurementUnit: string = '';
  public unitWidth: number;
  public unitHeight: number;
  public mainPosX: any[] = [];
  public mainPosY: any[] = [];

  //Private info
  private formatValue: (n: number | { valueOf(): number }) => string;
  private posArrUnit: { x: number, y: number }[][];
  private meterRows: number;
  private meterColumns: number;

  constructor(private core: CoreService) {
    super();
    this.formatValue = d3.format('.1f');

    this.meterRows = 6;
    this.meterColumns = 3;

  }

  public init(group: d3.Selection<SVGGElement, unknown, HTMLElement, unknown>) {
    this.group = group;
  }

  public draw() {
    this.group.selectAll("*").remove();
    this.group.selectChildren('*').remove();
    this.group.html("");

    this.posArrUnit = this.convertArea(
      this.unitWidth,
      this.unitHeight,
      this.meterRows,
      this.meterColumns
    );

    var baseLine = this.group.append('line')
      .attr("x1", this.data.Grid.X + this.unitWidth)
      .attr("y1", this.data.Grid.Y + this.unitHeight / 2)
      .attr("x2", this.data.Building.X)
      .attr("y2", this.data.Building.Y + this.unitHeight / 2)
      .attr("stroke-width", 3)
      .attr("stroke", "grey");

    if (this.data.Grid.OutValue !== 0) {
      this.appendPath(
        'arrowRight',
        this.group,
        this.data.Grid.X + this.unitWidth,
        this.data.Grid.Y + this.unitHeight / 2,
        '#5282c6',
        0.06,
        { classId: 'icon', moveUp: 20, moveRight: 30 }
      );
    }
    if (this.data.Grid.InValue !== 0) {
      this.appendPath(
        'arrowLeft',
        this.group,
        this.data.Grid.X + this.unitWidth,
        this.data.Grid.Y + this.unitHeight / 2,
        '#5282c6',
        0.06,
        { classId: 'icon', moveDown: 20, moveRight: 30 }
      );
    }
    if (this.data.Building.InValue !== 0) {
      this.appendPath(
        'arrowRight',
        this.group,
        this.data.Building.X,
        this.data.Building.Y + this.unitHeight / 2,
        '#5282c6',
        0.06,
        { classId: 'icon', moveUp: 20, moveLeft: 30 }
      );
    }
    if (this.data.Building.OutValue !== 0) {
      this.appendPath(
        'arrowLeft',
        this.group,
        this.data.Building.X,
        this.data.Building.Y + this.unitHeight / 2,
        '#5282c6',
        0.06,
        { classId: 'icon', moveDown: 20, moveLeft: 30 }
      );
    }

    for (let currUnitKey in this.data) {
      let currUnit = this.data[currUnitKey];
      if (currUnit.HasMeters) {
        currUnit.G = this.drawUnitBase(
          this.group,
          currUnit,
          this.data,
          this.posArrUnit,
          this.unitWidth,
          this.unitHeight,
        );
        if (currUnitKey === 'Battery' || currUnitKey === 'EVCharger') {
          this.group.append('line')
            .attr("x1", this.data[currUnitKey].X + this.unitWidth / 2)
            .attr("y1", this.data.Grid.Y + this.unitHeight / 2)
            .attr("x2", this.data[currUnitKey].X + this.unitWidth / 2)
            .attr("y2", this.data[currUnitKey].Y)
            .attr("stroke-width", 3)
            .attr("stroke", "grey");
          if (this.data[currUnitKey].InValue !== 0) {
            this.appendPath(
              'arrowDown',
              this.group,
              this.data[currUnitKey].X + this.unitWidth / 2,
              this.data[currUnitKey].Y,
              '#5282c6',
              0.06,
              { classId: 'icon', moveUp: 30, moveLeft: 20 }
            );
          }
          if (this.data[currUnitKey].OutValue !== 0) {
            this.appendPath(
              'arrowUp',
              this.group,
              this.data[currUnitKey].X + this.unitWidth / 2,
              this.data[currUnitKey].Y,
              '#5282c6',
              0.06,
              { classId: 'icon', moveUp: 30, moveRight: 20 }
            );
          }
        }
        if (currUnitKey === 'Solar' || currUnitKey === 'Wind') {
          this.group.append('line')
            .attr("x1", this.data[currUnitKey].X + this.unitWidth / 2)
            .attr("y1", this.data.Grid.Y + this.unitHeight / 2)
            .attr("x2", this.data[currUnitKey].X + this.unitWidth / 2)
            .attr("y2", this.data[currUnitKey].Y + this.unitHeight)
            .attr("stroke-width", 3)
            .attr("stroke", "grey");
          if (this.data[currUnitKey].InValue !== 0) {
            this.appendPath(
              'arrowUp',
              this.group,
              this.data[currUnitKey].X + this.unitWidth / 2,
              this.data[currUnitKey].Y + this.unitHeight,
              '#5282c6',
              0.06,
              { classId: 'icon', moveDown: 30, moveLeft: 20 }
            );
          }
          if (this.data[currUnitKey].OutValue !== 0) {
            this.appendPath(
              'arrowDown',
              this.group,
              this.data[currUnitKey].X + this.unitWidth / 2,
              this.data[currUnitKey].Y + this.unitHeight,
              '#5282c6',
              0.06,
              { classId: 'icon', moveDown: 30, moveRight: 20 }
            );
          }
        }
      }
    }
  }

  private drawUnitBase(g, currUnit, units, posArrUnit, width, height) {

    var unit = g
      .append('g')
      .attr('transform', 'translate(' + currUnit.X + ',' + currUnit.Y + ')');

    unit
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width)
      .attr('height', height)
      .attr('fill', 'transparent')
      .attr('rx', 5)
      .attr("stroke-width", 3)
      .attr('stroke', 'grey');

    unit
      .append('rect')
      .attr('x', width * 0.05)
      .attr('y', height * 0.32)
      .attr('width', width - (width * 0.05 * 2))
      .attr('height', height * 0.53)
      .attr('fill', 'blue')
      .attr('rx', 5)
      .attr('stroke', 'grey')
      .style('opacity', 0.1);

    this.appendPath(
      currUnit.Icon.IconSymbol,
      unit,
      posArrUnit[0][1].x,
      (posArrUnit[0][1].y + posArrUnit[1][1].y) / 2,
      currUnit.Icon.Color,
      currUnit.Icon.IconScale,
      { moveUp: currUnit.Icon.Up, moveDown: currUnit.Icon.Down, moveLeft: currUnit.Icon.Left, moveRight: currUnit.Icon.Right }
    );
    this.appendPath(
      'arrowRight',
      unit,
      posArrUnit[2][0].x,
      posArrUnit[2][0].y - 2,
      'grey',
      0.025,
      { classId: 'icon', moveDown: 0, moveRight: 0 }
    );
    unit.append('text')
      .attr('text-anchor', 'middle')
      .attr('x', posArrUnit[2][1].x)
      .attr('y', posArrUnit[2][1].y + 3)
      .style('fill', '#5282c6')
      .attr('class', 'currValues')
      .style('font-weight', 600)
      .style('font-size', 14)
      .text(this.formatValue(Math.abs(currUnit.InValue)));
    unit.append('text')
      .attr('text-anchor', 'middle')
      .attr('x', posArrUnit[2][2].x)
      .attr('y', posArrUnit[2][2].y + 3)
      .style('fill', 'grey')
      .style('font-size', 10)
      .text('kWh');
    this.appendPath(
      'arrowLeft',
      unit,
      posArrUnit[3][0].x,
      posArrUnit[3][0].y - 2,
      'grey',
      0.025,
      { classId: 'icon', moveDown: 0, moveRight: 0 }
    );
    unit.append('text')
      .attr('text-anchor', 'middle')
      .attr('x', posArrUnit[3][1].x)
      .attr('y', posArrUnit[3][1].y + 3)
      .style('fill', '#5282c6')
      .attr('class', 'currValues')
      .style('font-weight', 600)
      .style('font-size', 14)
      .text(this.formatValue(Math.abs(currUnit.OutValue)));
    unit.append('text')
      .attr('text-anchor', 'middle')
      .attr('x', posArrUnit[3][2].x)
      .attr('y', posArrUnit[3][2].y + 3)
      .style('fill', 'grey')
      .style('font-size', 10)
      .text('kWh');

    if (currUnit.TargetId) {
      this.appendChart(
        currUnit.TargetId,
        unit,
        '#5282c6',
        posArrUnit[4][1].x,
        posArrUnit[4][1].y,
      );
    }

    unit.append('text')
      .attr('text-anchor', 'middle')
      .attr('x', posArrUnit[5][1].x)
      .attr('y', posArrUnit[5][1].y + 5)
      .style('fill', 'grey')
      .style('font-size', 14)
      .style('font-weight', 700)
      .text(currUnit.DisplayName);
  }

  private convertArea(
    width,
    height,
    rows,
    columns,
    left = 0,
    right = 0,
    top = 0,
    bottom = 0
  ) {
    const columnWidth =
      (width - left - right) / columns;
    const rowHeight =
      (height - top - bottom) / rows;
    var startX = left;
    var startY = top;
    var arr = [];
    var obj = {};

    for (let i = 0; i < rows; i++) {
      arr[i] = [];
      for (let j = 0; j < columns; j++) {
        var currX = startX + j * columnWidth + columnWidth / 2;
        var currY = startY + i * rowHeight + rowHeight / 2;
        arr[i][j] = { x: currX, y: currY };
      }
    }
    return arr;
  }
  private appendPath(
    icon,
    group,
    x,
    y,
    color,
    scale = 1,
    { icons = this.icons, classId = '', moveUp = 0, moveDown = 0, moveLeft = 0, moveRight = 0, } = {}
  ) {
    var icon = group
      .append('path')
      .attr('d', icons[icon])
      .attr('fill', color)
      .attr('class', classId);
    var bBox = icon.node().getBBox();

    icon.attr(
      'transform',
      'translate(' +
      (x + moveRight - moveLeft - (bBox.width * scale) / 2) +
      ',' +
      (y - moveUp + moveDown - (bBox.height * scale) / 2) +
      ')scale(' +
      scale +
      ')'
    );
    return icon;
  }

  private appendChart(
    targetId,
    group,
    color,
    x,
    y,
    { icons = this.icons, classId = '', moveUp = 0, moveDown = 0, moveLeft = 0, moveRight = 0, assetId = '' } = {}
  ) {
    var gButton = group.append('g')
      .attr('transform', 'translate(' + x + ',' + y + ')');

    var buttonRect = gButton.append('rect')
      .attr('x', -12 + moveRight - moveLeft)
      .attr('y', -7 + moveDown - moveUp)
      .attr('width', 24)
      .attr('height', 16)
      .attr('rx', 2)
      .attr('stroke', 'none')
      .attr('fill', 'lightgrey')
      .attr('opacity', 0);

    var buttonPath = this.appendPath(
      'chart',
      gButton,
      0,
      0,
      color,
      0.025,
      { icons: icons, classId: classId, moveUp: moveUp, moveDown: moveDown, moveLeft: moveLeft, moveRight: moveRight }
    );

    buttonPath.attr('opacity', 1);

    gButton.style('cursor', 'pointer');
    gButton.on('mouseover', (e) => {
      buttonRect.attr('opacity', '0.8');
    });
    gButton.on('mouseout', (e) => {
      buttonRect.attr('opacity', '0');
    });
    gButton.on('click', (e) => {
      if (assetId === '') {
        this.core.navigate(['chart', targetId]);
      } else {
        this.core.navigate(['chart', targetId, 'asset', assetId]);
      }
    });

    return gButton;
  }

  private icons: {} = {
    carCharger: 'M96 0C60.7 0 32 28.7 32 64V448c-17.7 0-32 14.3-32 32s14.3 32 32 32H320c17.7 0 32-14.3 32-32s-14.3-32-32-32V304h16c22.1 0 40 17.9 40 40v32c0 39.8 32.2 72 72 72s72-32.2 72-72V252.3c32.5-10.2 56-40.5 56-76.3V144c0-8.8-7.2-16-16-16H544V80c0-8.8-7.2-16-16-16s-16 7.2-16 16v48H480V80c0-8.8-7.2-16-16-16s-16 7.2-16 16v48H432c-8.8 0-16 7.2-16 16v32c0 35.8 23.5 66.1 56 76.3V376c0 13.3-10.7 24-24 24s-24-10.7-24-24V344c0-48.6-39.4-88-88-88H320V64c0-35.3-28.7-64-64-64H96zM216.9 82.7c6 4 8.5 11.5 6.3 18.3l-25 74.9H256c6.7 0 12.7 4.2 15 10.4s.5 13.3-4.6 17.7l-112 96c-5.5 4.7-13.4 5.1-19.3 1.1s-8.5-11.5-6.3-18.3l25-74.9H96c-6.7 0-12.7-4.2-15-10.4s-.5-13.3 4.6-17.7l112-96c5.5-4.7 13.4-5.1 19.3-1.1z',
    battery: 'M80 96c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32l96 0c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32h16c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64l16 0zm304 96c0-8.8-7.2-16-16-16s-16 7.2-16 16v32H320c-8.8 0-16 7.2-16 16s7.2 16 16 16h32v32c0 8.8 7.2 16 16 16s16-7.2 16-16V256h32c8.8 0 16-7.2 16-16s-7.2-16-16-16H384V192zM80 240c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16s-7.2-16-16-16H96c-8.8 0-16 7.2-16 16z',
    electricityPole: 'M215.841,202.648,61.029,264.572A8,8,0,0,0,64,280H80v40a8,8,0,0,0,16,0V280h48v40a8,8,0,0,0,16,0V280h51.544l-3.529,63.509v.03l-8,144.018a8,8,0,0,0,12.425,7.1L256,465.615l43.562,29.041a8,8,0,0,0,12.425-7.1l-8-144.018v-.03L300.456,280H352v40a8,8,0,0,0,16,0V280h48v40a8,8,0,0,0,16,0V280h16a8,8,0,0,0,2.971-15.428L296.159,202.648,292.012,128H368v40a8,8,0,0,0,16,0V128h16a8,8,0,0,0,3.765-15.059L285.334,49.778,262.4,19.2a8,8,0,0,0-12.8,0L226.666,49.778,108.235,112.941A8,8,0,0,0,112,128h16v40a8,8,0,0,0,16,0V128h75.988Zm76.85,226.011L266.174,394.91l23.015-29.292Zm-69.88-63.041,23.015,29.292-26.517,33.749Zm23.707-207.9L233.2,178.644l2.139-38.5ZM246.573,128h18.854L256,142.814ZM256,172.615,273.427,200H238.573ZM244.686,304l-19.537,19.536,2.057-37.016Zm-1.372-24h25.372L256,292.686Zm-16.638,64.639L256,315.314l29.324,29.325L256,381.961ZM267.314,304l17.48-17.48,2.057,37.016Zm-38.857-40,2.666-48h49.753l2.667,48Zm37.025-106.286,11.18-17.568,2.139,38.5Zm23.294-87.967,29.059,15.5-27.019,21.229ZM272.432,64l2.667,48H236.9l2.667-48Zm-51.3,43.373-27.152-22.03,29.242-15.6ZM212.7,259.119,181.047,233.8l33.815-13.526Zm-48.732-18.491L193.192,264H105.541Zm96.466,208.716a8,8,0,0,0-8.876,0l-34.687,23.125.886-15.941L256,407.859l38.239,48.669.886,15.941ZM318.808,264l29.22-23.372L406.459,264Zm12.145-30.2L299.3,259.119l-2.158-38.847ZM309.681,112l23.581-18.527L368,112ZM256,37.333,264,48H248ZM178.659,93.515,201.442,112H144Z',
    solarPanel: 'M115.2 0C84.7 0 58.5 21.5 52.5 51.4L1.3 307.4C-6.6 347 23.6 384 64 384H281v64H217c-17.7 0-32 14.3-32 32s14.3 32 32 32H409c17.7 0 32-14.3 32-32s-14.3-32-32-32H345V384H562c40.4 0 70.7-36.9 62.8-76.6l-51.2-256C567.5 21.5 541.3 0 510.8 0H115.2zM253.9 64H372.1l10.4 104h-139L253.9 64zM195.3 168H94.4L115.2 64h90.4L195.3 168zM84.8 216H190.5L180.1 320H64L84.8 216zm153.9 0H387.3l10.4 104-169.4 0 10.4-104zm196.8 0H541.2L562 320h-116L435.5 216zm96-48H430.7L420.3 64h90.4l31.4-6.3L510.8 64l20.8 104z',
    building: 'M64 48c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16h80V400c0-26.5 21.5-48 48-48s48 21.5 48 48v64h80c8.8 0 16-7.2 16-16V64c0-8.8-7.2-16-16-16H64zM0 64C0 28.7 28.7 0 64 0H320c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm88 40c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H104c-8.8 0-16-7.2-16-16V104zM232 88h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H232c-8.8 0-16-7.2-16-16V104c0-8.8 7.2-16 16-16zM88 232c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H104c-8.8 0-16-7.2-16-16V232zm144-16h48c8.8 0 16 7.2 16 16v48c0 8.8-7.2 16-16 16H232c-8.8 0-16-7.2-16-16V232c0-8.8 7.2-16 16-16z',
    wind: 'M288 32c0 17.7 14.3 32 32 32h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H352c53 0 96-43 96-96s-43-96-96-96H320c-17.7 0-32 14.3-32 32zm64 352c0 17.7 14.3 32 32 32h32c53 0 96-43 96-96s-43-96-96-96H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H384c-17.7 0-32 14.3-32 32zM128 512h32c53 0 96-43 96-96s-43-96-96-96H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H160c17.7 0 32 14.3 32 32s-14.3 32-32 32H128c-17.7 0-32 14.3-32 32s14.3 32 32 32z',
    arrowRight: 'M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z',
    arrowLeft: 'M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z',
    arrowUp: 'M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z',
    arrowDown: 'M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z',
    chart: 'M496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM464 96H345.94c-21.38 0-32.09 25.85-16.97 40.97l32.4 32.4L288 242.75l-73.37-73.37c-12.5-12.5-32.76-12.5-45.25 0l-68.69 68.69c-6.25 6.25-6.25 16.38 0 22.63l22.62 22.62c6.25 6.25 16.38 6.25 22.63 0L192 237.25l73.37 73.37c12.5 12.5 32.76 12.5 45.25 0l96-96 32.4 32.4c15.12 15.12 40.97 4.41 40.97-16.97V112c.01-8.84-7.15-16-15.99-16z'
  };
}