import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Type, ViewChild } from "@angular/core";
import { HubConnection } from "@aspnet/signalr";
import { DashboardService, DataSourceInstance, TranslateService, WidgetComponent, WidgetInstance, SvgContainerComponent, WidgetUpdater, DataSourceResult, CoreService } from "@ats/ats-platform-dashboard";
import { Subscription } from "rxjs";

import { find, findIndex } from "lodash-es";
import { select } from 'd3';
import { LinearRegression, LinearRegressionData } from "../../svg/linear-regression";
import { BuildingEnergyData, BuildingEnergyBuildingData, RegressionParameters, BuildingEnergyDetailData } from "../../domain/dataModels/buildingEnergyData"
import { DayFilter } from "../../domain/enums/dayFilter";
import { BuildingEnergyValueType, BUILDING_ENERGY_VALUE_TYPES } from "../../domain/enums/building-energy-value-types";

@Component({
  selector: 'ats-smart-tool-building-energy-linear-regression-widget',
  templateUrl: './building-energy-linear-regression-widget.component.html',
  styleUrls: ['./building-energy-linear-regression-widget.component.scss']
})
export class BuildingEnergyLinearRegressionWidgetComponent implements WidgetComponent, OnInit, AfterViewInit, OnDestroy {
  @Input() hubConnection: HubConnection;

  public valueTypes: { Key: BuildingEnergyValueType, Display: string }[];

  public widgetInstance: WidgetInstance;
  public isLoading = false;
  public error: string;
  public buildingEnergyData: BuildingEnergyData;

  private widgetConfigSubscription: Subscription;
  private widgetUpdaters: WidgetUpdater[];
  private svg: SVGSVGElement;

  private drawer: LinearRegression;

  public svgContainerType: Type<SvgContainerComponent> = SvgContainerComponent;

  @ViewChild('svgContainer', { static: false }) svgContainer: ElementRef;

  constructor(private dashboardService: DashboardService, public translate: TranslateService, public core: CoreService) {
    this.valueTypes = core.translateObjectArray(BUILDING_ENERGY_VALUE_TYPES, 'Display');
  }

  public setWidgetInstance(widgetInstance: WidgetInstance) {
    this.widgetInstance = widgetInstance;
  }

  ngOnInit() {
    this.widgetConfigSubscription = this.widgetInstance.changed.subscribe({
      next: (instance: WidgetInstance) => {
        this.updateData(instance);
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => { this.updateData(this.widgetInstance); }, 0);
  }

  ngOnDestroy() {
    if (this.widgetConfigSubscription) {
      this.widgetConfigSubscription.unsubscribe();
      this.widgetConfigSubscription = null;
    }

    if (this.widgetUpdaters) {
      this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
      this.widgetUpdaters = null;
    }
  }

  protected updateData(instance: WidgetInstance) {
    instance.widgetData = {
      title: instance.widgetConfig.title
    };

    if (!this.svgContainer)
      return;

    const div: HTMLDivElement = this.svgContainer.nativeElement;
    const svgs = div.getElementsByTagName('svg');
    this.svg = svgs && svgs.length ? svgs.item(0) : null;
    if (!this.svg)
      return;

    //init drawer
    this.drawer = new LinearRegression();
    this.drawer.left = instance.widgetConfig.leftMargin;
    this.drawer.right = instance.widgetConfig.rightMargin;
    this.drawer.top = instance.widgetConfig.topMargin;
    this.drawer.bottom = instance.widgetConfig.bottomMargin;
    this.drawer.numberOfDecimalsXValue = instance.widgetConfig.numberOfDecimalsXValue;
    this.drawer.numberOfDecimalsYValue = instance.widgetConfig.numberOfDecimalsYValue;
    this.drawer.circleColor = instance.widgetConfig.circleColor;
    this.drawer.regressionLineColor = instance.widgetConfig.regressionLineColor;
    this.drawer.hideLabels = instance.widgetConfig.hideLabels;
    this.drawer.hideRegressionLine = instance.widgetConfig.hideRegressionLine;
    this.drawer.valueTypeLabel = find(this.valueTypes, (t: any) => t.Key == this.widgetInstance.widgetConfig.valueType).Display;

    if (this.widgetUpdaters) {
      this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
      this.widgetUpdaters = null;
    }

    const dataSourceInstance = find(this.dashboardService.dataSourceInstances, (dataSourceInstance: DataSourceInstance) => dataSourceInstance.id == instance.widgetConfig.dataSourceId);
    if (dataSourceInstance) {
      this.widgetUpdaters = [];
      this.widgetUpdaters.push(new WidgetUpdater(dataSourceInstance, div, (dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement) => this.update(dataSourceInstance, nativeElement), null));
    }
  }

  protected update(dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement) {
    this.isLoading = dataSourceInstance?.result.isLoading;

    if (dataSourceInstance.result && dataSourceInstance.result.data) {
      const d3svg = select(this.svg);
      d3svg.attr('viewBox', '0 0 ' + (nativeElement.getBoundingClientRect().width) + ' ' + (nativeElement.getBoundingClientRect().height));

      this.buildingEnergyData = <BuildingEnergyData>(dataSourceInstance.result.data);
      const buildingIndex = findIndex(this.buildingEnergyData.BuildingData, (buildingData) => { return (buildingData as any).Asset.Id == this.widgetInstance.widgetConfig.buildingIndex });
      const dayFilterDayData: any[] = this.buildingEnergyData.BuildingData[buildingIndex].DayData.filter((b: any) => { return this.filterByDate(this.widgetInstance.widgetConfig.dayFilterKey, new Date(b.TimeStamp)) });

      const data: LinearRegressionData = new LinearRegressionData();
      data.data = dayFilterDayData.map(d => {
        let item = { label: new Date(d.TimeStamp), x: d.degreedays, y: this.convertValue(d, this.widgetInstance.widgetConfig.valueType, this.buildingEnergyData.BuildingData[buildingIndex].FloorSurface) };
        return item;
      });
      data.regressionParameters = this.loadRegressionData(this.buildingEnergyData.BuildingData[buildingIndex], this.widgetInstance.widgetConfig.valueType, this.widgetInstance.widgetConfig.dayFilterKey);

      // update value
      if (this.drawer) {
        const d3svg = select(this.svg);
        d3svg.selectAll('g').remove();
        d3svg.attr('viewBox', '0 0 ' + nativeElement.getBoundingClientRect().width + ' ' + nativeElement.getBoundingClientRect().height);

        this.drawer.group = d3svg.append('g');
        this.drawer.unitXValue = this.translate.get('i18n:DATASOURCE.BUILDING_ENERGY.DEGREE_DAY')
        this.drawer.unitYValue = this.getUoM(this.widgetInstance.widgetConfig.valueType);
        this.drawer.width = nativeElement.getBoundingClientRect().width;
        this.drawer.height = nativeElement.getBoundingClientRect().height;
        this.drawer.draw(data);
      }
    }
    //generate title if not present, update happens twice first time title gets filled in with null as unit, then with correct unit value. This is the reason widgetConfig.title does not get
    if (!this.widgetInstance.widgetConfig.title) {
      this.widgetInstance.widgetData.title = this.translate.get('i18n:BUILDING_ENERGY_LINEAR_REGRESSION_WIDGET') + ' (' + this.drawer.unitYValue + ')';
    }
  }

  public cancel(e: any) {
    e.preventDefault();
    e.cancelBubble = true;
  }

  protected filterByDate(dayFilterKey: any, d: Date) {
    switch (dayFilterKey) {
      case DayFilter.WeekDays:
        return d.getDay() != 0 && d.getDay() != 6;
      case DayFilter.WeekendDays:
        return d.getDay() == 0 || d.getDay() == 6;
      default:
        return true;
    }
  }
  protected convertValue(data: BuildingEnergyDetailData, type: BuildingEnergyValueType, floorSurface: number): number {
    switch (type) {
      case BuildingEnergyValueType.AbsoluteEnergy:
        return data["energy"];
      case BuildingEnergyValueType.AbsoluteEmission:
        return data["emission"];
      case BuildingEnergyValueType.AbsolutePrimaryEnergy:
        return data["primaryEnergy"];
      case BuildingEnergyValueType.RelativeEnergyToFloorSurface:
        if (floorSurface) {
          return data["energy"] / floorSurface;
        } else {
          return 0;
        }
      case BuildingEnergyValueType.RelativeEmissionToFloorSurface:
        if (floorSurface) {
          return data["emission"] / floorSurface;
        } else {
          return 0;
        }
      case BuildingEnergyValueType.RelativePrimaryEnergyToFloorSurface:
        if (floorSurface) {
          return data["primaryEnergy"] / floorSurface;
        } else {
          return 0;
        }
      case BuildingEnergyValueType.RelativeEnergyToDegreeDays:
        if (data["degreedays"]) {
          return data["energy"] / data["degreedays"];
        } else {
          return 0;
        }
      case BuildingEnergyValueType.RelativeEmissionToDegreeDays:
        if (data["degreedays"]) {
          return data["emission"] / data["degreedays"];
        } else {
          return 0;
        }
      case BuildingEnergyValueType.RelativePrimaryEnergyToDegreeDays:
        if (data["degreedays"]) {
          return data["primaryEnergy"] / data["degreedays"];
        } else {
          return 0;
        }
      case BuildingEnergyValueType.RelativeEnergyToFloorSurfaceAndDegreeDays:
        if (floorSurface && data["degreedays"]) {
          return data["energy"] / floorSurface / data["degreedays"];
        } else {
          return 0;
        }
      case BuildingEnergyValueType.RelativeEmissionToFloorSurfaceAndDegreeDays:
        if (floorSurface && data["degreedays"]) {
          return data["emission"] / floorSurface / data["degreedays"];
        } else {
          return 0;
        }
      case BuildingEnergyValueType.RelativePrimaryEnergyToFloorSurfaceAndDegreeDays:
        if (floorSurface && data["degreedays"]) {
          return data["primaryEnergy"] / floorSurface / data["degreedays"];
        } else {
          return 0;
        }
      default:
        return 0;
    }
  }
  protected getUoM(type: BuildingEnergyValueType): string {
    switch (type) {
      case BuildingEnergyValueType.AbsoluteEnergy:
      case BuildingEnergyValueType.AbsolutePrimaryEnergy:
        return this.buildingEnergyData.EnergyUnit?.Symbol;
      case BuildingEnergyValueType.AbsoluteEmission:
        return this.buildingEnergyData.EmissionUnit?.Symbol;
      case BuildingEnergyValueType.RelativeEnergyToFloorSurface:
      case BuildingEnergyValueType.RelativePrimaryEnergyToFloorSurface:
        return this.buildingEnergyData.EnergyUnit?.Symbol + ' / ' + this.buildingEnergyData.SurfaceUnit?.Symbol;
      case BuildingEnergyValueType.RelativeEmissionToFloorSurface:
        return this.buildingEnergyData.EmissionUnit?.Symbol + ' / ' + this.buildingEnergyData.SurfaceUnit?.Symbol;
      case BuildingEnergyValueType.RelativeEnergyToDegreeDays:
      case BuildingEnergyValueType.RelativePrimaryEnergyToDegreeDays:
        return this.buildingEnergyData.EnergyUnit?.Symbol + ' / degreedays';
      case BuildingEnergyValueType.RelativeEmissionToDegreeDays:
        return this.buildingEnergyData.EmissionUnit?.Symbol + ' / degreedays';
      case BuildingEnergyValueType.RelativeEnergyToFloorSurfaceAndDegreeDays:
      case BuildingEnergyValueType.RelativePrimaryEnergyToFloorSurfaceAndDegreeDays:
        return this.buildingEnergyData.EnergyUnit?.Symbol + ' / ' + this.buildingEnergyData.SurfaceUnit?.Symbol + ' / degreedays';
      case BuildingEnergyValueType.RelativeEmissionToFloorSurfaceAndDegreeDays:
        return this.buildingEnergyData.EmissionUnit?.Symbol + ' / ' + this.buildingEnergyData.SurfaceUnit?.Symbol + ' / degreedays';
      default:
        return '';
    }
  }
  protected loadRegressionData(data: BuildingEnergyBuildingData, type: BuildingEnergyValueType, dayFilter: DayFilter): RegressionParameters {
    switch (type) {
      case BuildingEnergyValueType.AbsoluteEnergy:
        switch (dayFilter) {
          case DayFilter.AllDays:
            return data["AbsoluteEnergyAllDaysRegression"];
          case DayFilter.WeekDays:
            return data["AbsoluteEnergyWeekdaysRegression"];
          case DayFilter.WeekendDays:
            return data["AbsoluteEnergyWeekendDaysRegression"];
        }
      case BuildingEnergyValueType.AbsoluteEmission:
        switch (dayFilter) {
          case DayFilter.AllDays:
            return data["AbsoluteEmissionAllDaysRegression"];
          case DayFilter.WeekDays:
            return data["AbsoluteEmissionWeekdaysRegression"];
          case DayFilter.WeekendDays:
            return data["AbsoluteEmissionWeekendDaysRegression"];
        }
      case BuildingEnergyValueType.AbsolutePrimaryEnergy:
        switch (dayFilter) {
          case DayFilter.AllDays:
            return data["AbsolutePrimaryEnergyAllDaysRegression"];
          case DayFilter.WeekDays:
            return data["AbsolutePrimaryEnergyWeekdaysRegression"];
          case DayFilter.WeekendDays:
            return data["AbsolutePrimaryEnergyWeekendDaysRegression"];
        }
      case BuildingEnergyValueType.RelativeEnergyToFloorSurface:
        switch (dayFilter) {
          case DayFilter.AllDays:
            return data["RelativeEnergyToFloorSurfaceAllDaysRegression"];
          case DayFilter.WeekDays:
            return data["RelativeEnergyToFloorSurfaceWeekdaysRegression"];
          case DayFilter.WeekendDays:
            return data["RelativeEnergyToFloorSurfaceWeekendDaysRegression"];
        }
      case BuildingEnergyValueType.RelativeEmissionToFloorSurface:
        switch (dayFilter) {
          case DayFilter.AllDays:
            return data["RelativeEmissionToFloorSurfaceAllDaysRegression"];
          case DayFilter.WeekDays:
            return data["RelativeEmissionToFloorSurfaceWeekdaysRegression"];
          case DayFilter.WeekendDays:
            return data["RelativeEmissionToFloorSurfaceWeekendDaysRegression"];
        }
      case BuildingEnergyValueType.RelativePrimaryEnergyToFloorSurface:
        switch (dayFilter) {
          case DayFilter.AllDays:
            return data["RelativePrimaryEnergyToFloorSurfaceAllDaysRegression"];
          case DayFilter.WeekDays:
            return data["RelativePrimaryEnergyToFloorSurfaceWeekdaysRegression"];
          case DayFilter.WeekendDays:
            return data["RelativePrimaryEnergyToFloorSurfaceWeekendDaysRegression"];
        }
      case BuildingEnergyValueType.RelativeEnergyToDegreeDays:
        switch (dayFilter) {
          case DayFilter.AllDays:
            return data["RelativeEnergyToDegreeDaysAllDaysRegression"];
          case DayFilter.WeekDays:
            return data["RelativeEnergyToDegreeDaysWeekdaysRegression"];
          case DayFilter.WeekendDays:
            return data["RelativeEnergyToDegreeDaysWeekendDaysRegression"];
        }
      case BuildingEnergyValueType.RelativeEmissionToDegreeDays:
        switch (dayFilter) {
          case DayFilter.AllDays:
            return data["RelativeEmissionAllDaysRegression"];
          case DayFilter.WeekDays:
            return data["RelativeEmissionWeekdaysRegression"];
          case DayFilter.WeekendDays:
            return data["RelativeEmissionWeekendDaysRegression"];
        }
      case BuildingEnergyValueType.RelativePrimaryEnergyToDegreeDays:
        switch (dayFilter) {
          case DayFilter.AllDays:
            return data["RelativePrimaryAllDaysRegression"];
          case DayFilter.WeekDays:
            return data["RelativePrimaryWeekdaysRegression"];
          case DayFilter.WeekendDays:
            return data["RelativePrimaryWeekendDaysRegression"];
        }
      default:
        return null;
    }
  }
}


export class VisualizationConfig {
  leftMargin: number; //Includes the Y values
  rightMargin: number;
  topMargin: number;
  bottomMargin: number; //Includes the X values
  height: number;
  width: number;

  constructor(leftMargin: number, rightMargin: number, topMargin: number, bottomMargin: number, height: number, width: number) {
    this.leftMargin = leftMargin;
    this.rightMargin = rightMargin;
    this.topMargin = topMargin;
    this.bottomMargin = bottomMargin;
    this.height = height;
    this.width = width;
  }
}