<form class="k-form" [formGroup]="formGroup">
  <fieldset class="k-form-fieldset">
    <legend class="k-form-legend">{{ 'LABEL.GENERAL' | translate}}</legend>
    <kendo-formfield>
      <kendo-label [for]="title" text="{{ 'WIDGET.TITLE' | translate }}"></kendo-label>
      <input #title formControlName="title" kendoTextBox />
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="widgetType" text="{{ 'WIDGET.TYPE' | translate }}"></kendo-label>
      <kendo-textbox #widgetType formControlName="widgetType" [readonly]="true" ></kendo-textbox>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="dataSourceId" text="{{ 'WIDGET.DATASOURCE' | translate }}"></kendo-label>
      <ats-platform-entity-lookup-textbox #dataSourceId formControlName="dataSourceId"
        [settings]="dataSourceSelectionPopupSettings" [formatter]="dataSourceFormatter"
        (entityChange)="onDataSourceChange($event)"></ats-platform-entity-lookup-textbox>
      <kendo-formerror *ngIf="formGroup.controls['dataSourceId'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' |
        translate }}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="groupBy" text="{{ 'WIDGET.STACKED' | translate }}"></kendo-label>
      <kendo-dropdownlist #groupBy formControlName="groupBy" [textField]="'Display'" [valueField]="'Key'"
        [valuePrimitive]="true" [data]="timeUnits"></kendo-dropdownlist>
      <kendo-formerror *ngIf="formGroup.controls['groupBy'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' |
        translate }}
      </kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="selectedCategories" text="{{ 'WIDGET.SELECTEDCATEGORIES' | translate }}">
      </kendo-label>
      <kendo-multiselect #selectedCategories formControlName="selectedCategories" textField="Display"
        valueField="Name" [valuePrimitive]="true" [data]="categories" (open)="onOpen($event)"></kendo-multiselect>
      <kendo-formerror *ngIf="formGroup.controls['selectedCategories'].errors?.['required']">{{
        'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
    </kendo-formfield>
  </fieldset>
  <fieldset class="k-form-fieldset">
    <legend class="k-form-legend">{{ "LABEL.SETTINGS" | translate }}</legend>
    <kendo-formfield>
      <kendo-label [for]="leftMargin" text="{{ 'WIDGET.LEFTMARGIN' | translate }}"></kendo-label>
      <kendo-numerictextbox #leftMargin formControlName="leftMargin" [decimals]="0" [format]="'n0'">
      </kendo-numerictextbox>
      <kendo-formerror *ngIf="formGroup.controls['leftMargin'].errors?.['required']">{{
        "MESSAGE.FIELD_IS_REQUIRED" | translate
        }}</kendo-formerror>
      <kendo-formerror *ngIf="formGroup.controls['leftMargin'].errors?.['minError']">{{
        translate.get(
        "i18n:MESSAGE.VALIDATION_MINIMUM",
        formGroup.controls.leftMargin.errors.minError
        )
        }}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="rightMargin" text="{{ 'WIDGET.RIGHTMARGIN' | translate }}"></kendo-label>
      <kendo-numerictextbox #rightMargin formControlName="rightMargin" [decimals]="0" [format]="'n0'">
      </kendo-numerictextbox>
      <kendo-formerror *ngIf="formGroup.controls['rightMargin'].errors?.['required']">{{ "MESSAGE.FIELD_IS_REQUIRED" |
        translate }}</kendo-formerror>
      <kendo-formerror *ngIf="formGroup.controls['rightMargin'].errors?.['minError']">{{
        translate.get(
        "i18n:MESSAGE.VALIDATION_MINIMUM",
        formGroup.controls.rightMargin.errors.minError
        )
        }}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="topMargin" text="{{ 'WIDGET.topMargin' | translate }}"></kendo-label>
      <kendo-numerictextbox #topMargin formControlName="topMargin" [decimals]="0" [format]="'n0'">
      </kendo-numerictextbox>
      <kendo-formerror *ngIf="formGroup.controls['topMargin'].errors?.['required']">{{
        "MESSAGE.FIELD_IS_REQUIRED" | translate
        }}</kendo-formerror>
      <kendo-formerror *ngIf="formGroup.controls['topMargin'].errors?.['minError']">{{
        translate.get(
        "i18n:MESSAGE.VALIDATION_MINIMUM",
        formGroup.controls.topMargin.errors.minError
        )
        }}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="bottomMargin" text="{{ 'WIDGET.bottomMargin' | translate }}"></kendo-label>
      <kendo-numerictextbox #bottomMargin formControlName="bottomMargin" [decimals]="0" [format]="'n0'">
      </kendo-numerictextbox>
      <kendo-formerror *ngIf="formGroup.controls['bottomMargin'].errors?.['required']">{{ "MESSAGE.FIELD_IS_REQUIRED" |
        translate }}</kendo-formerror>
      <kendo-formerror *ngIf="formGroup.controls['bottomMargin'].errors?.['minError']">{{
        translate.get(
        "i18n:MESSAGE.VALIDATION_MINIMUM",
        formGroup.controls.bottomMargin.errors.minError
        )
        }}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="numberOfDecimals" text="{{ 'WIDGET.NUMBEROFDECIMALS' | translate }}"></kendo-label>
      <kendo-numerictextbox #numberOfDecimals formControlName="numberOfDecimals" [min]="0" [decimals]="0"
        [format]="'n0'"></kendo-numerictextbox>
      <kendo-formerror *ngIf="formGroup.controls['numberOfDecimals'].errors?.['minError']">{{
        translate.get(
        "i18n:MESSAGE.VALIDATION_MINIMUM",
        formGroup.controls.numberOfDecimals.errors.minError
        )
        }}</kendo-formerror>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label [for]="trendView" text="{{ 'WIDGET.TRENDVIEW' | translate }}"></kendo-label>
      <kendo-switch #trendView formControlName="trendView"></kendo-switch>
    </kendo-formfield>
  </fieldset>
</form>