import { Component, OnDestroy, OnInit, AfterViewInit } from "@angular/core";
import { AuthService, CoreService, EntitySelectionPopupSettings, TranslateService, } from "@ats/ats-platform-dashboard";
import { Asset } from "../../domain/entities/asset";
import { TagChart } from "../../domain/entities/tagChart";
import { ChartService } from "../../services/chart.service";
import { find, indexOf } from "lodash-es";
import { TagChartItem } from "../../domain/entities/tagChartItem";
import { Subscription } from "rxjs";
import { Breakpoints } from '@angular/cdk/layout';
import { WindowService } from "@progress/kendo-angular-dialog";

@Component({
  selector: 'ats-smart-tool-chart-toolbar',
  templateUrl: './chart-toolbar.component.html',
  styleUrls: ['./chart-toolbar.component.scss']
})
export class ChartToolbarComponent implements OnInit, OnDestroy {

  public saveButtons: any[];
  public breakpoint: string;

  public assetSelectionPopupSettings: EntitySelectionPopupSettings;
  public assetFormatter = (task: Asset) => task?.Path;

  private currentChartSubscription: Subscription;
  public isAssetFilterShow: boolean = false;

  constructor(public translate: TranslateService, public chartService: ChartService, public authService: AuthService, public core: CoreService) {
    this.saveButtons = [{
      text: this.translate.get('i18n:BUTTON.SAVE'),
      click: () => {
        this.chartService.save(chartService.currentChart);
      }
    }, {
      text: this.translate.get('i18n:BUTTON.SAVE_AS_COPY'),
      click: () => {
        this.chartService.saveAsCopy(chartService.currentChart);
      }
    }];

    this.assetSelectionPopupSettings = {
      title: translate.get('i18n:SELECT_ASSET'),
      entitySet: 'UserAssets',
      singleEntitySet: 'Assets',
      includes: 'AssetType',
      sort: [{ field: 'Path', dir: 'asc' }],
      multiSelect: false,
      selectionRequired: false,
      columns: [
        { field: 'Path', title: translate.get('i18n:ASSET.PATH'), filterable: true },
        { field: 'AssetType.Name', title: translate.get('i18n:ASSET.TYPE'), filterable: true }
      ],
      width: '300px',
      placeholder: translate.get('i18n:SELECT_ASSET')
    };
  }

  ngOnInit(): void {
    this.currentChartSubscription = this.chartService.currentChart$.subscribe({
      next: (chart: TagChart) => {
        if (chart && chart.AssetTypeId) {
          this.assetSelectionPopupSettings.filter = { field: 'AssetTypeId', operator: 'eq', value: chart.AssetTypeId };
        } else {
          this.assetSelectionPopupSettings.filter = null;
        }
        this.showAssetFilter();
      }
    });
    this.core.breakpoint$.subscribe({ next: (value: string) => { this.breakpoint = value; } });
  }

  ngOnDestroy(): void {
    if (this.currentChartSubscription) {
      this.currentChartSubscription.unsubscribe();
      this.currentChartSubscription = null;
    }
  }

  public isWebPage(): boolean {
    return this.breakpoint === Breakpoints.Web;
  }

  public onChartAddClicked() {
    if (this.chartService.currentChart?.Id) {
      this.core.navigate(['chart', 'new'], {
        state: {
          FolderId: this.chartService.currentFolderId
        }
      });
    } else {
      const chart = new TagChart();
      chart.Name = 'New chart'; // TODO: translate
      chart.FolderId = this.chartService.currentFolderId;
      chart.Config = JSON.stringify({});

      this.chartService.currentChart = chart;
    }
  }

  public onChartEditClicked() {
    this.chartService.showChartPopup(this.chartService.currentChart, this.chartService.currentFolderId);
  }

  public onChartDownloadClicked() {
    this.chartService.showFileSavePopup(this.chartService.currentChart);
  }

  public onChartDeleteClicked() {
    this.chartService.deleteChart(this.chartService.currentChart.Id);
  }

  public onReloadClicked() {
    this.chartService.reload();
  }

  public onChartToReportClicked() {
    this.chartService.showChartToReportPopup();
  }
  

  public onConfigChanged(newConfig: any) {
    setTimeout(() => {
      if (this.chartService.currentChart && newConfig) {
        this.chartService.currentChart.From = newConfig?.From;
        this.chartService.currentChart.To = newConfig?.To;
        this.chartService.currentChart.Period = newConfig?.Period;
      }
    }, 0);
  }

  public onAssetIdChanged(assetId: string) { 
    if (this.chartService.currentChart.AssetId !== assetId) {
      this.chartService.currentChart.AssetId = assetId;
      this.chartService.reload();
    }
  }

  public showAssetFilter() {

    this.isAssetFilterShow = false;
    if (this.chartService && this.chartService.currentChart) {
      if (this.chartService.currentChart.AssetId || this.chartService.currentChart.AssetTypeId || this.chartService.currentChart.TemplateId) {      
        this.isAssetFilterShow = true;
      }

      var discriminators = ['AssetTypeTag', 'AssetTypeConditionTag', 'AssetTypeCalculatedTag', 'AssetTypeComposedTag', 'AssetTypeArchiveTag'];
      if (find(this.chartService.currentChart.Items, (x: TagChartItem) => indexOf(discriminators, x.GenericAssetTagData?.Discriminator) != -1)) {      
        this.isAssetFilterShow =  true;
      }
    }
  }

}
