import { Injectable } from "@angular/core";
import { AbstractDataSourceExporter, DataSourceInstance, CoreService } from "@ats/ats-platform-dashboard";
import { TwoSocketEVCharger } from "../domain/dataModels/twoSocketEVCharger";
import { isString, join } from "lodash-es";
import { DateTime } from "luxon";
import { EVSocket } from "../domain/dataModels/evSocket";
import { EVSocketDataPoint } from "../domain/dataModels/evSocketDataPoint";


@Injectable()
export class EVChargersDataSourceExporter extends AbstractDataSourceExporter {

  constructor(public core: CoreService) {
    super();
  }
  public getAvailableExtensions(dataSourceInstance: DataSourceInstance): { Key: string, Display: string }[] {
    if (dataSourceInstance && dataSourceInstance.result) {
      switch (dataSourceInstance.result.contentType) {
        case 'application/json':
          return this.core.translateObjectArray([
            { Key: 'json', Display: 'json' },
            { Key: 'csv ,', Display: 'csv (,)' },
            { Key: 'csv ;', Display: 'csv (;)' },
            { Key: 'csv s', Display: 'i18n:CSV.SEPARATOR.SPACE' },
            { Key: 'csv t', Display: 'i18n:CSV.SEPARATOR.TAB' },
            { Key: 'csv |', Display: 'i18n:CSV.SEPARATOR.PIPE' }
          ], 'Display');
      }
    }
    return null;
  }

  public export(dataSourceInstance: DataSourceInstance, fileName: string, extension: string) {
    let data: Uint8Array;

    if (dataSourceInstance && dataSourceInstance.result) {
      const result = dataSourceInstance.result;

      switch (extension) {
        case 'json': {
          const str = JSON.stringify(result.data,
            (key: string, value: any): any => {
              if (key == "LatestPointSubject" || key == "LatestPoint$")
                return undefined;
              return value;
            }, 2);
          data = new Uint8Array(str.length);
          data.forEach((value, index, array) => { array[index] = str.charCodeAt(index); });
          break;
        } 
        case 'csv ,': { data = this.convertToCsvData(<TwoSocketEVCharger[]>result.data, ","); extension = "csv"; break; }
        case 'csv ;': { data = this.convertToCsvData(<TwoSocketEVCharger[]>result.data, ";"); extension = "csv"; break; }
        case 'csv s': { data = this.convertToCsvData(<TwoSocketEVCharger[]>result.data, " "); extension = "csv"; break; }
        case 'csv t': { data = this.convertToCsvData(<TwoSocketEVCharger[]>result.data, "\t"); extension = "csv"; break; }
        case 'csv |': { data = this.convertToCsvData(<TwoSocketEVCharger[]>result.data, "|"); extension = "csv"; break; }
      }

      if (!data) {
        throw 'No data to export.';
      }

      this.core.generateDownload(data, fileName, extension, result.contentType);
    } else {
      throw 'No data to export.';
    }
  }

  private convertToCsvData(twoSocketEVChargerData: TwoSocketEVCharger[], separator: string): Uint8Array {
    let csvdata: Uint8Array;

    if (twoSocketEVChargerData && twoSocketEVChargerData.length) {
      let str = join([' TimeStamp', 'Asset', 'Socket', 'State', 'ActivePower', 'ChargedEnergy', '\r\n'], separator);
     
      twoSocketEVChargerData.forEach((charger: TwoSocketEVCharger) => {
        var assetPath = charger.Asset.Path ?? '';
        str += this.appendSocketData(charger.Socket1, assetPath, 1, separator);
        str += this.appendSocketData(charger.Socket2, assetPath, 2, separator);
      });

      csvdata = new Uint8Array(str.length);
      csvdata.forEach((value, index, array) => { array[index] = str.charCodeAt(index); });
    } 

    return csvdata;
  }

  private appendSocketData(evSocket: EVSocket, assetPath: string, socketNumber: number, separator: string): string {
    let str = '';

    if (evSocket.Points && evSocket.Points.length) {
      
      evSocket.Points.forEach((point: EVSocketDataPoint) => {
        str += '"' + (isString(point.TimeStamp) ? DateTime.fromISO(point.TimeStamp.toString()).toISO() : DateTime.fromJSDate(point.TimeStamp).toISO()) + '"' + separator;
        str += '"' + assetPath + '"' + separator;
    
        str += (socketNumber ?? '') + separator;
        str += (point.State ?? '') + separator;
        str += (point.ActivePower ?? '') + separator;
        str += (point.ChargedEnergy ?? '') + '\r\n';
        
      });
    }

    return str;
  }

}
 