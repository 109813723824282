import { AccessRights, Folder } from "@ats/ats-platform-dashboard";
import { ReportDataSource } from "./reportDataSource";
import { ReportDefinition } from "./reportDefinition";
import { ReportParameterValue } from "./reportParameterValue";

export class Report {
    Id: string;
    Name: string;
    ReportDefinitionId: string;
    ReportDefinition: ReportDefinition;
    FolderId: string;
    Folder: Folder;

    ParameterValues: ReportParameterValue[];
    DataSources: ReportDataSource[];
    AccessRights: AccessRights[];
}