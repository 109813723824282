import { Component, Inject, Injector } from '@angular/core';
import { find, flatten, keyBy, includes, concat } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { of, map, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AbstractDataSourceLoader, ApiQueryOptions, ApiService, CoreService, DATASOURCETYPES, DashboardWidgetDocument, DataSource, DataSourceType, EntitySelectionPopupSettings, TranslateService, TreeItem, WidgetConfigComponent } from '@ats/ats-platform-dashboard';
import { TagChart } from '../../domain/entities/tagChart';

@Component({
  selector: 'ats-smart-tool-ev-chargers-map-widget-config',
  templateUrl: './ev-chargers-map-widget-config.component.html',
  styleUrls: ['./ev-chargers-map-widget-config.component.scss']
})
export class EVChargersMapWidgetConfigComponent implements WidgetConfigComponent {

  public dataSourceSelectionPopupSettings: EntitySelectionPopupSettings;
  public dataSourceFormatter = (dataSource: DataSource) => dataSource ? dataSource.Folder.Path + ' / ' + dataSource.Name : null;
  
  public chartSelectionPopupSettings: EntitySelectionPopupSettings;
  public chartFormatter = (chart: TagChart) => chart?.Name;
  
  public formGroup: FormGroup;

  public svgEditorOptions = { theme: 'vs', language: 'xml' };

  private documentsByKey: { [key: string]: DashboardWidgetDocument };

  public dataSourceTypes: DataSourceType[];
 
  constructor(private api: ApiService, public core: CoreService, public translate: TranslateService, @Inject(DATASOURCETYPES) dataSourceTypes: DataSourceType[][], private injector: Injector) {
    this.formGroup = new FormGroup({
      title: new FormControl(''),
      dataSourceId: new FormControl(null, Validators.required),
      chartId: new FormControl(null),
      svgContent: new FormControl(''),
      widgetType: new FormControl(translate.get('EV_CHARGERS_MAP_WIDGET'))
    });

    this.dataSourceTypes = this.core.translateAndSortObjectArray(flatten(dataSourceTypes), "description");

    this.dataSourceSelectionPopupSettings = {
      title: translate.get('i18n:SELECT_DATASOURCE'),
      entitySet: 'DataSources',
      filter: { field: 'Type', operator: 'eq', value: 'evChargers' },
      includes: 'Configs,Folder',
      sort: [{ field: 'Folder.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
      multiSelect: false,
      selectionRequired: true,
      columns: [{ field: 'Folder.Path', title: this.translate.get('i18n:FOLDER.PATH'), filterable: true },{ field: 'Name', title: translate.get('i18n:DATASOURCE.NAME'), filterable: true }],
      info: translate.get('i18n:WIDGET.SUPPORTED_DATATYPES') +': '+ translate.get('i18n:DATASOURCE.EV_CHARGERS')
    };

    this.chartSelectionPopupSettings = {
      title: translate.get('i18n:SELECT_CHART'),
      entitySet: 'TagCharts',
      includes: 'Folder',
      sort: [{ field: 'Folder.Path', dir: 'asc' },{ field: 'Name', dir: 'asc' }],
      multiSelect: false,
      selectionRequired: false,
      columns: [
        { field: 'Folder.Path', title: this.translate.get('i18n:FOLDER.PATH'), filterable: true },
        { field: 'Name', title: this.translate.get('i18n:CHART.NAME'), filterable: true }
      ],
      placeholder: translate.get('i18n:SELECT_CHART')
    };
  }

  public isValid(): boolean {
    this.formGroup.markAllAsTouched();
    return this.formGroup.valid;
  }

  public getConfig(): any {
    return {
      title: this.formGroup.get('title').value,
      dataSourceId: this.formGroup.get('dataSourceId').value,
      chartId: this.formGroup.get('chartId').value
    };
  }

  public getDocuments(): DashboardWidgetDocument[] {
    let result: DashboardWidgetDocument[] = [];

    const svgContent: string = this.formGroup.get('svgContent').value;
    if (svgContent && svgContent.length) {
      if (this.documentsByKey['svgContent']) {
        this.documentsByKey['svgContent'].Content = svgContent;
        result.push(this.documentsByKey['svgContent']);
      } else {
        result.push({ Id: uuidv4(), Name: 'svgContent', Content: svgContent });
      }
    }

    return result;
  }

  public setData(config: any, documents: DashboardWidgetDocument[]) {
    this.formGroup.get('title').setValue(config?.title ?? '');
    this.formGroup.get('dataSourceId').setValue(config?.dataSourceId);
    this.formGroup.get('chartId').setValue(config?.chartId);

    this.documentsByKey = (documents && documents.length) ? keyBy(documents, 'Name') : {};

    if (this.documentsByKey['svgContent']) {
      this.formGroup.get('svgContent').setValue(this.documentsByKey['svgContent'].Content);
    } else {
      this.formGroup.get('svgContent').setValue('');
    }
  }
}
