export enum BuildingEnergyValueType{
   AbsoluteEnergy,
   RelativeEnergyToFloorSurface,
   RelativeEnergyToDegreeDays,
   RelativeEnergyToFloorSurfaceAndDegreeDays,
   AbsoluteEmission,
   RelativeEmissionToFloorSurface,
   RelativeEmissionToDegreeDays,
   RelativeEmissionToFloorSurfaceAndDegreeDays,
   AbsolutePrimaryEnergy,
   RelativePrimaryEnergyToFloorSurface,
   RelativePrimaryEnergyToDegreeDays,
   RelativePrimaryEnergyToFloorSurfaceAndDegreeDays
 }

 export const BUILDING_ENERGY_VALUE_TYPES = [
   { Key: BuildingEnergyValueType.AbsoluteEnergy, Display: 'i18n:VIEW_STATIC_LIST.VALUETYPE.ABSOLUTEENERGY' },
   { Key: BuildingEnergyValueType.AbsoluteEmission, Display: 'i18n:VIEW_STATIC_LIST.VALUETYPE.ABSOLUTEEMISSION' },
   { Key: BuildingEnergyValueType.AbsolutePrimaryEnergy, Display: 'i18n:VIEW_STATIC_LIST.VALUETYPE.ABSOLUTEPRIMARYENERGY' },
   { Key: BuildingEnergyValueType.RelativeEnergyToFloorSurface, Display: 'i18n:VIEW_STATIC_LIST.VALUETYPE.RELATIVEENERGYTOFLOORSURFACE' },
   { Key: BuildingEnergyValueType.RelativeEmissionToFloorSurface, Display: 'i18n:VIEW_STATIC_LIST.VALUETYPE.RELATIVEEMISSIONTOFLOORSURFACE' },
   { Key: BuildingEnergyValueType.RelativePrimaryEnergyToFloorSurface, Display: 'i18n:VIEW_STATIC_LIST.VALUETYPE.RELATIVEPRIMARYENERGYTOFLOORSURFACE' },
   { Key: BuildingEnergyValueType.RelativeEnergyToDegreeDays, Display: 'i18n:VIEW_STATIC_LIST.VALUETYPE.RELATIVEENERGYTODEGREEDAYS' },
   { Key: BuildingEnergyValueType.RelativeEmissionToDegreeDays, Display: 'i18n:VIEW_STATIC_LIST.VALUETYPE.RELATIVEEMISSIONTODEGREEDAYS' },
   { Key: BuildingEnergyValueType.RelativePrimaryEnergyToDegreeDays, Display: 'i18n:VIEW_STATIC_LIST.VALUETYPE.RELATIVEPRIMARYENERGYTODEGREEDAYS' },
   { Key: BuildingEnergyValueType.RelativeEnergyToFloorSurfaceAndDegreeDays, Display: 'i18n:VIEW_STATIC_LIST.VALUETYPE.RELATIVEENERGYTOFLOORSURFACEANDDEGREEDAYS' },
   { Key: BuildingEnergyValueType.RelativeEmissionToFloorSurfaceAndDegreeDays, Display: 'i18n:VIEW_STATIC_LIST.VALUETYPE.RELATIVEEMISSIONTOFLOORSURFACEANDDEGREEDAYS' },
   { Key: BuildingEnergyValueType.RelativePrimaryEnergyToFloorSurfaceAndDegreeDays, Display: 'i18n:VIEW_STATIC_LIST.VALUETYPE.RELATIVEPRIMARYENERGYTOFLOORSURFACEANDDEGREEDAYS' },
 ];