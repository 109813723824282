<div class="report-download" #reportContainer>
  <form class="k-form" [formGroup]="formGroup">
    <fieldset *ngIf="reportDefinition && reportDefinition.Parameters" class="k-form-fieldset">
      <legend class="k-form-legend">{{ 'REPORT.GENERAL' | translate }}</legend>
      <kendo-formfield>
        <kendo-label [for]="_name" text="{{ 'COMMON.NAME' | translate }}"></kendo-label>
        <input  #_name formControlName="_name" kendoTextBox />
      </kendo-formfield>
    </fieldset>
    <fieldset *ngIf="reportDefinition && reportDefinition.Parameters" class="k-form-fieldset">
      <legend class="k-form-legend">{{ 'REPORT.PARAMETERS' | translate }}</legend>
      <div *ngFor="let parameter of reportDefinition.Parameters">
        <kendo-formfield>
          <kendo-label [for]="parameter.Id" text="{{ parameter.Name }}"></kendo-label>
          <input *ngIf="parameter.Type == 0" id="{{ parameter.Id }}" [formControlName]="parameter.Id" kendoTextBox />
          <kendo-numerictextbox *ngIf="parameter.Type == 1" id="{{ parameter.Id }}" [formControlName]="parameter.Id" [decimals]="0" [format]="'n0'"></kendo-numerictextbox>
          <kendo-numerictextbox *ngIf="parameter.Type == 2" id="{{ parameter.Id }}" [formControlName]="parameter.Id"></kendo-numerictextbox>
          <ats-platform-date-time-box *ngIf="parameter.Type == 3" id="{{ parameter.Id }}" [formControlName]="parameter.Id" [showTime]="true"></ats-platform-date-time-box>
          <kendo-switch *ngIf="parameter.Type == 4" id="{{ parameter.Id }}" [formControlName]="parameter.Id"></kendo-switch>
          <ats-platform-date-time-box *ngIf="parameter.Type == 5" id="{{ parameter.Id }}" [formControlName]="parameter.Id" [showTime]="false"></ats-platform-date-time-box>
          <kendo-formerror *ngIf="formGroup.controls[parameter.Id].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
      </div>
    </fieldset>
    <fieldset *ngIf="reportDefinition && reportDefinition.Parameters" class="k-form-fieldset">
      <legend class="k-form-legend">{{ 'REPORT.DATASOURCES' | translate }}</legend>
      <div *ngFor="let dataSource of reportDefinition.DataSources">
        <kendo-formfield>
          <kendo-label [for]="dataSource.Id" text="{{ dataSource.Name }}"></kendo-label>
          <ats-platform-entity-lookup-textbox id="{{ dataSource.Id }}" [formControlName]="dataSource.Id" [settings]="dataSourceSelectionPopupSettings[dataSource.Id]" [formatter]="dataSourceFormatter">
          </ats-platform-entity-lookup-textbox>
          <kendo-formerror *ngIf="formGroup.controls[dataSource.Id].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
      </div>
    </fieldset>
  </form>
</div>
