import { Component, Inject, Injector } from '@angular/core';
import * as _ from 'lodash';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService, CoreService, DashboardWidgetDocument, DataSource, DataSourceType, DATASOURCETYPES, EntitySelectionPopupSettings, TranslateService, WidgetConfigComponent } from '@ats/ats-platform-dashboard';
 
@Component({
  selector: 'ats-smart-tool-device-topology-widget-config',
  templateUrl: './device-topology-widget-config.component.html',
  styleUrls: ['./device-topology-widget-config.component.scss']
})
 
export class DeviceTopologyWidgetConfigComponent implements WidgetConfigComponent {

  public dataSourceSelectionPopupSettings: EntitySelectionPopupSettings;
  public dataSourceFormatter = (dataSource: DataSource) => dataSource ? dataSource.Folder.Path + ' / ' + dataSource.Name : null;
  public formGroup: FormGroup;

  constructor(public core: CoreService, public translate: TranslateService, private api: ApiService, @Inject(DATASOURCETYPES) dataSourceTypes: DataSourceType[][], private injector: Injector) {
    this.formGroup = new FormGroup({
      title: new FormControl(''),
      widgetType: new FormControl(translate.get('i18n:DEVICE_TOPOLOGY')),
      dataSourceId: new FormControl(null, Validators.required)
    });
    this.dataSourceSelectionPopupSettings = {
      title: translate.get('i18n:SELECT_DATASOURCE'),
      entitySet: 'DataSources',
      filter: {
        logic: 'or',
        filters: [
          { field: 'Type', operator: 'eq', value: 'deviceTopology' }
        ]
      },
      includes: 'Configs,Folder',
      sort: [{ field: 'Folder.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
      multiSelect: false,
      selectionRequired: true,
      columns: [{ field: 'Folder.Path', title: this.translate.get('i18n:FOLDER.PATH'), filterable: true }, { field: 'Name', title: translate.get('i18n:DATASOURCE.NAME'), filterable: true }],
      info: translate.get('i18n:WIDGET.SUPPORTED_DATATYPES') + ': ' + translate.get('i18n:DATASOURCE.EV_CHARGERS')
    };
  }

  public isValid(): boolean {
    this.formGroup.markAllAsTouched();
    return this.formGroup.valid;
  }
  public getConfig(): any {
    return {
      title: this.formGroup.get('title').value,
      dataSourceId: this.formGroup.get('dataSourceId').value
    };
  }
  public getDocuments(): DashboardWidgetDocument[] {
    return null;
  }
  public setData(config: any, documents: DashboardWidgetDocument[]) {
    this.formGroup.get('title').setValue(config?.title);
    this.formGroup.get('dataSourceId').setValue(config?.dataSourceId);
  }
}