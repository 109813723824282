<div #notificationRef>
    <form class="k-form" [formGroup]="formGroup">
        <fieldset class="k-form-fieldset">
            <legend class="k-form-legend">{{ 'i18n:CHART.GENERAL' | translate }}</legend>
            <kendo-formfield>
                <kendo-label [for]="name" text="{{ 'i18n:CHART.NAME' | translate }}"></kendo-label>
                <input #name formControlName="name" kendoTextBox />
                <kendo-formerror *ngIf="formGroup.controls['name'].errors?.['required']">{{ 'i18n:MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
            </kendo-formfield>
            <kendo-formfield>
              <kendo-label [for]="folderId" text="{{ 'CHART.FOLDERID' | translate }}"></kendo-label>
              <ats-platform-entity-lookup-textbox #folderId formControlName="folderId" [settings]="folderSelectionPopupSettings" [formatter]="folderFormatter"></ats-platform-entity-lookup-textbox>
              <kendo-formerror *ngIf="formGroup.controls['folderId'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
            </kendo-formfield>
            <kendo-formfield>
                <kendo-label [for]="assetTypeId" text="{{ 'i18n:CHART.ASSET_TYPE' | translate }}"></kendo-label>
                <ats-platform-entity-lookup-textbox #assetTypeId formControlName="assetTypeId" [settings]="assetTypeSelectionPopupSettings" [formatter]="assetTypeFormatter"></ats-platform-entity-lookup-textbox>
            </kendo-formfield>
            <kendo-formfield>
                <kendo-label [for]="templateId" text="{{ 'i18n:CHART.TEMPLATE' | translate }}"></kendo-label>
                <ats-platform-entity-lookup-textbox #templateId formControlName="templateId" [settings]="templateSelectionPopupSettings" [formatter]="templateFormatter"></ats-platform-entity-lookup-textbox>
            </kendo-formfield>
            <kendo-formfield>
                <kendo-label [for]="aggregationWindow" text="{{ 'i18n:CHART.AGGREGATION_WINDOW' | translate }}"></kendo-label>
                <kendo-combobox #aggregationWindow formControlName="aggregationWindow" [data]="timeIntervals" [textField]="'Display'" [valueField]="'Key'" [valuePrimitive]="true" [clearButton]="false"></kendo-combobox>
                <kendo-formerror *ngIf="formGroup.controls['aggregationWindow'].errors?.['required']">{{ 'i18n:MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
            </kendo-formfield>
            <kendo-formfield>
                <kendo-label [for]="maxPoints" text="{{ 'i18n:CHART.MAX_POINTS' | translate }}"></kendo-label>
                <kendo-numerictextbox #maxPoints formControlName="maxPoints" [min]="1" [max]="1000000" [decimals]="0" [format]="'n0'"></kendo-numerictextbox>
                <kendo-formerror *ngIf="formGroup.controls['maxPoints'].errors?.['required']">{{ 'i18n:MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
                <kendo-formerror *ngIf="formGroup.controls['maxPoints'].errors?.['minError']">{{ translate.get('i18n:MESSAGE.VALIDATION_MINIMUM', formGroup.controls.maxPoints.errors.minError) }}</kendo-formerror>
                <kendo-formerror *ngIf="formGroup.controls['maxPoints'].errors?.['maxError']">{{ translate.get('i18n:MESSAGE.VALIDATION_MAXIMUM', formGroup.controls.maxPoints.errors.maxError) }}</kendo-formerror>
            </kendo-formfield>
            <kendo-formfield>
                <kendo-label [for]="chartHeight" text="{{ 'i18n:CHART.HEIGHT' | translate }}"></kendo-label>
                <kendo-numerictextbox #chartHeight formControlName="chartHeight" [min]="150" [max]="1000" [decimals]="0" [format]="'n0'"></kendo-numerictextbox>
                <kendo-formerror *ngIf="formGroup.controls['chartHeight'].errors?.['required']">{{ 'i18n:MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
                <kendo-formerror *ngIf="formGroup.controls['chartHeight'].errors?.['minError']">{{ translate.get('i18n:MESSAGE.VALIDATION_MINIMUM', formGroup.controls.chartHeight.errors.minError) }}</kendo-formerror>
                <kendo-formerror *ngIf="formGroup.controls['chartHeight'].errors?.['maxError']">{{ translate.get('i18n:MESSAGE.VALIDATION_MAXIMUM', formGroup.controls.chartHeight.errors.maxError) }}</kendo-formerror>
            </kendo-formfield>
        </fieldset>
    </form>

    <kendo-tabstrip>
        <kendo-tabstrip-tab [selected]="true" title="{{ 'i18n:CHART.TAGS' | translate }}">
            <ng-template kendoTabContent>
                <kendo-grid [data]="items" [height]="410" [selectable]="{ mode: 'multiple', drag: true }" kendoGridSelectBy="GenericAssetTagDataId" [(selectedKeys)]="selectedItemKeys" 
                    (cellClick)="cellClick($event)" (cellClose)="cellClose($event)">
                    <ng-template kendoGridToolbarTemplate>
                        <button kendoButton look="outline" (click)="openTagSelectionPopup()">{{ 'i18n:DATASOURCE.TAGS.ADD' | translate }}</button>
                        <button kendoButton look="outline" (click)="openAssetTypeTagSelectionPopup()">{{ 'i18n:DATASOURCE.ASSET_TYPE_TAGS.ADD' | translate }}</button>
                        <button kendoButton look="outline" [disabled]="selectedItemKeys.length == 0" (click)="removeItems()">{{ 'i18n:DATASOURCE.TAGS.REMOVE' | translate }}</button>
                    </ng-template>

                    <kendo-grid-column [title]="translate.get('i18n:CHART.ITEM.TAG')" [field]="'Id'">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
                            {{ getTagDescription(dataItem) }}
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [title]="translate.get('i18n:CHART.ITEM.MEASUREMENT_UNIT')" [field]="'MeasurementUnitId'" [style]="{ 'padding': '0px' }">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
                            <div style="display: flex; width: 100%; height: 100%; align-items: center;">
                                <div style="flex: 1; padding: 8px 4px 8px 12px;">{{ getMeasurementUnitDescription(dataItem) }}</div>
                                <div style="flex: 0;">
                                    <button kendoButton look="flat" icon="edit" (click)="openMeasurementSelectionPopup(dataItem)"></button>
                                </div>
                                <div style="flex: 0;" *ngIf="dataItem.MeasurementUnitId && dataItem.MeasurementUnitId.length">
                                    <button kendoButton look="flat" icon="close" (click)="clearMeasurementUnit(dataItem)"></button>
                                </div>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [title]="translate.get('i18n:CHART.ITEM.AGGREGATE')" [field]="'Aggregate'" [editor]="'boolean'">
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                            <div>{{ dataItem.Aggregate ? translate.get('i18n:LABEL.YES') : translate.get('i18n:LABEL.NO') }}</div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [title]="translate.get('i18n:CHART.ITEM.SHOW_RANGE')" [field]="'ShowRange'"
                        [editor]="'boolean'">
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                            <div>{{ dataItem.ShowRange ? translate.get('i18n:LABEL.YES') : translate.get('i18n:LABEL.NO') }}
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [title]="translate.get('i18n:TAG_WIDGET.LINE_TYPE')" [field]="'ChartType'">
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                            <div>{{ getLineTypeDescription(dataItem) }}</div>
                        </ng-template>
                        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column"
                            let-formGroup="formGroup">
                            <kendo-combobox [data]="lineTypes" [formControl]="formGroup.get('ChartType')" textField="Display"
                                valueField="Key" [valuePrimitive]="true"></kendo-combobox>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [title]="translate.get('i18n:CHART.ITEM.SCALE')" [field]="'Scale'">
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                            <div>{{ dataItem.Scale }}</div>
                        </ng-template>
                        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
                            <kendo-combobox [data]="scales" [formControl]="formGroup.get('Scale')" [textField]="'text'" [valueField]="'text'" [valuePrimitive]="true"></kendo-combobox>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [title]="translate.get('i18n:CHART.ITEM.FILL_OPTION')" [field]="'FillOption'">
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                            <div>{{ getFillOptionDescription(dataItem) }}</div>
                        </ng-template>
                        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
                            <kendo-combobox [data]="fillOptions" [formControl]="formGroup.get('FillOption')" [textField]="'Display'" [valueField]="'Name'" [valuePrimitive]="true"></kendo-combobox>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [title]="translate.get('i18n:CHART.ITEM.PANE')" [field]="'Pane'" editor="numeric"></kendo-grid-column>
                    <kendo-grid-column [title]="translate.get('i18n:CHART.ITEM.COLOR')" [field]="'Color'">
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                            <div class="selected-color" [ngStyle]="{ 'background-color': dataItem.Color }"></div>
                        </ng-template>
                        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
                            <kendo-colorpicker activeView="palette" [format]="'hex'" [formControl]="formGroup.get('Color')"></kendo-colorpicker>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="{{ 'i18n:CHART.SCALES' | translate }}">
            <ng-template kendoTabContent>
                <kendo-grid [data]="scales" [loading]="scalesLoading" [height]="410" [selectable]="{ mode: 'multiple', drag: true }" kendoGridSelectBy="id" [(selectedKeys)]="selectedScaleKeys" 
                    (cellClick)="scaleCellClick($event)" (cellClose)="scaleCellClose($event)">
                    <ng-template kendoGridToolbarTemplate>
                        <button kendoButton look="outline" (click)="addScale()">{{ 'i18n:CHART.SCALE.ADD' | translate }}</button>
                        <button kendoButton look="outline" [disabled]="selectedScaleKeys.length == 0" (click)="removeScales()">{{ 'i18n:CHART.SCALES.REMOVE' | translate }}</button>
                    </ng-template>

                    <kendo-grid-column [title]="translate.get('i18n:CHART.SCALE.TEXT')" [field]="'text'"></kendo-grid-column>
                    <kendo-grid-column [title]="translate.get('i18n:CHART.ITEM.MEASUREMENT_UNIT')" [field]="'measurementUnitId'" [style]="{ 'padding': '0px' }">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
                            <div style="display: flex; width: 100%; height: 100%; align-items: center;">
                                <div style="flex: 1; padding: 8px 4px 8px 12px;">{{ getScaleMeasurementUnitDescription(dataItem) }}</div>
                                <div style="flex: 0;">
                                    <button kendoButton look="flat" icon="edit" (click)="openScaleMeasurementSelectionPopup(dataItem)"></button>
                                </div>
                                <div style="flex: 0;" *ngIf="dataItem.measurementUnitId && dataItem.measurementUnitId.length">
                                    <button kendoButton look="flat" icon="close" (click)="clearScaleMeasurementUnit(dataItem)"></button>
                                </div>
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [title]="translate.get('i18n:CHART.SCALE.MIN')" [field]="'min'" editor="numeric"></kendo-grid-column>
                    <kendo-grid-column [title]="translate.get('i18n:CHART.SCALE.MAX')" [field]="'max'" editor="numberic"></kendo-grid-column>
                    <kendo-grid-column [title]="translate.get('i18n:CHART.SCALE.SHOW_ZERO')" [field]="'showZero'" editor="boolean">
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                            <div>{{ dataItem.showZero ? translate.get('i18n:LABEL.YES') : translate.get('i18n:LABEL.NO') }}
                            </div>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="{{ 'i18n:CHART.FIXED_VALUES' | translate }}">
            <ng-template kendoTabContent>   
                <kendo-grid [data]="fixedValues" [height]="410" [selectable]="{ mode: 'multiple', drag: true }" kendoGridSelectBy="id" [(selectedKeys)]="selectedFixedValuesKeys" 
                    (cellClick)="fixedValueCellClick($event)" (cellClose)="fixedValueCellClose($event)">
                    <ng-template kendoGridToolbarTemplate>
                        <button kendoButton look="outline" (click)="addFixedValue()">{{ 'i18n:CHART.FIXED_VALUE.ADD' | translate }}</button>
                        <button kendoButton look="outline" [disabled]="selectedFixedValuesKeys.length == 0" (click)="removeFixedValues()">{{ 'i18n:CHART.FIXED_VALUES.REMOVE' | translate }}</button>
                    </ng-template>

                    <kendo-grid-column [title]="translate.get('i18n:CHART.FIXED_VALUE.NAME')" [field]="'name'"></kendo-grid-column>
                    <kendo-grid-column [title]="translate.get('i18n:CHART.FIXED_VALUE.VALUE')" [field]="'value'" editor="numeric"></kendo-grid-column>
                    <kendo-grid-column [title]="translate.get('i18n:CHART.FIXED_VALUE.SCALE')" [field]="'scale'">
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                            <div>{{ dataItem.scale }}</div>
                        </ng-template>
                        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
                            <kendo-combobox [data]="scales" [formControl]="formGroup.get('scale')" [textField]="'text'" [valueField]="'text'" [valuePrimitive]="true"></kendo-combobox>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column [title]="translate.get('i18n:CHART.FIXED_VALUE.PANE')" [field]="'pane'" editor="numeric"></kendo-grid-column>
                    <kendo-grid-column [title]="translate.get('i18n:CHART.FIXED_VALUE.COLOR')" [field]="'color'">
                        <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                            <div class="selected-color" [ngStyle]="{ 'background-color': dataItem.color }"></div>
                        </ng-template>
                        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
                            <kendo-colorpicker activeView="palette" [format]="'hex'" [formControl]="formGroup.get('color')"></kendo-colorpicker>
                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="{{ 'i18n:SECURITY.SECURITY' | translate }}">
          <ng-template kendoTabContent>
            <ats-platform-security-tab [accessRights] = "accessRights" ></ats-platform-security-tab>
          </ng-template>
        </kendo-tabstrip-tab>

    </kendo-tabstrip>
</div>