export const ENERGY_MANAGEMENT_CATEGORIES = [
  { Key: 0, Name: 'energyMix', Display: 'i18n:VIEW_STATIC_LIST.ENERGYCATEGORY.ENERGYMIX' },
  { Key: 1, Name: 'electricityMix', Display: 'i18n:VIEW_STATIC_LIST.ENERGYCATEGORY.ELECTRICITYMIX' },
  { Key: 2, Name: 'renewableEnergy', Display: 'i18n:VIEW_STATIC_LIST.ENERGYCATEGORY.RENEWEABLEENERGY' }, 
  { Key: 3, Name: 'gas', Display: 'i18n:VIEW_STATIC_LIST.ENERGYCATEGORY.GAS' },
  { Key: 4, Name: 'diesel', Display: 'i18n:VIEW_STATIC_LIST.ENERGYCATEGORY.DIESEL' },
  { Key: 5, Name: 'ev', Display: 'i18n:VIEW_STATIC_LIST.ENERGYCATEGORY.EV' },
  { Key: 6, Name: 'battery', Display: 'i18n:VIEW_STATIC_LIST.ENERGYCATEGORY.BATTERY' },
]

export const ENERGY_MANAGEMENT_ANALYSIS_CATEGORIES = [
  { Key: 0, Name: 'selfConsumption', Display: 'i18n:VIEW_STATIC_LIST.ENERGYANALYSISCATEGORY.SELFCONSUMPTION' },
  { Key: 1, Name: 'renewableEnergy', Display: 'i18n:VIEW_STATIC_LIST.ENERGYANALYSISCATEGORY.RENEWABLEENERGY' },
  { Key: 2, Name: 'energyMix', Display: 'i18n:VIEW_STATIC_LIST.ENERGYANALYSISCATEGORY.ENERGYMIX' }, 
  { Key: 3, Name: 'electricityMix', Display: 'i18n:VIEW_STATIC_LIST.ENERGYANALYSISCATEGORY.ELECTRICITYMIX' },
]

export const ENERGY_MANAGEMENT_VALUE_CATEGORIES = [
  { Key: 0, Name: 'Co2EnergyMix', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.CO2ENERGYMIX' },
  { Key: 1, Name: 'Co2ElectricityMix', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.CO2ELECTRICITYMIX' },
  { Key: 2, Name: 'Co2ElectricityMixWithoutEV', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.CO2ELECTRICITYMIXWITHOUTEV' },
  { Key: 3, Name: 'Co2Grid', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.CO2GRID' }, 
  { Key: 4, Name: 'Co2Gas', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.CO2GAS' }, 
  { Key: 5, Name: 'Co2Diesel', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.CO2DIESEL' }, 
  { Key: 6, Name: 'Co2EV', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.CO2EV' }, 
  { Key: 7, Name: 'Co2Saved', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.CO2SAVED' },
  { Key: 8, Name: 'PriceEnergyMix', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICEENERGYMIX' },
  { Key: 9, Name: 'PriceElectricityMix', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICEELECTRICITYMIX' },
  { Key: 10, Name: 'PriceElectricityMixWithoutEV', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICEELECTRICITYMIXWITHOUTEV' }, 
  { Key: 11, Name: 'PriceGridConsumption', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICEGRIDCONSUMPTION' }, 
  { Key: 12, Name: 'PriceGridInjection', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICEGRIDINJECTION' }, 
  { Key: 13, Name: 'PriceGas', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICEGAS' }, 
  { Key: 14, Name: 'PriceDiesel', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICEDIESEL' }, 
  { Key: 15, Name: 'PriceEV', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICEEV' }, 
  { Key: 16, Name: 'PriceSaved', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.PRICESAVED' },
  { Key: 17, Name: 'EnergyEnergyMix', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYENERGYMIX' },
  { Key: 18, Name: 'EnergyElectricityMix', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYELECTRICITYMIX' },
  { Key: 19, Name: 'EnergyElectricityMixWithoutEV', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYELECTRICITYMIXWITHOUTEV' },
  { Key: 20, Name: 'EnergySolar', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYSOLAR' },
  { Key: 21, Name: 'EnergyWind', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYWIND' },
  { Key: 22, Name: 'EnergyGridConsumption', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYGRIDCONSUMPTION' }, 
  { Key: 23, Name: 'EnergyGridInjection', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYGRIDINJECTION' },
  { Key: 24, Name: 'EnergyGas', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYGAS' },
  { Key: 25, Name: 'EnergyDiesel', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYDIESEL' },
  { Key: 26, Name: 'EnergyEV', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYEV' },
  { Key: 27, Name: 'EnergySelfConsumption', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYSELFCONSUMPTION' },
  { Key: 28, Name: 'RatioSelfConsumption', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.RATIOSELFCONSUMPTION' },
  { Key: 29, Name: 'RatioRenewableEnergy', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.RATIORENEWABLEENERGY' },
  { Key: 30, Name: 'EnergyBatteryEnergyIn', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYBATTERYENERGYIN' }, 
  { Key: 31, Name: 'EnergyBatteryEnergyOut', Display: 'i18n:VIEW_STATIC_LIST.ENERGYVALUECATEGORY.ENERGYBATTERYENERGYOUT' },
]