import { Component, OnInit, OnDestroy, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { HubConnection } from '@aspnet/signalr';
import { ApiQueryOptions, ApiService, CoreService, DashboardService, FileSaveService, NotFoundError, TranslateService, WidgetComponent, WidgetInstance } from '@ats/ats-platform-dashboard';
import { Subscription, map } from 'rxjs';
import { cloneDeep, isEmpty, keyBy } from "lodash-es";
import { Report } from '../../domain/entities/report';

@Component({
  selector: 'ats-smart-tool-report-widget',
  templateUrl: './report-widget.component.html',
  styleUrls: ['./report-widget.component.scss']
})
export class ReportWidgetComponent implements WidgetComponent, OnInit, OnDestroy, AfterViewInit {
  @Input() hubConnection: HubConnection;

  public widgetInstance: WidgetInstance;

  public isLoading = false;
  public error: string;

  private widgetConfigSubscription: Subscription;

  @ViewChild("svgContainer", { static: false }) svgContainer: ElementRef;

  constructor(private core: CoreService, private api: ApiService, private translate: TranslateService, private fileSaveService: FileSaveService, private dashboardService: DashboardService) { }

  public setWidgetInstance(widgetInstance: WidgetInstance) {
    this.widgetInstance = widgetInstance;
  }

  ngOnInit() {
    this.widgetConfigSubscription = this.widgetInstance.changed.subscribe({
      next: (instance: WidgetInstance) => {
        this.updateData(instance);
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => { this.updateData(this.widgetInstance); }, 0);
  }

  ngOnDestroy() {
    if (this.widgetConfigSubscription) {
      this.widgetConfigSubscription.unsubscribe();
      this.widgetConfigSubscription = null;
    }
  }

  protected updateData(instance: WidgetInstance) {
    if (!instance.widgetData)
    instance.widgetData = {};

    if (!isEmpty(instance.widgetConfig.title)) {
      instance.widgetData.title = instance.widgetConfig.title;
    } 

   if (instance.widgetData?.report?.Id != instance.widgetConfig?.reportId) {
      if (instance.widgetConfig?.reportId) {
        this.isLoading = true;
        this.api.getSingleResult('Reports', instance.widgetConfig.reportId, new ApiQueryOptions({})).subscribe(
          {
            next: (report: Report) => {
              instance.widgetData.report = report;
              // set task name as title when no title is configured
              if (isEmpty(instance.widgetConfig.title))
                instance.widgetData.title = report.Name;

              this.isLoading = false;
            }, error: (err: any) => {
              this.error = this.handleError(err);
              this.isLoading = false;
            }
          });
      } else {
        this.error = 'No report id';
      }
    }

    let svgContent: string;

    if (instance.dashboardWidget.Documents && instance.dashboardWidget.Documents.length) {
      const documentsByKey = keyBy(instance.dashboardWidget.Documents, 'Name');
      svgContent = documentsByKey['svgContent'] ? documentsByKey['svgContent'].Content : '';
    }

    if (!svgContent || !svgContent.length) {
      svgContent = '<svg width="100%" height="100%" viewBox="0 0 384 512" preserveAspectRatio="xMidYMid meet"><path style="fill: black; stroke-width: 0" d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zM332.1 128H256V51.9l76.1 76.1zM48 464V48h160v104c0 13.3 10.7 24 24 24h104v288H48z"></path></svg>';
    }

    const div: HTMLDivElement = this.svgContainer.nativeElement;
    div.innerHTML = svgContent;

    const svgs = div.getElementsByTagName('svg');
    const svg = svgs && svgs.length ? svgs.item(0) : null;

    if (svg) {
      svg.style.cursor = 'pointer';
      svg.onclick = (e: MouseEvent) => {
        this.downloadReport();
      };
    }
  }

  public downloadReport(): void {
    if (!this.error && this.widgetInstance.widgetConfig.reportId) {

      var periodConfig = null;
      if (this.widgetInstance.widgetConfig.useDashboardDateTime) {
        periodConfig = {
          from: cloneDeep(this.dashboardService.config.from),
          to: cloneDeep(this.dashboardService.config.to),
          period: cloneDeep(this.dashboardService.config.period)
        };
      }

        this.fileSaveService.showFileSavePopup(this.widgetInstance.widgetData.title, [{ Key: 'pdf', Display: 'pdf' }], periodConfig).subscribe({ next:(popupResult) => {
        if(popupResult){
          var command: { ReportId: string, From?: Date, To?: Date, UseDashboardDateTime: boolean } = { ReportId: this.widgetInstance.widgetConfig.reportId, UseDashboardDateTime: this.widgetInstance.widgetConfig.useDashboardDateTime };

          if (this.widgetInstance.widgetConfig.useDashboardDateTime) {
            command.From = popupResult.periodFrom;
            command.To = popupResult.periodTo;
          }
          
          this.isLoading = true;
          this.api.executeUnboundAction('ReportDefinitions', 'Download', command, null, { responseType: "arraybuffer" }).subscribe({ next:(result: ArrayBuffer) => {
            const data: Uint8Array = new Uint8Array(result);
            this.core.generateDownload(data, popupResult.fileName, popupResult.extension, "application/pdf");
            this.isLoading = false;
          }, error: (error: any) => {
            this.isLoading = false;
            this.handleError(error);
          }});
        }
      }});
    }
  }

  private handleError(error: any): string {
    if (error instanceof NotFoundError) {
      return this.translate.get('i18n:MESSAGE.ITEM_NO_LONGER_EXISTS', { type: this.translate.get('i18n:REPORT') });
    } else {
      return this.core.getErrorDetail(error);
    }
  }
}
