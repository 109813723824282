import { Selection, format, arc, line, DefaultArcObject, range } from 'd3';
import { isNumber, orderBy } from "lodash-es";

export class CircularGauge {

    private formatValue: (n: number | { valueOf(): number }) => string;

    private needleGroup: Selection<SVGGElement, unknown, HTMLElement, unknown>;
    private setpointGroup: Selection<SVGGElement, unknown, HTMLElement, unknown>;
    private valueText: Selection<SVGTextElement, unknown, HTMLElement, unknown>;

    constructor(public settings: CircularGaugeSettings) {
        this.formatValue = format('.' + settings.numberOfDecimals + 'f');
    }

    public draw(group: Selection<SVGGElement, unknown, HTMLElement, unknown>) {
        group.selectAll('g').remove();

        var gaugeXCenter = this.settings.width / 2;
        var gaugeYCenter = this.settings.height / 2;
        // if (this.settings.startAngle >= -90 || this.settings.endAngle <= 90) {
        //     var newYValue = group.node().parentElement.clientHeight - (group.node().parentElement.clientHeight / 5);
        //     if (newYValue < this.settings.height) {
        //         gaugeYCenter = newYValue;
        //     }
        // }

        const g = group.append('g')
            .attr('transform', 'translate(' + gaugeXCenter + ',' + gaugeYCenter + ')');

        // ranges
        const d3Arc = arc();
        const arcs = this.createArcs();
        g.selectAll('path')
            .data(arcs)
            .join('path')
            .attr('fill', d => d.data.color)
            .attr('d', d3Arc);

        // minor ticks
        if (this.settings.numberOfMinorTicks && this.settings.numberOfMinorTicks > 0) {
            const minorTicks = this.createTicks(this.settings.numberOfMinorTicks);
            const minorTickGroup = g.selectAll('g.minor-tick')
                .data(minorTicks)
                .join('g')
                .attr('class', 'minor-tick')
                .attr('transform', d => 'rotate(' + d.angle + ') translate(' + (this.settings.outerRadius + 4) + ',0)')
                .attr('font-size', 10)
                .attr('font-family', 'sans-serif');

            minorTickGroup.append('line')
                .attr('stroke', this.settings.minorTicksColor || 'gray')
                .attr('x2', 5);
        }

        // major ticks
        if (this.settings.numberOfMajorTicks && this.settings.numberOfMajorTicks > 0) {
            const majorTicks = this.createTicks(this.settings.numberOfMajorTicks);
            const majorTickGroup = g.selectAll('g.major-tick')
                .data(majorTicks)
                .join('g')
                .attr('class', 'major-tick')
                .attr('transform', d => 'rotate(' + d.angle + ') translate(' + (this.settings.outerRadius + 4) + ',0)')
                .attr('font-size', 10)
                .attr('font-family', 'sans-serif');

            majorTickGroup.append('line')
                .attr('stroke', this.settings.majorTicksColor || 'black')
                .attr('stroke-width', 2)
                .attr('x2', 8);

            majorTickGroup.append('text')
                .attr('x', 11)
                .attr('dy', '0.35em')
                .attr('fill', this.settings.majorTicksColor)
                .attr('transform', d => d.angle <= -90 || d.angle > 90 ? 'rotate(180) translate(-22)' : null)
                .attr('text-anchor', d => (d.angle <= -90 || d.angle > 90 ? 'end' : 'start'))
                .text(d => this.formatValue(d.value) + (this.settings.unit ? ' ' + this.settings.unit : ''));
        }

        // needle
        const needleRadius = this.settings.width / 50;
        const needleLength = this.settings.innerRadius - 5;
        const needleLineData: [number, number][] = [[needleRadius, 0], [0, -needleLength], [-needleRadius, 0], [needleRadius, 0]];

        this.needleGroup = g.append('g')
            .attr('opacity', 0)
            .attr('transform', 'rotate(' + this.settings.startAngle + ')');
        this.needleGroup.append('circle')
            .attr('r', needleRadius)
            .attr('fill', this.settings.needleColor);
        this.needleGroup.append('g')
            .data([needleLineData])
            .attr('fill', this.settings.needleColor)
            .append('path')
            .attr('d', line());

        // setpoint
        this.setpointGroup = g.append('g')
            .attr('opacity', 0)
            .attr('transform', 'rotate(' + this.settings.startAngle + ')');
        this.setpointGroup
            .append('line')
            .attr('stroke', this.settings.setpointColor)
            .attr('stroke-width', 4)
            .attr('x1', this.settings.innerRadius - 4)
            .attr('x2', this.settings.outerRadius + 4);

        // value text
        this.valueText = g.append('text')
            .attr('x', 0)
            .attr('dy', '0.35em')
            .attr('fill', this.settings.valueColor)
            .attr('text-anchor', 'middle')
            .attr('font-size', 20)
            .attr('font-weight', 'bold')
            .attr('font-family', 'sans-serif')
            .text('---' + (this.settings.unit ? ' ' + this.settings.unit : ''));
    }

    public setValue(value: number) {
        let showBelow = true;

        if (this.needleGroup) {
            if (isNumber(value) && value >= this.settings.minValue && value <= this.settings.maxValue) {
                const valueAngle = this.calcAngleDegrees(value);
                this.needleGroup.attr('opacity', 1);
                this.needleGroup.transition().duration(900).attr('transform', 'rotate(' + valueAngle + ')');

                showBelow = valueAngle >= -90 && valueAngle <= 90;
            }
            else {
                this.needleGroup.attr('opacity', 0);
                this.needleGroup.attr('transform', 'rotate(' + this.settings.startAngle + ')');
            }
        }

        if (this.valueText) {
            if (isNumber(value)) {
                this.valueText.text(this.formatValue(value) + (this.settings.unit ? ' ' + this.settings.unit : ''));
            } else {
                this.valueText.text('---' + (this.settings.unit ? ' ' + this.settings.unit : ''));
            }

            this.valueText.attr('transform', 'translate(0,' + (this.settings.height / 10 * (showBelow ? 1 : -1)) + ')');
        }
    }

    public setSetpoint(value: number) {
        if (this.setpointGroup) {
            if (isNumber(value) && value >= this.settings.minValue && value <= this.settings.maxValue) {
                const valueAngle = this.calcAngleDegrees(value);
                this.setpointGroup.attr('opacity', 1);
                this.setpointGroup.transition().duration(900).attr('transform', 'rotate(' + (valueAngle - 90) + ')');
            }
            else {
                this.setpointGroup.attr('opacity', 0);
                this.setpointGroup.attr('transform', 'rotate(' + this.settings.startAngle + ')');
            }
        }
    }

    private calcAngleDegrees(value: number) {
        return this.settings.startAngle + ((this.settings.endAngle - this.settings.startAngle) * (value - this.settings.minValue)) / (this.settings.maxValue - this.settings.minValue);
    }

    private calcAngleRad(value: number) {
        return this.calcAngleDegrees(value) * Math.PI / 180.0;
    }

    private createArcs(): ArcObject[] {
        const arcs: ArcObject[] = [];

        let arcIndex = 0;
        let lastEndValue = this.settings.minValue;
        let startValue: number;
        let endValue: number;

        if (this.settings.ranges && this.settings.ranges.length) {
            this.settings.ranges = orderBy(this.settings.ranges, r => r.startValue);

            for (let i = 0; i < this.settings.ranges.length; i++) {
                startValue = this.settings.ranges[i].startValue;
                endValue = this.settings.ranges[i].endValue;

                if (endValue <= this.settings.minValue || startValue >= this.settings.maxValue) {
                    // completly outside the range
                    continue;
                }

                startValue = Math.max(this.settings.minValue, startValue, lastEndValue);
                endValue = Math.min(this.settings.maxValue, endValue);

                if (startValue > lastEndValue) {
                    // add arc with default color
                    arcs.push(this.createArc(this.settings.defaultRangeColor, arcIndex++, lastEndValue, startValue));
                    lastEndValue = startValue;
                }

                if (startValue >= endValue) {
                    continue;
                }

                arcs.push(this.createArc(this.settings.ranges[i].color, arcIndex++, startValue, endValue));
                lastEndValue = endValue;
            }
        }

        if (lastEndValue < this.settings.maxValue) {
            arcs.push(this.createArc(this.settings.defaultRangeColor, arcIndex++, lastEndValue, this.settings.maxValue));
        }

        return arcs;
    }

    private createArc(color: string, index: number, startValue: number, endValue: number): ArcObject {
        return {
            data: { startValue: startValue, endValue: endValue, color: color },
            index: index,
            value: endValue - startValue,
            startAngle: this.calcAngleRad(startValue),
            endAngle: this.calcAngleRad(endValue),
            padAngle: 0.005,
            innerRadius: this.settings.innerRadius,
            outerRadius: this.settings.outerRadius
        };
    }

    private createTicks(numberOfTicks: number): TickObject[] {
        const ticks = range(numberOfTicks - 1).map(t => {
            const value = this.settings.minValue + ((this.settings.maxValue - this.settings.minValue) * t) / (numberOfTicks - 1);
            return { value: value, angle: this.calcAngleDegrees(value) - 90 };
        });

        ticks.push({ value: this.settings.maxValue, angle: this.calcAngleDegrees(this.settings.maxValue) - 90 });

        return ticks;
    }
}

export class CircularGaugeSettings {
    width: number;
    height: number;
    innerRadius: number;
    outerRadius: number;
    startAngle: number;
    endAngle: number;
    minValue: number;
    maxValue: number;
    defaultRangeColor: string;
    needleColor: string;
    minorTicksColor: string;
    majorTicksColor: string;
    setpointColor: string;
    valueColor: string;
    ranges: { startValue: number, endValue: number, color: string }[];
    numberOfMajorTicks?: number;
    numberOfMinorTicks?: number;
    numberOfDecimals: number;
    unit?: string;
}

class ArcObject implements DefaultArcObject {
    innerRadius: number;
    outerRadius: number;

    startAngle: number;
    endAngle: number;
    padAngle: number;

    data: { startValue: number, endValue: number, color: string };
    index: number;
    value: number;
}

class TickObject {
    angle: number;
    value: number;
}