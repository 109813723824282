import { DataSource, DataSourceType, GenericDataSourceLoader } from "@ats/ats-platform-dashboard";
import { DataSourceAssetTagsComponent } from "../components/datasource-asset-tags/datasource-asset-tags.component";
import { DataSourceBuildingEnergyComponent } from "../components/datasource-buildingenergy/datasource-buildingenergy.component";
import { DataSourceProductionConsumptionComponent } from "../components/datasource-production-consumption/datasource-production-consumption.component";
import { DataSourceEnergyManagementComponent } from "../components/datasource-energy-management/datasource-energy-management.component";
import { DataSourceSolarPerformanceComponent } from "../components/datasource-solar-performance/datasource-solar-performance.component";
import { DataSourceTagsHistoryComponent } from "../components/datasource-tags-history/datasource-tags-history.component";
import { DataSourceTagsComponent } from "../components/datasource-tags/datasource-tags.component";
import { DataSourceTimeInComfortComponent } from "../components/datasource-time-in-comfort/datasource-time-in-comfort.component";
import { DataSourceWeatherForecastComponent } from "../components/datasource-weatherforecast/datasource-weatherforecast.component";
import { AssetsDataSourceLoader } from "../services/assetsDataSourceLoader";
import { AssetTagsDataSourceLoader } from "../services/assetTagsDataSourceLoader";
import { BuildingEnergyDataSourceExporter } from "../services/buildingEnergyDataSourceExporter";
import { ProductionConsumptionDataSourceExporter } from "../services/productionConsumptionDataSourceExporter";
import { SolarPerformanceDataSourceExporter } from "../services/solarPerformanceDataSourceExporter";
import { TagHistoryDataSourceLoader } from "../services/tagDataSourceLoader";
import { TagsDataSourceLoader } from "../services/tagsDataSourceLoader";
import { TagsHistoryDataSourceExporter } from "../services/tagsHistoryDataSourceExporter";
import { TimeInComfortDataSourceExporter } from "../services/timeInComfortDataSourceExporter";
import { DataSourceEVChargingConstraintComponent } from "../components/datasource-ev-charging-constraint/datasource-ev-charging-constraint.component";
import { EVChargingConstraintDataSourceLoader } from "../services/evChargingConstraintDataSourceLoader";
import { DataSourceEvChargersComponent } from "../components/datasource-ev-chargers/datasource-ev-chargers.component";
import { EVChargersDataSourceLoader } from "../services/evChargersDataSourceLoader";
import { DataSourceDeviceTopologyComponent } from "../components/datasource-device-topology/datasource-device-topology.component";
import { DeviceTopologyDataSourceLoader } from "../services/deviceTopologyDataSourceLoader";
import { EnergyManagementDataSourceExporter } from "../services/energyManagementDataSourceExporter";
import { DataSourceEvChargingSessionsComponent } from "../components/datasource-ev-charging-sessions/datasource-ev-charging-sessions.component";
import { DeviceTopologyDataSourceExporter } from "../services/deviceTopologyDataSourceExporter";
import { EVChargingConstraintDataSourceExporter } from "../services/evChargingConstraintDataSourceExporter";
import { EVChargersDataSourceExporter } from "../services/evChargersDataSourceExporter";
import { EvChargingSessionsDataSourceExporter } from "../services/evChargingSessionsDataSourceExporter";

export const tagsDataSourceType: DataSourceType = {
  name: 'tags',
  description: 'i18n:DATASOURCE.TAGS',
  component: DataSourceTagsComponent,
  loader: TagsDataSourceLoader
};

export const assetTagsDataSourceType: DataSourceType = {
  name: 'assetTags',
  description: 'i18n:DATASOURCE.ASSET',
  component: DataSourceAssetTagsComponent,
  loader: AssetTagsDataSourceLoader
};

export const tagsHistoryDataSourceType: DataSourceType = {
  name: 'tagsHistory',
  description: 'i18n:DATASOURCE.TAGS_HISTORY',
  component: DataSourceTagsHistoryComponent,
  loader: TagHistoryDataSourceLoader,
  exporter: TagsHistoryDataSourceExporter
};

export const weatherForecastDataSourceType: DataSourceType = {
  name: 'weatherForecast',
  description: 'i18n:DATASOURCE.WEATHERFORECAST',
  component: DataSourceWeatherForecastComponent,
  loader: GenericDataSourceLoader
};

export const buildingEnergyDataSourceType: DataSourceType = {
  name: 'buildingEnergy',
  description: 'i18n:DATASOURCE.BUILDINGENERGY',
  component: DataSourceBuildingEnergyComponent,
  loader: AssetsDataSourceLoader,
  exporter: BuildingEnergyDataSourceExporter
};

export const productionConsumptionDataSourceType: DataSourceType = {
  name: 'productionConsumption',
  description: 'i18n:DATASOURCE.PRODUCTION_CONSUMPTION',
  component: DataSourceProductionConsumptionComponent,
  loader: GenericDataSourceLoader,
  exporter: ProductionConsumptionDataSourceExporter
}

export const energyManagementDataSourceType: DataSourceType = {
  name: 'energyManagement',
  description: 'i18n:DATASOURCE.ENERGY_MANAGEMENT',
  component: DataSourceEnergyManagementComponent,
  loader: GenericDataSourceLoader,
  exporter: EnergyManagementDataSourceExporter
}

export const deviceTopologyDataSourceType: DataSourceType = {
  name: 'deviceTopology',
  description: 'i18n:DATASOURCE.DEVICE_TOPOLOGY',
  component: DataSourceDeviceTopologyComponent,
  loader: DeviceTopologyDataSourceLoader,
  exporter: DeviceTopologyDataSourceExporter,
}

export const solarPerformanceDataSourceType: DataSourceType = {
  name: 'solarPerformance',
  description: 'i18n:DATASOURCE.SOLAR_PERFORMANCE',
  component: DataSourceSolarPerformanceComponent,
  loader: GenericDataSourceLoader,
  exporter: SolarPerformanceDataSourceExporter,
};

export const timeInComfortDataSourceType: DataSourceType = {
  name: 'timeInComfort',
  description: 'i18n:DATASOURCE.TIME_IN_COMFORT',
  component: DataSourceTimeInComfortComponent,
  loader: GenericDataSourceLoader,
  exporter: TimeInComfortDataSourceExporter,
}

export const evChargingConstraintDataSourceType: DataSourceType = {
  name: 'evChargingConstraint',
  description: 'i18n:DATASOURCE.EV_CHARGING_CONSTRAINT',
  component: DataSourceEVChargingConstraintComponent,
  loader: EVChargingConstraintDataSourceLoader,
  exporter: EVChargingConstraintDataSourceExporter,
}

export const evChargersDataSourceType: DataSourceType = {
  name: 'evChargers',
  description: 'i18n:DATASOURCE.EV_CHARGERS',
  component: DataSourceEvChargersComponent,
  loader: EVChargersDataSourceLoader,
  exporter: EVChargersDataSourceExporter,
};

export const evChargingSessionsDataSourceType: DataSourceType = {
  name: 'evChargingSessions',
  description: 'i18n:DATASOURCE.EV_CHARGING_SESSIONS',
  component: DataSourceEvChargingSessionsComponent,
  loader: GenericDataSourceLoader,
  exporter: EvChargingSessionsDataSourceExporter,
};