import { Injectable } from "@angular/core";
import { forkJoin, Observable, map } from "rxjs";
import { find } from "lodash-es";
import { ApiQueryOptions, DataSource, DataSourceConfig, GenericDataSourceLoader } from "@ats/ats-platform-dashboard";
import { BaseTag } from "../domain/entities/baseTag";

@Injectable()
export class TagHistoryDataSourceLoader extends  GenericDataSourceLoader {
    public override loadItems(dataSource: DataSource): Observable<{ id: string; name: string; }[]> {
        const tagsConfig: DataSourceConfig = find(dataSource.Configs, (config: DataSourceConfig) => config.Name == 'tags');
        const tags: { tagId: string }[] = tagsConfig && tagsConfig.Value && tagsConfig.Value.length ? JSON.parse(tagsConfig.Value) : [];

        const observables: Observable<BaseTag>[] = tags.map((tag: {tagId: string }) => {
            return this.api.getSingleResult('BaseTags', tag.tagId, new ApiQueryOptions({ include: 'Asset' }))
        });

        return forkJoin(observables).pipe(map((tags: BaseTag[]) => {
            return tags.map((tag: BaseTag) => {
                return {
                    id: tag.Id,
                    name: tag.Asset.Path + ' - ' + tag.Name
                };
            });
        }));
    }
}