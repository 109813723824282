import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { HubConnection } from "@aspnet/signalr";
import { DashboardService, DataSourceInstance, ItemValueChangedEventArgs, WidgetComponent, WidgetInstance, WidgetUpdater} from "@ats/ats-platform-dashboard";
import { Subscription } from "rxjs";

import { find } from "lodash-es";
import { select } from 'd3';
import { WeatherForecastDaily } from "../../svg/weather-forecast-daily";

@Component({
    selector: 'ats-smart-tool-weather-forecast-daily-widget',
    templateUrl: './weather-forecast-daily-widget.component.html',
    styleUrls: ['./weather-forecast-daily-widget.component.scss']
})
export class WeatherForecastDailyWidgetComponent implements WidgetComponent, OnInit, AfterViewInit, OnDestroy {
    @Input() hubConnection: HubConnection;

    public widgetInstance: WidgetInstance;
    public isLoading = false;
    public error: string;

    private widgetConfigSubscription: Subscription;
    private widgetUpdaters: WidgetUpdater[];
    private svg: SVGSVGElement;

    private drawer: WeatherForecastDaily;

    @ViewChild('svgContainer', { static: false }) svgContainer: ElementRef;

    constructor(private dashboardService: DashboardService) {

    }

    public setWidgetInstance(widgetInstance: WidgetInstance) {
        this.widgetInstance = widgetInstance;
    }

    ngOnInit() {
        this.widgetConfigSubscription = this.widgetInstance.changed.subscribe({
            next: (instance: WidgetInstance) => {
                this.updateData(instance);
            }
        });
    }

    ngAfterViewInit() {
        setTimeout(() => { this.updateData(this.widgetInstance); }, 0);
    }

    ngOnDestroy() {
        if (this.widgetConfigSubscription) {
            this.widgetConfigSubscription.unsubscribe();
            this.widgetConfigSubscription = null;
        }

        if (this.widgetUpdaters) {
            this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
            this.widgetUpdaters = null;
        }
    }

    protected updateData(instance: WidgetInstance) {
        instance.widgetData = {
            title: instance.widgetConfig.title
        };

        if (!this.svgContainer)
            return;

        const div: HTMLDivElement = this.svgContainer.nativeElement;
        const svgs = div.getElementsByTagName('svg');
        this.svg = svgs && svgs.length ? svgs.item(0) : null;
        if (!this.svg)
            return;

        const d3svg = select(this.svg);
        d3svg.selectAll('*').remove();
        d3svg.attr('viewBox', '0 0 ' + 700 + ' ' + 150);



        this.drawer = new WeatherForecastDaily();
        this.drawer.group = d3svg.append('g');

        if (this.widgetUpdaters) {
            this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
            this.widgetUpdaters = null;
        }

        const dataSourceInstance = find(this.dashboardService.dataSourceInstances, (dataSourceInstance: DataSourceInstance) => dataSourceInstance.id == instance.widgetConfig.dataSourceId);

        if (dataSourceInstance) {
            this.widgetUpdaters = [];
            this.widgetUpdaters.push(new WidgetUpdater(dataSourceInstance, div, (dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement) => this.update(dataSourceInstance, nativeElement), null));
        }
    }
    protected update(dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement) {
        this.isLoading = dataSourceInstance?.result?.isLoading;

        if (dataSourceInstance.result) {
            const d3svg = select(this.svg);
            d3svg.attr('viewBox', '0 0 ' + (nativeElement.getBoundingClientRect().width) + ' ' + (nativeElement.getBoundingClientRect().height));

            this.drawer.left = 30;
            this.drawer.right = 30;
            this.drawer.top = 10;
            this.drawer.bottom = 10;
            this.drawer.width = nativeElement.getBoundingClientRect().width;
            this.drawer.height = nativeElement.getBoundingClientRect().height;


            this.drawer.draw(dataSourceInstance.result.data);
        }
    }
}