import { Component, Inject, Injector } from '@angular/core';
import * as _ from 'lodash';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CoreService, DashboardWidgetDocument, DataSource, DataSourceType, DATASOURCETYPES, EntitySelectionPopupSettings, TranslateService, WidgetConfigComponent } from '@ats/ats-platform-dashboard';
import { TagChart } from '../../domain/entities/tagChart';

@Component({
  selector: 'ats-smart-tool-energy-distribution-widget-config',
  templateUrl: './energy-distribution-widget-config.component.html',
  styleUrls: ['./energy-distribution-widget-config.component.scss']
})
export class EnergyDistributionWidgetConfigComponent implements WidgetConfigComponent {

  public dataSourceSelectionPopupSettings: EntitySelectionPopupSettings;
  public dataSourceFormatter = (dataSource: DataSource) => dataSource ? dataSource.Folder.Path + ' / ' + dataSource.Name : null;
  public chartSelectionPopupSettings: EntitySelectionPopupSettings;
  public chartFormatter = (tagChart: TagChart) => tagChart ? tagChart.Folder.Path + ' / ' + tagChart.Name : null;

  public fg: FormGroup;
  public formGroup: FormGroup;
  public dataSourceItemsLoading: boolean = false;

  private dataSourceTypes: { [type: string]: DataSourceType };
  private currentDataSource: DataSource = null;

  constructor(private core: CoreService, public translate: TranslateService, @Inject(DATASOURCETYPES) dataSourceTypes: DataSourceType[][], private injector: Injector) {

    this.dataSourceTypes = _.keyBy(_.flatten(dataSourceTypes), (type: DataSourceType) => type.name);

    this.formGroup = new FormGroup({
      title: new FormControl(''),
      widgetType: new FormControl(translate.get('i18n:ENERGY_DISTRIBUTION_WIDGET')),
      dataSourceId: new FormControl(null, Validators.required),
      gridChartId: new FormControl(null),
      solarChartId: new FormControl(null),
      windChartId: new FormControl(null),
      batteryChartId: new FormControl(null),
      evChargerChartId: new FormControl(null),
      buildingChartId: new FormControl(null),
    });

    this.dataSourceSelectionPopupSettings = {
      title: translate.get('i18n:SELECT_DATASOURCE'),
      entitySet: 'DataSources',
      filter: {
        logic: 'or',
        filters: [
          { field: 'Type', operator: 'eq', value: 'energyManagement' },
        ]
      },
      includes: 'Configs,Folder',
      sort: [{ field: 'Folder.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
      multiSelect: false,
      selectionRequired: true,
      columns: [{ field: 'Folder.Path', title: this.translate.get('i18n:FOLDER.PATH'), filterable: true },{ field: 'Name', title: translate.get('i18n:DATASOURCE.NAME'), filterable: true }],
      info: translate.get('i18n:WIDGET.SUPPORTED_DATATYPES') +': '+ translate.get('i18n:DATASOURCE.ENERGY_MANAGEMENT')
    };

    this.chartSelectionPopupSettings = {
      title: translate.get('i18n:SELECT_GRID_CHART'),
      entitySet: 'TagCharts',
      includes: 'Folder',
      sort: [{ field: 'Folder.Path', dir: 'asc' },{ field: 'Name', dir: 'asc' }],
      multiSelect: false,
      selectionRequired: false,
      columns: [{ field: 'Folder.Path', title: this.translate.get('i18n:FOLDER.PATH'), filterable: true },{ field: 'Name', title: translate.get('i18n:TAGCHART.NAME'), filterable: true }
      ],
      info: 'Select a chart for detailed information about the sensors'
    };
  }

  public isValid(): boolean {
    this.formGroup.markAllAsTouched();
    return this.formGroup.valid;
  }

  public getConfig(): any {
    return {
      title: this.formGroup.get('title').value,
      dataSourceId: this.formGroup.get('dataSourceId').value,
      gridChartId: this.formGroup.get('gridChartId').value,
      solarChartId: this.formGroup.get('solarChartId').value,
      windChartId: this.formGroup.get('windChartId').value,
      batteryChartId: this.formGroup.get('batteryChartId').value,
      evChargerChartId: this.formGroup.get('evChargerChartId').value,
      buildingChartId: this.formGroup.get('buildingChartId').value,
    };
  }

  public getDocuments(): DashboardWidgetDocument[] {
    return null;
  }

  public setData(config: any, documents: DashboardWidgetDocument[]) {
    this.formGroup.get('title').setValue(config?.title);
    this.formGroup.get('dataSourceId').setValue(config?.dataSourceId);
    this.formGroup.get('gridChartId').setValue(config?.gridChartId);
    this.formGroup.get('solarChartId').setValue(config?.solarChartId);
    this.formGroup.get('windChartId').setValue(config?.windChartId);
    this.formGroup.get('batteryChartId').setValue(config?.batteryChartId);
    this.formGroup.get('evChargerChartId').setValue(config?.evChargerChartId);
    this.formGroup.get('buildingChartId').setValue(config?.buildingChartId);
  }
}
