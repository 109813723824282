import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ApiService, DataSource, DataSourceConfig, DataSourceComponent, TranslateService, EntitySelectionPopupSettings, CoreService } from "@ats/ats-platform-dashboard";
import { CellClickEvent, CellCloseEvent } from '@progress/kendo-angular-grid';
import * as _ from 'lodash';

import { find, findIndex, clone, remove } from "lodash-es";
import { v4 as uuidv4 } from 'uuid';
import { Asset } from "../../domain/entities/asset";
import { BaseTag } from "../../domain/entities/baseTag";
import { MeasurementUnit } from "../../domain/entities/measurementUnit";
import { ParameterDefinition } from "../../domain/entities/parameterDefinition";

@Component({
    selector: 'ats-smarttool-datasource-solar-performance',
    templateUrl: './datasource-solar-performance.component.html',
    styleUrls: ['./datasource-solar-performance.component.scss']
})
export class DataSourceSolarPerformanceComponent implements DataSourceComponent {

    dataSource: DataSource;

    public formGroup: FormGroup;
    public items: DataSourceSolarPerformanceItemTag[];
    public selectedItemKeys: string[] = [];

    //public cellFormGroup: FormGroup;

    public measurementUnitSelectionPopupSettings: EntitySelectionPopupSettings;
    public measurementUnitFormatter = (task: MeasurementUnit) => task?.Name;
    public parameterDefinitionSelectionPopupSettings: EntitySelectionPopupSettings;
    public parameterDefinitionFormatter = (task: ParameterDefinition) => task?.Code;

    public gridErr: string=""

    constructor(public translate: TranslateService, private api: ApiService, private core: CoreService) {
        this.measurementUnitSelectionPopupSettings = {
            title: translate.get('i18n:SELECT_MEASUREMENTUNIT'),
            entitySet: 'MeasurementUnits',
            includes: 'Measurement',
            sort: [{ field: 'Measurement.Name', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
            multiSelect: false,
            selectionRequired: true,
            columns: [
                { field: 'Measurement.Name', title: translate.get('i18n:MEASUREMENT_UNIT.MEASUREMENT'), filterable: true },
                { field: 'Name', title: translate.get('i18n:MEASUREMENT_UNIT.NAME'), filterable: true },
                { field: 'Symbol', title: translate.get('i18n:MEASUREMENT_UNIT.SYMBOL'), filterable: true }
            ]
        }

        this.parameterDefinitionSelectionPopupSettings = {
            title: translate.get('i18n:SELECT_PARAMETERD_DEFINITION'),
            entitySet: 'ParameterDefinition',
            includes: 'MeasurementUnit',
            sort: [{ field: 'Code', dir: 'asc' }],
            multiSelect: false,
            selectionRequired: true,
            columns: [
                { field: 'Code', title: translate.get('i18n:PARAMETER_DEFINITION.CODE'), filterable: true },
                { field: 'Description', title: translate.get('i18n:PARAMETER_DEFINITION.DESCRIPTION'), filterable: true },
            ]
        }
    }

    setDataSource(dataSource: DataSource, formGroup: FormGroup): FormGroup {
        this.dataSource = dataSource;

      if (formGroup) {
        this.formGroup = formGroup;
        const configs = this.dataSource?.Configs;
        const assetsConfig = _.find(configs, (x: DataSourceConfig) => x.Name === 'Assets'); 
        this.items = assetsConfig ? assetsConfig.Data : []; 
      } else {
        const configs = this.dataSource?.Configs;
        const energyMeasurementUnitIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'EnergyMeasurementUnitId');
        const irradianceMeasurementUnitIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'IrradianceMeasurementUnitId');
        const peakPowerMeasurementUnitIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'PeakPowerMeasurementUnitId');
        const peakPowerParameterDefinitionIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'PeakPowerParameterDefinitionId');
        const assetsConfig = find(configs, (x: DataSourceConfig) => x.Name === 'Assets');        
        this.items = assetsConfig ? assetsConfig.Data : []; 

        this.formGroup = new FormGroup({           
            EnergyMeasurementUnitId: new FormControl(energyMeasurementUnitIdConfig ? energyMeasurementUnitIdConfig.Value : null, Validators.required),
            IrradianceMeasurementUnitId: new FormControl(irradianceMeasurementUnitIdConfig ? irradianceMeasurementUnitIdConfig.Value : null, Validators.required),
            PeakPowerMeasurementUnitId: new FormControl(peakPowerMeasurementUnitIdConfig ? peakPowerMeasurementUnitIdConfig.Value : null, Validators.required),
            PeakPowerParameterDefinitionId: new FormControl(peakPowerParameterDefinitionIdConfig ? peakPowerParameterDefinitionIdConfig.Value : null, Validators.required),
        });
      }

        return this.formGroup;
    }

    getDataSource(): DataSource {
        const dataSource = new DataSource();
        dataSource.Configs = [];

        const configs = this.dataSource?.Configs;
        const energyMeasurementUnitIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'EnergyMeasurementUnitId');
        const irradianceMeasurementUnitIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'IrradianceMeasurementUnitId');
        const peakPowerMeasurementUnitIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'PeakPowerMeasurementUnitId');
        const peakPowerParameterDefinitionIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'PeakPowerParameterDefinitionId');
        const assetsConfig = find(configs, (x: DataSourceConfig) => x.Name === 'Assets');

        dataSource.Configs.push({ Id: energyMeasurementUnitIdConfig ? energyMeasurementUnitIdConfig.Id : uuidv4(), Name: 'EnergyMeasurementUnitId', Value: this.formGroup.get('EnergyMeasurementUnitId').value, Encrypt: false, Encrypted: false, Data: null });
        dataSource.Configs.push({ Id: irradianceMeasurementUnitIdConfig ? irradianceMeasurementUnitIdConfig.Id : uuidv4(), Name: 'IrradianceMeasurementUnitId', Value: this.formGroup.get('IrradianceMeasurementUnitId').value, Encrypt: false, Encrypted: false, Data: null });
        dataSource.Configs.push({ Id: peakPowerMeasurementUnitIdConfig ? peakPowerMeasurementUnitIdConfig.Id : uuidv4(), Name: 'PeakPowerMeasurementUnitId', Value: this.formGroup.get('PeakPowerMeasurementUnitId').value, Encrypt: false, Encrypted: false, Data: null });
        dataSource.Configs.push({ Id: peakPowerParameterDefinitionIdConfig ? peakPowerParameterDefinitionIdConfig.Id : uuidv4(), Name: 'PeakPowerParameterDefinitionId', Value: this.formGroup.get('PeakPowerParameterDefinitionId').value, Encrypt: false, Encrypted: false, Data: null });
        dataSource.Configs.push({
            Id: assetsConfig ? assetsConfig.Id : uuidv4(), Name: 'Assets', Value: JSON.stringify(this.items.filter(
                (function (item) {
                    if (item.AssetId === null || item.AssetId === undefined) { return false; } //Skip empty elements
                    else { return true; }
                })).map((item: DataSourceSolarPerformanceItemTag) => {
                    item.Asset = null;
                    item.EstimatedEnergyTag = null;
                    item.ActualEnergyTag = null;
                    item.EstimatedIrradianceTag = null;
                    item.ActualIrradianceTag = null;
                    return { AssetId: item.AssetId, EstimatedEnergyTagId: item.EstimatedEnergyTagId, ActualEnergyTagId: item.ActualEnergyTagId, EstimatedIrradianceTagId: item.EstimatedIrradianceTagId, ActualIrradianceTagId: item.ActualIrradianceTagId };
                })), Encrypt: false, Encrypted: false, Data: null
        });

        return dataSource;
    }

    markAllAsTouched() {
        this.formGroup.get('EnergyMeasurementUnitId').markAsTouched();
        this.formGroup.get('IrradianceMeasurementUnitId').markAsTouched();
        this.formGroup.get('PeakPowerMeasurementUnitId').markAsTouched();
        this.formGroup.get('PeakPowerParameterDefinitionId').markAsTouched();
    }

    isValid(): boolean {
        return this.formGroup.valid && this.gridErr.length == 0;
    }

    hasGridErr(): boolean {
        this.gridErr = this.getFirstGridErrorLoc();
        return this.gridErr.length > 0;
    }

    getFirstGridErrorLoc(): string {
        var isRequired = ' ' + this.translate.get('i18n:MESSAGE.FIELD_IS_REQUIRED');

        for (let i = 0; i < this.items?.length; ++i) {
            if (this.items[i].ActualEnergyTagId == null) return this.translate.get('i18n:DATASOURCE.SOLAR_PERFORMANCE.ACTUAL_ENERGY') + isRequired;
        }
        return '';
    }

    getActualEnergyTagDescription(item: DataSourceSolarPerformanceItemTag) {
        return this.getTagDescription(item.ActualEnergyTag);
    }

    getEstimatedEnergyTagDescription(item: DataSourceSolarPerformanceItemTag) {
        return this.getTagDescription(item.EstimatedEnergyTag);
    }

    getActualIrradianceTagDescription(item: DataSourceSolarPerformanceItemTag) {
        return this.getTagDescription(item.ActualIrradianceTag);
    }

    getEstimatedIrradianceTagDescription(item: DataSourceSolarPerformanceItemTag) {
        return this.getTagDescription(item.EstimatedIrradianceTag);
    }

    private getTagDescription(item: BaseTag): string {
        if (item) {
            return item.Asset.Path + ' - ' + item.Name;
        }
        else if (item?.Id) {
            return this.translate.get('i18n:LABEL.LOADING');
        } else {
            return '';
        }
    }

    getAssetDescription(item: DataSourceSolarPerformanceItemTag): string {
        if (item.Asset) {
            return item.Asset.Path;
        } else {
            return '';
        }
    }

    openActualEnergySelectionPopup(item: DataSourceSolarPerformanceItemTag) {
        this.core.showEntitySelectionPopup({
            title: this.translate.get('i18n:LABEL.TAG_SELECTION'),
            entitySet: 'UserBaseTags',
            singleEntitySet: 'BaseTags',
            includes: 'Asset.AssetType,MeasurementUnit.Measurement',
            sort: [{ field: 'Asset.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
            multiSelect: false,
            selectionRequired: false,
            columns: [
                { field: 'Asset.Path', title: this.translate.get('i18n:TAG.ASSET'), filterable: true },
                { field: 'Name', title: this.translate.get('i18n:TAG.NAME'), filterable: true },
                { field: 'MeasurementUnit.Name', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT'), filterable: true },
                { field: 'MeasurementUnit.Symbol', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT.SYMBOL'), filterable: true }
            ]
        }).subscribe({
            next: result => {
                if (result && result.length == 1) {
                    item.ActualEnergyTag = result[0] as BaseTag;
                    item.ActualEnergyTagId = item.ActualEnergyTag.Id;
                }
            }
        });
    }

    openEstimatedEnergySelectionPopup(item: DataSourceSolarPerformanceItemTag) {
        this.core.showEntitySelectionPopup({
            title: this.translate.get('i18n:LABEL.TAG_SELECTION'),
            entitySet: 'UserBaseTags',
            singleEntitySet: 'BaseTags',
            includes: 'Asset.AssetType,MeasurementUnit.Measurement',
            sort: [{ field: 'Asset.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
            multiSelect: false,
            selectionRequired: false,
            columns: [
                { field: 'Asset.Path', title: this.translate.get('i18n:TAG.ASSET'), filterable: true },
                { field: 'Name', title: this.translate.get('i18n:TAG.NAME'), filterable: true },
                { field: 'MeasurementUnit.Name', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT'), filterable: true },
                { field: 'MeasurementUnit.Symbol', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT.SYMBOL'), filterable: true }
            ]
        }).subscribe({
            next: result => {
                if (result && result.length == 1) {
                    item.EstimatedEnergyTag = result[0] as BaseTag;
                    item.EstimatedEnergyTagId = item.EstimatedEnergyTag.Id;
                }
            }
        });
    }

    openActualIrradianceSelectionPopup(item: DataSourceSolarPerformanceItemTag) {
        this.core.showEntitySelectionPopup({
            title: this.translate.get('i18n:LABEL.TAG_SELECTION'),
            entitySet: 'UserBaseTags',
            singleEntitySet: 'BaseTags',
            includes: 'Asset.AssetType,MeasurementUnit.Measurement',
            sort: [{ field: 'Asset.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
            multiSelect: false,
            selectionRequired: false,
            columns: [
                { field: 'Asset.Path', title: this.translate.get('i18n:TAG.ASSET'), filterable: true },
                { field: 'Name', title: this.translate.get('i18n:TAG.NAME'), filterable: true },
                { field: 'MeasurementUnit.Name', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT'), filterable: true },
                { field: 'MeasurementUnit.Symbol', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT.SYMBOL'), filterable: true }
            ]
        }).subscribe({
            next: result => {
                if (result && result.length == 1) {
                    item.ActualIrradianceTag = result[0] as BaseTag;
                    item.ActualIrradianceTagId = item.ActualIrradianceTag.Id;
                }
            }
        });
    }

    openEstimatedIrradianceSelectionPopup(item: DataSourceSolarPerformanceItemTag) {
        this.core.showEntitySelectionPopup({
            title: this.translate.get('i18n:LABEL.TAG_SELECTION'),
            entitySet: 'UserBaseTags',
            singleEntitySet: 'BaseTags',
            includes: 'Asset.AssetType,MeasurementUnit.Measurement',
            sort: [{ field: 'Asset.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
            multiSelect: false,
            selectionRequired: false,
            columns: [
                { field: 'Asset.Path', title: this.translate.get('i18n:TAG.ASSET'), filterable: true },
                { field: 'Name', title: this.translate.get('i18n:TAG.NAME'), filterable: true },
                { field: 'MeasurementUnit.Name', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT'), filterable: true },
                { field: 'MeasurementUnit.Symbol', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT.SYMBOL'), filterable: true }
            ]
        }).subscribe({
            next: result => {
                if (result && result.length == 1) {
                    item.EstimatedIrradianceTag = result[0] as BaseTag;
                    item.EstimatedIrradianceTagId = item.EstimatedIrradianceTag.Id;
                }
            }
        });
    }

    clearEstimatedEnergy(item: DataSourceSolarPerformanceItemTag) {
        item.EstimatedEnergyTag = null;
        item.EstimatedEnergyTagId = null;
    }

    clearActualEnergy(item: DataSourceSolarPerformanceItemTag) {
        item.ActualEnergyTag = null;
        item.ActualEnergyTagId = null;
    }

    clearEstimatedIrradiance(item: DataSourceSolarPerformanceItemTag) {
        item.EstimatedIrradianceTag = null;
        item.EstimatedIrradianceTagId = null;
    }

    clearActualIrradiance(item: DataSourceSolarPerformanceItemTag) {
        item.ActualIrradianceTag = null;
        item.ActualIrradianceTagId = null;
    }

    openAssetSelectionPopup() {
        this.core.showEntitySelectionPopup({
            title: this.translate.get('i18n:LABEL.ASSET_SELECTION'),
            entitySet: 'UserAssets',
            singleEntitySet: 'Assets',
            includes: 'AssetType',
            sort: [{ field: 'Path', dir: 'asc' }],
            multiSelect: true,
            selectionRequired: false,
            columns: [
                { field: 'Path', title: this.translate.get('i18n:ASSET.PATH'), filterable: true },
                { field: 'Description', title: this.translate.get('i18n:ASSET.DESCRIPTION'), filterable: true },
                { field: 'AssetType.Name', title: this.translate.get('i18n:ASSET.TYPE'), filterable: true }
            ]
        }).subscribe({
            next: result => {
                if (result && result.length) {
                    result.forEach((asset: Asset) => {
                        if (findIndex(this.items, (item: DataSourceSolarPerformanceItemTag) => item.AssetId == asset.Id) == -1) {
                            this.items.push({
                                Asset: asset,
                                AssetId: asset.Id,
                                ActualEnergyTag: null,
                                ActualEnergyTagId: null,
                                ActualIrradianceTag: null,
                                ActualIrradianceTagId: null,
                                EstimatedEnergyTag: null,
                                EstimatedEnergyTagId: null,
                                EstimatedIrradianceTag: null,
                                EstimatedIrradianceTagId: null
                            });
                        }
                    });
                }
            }
        });
    }

    removeItems() {
        if (this.items && this.items.length && this.selectedItemKeys && this.selectedItemKeys.length) {
            const assetIds: string[] = clone(this.selectedItemKeys);
            assetIds.forEach((assetId: string) => {
                remove(this.items, (item: DataSourceSolarPerformanceItemTag) => item.AssetId == assetId);
            });
            this.selectedItemKeys = [];
        }
    }

    public cellClick(event: CellClickEvent) {
        if (!event.isEdited) {
            event.sender.editCell(event.rowIndex, event.columnIndex, this.createFormGroup(event.dataItem));
        }
      }

      public cellClose(event: CellCloseEvent) {
        const formControl: FormControl = event.formGroup.get(event.column.field);
        if (formControl) {
            if (!formControl.valid) {
                event.preventDefault();
            } else if (formControl.dirty) {
                event.dataItem[event.column.field] = formControl.value;
            }
        }
    }

    public createFormGroup(dataItem: DataSourceSolarPerformanceItemTag) {
        return new FormGroup({
            AssetId: new FormControl(dataItem.AssetId, Validators.required),
            EstimatedEnergyTagId: new FormControl(dataItem.EstimatedEnergyTagId),
            ActualEnergyTagId: new FormControl(dataItem.ActualEnergyTagId, Validators.required),
            EstimatedIrradianceTagId: new FormControl(dataItem.EstimatedIrradianceTagId),
            ActualIrradianceTagId: new FormControl(dataItem.ActualIrradianceTagId),
        });
    }
}

export class DataSourceSolarPerformanceItem {
    EnergyMeasurementUnitId: string;
    EnergyMeasurementUnit: MeasurementUnit;
    IrradianceMeasurementUnitId: string;
    IrradianceMeasurementUnit: MeasurementUnit;
    PeakPowerParameterDefinitionId: string;
    PeakPowerParameterDefinition: ParameterDefinition;
    PeakPowerMeasurementUnitId: string;
    PeakPowerMeasurementUnit: MeasurementUnit;

    Tags: DataSourceSolarPerformanceItemTag[];
}

export class DataSourceSolarPerformanceItemTag {
    AssetId: string;
    Asset: Asset;
    EstimatedEnergyTagId: string;
    EstimatedEnergyTag: BaseTag;
    ActualEnergyTagId: string;
    ActualEnergyTag: BaseTag;
    EstimatedIrradianceTagId: string;
    EstimatedIrradianceTag: BaseTag;
    ActualIrradianceTagId: string;
    ActualIrradianceTag: BaseTag;
}