import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DataSource, DataSourceConfig, DataSourceComponent, TranslateService, EntitySelectionPopupSettings, CoreService } from "@ats/ats-platform-dashboard";

import { find, isNumber, findIndex, clone, remove } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import { BaseTag } from "../../domain/entities/baseTag";
import { MeasurementUnit } from "../../domain/entities/measurementUnit";
import { EnergyMeterType } from "../../domain/enums/EnergyMeterType";

@Component({
    selector: 'ats-smarttool-datasource-energy-management',
    templateUrl: './datasource-energy-management.component.html',
    styleUrls: ['./datasource-energy-management.component.scss']
})
export class DataSourceEnergyManagementComponent implements DataSourceComponent {

    dataSource: DataSource;

    public formGroup: FormGroup;
    public solarItems: DataSourceEnergyManagementTagConfig[] = [];
    public selectedSolarItemKeys: string[] = [];
    public windItems: DataSourceEnergyManagementTagConfig[] = [];
    public selectedWindItemKeys: string[] = [];
    public gridConsumptionItems: DataSourceEnergyManagementTagConfig[] = [];
    public selectedGridConsumptionItemKeys: string[] = [];
    public gridInjectionItems: DataSourceEnergyManagementTagConfig[] = [];
    public selectedGridInjectionItemKeys: string[] = [];
    public gasItems: DataSourceEnergyManagementTagConfig[] = [];
    public selectedGasItemKeys: string[] = [];
    public dieselItems: DataSourceEnergyManagementTagConfig[] = [];
    public selectedDieselItemKeys: string[] = [];
    public evChargerItems: DataSourceEnergyManagementTagConfig[] = [];
    public selectedEVChargerItemKeys: string[] = [];
    public batteryEnergyInItems: DataSourceEnergyManagementTagConfig[] = [];
    public selectedBatteryEnergyInItemKeys: string[] = [];
    public batteryEnergyOutItems: DataSourceEnergyManagementTagConfig[] = [];
    public selectedBatteryEnergyOutItemKeys: string[] = [];

    public measurementUnitSelectionPopupSettings: EntitySelectionPopupSettings;
    public measurementUnitFormatter = (task: MeasurementUnit) => task?.Name;
    public tagSelectionPopupSettings: EntitySelectionPopupSettings;
    public tagFormatter = (tag: BaseTag) => tag?.Name;

    constructor(public translate: TranslateService, private core: CoreService) {
        this.measurementUnitSelectionPopupSettings = {
            title: translate.get('i18n:SELECT_MEASUREMENTUNIT'),
            entitySet: 'MeasurementUnits',
            includes: 'Measurement',
            sort: [{ field: 'Measurement.Name', dir: 'asc'}, { field: 'Name', dir: 'asc' }],
            multiSelect: false,
            selectionRequired: true,
            columns: [
                { field: 'Measurement.Name', title: translate.get('i18n:MEASUREMENT_UNIT.MEASUREMENT'), filterable: true },
                { field: 'Name', title: translate.get('i18n:MEASUREMENT_UNIT.NAME'), filterable: true },
                { field: 'Symbol', title: translate.get('i18n:MEASUREMENT_UNIT.SYMBOL'), filterable: true }
            ]
        }
        this.tagSelectionPopupSettings = {
          title: this.translate.get('i18n:LABEL.TAG_SELECTION'),
          entitySet: 'UserBaseTags',
          singleEntitySet: 'BaseTags',
          includes: 'Asset.AssetType,MeasurementUnit.Measurement',
          sort: [{ field: 'Asset.Path', dir: 'asc' },{ field: 'Name', dir: 'asc' }],
          multiSelect: false,
          selectionRequired: false,
          columns: [
            { field: 'Asset.Path', title: this.translate.get('i18n:TAG.ASSET'), filterable: true },
            { field: 'Name', title: this.translate.get('i18n:TAG.NAME'), filterable: true },
            { field: 'MeasurementUnit.Name', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT'), filterable: true },
            { field: 'MeasurementUnit.Symbol', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT.SYMBOL'), filterable: true }
          ]
      }
    }

    setDataSource(dataSource: DataSource, formGroup: FormGroup): FormGroup {
      this.dataSource = dataSource;

      if (formGroup) {
        this.formGroup = formGroup;
      } else {
        const configs = this.dataSource?.Configs;
        const energyMeasurementUnitIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'EnergyMeasurementUnitId');
        const co2MeasurementUnitIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'Co2MeasurementUnitId');
        const gasEmissionFactorConfig = find(configs, (x: DataSourceConfig) => x.Name == 'GasEmissionFactor');
        const gridEmissionFactorConfig = find(configs, (x: DataSourceConfig) => x.Name == 'GridEmissionFactor');
        const dieselEmissionFactorConfig = find(configs, (x: DataSourceConfig) => x.Name == 'DieselEmissionFactor');
        const priceGasConfig = find(configs, (x: DataSourceConfig) => x.Name == 'PriceGas');
        const tagPriceGasIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'TagPriceGasId');
        const priceGridConsumptionConfig = find(configs, (x: DataSourceConfig) => x.Name == 'PriceGridConsumption');
        const tagPriceGridConsumptionIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'TagPriceGridConsumptionId');
        const priceGridInjectionConfig = find(configs, (x: DataSourceConfig) => x.Name == 'PriceGridInjection');
        const tagPriceGridInjectionIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'TagPriceGridInjectionId');
        const priceDieselConfig = find(configs, (x: DataSourceConfig) => x.Name == 'PriceDiesel');
        const tagPriceDieselIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'TagPriceDieselId');
        const gasEnergyFactorConfig = find(configs, (x: DataSourceConfig) => x.Name == 'GasEnergyFactor');
        const dieselEnergyFactorConfig = find(configs, (x: DataSourceConfig) => x.Name == 'DieselEnergyFactor');
        const tagsConfig = find(configs, (x: DataSourceConfig) => x.Name === 'Tags');
        if(tagsConfig){
            tagsConfig.Data.map((tag: DataSourceEnergyManagementTagConfig) => {
              switch (tag.EnergyMeterType) {
                case EnergyMeterType.Solar:
                  this.solarItems.push(tag);
                  break;
                case EnergyMeterType.Wind:
                  this.windItems.push(tag);
                  break;
                case EnergyMeterType.GridConsumption:
                  this.gridConsumptionItems.push(tag);
                  break;
                case EnergyMeterType.GridInjection:
                  this.gridInjectionItems.push(tag);
                  break;
                case EnergyMeterType.Gas:
                  this.gasItems.push(tag);
                  break;
                case EnergyMeterType.Diesel:
                  this.dieselItems.push(tag);
                  break;
                case EnergyMeterType.EVCharger:
                  this.evChargerItems.push(tag);
                  break;
                case EnergyMeterType.BatteryEnergyIn:
                  this.batteryEnergyInItems.push(tag);
                  break;
                case EnergyMeterType.BatteryEnergyOut:
                  this.batteryEnergyOutItems.push(tag);
                  break;
                default:
                  // do nothing
              }
            } 
          );
        }

        this.formGroup = new FormGroup({         
          EnergyMeasurementUnitId: new FormControl(energyMeasurementUnitIdConfig ? energyMeasurementUnitIdConfig.Value : null, Validators.required),
          Co2MeasurementUnitId: new FormControl(co2MeasurementUnitIdConfig ? co2MeasurementUnitIdConfig.Value : null, Validators.required),
          GasEmissionFactor: new FormControl(gasEmissionFactorConfig ? +gasEmissionFactorConfig.Value : null),
          GridEmissionFactor: new FormControl(gridEmissionFactorConfig ? +gridEmissionFactorConfig.Value : null, Validators.required),
          DieselEmissionFactor: new FormControl(dieselEmissionFactorConfig ? +dieselEmissionFactorConfig.Value : null),
          PriceGas: new FormControl(priceGasConfig ? +priceGasConfig.Value : null),
          TagPriceGasId: new FormControl(tagPriceGasIdConfig ? tagPriceGasIdConfig.Value : null),
          PriceGridConsumption: new FormControl(priceGridConsumptionConfig ? +priceGridConsumptionConfig.Value : null),
          TagPriceGridConsumptionId: new FormControl(tagPriceGridConsumptionIdConfig ? tagPriceGridConsumptionIdConfig.Value : null),
          PriceGridInjection: new FormControl(priceGridInjectionConfig ? +priceGridInjectionConfig.Value : null),
          TagPriceGridInjectionId: new FormControl(tagPriceGridInjectionIdConfig ? tagPriceGridInjectionIdConfig.Value : null),
          PriceDiesel: new FormControl(priceDieselConfig ? +priceDieselConfig.Value : null),
          TagPriceDieselId: new FormControl(tagPriceDieselIdConfig ? tagPriceDieselIdConfig.Value : null),
          GasEnergyFactor: new FormControl(gasEnergyFactorConfig ? +gasEnergyFactorConfig.Value : null),
          DieselEnergyFactor: new FormControl(dieselEnergyFactorConfig ? +dieselEnergyFactorConfig.Value : null),
        });
      }

      return this.formGroup;
    }

    getDataSource(): DataSource {
        const dataSource = new DataSource();
        dataSource.Configs = [];
        const items: DataSourceEnergyManagementTagConfig[] = [...this.solarItems, ...this.windItems, ...this.gridConsumptionItems, ...this.gridInjectionItems, ...this.gasItems, ...this.dieselItems, ...this.evChargerItems, ...this.batteryEnergyInItems, ...this.batteryEnergyOutItems];

        const configs = this.dataSource?.Configs;
        const energyMeasurementUnitIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'EnergyMeasurementUnitId');
        const co2MeasurementUnitIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'Co2MeasurementUnitId');
        const gasEmissionFactorConfig = find(configs, (x: DataSourceConfig) => x.Name == 'GasEmissionFactor');
        const gridEmissionFactorConfig = find(configs, (x: DataSourceConfig) => x.Name == 'GridEmissionFactor');
        const dieselEmissionFactorConfig = find(configs, (x: DataSourceConfig) => x.Name == 'DieselEmissionFactor');
        const priceGasConfig = find(configs, (x: DataSourceConfig) => x.Name == 'PriceGas');
        const tagPriceGasIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'TagPriceGasId');
        const priceGridConsumptionConfig = find(configs, (x: DataSourceConfig) => x.Name == 'PriceGridConsumption');
        const tagPriceGridConsumptionIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'TagPriceGridConsumptionId');
        const priceGridInjectionConfig = find(configs, (x: DataSourceConfig) => x.Name == 'PriceGridInjection');
        const tagPriceGridInjectionIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'TagPriceGridInjectionId');
        const priceDieselConfig = find(configs, (x: DataSourceConfig) => x.Name == 'PriceDiesel');
        const tagPriceDieselIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'TagPriceDieselId');
        const gasEnergyFactorConfig = find(configs, (x: DataSourceConfig) => x.Name == 'GasEnergyFactor');
        const dieselEnergyFactorConfig = find(configs, (x: DataSourceConfig) => x.Name == 'DieselEnergyFactor');
        const tagsConfig = find(configs, (x: DataSourceConfig) => x.Name === 'Tags');

        dataSource.Configs.push({ Id: energyMeasurementUnitIdConfig ? energyMeasurementUnitIdConfig.Id : uuidv4(), Name: 'EnergyMeasurementUnitId', Value: this.formGroup.get('EnergyMeasurementUnitId').value, Encrypt: false, Encrypted: false, Data: null });
        dataSource.Configs.push({ Id: co2MeasurementUnitIdConfig ? co2MeasurementUnitIdConfig.Id : uuidv4(), Name: 'Co2MeasurementUnitId', Value: this.formGroup.get('Co2MeasurementUnitId').value, Encrypt: false, Encrypted: false, Data: null });
        dataSource.Configs.push({ Id: gasEmissionFactorConfig ? gasEmissionFactorConfig.Id : uuidv4(), Name: 'GasEmissionFactor', Value: this.configEvaluation(this.formGroup.get('GasEmissionFactor').value), Encrypt: false, Encrypted: false, Data: null });
        dataSource.Configs.push({ Id: gridEmissionFactorConfig ? gridEmissionFactorConfig.Id : uuidv4(), Name: 'GridEmissionFactor', Value: this.configEvaluation(this.formGroup.get('GridEmissionFactor').value), Encrypt: false, Encrypted: false, Data: null });
        dataSource.Configs.push({ Id: dieselEmissionFactorConfig ? dieselEmissionFactorConfig.Id : uuidv4(), Name: 'DieselEmissionFactor', Value: this.configEvaluation(this.formGroup.get('DieselEmissionFactor').value), Encrypt: false, Encrypted: false, Data: null });
        dataSource.Configs.push({ Id: priceGasConfig ? priceGasConfig.Id : uuidv4(), Name: 'PriceGas', Value: this.configEvaluation(this.formGroup.get('PriceGas').value), Encrypt: false, Encrypted: false, Data: null });
        dataSource.Configs.push({ Id: tagPriceGasIdConfig ? tagPriceGasIdConfig.Id : uuidv4(), Name: 'TagPriceGasId', Value: this.formGroup.get('TagPriceGasId').value, Encrypt: false, Encrypted: false, Data: null });
        dataSource.Configs.push({ Id: priceGridConsumptionConfig ? priceGridConsumptionConfig.Id : uuidv4(), Name: 'PriceGridConsumption', Value: this.configEvaluation(this.formGroup.get('PriceGridConsumption').value), Encrypt: false, Encrypted: false, Data: null });
        dataSource.Configs.push({ Id: tagPriceGridConsumptionIdConfig ? tagPriceGridConsumptionIdConfig.Id : uuidv4(), Name: 'TagPriceGridConsumptionId', Value: this.formGroup.get('TagPriceGridConsumptionId').value, Encrypt: false, Encrypted: false, Data: null });
        dataSource.Configs.push({ Id: priceGridInjectionConfig ? priceGridInjectionConfig.Id : uuidv4(), Name: 'PriceGridInjection', Value: this.configEvaluation(this.formGroup.get('PriceGridInjection').value), Encrypt: false, Encrypted: false, Data: null });
        dataSource.Configs.push({ Id: tagPriceGridInjectionIdConfig ? tagPriceGridInjectionIdConfig.Id : uuidv4(), Name: 'TagPriceGridInjectionId', Value: this.formGroup.get('TagPriceGridInjectionId').value, Encrypt: false, Encrypted: false, Data: null });
        dataSource.Configs.push({ Id: priceDieselConfig ? priceDieselConfig.Id : uuidv4(), Name: 'PriceDiesel', Value: this.configEvaluation(this.formGroup.get('PriceDiesel').value), Encrypt: false, Encrypted: false, Data: null });
        dataSource.Configs.push({ Id: tagPriceDieselIdConfig ? tagPriceDieselIdConfig.Id : uuidv4(), Name: 'TagPriceDieselId', Value: this.formGroup.get('TagPriceDieselId').value, Encrypt: false, Encrypted: false, Data: null });
        dataSource.Configs.push({ Id: gasEnergyFactorConfig ? gasEnergyFactorConfig.Id : uuidv4(), Name: 'GasEnergyFactor', Value: this.configEvaluation(this.formGroup.get('GasEnergyFactor').value), Encrypt: false, Encrypted: false, Data: null });
        dataSource.Configs.push({ Id: dieselEnergyFactorConfig ? dieselEnergyFactorConfig.Id : uuidv4(), Name: 'DieselEnergyFactor', Value: this.configEvaluation(this.formGroup.get('DieselEnergyFactor').value), Encrypt: false, Encrypted: false, Data: null });

        dataSource.Configs.push({
            Id: tagsConfig ? tagsConfig.Id : uuidv4(), Name: 'Tags', Value: JSON.stringify(items.filter(
                (function (item) {
                    if (item.TagId === null || item.TagId === undefined) { return false; } //Skip empty elements
                    else { return true; }
                })).map((item: DataSourceEnergyManagementTagConfig) => {
                    item.Tag = null;
                    return { TagId: item.TagId, EnergyMeterType: item.EnergyMeterType };
                })), Encrypt: false, Encrypted: false, Data: null
        });

        return dataSource;
    }

    markAllAsTouched() {
      this.formGroup.get('EnergyMeasurementUnitId').markAsTouched();
      this.formGroup.get('Co2MeasurementUnitId').markAsTouched();
      this.formGroup.get('GasEmissionFactor').markAsTouched();
      this.formGroup.get('GridEmissionFactor').markAsTouched();
      this.formGroup.get('DieselEmissionFactor').markAsTouched();
      this.formGroup.get('PriceGas').markAsTouched();
      this.formGroup.get('TagPriceGasId').markAsTouched();
      this.formGroup.get('PriceGridConsumption').markAsTouched();
      this.formGroup.get('TagPriceGridConsumptionId').markAsTouched();
      this.formGroup.get('PriceGridInjection').markAsTouched();
      this.formGroup.get('TagPriceGridInjectionId').markAsTouched();
      this.formGroup.get('PriceDiesel').markAsTouched();
      this.formGroup.get('TagPriceDieselId').markAsTouched();
      this.formGroup.get('GasEnergyFactor').markAsTouched();
      this.formGroup.get('DieselEnergyFactor').markAsTouched();
    }

    isValid(): boolean {
        return this.formGroup.valid;
    }

    getTagDescription(item: DataSourceEnergyManagementTagConfig): string {
        if (item.Tag) {
            return item.Tag.Asset.Path + ' - ' + item.Tag.Name;
        }
        else if (item.TagId) {
            return this.translate.get('i18n:LABEL.LOADING');
        } else {
            return '';
        }
    }
    configEvaluation(value: any){
      if(isNumber(value)){
        return value.toString();
      }
      return value;
    }

    openTagSelectionPopup(items : DataSourceEnergyManagementTagConfig[], energyMeterType : EnergyMeterType) {

      this.core.showEntitySelectionPopup({
        title: this.translate.get('i18n:LABEL.TAG_SELECTION'),
        entitySet: 'UserBaseTags',
        singleEntitySet: 'BaseTags',
        includes: 'Asset.AssetType,MeasurementUnit.Measurement',
        sort: [{ field: 'Asset.Path', dir: 'asc' },{ field: 'Name', dir: 'asc' }],
        multiSelect: true,
        selectionRequired: false,
        columns: [
          { field: 'Asset.Path', title: this.translate.get('i18n:TAG.ASSET'), filterable: true },
          { field: 'Name', title: this.translate.get('i18n:TAG.NAME'), filterable: true },
          { field: 'MeasurementUnit.Name', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT'), filterable: true },
          { field: 'MeasurementUnit.Symbol', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT.SYMBOL'), filterable: true }
        ]
      }).subscribe(result => {
          if (result && result.length) {
              result.forEach((tag: BaseTag) => {
                  if (findIndex(items, (item: DataSourceEnergyManagementTagConfig) => item.TagId == tag.Id) == -1) {
                      items.push({
                          TagId: tag.Id,
                          Tag: tag,
                          EnergyMeterType: energyMeterType
                      });
                  }
              });
          }
      });
    }

    removeItems(items : DataSourceEnergyManagementTagConfig[], energyMeterType : EnergyMeterType, selectedItemKeys : string[]) {
        if (items && items.length && selectedItemKeys && selectedItemKeys.length) {
            const tagIds: string[] = clone(selectedItemKeys);
            tagIds.forEach((tagId: string) => {
                remove(items, (item: DataSourceEnergyManagementTagConfig) => item.TagId == tagId);
            });
            selectedItemKeys = [];
        }
    }
}

export class DataSourceEnergyManagementTagConfig {
    TagId: string;
    Tag: BaseTag;
    EnergyMeterType: EnergyMeterType;
}
