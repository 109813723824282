import { Injectable } from "@angular/core";
import { forkJoin, Observable, of, map } from "rxjs";
import { find } from "lodash-es";
import { AbstractDataSourceLoader, ApiQueryOptions, ApiService, CoreService, DataSource, DataSourceConfig, DataSourceInstance, DataSourceResult } from "@ats/ats-platform-dashboard";
import { BaseTag } from "../domain/entities/baseTag";
import { TagHubService } from "./tag-hub.service";
import { TagValue } from "../domain/tagValue";
import { MeasurementUnit } from "../domain/entities/measurementUnit";

@Injectable({
 providedIn: 'root',
})
export class TagsDataSourceLoader implements AbstractDataSourceLoader {

    private dataSourceInstances: { [id: string]: DataSourceInstance } = {};

    constructor(private api: ApiService, private core: CoreService, private tagHub: TagHubService) {
        this.tagHub.on('broadcastTagValue', this.onBroadcastTagValue);
    }

    public load(from: Date, to: Date, instance: DataSourceInstance): Observable<DataSourceResult> {
        if (!this.dataSourceInstances[instance.id]) {
            this.dataSourceInstances[instance.id] = instance;

            return this.tagHub.invoke<TagDataSourceItem[]>('SubscribeDataSource', instance.id).pipe(map((result: TagDataSourceItem[]) => {
                return {
                    isLoading: false,
                    data: result,
                    error: null,
                    contentType: 'application/json'
                };
            }));
        } else {
            return of(this.dataSourceInstances[instance.id].result);
        }
    }

    public unload(id: string): Observable<void> {
        delete this.dataSourceInstances[id];

        return this.tagHub.invoke<any>('UnsubscribeDataSource', id);
    }

    hasItems(): boolean {
        return true;
    }

    loadItems(dataSource: DataSource): Observable<{ id: string; name: string; }[]> {

        const tagsConfig: DataSourceConfig = find(dataSource.Configs, (config: DataSourceConfig) => config.Name == 'tags');
        const tags: { TagId: string }[] = tagsConfig && tagsConfig.Value && tagsConfig.Value.length ? JSON.parse(tagsConfig.Value) : [];
        
        const observables: Observable<BaseTag>[] = tags.map((tag: {TagId: string}) => {
            return this.api.getSingleResult('BaseTags', tag.TagId, new ApiQueryOptions({ include: 'Asset' }))
        });

        return forkJoin(observables).pipe(map((tags: BaseTag[]) => {
           
            return tags.map((tag: BaseTag) => {
               
                return {                    
                    id: tag.Id,
                    name: tag.Asset.Path + ' - ' + tag.Name
                };
            });
        }));
    }

    onBroadcastTagValue = (dataSourceId: string, tagValue: TagValue) => {
        const instance = this.dataSourceInstances[dataSourceId];
        if (instance && instance.result) {
            const item = find(<TagDataSourceItem[]>instance.result.data, (item: TagDataSourceItem) => item.TagId == tagValue.TagId && item.MeasurementUnitId == tagValue.MeasurementUnitId);
            if (item && (!item.LatestValue || item.LatestValue.TimeStamp <= tagValue.TimeStamp)) {
                item.LatestValue = tagValue;
                instance.fireItemValueChangeEvent(item.TagId, item);
            }
        }
    }

}

export class TagDataSourceItem {
    TagId: string;
    Tag: BaseTag;
    MeasurementUnitId: string;
    MeasurementUnit: MeasurementUnit;
    LatestValue: TagValue;
}
