import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Type, ViewChild } from "@angular/core";
import { HubConnection } from "@aspnet/signalr";
import { DashboardService, DataSourceInstance, TranslateService, WidgetComponent, WidgetInstance, SvgContainerComponent, WidgetUpdater, ItemValueChangedEventArgs } from "@ats/ats-platform-dashboard";
import { Subscription } from "rxjs";

import { find, round } from "lodash-es";
import { select } from 'd3';
import { PieChart } from "../../svg/pie-chart";
import { PieChartStackData } from "../../domain/dataModels/pieChartData";
import { ProductionConsumptionData } from "../../domain/dataModels/productionConsumptionData";
import { EnergyManagementData, EnergyMeterTypeConfig } from "../../domain/dataModels/energyManagementData";
import { EnergyMeterType } from "../../domain/enums/EnergyMeterType";

@Component({
  selector: 'ats-smart-tool-tag-pie-chart-widget',
  templateUrl: './tag-pie-chart-widget.component.html',
  styleUrls: ['./tag-pie-chart-widget.component.scss']
})
export class TagPieChartWidgetComponent implements WidgetComponent, OnInit, AfterViewInit, OnDestroy {
  @Input() hubConnection: HubConnection;

  public widgetInstance: WidgetInstance;
  public isLoading = false;
  public error: string;

  private widgetConfigSubscription: Subscription;
  private widgetUpdaters: WidgetUpdater[];
  private svg: SVGSVGElement;
  private div: HTMLDivElement;
  private drawer: PieChart;

  public svgContainerType: Type<SvgContainerComponent> = SvgContainerComponent;

  @ViewChild('svgContainer', { static: false }) svgContainer: ElementRef;

  constructor(private dashboardService: DashboardService, private translate: TranslateService) {
  }

  public setWidgetInstance(widgetInstance: WidgetInstance) {
    this.widgetInstance = widgetInstance;
  }

  ngOnInit() {
    this.widgetConfigSubscription = this.widgetInstance.changed.subscribe({
      next: (instance: WidgetInstance) => {
        this.updateData(instance);
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => { this.updateData(this.widgetInstance); }, 0);
  }

  ngOnDestroy() {
    if (this.widgetConfigSubscription) {
      this.widgetConfigSubscription.unsubscribe();
      this.widgetConfigSubscription = null;
    }

    if (this.widgetUpdaters) {
      this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
      this.widgetUpdaters = null;
    }
  }

  protected updateData(instance: WidgetInstance) {
    instance.widgetData = {
      title: instance.widgetConfig.title
    };

    if (!this.svgContainer)
      return;

    this.div = this.svgContainer.nativeElement;
    const svgs = this.div.getElementsByTagName('svg');
    this.svg = svgs && svgs.length ? svgs.item(0) : null;
    if (!this.svg)
      return;

    const d3svg = select(this.svg);
    d3svg.selectAll('g').remove();

    const g = d3svg.append('g');

    this.drawer = new PieChart();
    this.drawer.init(g);

    if (this.widgetUpdaters) {
      this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
    }

    const dataSourceInstance = find(this.dashboardService.dataSourceInstances, (dataSourceInstance: DataSourceInstance) => dataSourceInstance.id == instance.widgetConfig.dataSourceId);

    if (dataSourceInstance) {
      this.widgetUpdaters = [];

      this.widgetUpdaters.push(
        new WidgetUpdater(dataSourceInstance, this.svgContainer.nativeElement,
          (dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement) => {
            this.isLoading = dataSourceInstance?.result?.isLoading;

            if (dataSourceInstance.result && dataSourceInstance.result.data) {
              let width = (this.div.getBoundingClientRect().width);
              let height = (this.div.getBoundingClientRect().height);
              d3svg.attr('viewBox', '' + (-width / 2) + ' ' + (-height / 2) + ' ' + (this.div.getBoundingClientRect().width) + ' ' + (this.div.getBoundingClientRect().height));

              this.drawer.bottom = 40;
              this.drawer.top = 40;
              this.drawer.left = 40;
              this.drawer.right = 40;
              this.drawer.height = this.div.getBoundingClientRect().height;
              this.drawer.width = this.div.getBoundingClientRect().width;
              this.drawer.numberOfDecimals = instance.widgetConfig.numberOfDecimals;
              this.drawer.selectedCategory = instance.widgetConfig.selectedCategory;

              switch (dataSourceInstance.type?.name) {
                case "productionConsumption":
                  var productionConsumptionData: ProductionConsumptionData = <ProductionConsumptionData>(dataSourceInstance.result.data);

                  //TODO PAND opkuisen (niet op deze manier werken)
                  instance.widgetConfig.visualizeTags = [];
                  productionConsumptionData.TagConfig.forEach(x => {
                    instance.widgetConfig.visualizeTags.push({ TagId: x.TagId, Show: true });
                  })

                  this.drawer.measurementUnit = productionConsumptionData.MeasurementUnit.Symbol;
                  this.drawer.categoryTotal = 0;

                  this.drawer.data = productionConsumptionData.TagConfig.filter(x => x.Category === this.drawer.selectedCategory).map(x => <PieChartStackData>{ Id: x.TagId, Color: x.Color, Value: 0, Path: productionConsumptionData.Tags.find(tag => tag.Id === x.TagId).Asset.Path, Name: productionConsumptionData.Tags.find(tag => tag.Id === x.TagId).Name });
                  this.drawer.data.forEach(x => {
                    var summaryData = productionConsumptionData.CategorySummaryData.filter(y => y.Name === x.Id)[0].Value;
                    x.Value = summaryData;
                    this.drawer.categoryTotal += summaryData;
                  });
                  this.drawer.centerText = round(this.drawer.categoryTotal, this.drawer.numberOfDecimals) + " " + this.drawer.measurementUnit;
                  break;
                case "energyManagement":
                  let energyManagementData: EnergyManagementData = dataSourceInstance.result.data;

                  this.drawer.measurementUnit = energyManagementData.EnergyMeasurementUnit.Symbol;
                  switch (this.drawer.selectedCategory) {
                    case "selfConsumption":
                      this.drawer.data[0] = <PieChartStackData>(energyManagementData.AnalysisSummaryData.SelfConsumptionPieStackData.SelfConsumption);
                      this.drawer.data[1] = <PieChartStackData>(energyManagementData.AnalysisSummaryData.SelfConsumptionPieStackData.InjectedEnergy);
                      this.drawer.categoryTotal = this.drawer.data[0].Value + this.drawer.data[1].Value;
                      this.drawer.centerText = round(this.drawer.data[0].Value / this.drawer.categoryTotal * 100, this.drawer.numberOfDecimals) + " %";
                      break;
                    case "renewableEnergy":
                      this.drawer.data[0] = <PieChartStackData>(energyManagementData.AnalysisSummaryData.RenewableEnergyPieStackData.SelfConsumption);
                      this.drawer.data[1] = <PieChartStackData>(energyManagementData.AnalysisSummaryData.RenewableEnergyPieStackData.ExternalEnergyUsed);
                      this.drawer.categoryTotal = this.drawer.data[0].Value + this.drawer.data[1].Value;
                      this.drawer.centerText = round(this.drawer.data[0].Value / this.drawer.categoryTotal * 100, this.drawer.numberOfDecimals) + " %";
                      break;
                    case "energyMix":
                    case "electricityMix":
                      this.drawer.data = [];
                      this.drawer.categoryTotal = 0;
                      energyManagementData.EnergyMeterTypeConfig.forEach(energyMeterTypeConfig => {
                        let tempStackItemInfo = new PieChartStackData();
                        const mappedCategory = this.setEnergyMeterTypeCategory(energyMeterTypeConfig, new Array(this.drawer.selectedCategory));

                        if (mappedCategory === this.drawer.selectedCategory) {
                          tempStackItemInfo.Id = EnergyMeterType[energyMeterTypeConfig.EnergyMeterType];
                          tempStackItemInfo.Name = EnergyMeterType[energyMeterTypeConfig.EnergyMeterType];
                          tempStackItemInfo.Value = energyManagementData.EnergyMeterTypeSummaryData[tempStackItemInfo.Name];
                          ;
                          tempStackItemInfo.Path = energyMeterTypeConfig.Path;
                          tempStackItemInfo.Color = energyMeterTypeConfig.Color;

                          this.drawer.categoryTotal += tempStackItemInfo.Value;
                          this.drawer.data.push(tempStackItemInfo);
                        }
                      });
                      this.drawer.centerText = round(this.drawer.categoryTotal, this.drawer.numberOfDecimals) + "  " + this.drawer.measurementUnit;
                      break;
                  }
              }
              this.drawer.draw();
            }
            //generate title if not present, update happens twice first time title gets filled in with null as unit, then with correct unit value. this is the reason widgetconfig.title does not get updated
            if (!this.widgetInstance.widgetConfig.title) {
              this.widgetInstance.widgetData.title = this.translate.get('i18n:DONUT_CHART_WIDGET') + ' (' + this.drawer.measurementUnit + ')';
            }
          },
          (dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement, event: ItemValueChangedEventArgs) => {
            //No need to change if data from item changed
          }));
    }
  }

  public cancel(e: any) {
    e.preventDefault();
    e.cancelBubble = true;
  }

  private setEnergyMeterTypeCategory(energyMeterTypeConfig: EnergyMeterTypeConfig, widgetCategory: string[]): string {

    const selectedCategory = widgetCategory[0];
    let categoryMapping = {};

    switch (selectedCategory) {
      case 'energyMix':
        for (let energyMeterType in EnergyMeterType) {
          switch (energyMeterType) {
            case EnergyMeterType.Solar.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
            case EnergyMeterType.Wind.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
            case EnergyMeterType.GridConsumption.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
              break;
            case EnergyMeterType.GridInjection.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
            case EnergyMeterType.Gas.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
              break;
            case EnergyMeterType.Diesel.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
              break;
            case EnergyMeterType.EVCharger.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
            case EnergyMeterType.SelfConsumption.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
              break;
            case EnergyMeterType.BatteryEnergyIn.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
            case EnergyMeterType.BatteryEnergyOut.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
              break;
          }
        }
        break;
      case 'electricityMix':
        for (let energyMeterType in EnergyMeterType) {
          switch (energyMeterType) {
            case EnergyMeterType.Solar.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
            case EnergyMeterType.Wind.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
            case EnergyMeterType.GridConsumption.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
              break;
            case EnergyMeterType.GridInjection.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
            case EnergyMeterType.Gas.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
            case EnergyMeterType.Diesel.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
              break;
            case EnergyMeterType.EVCharger.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
            case EnergyMeterType.SelfConsumption.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
              break;
            case EnergyMeterType.BatteryEnergyIn.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
            case EnergyMeterType.BatteryEnergyOut.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
              break;              
          }
        }
        break;
    }
    const energyMeterTypeString = EnergyMeterType[energyMeterTypeConfig.EnergyMeterType];
    return categoryMapping[energyMeterTypeString];
  }
}