import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import * as _ from 'lodash';
import { ApiQueryOptions, ApiService, BASE_URL, CoreService, DataSource, DataSourceConfig, DataSourceInstance, DataSourceResult, GenericDataSourceLoader, PageResult } from "@ats/ats-platform-dashboard";
import { TagHubService } from "./tag-hub.service";
import { TagValue } from "../domain/tagValue";
import { TagDataSourceItem } from "./tagsDataSourceLoader";
import { debounceTime, map, switchMap, tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { TopologyDevice } from "../domain/dataModels/TopologyDevice";
import { TopologyDeviceDataPoint } from "../domain/dataModels/topologyDeviceDataPoint";
import { BaseTag } from "../domain/entities/baseTag";

@Injectable({
  providedIn: 'root',
})
export class DeviceTopologyDataSourceLoader extends GenericDataSourceLoader {

  private dataSourceInstances: { [id: string]: DataSourceInstance } = {};

  constructor(protected override api: ApiService, protected override core: CoreService, private tagHub: TagHubService, protected override http: HttpClient, @Inject(BASE_URL) protected override baseUrl: string) {
    super(api, core, http, baseUrl);
    this.tagHub.on('broadcastTagValue', this.onBroadcastTagValue);
  }

  public override load(from: Date, to: Date, instance: DataSourceInstance): Observable<DataSourceResult> {
    if (!this.dataSourceInstances[instance.id]) {
      this.dataSourceInstances[instance.id] = instance;
      return this.tagHub.invoke<TagDataSourceItem[]>('SubscribeDataSource', instance.id).pipe(switchMap((result: TagDataSourceItem[]) => {
        var observer = super.load(from, to, instance);
        return this.addSubjects(instance, observer);
      }));
    } else {
      var observer = super.load(from, to, instance);
      return this.addSubjects(instance, observer);
    }
  }
  
  protected addSubjects(instance: DataSourceInstance, observable: Observable<DataSourceResult>): Observable<DataSourceResult> {
    return observable.pipe(
      tap((result: DataSourceResult) => {
        const addSubjectsRecursive = (devices: TopologyDevice[]) => {
          _.forEach(devices, (device: TopologyDevice) => {
            device.LatestPointSubject = new BehaviorSubject(device.LatestPoint);
            device.LatestPoint$ = device.LatestPointSubject.asObservable();
            device.LatestPoint$.pipe(debounceTime(50)).subscribe((point: TopologyDeviceDataPoint) => {
              instance.fireItemValueChangeEvent(device.Name, point);
            });
            if (device.Children) {
              addSubjectsRecursive(device.Children);
            }
          });
        };
        addSubjectsRecursive(result.data);
      })
    );
  }

  override loadItems(dataSource: DataSource): Observable<{ id: string; name: string; }[]> {
    return this.api.executeFunction('DeviceTopology', 'GetPowerTags', dataSource.Id, new ApiQueryOptions({ include: 'Asset', sort: [{ field: 'Asset.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }] }))
      .pipe(map((pageResult: PageResult<BaseTag>) => pageResult.Items.map((item: BaseTag) => {
        return {
          id: item.Id,
          name: item.Asset.Path + ' - ' + item.Name
        };
      })));
  }

  public override unload(id: string): Observable<void> {
    var instance = this.dataSourceInstances[id];
    var topologyDevices: TopologyDevice[] = instance?.result?.data?.TopologyDevices;
    _.forEach(topologyDevices, (topologyDevice: TopologyDevice) => {
      topologyDevice.LatestPointSubject = new BehaviorSubject(topologyDevice.LatestPoint);
      topologyDevice.LatestPointSubject.complete();
    });
    delete this.dataSourceInstances[id];
    return this.tagHub.invoke<any>('UnsubscribeDataSource', id);
  }

  override hasItems(): boolean {
    return true;
  }

  onBroadcastTagValue = (dataSourceId: string, tagValue: TagValue) => {
    const instance = this.dataSourceInstances[dataSourceId];

    if (instance && instance.result) {
      _.forEach(<TopologyDevice[]>instance.result.data, topologyDevice => {
        this.traverseRootNodeAndUpdate(topologyDevice, tagValue);
      });
    }
  }
  private traverseRootNodeAndUpdate(item: TopologyDevice, tagValue: TagValue){
    if (tagValue.TagId == item.PowerTagId) {
      item.LatestPoint.IntervalEnd = tagValue.TimeStamp;
      item.LatestPoint.Power = tagValue.FloatingPointValue;
      item.LatestPointSubject?.next(item.LatestPoint);
    }
    if(item.Children){
      _.forEach(item.Children, child => {
        this.traverseRootNodeAndUpdate(child, tagValue);
      });
    }
  };
}