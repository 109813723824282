import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DataSource, DataSourceConfig, DataSourceComponent, TranslateService, CoreService } from "@ats/ats-platform-dashboard";
import { AddEvent, CellClickEvent, CellCloseEvent } from "@progress/kendo-angular-grid";
import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { TopologyDevice } from "../../domain/dataModels/TopologyDevice";
import { BaseTag } from "../../domain/entities/baseTag";
import { TagChart } from "../../domain/entities/tagChart";

@Component({
  selector: 'ats-smarttool-datasource-device-topology',
  templateUrl: './datasource-device-topology.component.html',
  styleUrls: ['./datasource-device-topology.component.scss']
})
export class DataSourceDeviceTopologyComponent implements DataSourceComponent {

  dataSource: DataSource;

  public formGroup: FormGroup;
  public items: TopologyDevice[];
  public selectedItemKeys: string[] = [];
  public gridErr: string = "";
  public defaultItem: TopologyDevice = {
    Name: null,
    PowerTagId: null,
    PowerTag: null,
    EnergyInTagId: null,
    EnergyInTag: null,
    EnergyOutTagId: null,
    EnergyOutTag: null,
    ChartId: null,
    Chart: null,
    Children: null,
    ParentDeviceName: null,
    IconName: null,
    IconScale: 0,
    X: 0,
    Y: 0,
    Info: null,
    G: null,
    Points: null,
    LatestPoint: null,
    LatestPointSubject: null,
    LatestPoint$: null
  };

  constructor(public translate: TranslateService, private core: CoreService) {
  }

  setDataSource(dataSource: DataSource, formGroup: FormGroup): FormGroup {
    this.dataSource = dataSource;

    if (formGroup) {
      this.formGroup = formGroup;
    } else {
      this.formGroup = new FormGroup({});
    }
    const configs = this.dataSource?.Configs;
    const topologyConfig = _.find(configs, (x: DataSourceConfig) => x.Name === 'Topology');
    this.items = topologyConfig ? topologyConfig.Data : [];

    return this.formGroup;
  }

  getDataSource(): DataSource {
    const dataSource = new DataSource();
    dataSource.Configs = [];

    const configs = this.dataSource?.Configs;
    const topologyConfig = _.find(configs, (x: DataSourceConfig) => x.Name === 'Topology');
    dataSource.Configs.push({
      Id: topologyConfig ? topologyConfig.Id : uuidv4(), Name: 'Topology',
      Value: JSON.stringify(this.items.filter(
        (function (item: TopologyDevice) {
          if (item.Name === null || item.Name === undefined) { return false; } //Skip empty elements
          else { return true; }
        })).map((item) => {
          item.PowerTag = null;
          item.EnergyInTag = null;
          item.EnergyOutTag = null;
          item.Chart = null;
          return item;
        })), Encrypt: false, Encrypted: false, Data: null
    })

    return dataSource;
  }

  markAllAsTouched() {
  } 

  isValid(): boolean {
    return this.gridErr.length == 0;
  }

  hasGridErr(): boolean {
    this.gridErr = this.getFirstGridErrorLoc();
    return this.gridErr.length > 0;
  }

  getFirstGridErrorLoc(): string {
    var isRequired = ' ' + this.translate.get('i18n:MESSAGE.FIELD_IS_REQUIRED');
    var circularReference = ' ' + this.translate.get('i18n:MESSAGE.CIRCULAR_REFERENCE');
    var sameName = ' ' + this.translate.get('i18n:MESSAGE.SAME_NAME');


    for (let i = 0; i < this.items?.length; ++i) {
      if (this.items[i].Name == null) return this.translate.get('i18n:DATASOURCE.DEVICE_TOPOLOGY.DEVICE') + isRequired;
      if (this.items[i].Name == this.items[i].ParentDeviceName) return this.translate.get('i18n:DATASOURCE.DEVICE_TOPOLOGY.DEVICE') + circularReference;
      if (this.items.filter(x => this.items[i].Name === x.Name).length !== 1) return this.translate.get('i18n:DATASOURCE.DEVICE_TOPOLOGY.DEVICE') + sameName;
    }
    return '';
  }

  getTagDescription(item: TopologyDevice, type: string): string {
    if (item) {
      switch (type) {
        case 'Power':
          if (item.PowerTag !== null) {
            return item.PowerTag.Asset.Path + ' - ' + item.PowerTag.Name;
          }
          break;
        case 'EnergyIn':
          if (item.EnergyInTag !== null) {
            return item.EnergyInTag.Asset.Path + ' - ' + item.EnergyInTag.Name;
          }
          break;
        case 'EnergyOut':
          if (item.EnergyOutTag !== null) {
            return item.EnergyOutTag.Asset.Path + ' - ' + item.EnergyOutTag.Name;
          }
          break;
        default:
          return '';
      }
    }
    return '';
  }

  getTagChartDescription(item: TopologyDevice): string {
    if (item && item.Chart !== null) {
      return item.Chart.Folder.Name + ' - ' + item.Chart.Name;
    }
    else {
      return '';
    }
  }

  public addHandler(args: AddEvent) {
    var topologyDevice = <TopologyDevice>({
      Name: null,
      PowerTagId: null,
      PowerTag: null,
      EnergyInTagId: null,
      EnergyInTag: null,
      EnergyOutTagId: null,
      EnergyOutTag: null,
      ChartId: null,
      Chart: null,
      ParentDeviceName: null,
      IconName: null,
      Points: null,
      LatestPoint: null,
      LatestPointSubject: null,
      LatestPoint$: null
    });
    this.items.push(topologyDevice);
  }

  public removeHandler({ sender, rowIndex }) {
    var removedItem = this.items[rowIndex];
    this.items.map(item => {
      if(item.ParentDeviceName === removedItem.ParentDeviceName) {
        item.ParentDeviceName = null;
    }});
    this.items.splice(rowIndex, 1);
  }

  public cellClickHandler(event: CellClickEvent) {
    if (!event.isEdited) {
      event.sender.editCell(event.rowIndex, event.columnIndex, this.createFormGroup(event.dataItem));
    }
  }
  public cellCloseHandler(event: CellCloseEvent) {
    let formControl: FormControl = event.formGroup.get(event.column.field)
    if (formControl) {
      if (!formControl.valid) {
        event.preventDefault();
      } else if (formControl.dirty) {
        event.dataItem[event.column.field] = formControl.value;
      }
    } else {
      const columnField = Object.entries(event.formGroup.controls).filter(([key, value]) => value['touched'] === true)[0][0];
      formControl = event.formGroup.get(columnField);
      if (formControl) {
        if (!formControl.valid) {
          event.preventDefault();
        } else if (formControl.dirty) {
          event.dataItem[columnField] = formControl.value;
        }
      }
    }
  }

  public createFormGroup(dataItem: TopologyDevice): FormGroup {
    return new FormGroup({
      Name: new FormControl(dataItem.Name, Validators.required),
      PowerTagId: new FormControl(dataItem.PowerTagId),
      EnergyInTagId: new FormControl(dataItem.EnergyInTagId),
      EnergyOutTagId: new FormControl(dataItem.EnergyOutTagId),
      Chart: new FormControl(dataItem.ChartId),
      ParentDeviceName: new FormControl(dataItem.ParentDeviceName),
      IconName: new FormControl(dataItem.IconName),
    });
  }

  openTagSelectionPopup(item: TopologyDevice, type: string) {
    this.core.showEntitySelectionPopup({
      title: this.translate.get('i18n:LABEL.TAG_SELECTION'),
      entitySet: 'UserBaseTags',
      singleEntitySet: 'BaseTags',
      includes: 'Asset.AssetType,MeasurementUnit.Measurement',
      sort: [{ field: 'Asset.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
      multiSelect: false,
      selectionRequired: false,
      columns: [
        { field: 'Asset.Path', title: this.translate.get('i18n:TAG.ASSET'), filterable: true },
        { field: 'Name', title: this.translate.get('i18n:TAG.NAME'), filterable: true },
        { field: 'MeasurementUnit.Name', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT'), filterable: true },
        { field: 'MeasurementUnit.Symbol', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT.SYMBOL'), filterable: true }
      ]
    }).subscribe(result => {
      if (result && result.length) {
        result.forEach((tag: BaseTag) => {
          switch (type) {
            case 'Power':
              item.PowerTag = tag as BaseTag;
              item.PowerTagId = item.PowerTag.Id;
              break;
            case 'EnergyIn':
              item.EnergyInTag = tag as BaseTag;
              item.EnergyInTagId = item.EnergyInTag.Id;
              break;
            case 'EnergyOut':
              item.EnergyOutTag = tag as BaseTag;
              item.EnergyOutTagId = item.EnergyOutTag.Id;
              break;
            default:
          }
        });
      }
    });
  }
  clearTagCell(item: TopologyDevice, type: string) {
    switch (type) {
      case 'Power':
        item.PowerTag = null;
        item.PowerTagId = null;
        break;
      case 'EnergyIn':
        item.EnergyInTag = null;
        item.EnergyInTagId = null;
        break;
      case 'EnergyOut':
        item.EnergyOutTag = null;
        item.EnergyOutTagId = null;
        break;
      default:
    }
  }

  openChartSelectionPopup(item: TopologyDevice) {
    this.core.showEntitySelectionPopup({
      title: this.translate.get('i18n:LABEL.CHART_SELECTION'),
      entitySet: 'TagCharts',
      includes: 'Folder',
      sort: [{ field: 'Folder.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
      multiSelect: false,
      selectionRequired: false,
      columns: [{ field: 'Folder.Path', title: this.translate.get('i18n:FOLDER.PATH'), filterable: true }, { field: 'Name', title: this.translate.get('i18n:TAGCHART.NAME'), filterable: true }
      ]
    }).subscribe(result => {
      if (result && result.length) {
        result.forEach((chart: TagChart) => {
          item.Chart = chart;
          item.ChartId = chart.Id;
        });
      }
    });
  }
  clearChartCell(item: TopologyDevice) {
    item.Chart = null;
    item.ChartId = null;
  }
}
