import { TagChartItem } from "./tagChartItem";
import { AssetType } from "./assetType";
import { Asset } from "./asset";
import { AccessRights, Folder } from "@ats/ats-platform-dashboard";

export class TagChart {
  Id: string;
  Name: string;

  FolderId: string;
  Folder: Folder;

  From: any;
  To: any;
  Period: any;
  TimeZone: string;
  FillTimeInterval: number;
  Config: string;
  Overwrite: boolean;
  Items: TagChartItem[];

  AssetTypeId: string;
  AssetType: AssetType;

  AssetId: string;
  Asset: Asset;

  TemplateId: string;
  Template: TagChart;

  fillTimeIntervalTypes: any[];
  AccessRights: AccessRights[];
  Level: number;

  constructor() {
    this.Name = "";
    this.FolderId = "";
    let today = new Date();
    let from = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0
    );
    let to = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      23,
      59,
      0
    );
    this.From = {
      fixedDateTime: from,
      beginEndOf: 0,
      offset: 1,
      unit: 2,
      period: 0,
      type: 0,
    };
    this.To = {
      fixedDateTime: to,
      beginEndOf: 2,
      offset: 0,
      unit: 2,
      period: 0,
      type: 1,
    };
    this.Period = { offset: 0, unit: 2, type: 0 };
    this.Items = [];
    this.Config = JSON.stringify({
      zoomValueAxis: false,
      zoomArgumentAxis: true,
      scales: [],
    });
    this.FillTimeInterval = -1;
  }
}
