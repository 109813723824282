import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CoreService, TranslateService } from '@ats/ats-platform-dashboard';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'; 
import { ChartComponent } from '../../public_api';
import { ChartService } from "../services/chart.service";

@Injectable()
export class ChartNavGuard implements CanDeactivate<ChartComponent> {

  constructor( private translate: TranslateService, private coreService: CoreService, private chartService: ChartService) { }

    canDeactivate(component: ChartComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

        if (this.chartService.chartConfigChanged != null)
        {
            if (nextState && nextState.root.firstChild.routeConfig.path !== 'login' && this.chartService.chartConfigChanged) {

                return this.coreService.showConfirmation(this.translate.get('i18n:SEVERITY.WARNING'), this.translate.get('i18n:NAV.CONFIRMATION.DATA_WILL_BE_LOST')).pipe(map(
                    (confirmed: boolean) => {
                        if (!confirmed) {
                            this.coreService.setBusy(false);
                            return false;
                        } else {
                            this.chartService.chartConfigChanged = false;
                            return true;
                        }
                    }
                ));
            } else {
                return true;
            }
        }
        else
        {
            return true;
        }
    }
}