import { Component, Inject, Injector } from '@angular/core';
import { keyBy, flatten, clone, intersection ,map} from "lodash-es";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CoreService, DashboardWidgetDocument, DataSource, DataSourceType, DATASOURCETYPES, EntitySelectionPopupSettings, TranslateService, WidgetConfigComponent } from '@ats/ats-platform-dashboard';
import { TagConfig } from '../../domain/entities/tagConfig';
import { ENERGY_MANAGEMENT_CATEGORIES } from '../../domain/enums/energy-management-categories';

@Component({
  selector: 'ats-smart-tool-tag-grouped-stacked-bar-chart-widget-config',
  templateUrl: './tag-grouped-stacked-bar-chart-widget-config.component.html',
  styleUrls: ['./tag-grouped-stacked-bar-chart-widget-config.component.scss']
})
export class TagGroupedStackedBarChartWidgetConfigComponent implements WidgetConfigComponent {

  public dataSourceSelectionPopupSettings: EntitySelectionPopupSettings;
  public dataSourceFormatter = (dataSource: DataSource) => dataSource ? dataSource.Folder.Path + ' / ' + dataSource.Name : null;

  public fg: FormGroup;
  public formGroup: FormGroup;
  public categories: any[] = [];
  public dataSourceItemsLoading: boolean = false;

  private dataSourceTypes: { [type: string]: DataSourceType };
  private currentDataSource: DataSource = null;

  public timeUnits = [
    { Key: 7, Display: this.translate.get('i18n:VIEW_STATIC_LIST.TIMEUNIT.AUTO') },
    { Key: 5, Display: this.translate.get('i18n:VIEW_STATIC_LIST.TIMEUNIT.QUARTERHOUR') },
    { Key: 6, Display: this.translate.get('i18n:VIEW_STATIC_LIST.TIMEUNIT.HOUR') },
    { Key: 0, Display: this.translate.get('i18n:VIEW_STATIC_LIST.TIMEUNIT.DAY') },
    { Key: 1, Display: this.translate.get('i18n:VIEW_STATIC_LIST.TIMEUNIT.WEEK') },
    { Key: 2, Display: this.translate.get('i18n:VIEW_STATIC_LIST.TIMEUNIT.MONTH') },
    { Key: 3, Display: this.translate.get('i18n:VIEW_STATIC_LIST.TIMEUNIT.QUARTER') },
    { Key: 4, Display: this.translate.get('i18n:VIEW_STATIC_LIST.TIMEUNIT.YEAR') }
  ];

  constructor(private core: CoreService, public translate: TranslateService, @Inject(DATASOURCETYPES) dataSourceTypes: DataSourceType[][], private injector: Injector) {

    this.dataSourceTypes = keyBy(flatten(dataSourceTypes), (type: DataSourceType) => type.name);

    this.formGroup = new FormGroup({
      title: new FormControl(''),
      dataSourceId: new FormControl(null, Validators.required),
      groupBy: new FormControl(null, Validators.required),
      selectedCategories: new FormControl(null),
      leftMargin: new FormControl(null, Validators.compose([Validators.required, Validators.min(1)])),
      rightMargin: new FormControl(null, Validators.compose([Validators.required, Validators.min(1)])),
      topMargin: new FormControl(null, Validators.compose([Validators.required, Validators.min(1)])),
      bottomMargin: new FormControl(null, Validators.compose([Validators.required, Validators.min(1)])),
      numberOfDecimals: new FormControl(0, Validators.min(0)),
      trendView: new FormControl(true),
      widgetType: new FormControl(translate.get('TAG_GROUPED_STACKED_BAR_CHART_WIDGET'))
    });

    this.dataSourceSelectionPopupSettings = {
      title: translate.get('i18n:SELECT_DATASOURCE'),
      entitySet: 'DataSources',
      filter: {
        logic: 'or',
        filters: [
          { field: 'Type', operator: 'eq', value: 'productionConsumption' }, { field: 'Type', operator: 'eq', value: 'energyManagement' },
        ]
      },
      includes: 'Configs,Folder',
      sort: [{ field: 'Folder.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
      multiSelect: false,
      selectionRequired: true,
      columns: [{ field: 'Folder.Path', title: this.translate.get('i18n:FOLDER.PATH'), filterable: true }, { field: 'Name', title: translate.get('i18n:DATASOURCE.NAME'), filterable: true }],
      info: translate.get('i18n:WIDGET.SUPPORTED_DATATYPES') + ': ' + translate.get('i18n:DATASOURCE.PRODUCTION_CONSUMPTION')
    };
  }

  public onDataSourceChange = (dataSource: DataSource) => {
    if (dataSource) {
      this.currentDataSource = dataSource;
      const dataSourceType: DataSourceType = this.dataSourceTypes[dataSource.Type];
      switch (dataSourceType.name) {
        case "productionConsumption":
          this.dataSourceItemsLoading = true;
          var tagConfigJson = dataSource.Configs.filter(x => x.Name === "Tags")[0].Value;
          var tagConfig: TagConfig[] = JSON.parse(tagConfigJson);
          this.categories = 
            [...new Map(tagConfig.map(x => ({Name: x.Category, Display: x.Category})).map(item =>
            [item["Name"], item])).values()];

          var selectedCategories = this.formGroup.get('selectedCategories').value;
          var allCategories = map(this.categories, c => c.Name);
          if (allCategories && allCategories.length && selectedCategories && selectedCategories.length) {
            selectedCategories = intersection(allCategories, selectedCategories);
            if (!selectedCategories || !selectedCategories.length)
              selectedCategories = clone(allCategories);
          } else {
            selectedCategories = clone(allCategories);
          }
          this.formGroup.get('selectedCategories').setValue(selectedCategories);

          this.dataSourceItemsLoading = false;
          break;
        case "energyManagement":
          this.categories = this.core.translateObjectArray(ENERGY_MANAGEMENT_CATEGORIES, 'Display');

          var selectedCategories = this.formGroup.get('selectedCategories').value;
          var allCategories = map(this.categories, c => c.Name);
          if (allCategories && allCategories.length && selectedCategories && selectedCategories.length) {
            selectedCategories = intersection(allCategories, selectedCategories);
            if (!selectedCategories || !selectedCategories.length)
              selectedCategories = clone(['electricityMix']);
          } else {
            selectedCategories = clone(['electricityMix']);
          }
          this.formGroup.get('selectedCategories').setValue(selectedCategories);

          break;
        default:
          this.categories = [];
          this.formGroup.get('selectedCategories').setValue([]);
      }
    } else {
      this.categories = [];
      this.formGroup.get('selectedCategories').setValue([]);
    }
  };

  public isValid(): boolean {
    this.formGroup.markAllAsTouched();
    return this.formGroup.valid;
  }

  public getConfig(): any {
    return {
      title: this.formGroup.get('title').value,
      dataSourceId: this.formGroup.get('dataSourceId').value,
      groupBy: this.formGroup.get('groupBy').value,
      selectedCategories: this.formGroup.get('selectedCategories').value,
      leftMargin: this.formGroup.get('leftMargin').value,
      rightMargin: this.formGroup.get('rightMargin').value,
      topMargin: this.formGroup.get('topMargin').value,
      bottomMargin: this.formGroup.get('bottomMargin').value,
      numberOfDecimals: this.formGroup.get('numberOfDecimals').value,
      trendView: this.formGroup.get('trendView').value,
    };
  }

  public getDocuments(): DashboardWidgetDocument[] {
    return null;
  }

  public setData(config: any, documents: DashboardWidgetDocument[]) {
    this.formGroup.get('title').setValue(config?.title ?? '');
    this.formGroup.get('dataSourceId').setValue(config?.dataSourceId);
    this.formGroup.get('groupBy').setValue(config?.groupBy ?? 7);
    this.formGroup.get('selectedCategories').setValue(config?.selectedCategories);
    this.formGroup.get('leftMargin').setValue(config?.leftMargin);
    this.formGroup.get('rightMargin').setValue(config?.rightMargin);
    this.formGroup.get('topMargin').setValue(config?.topMargin);
    this.formGroup.get('bottomMargin').setValue(config?.bottomMargin);
    this.formGroup.get('numberOfDecimals').setValue(config?.numberOfDecimals ?? 0);
    this.formGroup.get('trendView').setValue(config?.trendView);
  }

  public onOpen(event: any): void {
    if (this.formGroup.get('selectedCategories').value.length > 0 && this.currentDataSource.Type === "energyManagement") {
      event.preventDefault();
    }
  }
}
