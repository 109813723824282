import { MeasurementUnit } from "../entities/measurementUnit";

export class GroupedStackedBarChartData {
  StackItemInfoArray: StackItemInfo[] = [];
  GroupedStackItemData: any[] = [];
  //Category1
    //StackItemData1
    //StackItemData2
    //...
  //Category2
    //StackItemData1
    //StackItemData2
    //...  
}

export class StackItemInfo {
  Id: string;
  Name: string;
  MeasurementUnit: MeasurementUnit;
  Category: string;
  Color: string;
  PathName: string;
}