import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AddEvent, CellClickEvent, CellCloseEvent } from "@progress/kendo-angular-grid";
import { ApiService, ApiQueryOptions, DataSource, DataSourceConfig, DataSourceComponent, TranslateService, EntitySelectionPopupSettings, CoreService } from "@ats/ats-platform-dashboard";

import { find, clone, remove } from "lodash-es";
import { v4 as uuidv4 } from 'uuid';
import { BaseTag } from "../../domain/entities/baseTag";

@Component({
    selector: 'ats-smarttool-datasource-time-in-comfort',
    templateUrl: './datasource-time-in-comfort.component.html',
    styleUrls: ['./datasource-time-in-comfort.component.scss']
})
export class DataSourceTimeInComfortComponent implements DataSourceComponent {

    dataSource: DataSource;

    public formGroup: FormGroup;
    public items: DataSourceTimeInComfortItem[];
    public selectedItemKeys: string[] = [];

    public tagSelectionPopupSettings: EntitySelectionPopupSettings;
    public tagFormatter = (tag: BaseTag) => tag?.Name;

    public gridErr: string="";

    constructor(public translate: TranslateService, private api: ApiService, private core: CoreService) {
        this.tagSelectionPopupSettings = {
            title: translate.get('i18n:SELECT_TAG'),
            entitySet: 'UserBaseTags',
            singleEntitySet: 'BaseTags',
            includes: 'Asset,MeasurementUnit',
            sort: [{ field: 'Asset.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
            multiSelect: false,
            selectionRequired: true,
            columns: [
                { field: 'Asset.Path', title: translate.get('i18n:TAG.ASSET'), filterable: true },
                { field: 'Name', title: translate.get('i18n:TAG.NAME'), filterable: true },
                { field: 'MeasurementUnit.Name', title: translate.get('i18n:TAG.MEASUREMENT_UNIT'), filterable: true },
                { field: 'MeasurementUnit.Symbol', title: translate.get('i18n:TAG.MEASUREMENT_UNIT.SYMBOL'), filterable: true }
            ]
        };
    }

    setDataSource(dataSource: DataSource, formGroup: FormGroup): FormGroup {
        this.dataSource = dataSource;

      if (formGroup) {
        this.formGroup = formGroup;
      } else {
        const configs = this.dataSource?.Configs;
        
        const itemsConfig = find(configs, (x: DataSourceConfig) => x.Name === 'Items');
        this.items = itemsConfig ? itemsConfig.Data : [];  

        this.formGroup = new FormGroup({         
        });
      }

        return this.formGroup;
    }

    getDataSource(): DataSource {
        const dataSource = new DataSource();
        dataSource.Configs = [];

        const configs = this.dataSource?.Configs;
        const itemsConfig = find(configs, (x: DataSourceConfig) => x.Name === 'Items');

        dataSource.Configs.push({Id: itemsConfig ? itemsConfig.Id : uuidv4(), Name: 'Items', Value: JSON.stringify(this.items, ['Id','Name','ThermalComfortTagId','RelHumidityComfortTagId','CO2ComfortTagId','OverallComfortTagId','HVACModeTagId']), Encrypt: false, Encrypted: false, Data: null});

        return dataSource;
    }

    markAllAsTouched() {
        this.formGroup.markAllAsTouched();
    }

    isValid(): boolean {
        return this.formGroup.valid;
    }
    hasGridErr(): boolean {
        this.gridErr = this.getFirstGridErrorLoc();
        return this.gridErr.length > 0;
    }

    getFirstGridErrorLoc(): string {
        var isRequired = ' ' + this.translate.get('i18n:MESSAGE.FIELD_IS_REQUIRED');

        for (let i = 0; i < this.items?.length; ++i) {
            if (this.items[i].Name.trim().length == 0) return this.translate.get('i18n:DATASOURCE.TIME_IN_COMFORT.NAME') + isRequired;
        }
        return '';
    }

    public addItem() {
        let item = new DataSourceTimeInComfortItem();
        item.Id = uuidv4();
        item.Name = '';

        this.items.push(item);
    }

    public removeItems() {
        if (this.items && this.items.length && this.selectedItemKeys && this.selectedItemKeys.length) {
            const ids: string[] = clone(this.selectedItemKeys);
            ids.forEach((id: string) => {
                remove(this.items, (item: DataSourceTimeInComfortItem) => item.Id == id);
            });
            this.selectedItemKeys = [];
        }
    }

    public getTagDescription(item: DataSourceTimeInComfortItem, key): string {
        if (item && item[key]) {
            return item[key].Asset.Path + ' - ' + item[key].Name;
        }
        else if (item[key]?.Id) {
            return this.translate.get('i18n:LABEL.LOADING');
        } else {
            return '';
        }
    }

    public openTagSelectionPopup(item: DataSourceTimeInComfortItem, key: string, title: string) {
        this.core.showEntitySelectionPopup({
            title: this.translate.get('i18n:LABEL.TAG_SELECTION'),
            entitySet: 'UserBaseTags',
            singleEntitySet: 'BaseTags',
            includes: 'Asset.AssetType,MeasurementUnit.Measurement',
            sort: [{ field: 'Asset.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
            multiSelect: false,
            selectionRequired: false,
            columns: [
                { field: 'Asset.Path', title: this.translate.get('i18n:TAG.ASSET'), filterable: true },
                { field: 'Name', title: this.translate.get('i18n:TAG.NAME'), filterable: true },
                { field: 'MeasurementUnit.Name', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT'), filterable: true },
                { field: 'MeasurementUnit.Symbol', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT.SYMBOL'), filterable: true }
            ]
        }).subscribe({
            next: result => {
                if (result && result.length == 1) {
                    item[key] = result[0];
                    item[key + 'Id'] = item[key].Id;
                }
            }
        });
    }

    public clearTag(item: DataSourceTimeInComfortItem, key: string) {
        item[key] = null;
        item[key + 'Id'] = null;
    }

    public addHandler(e: AddEvent) {
        if (!e.dataItem.Id)
            e.dataItem.Id = uuidv4();
    }

    public cellClickHandler(e: CellClickEvent) {
        if (!e.isEdited && e.column.field == 'Name') {
            e.sender.editCell(e.rowIndex, e.columnIndex, this.createFormGroup(e.dataItem));
        }
    }

    public cellCloseHandler(e: CellCloseEvent) {
        if (!e.formGroup.valid) {
            // prevent closing the edited cell if there are invalid values.
            e.preventDefault();
        } else if (e.formGroup.dirty) {

            e.dataItem.Name = e.formGroup.get('Name').value;
        }
    }

    public createFormGroup(dataItem: DataSourceTimeInComfortItem) {
        return new FormGroup({
            Name: new FormControl(dataItem.Name, Validators.required)
        });
    }
}

export class DataSourceTimeInComfortItem {
    Id: string;
    Name: string;
    ThermalComfortTagId: string;
    ThermalComfortTag: BaseTag;
    RelHumidityComfortTagId: string;
    RelHumidityComfortTag: BaseTag;
    CO2ComfortTagId: string;
    CO2ComfortTag: BaseTag;
    OverallComfortTagId: string;
    OverallComfortTag: BaseTag;
    HVACModeTagId: string;
    HVACModeTag: BaseTag;
}