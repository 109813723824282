import { Component, ViewChild, ViewContainerRef } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Report } from "../../domain/entities/report";
import { AccessRights, ApiDataSource, CoreService, DataSource, EntitySelectionPopupSettings, Folder, TranslateService } from "@ats/ats-platform-dashboard";
import { ReportDefinition } from "../../domain/entities/reportDefinition";
import { ReportParameter } from "../../domain/entities/reportParameter";
import { ReportParameterValue } from "../../domain/entities/reportParameterValue";

import { ReportDefinitionDataSource } from "../../domain/entities/reportDefinitionDataSource";
import { ReportDataSource } from "../../domain/entities/reportDataSource";
import { downloadReportCommand } from "../../domain/commands/downloadReportCommand";

@Component({
    selector: 'ats-smart-tool-report-download-popup',
    templateUrl: './report-download-popup.component.html',
    styleUrls: ['./report-download-popup.component.scss']
})
export class ReportDownloadPopupComponent {
    
    public report: Report;
    public reportDefinition: ReportDefinition;

    public formGroup: FormGroup;
    public dataSourceSelectionPopupSettings: { [key: string]: EntitySelectionPopupSettings };   
    public dataSourceFormatter = (dataSource: DataSource) => dataSource?.Folder?.Path + ' / ' + dataSource?.Name;
    public reportDefinitionSelectionPopupSettings: EntitySelectionPopupSettings;
    public reportDefinitionFormatter = (reportDefinition: ReportDefinition) => reportDefinition.Name;

    @ViewChild('reportContainer', {read: ViewContainerRef}) public reportContainer: ViewContainerRef;

    constructor(private core: CoreService, public translate: TranslateService) { 
        this.formGroup = new FormGroup({});

        this.reportDefinitionSelectionPopupSettings = {
          title: this.translate.get('i18n:SELECT_REPORT_DEFINITION'),
          entitySet: 'ReportDefinitions',
          includes: 'Parameters,DataSources',
          filter: { field: 'DataType', operator: 'neq', value: 2},
          sort: [{ field: 'Name', dir: 'asc' }],
          multiSelect: false,
          selectionRequired: true,
          columns: [{ field: 'Name', title: this.translate.get('i18n:FOLDER.NAME'), filterable: true }] // TODO: add other columns
        };
    }

    public init(reportDefinition: ReportDefinition, report: Report) {
      this.formGroup = new FormGroup({ _name: new FormControl( report?.Name ?? reportDefinition?.Name, Validators.required )});
      this.dataSourceSelectionPopupSettings = {};

      this.report = report;
      this.reportDefinition = reportDefinition;
      this.updateParametersAndDataSources(report.ReportDefinition, report);
    }

    public onReportDefinitionChanged(reportDefinition: ReportDefinition) {
      this.reportDefinition = reportDefinition;
      this.updateParametersAndDataSources(reportDefinition, null);
    }

    private updateParametersAndDataSources(reportDefinition: ReportDefinition, report: Report)
    {
      if (reportDefinition && reportDefinition.Parameters && reportDefinition.Parameters.length) {
        reportDefinition.Parameters.forEach((parameter: ReportParameter) => {
          if (!this.formGroup.get(parameter.Id))
            this.formGroup.addControl(parameter.Id, new FormControl(null, parameter.IsRequired ? Validators.required : null));
        });

        if (report && report.ParameterValues && report.ParameterValues.length) {
          report.ParameterValues.forEach((parameterValue: ReportParameterValue) => {
            const formControl = this.formGroup.get(parameterValue.ReportParameterId);
            if (formControl && parameterValue.Value) {
              const value = JSON.parse(parameterValue.Value);

              switch (parameterValue.ReportParameter?.Type) {
                case 1:
                  formControl.setValue(value.intValue);
                  break;
                case 2:
                  formControl.setValue(value.floatValue);
                  break;
                case 3:
                case 5:
                  formControl.setValue(value);
                  break;
                case 4:
                  formControl.setValue(value.boolValue);
                  break;
                default:
                case 0:
                  formControl.setValue(value.text);
                  break;
              }
            }
          });
        }
      }

      if (reportDefinition && reportDefinition.DataSources && reportDefinition.DataSources.length) {
        reportDefinition.DataSources.forEach((dataSource: ReportDefinitionDataSource) => {
          if (!this.formGroup.get(dataSource.Id))
            this.formGroup.addControl(dataSource.Id, new FormControl(null, dataSource.IsRequired ? Validators.required : null));

          this.dataSourceSelectionPopupSettings[dataSource.Id] = {
            title: dataSource.Name,
            entitySet: 'DataSources',
            includes: 'Folder',
            filter: { field: 'Type', operator: 'eq', value: dataSource.DataSourceType },
            sort: [{ field: 'Folder.Path', dir: 'asc'}, { field: 'Name', dir: 'asc' }],
            multiSelect: false,
            selectionRequired: dataSource.IsRequired,
            columns: [
                { field: 'Folder.Path', title: this.translate.get('i18n:FOLDER.PATH'), filterable: true },
                { field: 'Name', title: this.translate.get('i18n:DATASOURCE.NAME'), filterable: true }
            ]
          };
        });

        if (report && report.DataSources && report.DataSources.length) {
          report.DataSources.forEach((dataSource: ReportDataSource) => {
            const formControl = this.formGroup.get(dataSource.ReportDefinitionDataSourceId);
            if (formControl)
              formControl.setValue(dataSource.DataSourceId);
          });
        }
      }
    }

    public getCommand(): downloadReportCommand {
      let command = new downloadReportCommand();
      command.Name = this.formGroup.get('_name').value;
      command.ReportDefinitionId = this.reportDefinition.Id;
      command.ReportId = this.report?.Id;
        
        if (this.reportDefinition && this.reportDefinition.Parameters && this.reportDefinition.Parameters.length) {
          command.ParameterValues = {};

          this.reportDefinition.Parameters.forEach((parameter: ReportParameter) => {
            const formControl = this.formGroup.get(parameter.Id);
            switch (parameter.Type) {
              case 1:
                command.ParameterValues[parameter.Name] = { dataType: parameter.Type, reportParameterId: parameter.Id, intValue: formControl?.value };
                break;
              case 2:
                command.ParameterValues[parameter.Name] = { dataType: parameter.Type, reportParameterId: parameter.Id, floatValue: formControl?.value };
                break;
              case 3:
              case 5:
                const value = formControl?.value ?? {};
                value.dataType = parameter.Type;
                value.reportParameterId = parameter.Id;
                command.ParameterValues[parameter.Name] = value;
                break;
              case 4:
                command.ParameterValues[parameter.Name] = { dataType: parameter.Type, reportParameterId: parameter.Id, boolValue: formControl?.value };
                break;
              default:
                command.ParameterValues[parameter.Name] = { dataType: parameter.Type, reportParameterId: parameter.Id, text: formControl.value?.toString() };
                break;
            }
          });
        }

        if (this.reportDefinition && this.reportDefinition.DataSources && this.reportDefinition.DataSources.length) {
          command.DataSources = {};

          this.reportDefinition.DataSources.forEach((dataSource: ReportDefinitionDataSource) => {
            const formControl = this.formGroup.get(dataSource.Id);
            if (formControl)
            command.DataSources[dataSource.Name] = formControl.value;
          });
        }

        return command;
    }

    public isValid(): boolean {
        this.formGroup.markAllAsTouched();

        return this.formGroup.valid;
    }
}