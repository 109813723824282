import { BaseSvg } from "@ats/ats-platform-dashboard"; 
import { ScaleLinear, format, scaleLinear, max, axisBottom, axisLeft, select } from 'd3';;
import { round } from "lodash-es";
import { RegressionParameters } from "../domain/dataModels/buildingEnergyData";
import { DateTime } from "luxon";

export class LinearRegression extends BaseSvg<LinearRegressionData> {

  public valueTypeLabel: string;
  public numberOfDecimalsXValue: number;
  public numberOfDecimalsYValue: number;
  public unitXValue: string = null; // cover in case no unit is passed
  public unitYValue: string = null; // cover in case no unit is passed
  public hideLabels: boolean;
  public hideRegressionLine: boolean;
  public categories: string[];
  public circleColor: string;
  public regressionLineColor: string;

  private xScale: ScaleLinear<number, number, never>;
  private yScale: ScaleLinear<number, number, never>;
  private formatValueXValue: (n: number | { valueOf(): number }) => string;
  private formatValueYValue: (n: number | { valueOf(): number }) => string;
  private xValueLabelText: string;
  private linearRegressionData: LinearRegressionData;

  constructor() {
    super();
  }

  public draw(LinearRegressionData: LinearRegressionData) {
    if (!LinearRegressionData)
      return;

    this.formatValueXValue = format('.' + this.numberOfDecimalsXValue + 'f');
    this.formatValueYValue = format('.' + this.numberOfDecimalsYValue + 'f');
    this.group.selectAll('*').remove();
    this.linearRegressionData = LinearRegressionData;

    this.createScales();
    this.drawAxis();
    this.drawCircle();
    if (!this.hideRegressionLine && this.linearRegressionData.regressionParameters && this.linearRegressionData.regressionParameters.Slope) {
      this.drawLine();
    }
  }

  private createScales() {
    this.xScale = scaleLinear()
      .domain([0, max(this.linearRegressionData.data, (d) => d.x)])
      .range([this.left, this.width - this.right])
      .nice();

    this.yScale = scaleLinear()
      .domain([0, max(this.linearRegressionData.data, (d) => d.y)])
      .range([this.height - this.bottom, this.top])
      .nice();
  }

  private drawAxis() {
    const fontSize = 11;
    this.xValueLabelText = '';
    let yValueLabelText: string = '';
    const unitXValuePresent = (this.unitXValue) ? this.xValueLabelText = this.unitXValue : this.xValueLabelText = '';
    const unitYValuePresent = (this.unitYValue) ? yValueLabelText = this.valueTypeLabel + ' (' + this.unitYValue + ')' : yValueLabelText = this.valueTypeLabel;


    const bottomAxis = this.group
      .append('g')
      .attr('id', 'BottomAxis')
      .attr('transform', 'translate(0,' + (this.height - this.bottom) + ')')
      .call(
        axisBottom(this.xScale)
          .ticks((this.width - this.left - this.right) / 100)
          .tickFormat((d,i) => this.formatValueXValue(d))
          .tickPadding(3)
          .tickSizeOuter(0)
      )
    if (!this.hideLabels) {
      bottomAxis
        .append('text')
        .attr('x', (this.left + ((this.width - this.left - this.right) / 2)))
        .attr('y', this.bottom - fontSize)
        .attr('dy', '0.32em')
        .attr('fill', '#000000')
        .attr('font-weight', 'bold')
        .attr('text-anchor', 'middle')
        .attr('font-size', fontSize)
        .text(this.xValueLabelText);
    }

    const leftAxis = this.group
      .append('g')
      .attr('id', 'LeftAxis')
      .attr('transform', 'translate(' + this.left + ', 0)')
      .call(
        axisLeft(this.yScale)
          .ticks((this.height - this.bottom - this.top) / 40)
          .tickFormat((d,i) => this.formatValueYValue(d))
          .tickPadding(3)
          .tickSizeOuter(0)
      )
    if (!this.hideLabels) {
      leftAxis
        .append('text')
        .attr('transform', 'rotate(-90)')
        .attr('y', - this.left + fontSize)
        .attr('x', - ((this.height) / 2))
        .attr('dy', '0.32em')
        .attr('fill', '#000000')
        .attr('font-weight', 'bold')
        .attr('text-anchor', 'middle')
        .attr('font-size', fontSize)
        .text(yValueLabelText)
        .call(this.wrap, (this.height - this.top - this.bottom));
    }
  }

  private drawCircle() {

    let rows = this.group
      .selectAll('.datapoint')
      .data(this.linearRegressionData.data)
      .enter()
      .append('circle')
      .attr('class', 'datapoint')
      .attr('cx', (d) => this.xScale(d.x))
      .attr('cy', (d) => this.yScale(d.y))
      .attr('r', 3)
      .style('fill', this.circleColor)
      .on("mouseover", (e: any, d: any) => {

        let yValueText = '';
        let xValueText = '';
        if (this.unitXValue && this.unitXValue) {
          yValueText = this.formatValueYValue(d.y) + " (" + this.unitYValue + ")";
          xValueText = this.formatValueXValue(d.x) + " (" + this.unitXValue + ")";
        } else {
          yValueText = this.formatValueYValue(d.y);
          xValueText = this.formatValueXValue(d.x);
        }

        //Remove all child elements
        var tooltipContainer = document.getElementById("tooltipContainer");
        //global style
        tooltipContainer.style.position = 'absolute';
        tooltipContainer.style.borderStyle = 'solid';
        tooltipContainer.style.borderColor = 'lightgrey';
        tooltipContainer.style.borderWidth = '1px';
        tooltipContainer.style.padding = '3px';
        tooltipContainer.style.background = 'white';
        tooltipContainer.style.fontSize = '12px';

        //specific style
        tooltipContainer.style.display = "inline";
        tooltipContainer.className = "valueTooltip";

        var tooltipHtml = '';

        //timestamp or label if present
        tooltipHtml += '<div style="text-align: center"><b>' + DateTime.fromJSDate(d.label).toFormat('dd-LL-yyyy HH:mm') + '</b></div>';

        tooltipHtml += '<table>';
        //property kolom
        tooltipHtml += '<td style="white-space:nowrap;">';
        tooltipHtml += '<div>' + this.valueTypeLabel + ':</div>';
        tooltipHtml += '</td>';
        //value & unit kolom
        tooltipHtml += '<td style="white-space:nowrap;">';
        tooltipHtml += '<div>' + yValueText + '</div>';
        tooltipHtml += '</td>';
        tooltipHtml += '</tr>';

        tooltipHtml += '<tr>';
        //property kolom
        tooltipHtml += '<td style="white-space:nowrap;">';
        tooltipHtml += '<div>' + this.xValueLabelText + ':</div>';
        tooltipHtml += '</td>';
        //value & unit kolom
        tooltipHtml += '<td style="white-space:nowrap;">';
        tooltipHtml += '<div>' + xValueText + '</div>';
        tooltipHtml += '</td>';
        tooltipHtml += '</tr>';

        tooltipHtml += '</table>';
        tooltipContainer.innerHTML = tooltipHtml;
        // #endregion

      })
      .on("mouseout", (e: any, d: any) => {
        var tooltipContainer = document.getElementById("tooltipContainer");
        tooltipContainer.innerHTML = '';
        tooltipContainer.style.display = "none";
        select(e.currentTarget).transition()
          .attr('r', 3)
          .style('fill', this.circleColor);
      })
      .on("mousemove", (e: any, d: any) => {
        var tooltipContainer = document.getElementById("tooltipContainer");
        tooltipContainer.setAttribute('transform', 'translate(' + e.pageX + ',' + e.pageY + ')');
        this.handleTooltipPosition(tooltipContainer,e);
        tooltipContainer.style.background = 'white';
        tooltipContainer.style.opacity = '0.95';
        select(e.currentTarget).transition()
          .attr('r', 4)
          .style('fill', 'orange');
      })
  }

  private drawLine() {

    if (!this.linearRegressionData.regressionParameters || !this.hideRegressionLine) {
      this.group
        .append('g')
        .append('line')
        .attr('x1', this.xScale(this.xScale.domain()[0]))
        .attr('y1', this.yScale(this.linearRegressionData.regressionParameters.YIntercept + this.linearRegressionData.regressionParameters.Slope * this.xScale.domain()[0]))
        .attr('x2', this.xScale(this.xScale.domain()[1]))
        .attr('y2', this.yScale(this.linearRegressionData.regressionParameters.YIntercept + this.linearRegressionData.regressionParameters.Slope * this.xScale.domain()[1]))
        .style('stroke', this.regressionLineColor)
        .style('stroke-width', 2)
        .on("mouseover", (e: any, d: any) => {

          // #region tooltip

          //Remove all child elements
          var tooltipContainer = document.getElementById("tooltipContainer");
          //global style
          tooltipContainer.style.position = 'absolute';
          tooltipContainer.style.borderStyle = 'solid';
          tooltipContainer.style.borderColor = 'lightgrey';
          tooltipContainer.style.borderWidth = '1px';
          tooltipContainer.style.padding = '3px';
          tooltipContainer.style.background = 'white';
          tooltipContainer.style.fontSize = '12px';

          //specific style
          tooltipContainer.style.display = "inline";
          tooltipContainer.className = "regressionLineTooltip";

          var tooltipHtml = '';

          tooltipHtml += '<table>';
          tooltipHtml += '<tr>';

          //property kolom
          tooltipHtml += '<td style="white-space:nowrap;">';
          tooltipHtml += '<div>Regression Line: </div>';
          tooltipHtml += '</td>';
          //value & unit kolom
          tooltipHtml += '<td style="white-space:nowrap;">';
          tooltipHtml += '<div>y = ' + round(this.linearRegressionData.regressionParameters.YIntercept, 1) + ' + ' + round(this.linearRegressionData.regressionParameters.Slope, 1) + ' * x</div>';
          tooltipHtml += '</td>';
          tooltipHtml += '</tr>';

          tooltipHtml += '</table>';
          tooltipContainer.innerHTML = tooltipHtml;
          // #endregion

        })
        .on("mouseout", (e: any, d: any) => {
          var tooltipContainer = document.getElementById("tooltipContainer");
          tooltipContainer.innerHTML = '';
          tooltipContainer.style.display = "none";
          select(e.currentTarget).transition()
            .style('stroke-width', 2)
        })
        .on("mousemove", (e: any, d: any) => {
          var tooltipContainer = document.getElementById("tooltipContainer");
          tooltipContainer.setAttribute('transform', 'translate(' + e.pageX + ',' + e.pageY + ')');
          this.handleTooltipPosition(tooltipContainer,e);
          tooltipContainer.style.background = 'white';
          tooltipContainer.style.opacity = '0.95';
          select(e.currentTarget).transition()
            .style('stroke-width', 4)
        })

    }
  }
}
export class LinearRegressionData {
  data: {
    label: Date;
    x: number;
    y: number;
  }[]
  regressionParameters: RegressionParameters;
}