import { WidgetInstance, WidgetType } from "@ats/ats-platform-dashboard";
import { TagCircularGaugeWidgetConfigComponent } from "../components/tag-circular-gauge-widget-config/tag-circular-gauge-widget-config.component";
import { TagCircularGaugeWidgetComponent } from "../components/tag-circular-gauge-widget/tag-circular-gauge-widget.component";
import { TagLinearGaugeWidgetConfigComponent } from "../components/tag-linear-gauge-widget-config/tag-linear-gauge-widget-config.component";
import { TagLinearGaugeWidgetComponent } from "../components/tag-linear-gauge-widget/tag-linear-gauge-widget.component";
import { TagGroupedStackedBarChartWidgetConfigComponent } from "../components/tag-grouped-stacked-bar-chart-widget-config/tag-grouped-stacked-bar-chart-widget-config.component";
import { TagGroupedStackedBarChartWidgetComponent } from "../components/tag-grouped-stacked-bar-chart-widget/tag-grouped-stacked-bar-chart-widget.component";
import { TagValueWidgetConfigComponent } from "../components/tag-value-widget-config/tag-value-widget-config.component";
import { TagValueWidgetComponent } from "../components/tag-value-widget/tag-value-widget.component";
import { TagsValueWidgetConfigComponent } from "../components/tags-value-widget-config/tags-value-widget-config.component";
import { TagsValueWidgetComponent } from "../components/tags-value-widget/tags-value-widget.component";
import { WeatherForecastDailyWidgetConfigComponent } from "../components/weather-forcast-daily-widget-config/weather-forecast-daily-widget-config.component";
import { WeatherForecastDailyWidgetComponent } from "../components/weather-forecast-daily-widget/weather-forecast-daily-widget.component";
import { TagPieChartWidgetComponent } from "../components/tag-pie-chart-widget/tag-pie-chart-widget.component";
import { TagPieChartWidgetConfigComponent } from "../components/tag-pie-chart-widget-config/tag-pie-chart-widget-config.component";
import { TagLegendWidgetComponent } from "../components/tag-legend-widget/tag-legend-widget.component";
import { TagLegendWidgetConfigComponent } from "../components/tag-legend-widget-config/tag-legend-widget-config.component";
import { TagLineChartWidgetComponent } from "../components/tag-line-chart-widget/tag-line-chart-widget.component";
import { TagLineChartWidgetConfigComponent } from "../components/tag-line-chart-widget-config/tag-line-chart-widget-config.component";
import { WeatherForecastHourlyWidgetConfigComponent } from "../components/weather-forcast-hourly-widget-config/weather-forecast-hourly-widget-config.component";
import { WeatherForecastHourlyWidgetComponent } from "../components/weather-forecast-hourly-widget/weather-forecast-hourly-widget.component";
import { BuildingEnergyLinearRegressionWidgetConfigComponent } from "../components/building-energy-linear-regression-widget-config/building-energy-linear-regression-widget-config.component";
import { BuildingEnergyLinearRegressionWidgetComponent } from "../components/building-energy-linear-regression-widget/building-energy-linear-regression-widget.component";
import { BuildingEnergyHorizStackedBarChartWidgetConfigComponent } from "../components/building-energy-horiz-stacked-bar-chart-widget-config/building-energy-horiz-stacked-bar-chart-widget-config.component";
import { BuildingEnergyHorizStackedBarChartWidgetComponent } from "../components/building-energy-horiz-stacked-bar-chart-widget/building-energy-horiz-stacked-bar-chart-widget.component";
import { BuildingEnergyVertStackedBarChartWidgetConfigComponent } from "../components/building-energy-vert-stacked-bar-chart-widget-config/building-energy-vert-stacked-bar-chart-widget-config.component";
import { BuildingEnergyVertStackedBarChartWidgetComponent } from "../components/building-energy-vert-stacked-bar-chart-widget/building-energy-vert-stacked-bar-chart-widget.component";
import { ChartWidgetConfigComponent } from "../components/chart-widget-config/chart-widget-config.component";
import { ChartWidgetComponent } from "../components/chart-widget/chart-widget.component";
import { ReportWidgetComponent } from "../components/report-widget/report-widget.component";
import { ReportWidgetConfigComponent } from "../components/report-widget-config/report-widget-config.component";
import { EVChargingConstraintChartWidgetComponent } from "../components/ev-charging-constraint-chart-widget/ev-charging-constraint-chart-widget.component";
import { EVChargingConstraintChartWidgetConfigComponent } from "../components/ev-charging-constraint-chart-widget-config/ev-charging-constraint-chart-widget-config.component";
import { EVChargingConstraintWidgetConfigComponent } from "../components/ev-charging-constraint-widget-config/ev-charging-constraint-widget-config.component";
import { EVChargingConstraintWidgetComponent } from "../components/ev-charging-constraint-widget/ev-charging-constraint-widget.component";
import { EVChargersWidgetComponent } from "../components/ev-chargers-widget/ev-chargers-widget.component";
import { EVChargersWidgetConfigComponent } from "../components/ev-chargers-widget-config/ev-chargers-widget-config.component";
import { EnergyDistributionWidgetComponent } from "../components/energy-distribution-widget/energy-distribution-widget.component";
import { EnergyDistributionWidgetConfigComponent } from "../components/energy-distribution-widget-config/energy-distribution-widget-config.component";
import { DeviceTopologyWidgetComponent } from "../components/device-topology-widget/device-topology-widget.component";
import { DeviceTopologyWidgetConfigComponent } from "../components/device-topology-widget-config/device-topology-widget-config.component";
import { EVChargingSessionsWidgetComponent } from "../components/ev-charging-sessions-widget/ev-charging-sessions-widget.component";
import { EVChargingSessionsWidgetConfigComponent } from "../components/ev-charging-sessions-widget-config/ev-charging-sessions-widget-config.component";
import { EVChargersMapWidgetComponent } from "../components/ev-chargers-map-widget/ev-chargers-map-widget.component";
import { EVChargersMapWidgetConfigComponent } from "../components/ev-chargers-map-widget-config/ev-chargers-map-widget-config.component";
import { HeatMapWidgetComponent } from "../components/heat-map-widget/heat-map-widget.component";
import { HeatMapWidgetConfigComponent } from "../components/heat-map-widget-config/heat-map-widget-config.component";

export const chartWidgetType: WidgetType = {
    name: "chart",
    description: "i18n:CHART_WIDGET",
    component: ChartWidgetComponent,
    configComponent: ChartWidgetConfigComponent,
    getUsedDataSourceIds: null
  };

export const tagCircularGaugeWidgetType: WidgetType = {
    name: "tag-circular-gauge",
    description: "i18n:TAG_CIRCULAR_GAUGE_WIDGET",
    component: TagCircularGaugeWidgetComponent,
    configComponent: TagCircularGaugeWidgetConfigComponent,
    getUsedDataSourceIds: (widgetInstance: WidgetInstance) => {
        if (widgetInstance.widgetConfig?.dataSourceId) {
            return [widgetInstance.widgetConfig.dataSourceId];
        } else {
            return null;
        }
    }
};

export const tagLinearGaugeWidgetType: WidgetType = {
    name: "tag-linear-gauge",
    description: "i18n:TAG_LINEAR_GAUGE_WIDGET",
    component: TagLinearGaugeWidgetComponent,
    configComponent: TagLinearGaugeWidgetConfigComponent,
    getUsedDataSourceIds: (widgetInstance: WidgetInstance) => {
        if (widgetInstance.widgetConfig?.dataSourceId) {
            return [widgetInstance.widgetConfig.dataSourceId];
        } else {
            return null;
        }
    }
};

export const tagValueWidgetType: WidgetType = {
    name: "tag-value",
    description: "i18n:TAG_VALUE_WIDGET",
    component: TagValueWidgetComponent,
    configComponent: TagValueWidgetConfigComponent,
    getUsedDataSourceIds: (widgetInstance: WidgetInstance) => {
        if (widgetInstance.widgetConfig?.dataSourceId) {
            return [widgetInstance.widgetConfig.dataSourceId];
        } else {
            return null;
        }
    }
};

export const tagsValueWidgetType: WidgetType = {
    name: "tags-value",
    description: "i18n:TAGS_VALUE_WIDGET",
    component: TagsValueWidgetComponent,
    configComponent: TagsValueWidgetConfigComponent,
    getUsedDataSourceIds: (widgetInstance: WidgetInstance) => {
        if (widgetInstance.widgetConfig?.dataSourceId) {
            return [widgetInstance.widgetConfig.dataSourceId];
        } else {
            return null;
        }
    }
};

export const WeatherForecastDailyWidgetType: WidgetType = {
    name: "weather-forecast-daily",
    description: "i18n:WEATHER_FORECAST_DAILY_WIDGET",
    component: WeatherForecastDailyWidgetComponent,
    configComponent: WeatherForecastDailyWidgetConfigComponent,
    getUsedDataSourceIds: (widgetInstance: WidgetInstance) => {
        if (widgetInstance.widgetConfig?.dataSourceId) {
            return [widgetInstance.widgetConfig.dataSourceId];
        } else {
            return null;
        }
    }
};

export const tagGroupedStackedBarChartWidgetType: WidgetType = {
    name: "tag-grouped-stacked-bar-chart-value",
    description: "i18n:TAG_GROUPED_STACKED_BAR_CHART_WIDGET",
    component: TagGroupedStackedBarChartWidgetComponent,
    configComponent: TagGroupedStackedBarChartWidgetConfigComponent,
    getUsedDataSourceIds: (widgetInstance: WidgetInstance) => {
        if (widgetInstance.widgetConfig?.dataSourceId) {
            return [widgetInstance.widgetConfig.dataSourceId];
        } else {
            return null;
        }
    }
};

export const energyDistributionWidgetType: WidgetType = {
  name: "energy-distribution",
  description: "i18n:ENERGY_DISTRIBUTION_WIDGET",
  component: EnergyDistributionWidgetComponent,
  configComponent: EnergyDistributionWidgetConfigComponent,
  getUsedDataSourceIds: (widgetInstance: WidgetInstance) => {
      if (widgetInstance.widgetConfig?.dataSourceId) {
          return [widgetInstance.widgetConfig.dataSourceId];
      } else {
          return null;
      }
  }
};

export const deviceTopologyWidgetType: WidgetType = {
  name: "device-topology",
  description: "i18n:DEVICE_TOPOLOGY_WIDGET",
  component: DeviceTopologyWidgetComponent,
  configComponent: DeviceTopologyWidgetConfigComponent,
  getUsedDataSourceIds: (widgetInstance: WidgetInstance) => {
      if (widgetInstance.widgetConfig?.dataSourceId) {
          return [widgetInstance.widgetConfig.dataSourceId];
      } else {
          return null;
      }
  }
};

export const evChargersWidgetType: WidgetType = {
  name: "ev-chargers",
  description: "i18n:EV_CHARGERS",
  component: EVChargersWidgetComponent,
  configComponent: EVChargersWidgetConfigComponent,
  getUsedDataSourceIds: (widgetInstance: WidgetInstance) => {
      if (widgetInstance.widgetConfig?.dataSourceId) {
          return [widgetInstance.widgetConfig.dataSourceId];
      } else {
          return null;
      }
  }
};

export const evChargersMapWidgetType: WidgetType = {
  name: "ev-chargers-map",
  description: "i18n:EV_CHARGERS_MAP",
  component: EVChargersMapWidgetComponent,
  configComponent: EVChargersMapWidgetConfigComponent,
  getUsedDataSourceIds: (widgetInstance: WidgetInstance) => {
      if (widgetInstance.widgetConfig?.dataSourceId) {
          return [widgetInstance.widgetConfig.dataSourceId];
      } else {
          return null;
      }
  }
};

export const tagPieChartWidgetType: WidgetType = {
    name: "tag-pie-chart-value",
    description: "i18n:DONUT_CHART_WIDGET",
    component: TagPieChartWidgetComponent,
    configComponent: TagPieChartWidgetConfigComponent,
    getUsedDataSourceIds: (widgetInstance: WidgetInstance) => {
        if (widgetInstance.widgetConfig?.dataSourceId) {
            return [widgetInstance.widgetConfig.dataSourceId];
        } else {
            return null;
        }
    }
};

export const tagLegendWidgetType: WidgetType = {
    name: "tag-legend-value",
    description: "i18n:TAG_LEGEND_WIDGET",
    component: TagLegendWidgetComponent,
    configComponent: TagLegendWidgetConfigComponent,
    getUsedDataSourceIds: (widgetInstance: WidgetInstance) => {
        if (widgetInstance.widgetConfig?.dataSourceId) {
            return [widgetInstance.widgetConfig.dataSourceId];
        } else {
            return null;
        }
    }
}


export const WeatherForecastHourlyWidgetType: WidgetType = {
    name: "weather-forecast-hourly",
    description: "i18n:WEATHER_FORECAST_HOURLY_WIDGET",
    component: WeatherForecastHourlyWidgetComponent,
    configComponent: WeatherForecastHourlyWidgetConfigComponent,
    getUsedDataSourceIds: (widgetInstance: WidgetInstance) => {
        if (widgetInstance.widgetConfig?.dataSourceId) {
            return [widgetInstance.widgetConfig.dataSourceId];
        } else {
            return null;
        }
    }
};

export const tagLineChartWidgetType: WidgetType = {
    name: "tag-line-chart-value",
    description: "i18n:TAG_LINE_CHART_WIDGET",
    component: TagLineChartWidgetComponent,
    configComponent: TagLineChartWidgetConfigComponent,
    getUsedDataSourceIds: (widgetInstance: WidgetInstance) => {
        if (widgetInstance.widgetConfig?.dataSourceId) {
            return [widgetInstance.widgetConfig.dataSourceId];
        } else {
            return null;
        }
    }
};

export const buildingEnergyLinearRegressionWidgetType: WidgetType = {
    name: "building-energy-linear-regression",
    description: "i18n:BUILDING_ENERGY_LINEAR_REGRESSION_WIDGET",
    component: BuildingEnergyLinearRegressionWidgetComponent,
    configComponent: BuildingEnergyLinearRegressionWidgetConfigComponent,
    getUsedDataSourceIds: (widgetInstance: WidgetInstance) => {
        if (widgetInstance.widgetConfig?.dataSourceId) {
            return [widgetInstance.widgetConfig.dataSourceId];
        } else {
            return null;
        }
    }
};

export const buildingEnergyHorizStackedBarChartWidgetType: WidgetType = {
    name: "building-energy-horiz-stacked-bar-chart",
    description: "i18n:BUILDING_ENERGY_HORIZ_STACKED_BAR_CHART_WIDGET",
    component: BuildingEnergyHorizStackedBarChartWidgetComponent,
    configComponent: BuildingEnergyHorizStackedBarChartWidgetConfigComponent,
    getUsedDataSourceIds: (widgetInstance: WidgetInstance) => {
        if (widgetInstance.widgetConfig?.dataSourceId) {
            return [widgetInstance.widgetConfig.dataSourceId];
        } else {
            return null;
        }
    }
};

export const buildingEnergyVertStackedBarChartWidgetType: WidgetType = {
    name: "building-energy-vert-stacked-bar-chart",
    description: "i18n:BUILDING_ENERGY_VERT_STACKED_BAR_CHART_WIDGET",
    component: BuildingEnergyVertStackedBarChartWidgetComponent,
    configComponent: BuildingEnergyVertStackedBarChartWidgetConfigComponent,
    getUsedDataSourceIds: (widgetInstance: WidgetInstance) => {
        if (widgetInstance.widgetConfig?.dataSourceId) {
            return [widgetInstance.widgetConfig.dataSourceId];
        } else {
            return null;
        }
    }
};

export const heatMapWidgetType: WidgetType = {
    name: "heat-map",
    description: "i18n:HEAT_MAP_WIDGET",
    component: HeatMapWidgetComponent,
    configComponent: HeatMapWidgetConfigComponent,
    getUsedDataSourceIds: (widgetInstance: WidgetInstance) => {
        if (widgetInstance.widgetConfig?.dataSourceId) {
            return [widgetInstance.widgetConfig.dataSourceId];
        } else {
            return null;
        }
    }
};

export const reportWidgetType: WidgetType = {
    name: "report",
    description: "i18n:REPORT_WIDGET",
    component: ReportWidgetComponent,
    configComponent: ReportWidgetConfigComponent,
    getUsedDataSourceIds: null
};

export const evChargingConstraintWidgetType: WidgetType = {
    name: "evChargingConstraint",
    description: "i18n:EV_CHARGING_CONSTRAINT",
    component: EVChargingConstraintWidgetComponent,
    configComponent: EVChargingConstraintWidgetConfigComponent,
    getUsedDataSourceIds: (widgetInstance: WidgetInstance) => {
        if (widgetInstance.widgetConfig?.dataSourceId) {
            return [widgetInstance.widgetConfig.dataSourceId];
        } else {
            return null;
        }
    }
};

export const evChargingConstraintChartWidgetType: WidgetType = {
    name: "evChargingConstraintChart",
    description: "i18n:EV_CHARGING_CONSTRAINT_CHART",
    component: EVChargingConstraintChartWidgetComponent,
    configComponent: EVChargingConstraintChartWidgetConfigComponent,
    getUsedDataSourceIds: (widgetInstance: WidgetInstance) => {
        if (widgetInstance.widgetConfig?.dataSourceId) {
            return [widgetInstance.widgetConfig.dataSourceId];
        } else {
            return null;
        }
    }
};

export const evChargingSessionsWidgetType: WidgetType = {
    name: "evChargingSessions",
    description: "i18n:EV_CHARGING_SESSIONS",
    component: EVChargingSessionsWidgetComponent,
    configComponent: EVChargingSessionsWidgetConfigComponent,
    getUsedDataSourceIds: (widgetInstance: WidgetInstance) => {
        if (widgetInstance.widgetConfig?.historyOrActive === 1 && widgetInstance.widgetConfig?.dataSourceId) {
            return [widgetInstance.widgetConfig.dataSourceId];
        } else {
            return null;
        }
    }
}