import { Component } from '@angular/core';
import { keyBy } from "lodash-es";
import { v4 as uuidv4 } from 'uuid';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService, CoreService, DashboardWidgetDocument, EntitySelectionPopupSettings, TranslateService, WidgetConfigComponent } from '@ats/ats-platform-dashboard';
import { Report } from '../../domain/entities/report';

@Component({
  selector: 'ats-smart-tool-report-widget-config',
  templateUrl: './report-widget-config.component.html',
  styleUrls: ['./report-widget-config.component.scss']
})
export class ReportWidgetConfigComponent implements WidgetConfigComponent {
  
  private documentsByKey: { [key: string]: DashboardWidgetDocument };
  
  public reportSelectionPopupSettings: EntitySelectionPopupSettings;
  public reportFormatter = (report: Report) => report?.Name;

  public formGroup: FormGroup;

  public svgEditorOptions = { theme: 'vs', language: 'xml' };
  
  constructor(public core: CoreService, public translate: TranslateService, public api: ApiService) {
    this.formGroup = new FormGroup({
      title: new FormControl(''),
      widgetType: new FormControl(translate.get('REPORT_WIDGET')),
      reportId: new FormControl(null, Validators.required),
      useDashboardDateTime: new FormControl(true, Validators.required),
      svgContent: new FormControl('')
    });

    this.reportSelectionPopupSettings = {
      title: translate.get('i18n:SELECT_REPORT'),
      entitySet: 'Reports',
      includes: 'ReportDefinition',
      sort: [{ field: 'ReportDefinition.Name', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
      multiSelect: false,
      selectionRequired: true,
      columns: [
        { field: 'ReportDefinition.Name', title: translate.get('i18n:REPORT.REPORTDEFINITION'), filterable: true },
        { field: 'Name', title: translate.get('i18n:REPORT.NAME'), filterable: true }
      ]
    }
  }

  public isValid(): boolean {
    this.formGroup.markAllAsTouched();
    return this.formGroup.valid;
  }

  public getConfig(): any {
    return {
      title: this.formGroup.get('title').value,
      reportId: this.formGroup.get('reportId').value,
      useDashboardDateTime: this.formGroup.get('useDashboardDateTime').value
    };
  }

  public getDocuments(): DashboardWidgetDocument[] {
    let result: DashboardWidgetDocument[] = [];

    const svgContent: string = this.formGroup.get('svgContent').value;
    if (svgContent && svgContent.length)
    if (this.documentsByKey['svgContent']) {
      this.documentsByKey['svgContent'].Content = svgContent;
      result.push(this.documentsByKey['svgContent']);
    } else {
      result.push({ Id: uuidv4(), Name: 'svgContent', Content: svgContent });
    }

    return result;
  }

  public setData(config: any, documents: DashboardWidgetDocument[]) {
    this.formGroup.get('title').setValue(config?.title ?? '');
    this.formGroup.get('reportId').setValue(config?.reportId);
    this.formGroup.get('useDashboardDateTime').setValue(config?.useDashboardDateTime);

    this.documentsByKey = (documents && documents.length) ? keyBy(documents, 'Name') : {};

    if (this.documentsByKey['svgContent']) {
      this.formGroup.get('svgContent').setValue(this.documentsByKey['svgContent'].Content);
    } else {
      this.formGroup.get('svgContent').setValue('');
    }
  }
}
