import { DashboardService, TranslateService } from "@ats/ats-platform-dashboard";
import { CircularGauge, CircularGaugeSettings } from "./circular-gauge";
import { GroupedStackedBarChart } from "./grouped-stacked-bar-chart";
import { HeatMap } from "./heat-map";
import { HorizStackedBarChart } from "./horiz-stacked-bar-chart";
import { LineChart } from "./line-chart";
import { LinearGauge, LinearGaugeSettings } from "./linear-gauge";
import { LinearRegression } from "./linear-regression";
import { PieChart } from "./pie-chart";
import { TagValueText } from "./tag-value-text";
import { VertStackedBarChart } from "./vert-stacked-bar-chart";
import { WeatherForecastDaily } from "./weather-forecast-daily";
import { WeatherForecastHourly } from "./weather-forecast-hourly";

export function BaseSvgContextInitializer(context: any) {
    context.baseSvg = {
        drawCircularGauge: (settings: CircularGaugeSettings) => { return new CircularGauge(settings); },
        drawLinearGauge: (settings: LinearGaugeSettings) => { return new LinearGauge(settings); },
        drawDailyWeatherForecast: () => { return new WeatherForecastDaily(); },
        drawHourlyWeatherForecast: () => { return new WeatherForecastHourly(); },
        drawGroupedStackBarChart: (dashboardService: DashboardService) => { return new GroupedStackedBarChart(dashboardService) },
        drawHeatMap: () => { return new HeatMap() },
        drawHorizStackBarChart: () => { return new HorizStackedBarChart() },
        drawLineChart: () => { return new LineChart() },
        drawLinearRegression: () => { new LinearRegression() },
        drawPieChart: () => { return new PieChart() },
        drawTagValueText: (translate: TranslateService) => { return new TagValueText(translate) },
        drawVertStackBarChart: () => { return new VertStackedBarChart() }
    };
};