import { BaseSvg, DashboardConfig, DashboardService } from '@ats/ats-platform-dashboard';
import { ScaleBand, ScaleLinear, Selection, scaleBand, scaleLinear, format, axisBottom, axisLeft } from 'd3';;
import { round, capitalize, isString } from "lodash-es";
import { DateTime } from 'luxon';
import { GroupedStackedBarChartData } from '../domain/dataModels/groupedStackedBarChartData';
//http://bl.ocks.org/caged/6476579
export class GroupedStackedBarChart extends BaseSvg<any> {

  //Content level info + values
  public data: GroupedStackedBarChartData = new GroupedStackedBarChartData();

  //Chart level info
  public maxYValue: number;
  public minYValue: number;
  public selectedCategories: string[] = [];
  public groupBy: number;
  public numberOfDecimals: number;
  public trendView: boolean;
  public measurementUnit: string = '';
  public getAutoGroupBy: (from: number, to: number, groupBy: number) => number;

  //Private info
  private x: ScaleBand<string>;
  private y: ScaleLinear<number, number, never>;
  private xAxisWidth: number;
  private yAxisHeight: number;
  private formatValue: (n: number | { valueOf(): number }) => string;
  private stackedFormat: string;
  private localGroupBy: number;

  constructor(private dashboardService: DashboardService) {
    super();
  }

  public init(group: Selection<SVGGElement, unknown, HTMLElement, unknown>) {
    this.group = group;
  }

  public draw() {
    this.group.selectAll("*").remove();
    this.group.selectChildren('*').remove();
    this.group.html("");

    if (this.data.GroupedStackItemData[0] == null || this.data.GroupedStackItemData[0].length === 0) {
      return
    }

    this.xAxisWidth = this.width - this.left - this.right;
    this.yAxisHeight = this.height - this.top - this.bottom;
    this.formatValue = format('.' + this.numberOfDecimals + 'f');

    var fromTimestamp = Math.min.apply(null, this.data?.GroupedStackItemData[0][0].map(x => isString(x.data?.TimeStamp) ? DateTime.fromISO(x.data?.TimeStamp) : DateTime.fromJSDate(x.data?.TimeStamp)));
    var toTimestamp = Math.max.apply(null, this.data?.GroupedStackItemData[0][0].map(x => isString(x.data?.TimeStamp) ? DateTime.fromISO(x.data?.TimeStamp) : DateTime.fromJSDate(x.data?.TimeStamp)));

    this.localGroupBy = this.getAutoGroupBy(fromTimestamp, toTimestamp, this.groupBy);

    if (this.localGroupBy == 5 || this.localGroupBy == 6) {
      this.stackedFormat = 'dd-LL-yyyy HH:mm:ss';
    } else if (this.localGroupBy == 0 || this.localGroupBy == 1) {
      this.stackedFormat = 'dd-LL-yyyy';
    } else if (this.localGroupBy == 2 || this.localGroupBy == 3) {
      this.stackedFormat = 'LLL-yyyy';
    } else if (this.localGroupBy == 4) {
      this.stackedFormat = 'yyyy';
    }

    this.createAxis();
    this.drawAxis();
    this.drawBars();
  }

  private createAxis() {
    this.x = scaleBand()
      .domain(this.data?.GroupedStackItemData[0][0].map(x => DateTime.fromJSDate(x.data?.TimeStamp).toFormat(this.stackedFormat)))
      .range([0, this.xAxisWidth])
      .paddingInner(0.3)
      .paddingOuter(0.2);

    this.y = scaleLinear()
      .domain([round(this.minYValue, this.numberOfDecimals), round(this.maxYValue, this.numberOfDecimals)])
      .range([(this.yAxisHeight), 0])
      .nice();
  }

  private drawAxis() {
    const fontSize: number = 12;
    const valueLabelText: string = this.measurementUnit;
    const tickReduction = round(this.x.domain().length / ((this.xAxisWidth) / 100), 0);
    let numberOfBottumTicks = 0;

    if (!this.trendView) {
      const gBottom = this.group
        .append('g')
        .attr('transform', 'translate(' + this.left + ',' + (this.top + this.yAxisHeight) + ')')
        .call(axisBottom(this.x)
          .tickValues(
            this.x.domain().filter(function (d, i) {
              return !(i % tickReduction);
            }))
          .tickSizeOuter(0)
          .tickPadding(3)
          .ticks(this.xAxisWidth / 150));
      gBottom
        .selectAll<SVGTextElement, unknown>('.tick text')
        .each(function (d, i) { numberOfBottumTicks++ })
        .call(function (d, format) { DateTime.fromFormat(d.selection.toString(), "dd/LL/yyyy HH:mm:ss").toFormat(format) }, this.stackedFormat)
        .call(this.wrap, this.xAxisWidth / 150);

      const leftAxis = this.group
        .append('g')
        .attr('transform', 'translate(' + this.left + ',' + this.top + ')')
        .call(
          axisLeft(this.y)
            .ticks(this.yAxisHeight / 40)
            .tickPadding(3)
            .tickSizeOuter(0)
        )
      const leftAxisWidth = leftAxis.node().getBBox().width;

      const labelLeftAxis = leftAxis
        .append('text')
        .attr('transform', 'rotate(-90)')
        .attr('x', - ((this.yAxisHeight) / 2))
        .attr('dy', '0.32em')
        .attr('fill', '#000000')
        .attr('font-weight', 'bold')
        .attr('text-anchor', 'middle')
        .attr('font-size', fontSize)
        .text(valueLabelText)
        .call(this.wrap, (this.yAxisHeight));

      const labelLeftAxisHeight = labelLeftAxis.node().getBBox().height;

      labelLeftAxis
        .attr('y', - leftAxisWidth - labelLeftAxisHeight);
    }
  }

  private drawBars() {

    var stringArray: string[] = [];
    for (let index = 0; index < this.selectedCategories.length; index++) {
      stringArray.push(index.toLocaleString());
    }
    var localStackedFormat = this.stackedFormat.replace(/\s+|:/g, '-');

    var classNameFormatter = (d) => 'class-' + DateTime.fromJSDate(d.data?.TimeStamp).toFormat(localStackedFormat) + '-rect'

    let x1 = scaleBand()
      .domain(stringArray)
      .range([0, this.x.bandwidth()])
      .padding(0.05)

    let tempIndex = 0;
    this.data?.GroupedStackItemData.forEach(stackedDataItem => {
      var bars = this.group
        .selectAll('.bar')
        .data(stackedDataItem as any[])
        .enter()
        .append('g')
        .attr('transform', 'translate(' + this.left + ',' + this.top + ')')
        .selectAll('rect')
        .data(d => d as any[])
        .enter()
        .append('rect')
        .attr('class', d => classNameFormatter(d))
        .attr('x', d => this.x(DateTime.fromJSDate(d.data?.TimeStamp).toFormat(this.stackedFormat)) + x1(tempIndex.toLocaleString()))
        .attr('width', (stringArray.length > 1) ? x1.bandwidth() : this.x.bandwidth())
        .attr('y', d => this.y(d[1]))
        .attr('height', d => this.y(d[0]) - this.y(d[1]))
        .style('fill', d => this.getColorForItem(d))
        .on("mouseover", (d: any, selectedData: any) => {

          // #region tooltip
          var tagConfigCategoryArray = {};
          this.selectedCategories.forEach(category => {
            tagConfigCategoryArray[category] = this.data.StackItemInfoArray.filter(element => element.Category == category);
          });

          // //Remove all child elements
          var tooltipContainer = document.getElementById("tooltipContainer");
          //global style
          tooltipContainer.style.position = 'absolute';
          tooltipContainer.style.borderStyle = 'solid';
          tooltipContainer.style.borderColor = 'lightgrey';
          tooltipContainer.style.borderWidth = '1px';
          tooltipContainer.style.padding = '3px';
          tooltipContainer.style.background = 'white';
          tooltipContainer.style.fontSize = '12px';

          //specific style
          tooltipContainer.style.display = "inline";
          tooltipContainer.className = "valueTooltip";

          var tooltipHtml = '';

          //timestamp or label if present
          tooltipHtml += '<div style="text-align: center"><b>' + DateTime.fromJSDate(selectedData.data.TimeStamp).toFormat(this.stackedFormat) + '</b></div>';

          tooltipHtml += '<table>';
          this.selectedCategories.forEach((category, i) => {
            var emptyCategory = 'Category ' + (i + 1);
            tooltipHtml += '<tr>';
            //color kolom
            tooltipHtml += '<td style="width: 20px;">';
            tooltipHtml += '</td>';
            //property kolom
            tooltipHtml += '<td style="white-space:nowrap;">';
            tooltipHtml += '<div><strong><u>' + (category != '' ? capitalize(category) : emptyCategory) + ': </u></strong></div>';
            tooltipHtml += '</td>';
            //value & unit kolom
            tooltipHtml += '<td style="white-space:nowrap;">';
            tooltipHtml += '<div> </div>';
            tooltipHtml += '</td>';
            tooltipHtml += '</tr>';

            tagConfigCategoryArray[category].forEach(element => {
              var elementTagValue = selectedData.data[element.Id];
              var currentTag = this.data.StackItemInfoArray.find(tag => tag.Id == element.Id);
              var elementTagPathArray = currentTag.PathName.split(' / ');
              elementTagPathArray.push(currentTag.Name);
              var elementTagPathArrayLength = elementTagPathArray.length;
              var elementTagName = elementTagPathArray[elementTagPathArrayLength - 2] ? elementTagPathArray[elementTagPathArrayLength - 2] + ' / ' + elementTagPathArray[elementTagPathArrayLength - 1] : elementTagPathArray[elementTagPathArrayLength - 1];
              var selectedTag = (element.Id == selectedData.key) ? true : false;
              var percent = '%';

              tooltipHtml += '<tr>';
              //color kolom
              var colorRectStyle = 'height: 15px; width: 15px; background-color:' + element.Color + '; border:1px solid WhiteSmoke;';
              tooltipHtml += '<td style="width: 20px;">';
              tooltipHtml += "<div style='" + colorRectStyle + "'></div>";
              tooltipHtml += '</td>';
              //property or name kolom
              tooltipHtml += '<td style="white-space:nowrap;">';
              tooltipHtml += '<div>' + (selectedTag ? elementTagName.bold() : elementTagName) + ' : </div>';
              tooltipHtml += '</td>';
              //value & unit kolom
              tooltipHtml += '<td style="white-space:nowrap;">';
              tooltipHtml += '<div>' + (selectedTag ? this.formatValue(elementTagValue).bold() + ' ' + this.measurementUnit.bold() : this.formatValue(elementTagValue) + ' ' + this.measurementUnit) + '</div>';
              tooltipHtml += '</td>';
              tooltipHtml += '</tr>';
            });

            var totalValueCat = 'Total' + category;
            if (Object.keys(selectedData.data).find(x => x === totalValueCat)) {
              tooltipHtml += '<tr>';
              //color kolom
              tooltipHtml += '<td style="width: 20px;">';
              tooltipHtml += '</td>';
              //property kolom
              tooltipHtml += '<td style="white-space:nowrap;">';
              tooltipHtml += '<div>Total ' + category.toLowerCase() + ' : </div>';
              tooltipHtml += '</td>';
              //value & unit kolom
              tooltipHtml += '<td style="white-space:nowrap;">';
              tooltipHtml += '<div>' + this.formatValue(selectedData.data[totalValueCat]) + ' ' + this.measurementUnit + '</div>';
              tooltipHtml += '</td>';
              tooltipHtml += '</tr>';
            }
          });
          tooltipHtml += '</table>';
          tooltipContainer.innerHTML = tooltipHtml;
          // #endregion
        })
        .on("mouseout", (e: any, selectedData: any) => {
          this.group.selectAll('.' + classNameFormatter(selectedData))
            .style('opacity', '1')
            .style('cursor', 'default');

          var tooltipContainer = document.getElementById("tooltipContainer");
          tooltipContainer.innerHTML = '';
          tooltipContainer.style.display = "none";
        })
        .on("mousemove", (e: any, selectedData: any) => {

          var classRect = this.group.selectAll('.' + classNameFormatter(selectedData));
          classRect.style('opacity', '0.5');

          if (this.localGroupBy != 5 && this.localGroupBy != 6) {
            classRect.style('cursor', 'pointer');
          }

          var tooltipContainer = document.getElementById("tooltipContainer");
          tooltipContainer.setAttribute('transform', 'translate(' + e.pageX + ',' + e.pageY + ')');
          this.handleTooltipPosition(tooltipContainer, e);
          tooltipContainer.style.background = 'white';
          tooltipContainer.style.opacity = '0.95';
        })
        .on("click", (e: any, d: any) => {
          let config: DashboardConfig = this.dashboardService.config;
          if (config && d.data.TimeStamp) {
            if (this.localGroupBy == 5 || this.localGroupBy == 6) {
              e.stopPropagation
            } else {
              if (this.localGroupBy == 0) {
                this.localGroupBy = 6;
                config.to = { type: 0, fixedDateTime: DateTime.fromJSDate(d.data.TimeStamp).plus({ days: 1 }).toJSDate() };
              } else if (this.localGroupBy == 1) {
                this.localGroupBy = 0;
                config.to = { type: 0, fixedDateTime: DateTime.fromJSDate(d.data.TimeStamp).plus({ weeks: 1 }).toJSDate() };
              } else if (this.localGroupBy == 2) {
                this.localGroupBy = 0;
                config.to = { type: 0, fixedDateTime: DateTime.fromJSDate(d.data.TimeStamp).plus({ months: 1 }).toJSDate() };
              } else if (this.localGroupBy == 3) {
                this.localGroupBy = 2;
                config.to = { type: 0, fixedDateTime: DateTime.fromJSDate(d.data.TimeStamp).plus({ quarters: 1 }).toJSDate() };
              } else if (this.localGroupBy == 4) {
                this.localGroupBy = 2;
                config.to = { type: 0, fixedDateTime: DateTime.fromJSDate(d.data.TimeStamp).plus({ years: 1 }).toJSDate() };
              }
              config.from = { type: 0, fixedDateTime: new Date(d.data.TimeStamp) };
              config.period = { type: 1, offset: 0 };

              this.dashboardService.reload();

              this.dashboardService.widgetInstances.forEach((widgetInstance) => {
                if (widgetInstance.widgetConfig.hasOwnProperty("groupBy")) {
                  widgetInstance.widgetConfig.groupBy = 7;
                }
              })
              this.dashboardService.config = config;
            }
          }
        })
      tempIndex++;
    })
  }
  private getColorForItem(d: any): string {
    return this.data.StackItemInfoArray.filter(x => x.Id === d.key)[0].Color;
  }
}