import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AbstractResolver, ApiQueryOptions, ApiService } from '@ats/ats-platform-dashboard';
import { Observable, of as observableOf, map } from 'rxjs';
import { TagChart } from '../domain/entities/tagChart';

@Injectable()
export class ChartResolver extends AbstractResolver<TagChart> {

  constructor(private api: ApiService, private router: Router) {
    super();
  }

  protected resolveValue(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TagChart> {
    const queryOptions = new ApiQueryOptions({ include: 'Folder,Items,Asset,AssetType,AccessRights.User,AccessRights.Role'});

    if (!route.params['chartId'] ||route.params['chartId'] === 'new') {
      const chart = new TagChart();
      chart.Name = 'New chart'; // TODO: translate
      chart.Config = JSON.stringify({});

      const extras = this.router.getCurrentNavigation()?.extras;
      if (extras && extras.state) {
        if (extras.state['Name']) {
          chart.Name = extras.state['Name'];
        }
          
        if (extras.state['Config']) {
          chart.Config = extras.state['Config'];
        }

        if (extras.state['FolderId']) {
          chart.FolderId = extras.state['FolderId'];
        }

        if (extras.state['AccessRights']) {
          chart.AccessRights = extras.state['AccessRights'];
        }
      }

      return observableOf(chart);
    } else {
      var obs: Observable<TagChart> = this.api.getSingleResult('UserCharts', route.params['chartId'], queryOptions);

      const extras = this.router.getCurrentNavigation()?.extras;
      if (extras && extras.state && extras.state['periodSelection']) {
        obs = obs.pipe(map((value: TagChart) => {
          value.From = extras.state['periodSelection'].from;
          value.To = extras.state['periodSelection'].to;
          value.Period = extras.state['periodSelection'].period;
          return value;
        }));
      }

      if (route.params['assetId'] && route.params['assetId'].length) {
        obs = obs.pipe(map((value: TagChart) => {
          value.AssetId = route.params['assetId'];
          value.Asset = null;
          return value;
        }));
      }

      return obs;
    }
  }
}
