import { Injectable } from "@angular/core";
import { Observable, of, map } from "rxjs";
import { find } from "lodash-es";
import { AbstractDataSourceLoader, ApiQueryOptions, ApiService, CoreService, DataSource, DataSourceConfig, DataSourceInstance, DataSourceResult, PageResult } from "@ats/ats-platform-dashboard";
import { BaseTag } from "../domain/entities/baseTag";
import { TagHubService } from "./tag-hub.service";
import { TagValue } from "../domain/tagValue";
import { TagDataSourceItem } from "./tagsDataSourceLoader";

@Injectable({
    providedIn: 'root',
})
export class AssetTagsDataSourceLoader implements AbstractDataSourceLoader {

    private dataSourceInstances: { [id: string]: DataSourceInstance } = {};

    constructor(private api: ApiService, private core: CoreService, private tagHub: TagHubService) {
        this.tagHub.on('broadcastTagValue', this.onBroadcastTagValue);
    }

    public load(from: Date, to: Date, instance: DataSourceInstance): Observable<DataSourceResult> {
        if (!this.dataSourceInstances[instance.id]) {
            this.dataSourceInstances[instance.id] = instance;

            return this.tagHub.invoke<TagDataSourceItem[]>('SubscribeDataSource', instance.id).pipe(map((result: TagDataSourceItem[]) => {
                return {
                    isLoading: false,
                    data: result,
                    error: null,
                    contentType: 'application/json'
                };
            }));
        } else {
            return of(this.dataSourceInstances[instance.id].result);
        }
    }

    public unload(id: string): Observable<void> {
        delete this.dataSourceInstances[id];

        return this.tagHub.invoke<any>('UnsubscribeDataSource', id);
    }

    hasItems(): boolean {
        return true;
    }

    loadItems(dataSource: DataSource): Observable<{ id: string; name: string; }[]> {

        const assetIdConfig: DataSourceConfig = find(dataSource.Configs, (config: DataSourceConfig) => config.Name == 'assetId');
        const includeChildAssetsConfig: DataSourceConfig = find(dataSource.Configs, (config: DataSourceConfig) => config.Name == 'includeChildAssets');

        if (includeChildAssetsConfig && includeChildAssetsConfig.Value && includeChildAssetsConfig.Value.length && JSON.parse(includeChildAssetsConfig.Value)) {
            return this.api.executeFunction<PageResult<BaseTag>>('BaseTags', 'GetAllAssetTags', assetIdConfig.Value, new ApiQueryOptions({ include: 'Asset', sort: [{ field: 'Asset.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }] }))
                .pipe(map((pageResult: PageResult<BaseTag>) => pageResult.Items.map((item: BaseTag) => {
                    return {
                        id: item.Id,
                        name: item.Asset.Path + ' - ' + item.Name
                    };
                })));
        } else {
            return this.api.getPageResult<BaseTag>('BaseTags', new ApiQueryOptions({
                include: 'Asset',
                filter: { field: 'AssetId', operator: 'eq', value: assetIdConfig.Value },
                sort: [{ field: 'Asset.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }]
            })).pipe(map((pageResult: PageResult<BaseTag>) => pageResult.Items.map((item: BaseTag) => {
                return {
                    id: item.Id,
                    name: item.Asset.Path + ' - ' + item.Name
                };
            })));
        }
    }

    onBroadcastTagValue = (dataSourceId: string, tagValue: TagValue) => {
        const instance = this.dataSourceInstances[dataSourceId];
        if (instance && instance.result) {
            const item = find(<TagDataSourceItem[]>instance.result.data, (item: TagDataSourceItem) => item.TagId == tagValue.TagId && item.MeasurementUnitId == tagValue.MeasurementUnitId);
            if (item && (!item.LatestValue || item.LatestValue.TimeStamp <= tagValue.TimeStamp)) {
                item.LatestValue = tagValue;
                instance.fireItemValueChangeEvent(item.TagId, item);
            }
        }
    }

}