<form class="k-form" [formGroup]="formGroup">
    <fieldset class="k-form-fieldset">
        <kendo-formfield>
            <kendo-label [for]="EnergyMeasurementUnitId"
                text="{{ 'i18n:DATASOURCE.SOLAR_PERFORMANCE.ENERGY_MEASUREMENTUNITID' | translate }}">
            </kendo-label>
            <ats-platform-entity-lookup-textbox #EnergyMeasurementUnitId formControlName="EnergyMeasurementUnitId"
                [settings]="measurementUnitSelectionPopupSettings" [formatter]="measurementUnitFormatter">
            </ats-platform-entity-lookup-textbox>
            <kendo-formerror *ngIf="formGroup.controls['EnergyMeasurementUnitId'].errors?.['required']">{{
                'i18n:MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="IrradianceMeasurementUnitId"
                text="{{ 'i18n:DATASOURCE.SOLAR_PERFORMANCE.IRRADIANCE_MEASUREMENTUNITID' | translate }}">
            </kendo-label>
            <ats-platform-entity-lookup-textbox #IrradianceMeasurementUnitId
                formControlName="IrradianceMeasurementUnitId" [settings]="measurementUnitSelectionPopupSettings"
                [formatter]="measurementUnitFormatter">
            </ats-platform-entity-lookup-textbox>
            <kendo-formerror *ngIf="formGroup.controls['IrradianceMeasurementUnitId'].errors?.['required']">{{
                'i18n:MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="PeakPowerMeasurementUnitId"
                text="{{ 'i18n:DATASOURCE.SOLAR_PERFORMANCE.PEAKPOWER_MEASUREMENTUNITID' | translate }}">
            </kendo-label>
            <ats-platform-entity-lookup-textbox #PeakPowerMeasurementUnitId
                formControlName="PeakPowerMeasurementUnitId" [settings]="measurementUnitSelectionPopupSettings"
                [formatter]="measurementUnitFormatter">
            </ats-platform-entity-lookup-textbox>
            <kendo-formerror *ngIf="formGroup.controls['PeakPowerMeasurementUnitId'].errors?.['required']">{{
                'i18n:MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="PeakPowerParameterDefinitionId"
                text="{{ 'i18n:DATASOURCE.SOLAR_PERFORMANCE.PEAKPOWERPARAMETERDEFINITIONID' | translate }}"></kendo-label>
            <ats-platform-entity-lookup-textbox #PeakPowerParameterDefinitionId
                formControlName="PeakPowerParameterDefinitionId" [settings]="parameterDefinitionSelectionPopupSettings"
                [formatter]="parameterDefinitionFormatter"></ats-platform-entity-lookup-textbox>
            <kendo-formerror *ngIf="formGroup.controls['PeakPowerParameterDefinitionId'].errors?.['required']">{{
                'i18n:MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
    </fieldset>
    <fieldset class="k-form-fieldset">
        <legend class="k-form-legend">{{ 'i18n:DATASOURCE.TAGS' | translate }}</legend>
        <kendo-grid [data]="items" [height]="410" 
            [selectable]="{ mode: 'multiple', drag: true }" kendoGridSelectBy="AssetId"
            [(selectedKeys)]="selectedItemKeys">
            <ng-template kendoGridToolbarTemplate>
                <button kendoButton look="outline" (click)="openAssetSelectionPopup()">{{
                    'i18n:DATASOURCE.ASSET.ADD' |
                    translate }}</button>
                <button kendoButton look="outline" [disabled]="selectedItemKeys.length == 0"
                    (click)="removeItems()">{{ 'i18n:DATASOURCE.ASSET.REMOVE' | translate }}</button>
                <kendo-formerror *ngIf="hasGridErr()">{{gridErr}}</kendo-formerror>
            </ng-template>

            <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.SOLAR_PERFORMANCE.ASSET')"
                [field]="'AssetId'">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
                    {{ getAssetDescription(dataItem) }}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.SOLAR_PERFORMANCE.ESTIMATED_ENERGY')"
                [field]="'EstimatedEnergyTagId'" [style]="{ 'padding': '0px' }">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
                    <div style="display: flex; width: 100%; height: 100%; align-items: center;">
                        <div style="flex: 1; padding: 8px 4px 8px 12px;">{{ getEstimatedEnergyTagDescription(dataItem) }}</div>
                        <div style="flex: 0;">
                            <button kendoButton look="flat" icon="edit"
                                (click)="openEstimatedEnergySelectionPopup(dataItem)"></button>
                        </div>
                        <div style="flex: 0;"
                            *ngIf="dataItem.EstimatedEnergyTagId && dataItem.EstimatedEnergyTagId.length">
                            <button kendoButton look="flat" icon="close"
                                (click)="clearEstimatedEnergy(dataItem)"></button>
                        </div>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.SOLAR_PERFORMANCE.ACTUAL_ENERGY')"
                [field]="'ActualEnergyTagId'" [style]="{ 'padding': '0px' }">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
                    <div style="display: flex; width: 100%; height: 100%; align-items: center;">
                        <div style="flex: 1; padding: 8px 4px 8px 12px;">{{ getActualEnergyTagDescription(dataItem) }}</div>
                        <div style="flex: 0;">
                            <button kendoButton look="flat" icon="edit"
                                (click)="openActualEnergySelectionPopup(dataItem)"></button>
                        </div>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.SOLAR_PERFORMANCE.ESTIMATED_IRRADIANCE')"
                [field]="'EstimatedIrradianceTagId'" [style]="{ 'padding': '0px' }">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
                    <div style="display: flex; width: 100%; height: 100%; align-items: center;">
                        <div style="flex: 1; padding: 8px 4px 8px 12px;">{{ getEstimatedIrradianceTagDescription(dataItem) }}</div>
                        <div style="flex: 0;">
                            <button kendoButton look="flat" icon="edit"
                                (click)="openEstimatedIrradianceSelectionPopup(dataItem)"></button>
                        </div>
                        <div style="flex: 0;"
                            *ngIf="dataItem.EstimatedIrradianceTagId && dataItem.EstimatedIrradianceTagId.length">
                            <button kendoButton look="flat" icon="close"
                                (click)="clearEstimatedIrradiance(dataItem)"></button>
                        </div>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.SOLAR_PERFORMANCE.ACTUAL_IRRADIANCE')"
                [field]="'ActualIrradianceTagId'" [style]="{ 'padding': '0px' }">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
                    <div style="display: flex; width: 100%; height: 100%; align-items: center;">
                        <div style="flex: 1; padding: 8px 4px 8px 12px;">{{ getActualIrradianceTagDescription(dataItem) }}</div>
                        <div style="flex: 0;">
                            <button kendoButton look="flat" icon="edit"
                                (click)="openActualIrradianceSelectionPopup(dataItem)"></button>
                        </div>
                        <div style="flex: 0;"
                            *ngIf="dataItem.ActualIrradianceTagId && dataItem.ActualIrradianceTagId.length">
                            <button kendoButton look="flat" icon="close"
                                (click)="clearActualIrradiance(dataItem)"></button>
                        </div>
                    </div>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>   
    </fieldset>
</form>
