import { AfterViewInit, Component, ElementRef, Inject, Injector, Input, OnDestroy, OnInit, Type, ViewChild } from "@angular/core";
import { HubConnection } from "@aspnet/signalr";
import { DashboardService, DataSourceInstance, TranslateService, WidgetComponent, WidgetInstance, SvgContainerComponent, WidgetUpdater, ItemValueChangedEventArgs, ApiService, DataSourceType, DATASOURCETYPES, CoreService } from "@ats/ats-platform-dashboard";
import { Subscription } from "rxjs";
import * as _ from 'lodash';
import * as d3 from 'd3';
import { EnergyDistribution } from "../../svg/energy-distribution";
import { EnergyDistributionData } from "../../domain/dataModels/energyDistributionData";

//https://stackblitz.com/edit/d3-tooltip?embed=1&file=src/app/bar-chart.ts&hideNavigation=1s

@Component({
  selector: 'ats-smart-tool-energy-distribution-widget',
  templateUrl: './energy-distribution-widget.component.html',
  styleUrls: ['./energy-distribution-widget.component.scss']
})
export class EnergyDistributionWidgetComponent implements WidgetComponent, OnInit, AfterViewInit, OnDestroy {
  @Input() hubConnection: HubConnection;

  public widgetInstance: WidgetInstance;
  public isLoading = false;
  public error: string;
  public dataSourceItemsLoading: boolean = false;

  private widgetConfigSubscription: Subscription;
  private widgetUpdaters: WidgetUpdater[];
  private svg: SVGSVGElement;
  private dataSourceTypes: { [type: string]: DataSourceType };

  private div: HTMLDivElement;

  private drawer: EnergyDistribution;

  public svgContainerType: Type<SvgContainerComponent> = SvgContainerComponent;

  @ViewChild('svgContainer', { static: false }) svgContainer: ElementRef;

  constructor(private dashboardService: DashboardService, private translate: TranslateService, private api: ApiService, @Inject(DATASOURCETYPES) dataSourceTypes: DataSourceType[][], private injector: Injector, public core: CoreService) {
    this.dataSourceTypes = _.keyBy(_.flatten(dataSourceTypes), (type: DataSourceType) => type.name);
  }

  public setWidgetInstance(widgetInstance: WidgetInstance) {
    this.widgetInstance = widgetInstance;
  }

  ngOnInit() {
    this.widgetConfigSubscription = this.widgetInstance.changed.subscribe((instance: WidgetInstance) => {
      this.updateData(instance);
    })
  }

  ngAfterViewInit() {
    this.updateData(this.widgetInstance);
  }

  ngOnDestroy() {
    if (this.widgetConfigSubscription) {
      this.widgetConfigSubscription.unsubscribe();
      this.widgetConfigSubscription = null;
    }

    if (this.widgetUpdaters) {
      this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
      this.widgetUpdaters = null;
    }
  }

  protected updateData(instance: WidgetInstance) {
    instance.widgetData = {
      title: instance.widgetConfig.title
    };

    if (!this.svgContainer)
      return;

    this.div = this.svgContainer.nativeElement;
    const svgs = this.div.getElementsByTagName('svg');
    this.svg = svgs && svgs.length ? svgs.item(0) : null;
    if (!this.svg)
      return;

    const d3svg = d3.select(this.svg);

    d3svg.selectAll('g').remove();

    const g = d3svg.append('g');

    this.drawer = new EnergyDistribution(this.core);
    this.drawer.init(g);

    if (this.widgetUpdaters) {
      this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
    }

    const dataSourceInstance = _.find(this.dashboardService.dataSourceInstances, (dataSourceInstance: DataSourceInstance) => dataSourceInstance.id == instance.widgetConfig.dataSourceId);

    if (dataSourceInstance) {
      this.widgetUpdaters = [];

      this.widgetUpdaters.push(
        new WidgetUpdater(dataSourceInstance, this.svgContainer.nativeElement,
          (dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement) => {
            this.isLoading = dataSourceInstance?.result?.isLoading;

            if (dataSourceInstance.result && dataSourceInstance.result.data) {

              this.drawer.left = 0;
              this.drawer.right = 0;
              this.drawer.top = 0;
              this.drawer.bottom = 0;
              this.drawer.width = 900;
              this.drawer.height = 500;
              this.drawer.title = instance.widgetConfig.title;
              this.drawer.columns = 4;
              this.drawer.rows = 3;
              this.drawer.marginBetweenX = (this.drawer.width - this.drawer.left - this.drawer.right) / this.drawer.columns * 0.35;
              this.drawer.marginBetweenY = (this.drawer.height - this.drawer.top - this.drawer.bottom) / this.drawer.rows * 0.2;
              this.drawer.unitWidth = (this.drawer.width - this.drawer.left - this.drawer.right - (this.drawer.marginBetweenX * (this.drawer.columns - 1))) / this.drawer.columns;
              this.drawer.unitHeight = (this.drawer.height - this.drawer.top - this.drawer.bottom - (this.drawer.marginBetweenY * (this.drawer.rows - 1))) / this.drawer.rows;
              for (var r = 0; r < this.drawer.rows; r++) {
                this.drawer.mainPosY.push(this.drawer.top + this.drawer.unitHeight * r + this.drawer.marginBetweenY * r);
              }
              for (var c = 0; c < this.drawer.columns; c++) {
                this.drawer.mainPosX.push(this.drawer.left + this.drawer.unitWidth * c + this.drawer.marginBetweenX * c);
              }

              var summaryData: { [key: string]: number } = dataSourceInstance.result.data.EnergyMeterTypeSummaryData;
              var configData: any = dataSourceInstance.result.data.EnergyMeterTypeConfig;
              var unitCount = 0;

              var energyDistributionData: EnergyDistributionData = {
                Grid: {HasMeters: true, DisplayName: 'Grid', X: this.drawer.mainPosX[0], Y: this.drawer.mainPosY[1], Icon: {IconSymbol: 'electricityPole', IconScale: '0.07', Color: 'grey', Left: 5, Right: 0, Up: 0, Down: 0}, InValue: 0, OutValue: 0, CurrentValue: 0, G: null, TargetId: instance.widgetConfig.gridChartId},
                Solar: {HasMeters: false, DisplayName: 'Solar', X: this.drawer.mainPosX[1], Y:this.drawer.mainPosY[0], Icon: {IconSymbol: 'solarPanel', IconScale: '0.06', Color: 'grey', Left: 0, Right: 0, Up: 0, Down: 0}, InValue: 0, OutValue: 0, CurrentValue: 0, G: null, TargetId: instance.widgetConfig.solarChartId},
                Wind: {HasMeters: false, DisplayName: 'Wind', X: this.drawer.mainPosX[2], Y:this.drawer.mainPosY[0], Icon: {IconSymbol: 'wind', IconScale: '0.06', Color: 'grey', Left: 0, Right: 0, Up: 0, Down: 0}, InValue: 0, OutValue: 0, CurrentValue: 0, G: null, TargetId: instance.widgetConfig.windChartId},
                Battery: {HasMeters: false, DisplayName: 'Battery', X: this.drawer.mainPosX[1], Y:this.drawer.mainPosY[2], Icon: {IconSymbol: 'battery', IconScale: '0.06', Color: 'grey', Left: 0, Right: 0, Up: 5, Down: 0}, InValue: 0, OutValue: 0, CurrentValue: 0, G: null, TargetId: instance.widgetConfig.batteryChartId},
                EVCharger: {HasMeters: false, DisplayName: 'EV-Charger', X: this.drawer.mainPosX[2], Y:this.drawer.mainPosY[2], Icon: {IconSymbol: 'carCharger', IconScale: '0.06', Color: 'grey', Left: 0, Right: 0, Up: 0, Down: 0}, InValue: 0, OutValue: 0, CurrentValue: 0, G: null, TargetId: instance.widgetConfig.evChargerChartId},
                Building: {HasMeters: true, DisplayName: 'Building', X: this.drawer.mainPosX[3], Y:this.drawer.mainPosY[1], Icon: {IconSymbol: 'building', IconScale: '0.06', Color: 'grey', Left: 0, Right: 0, Up: 0, Down: 0}, InValue: 0, OutValue: 0, CurrentValue: 0, G: null, TargetId: instance.widgetConfig.buildingChartId},
              };

              for (let key in summaryData) {
                switch (key) {
                  case ('GridConsumption'): {
                    energyDistributionData.Grid.InValue = summaryData[key];
                    energyDistributionData.Building.InValue += summaryData[key];
                    energyDistributionData.Grid.HasMeters = true;
                    break;
                  }
                  case ('GridInjection'): {
                    energyDistributionData.Grid.OutValue = summaryData[key];
                    energyDistributionData.Building.InValue -= summaryData[key];
                    energyDistributionData.Grid.HasMeters = true;
                    break;
                  }
                  case ('Solar'): {
                    energyDistributionData.Solar.OutValue = summaryData[key];
                    energyDistributionData.Building.InValue += summaryData[key];
                    energyDistributionData.Solar.HasMeters = true;
                    break;
                  }
                  case ('Wind'): {
                    energyDistributionData.Wind.InValue = summaryData[key];
                    energyDistributionData.Building.InValue += summaryData[key];
                    energyDistributionData.Wind.HasMeters = true;
                    break;
                  }
                  case ('BatteryEnergyIn'): {
                    energyDistributionData.Battery.InValue = summaryData[key];
                    energyDistributionData.Building.InValue -= summaryData[key];
                    energyDistributionData.Battery.HasMeters = true;
                    break;
                  }
                  case ('BatteryEnergyOut'): {
                    energyDistributionData.Battery.OutValue = summaryData[key];
                    energyDistributionData.Building.InValue += summaryData[key];
                    energyDistributionData.Battery.HasMeters = true;
                    break;
                  }
                  case ('EVCharger'): {
                    energyDistributionData.EVCharger.InValue = summaryData[key];
                    energyDistributionData.Building.InValue -= summaryData[key];
                    energyDistributionData.EVCharger.HasMeters = true;
                    break;
                  }
                  default: {
                    break;
                  }
                }
                unitCount ++;
              }

              this.drawer.data = energyDistributionData;
              this.drawer.draw();

            }
            //generate title if not present, update happens twice first time title gets filled in with null as unit, then with correct unit value. this is the reason widgetconfig.title does not get updated
            if (!this.widgetInstance.widgetConfig.title) {
              this.widgetInstance.widgetData.title = this.translate.get('i18n:ENERGY_DISTRIBUTION_WIDGET') + ' (' + this.drawer.measurementUnit + ')';
            }
          },
          (dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement, event: ItemValueChangedEventArgs) => {
            //No need to change if data from item changed
          }));
    }
  }

  public cancel(e: any) {
    e.preventDefault();
    e.cancelBubble = true;
  }
  private 
}