import { Component, Inject, ViewChild, ViewContainerRef } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Report } from "../../domain/entities/report";
import { EntitySelectionPopupSettings, TranslateService } from "@ats/ats-platform-dashboard";
import { ReportDefinition } from "../../domain/entities/reportDefinition";
import { ReportParameter } from "../../domain/entities/reportParameter";
import { downloadReportCommand } from "../../domain/commands/downloadReportCommand";
import { TagChart } from "../../domain/entities/tagChart";


@Component({
  selector: 'ats-smart-tool-chart-to-report-popup',
  templateUrl: './chart-to-report-popup.component.html',
  styleUrls: ['./chart-to-report-popup.component.scss']
})
export class ChartToReportPopupComponent {

  public report: Report;
  public reportDefinition: ReportDefinition;

  public formGroup: FormGroup;
  public dataSourceSelectionPopupSettings: { [key: string]: EntitySelectionPopupSettings };
  public reportDefinitionSelectionPopupSettings: EntitySelectionPopupSettings;
  public reportDefinitionFormatter = (reportDefinition: ReportDefinition) => reportDefinition?.Name;

  @ViewChild('chartToReportContainer', { read: ViewContainerRef })
  public chartToReportContainer: ViewContainerRef;

  constructor(public translate: TranslateService) {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      reportDefinitionId: new FormControl(null, Validators.required),
      chartName: new FormControl(null, Validators.required)
    });

    this.reportDefinitionSelectionPopupSettings = {
      title: this.translate.get('i18n:SELECT_REPORT_DEFINITION'),
      entitySet: 'ReportDefinitions',
      filter: { field: 'DataType', operator: 'eq', value: 2 },
      includes: 'Parameters',
      sort: [{ field: 'Name', dir: 'asc' }],
      multiSelect: false,
      selectionRequired: true,
      columns: [{ field: 'Name', title: this.translate.get('i18n:FOLDER.NAME'), filterable: true }]
    };
  }

  public init(chart: TagChart) {
    this.formGroup.get('name').setValue("New report");
    this.formGroup.get('reportDefinitionId').setValue(this.reportDefinition?.Id);
    this.formGroup.get('chartName').setValue(chart.Name);
  }

  public onReportDefinitionChanged(reportDefinition: ReportDefinition) {
    this.reportDefinition = reportDefinition;
    if (reportDefinition && reportDefinition.Parameters && reportDefinition.Parameters.length) {
      reportDefinition.Parameters.forEach((parameter: ReportParameter) => {
        if (!this.formGroup.get(parameter.Id))
          this.formGroup.addControl(parameter.Id, new FormControl(null, parameter.IsRequired ? Validators.required : null));
      });
    }
  }

  public getCommand(): downloadReportCommand {
    let command = new downloadReportCommand();
    command.Name = this.formGroup.get('name').value;
    command.ReportDefinitionId = this.reportDefinition.Id;
    command.ReportId = this.report?.Id;

    if (this.reportDefinition && this.reportDefinition.Parameters && this.reportDefinition.Parameters.length) {
      command.ParameterValues = {};

      this.reportDefinition.Parameters.forEach((parameter: ReportParameter) => {
        const formControl = this.formGroup.get(parameter.Id);
        switch (parameter.Type) {
          case 1:
            command.ParameterValues[parameter.Name] = { dataType: parameter.Type, reportParameterId: parameter.Id, intValue: formControl?.value };
            break;
          case 2:
            command.ParameterValues[parameter.Name] = { dataType: parameter.Type, reportParameterId: parameter.Id, floatValue: formControl?.value };
            break;
          case 3:
          case 5:
            const value = formControl?.value ?? {};
            value.dataType = parameter.Type;
            value.reportParameterId = parameter.Id;
            command.ParameterValues[parameter.Name] = value;
            break;
          case 4:
            command.ParameterValues[parameter.Name] = { dataType: parameter.Type, reportParameterId: parameter.Id, boolValue: formControl?.value };
            break;
          default:
            command.ParameterValues[parameter.Name] = { dataType: parameter.Type, reportParameterId: parameter.Id, text: formControl.value?.toString() };
            break;
        }
      });
    }

    return command;
  }

  public isValid(): boolean {
    this.formGroup.markAllAsTouched();

    return this.formGroup.valid;
  }
}