import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import {
  LayoutComponent,
  AtsPlatformDashboardModule,
} from "@ats/ats-platform-dashboard";
import { environment } from "environments/environment";
import { AtsSmartToolDashboardModule } from "../../projects/ats-smart-tool-dashboard/src/public_api";

@NgModule({
  imports: [
    RouterModule.forRoot([], {
      useHash: true,
      onSameUrlNavigation: "reload",
    }),
    AtsPlatformDashboardModule.forRoot(),
    AtsSmartToolDashboardModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [],
  bootstrap: [LayoutComponent],
})
export class AppModule {}

