import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, EMPTY, Observable } from "rxjs";
import { forEach, find }  from 'lodash-es';
import { ApiQueryOptions, ApiService, BASE_URL, CoreService, DataSource, DataSourceConfig, DataSourceInstance, DataSourceResult, GenericDataSourceLoader, PageResult } from "@ats/ats-platform-dashboard";
import { TagHubService } from "./tag-hub.service";
import { TagValue } from "../domain/tagValue";
import { TagDataSourceItem } from "./tagsDataSourceLoader";
import { debounceTime, map, switchMap, tap } from "rxjs/operators";
import { TwoSocketEVCharger } from "../domain/dataModels/twoSocketEVCharger";
import { EVSocketDataPoint } from "../domain/dataModels/evSocketDataPoint";
import { Asset } from "../domain/entities/asset";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root',
})
export class EVChargersDataSourceLoader extends GenericDataSourceLoader {

  private dataSourceInstances: { [id: string]: DataSourceInstance } = {};

  constructor(protected override api: ApiService, protected override core: CoreService, private tagHub: TagHubService, protected override http: HttpClient, @Inject(BASE_URL) protected override baseUrl: string) {
    super(api, core, http, baseUrl);
    this.tagHub.on('broadcastTagValue', this.onBroadcastTagValue);
  }

  public override load(from: Date, to: Date, instance: DataSourceInstance): Observable<DataSourceResult> {
    if (!this.dataSourceInstances[instance.id]) {
      this.dataSourceInstances[instance.id] = instance;
      return this.tagHub.invoke<TagDataSourceItem[]>('SubscribeDataSource', instance.id).pipe(switchMap((result: TagDataSourceItem[]) => {
        var observer = super.load(from, to, instance);
        return this.addSubjects(instance, observer);
      }));
    } else {
      var observer = super.load(from, to, instance);
      return this.addSubjects(instance, observer);
    }
  }
  protected addSubjects(instance: DataSourceInstance, observable: Observable<DataSourceResult>): Observable<DataSourceResult> {
    return observable.pipe(tap((result: DataSourceResult) => {
      var twoSocketEVChargers: TwoSocketEVCharger[] = result.data;
        forEach(twoSocketEVChargers, (twoSocketEVCharger: TwoSocketEVCharger) => {

        if(twoSocketEVCharger.Socket1){
          twoSocketEVCharger.Socket1.LatestPointSubject = new BehaviorSubject(twoSocketEVCharger.Socket1.LatestPoint);
          twoSocketEVCharger.Socket1.LatestPoint$ = twoSocketEVCharger.Socket1.LatestPointSubject.asObservable();
          twoSocketEVCharger.Socket1.LatestPoint$.pipe(debounceTime(50)).subscribe((point: EVSocketDataPoint) => {
            instance.fireItemValueChangeEvent(twoSocketEVCharger.Socket1.Asset.Id, point);
          });
        }

        if(twoSocketEVCharger.Socket2){
          twoSocketEVCharger.Socket2.LatestPointSubject = new BehaviorSubject(twoSocketEVCharger.Socket2.LatestPoint);
          twoSocketEVCharger.Socket2.LatestPoint$ = twoSocketEVCharger.Socket2.LatestPointSubject.asObservable();
          twoSocketEVCharger.Socket2.LatestPoint$.pipe(debounceTime(50)).subscribe((point: EVSocketDataPoint) => {
            instance.fireItemValueChangeEvent(twoSocketEVCharger.Socket2.Asset.Id, point);
          });
        }

      });
    }));
  }

  public override unload(id: string): Observable<void> {
    var instance = this.dataSourceInstances[id];
    var twoSocketEVChargers: TwoSocketEVCharger[] = instance?.result?.data?.TwoSocketEVChargers;
      forEach(twoSocketEVChargers, (twoSocketEVCharger: TwoSocketEVCharger) => {
      twoSocketEVCharger.Socket1.LatestPointSubject = new BehaviorSubject(twoSocketEVCharger.Socket1.LatestPoint);
      twoSocketEVCharger.Socket1.LatestPointSubject.complete();
      twoSocketEVCharger.Socket2.LatestPointSubject = new BehaviorSubject(twoSocketEVCharger.Socket2.LatestPoint);
      twoSocketEVCharger.Socket2.LatestPointSubject.complete();
    });
    delete this.dataSourceInstances[id];
    return this.tagHub.invoke<any>('UnsubscribeDataSource', id);
  }

  override hasItems(): boolean {
    return true;
  }

  override loadItems(dataSource: DataSource): Observable<{ id: string; name: string; }[]> {
    const assetIdConfig: DataSourceConfig = find(dataSource.Configs, (config: DataSourceConfig) => config.Name == 'assetId');
    return this.api.executeFunction('EVCharging', 'GetEVSEs', assetIdConfig.Value, new ApiQueryOptions({}))
      .pipe(map((pageResult: PageResult<Asset>) => pageResult.Items.map((item: Asset) => {
        return { id: item.Id, name: item.Path };
      })));
  }

  onBroadcastTagValue = (dataSourceId: string, tagValue: TagValue) => {
    const instance = this.dataSourceInstances[dataSourceId];

    if (instance && instance.result) {
      forEach(<TwoSocketEVCharger[]>instance.result.data, (item: TwoSocketEVCharger) => {
        if (item.Socket1) {
          if (tagValue.TagId == item.Socket1.StateTagId) {
            item.Socket1.LatestPoint.State = tagValue.IntegerValue;
            item.Socket1.LatestPoint.TimeStamp = tagValue.TimeStamp;
            item.Socket1.LatestPointSubject?.next(item.Socket1.LatestPoint);
            return false;
          } else if (tagValue.TagId == item.Socket1.ActivePowerTagId) {
            item.Socket1.LatestPoint.ActivePower = tagValue.FloatingPointValue;
            item.Socket1.LatestPoint.TimeStamp = tagValue.TimeStamp;
            item.Socket1.LatestPointSubject?.next(item.Socket1.LatestPoint);
            return false;
          } else if (tagValue.TagId == item.Socket1.ChargedEnergyTagId) {
            item.Socket1.LatestPoint.ChargedEnergy = tagValue.FloatingPointValue;
            item.Socket1.LatestPoint.TimeStamp = tagValue.TimeStamp;
            item.Socket1.LatestPointSubject?.next(item.Socket1.LatestPoint);
            return false;
          }
        } 
        if (item.Socket2) {
          if (tagValue.TagId == item.Socket2.StateTagId) {
            item.Socket2.LatestPoint.State = tagValue.IntegerValue;
            item.Socket2.LatestPoint.TimeStamp = tagValue.TimeStamp;
            item.Socket2.LatestPointSubject?.next(item.Socket2.LatestPoint);
            return false;
          } else if (tagValue.TagId == item.Socket2.ActivePowerTagId) {
            item.Socket2.LatestPoint.ActivePower = tagValue.FloatingPointValue;
            item.Socket2.LatestPoint.TimeStamp = tagValue.TimeStamp;
            item.Socket2.LatestPointSubject?.next(item.Socket2.LatestPoint);
            return false;
          } else if (tagValue.TagId == item.Socket2.ChargedEnergyTagId) {
            item.Socket2.LatestPoint.ChargedEnergy = tagValue.FloatingPointValue;
            item.Socket2.LatestPoint.TimeStamp = tagValue.TimeStamp;
            item.Socket2.LatestPointSubject?.next(item.Socket2.LatestPoint);
            return false;
          }
        }
        return true;
      });
    }
  }

}