import { Component, OnInit, OnDestroy, Input, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { HubConnection } from '@aspnet/signalr';
import { ApiQueryOptions, ApiService, CoreService, DashboardService, NotFoundError, TranslateService, WidgetComponent, WidgetInstance } from '@ats/ats-platform-dashboard';
import { Subscription } from 'rxjs';
import { TagChart } from '../../domain/entities/tagChart';
import { isEmpty, keyBy } from "lodash-es";

@Component({
  selector: 'ats-smart-tool-chart-widget',
  templateUrl: './chart-widget.component.html',
  styleUrls: ['./chart-widget.component.scss']
})
export class ChartWidgetComponent implements WidgetComponent, OnInit, OnDestroy, AfterViewInit {
  @Input() hubConnection: HubConnection;

  public widgetInstance: WidgetInstance;

  public isLoading = false;
  public error: string;

  private widgetConfigSubscription: Subscription;

  @ViewChild("svgContainer", { static: false }) svgContainer: ElementRef;

  constructor(private core: CoreService, private api: ApiService, private translate: TranslateService, private dashboardService: DashboardService) { }

  public setWidgetInstance(widgetInstance: WidgetInstance) {
    this.widgetInstance = widgetInstance;
  }

  ngOnInit() {
    this.widgetConfigSubscription = this.widgetInstance.changed.subscribe({
      next: (instance: WidgetInstance) => {
        this.updateData(instance);
      }
    })
  }

  ngAfterViewInit() {
    setTimeout(() => { this.updateData(this.widgetInstance); }, 0);
  }

  ngOnDestroy() {
    if (this.widgetConfigSubscription) {
      this.widgetConfigSubscription.unsubscribe();
      this.widgetConfigSubscription = null;
    }
  }

  protected updateData(instance: WidgetInstance) {
    if (!instance.widgetData)
    instance.widgetData = {};

    if (!isEmpty(instance.widgetConfig.title)) {
      instance.widgetData.title = instance.widgetConfig.title;
    } 

    if (instance.widgetData?.chart?.Id != instance.widgetConfig?.chartId) {
      if (instance.widgetConfig?.chartId) {
        this.isLoading = true;
        this.api.getSingleResult('TagCharts', instance.widgetConfig.chartId, new ApiQueryOptions({})).subscribe({
          next:
            (chart: TagChart) => {
              instance.widgetData.chart = chart;
              // set task name as title when no title is configured
              if (isEmpty(instance.widgetConfig.title))
                instance.widgetData.title = chart.Name;

              this.isLoading = false;
            }, error: (err: any) => {
              this.error = this.handleError(err);
              this.isLoading = false;
            }
        }
        );
      } else {
        this.error = 'No chart id';
      }
    }

    let svgContent: string;

    if (instance.dashboardWidget.Documents && instance.dashboardWidget.Documents.length) {
      const documentsByKey = keyBy(instance.dashboardWidget.Documents, 'Name');
      svgContent = documentsByKey['svgContent'] ? documentsByKey['svgContent'].Content : '';
    }

    if (!svgContent || !svgContent.length) {
      svgContent = '<svg width="100%" height="100%" viewBox="0 0 512 512" preserveAspectRatio="xMidYMid meet"><path style="fill: black; stroke-width: 0" d="M496 384H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM464 96H345.94c-21.38 0-32.09 25.85-16.97 40.97l32.4 32.4L288 242.75l-73.37-73.37c-12.5-12.5-32.76-12.5-45.25 0l-68.69 68.69c-6.25 6.25-6.25 16.38 0 22.63l22.62 22.62c6.25 6.25 16.38 6.25 22.63 0L192 237.25l73.37 73.37c12.5 12.5 32.76 12.5 45.25 0l96-96 32.4 32.4c15.12 15.12 40.97 4.41 40.97-16.97V112c.01-8.84-7.15-16-15.99-16z"></path></svg>';
    }

    const div: HTMLDivElement = this.svgContainer.nativeElement;
    div.innerHTML = svgContent;

    const svgs = div.getElementsByTagName('svg');
    const svg = svgs && svgs.length ? svgs.item(0) : null;

    if (svg) {
      svg.style.cursor = 'pointer';
      svg.onclick = (e: MouseEvent) => {
        this.navigateToChart();
      };
    }
  }

  public navigateToChart(): void {
    if (!this.error && this.widgetInstance.widgetConfig.chartId) {
      var route = ['chart', this.widgetInstance.widgetConfig.chartId];
      if (this.widgetInstance.widgetConfig.assetId)
        route.push('asset', this.widgetInstance.widgetConfig.assetId);

      if (this.widgetInstance.widgetConfig?.keepPeriodSelection && this.dashboardService.config) {
        this.core.navigate(route, {
          state: {
            periodSelection: {
              from: this.dashboardService.config.from,
              to: this.dashboardService.config.to,
              period: this.dashboardService.config.period
            }
          }
        });
      } else {
        this.core.navigate(route);
      }
    }
  }

  private handleError(error: any): string {
    if (error instanceof NotFoundError) {
      return this.translate.get('i18n:MESSAGE.ITEM_NO_LONGER_EXISTS', { type: this.translate.get('i18n:CHART') });
    } else {
      return this.core.getErrorDetail(error);
    }
  }
}
