import {
  NgModule,
  ModuleWithProviders,
  Optional,
  SkipSelf,
} from "@angular/core";
import { RouterModule } from "@angular/router";
import { ATS_SMARTTOOL_ROUTES } from "./ats-smart-tool-dashboard.routes";
import { AtsPlatformDashboardModule, provideWidgetTypes, provideDataSourceTypes, provideTranslationFile, GenericDataSourceLoader, provideSvgWidgetContextInitializers, provideModules, TranslatePipe, EntityLookupTextboxModule, DateTimePeriodModule, DateTimeBoxModule, provideSystemEventFilterTypes } from "@ats/ats-platform-dashboard";
import { DataSourceAssetTagsComponent } from "./components/datasource-asset-tags/datasource-asset-tags.component";
import { DataSourceEvChargersComponent } from "./components/datasource-ev-chargers/datasource-ev-chargers.component";
import { TagsDataSourceLoader } from "./services/tagsDataSourceLoader";
import { MeasurementUnitSelectionPopupComponent } from "./components/measurement-unit-selection-popup/measurement-unit-selection-popup.component";
import { TagHubService } from "./services/tag-hub.service";
import { SMARTTOOL_DASHBOARD_TRANSLATIONS } from "./domain/translations.smarttool.dashboard";
import { DataSourceTagsHistoryComponent } from "./components/datasource-tags-history/datasource-tags-history.component";
import { AssetTagsDataSourceLoader } from "./services/assetTagsDataSourceLoader";
import { AssetsDataSourceLoader } from "./services/assetsDataSourceLoader";
import { DataSourceTagsComponent } from "./components/datasource-tags/datasource-tags.component";
import { DataSourceWeatherForecastComponent } from "./components/datasource-weatherforecast/datasource-weatherforecast.component";
import { TagCircularGaugeWidgetComponent } from "./components/tag-circular-gauge-widget/tag-circular-gauge-widget.component";
import { TagCircularGaugeWidgetConfigComponent } from "./components/tag-circular-gauge-widget-config/tag-circular-gauge-widget-config.component";
import { tagCircularGaugeWidgetType, tagLinearGaugeWidgetType, tagGroupedStackedBarChartWidgetType, tagsValueWidgetType, tagValueWidgetType, WeatherForecastDailyWidgetType, tagPieChartWidgetType, tagLegendWidgetType, tagLineChartWidgetType, WeatherForecastHourlyWidgetType, buildingEnergyLinearRegressionWidgetType, buildingEnergyHorizStackedBarChartWidgetType, buildingEnergyVertStackedBarChartWidgetType, chartWidgetType, reportWidgetType, evChargingConstraintChartWidgetType, evChargingConstraintWidgetType, evChargersWidgetType, energyDistributionWidgetType, deviceTopologyWidgetType, evChargingSessionsWidgetType, evChargersMapWidgetType, heatMapWidgetType} from "./domain/widgetType";
import { TagLinearGaugeWidgetComponent } from "./components/tag-linear-gauge-widget/tag-linear-gauge-widget.component";
import { TagLinearGaugeWidgetConfigComponent } from "./components/tag-linear-gauge-widget-config/tag-linear-gauge-widget-config.component";
import { TagValueWidgetComponent } from "./components/tag-value-widget/tag-value-widget.component";
import { TagValueWidgetConfigComponent } from "./components/tag-value-widget-config/tag-value-widget-config.component";
import { TagsValueWidgetComponent } from "./components/tags-value-widget/tags-value-widget.component";
import { TagsValueWidgetConfigComponent } from "./components/tags-value-widget-config/tags-value-widget-config.component";
import { DataSourceBuildingEnergyComponent } from "./components/datasource-buildingenergy/datasource-buildingenergy.component";
import { DataSourceProductionConsumptionComponent, DataSourceProductionConsumptionItem } from "./components/datasource-production-consumption/datasource-production-consumption.component";
import { DataSourceEnergyManagementComponent } from "./components/datasource-energy-management/datasource-energy-management.component";
import { DataSourceSolarPerformanceComponent } from "./components/datasource-solar-performance/datasource-solar-performance.component";
import { WeatherForecastDailyWidgetComponent } from "./components/weather-forecast-daily-widget/weather-forecast-daily-widget.component";
import { WeatherForecastDailyWidgetConfigComponent } from "./components/weather-forcast-daily-widget-config/weather-forecast-daily-widget-config.component";
import { TagGroupedStackedBarChartWidgetConfigComponent } from "./components/tag-grouped-stacked-bar-chart-widget-config/tag-grouped-stacked-bar-chart-widget-config.component";
import { TagGroupedStackedBarChartWidgetComponent } from "./components/tag-grouped-stacked-bar-chart-widget/tag-grouped-stacked-bar-chart-widget.component";
import { TagPieChartWidgetComponent } from "./components/tag-pie-chart-widget/tag-pie-chart-widget.component";
import { TagPieChartWidgetConfigComponent } from "./components/tag-pie-chart-widget-config/tag-pie-chart-widget-config.component";
import { TagLegendWidgetComponent } from "./components/tag-legend-widget/tag-legend-widget.component";
import { TagLegendWidgetConfigComponent } from "./components/tag-legend-widget-config/tag-legend-widget-config.component";
import { WeatherForecastHourlyWidgetComponent } from "./components/weather-forecast-hourly-widget/weather-forecast-hourly-widget.component";
import { WeatherForecastHourlyWidgetConfigComponent } from "./components/weather-forcast-hourly-widget-config/weather-forecast-hourly-widget-config.component";
import { TagLineChartWidgetComponent } from "./components/tag-line-chart-widget/tag-line-chart-widget.component";
import { TagLineChartWidgetConfigComponent } from "./components/tag-line-chart-widget-config/tag-line-chart-widget-config.component";
import { TagHistoryDataSourceLoader } from "./services/tagDataSourceLoader";
import { BuildingEnergyLinearRegressionWidgetConfigComponent } from "./components/building-energy-linear-regression-widget-config/building-energy-linear-regression-widget-config.component";
import { BuildingEnergyLinearRegressionWidgetComponent } from "./components/building-energy-linear-regression-widget/building-energy-linear-regression-widget.component";
import { BuildingEnergyHorizStackedBarChartWidgetConfigComponent } from "./components/building-energy-horiz-stacked-bar-chart-widget-config/building-energy-horiz-stacked-bar-chart-widget-config.component";
import { BuildingEnergyHorizStackedBarChartWidgetComponent } from "./components/building-energy-horiz-stacked-bar-chart-widget/building-energy-horiz-stacked-bar-chart-widget.component";
import { BuildingEnergyVertStackedBarChartWidgetConfigComponent } from "./components/building-energy-vert-stacked-bar-chart-widget-config/building-energy-vert-stacked-bar-chart-widget-config.component";
import { BuildingEnergyVertStackedBarChartWidgetComponent } from "./components/building-energy-vert-stacked-bar-chart-widget/building-energy-vert-stacked-bar-chart-widget.component";
import { assetTagsDataSourceType, buildingEnergyDataSourceType, productionConsumptionDataSourceType, solarPerformanceDataSourceType, tagsDataSourceType, tagsHistoryDataSourceType, timeInComfortDataSourceType, weatherForecastDataSourceType, energyManagementDataSourceType, evChargingConstraintDataSourceType, evChargersDataSourceType, deviceTopologyDataSourceType, evChargingSessionsDataSourceType} from "./domain/dataSourceType";
import { TagsHistoryDataSourceExporter } from "./services/tagsHistoryDataSourceExporter";
import { DataSourceTimeInComfortComponent } from "./components/datasource-time-in-comfort/datasource-time-in-comfort.component";
import { BaseSvgContextInitializer } from "./svg/base-svg-context-initializer";
import { ChartPopupComponent } from "./components/chart-popup/chart-popup.component";
import { ChartToolbarComponent } from "./components/chart-toolbar/chart-toolbar.component";
import { ChartService } from "./services/chart.service";
import { ChartResolver } from "./services/chart-resolver.service";
import { ChartWidgetComponent } from "./components/chart-widget/chart-widget.component";
import { ChartWidgetConfigComponent } from "./components/chart-widget-config/chart-widget-config.component";
import { TagValueWritePopupComponent } from "./components/tag-value-write-popup/tag-value-write-popup.component";
import { ProductionConsumptionDataSourceExporter } from "./services/productionConsumptionDataSourceExporter";
import { BuildingEnergyDataSourceExporter } from "./services/buildingEnergyDataSourceExporter";
import { SolarPerformanceDataSourceExporter } from "./services/solarPerformanceDataSourceExporter";
import { TimeInComfortDataSourceExporter } from "./services/timeInComfortDataSourceExporter";
import { ReportWidgetConfigComponent } from "./components/report-widget-config/report-widget-config.component";
import { ReportWidgetComponent } from "./components/report-widget/report-widget.component";
import { ChartMenuComponent } from "./components/chart-menu/chart-menu.component";
import { ReportsMenuComponent } from "./components/reports-menu/reports-menu.component";
import { ReportsPopupComponent } from "./components/reports-popup/reports-popup.component";
import { ReportDownloadPopupComponent } from "./components/report-download-popup/report-download-popup.component";
import { EVChargersDataSourceLoader } from "./services/evChargersDataSourceLoader";
import { EVChargingConstraintDataSourceLoader } from "./services/evChargingConstraintDataSourceLoader";
import { EVChargingConstraintChartWidgetConfigComponent } from "./components/ev-charging-constraint-chart-widget-config/ev-charging-constraint-chart-widget-config.component";
import { EVChargingConstraintChartWidgetComponent } from "./components/ev-charging-constraint-chart-widget/ev-charging-constraint-chart-widget.component";
import { EVChargingConstraintWidgetConfigComponent } from "./components/ev-charging-constraint-widget-config/ev-charging-constraint-widget-config.component";
import { EVChargingConstraintWidgetComponent } from "./components/ev-charging-constraint-widget/ev-charging-constraint-widget.component";
import { EVChargersWidgetComponent } from "./components/ev-chargers-widget/ev-chargers-widget.component";
import { EVChargersWidgetConfigComponent } from "./components/ev-chargers-widget-config/ev-chargers-widget-config.component";
import { DataSourceEVChargingConstraintComponent } from "./components/datasource-ev-charging-constraint/datasource-ev-charging-constraint.component";
import { ChartToReportPopupComponent } from "./components/chart-to-report-popup/chart-to-report-popup.component";
import { EnergyDistributionWidgetComponent } from "./components/energy-distribution-widget/energy-distribution-widget.component";
import { EnergyDistributionWidgetConfigComponent } from "./components/energy-distribution-widget-config/energy-distribution-widget-config.component";
import { DataSourceDeviceTopologyComponent } from "./components/datasource-device-topology/datasource-device-topology.component";
import { DeviceTopologyDataSourceLoader } from "./services/deviceTopologyDataSourceLoader";
import { DeviceTopologyWidgetConfigComponent } from "./components/device-topology-widget-config/device-topology-widget-config.component";
import { DeviceTopologyWidgetComponent } from "./components/device-topology-widget/device-topology-widget.component";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { faCheck, faChartLine, faFilePdf, faClipboardCheck, faClock, faClose, faCommentDots, faDatabase, faDownload, faExclamationCircle, faExclamationTriangle, faEye, faEyeSlash, faInfoCircle, faPen, faSearch, faTachometerAlt, faTrash, faUser } from "@fortawesome/free-solid-svg-icons";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { ChartNavGuard } from "./nav/chart.nav.guard";
import { EnergyManagementDataSourceExporter } from "./services/energyManagementDataSourceExporter";
import { EVChargingSessionsWidgetComponent } from "./components/ev-charging-sessions-widget/ev-charging-sessions-widget.component";
import { EVChargingSessionsWidgetConfigComponent } from "./components/ev-charging-sessions-widget-config/ev-charging-sessions-widget-config.component";
import { EVChargingSessionsHubService } from "./services/ev-charging-sessions-hub.service";
import { DataSourceEvChargingSessionsComponent } from "./components/datasource-ev-charging-sessions/datasource-ev-charging-sessions.component";
import { EvChargingSessionsDataSourceExporter } from "./services/evChargingSessionsDataSourceExporter";
import { DeviceTopologyDataSourceExporter } from "./services/deviceTopologyDataSourceExporter";
import { EVChargingConstraintDataSourceExporter } from "./services/evChargingConstraintDataSourceExporter";
import { EVChargersDataSourceExporter } from "./services/evChargersDataSourceExporter";
import { EVChargersMapWidgetComponent } from "./components/ev-chargers-map-widget/ev-chargers-map-widget.component";
import { EVChargersMapWidgetConfigComponent } from "./components/ev-chargers-map-widget-config/ev-chargers-map-widget-config.component";
import { SystemEventAssetFilterComponent } from "./components/system-event-asset-filter/system-event-asset-filter.component";
import { systemEventAssetFilterType} from "./domain/systemEventFilterType";
import { HeatMapWidgetComponent } from "./components/heat-map-widget/heat-map-widget.component";
import { HeatMapWidgetConfigComponent } from "./components/heat-map-widget-config/heat-map-widget-config.component";

@NgModule({
  imports: [
    AtsPlatformDashboardModule,
    TranslatePipe,
    EntityLookupTextboxModule,
    DateTimePeriodModule,
    DateTimeBoxModule,
    RouterModule.forChild(ATS_SMARTTOOL_ROUTES)
  ],
  exports: [
  ],
  declarations: [
    DataSourceAssetTagsComponent,
    DataSourceEvChargersComponent,
    DataSourceTagsComponent,
    DataSourceTagsHistoryComponent,
    DataSourceWeatherForecastComponent,
    DataSourceBuildingEnergyComponent,
    DataSourceDeviceTopologyComponent,
    DataSourceProductionConsumptionComponent,
    DataSourceEnergyManagementComponent,
    DataSourceSolarPerformanceComponent,
    MeasurementUnitSelectionPopupComponent,
    TagCircularGaugeWidgetComponent,
    TagCircularGaugeWidgetConfigComponent,
    TagLinearGaugeWidgetComponent,
    TagLinearGaugeWidgetConfigComponent,
    TagValueWidgetComponent,
    TagValueWidgetConfigComponent,
    TagsValueWidgetComponent,
    TagsValueWidgetConfigComponent,
    TagGroupedStackedBarChartWidgetComponent,
    TagGroupedStackedBarChartWidgetConfigComponent,
    EnergyDistributionWidgetComponent,
    EnergyDistributionWidgetConfigComponent,
    DeviceTopologyWidgetComponent,
    DeviceTopologyWidgetConfigComponent,
    WeatherForecastDailyWidgetComponent,
    WeatherForecastDailyWidgetConfigComponent,
    TagPieChartWidgetComponent,
    TagPieChartWidgetConfigComponent,
    TagLegendWidgetComponent,
    TagLegendWidgetConfigComponent,
    WeatherForecastHourlyWidgetComponent,
    WeatherForecastHourlyWidgetConfigComponent,
    TagLineChartWidgetComponent,
    TagLineChartWidgetConfigComponent,
    BuildingEnergyLinearRegressionWidgetComponent,
    BuildingEnergyLinearRegressionWidgetConfigComponent,
    BuildingEnergyHorizStackedBarChartWidgetComponent,
    BuildingEnergyHorizStackedBarChartWidgetConfigComponent,
    BuildingEnergyVertStackedBarChartWidgetComponent,
    BuildingEnergyVertStackedBarChartWidgetConfigComponent,
    HeatMapWidgetComponent,
    HeatMapWidgetConfigComponent,
    DataSourceTimeInComfortComponent,
    ChartMenuComponent,
    ChartPopupComponent,
    ChartToolbarComponent,
    ChartWidgetComponent,
    ChartWidgetConfigComponent,
    TagValueWritePopupComponent,
    ReportWidgetConfigComponent,
    ReportWidgetComponent,
    ReportsMenuComponent,
    ReportsPopupComponent,
    ReportDownloadPopupComponent,
    DataSourceEVChargingConstraintComponent,
    EVChargingConstraintWidgetConfigComponent,
    EVChargingConstraintWidgetComponent,
    EVChargingConstraintChartWidgetConfigComponent,
    EVChargingConstraintChartWidgetComponent,
    EVChargersWidgetComponent,
    EVChargersWidgetConfigComponent,
    ChartToReportPopupComponent,
    EVChargingSessionsWidgetComponent,
    EVChargingSessionsWidgetConfigComponent,
    DataSourceEvChargingSessionsComponent,
    EVChargersMapWidgetComponent,
    EVChargersMapWidgetConfigComponent,
    SystemEventAssetFilterComponent
  ],
  entryComponents: [
    MeasurementUnitSelectionPopupComponent,
    ChartPopupComponent,
    TagValueWritePopupComponent,
    ReportsPopupComponent,
    ReportDownloadPopupComponent,
    ChartToReportPopupComponent
  ],
})
export class AtsSmartToolDashboardModule {
  constructor(@Optional() @SkipSelf() parentModule: AtsSmartToolDashboardModule, private library: FaIconLibrary) {
    if (parentModule) {
      throw new Error(
        "AtsSmartToolModule is already loaded. Import it in the AppModule only"
      );
    }

    library.addIcons(faChartLine, faFilePdf, faPen, faTrash, faDownload, faInfoCircle, faExclamationTriangle, faExclamationCircle, faClock, faUser, faCommentDots, faClipboard, faClipboardCheck, faEyeSlash, faEye, faTachometerAlt, faDatabase, faClose, faSearch, faCheck);
  }

  static forRoot(): ModuleWithProviders<AtsSmartToolDashboardModule> {
    return {
      ngModule: AtsSmartToolDashboardModule,
      providers: [
        provideTranslationFile(SMARTTOOL_DASHBOARD_TRANSLATIONS),
        provideModules([{
          index: 100,
          allowOnMobile: false,
          icon: 'chart-line',
          menuComponent: ChartMenuComponent,
          toolbarComponent: ChartToolbarComponent,
          route: 'chart'
        }, {
          index: 200,
          allowOnMobile: false,
          icon: 'file-pdf',
          menuComponent: ReportsMenuComponent
        }]),
        provideWidgetTypes([
          chartWidgetType,
          tagCircularGaugeWidgetType,
          tagLinearGaugeWidgetType,
          tagValueWidgetType,
          tagsValueWidgetType,
          WeatherForecastDailyWidgetType,
          tagGroupedStackedBarChartWidgetType,
          tagPieChartWidgetType,
          tagLegendWidgetType,
          tagLineChartWidgetType,
          WeatherForecastHourlyWidgetType,
          buildingEnergyLinearRegressionWidgetType,
          buildingEnergyHorizStackedBarChartWidgetType,
          buildingEnergyVertStackedBarChartWidgetType,
          heatMapWidgetType,
          reportWidgetType,
          evChargingConstraintWidgetType,
          evChargingConstraintChartWidgetType,
          evChargersWidgetType,
          evChargersMapWidgetType,
          energyDistributionWidgetType,
          deviceTopologyWidgetType,
          evChargingSessionsWidgetType
        ]),
        TagHubService,
        TagsDataSourceLoader,
        AssetTagsDataSourceLoader,
        EVChargersDataSourceLoader,
        DeviceTopologyDataSourceLoader,
        TagHistoryDataSourceLoader,
        AssetsDataSourceLoader,
        EVChargingConstraintDataSourceLoader,
        TagsHistoryDataSourceExporter,
        ProductionConsumptionDataSourceExporter,
        EnergyManagementDataSourceExporter,
        BuildingEnergyDataSourceExporter,
        SolarPerformanceDataSourceExporter,
        DeviceTopologyDataSourceExporter,
        EVChargingConstraintDataSourceExporter,
        EVChargersDataSourceExporter,
        EvChargingSessionsDataSourceExporter,
        TimeInComfortDataSourceExporter,
        ChartNavGuard,
        provideDataSourceTypes([
          tagsDataSourceType,
          assetTagsDataSourceType,
          tagsHistoryDataSourceType,
          weatherForecastDataSourceType,
          buildingEnergyDataSourceType,
          productionConsumptionDataSourceType,
          energyManagementDataSourceType,
          deviceTopologyDataSourceType,
          solarPerformanceDataSourceType,
          timeInComfortDataSourceType,
          evChargersDataSourceType,
          evChargingConstraintDataSourceType,
          evChargingSessionsDataSourceType,
        ]),
        provideSystemEventFilterTypes([
          systemEventAssetFilterType
        ]),
        provideSvgWidgetContextInitializers([BaseSvgContextInitializer]),
        ChartService,
        ChartResolver,
        EVChargingSessionsHubService
      ]
    };
  }
}
