import { AfterViewInit, Component, ElementRef, Inject, Injector, Input, OnDestroy, OnInit, Type, ViewChild } from "@angular/core";
import { HubConnection } from "@aspnet/signalr";
import { DashboardService, DataSourceInstance, TranslateService, WidgetComponent, WidgetInstance, SvgContainerComponent, WidgetUpdater, ItemValueChangedEventArgs, ApiService, DataSourceType, DATASOURCETYPES, CoreService } from "@ats/ats-platform-dashboard";
import { Subscription } from "rxjs";

import { find, flatten, keyBy, uniq, map, isString } from "lodash-es";
import { select, rollups, stack, max, min, stackOffsetDiverging } from 'd3';;
import { GroupedStackedBarChart } from "../../svg/grouped-stacked-bar-chart";
import { ProductionConsumptionData } from "../../domain/entities/productionConsumptionData";
import { StackItemInfo } from "../../domain/dataModels/groupedStackedBarChartData";
import { TagConfig } from "../../domain/entities/tagConfig";
import { EnergyManagementData, EnergyMeterTypeConfig } from "../../domain/entities/energyManagementData";
import { ENERGY_MANAGEMENT_CATEGORIES } from "../../domain/enums/energy-management-categories";
import { EnergyMeterType } from "../../domain/enums/EnergyMeterType";
import { DateTime } from "luxon";

//https://stackblitz.com/edit/d3-tooltip?embed=1&file=src/app/bar-chart.ts&hideNavigation=1s

@Component({
  selector: 'ats-smart-tool-tag-grouped-stacked-bar-chart-widget',
  templateUrl: './tag-grouped-stacked-bar-chart-widget.component.html',
  styleUrls: ['./tag-grouped-stacked-bar-chart-widget.component.scss']
})
export class TagGroupedStackedBarChartWidgetComponent implements WidgetComponent, OnInit, AfterViewInit, OnDestroy {
  @Input() hubConnection: HubConnection;

  public widgetInstance: WidgetInstance;
  public isLoading = false;
  public error: string;
  public dataSourceItemsLoading: boolean = false;

  private widgetConfigSubscription: Subscription;
  private widgetUpdaters: WidgetUpdater[];
  private svg: SVGSVGElement;
  private dataSourceTypes: { [type: string]: DataSourceType };

  private div: HTMLDivElement;

  private drawer: GroupedStackedBarChart;

  public svgContainerType: Type<SvgContainerComponent> = SvgContainerComponent;

  @ViewChild('svgContainer', { static: false }) svgContainer: ElementRef;

  constructor(private dashboardService: DashboardService, private translate: TranslateService, private api: ApiService, @Inject(DATASOURCETYPES) dataSourceTypes: DataSourceType[][], private injector: Injector, public core: CoreService) {
    this.dataSourceTypes = keyBy(flatten(dataSourceTypes), (type: DataSourceType) => type.name);
  }

  public setWidgetInstance(widgetInstance: WidgetInstance) {
    this.widgetInstance = widgetInstance;
  }

  ngOnInit() {
    this.widgetConfigSubscription = this.widgetInstance.changed.subscribe({
      next: (instance: WidgetInstance) => {
        this.updateData(instance);
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => { this.updateData(this.widgetInstance); }, 0);
  }

  ngOnDestroy() {
    if (this.widgetConfigSubscription) {
      this.widgetConfigSubscription.unsubscribe();
      this.widgetConfigSubscription = null;
    }

    if (this.widgetUpdaters) {
      this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
      this.widgetUpdaters = null;
    }
  }

  protected updateData(instance: WidgetInstance) {
    instance.widgetData = {
      title: instance.widgetConfig.title
    };

    if (!this.svgContainer)
      return;

    this.div = this.svgContainer.nativeElement;
    const svgs = this.div.getElementsByTagName('svg');
    this.svg = svgs && svgs.length ? svgs.item(0) : null;
    if (!this.svg)
      return;

    const d3svg = select(this.svg);

    d3svg.selectAll('g').remove();

    const g = d3svg.append('g');

    this.drawer = new GroupedStackedBarChart(this.dashboardService);
    this.drawer.init(g);

    if (this.widgetUpdaters) {
      this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
    }

    const dataSourceInstance = find(this.dashboardService.dataSourceInstances, (dataSourceInstance: DataSourceInstance) => dataSourceInstance.id == instance.widgetConfig.dataSourceId);

    if (dataSourceInstance) {
      this.widgetUpdaters = [];

      this.widgetUpdaters.push(
        new WidgetUpdater(dataSourceInstance, this.svgContainer.nativeElement,
          (dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement) => {
            this.isLoading = dataSourceInstance?.result?.isLoading;

            if (dataSourceInstance.result && dataSourceInstance.result.data) {
              d3svg.attr('viewBox', '0 0 ' + (this.div.getBoundingClientRect().width) + ' ' + (this.div.getBoundingClientRect().height));

              this.drawer.groupBy = instance.widgetConfig.groupBy;
              this.drawer.left = instance.widgetConfig.leftMargin;
              this.drawer.right = instance.widgetConfig.rightMargin;
              this.drawer.top = instance.widgetConfig.topMargin;
              this.drawer.bottom = instance.widgetConfig.bottomMargin;
              this.drawer.numberOfDecimals = instance.widgetConfig.numberOfDecimals;
              this.drawer.trendView = instance.widgetConfig.trendView;
              this.drawer.width = this.div.getBoundingClientRect().width;
              this.drawer.height = this.div.getBoundingClientRect().height;
              this.drawer.title = instance.widgetConfig.title;
              this.drawer.getAutoGroupBy = this.getAutoGroupBy;

              switch (dataSourceInstance.type?.name) {
                case "productionConsumption":
                  let productionConsumptionData: ProductionConsumptionData = dataSourceInstance.result.data;

                  //StackItemInfoArray
                  this.drawer.data.StackItemInfoArray = [];
                  productionConsumptionData.Tags.forEach(tag => {
                    const tagConfig = productionConsumptionData.TagConfig.find(tagConfig => tagConfig.TagId == tag.Id);

                    let tempStackItemInfo = new StackItemInfo();
                    tempStackItemInfo.Id = tag.Id;
                    tempStackItemInfo.Name = tag.Name;
                    tempStackItemInfo.MeasurementUnit = tag.MeasurementUnit;
                    tempStackItemInfo.PathName = tag.Asset.Path;
                    tempStackItemInfo.Category = tagConfig.Category;
                    tempStackItemInfo.Color = tagConfig.Color;
                    //TODO PAND measurement unit selection can be need to be improved
                    this.drawer.measurementUnit = tag.MeasurementUnit.Symbol ?? this.drawer.measurementUnit;

                    this.drawer.data.StackItemInfoArray.push(tempStackItemInfo);
                  });

                  //SelectedCategories + generate groupedStackItemData
                  if(instance.widgetConfig?.selectedCategories && instance.widgetConfig?.selectedCategories.length){
                    this.drawer.selectedCategories = instance.widgetConfig.selectedCategories;
                  } else {
                    this.drawer.selectedCategories = uniq(map(productionConsumptionData.TagConfig, x => x.Category));
                  };

                  this.generateGroupedStackItemDataAndYValues(productionConsumptionData.DetailData, instance.widgetConfig.groupBy);
                  this.drawer.draw();

                  break;
                case "energyManagement":
                  let energyManagementData: EnergyManagementData = dataSourceInstance.result.data;
                  const categories = this.core.translateObjectArray(ENERGY_MANAGEMENT_CATEGORIES, 'Display');

                  //SelectedCategories
                  if(!(instance.widgetConfig?.selectedCategories && instance.widgetConfig?.selectedCategories.length && instance.widgetConfig.selectedCategories.filter(x => categories.map(x => x.Name).includes(x)).length > 0)){
                    instance.widgetConfig.selectedCategories = ['electricityMix'];
                  }          
                  
                  this.drawer.selectedCategories = instance.widgetConfig.selectedCategories;

                  //StackItemInfoArray
                  this.drawer.data.StackItemInfoArray = [];
                  energyManagementData.EnergyMeterTypeConfig.forEach(energyMeterTypeConfig => {

                    let tempStackItemInfo = new StackItemInfo();
                    tempStackItemInfo.Id = EnergyMeterType[energyMeterTypeConfig.EnergyMeterType];
                    tempStackItemInfo.Name = EnergyMeterType[energyMeterTypeConfig.EnergyMeterType];
                    tempStackItemInfo.MeasurementUnit = energyManagementData.EnergyMeasurementUnit;
                    tempStackItemInfo.PathName = energyMeterTypeConfig.Path;
                    tempStackItemInfo.Category = this.setEnergyMeterTypeCategory (energyMeterTypeConfig, this.drawer.selectedCategories, categories)
                    tempStackItemInfo.Color = energyMeterTypeConfig.Color;
                    //TODO PAND measurement unit selection can be need to be improved
                    this.drawer.measurementUnit = energyManagementData.EnergyMeasurementUnit.Symbol ?? this.drawer.measurementUnit;

                    this.drawer.data.StackItemInfoArray.push(tempStackItemInfo);
                  });
                  
                  // generate groupedStackItemData
                  this.generateGroupedStackItemDataAndYValues(energyManagementData.EnergyMeterTypeDetailData, instance.widgetConfig.groupBy);
                  this.drawer.draw();
                  break;
              }
            }
            //generate title if not present, update happens twice first time title gets filled in with null as unit, then with correct unit value. this is the reason widgetconfig.title does not get updated
            if (!this.widgetInstance.widgetConfig.title) {
              this.widgetInstance.widgetData.title = this.translate.get('i18n:TAG_GROUPED_STACKED_BAR_CHART_WIDGET') + ' (' + this.drawer.measurementUnit + ')';
            }
          },
          (dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement, event: ItemValueChangedEventArgs) => {
            //No need to change if data from item changed
          }));
    }
  }

  public cancel(e: any) {
    e.preventDefault();
    e.cancelBubble = true;
  }

  private setEnergyMeterTypeCategory (energyMeterTypeConfig: EnergyMeterTypeConfig, widgetCategory: string[], categories: any) : string{

    const selectedCategory = categories.find(x => x.Name === widgetCategory[0]).Name;
    let categoryMapping = {};

    switch (selectedCategory){
      case 'energyMix':
        for (let energyMeterType in EnergyMeterType){
          switch (energyMeterType){ 
            case EnergyMeterType.Solar.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.Wind.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.GridConsumption.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.GridInjection.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.Gas.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.Diesel.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.EVCharger.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.SelfConsumption.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.BatteryEnergyIn.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.BatteryEnergyOut.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
          }
        }
        break;
      case 'electricityMix':
        for (let energyMeterType in EnergyMeterType){
          switch (energyMeterType){ 
            case EnergyMeterType.Solar.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.Wind.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.GridConsumption.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.GridInjection.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.Gas.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.Diesel.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.EVCharger.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.SelfConsumption.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.BatteryEnergyIn.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.BatteryEnergyOut.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
          }
        }
        break;
      case 'renewableEnergy':
        for (let energyMeterType in EnergyMeterType){
          switch (energyMeterType){ 
            case EnergyMeterType.Solar.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.Wind.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.GridConsumption.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.GridInjection.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.Gas.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.Diesel.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.EVCharger.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.SelfConsumption.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.BatteryEnergyIn.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.BatteryEnergyOut.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
          }
        }
        break;
      case 'gas':
          for (let energyMeterType in EnergyMeterType){
            switch (energyMeterType){ 
              case EnergyMeterType.Solar.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.Wind.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.GridConsumption.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.GridInjection.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.Gas.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
              break;
              case EnergyMeterType.Diesel.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.SelfConsumption.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.BatteryEnergyIn.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.BatteryEnergyOut.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
            }
          }
          break;
      case 'diesel':
        for (let energyMeterType in EnergyMeterType){
          switch (energyMeterType){ 
            case EnergyMeterType.Solar.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.Wind.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.GridConsumption.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.GridInjection.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.Gas.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.Diesel.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.EVCharger.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.SelfConsumption.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.BatteryEnergyIn.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.BatteryEnergyOut.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
          }
        }
        break;
      case 'ev':
        for (let energyMeterType in EnergyMeterType){
          switch (energyMeterType){ 
            case EnergyMeterType.Solar.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.Wind.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.GridConsumption.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.GridInjection.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.Gas.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.Diesel.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.EVCharger.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
            break;
            case EnergyMeterType.SelfConsumption.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.BatteryEnergyIn.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
            case EnergyMeterType.BatteryEnergyOut.toString():
              categoryMapping[EnergyMeterType[energyMeterType]] = '';
            break;
          }
        }
        break;         
        case 'battery':
          for (let energyMeterType in EnergyMeterType){
            switch (energyMeterType){ 
              case EnergyMeterType.Solar.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.Wind.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.GridConsumption.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.GridInjection.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.Gas.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.Diesel.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.EVCharger.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.SelfConsumption.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = '';
              break;
              case EnergyMeterType.BatteryEnergyIn.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
              break;
              case EnergyMeterType.BatteryEnergyOut.toString():
                categoryMapping[EnergyMeterType[energyMeterType]] = selectedCategory;
              break;
            }
          }
          break;      
      }
    const energyMeterTypeString = EnergyMeterType[energyMeterTypeConfig.EnergyMeterType];
    return categoryMapping[energyMeterTypeString];   
  }
  public getAutoGroupBy (fromTimestamp: number, toTimestamp: number, groupBy: number) : number{
    if(groupBy === 7) //"Auto" -> calculate what the beste groupBy configuration is
    {
      var difference = toTimestamp - fromTimestamp;

      var oneDay = 24 * 60 * 60 * 1000;
      var twoDays = 2 * oneDay;
      var oneMonth = 31 * oneDay;
      var threeMonths = 3 * oneMonth;
      var oneYear = 365 * oneDay;
      var twoYears = 2 * oneYear;

      if (difference < twoDays) {
          return 6; //hours
      } else if (difference < oneMonth) {
        return 0; //days
      } else if (difference < threeMonths) {
        return 1; //weeks
      } else if (difference < oneYear) {
        return 2; //months
      } else if (difference < twoYears) {
        return 3; //quarters
      } else {
        return 4; //years
      }
    } else {
      return groupBy;
    }
  }

  private generateGroupedStackItemDataAndYValues(detailData: any[], groupBy: number) {
    var fromTimestamp = Math.min.apply(null, detailData.map(x => isString(x.TimeStamp) ? DateTime.fromISO(x.TimeStamp as string) : DateTime.fromJSDate(x.TimeStamp)));
    var toTimestamp = Math.max.apply(null, detailData.map(x => isString(x.TimeStamp) ? DateTime.fromISO(x.TimeStamp as string) : DateTime.fromJSDate(x.TimeStamp)));
    var localGroupBy = this.getAutoGroupBy(fromTimestamp, toTimestamp, groupBy);
 
    //Roll up detail data
    let rollupsData: any = rollups(
      detailData,
      (v) => v.reduce(function (acc, obj) {
        Object.keys(obj).forEach(function (key) {
          if (key !== 'TimeStamp') {
            acc[key] = (acc[key] || 0) + obj[key];
          }
        })
        return acc;
      }, []),
      (d) => {
        return this.calculateDateTime(localGroupBy, new Date(d.TimeStamp));
      }
    );
    rollupsData = rollupsData.map(x => {
      x[1]["TimeStamp"] = x[0];
      return x[1];
    });

    this.drawer.data.GroupedStackItemData = [];
    this.drawer.selectedCategories.forEach((category) => {
      let seriesInCategory = this.drawer.data.StackItemInfoArray.filter(x => x.Category === category).map(x => x.Id);
      let stackedGroupData = stack()
        .keys(seriesInCategory)
        .offset(stackOffsetDiverging)(rollupsData)
        .map((d) => (d.forEach((v) => (v["key"] = d.key)), d));
      this.drawer.data.GroupedStackItemData.push(stackedGroupData);
    });
    const maxDataSetValue = max(this.drawer.data.GroupedStackItemData, (d) =>
       max(<any[]>d, (d) =>
        max(<any[]>d, (d) => d[1]))
    );
    this.drawer.maxYValue = maxDataSetValue > 0 ? maxDataSetValue : 0;

    const minDataSetValue = min(this.drawer.data.GroupedStackItemData, (d) =>
    min(<any[]>d, (d) =>
      min(<any[]>d, (d) => d[0]))
    );
    this.drawer.minYValue = minDataSetValue < 0 ? minDataSetValue : 0;
  }

  private calculateDateTime(groupBy: number, dateTime: Date): Date {
    if (groupBy === 0) {
      //days
      dateTime.setMilliseconds(0);
      dateTime.setSeconds(0);
      dateTime.setMinutes(0);
      dateTime.setHours(0);
      return dateTime;
    } else if (groupBy === 1) {
      //weeks
      return DateTime.fromJSDate(dateTime).startOf('week').toJSDate();
    } else if (groupBy === 2) {
      //months
      dateTime.setMilliseconds(0);
      dateTime.setSeconds(0);
      dateTime.setMinutes(0);
      dateTime.setHours(0);
      dateTime.setDate(1);
      return dateTime;
    } else if (groupBy === 3) {
      //quarter
      let quarter = Math.floor(dateTime.getMonth() / 3);
      dateTime.setMilliseconds(0);
      dateTime.setSeconds(0);
      dateTime.setMinutes(0);
      dateTime.setHours(0);
      dateTime.setDate(1);
      if (quarter === 0) {
        dateTime.setMonth(0); // 0 represents January, 11 represents December
      } else {
        dateTime.setMonth(quarter * 3);
      }
      return dateTime;
    } else if (groupBy === 4) {
      //years
      dateTime.setMilliseconds(0);
      dateTime.setSeconds(0);
      dateTime.setMinutes(0);
      dateTime.setHours(0);
      dateTime.setDate(1);
      dateTime.setMonth(0);
      return dateTime;
    } else if (groupBy === 5) {
      //milliseconds
      return dateTime;
    } else if (groupBy === 6) {
      //hours
      dateTime.setMilliseconds(0);
      dateTime.setSeconds(0);
      dateTime.setMinutes(0);
      return dateTime;
    } else {
      return undefined;
    }
  }
}