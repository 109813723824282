<form class="k-form" [formGroup]="formGroup">
    <fieldset class="k-form-fieldset">
        <legend class="k-form-legend">{{ 'LABEL.GENERAL' | translate}}</legend>
        <kendo-formfield>
            <kendo-label [for]="title" text="{{ 'WIDGET.TITLE' | translate }}"></kendo-label>
            <input #title formControlName="title" kendoTextBox />
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="widgetType" text="{{ 'WIDGET.TYPE' | translate }}"></kendo-label>
            <kendo-textbox #widgetType formControlName="widgetType" [readonly]="true" ></kendo-textbox>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="dataSourceId" text="{{ 'WIDGET.DATASOURCE' | translate }}"></kendo-label>
            <ats-platform-entity-lookup-textbox #dataSourceId formControlName="dataSourceId"
                [settings]="dataSourceSelectionPopupSettings" [formatter]="dataSourceFormatter"
                (entityChange)="onDataSourceChange($event)"></ats-platform-entity-lookup-textbox>
            <kendo-formerror *ngIf="formGroup.controls['dataSourceId'].errors?.['required']">{{
                'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="itemId" text="{{ 'WIDGET.TAG' | translate }}"></kendo-label>
            <kendo-combobox #itemId formControlName="itemId" [data]="dataSourceItems" textField="name" valueField="id"
                [valuePrimitive]="true" [loading]="dataSourceItemsLoading"></kendo-combobox>
            <kendo-formerror *ngIf="formGroup.controls['itemId'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' |
                translate }}</kendo-formerror>
        </kendo-formfield>
    </fieldset>
    <fieldset class="k-form-fieldset">
        <legend class="k-form-legend">{{ 'LABEL.SETTINGS' | translate}}</legend>
        <kendo-formfield>
            <kendo-label [for]="width" text="{{ 'WIDGET.WIDTH' | translate }}"></kendo-label>
            <kendo-numerictextbox #width formControlName="width" [min]="1" [decimals]="0" [format]="'n0'">
            </kendo-numerictextbox>
            <kendo-formerror *ngIf="formGroup.controls['width'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' |
                translate }}</kendo-formerror>
            <kendo-formerror *ngIf="formGroup.controls['width'].errors?.['minError']">{{
                translate.get('i18n:MESSAGE.VALIDATION_MINIMUM', formGroup.controls.width.errors.minError) }}
            </kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="height" text="{{ 'WIDGET.HEIGHT' | translate }}"></kendo-label>
            <kendo-numerictextbox #height formControlName="height" [min]="1" [decimals]="0" [format]="'n0'">
            </kendo-numerictextbox>
            <kendo-formerror *ngIf="formGroup.controls['height'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' |
                translate }}</kendo-formerror>
            <kendo-formerror *ngIf="formGroup.controls['height'].errors?.['minError']">{{
                translate.get('i18n:MESSAGE.VALIDATION_MINIMUM', formGroup.controls.height.errors.minError)}}
            </kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="innerRadius" text="{{ 'WIDGET.INNERRADIUS' | translate }}"></kendo-label>
            <kendo-numerictextbox #innerRadius formControlName="innerRadius" [min]="1" [decimals]="0" [format]="'n0'">
            </kendo-numerictextbox>
            <kendo-formerror *ngIf="formGroup.controls['innerRadius'].errors?.['required']">{{
                'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
            <kendo-formerror *ngIf="formGroup.controls['innerRadius'].errors?.['minError']">{{
                translate.get('i18n:MESSAGE.VALIDATION_MINIMUM', formGroup.controls.innerRadius.errors.minError)}}
            </kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="outerRadius" text="{{ 'WIDGET.OUTERRADIUS' | translate }}"></kendo-label>
            <kendo-numerictextbox #outerRadius formControlName="outerRadius" [min]="1" [decimals]="0" [format]="'n0'">
            </kendo-numerictextbox>
            <kendo-formerror *ngIf="formGroup.controls['outerRadius'].errors?.['required']">{{
                'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
            <kendo-formerror *ngIf="formGroup.controls['outerRadius'].errors?.['minError']">{{
                translate.get('i18n:MESSAGE.VALIDATION_MINIMUM', formGroup.controls.outerRadius.errors.minError)}}
            </kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="startAngle" text="{{ 'WIDGET.STARTANGLE' | translate }}"></kendo-label>
            <kendo-numerictextbox #startAngle formControlName="startAngle" [min]="-360" [max]="360" [decimals]="0"
                [format]="'n0'"></kendo-numerictextbox>
            <kendo-formerror *ngIf="formGroup.controls['startAngle'].errors?.['required']">{{
                'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
            <kendo-formerror *ngIf="formGroup.controls['startAngle'].errors?.['minError']">{{
                translate.get('i18n:MESSAGE.VALIDATION_MINIMUM', formGroup.controls.startAngle.errors.minError) }}
            </kendo-formerror>
            <kendo-formerror *ngIf="formGroup.controls['startAngle'].errors?.['maxError']">{{
                translate.get('i18n:MESSAGE.VALIDATION_MAXIMUM', formGroup.controls.startAngle.errors.maxError) }}
            </kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="endAngle" text="{{ 'WIDGET.ENDANGLE' | translate }}"></kendo-label>
            <kendo-numerictextbox #endAngle formControlName="endAngle" [min]="-360" [max]="360" [decimals]="0"
                [format]="'n0'"></kendo-numerictextbox>
            <kendo-formerror *ngIf="formGroup.controls['endAngle'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED'
                | translate }}</kendo-formerror>
            <kendo-formerror *ngIf="formGroup.controls['endAngle'].errors?.['minError']">{{
                translate.get('i18n:MESSAGE.VALIDATION_MINIMUM', formGroup.controls.endAngle.errors.minError) }}
            </kendo-formerror>
            <kendo-formerror *ngIf="formGroup.controls['endAngle'].errors?.['maxError']">{{
                translate.get('i18n:MESSAGE.VALIDATION_MAXIMUM', formGroup.controls.endAngle.errors.maxError) }}
            </kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="minValue" text="{{ 'WIDGET.MINVALUE' | translate }}"></kendo-label>
            <kendo-numerictextbox #minValue formControlName="minValue"></kendo-numerictextbox>
            <kendo-formerror *ngIf="formGroup.controls['minValue'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED'
                | translate }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="maxValue" text="{{ 'WIDGET.MAXVALUE' | translate }}"></kendo-label>
            <kendo-numerictextbox #maxValue formControlName="maxValue"></kendo-numerictextbox>
            <kendo-formerror *ngIf="formGroup.controls['maxValue'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED'
                | translate }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="numberOfDecimals" text="{{ 'WIDGET.NUMBEROFDECIMALS' | translate }}"></kendo-label>
            <kendo-numerictextbox #numberOfDecimals formControlName="numberOfDecimals" [min]="0" [decimals]="0"
                [format]="'n0'"></kendo-numerictextbox>
            <kendo-formerror *ngIf="formGroup.controls['numberOfDecimals'].errors?.['required']">{{
                'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
            <kendo-formerror *ngIf="formGroup.controls['numberOfDecimals'].errors?.['minError']">{{
                translate.get('i18n:MESSAGE.VALIDATION_MINIMUM', formGroup.controls.numberOfDecimals.errors.minError) }}
            </kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="numberOfMajorTicks" text="{{ 'WIDGET.NUMBEROFMAJORTICKS' | translate }}"></kendo-label>
            <kendo-numerictextbox #numberOfMajorTicks formControlName="numberOfMajorTicks" [min]="1" [decimals]="0"
                [format]="'n0'"></kendo-numerictextbox>
            <kendo-formerror *ngIf="formGroup.controls['numberOfMajorTicks'].errors?.['required']">{{
                'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
            <kendo-formerror *ngIf="formGroup.controls['numberOfMajorTicks'].errors?.['minError']">{{
                translate.get('i18n:MESSAGE.VALIDATION_MINIMUM', formGroup.controls.numberOfMajorTicks.errors.minError)
                }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="numberOfMinorTicks" text="{{ 'WIDGET.NUMBEROFMINORTICKS' | translate }}"></kendo-label>
            <kendo-numerictextbox #numberOfMinorTicks formControlName="numberOfMinorTicks" [min]="1" [decimals]="0"
                [format]="'n0'"></kendo-numerictextbox>
            <kendo-formerror *ngIf="formGroup.controls['numberOfMinorTicks'].errors?.['required']">{{
                'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
            <kendo-formerror *ngIf="formGroup.controls['numberOfMinorTicks'].errors?.['minError']">{{
                translate.get('i18n:MESSAGE.VALIDATION_MINIMUM', formGroup.controls.numberOfMinorTicks.errors.minError)
                }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="defaultRangeColor" text="{{ 'WIDGET.DEFAULTRANGECOLOR' | translate }}"></kendo-label>
            <kendo-colorpicker #defaultRangeColor formControlName="defaultRangeColor" activeView="palette" [format]="'hex'">
            </kendo-colorpicker>
            <kendo-formerror *ngIf="formGroup.controls['defaultRangeColor'].errors?.['required']">{{
                'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="majorTicksColor" text="{{ 'WIDGET.MAJORTICKSCOLOR' | translate }}"></kendo-label>
            <kendo-colorpicker #majorTicksColor formControlName="majorTicksColor" activeView="palette" [format]="'hex'">
            </kendo-colorpicker>
            <kendo-formerror *ngIf="formGroup.controls['majorTicksColor'].errors?.['required']">{{
                'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="minorTicksColor" text="{{ 'WIDGET.MINORTICKSCOLOR' | translate }}"></kendo-label>
            <kendo-colorpicker #minorTicksColor formControlName="minorTicksColor" activeView="palette" [format]="'hex'">
            </kendo-colorpicker>
            <kendo-formerror *ngIf="formGroup.controls['minorTicksColor'].errors?.['required']">{{
                'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="needleColor" text="{{ 'WIDGET.NEEDLECOLOR' | translate }}"></kendo-label>
            <kendo-colorpicker #needleColor formControlName="needleColor" activeView="palette" [format]="'hex'">
            </kendo-colorpicker>
            <kendo-formerror *ngIf="formGroup.controls['needleColor'].errors?.['required']">{{
                'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="valueColor" text="{{ 'WIDGET.VALUECOLOR' | translate }}"></kendo-label>
            <kendo-colorpicker #valueColor formControlName="valueColor" activeView="palette" [format]="'hex'">
            </kendo-colorpicker>
            <kendo-formerror *ngIf="formGroup.controls['valueColor'].errors?.['required']">{{
                'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="setpointColor" text="{{ 'WIDGET.SETPOINTCOLOR' | translate }}"></kendo-label>
            <kendo-colorpicker #setpointColor formControlName="setpointColor" activeView="palette" [format]="'hex'">
            </kendo-colorpicker>
            <kendo-formerror *ngIf="formGroup.controls['setpointColor'].errors?.['required']">{{
                'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
    </fieldset>
    <fieldset class="k-form-fieldset">
        <legend class="k-form-legend">{{ 'i18n:TAG_WIDGET.RANGES' | translate }}</legend>
        <kendo-grid [data]="ranges" [height]="410" style="margin-bottom: 16px;" [selectable]="{ mode: 'multiple', drag: true }"
            kendoGridSelectBy="id" [(selectedKeys)]="selectedRangeIds" (cellClick)="cellClick($event)"
            (cellClose)="cellClose($event)">
            <ng-template kendoGridToolbarTemplate>
                <button kendoButton look="outline" (click)="addRange()">{{ 'i18n:TAG_WIDGET.RANGES.ADD' | translate
                    }}</button>
                <button kendoButton look="outline" [disabled]="selectedRangeIds.length == 0" (click)="removeRanges()">{{
                    'i18n:TAG_WIDGET.RANGES.REMOVE' | translate }}</button>
            </ng-template>

            <kendo-grid-column [title]="translate.get('i18n:TAG_WIDGET.RANGE.STARTVALUE')" [field]="'startValue'"
                [editor]="'number'"></kendo-grid-column>
            <kendo-grid-column [title]="translate.get('i18n:TAG_WIDGET.RANGE.ENDVALUE')" [field]="'endValue'"
                [editor]="'number'"></kendo-grid-column>
            <kendo-grid-column [title]="translate.get('i18n:TAG_WIDGET.RANGE.COLOR')" [field]="'color'">
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                    <div class="selected-color" [ngStyle]="{ 'background-color': dataItem.color }"></div>
                </ng-template>
                <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column"
                    let-formGroup="formGroup">
                    <kendo-colorpicker activeView="palette" [format]="'hex'" [formControl]="formGroup.get('color')">
                    </kendo-colorpicker>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </fieldset>
</form>