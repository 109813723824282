import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { HubConnection } from '@aspnet/signalr';
import { CoreService, DashboardService, DataSourceInstance, ItemValueChangedEventArgs, TranslateService, WidgetComponent, WidgetInstance, WidgetUpdater } from '@ats/ats-platform-dashboard';
import { assign, find, findIndex, isEmpty, isString, orderBy, pull, remove, some } from 'lodash-es';
import { Subscription } from 'rxjs';
import { EVChargingSessionsHubService } from '../../services/ev-charging-sessions-hub.service';
import { EVChargingSession } from '../../domain/entities/evChargingSession';
import { DateTime } from 'luxon';
import { CompositeFilterDescriptor, FilterDescriptor, SortDescriptor, filterBy } from '@progress/kendo-data-query';
import { ColumnMenuSettings, FilterService, MultipleSortSettings } from '@progress/kendo-angular-grid';
import { EV_SESSION_STATUS } from '../../domain/enums/evSessionStatus';

@Component({
  selector: 'ats-smart-tool-ev-charging-sessions-widget',
  templateUrl: './ev-charging-sessions-widget.component.html',
  styleUrls: ['./ev-charging-sessions-widget.component.scss']
})
export class EVChargingSessionsWidgetComponent implements WidgetComponent, OnInit, OnDestroy {
  @Input() hubConnection: HubConnection;

  public widgetInstance: WidgetInstance;
  private widgetConfigSubscription: Subscription;

  public isLoading = false;
  public error: string;

  public entityTranslationKey: string;

  private connectedSubscription: Subscription;
  private connectedMessageSubscription: Subscription;
  private widgetUpdaters: WidgetUpdater[];

  private historyOrActive = -1;

  public sort: SortDescriptor[] = [{ field: 'StartDateTime', dir: 'asc' }];
  public sortSettings: MultipleSortSettings = { mode: 'multiple', allowUnsort: true, showIndexes: true };
  public evChargingSessionStatuses: {Key: number, Display: string}[];

  public getStatusDescription = (value: number) => find(this.evChargingSessionStatuses, x => x.Key == value)?.Display;

  public filter: CompositeFilterDescriptor = { logic: "and", filters: [] };
  public statusFilterValue: number[] = [];
  public isStatusSelected = (item: { Key: number }) => this.statusFilterValue.some(x => x === item.Key);
  public onStatusSelectionChange = (item: { Key: number }, filter: CompositeFilterDescriptor, filterService: FilterService) => {
    if (this.isStatusSelected(item)) {
      pull(this.statusFilterValue, item.Key);
    } else {
      this.statusFilterValue.push(item.Key);
    }

    filterService.filter({
      filters: this.statusFilterValue.map(value => ({
        field: 'Status',
        operator: 'eq',
        value: value
      })),
      logic: 'or'
    });
  }

  public gridData: EVChargingSession[];

  public filterChange = (filter: CompositeFilterDescriptor) => {
    this.filter = filter;

    this.setSessions(this.widgetInstance?.widgetData?.sessions);

    let newStatusFilterValue = [];
    let checkFilters = (filter: FilterDescriptor | CompositeFilterDescriptor) => {
      let composedFilter = filter as CompositeFilterDescriptor;
      if (composedFilter?.filters) {
        composedFilter.filters.forEach((child) => {
          checkFilters(child);
        });
      }

      let fieldFilter = filter as FilterDescriptor;
      if (fieldFilter.field === 'Status' && fieldFilter.operator === 'eq') {
        newStatusFilterValue.push(fieldFilter.value as number);
      }
    }
    checkFilters(filter);

    this.statusFilterValue = newStatusFilterValue;

  }

  public menuSettings: ColumnMenuSettings = {
  };

  constructor(public translate: TranslateService, public core: CoreService, private evChargingSessionsHub: EVChargingSessionsHubService, private dashboardService: DashboardService) {
    this.entityTranslationKey = 'i18n:EV_CHARGING_SESSIONS';

    this.evChargingSessionStatuses = this.core.translateObjectArray(EV_SESSION_STATUS, 'Display');
  }

  public setWidgetInstance(widgetInstance: WidgetInstance) {
    this.widgetInstance = widgetInstance;
  }

  protected getTitle(instance: WidgetInstance): string {
    return isEmpty(instance?.widgetConfig?.title) ? this.translate.get('i18n:EV_CHARGING_SESSIONS'):  instance.widgetConfig.title;
  }

  ngOnInit() {
    this.widgetConfigSubscription = this.widgetInstance.changed.subscribe({
      next: (instance: WidgetInstance) => { 
        if (instance.widgetData) {
          instance.widgetData.title = this.getTitle(instance);
          this.loadSystemEvents();
        }
      }
    });

    setTimeout(() => {
      this.widgetInstance.widgetData = {
        title: this.getTitle(this.widgetInstance),
        sessions: null
      };

      this.loadSystemEvents();
    });
  }

  ngOnDestroy() {
    this.cleanupSignalR();
    this.cleanupDataSource();

    if (this.widgetConfigSubscription) {
      this.widgetConfigSubscription.unsubscribe();
      this.widgetConfigSubscription = null;
    }
  }

  private loadSystemEvents(): void {
    if (!this.widgetInstance || !this.widgetInstance.widgetConfig)
      return;

    let historyOrActiveChanged = this.widgetInstance.widgetConfig.historyOrActive !== this.historyOrActive;

    if (historyOrActiveChanged && this.historyOrActive !== -1)
    {
      if (this.historyOrActive === 1) {
        this.cleanupDataSource();
      } else {
        this.cleanupSignalR();
      }
    }

    this.historyOrActive = this.widgetInstance.widgetConfig.historyOrActive;

    if (this.historyOrActive === 1) {
      this.useDataSource(this.widgetInstance, historyOrActiveChanged);
    } else {
      this.useSignalR(this.widgetInstance, historyOrActiveChanged);
    }
  }

  protected useSignalR(instance: WidgetInstance, historyOrActiveChanged: boolean) {
    if (historyOrActiveChanged) {
      this.subscribeToConnection();
      this.watch();
    }

    this.evChargingSessionsHub.invoke<EVChargingSession[]>('Subscribe', this.widgetInstance.widgetConfig.assetId).subscribe({
      next: (result: EVChargingSession[]) => {
        this.setSessions(result);
      },
      error: (err: any) => this.error = err
    });
  }

  private subscribeToConnection(): void {
    this.connectedSubscription = this.evChargingSessionsHub.isConnected$.subscribe({
      next: (connected: boolean) => {
        if (connected === false) {
          if (this.widgetInstance.widgetData?.sessions)
            this.widgetInstance.widgetData.sessions = null;
        } else {
          this.loadSystemEvents();
        }
      }
    });

    this.connectedMessageSubscription = this.evChargingSessionsHub.connectedMessage$.subscribe({
      next: (message: string) => {
        if (message == null) {
          this.error = null;
        } else {
          this.error = message;
        }
      }
    });
  }

  private watch(): void {
    this.evChargingSessionsHub.on('broadcastEVChargingSession', (created: boolean, session: EVChargingSession) => {
      let sessions: EVChargingSession[] = this.widgetInstance.widgetData?.sessions;
      if (!sessions)
        return;

      let tmp = find(sessions, x => x.Id == session.Id);

      let index = findIndex(sessions, x => x.Id === session.Id);
      if (tmp) {
        if (session.Status == 1) {
          // session is in list and is still active -> update
          assign(tmp, session);
          this.setSessions(sessions);
        } else {
          remove(sessions, tmp);
          this.setSessions(sessions);
        }
      } else if (session.Status == 1) {
        sessions.push(session);
        this.setSessions(sessions);
      }
    });
  }

  protected cleanupSignalR() {
    this.evChargingSessionsHub.off('broadcastEVChargingSession', null);

    if (this.connectedSubscription) {
      this.connectedSubscription.unsubscribe();
      this.connectedSubscription = null;
    }

    if (this.connectedMessageSubscription) {
      this.connectedMessageSubscription.unsubscribe();
      this.connectedMessageSubscription = null;
    }
  }

  protected useDataSource(instance: WidgetInstance, historyOrActiveChanged: boolean) {
    if (this.widgetUpdaters) {
      this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
    }

    const dataSourceInstance = find(this.dashboardService.dataSourceInstances, (dataSourceInstance: DataSourceInstance) => dataSourceInstance.id == instance.widgetConfig.dataSourceId);

    if (dataSourceInstance) {
      if (isEmpty(instance.widgetData.title))
        instance.widgetData.title = dataSourceInstance.name;

      this.widgetUpdaters = [];
      this.widgetUpdaters.push(new WidgetUpdater(dataSourceInstance, null, this.update, this.updateItem));
    }
  }
  
  protected update = (instance: DataSourceInstance, nativeElement: HTMLElement) => {
    this.isLoading = instance?.result?.isLoading;

    if (!this.widgetInstance.widgetData.title)
      this.widgetInstance.widgetData.title = instance.name;
        
    if (instance.result && instance.result.data) {
      var sessions: EVChargingSession[] = instance.result.data;
      this.setSessions(sessions);
    }
  };

  protected updateItem = (dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement, event: ItemValueChangedEventArgs) => {
      //No need to change if data from item changed
  };

  protected cleanupDataSource() {
    if (this.widgetUpdaters) {
      this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
    }
  }

  private setSessions(sessions: EVChargingSession[]): void {
    if (!sessions)
      return;

    sessions.forEach(x => {
      if (isString(x.StartDateTime)) x.StartDateTime = DateTime.fromISO(x.StartDateTime).toJSDate();
      if (isString(x.EndDateTime)) x.EndDateTime = DateTime.fromISO(x.EndDateTime).toJSDate();
      if (isString(x.ChargingEndTime)) x.ChargingEndTime = DateTime.fromISO(x.ChargingEndTime).toJSDate();
      if (isString(x.EstimatedDepartureTime)) x.EstimatedDepartureTime = DateTime.fromISO(x.EstimatedDepartureTime).toJSDate();
      if (isString(x.LastUpdated)) x.LastUpdated = DateTime.fromISO(x.LastUpdated).toJSDate();
    });

    this.widgetInstance.widgetData.sessions = sessions;
    this.gridData = filterBy(this.widgetInstance?.widgetData?.sessions, this.filter);
  }

  public onChartClick(session: EVChargingSession) {
    if (!this.widgetInstance?.widgetConfig?.chartId)
      return;

    this.core.navigate(['chart', this.widgetInstance.widgetConfig.chartId, 'asset', session.AssetId], {
      state: {
        periodSelection: {
          from: { type: 0, fixedDateTime: session.StartDateTime, beginEndOf: 0, offset: 1, unit: 2, period: 0 },
          to: { type: session.EndDateTime ? 0 : 1, fixedDateTime: session.EndDateTime, beginEndOf: 0, offset: 1, unit: 2, period: 0 },
          period: { offset: 0, unit: 2, type: 1 }
        }
      }
    });
  }
}
