import { Component, Inject, Injector } from '@angular/core';
import { keyBy, flatten, clone, remove } from "lodash-es";
import { v4 as uuidv4 } from 'uuid';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CoreService, DashboardWidgetDocument, DataSource, DataSourceType, DATASOURCETYPES, EntitySelectionPopupSettings, TranslateService, WidgetConfigComponent } from '@ats/ats-platform-dashboard';
import { CellClickEvent, CellCloseEvent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'ats-smart-tool-tag-linear-gauge-widget-config',
  templateUrl: './tag-linear-gauge-widget-config.component.html',
  styleUrls: ['./tag-linear-gauge-widget-config.component.scss']
})
export class TagLinearGaugeWidgetConfigComponent implements WidgetConfigComponent {

  public dataSourceSelectionPopupSettings: EntitySelectionPopupSettings;
  public dataSourceFormatter = (dataSource: DataSource) => dataSource ? dataSource.Folder.Path + ' / ' + dataSource.Name : null;
  
  public fg: FormGroup;
  public formGroup: FormGroup;
  public dataSourceItems: { id: string, name: string }[] = [];
  public dataSourceItemsLoading: boolean = false;
  public ranges: { id: string, startValue: number, endValue: number, color: string }[];
  public selectedRangeIds: string[];

  public directions: { Key: string, Display: string }[];
  public scalePositions: { Key: string, Display: string }[];

  private dataSourceTypes: { [type: string]: DataSourceType };

  constructor(public core: CoreService, public translate: TranslateService, @Inject(DATASOURCETYPES) dataSourceTypes: DataSourceType[][], private injector: Injector) {

    this.dataSourceTypes = keyBy(flatten(dataSourceTypes), (type: DataSourceType) => type.name);

    this.directions = [
      { Key: 'lefttoright', Display: translate.get('i18n:TAG_WIDGET.DIRECTION.LEFTTORIGHT') },
      { Key: 'righttoleft', Display: translate.get('i18n:TAG_WIDGET.DIRECTION.RIGHTTOLEFT') },
      { Key: 'bottomtotop', Display: translate.get('i18n:TAG_WIDGET.DIRECTION.BOTTOMTOTOP') },
      { Key: 'toptobottom', Display: translate.get('i18n:TAG_WIDGET.DIRECTION.TOPTOBOTTOM') }
    ];

    this.scalePositions = [
      { Key: 'top', Display: translate.get('i18n:TAG_WIDGET.SCALEPOSITION.TOP') },
      { Key: 'bottom', Display: translate.get('i18n:TAG_WIDGET.SCALEPOSITION.BOTTOM') },
      { Key: 'left', Display: translate.get('i18n:TAG_WIDGET.SCALEPOSITION.LEFT') },
      { Key: 'right', Display: translate.get('i18n:TAG_WIDGET.SCALEPOSITION.RIGHT') }
    ];

    this.formGroup = new FormGroup({
      title: new FormControl(''),
      dataSourceId: new FormControl(null, Validators.required),
      itemId: new FormControl(null, Validators.required),
      direction: new FormControl(null, Validators.required),
      scalePosition: new FormControl(null, Validators.required),
      width: new FormControl(null, Validators.compose([Validators.required, Validators.min(1)])),
      height: new FormControl(null, Validators.compose([Validators.required, Validators.min(1)])),
      barWidth: new FormControl(null, Validators.compose([Validators.required, Validators.min(1)])),
      minValue: new FormControl(null, Validators.required),
      maxValue: new FormControl(null, Validators.required),
      numberOfDecimals: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
      numberOfMajorTicks: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
      numberOfMinorTicks: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
      defaultRangeColor: new FormControl(null, Validators.required),
      majorTicksColor: new FormControl(null, Validators.required),
      minorTicksColor: new FormControl(null, Validators.required),
      valuePointerColor: new FormControl(null, Validators.required),
      valueColor: new FormControl(null, Validators.required),
      setpointColor: new FormControl(null, Validators.required),
      widgetType: new FormControl(translate.get('TAG_LINEAR_GAUGE_WIDGET'))
    });

    this.dataSourceSelectionPopupSettings = {
      title: translate.get('i18n:SELECT_DATASOURCE'),
      entitySet: 'DataSources',
      filter: {
        logic: 'or',
        filters: [
          { field: 'Type', operator: 'eq', value: 'tags' },
          { field: 'Type', operator: 'eq', value: 'assetTags' }
        ]
      },
      includes: 'Configs,Folder',
      sort: [{ field: 'Folder.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
      multiSelect: false,
      selectionRequired: true,
      columns: [{ field: 'Folder.Path', title: this.translate.get('i18n:FOLDER.PATH'), filterable: true },{ field: 'Name', title: translate.get('i18n:DATASOURCE.NAME'), filterable: true }],
      info: translate.get('i18n:WIDGET.SUPPORTED_DATATYPES') +': '+ translate.get('i18n:DATASOURCE.TAGS.TAG')+', '+ translate.get('i18n:DATASOURCE.ASSET')
    };

    this.ranges = [];
    this.selectedRangeIds = [];
  }

  public onDataSourceChange = (dataSource: DataSource) => {
    if (dataSource) {
      const dataSourceType: DataSourceType = this.dataSourceTypes[dataSource.Type];
      if (dataSourceType) {
        const loader = this.injector.get(dataSourceType.loader);
        this.dataSourceItemsLoading = true;
        loader.loadItems(dataSource).subscribe({
          next: (items: { id: string; name: string }[]) => {
            this.dataSourceItemsLoading = false;
            this.dataSourceItems = items;
          }, error: (err: any) => {
            this.dataSourceItemsLoading = false;
            this.core.showErrorDetail(err);
          }
        });
      } else {
        this.dataSourceItems = [];
      }
    } else {
      this.dataSourceItems = [];
    }
  };

  public isValid(): boolean {
    this.formGroup.markAllAsTouched();
    return this.formGroup.valid;
  }

  public getConfig(): any {
    return {
      title: this.formGroup.get('title').value,
      dataSourceId: this.formGroup.get('dataSourceId').value,
      itemId: this.formGroup.get('itemId').value,
      direction: this.formGroup.get('direction').value,
      scalePosition: this.formGroup.get('scalePosition').value,
      width: this.formGroup.get('width').value,
      height: this.formGroup.get('height').value,
      barWidth: this.formGroup.get('barWidth').value,
      minValue: this.formGroup.get('minValue').value,
      maxValue: this.formGroup.get('maxValue').value,
      numberOfDecimals: this.formGroup.get('numberOfDecimals').value,
      numberOfMajorTicks: this.formGroup.get('numberOfMajorTicks').value,
      numberOfMinorTicks: this.formGroup.get('numberOfMinorTicks').value,
      defaultRangeColor: this.formGroup.get('defaultRangeColor').value,
      majorTicksColor: this.formGroup.get('majorTicksColor').value,
      minorTicksColor: this.formGroup.get('minorTicksColor').value,
      valuePointerColor: this.formGroup.get('valuePointerColor').value,
      valueColor: this.formGroup.get('valueColor').value,
      setpointColor: this.formGroup.get('setpointColor').value,
      ranges: this.ranges
    };
  }

  public getDocuments(): DashboardWidgetDocument[] {
    return null;
  }

  public setData(config: any, documents: DashboardWidgetDocument[]) {
    this.formGroup.get('title').setValue(config?.title ?? '');
    this.formGroup.get('dataSourceId').setValue(config?.dataSourceId);
    this.formGroup.get('itemId').setValue(config?.itemId);
    this.formGroup.get('direction').setValue(config?.direction);
    this.formGroup.get('scalePosition').setValue(config?.scalePosition);
    this.formGroup.get('width').setValue(config?.width);
    this.formGroup.get('height').setValue(config?.height);
    this.formGroup.get('barWidth').setValue(config?.barWidth);
    this.formGroup.get('minValue').setValue(config?.minValue);
    this.formGroup.get('maxValue').setValue(config?.maxValue);
    this.formGroup.get('numberOfDecimals').setValue(config?.numberOfDecimals);
    this.formGroup.get('numberOfMajorTicks').setValue(config?.numberOfMajorTicks);
    this.formGroup.get('numberOfMinorTicks').setValue(config?.numberOfMinorTicks);
    this.formGroup.get('defaultRangeColor').setValue(config?.defaultRangeColor);
    this.formGroup.get('majorTicksColor').setValue(config?.majorTicksColor);
    this.formGroup.get('minorTicksColor').setValue(config?.minorTicksColor);
    this.formGroup.get('valuePointerColor').setValue(config?.valuePointerColor);
    this.formGroup.get('valueColor').setValue(config?.valueColor);
    this.formGroup.get('setpointColor').setValue(config?.setpointColor);

    this.ranges = config?.ranges || [];
  }

  public cellClick(event: CellClickEvent) {
    if (!event.isEdited) {
      event.sender.editCell(event.rowIndex, event.columnIndex, this.createFormGroup(event.dataItem));
    }
  }

  public cellClose(event: CellCloseEvent) {
    const formControl: FormControl = event.formGroup.get(event.column.field);
    if (formControl) {
      if (!formControl.valid) {
        event.preventDefault();
      } else if (formControl.dirty) {
        event.dataItem[event.column.field] = formControl.value;
      }
    }
  }

  public createFormGroup(dataItem: { startValue: number, endValue: number, color: string }) {
    return new FormGroup({
      startValue: new FormControl(dataItem.startValue, Validators.required),
      endValue: new FormControl(dataItem.endValue, Validators.required),
      color: new FormControl(dataItem.color, Validators.required)
    });
  }

  public addRange() {
    this.ranges.push({ id: uuidv4(), startValue: 0, endValue: 0, color: '#000000' });
  }

  public removeRanges() {
    if (this.ranges && this.ranges.length && this.selectedRangeIds && this.selectedRangeIds.length) {
      const ids: string[] = clone(this.selectedRangeIds);
      ids.forEach((id: string) => {
        remove(this.ranges, (item) => item.id == id);
      });
      this.selectedRangeIds = [];
    }
  }
}
