import { Component } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { CoreService, TranslateService } from '@ats/ats-platform-dashboard';

@Component({
    selector: 'ats-smart-tool-tag-value-write-popup',
    templateUrl: './tag-value-write-popup.component.html',
    styleUrls: ['./tag-value-write-popup.component.scss']
})
export class TagValueWritePopupComponent {

    public extensions: string[];

    public dataType: number;
    public formGroup: FormGroup;
    public errorMessage: string;

    constructor(private core: CoreService, public translate: TranslateService) {
        this.formGroup = new FormGroup({
            booleanValue: new FormControl(null, Validators.required),
            integerValue: new FormControl(null, Validators.required),
            floatingPointValue: new FormControl(null, Validators.required),
            stringValue: new FormControl(null, Validators.required),
            dateTimeValue: new FormControl(null, Validators.required)
        });
    }

    public isValid(): boolean {

        let formControl: AbstractControl;

        switch (this.dataType) {
            case 0: // bool
                formControl = this.formGroup.get('booleanValue');
                break;
            case 1: // short
            case 2: // int
            case 8: // ushort
            case 9: // uint
                formControl = this.formGroup.get('integerValue');
                break;
            case 3: // float
                formControl = this.formGroup.get('floatingPointValue');
                break;
            case 4: // string
                formControl = this.formGroup.get('stringValue');
                break;
            case 5: // date
            case 6: // time
            case 7: // datetime
                formControl = this.formGroup.get('dateTimeValue');
                break;
        }

        if (formControl) {
            formControl.markAllAsTouched();
            return formControl.valid;
        } else 
        {
            return false;
        }
    }
}