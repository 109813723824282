import { Component, Inject, Injector } from '@angular/core';
import { keyBy, flatten } from "lodash-es";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CoreService, DashboardWidgetDocument, DataSource, DataSourceType, DATASOURCETYPES, EntitySelectionPopupSettings, TranslateService, WidgetConfigComponent } from '@ats/ats-platform-dashboard';

@Component({
  selector: 'ats-smart-tool-weather-forecast-hourly-widget-config',
  templateUrl: './weather-forecast-hourly-widget-config.component.html',
  styleUrls: ['./weather-forecast-hourly-widget-config.component.scss']
})
export class WeatherForecastHourlyWidgetConfigComponent implements WidgetConfigComponent {

  public dataSourceSelectionPopupSettings: EntitySelectionPopupSettings;
  public dataSourceFormatter = (dataSource: DataSource) => dataSource ? dataSource.Folder.Path + ' / ' + dataSource.Name : null;
  
  public formGroup: FormGroup;
  public dataSourceItems: { id: string, name: string }[] = [];
  public dataSourceItemsLoading: boolean = false;

  private dataSourceTypes: { [type: string]: DataSourceType };

  constructor(public core: CoreService, public translate: TranslateService, @Inject(DATASOURCETYPES) dataSourceTypes: DataSourceType[][], private injector: Injector) {

    this.dataSourceTypes = keyBy(flatten(dataSourceTypes), (type: DataSourceType) => type.name);

    this.formGroup = new FormGroup({
      title: new FormControl(''),
      dataSourceId: new FormControl(null, Validators.required),
      widgetType: new FormControl(translate.get('WEATHER_FORECAST_HOURLY_WIDGET'))
    });

    this.dataSourceSelectionPopupSettings = {
      title: translate.get('i18n:SELECT_DATASOURCE'),
      entitySet: 'DataSources',
      filter: { field: 'Type', operator: 'eq', value: 'weatherForecast' },
      includes: 'Configs,Folder',
      sort: [{ field: 'Folder.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
      multiSelect: false,
      selectionRequired: true,
      columns: [{ field: 'Folder.Path', title: this.translate.get('i18n:FOLDER.PATH'), filterable: true },{ field: 'Name', title: translate.get('i18n:DATASOURCE.NAME'), filterable: true }],
      info: translate.get('i18n:WIDGET.SUPPORTED_DATATYPES') +': '+ translate.get('i18n:DATASOURCE.WEATHERFORECAST')
    };
  }

  public onDataSourceChange = (dataSource: DataSource) => {
    if (dataSource) {
      const dataSourceType: DataSourceType = this.dataSourceTypes[dataSource.Type];
      if (dataSourceType) {
        const loader = this.injector.get(dataSourceType.loader);
        this.dataSourceItemsLoading = true;
        loader.loadItems(dataSource).subscribe({
          next: (items: { id: string; name: string }[]) => {
            this.dataSourceItemsLoading = false;
            this.dataSourceItems = items;
          }, error: (err: any) => {
            this.dataSourceItemsLoading = false;
            this.core.showErrorDetail(err);
          }
        });
      } else {
        this.dataSourceItems = [];
      }
    } else {
      this.dataSourceItems = [];
    }
  };

  public isValid(): boolean {
    this.formGroup.markAllAsTouched();
    return this.formGroup.valid;
  }

  public getConfig(): any {
    return {
      title: this.formGroup.get('title').value,
      dataSourceId: this.formGroup.get('dataSourceId').value
    };
  }

  public getDocuments(): DashboardWidgetDocument[] {
    return null;
  }

  public setData(config: any, documents: DashboardWidgetDocument[]) {
    this.formGroup.get('title').setValue(config?.title ?? '');
    this.formGroup.get('dataSourceId').setValue(config?.dataSourceId);
  }
}
