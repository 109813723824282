import * as _ from 'lodash';
import { AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild } from "@angular/core";
import { SelectableSettings, SelectionItem } from "@progress/kendo-angular-treelist";
import { CompositeFilterDescriptor, filterBy} from "@progress/kendo-data-query";
import { DialogCloseResult, DialogRef, DialogResult, DialogService } from "@progress/kendo-angular-dialog";
import { ApiQueryOptions, ApiService, CoreService, Folder, FolderPopupComponent, TranslateService, TreeItem } from '@ats/ats-platform-dashboard';
import { ReportsPopupComponent } from '../reports-popup/reports-popup.component';
import { Report } from '../../domain/entities/report';
import { ReportDownloadPopupComponent } from '../report-download-popup/report-download-popup.component';
import { downloadReportCommand } from '../../domain/commands/downloadReportCommand';

@Component({
  selector: 'ats-platform-reports-menu',
  templateUrl: './reports-menu.component.html',
  styleUrls: ['./reports-menu.component.scss']
})
export class ReportsMenuComponent implements AfterViewInit, OnDestroy {

  public dialogRef: DialogRef;
  @ViewChild('actionsReportTemplate') private actionsReportTemplate: TemplateRef<any>;
  @ViewChild('actionsDownloadReportTemplate') private actionsDownloadReportTemplate: TemplateRef<any>;

  public selectableSettings: SelectableSettings;
  public error: string;
  public idField: string = "Id";
  public loading: boolean = true;
  public actionLoading: boolean = false;
  public selectedItem: TreeItem = new TreeItem();
  public selectedItems: {'itemKey': TreeItem}[] = [];
  public selectedFolder: Folder = new Folder();
  public currentFolderId: string = "";
  public filter: CompositeFilterDescriptor;
  public expandedIds: any[] = [];
  private cache: any = new Map();
  public treeData: TreeItem[];
  public loadedData: TreeItem[];

  constructor(private api: ApiService, private core: CoreService, public translate: TranslateService, private dialogService: DialogService) {
    this.selectableSettings = { mode: "row", drag: false, multiple: false, enabled: true, readonly: false };

    this.filter = {
      logic: "or",
      filters: [
      ]
    };
  }

  ngAfterViewInit(): void {
    this.loadData();
  }

  ngOnDestroy(): void {

  }

  private loadData() {
    this.loading = true;
    this.api.executeUnboundFunction('ReportTree', 'GetTree').subscribe({
      next: (result: TreeItem[]) => {
      this.treeData = result;
      this.loadedData = result;
      this.loading = false;
      }, error: (error: any) => {
      this.loading = false;
      this.core.showErrorDetail(error);
      }
    });
  }

  private findSelectedItem(treeItem: TreeItem, treeItemId: string) {
    if (!treeItem) {
      for (let rootItem of this.loadedData) {
        var result = this.findSelectedItem(rootItem, treeItemId);
        if (result) {
          return result;
        }
      }
    } else if (treeItem.Id === treeItemId) {
      return treeItem;
    } else if (treeItem.Children) {
      for (let child of treeItem.Children) {
        var newChild = this.findSelectedItem(child, treeItemId);
        if (newChild)
          return newChild;
      }
    };
  }

  public fetchChildren = (treeItem?: TreeItem): TreeItem[] => {
    if (this.cache.get(treeItem)) {
      return this.cache.get(treeItem);
    }
    let result;
    const items = treeItem? treeItem.Children : this.loadedData;
    if (this.filter && this.filter.filters.length && items) {
      result = filterBy(items, {
        logic: "or",
        filters: [
          this.filter,
          {
            // matches the current filter or a child matches the filter
            operator: (item: any) => {
              if (item.Children) {
                const children = this.fetchChildren(item);
                return children && children.length;
              }
              return undefined;
            },
          },
        ],
      });
    } else {
      result = items;
    }

    this.cache.set(treeItem, result);

    return result;
  } 

  public hasChildren = (treeData: TreeItem): boolean => {
    return treeData.Children && treeData.Children.length > 0;
  }

  public selectedItemsChange(selectedItems: SelectionItem[]) {
    this.selectedItem = this.findSelectedItem(null, selectedItems[0].itemKey);
    if (selectedItems) {
      if (this.selectedItem.Type === 0) { // SelectedItem = folder
        this.currentFolderId = this.selectedItem.Id;
      } else if (this.selectedItem.Type === 4) {
        this.currentFolderId = this.selectedItem.ParentId;
      }
    }
  }

  public onTreeFilterChange(filter: CompositeFilterDescriptor) {
    this.filter = filter;
    this.cache.clear();
    this.treeData = this.fetchChildren();
  }

  public closePopup() {
    this.dialogRef.close();
    this.dialogRef = null;
  }

  public addReport() {
    this.showReportPopup(null, true, this.currentFolderId);
  }

  public updateReport() {
    this.actionLoading = true;
      this.api.getSingleResult('Reports', this.selectedItem.Id, new ApiQueryOptions({ include: 'ParameterValues.ReportParameter,DataSources,ReportDefinition,ReportDefinition.Parameters,ReportDefinition.DataSources,AccessRights.User,AccessRights.Role' })).subscribe({
      next: (report: Report) => {
      this.showReportPopup(report, false, null);
      this.actionLoading = false;
      }, error: (error: any) => {
      this.actionLoading = false;
      this.core.showErrorDetail(error)
      }
    });
  }

  public duplicateReport() {
    this.actionLoading = true;
      this.api.getSingleResult('Reports', this.selectedItem.Id, new ApiQueryOptions({ include: 'ParameterValues.ReportParameter,DataSources,ReportDefinition,ReportDefinition.Parameters,ReportDefinition.DataSources,AccessRights.User,AccessRights.Role' })).subscribe({
      next: (report: Report) => {
      this.showReportPopup(report, true, null);;
      this.actionLoading = false;
      }, error: (error: any) => {
      this.actionLoading = false;
      this.core.showErrorDetail(error)
      }
    });
  }

  public downloadReport() {
    this.actionLoading = true;
    this.api.getSingleResult('Reports', this.selectedItem.Id, new ApiQueryOptions({ include: 'ParameterValues.ReportParameter,DataSources,ReportDefinition,ReportDefinition.Parameters,ReportDefinition.DataSources,AccessRights.User,AccessRights.Role' })).subscribe({
      next: (report: Report) => {
      this.showDownloadReportPopup(report);
      this.actionLoading = false;
      }, error: (error: any) => {
      this.actionLoading = false;
      this.core.showErrorDetail(error)
      }
    });
  }

  public deleteReport() {
    this.core.showConfirmation(this.translate.get('i18n:MESSAGE.DELETE_REPORT'), this.translate.get('i18n:MESSAGE.CONFIRM_DELETE_SELECTED_REPORT')).subscribe({
      next: (confirmed: boolean) => {
      if (confirmed) {
        // delete
        this.loading = true;
          this.api.removeResult('Reports', this.selectedItem.Id, new ApiQueryOptions({})).subscribe({
            next: () => {
          this.loadData();
          this.core.showNotification(this.translate.get('i18n:MESSAGE.REPORT_DELETED'), "success");
            }, error: (err: any) => {
          this.loading = false;
          this.core.showErrorDetail(err);
            }
        });
      }
      }
    });
  }

  private showReportPopup(report: Report, isNew: boolean, folderId: string) {
    this.dialogRef = this.dialogService.open({
      content: ReportsPopupComponent,
      title: this.translate.get('i18n:REPORT'),
      height: '90vh',  
      width: '90vw',
      minHeight: '90vh',
      minWidth: '90vw',
      actions: this.actionsReportTemplate
    });

    const popup: ReportsPopupComponent = this.dialogRef.content.instance;
    popup.init(report, isNew, folderId);
  }

  private showDownloadReportPopup(report: Report) {
    this.dialogRef = this.dialogService.open({
      content: ReportDownloadPopupComponent,
      title: this.translate.get('i18n:REPORT'),
      height: '90vh',  
      width: '90vw',
      minHeight: '90vh',
      minWidth: '90vw',
      actions: this.actionsDownloadReportTemplate
    });

    const popup: ReportDownloadPopupComponent = this.dialogRef.content.instance;
    popup.init(report.ReportDefinition, report);
  }

  public saveReport() {
    const popup: ReportsPopupComponent = this.dialogRef.content.instance;
    const newReport: Report = popup.getNewReport();
    popup.markAllAsTouched();

    if (popup.report && !popup.isNew) {
      if (popup.isValid()){
        this.actionLoading = true;
        this.api.updateItem('Reports', popup.report.Id, newReport, new ApiQueryOptions({ include: 'ReportDefinition,ReportDefinition.Parameters,ReportDefinition.DataSources,AccessRights.User,AccessRights.Role' })).subscribe({
          next: () => {
          this.actionLoading = false;
          this.closePopup();
          this.loadData();
          this.core.showNotification(this.translate.get('i18n:MESSAGE.REPORT_UPDATED'), "success");
          }, error: (err: any) => {
          this.actionLoading = false;
          this.core.showErrorDetail(err, popup.reportContainer);
          }
        
        });
      }
    } else {
      if (popup.isValid()){
        this.actionLoading = true;
        this.api.addNewItem('Reports', newReport, new ApiQueryOptions({ include: 'ReportDefinition,ReportDefinition.Parameters,ReportDefinition.DataSources,AccessRights.User,AccessRights.Role' })).subscribe({
          next: () => {
          this.actionLoading = false;
          this.closePopup();
          this.loadData();
          this.core.showNotification(this.translate.get('i18n:MESSAGE.REPORT_CREATED'), "success");
          }, error: (err: any) => {
          this.actionLoading = false;
          this.core.showErrorDetail(err, popup.reportContainer);
          }
        });
      }
    }
  }

  public executeDownloadReport() {
    const popup: ReportDownloadPopupComponent = this.dialogRef.content.instance;
    const command: downloadReportCommand = popup.getCommand();

    this.actionLoading = true;
    this.api.executeUnboundAction('ReportDefinitions', 'Download', command, null, { responseType: "arraybuffer" }).subscribe({
      next: (result: ArrayBuffer) => {
    this.actionLoading = false;
      this.closePopup();
      const data: Uint8Array = new Uint8Array(result);
      this.core.generateDownload(data, command.Name + '.pdf', 'pdf', "application/pdf");
      }, error: (err: any) => {
      this.actionLoading = false;
      this.core.showErrorDetail(err, popup.reportContainer);
      }
    });
  }

  public addFolder() {
    this.loading = true;
    this.showFolderPopup(this.selectedItem, null);
  }

  public updateFolder() {
    this.loading = true;
    this.actionLoading = true;
    this.api.getSingleResult('Folders', this.selectedItem.Id, new ApiQueryOptions({ include: 'AccessRights.User,AccessRights.Role' })).subscribe({
      next: (folder: Folder) => {
      this.showFolderPopup(this.selectedItem, folder);
      this.actionLoading = false;
      }, error: (error: any) => {
      this.actionLoading = false;
      this.loading = false;
      this.core.showErrorDetail(error)
      }
    });
  }

  public deleteFolder() {
    this.core.showConfirmation(this.translate.get('i18n:MESSAGE.DELETE_FOLDER'), this.translate.get('i18n:MESSAGE.CONFIRM_DELETE_SELECTED_FOLDER')).subscribe({
      next: (confirmed: boolean) => {
      if (confirmed) {
        // delete
        this.loading = true;
          this.api.removeResult('Folders', this.selectedItem.Id, new ApiQueryOptions({})).subscribe({
            next: () => {
          this.loadData();
          this.core.showNotification(this.translate.get('i18n:MESSAGE.FOLDER_DELETED'), "success");
            }, error: (err: any) => {
          this.loading = false;
          this.core.showErrorDetail(err);
            }
        });
      }
      }
    });
  }

  private showFolderPopup(treeItem: TreeItem, folder: Folder) {

    this.dialogRef = this.dialogService.open({
      content: FolderPopupComponent,
      title: this.translate.get('i18n:FOLDER'),
      height: '90vh',  
      width: '90vw',
      minHeight: '90vh',
      minWidth: '90vw',
      actions: [{ text: this.translate.get('i18n:LABEL.CANCEL') }, { text: this.translate.get('i18n:LABEL.OK'), themeColor: 'primary' }],
      preventAction: (ev, dialog) => {
        if ((ev instanceof DialogCloseResult) || (ev.themeColor != 'primary'))
          return false;

        if (dialog.content.instance.isValid()) {
          return false;
        }
        return true;
      }
    });

    const popup: FolderPopupComponent = this.dialogRef.content.instance;
    popup.defaultType = 4;

    if (folder && folder.Id) {
      // update
      popup.setFolder(folder.ParentId, folder);
    } else if (treeItem && treeItem.Type == 0) {
      // add new folder to selected folder
      popup.setFolder(treeItem.Id, folder);
    } else if (treeItem && treeItem.Type == 4) {
      // add new folder to the folder of the selected data source
      popup.setFolder(treeItem.ParentId, folder);
    } else {
      popup.setFolder(null, folder);
    }

    this.dialogRef.result.subscribe({
      next: (r: DialogResult) => {
            if (!(r instanceof DialogCloseResult) && r.themeColor == 'primary') {

        if (folder) {
          this.actionLoading = true;
            this.api.updateItem('Folders', folder.Id, popup.getFolder(), new ApiQueryOptions({ include: 'AccessRights' })).subscribe({
              next: (savedFolder: Folder) => {
          this.loadData();
          this.actionLoading = false;
                this.core.showNotification(this.translate.get('i18n:MESSAGE.FOLDER_UPDATED'), "success")
              }, error:
          (error: any) => {
            this.actionLoading = false;
            this.loading = false;
            this.core.showErrorDetail(error)
                }
          });
        } else {
          this.actionLoading = true;
            this.api.addNewItem('Folders', popup.getFolder(), new ApiQueryOptions({ include: 'AccessRights' })).subscribe({
              next: (savedFolder: Folder) => {
          this.loadData();
          this.actionLoading = false;
                this.core.showNotification(this.translate.get('i18n:MESSAGE.FOLDER_ADDED'), "success")
              }, error: (error: any) => {
            this.actionLoading = false;
            this.loading = false;
            this.core.showErrorDetail(error)
              }
          });
        }
      }
        else {
        this.loading = false; 
      }
      }
    });
  }
}