import { AfterViewInit, Component, ElementRef, Inject, Injector, Input, OnDestroy, OnInit, Type, ViewChild } from "@angular/core";
import { HubConnection } from "@aspnet/signalr";
import { DashboardService, DataSourceInstance, TranslateService, WidgetComponent, WidgetInstance, SvgContainerComponent, WidgetUpdater, ItemValueChangedEventArgs, ApiService, DataSourceType, DATASOURCETYPES, CoreService } from "@ats/ats-platform-dashboard";
import { Subscription } from "rxjs";
import * as _ from 'lodash';
import * as d3 from 'd3';
import { TopologyDevice } from "../../domain/dataModels/TopologyDevice";
import { DeviceTopology } from "../../svg/device-topology";

//https://stackblitz.com/edit/d3-tooltip?embed=1&file=src/app/bar-chart.ts&hideNavigation=1s

@Component({
  selector: 'ats-smart-tool-device-topology-widget',
  templateUrl: './device-topology-widget.component.html',
  styleUrls: ['./device-topology-widget.component.scss']
})
export class DeviceTopologyWidgetComponent implements WidgetComponent, WidgetComponent, OnInit, AfterViewInit, OnDestroy {
  @Input() hubConnection: HubConnection;

  public widgetInstance: WidgetInstance;
  public isLoading = false;
  public error: string;
  public dataSourceItemsLoading: boolean = false;

  private widgetConfigSubscription: Subscription;
  private widgetUpdaters: WidgetUpdater[];
  private svg: SVGSVGElement;
  private dataTree: TopologyDevice[];

  private div: HTMLDivElement;

  private drawer: DeviceTopology;

  public svgContainerType: Type<SvgContainerComponent> = SvgContainerComponent;

  @ViewChild('svgContainer', { static: false }) svgContainer: ElementRef;

  constructor(private dashboardService: DashboardService, private translate: TranslateService, private api: ApiService, @Inject(DATASOURCETYPES) dataSourceTypes: DataSourceType[][], private injector: Injector, public core: CoreService) {
  }

  public setWidgetInstance(widgetInstance: WidgetInstance) {
    this.widgetInstance = widgetInstance;
  }

  ngOnInit() {
    this.widgetConfigSubscription = this.widgetInstance.changed.subscribe((instance: WidgetInstance) => {
      this.updateData(instance);
    })
  }

  ngAfterViewInit() {
    setTimeout(() => { this.updateData(this.widgetInstance); }, 0);
  }

  ngOnDestroy() {
    if (this.widgetConfigSubscription) {
      this.widgetConfigSubscription.unsubscribe();
      this.widgetConfigSubscription = null;
    }

    if (this.widgetUpdaters) {
      this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
      this.widgetUpdaters = null;
    }
  }

  protected updateData(instance: WidgetInstance) {
    instance.widgetData = {
      title: instance.widgetConfig.title
    };
    if (!this.svgContainer)
      return;
    this.div = this.svgContainer.nativeElement;
    const svgs = this.div.getElementsByTagName('svg');
    this.svg = svgs && svgs.length ? svgs.item(0) : null;
    if (!this.svg)
      return;

    const d3svg = d3.select(this.svg);

    d3svg.selectAll('g').remove();

    const g = d3svg.append('g');

    this.drawer = new DeviceTopology(this.core);
    this.drawer.init(g);

    if (this.widgetUpdaters) {
      this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
    }

    const dataSourceInstance = _.find(this.dashboardService.dataSourceInstances, (dataSourceInstance: DataSourceInstance) => dataSourceInstance.id == instance.widgetConfig.dataSourceId);
    if (dataSourceInstance) {
      if (!instance.widgetData.title)
        instance.widgetData.title = dataSourceInstance.name;
      this.widgetUpdaters = [];
      this.widgetUpdaters.push(new WidgetUpdater(dataSourceInstance, this.svgContainer.nativeElement, this.update, this.updateItem));
    }
  }

  protected update = (instance: DataSourceInstance, nativeElement: HTMLElement) => {
    this.isLoading = instance?.result?.isLoading;
    if (!this.widgetInstance.widgetData.title)
      this.widgetInstance.widgetData.title = instance.name;
      
    if (instance.result && instance.result.data) {
      this.drawer.data = this.dataTree = instance.result.data;

      this.drawer.left = 0;
      this.drawer.right = 0;
      this.drawer.top = 0;
      this.drawer.bottom = 0;
      this.drawer.title = this.widgetInstance.widgetData.title;
      this.drawer.columns = this.getMaxNodesInSingleDepth(this.drawer.data);
      this.drawer.rows = this.getMaxDepthInTree(this.drawer.data);
      this.drawer.marginBetweenX = 20;
      this.drawer.marginBetweenY = 40;
      this.drawer.unitWidth = 70;
      this.drawer.unitHeight = 90;
      this.drawer.meterRows = 6;
      this.drawer.meterColumns = 3;
      this.drawer.mainPosY = [];
      this.drawer.mainPosX = [];
      for (var r = 0; r < this.drawer.rows; r++) {
        this.drawer.mainPosY.push(this.drawer.top + this.drawer.unitHeight * r + this.drawer.marginBetweenY * r);
      }
      for (var c = 0; c < this.drawer.columns; c++) {
        this.drawer.mainPosX.push(this.drawer.left + this.drawer.unitWidth * c + this.drawer.marginBetweenX * c);
      }
      this.drawer.width = this.drawer.mainPosX[this.drawer.mainPosX.length - 1] + this.drawer.unitWidth;
      this.drawer.height = this.drawer.mainPosY[this.drawer.mainPosY.length - 1] + this.drawer.unitHeight;

      const d3svg = d3.select(this.svg);
      d3svg.attr('viewBox', '' + -2 + ' ' + -2 + ' ' + (this.drawer.width + 4) + ' ' + (this.drawer.height + 4));

      this.assignCoordinates(this.drawer.data, this.drawer.mainPosX, this.drawer.mainPosY, this.drawer.rows, this.drawer.columns)

      this.drawer.draw();
      _.forEach(this.dataTree, topologyDevice => {
        this.initUpdateItem(topologyDevice);
      });
    }
  }

  protected updateItem = (dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement, event: ItemValueChangedEventArgs) => {
    if(!this.dataTree){
      return;
    }
    var currUnit = this.findNodeByNameBFS(this.dataTree, event.id);
    this.powerChanged(currUnit, event.value)

  };
  private powerChanged(currUnit: TopologyDevice, item) {
    if (currUnit && currUnit.Info) {
      currUnit.Info.Power = item && item.Power ? item.Power : null;
      if (_.isNumber(currUnit.Info.Power)) {
        if (currUnit.Info.TextPower) {
          currUnit.Info.TextPower.text(this.drawer.formatValue(currUnit.Info.Power));
        }
      } else {
        if (currUnit.Info.TextPower) {
          currUnit.Info.TextPower.text('--- kW');
        }
      }
    }
  };
  private initUpdateItem(topologyDevice: TopologyDevice){
    this.powerChanged(topologyDevice, topologyDevice.LatestPoint);
    if(topologyDevice.Children){
      _.forEach(topologyDevice.Children, child => {
        this.powerChanged(child, child.LatestPoint);
      });
    }
  };
  private findNodeByNameBFS(roots: TopologyDevice[], name: string) {
    const queue = [...roots];
    while (queue.length > 0) {
      const node = queue.shift();
      if (node.Name === name) {
        return node;
      }
      if (node.Children) {
        for (const child of node.Children) {
          queue.push(child);
        }
      }
    }
    return null;
  }
  protected assignCoordinates(dataTree: TopologyDevice[], mainPosX: any[], mainPosY: any[], rows: number, columns: number) {
    if (dataTree === null) {
      return;
    }
    for (var i = 0; i < rows; i++) {
      var nodesAtDepth = this.getNodesAtSingleDepth(dataTree, i)
      var nodesLen = nodesAtDepth.length;
      var emptyNodes = columns - nodesLen;
      var defaultEmptyNodes = Math.floor(emptyNodes / (nodesLen + 1));
      var modusEmptyNodes = emptyNodes % (nodesLen + 1);
      var betweenChildEmptyNodes = 0;
      var leftOverEmptyNodes = 0;
      if (nodesLen > 1 && modusEmptyNodes > 0) {
        betweenChildEmptyNodes = Math.floor(modusEmptyNodes / (nodesLen - 1));
        leftOverEmptyNodes = Math.floor(modusEmptyNodes % (nodesLen - 1));
      }
      nodesAtDepth.forEach((element, index) => {
        var columnNumber = defaultEmptyNodes + index + index * defaultEmptyNodes + betweenChildEmptyNodes * index;
        if (index !== 0) {
          columnNumber += leftOverEmptyNodes;
        }
        element.X = mainPosX[columnNumber];
        element.Y = mainPosY[i];
      });
    }
  }

  protected getMaxDepth(node: TopologyDevice, currentDepth: number): number {
    // If node is null, return the current depth minus one
    if (!node) {
      return currentDepth - 1;
    }

    // Initialize max depth as the current depth
    let maxDepth = currentDepth;

    // Recursively check each child node and update max depth if necessary
    node.Children.forEach(child => {
      const childDepth = this.getMaxDepth(child, currentDepth + 1);
      if (childDepth > maxDepth) {
        maxDepth = childDepth;
      }
    });

    return maxDepth;
  }

  protected getMaxDepthInTree(tree: TopologyDevice[]): number {
    let maxDepth = 0;
    tree.forEach(node => {
      const depth = this.getMaxDepth(node, 0) + 1;
      if (depth > maxDepth) {
        maxDepth = depth;
      }
    });
    return maxDepth;
  }

  protected getNodesAtDepth(node: TopologyDevice, targetDepth: number, currentDepth: number, nodesAtDepth: TopologyDevice[]): void {
    // If node is null, return
    if (!node) {
      return;
    }

    // If the current depth matches the target depth, add the node to the nodesAtDepth array
    if (currentDepth === targetDepth) {
      nodesAtDepth.push(node);
    }

    // Recursively check each child node
    node.Children.forEach(child => {
      this.getNodesAtDepth(child, targetDepth, currentDepth + 1, nodesAtDepth);
    });
  }

  protected getNodesAtSingleDepth(tree: TopologyDevice[], targetDepth: number): TopologyDevice[] {
    const nodesAtDepth: TopologyDevice[] = [];
    tree.forEach(node => {
      this.getNodesAtDepth(node, targetDepth, 0, nodesAtDepth);
    });
    return nodesAtDepth;
  }

  protected getMaxNodesInDepth(node: TopologyDevice, currentDepth: number, nodesPerDepth: number[]): void {
    // If node is null, return
    if (!node) {
      return;
    }

    // Increment the number of nodes at the current depth
    if (!(nodesPerDepth[currentDepth] >= 0)) {
      nodesPerDepth[currentDepth] = 1;
    } else {
      nodesPerDepth[currentDepth]++;
    }

    // Recursively check each child node
    node.Children.forEach(child => {
      this.getMaxNodesInDepth(child, currentDepth + 1, nodesPerDepth);
    });
  }

  protected getMaxNodesInSingleDepth(tree: TopologyDevice[]): number {
    const nodesPerDepth: number[] = [];
    tree.forEach(node => {
      this.getMaxNodesInDepth(node, 0, nodesPerDepth);
    });
    return Math.max(...nodesPerDepth);
  }

  public cancel(e: any) {
    e.preventDefault();
    e.cancelBubble = true;
  }
}