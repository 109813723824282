import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { HubConnection } from "@aspnet/signalr";
import { ApiService, CoreService, DashboardService, DataSourceInstance, ItemValueChangedEventArgs, TranslateService, WidgetComponent, WidgetInstance, WidgetUpdater } from "@ats/ats-platform-dashboard";
import { Observable, Subscription } from "rxjs";

import { find } from "lodash-es";
import { select, format } from 'd3';;
import { TagDataSourceItem } from "../../services/tagsDataSourceLoader";
import { TagValueText } from "../../svg/tag-value-text";
import { BaseTag } from "../../domain/entities/baseTag";
import { DialogCloseResult, DialogResult, DialogService } from "@progress/kendo-angular-dialog";
import { TagValueWritePopupComponent } from "../tag-value-write-popup/tag-value-write-popup.component";
import { EnergyManagementData } from "../../domain/entities/energyManagementData";
import { ENERGY_MANAGEMENT_VALUE_CATEGORIES } from "../../domain/enums/energy-management-categories";

@Component({
  selector: 'ats-smart-tool-tag-value-widget',
  templateUrl: './tag-value-widget.component.html',
  styleUrls: ['./tag-value-widget.component.scss']
})
export class TagValueWidgetComponent implements WidgetComponent, OnInit, AfterViewInit, OnDestroy {
  @Input() hubConnection: HubConnection;

  public widgetInstance: WidgetInstance;
  public isLoading = true;
  public error: string;

  private widgetConfigSubscription: Subscription;
  private widgetUpdater: WidgetUpdater;

  private svg: SVGSVGElement;

  private valueText: TagValueText;

  @ViewChild('svgContainer', { static: false }) svgContainer: ElementRef;

  constructor(private dashboardService: DashboardService, private translate: TranslateService, private dialogService: DialogService, private api: ApiService, private core: CoreService) {

  }

  public setWidgetInstance(widgetInstance: WidgetInstance) {
    this.widgetInstance = widgetInstance;
  }

  ngOnInit() {
    this.widgetConfigSubscription = this.widgetInstance.changed.subscribe({
      next: (instance: WidgetInstance) => {
        this.updateData(instance);
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => { this.updateData(this.widgetInstance); }, 0);
  }

  ngOnDestroy() {
    if (this.widgetConfigSubscription) {
      this.widgetConfigSubscription.unsubscribe();
      this.widgetConfigSubscription = null;
    }

    if (this.widgetUpdater) {
      this.widgetUpdater.destroy();
      this.widgetUpdater = null;
    }
  }

  protected updateData(instance: WidgetInstance) {
    instance.widgetData = {
      title: instance.widgetConfig.title
    };

    if (!this.svgContainer)
      return;

    const div: HTMLDivElement = this.svgContainer.nativeElement;
    const svgs = div.getElementsByTagName('svg');
    this.svg = svgs && svgs.length ? svgs.item(0) : null;
    if (!this.svg)
      return;

    // init gauge
    this.valueText = new TagValueText(this.translate);
    this.valueText.unit = null;
    this.valueText.fontSize = instance.widgetConfig.fontSize;
    this.valueText.subFontSize = instance.widgetConfig.subFontSize;
    this.valueText.numberOfDecimals = instance.widgetConfig.numberOfDecimals;
    this.valueText.dateTimeFormat = instance.widgetConfig.dateTimeFormat;

    if (this.widgetUpdater) {
      this.widgetUpdater.destroy();
      this.widgetUpdater = null;
    }

    const dataSourceInstance = find(this.dashboardService.dataSourceInstances, (dataSourceInstance: DataSourceInstance) => dataSourceInstance.id == instance.widgetConfig.dataSourceId);
    if (dataSourceInstance) {
      this.widgetUpdater = new WidgetUpdater(dataSourceInstance, this.svgContainer.nativeElement,
        (dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement) => this.update(dataSourceInstance, nativeElement),
        (dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement, e: ItemValueChangedEventArgs) => this.updateValue(dataSourceInstance, nativeElement, e));
    }
  }

  protected update(dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement) {
    this.isLoading = dataSourceInstance?.result?.isLoading;

    if (dataSourceInstance.result && dataSourceInstance.result.data) {

      // update value
      if (this.valueText) {
        const d3svg = select(this.svg);
        d3svg.selectAll('g').remove();
        d3svg.attr('viewBox', '0 0 ' + nativeElement.getBoundingClientRect().width + ' ' + nativeElement.getBoundingClientRect().height);

        this.valueText.group = d3svg.append('g');
        this.valueText.width = nativeElement.getBoundingClientRect().width;
        this.valueText.height = nativeElement.getBoundingClientRect().height;
        const formatValue = format('.' + (this.widgetInstance.widgetConfig.numberOfDecimals ?? 0) + 'f');

        switch (dataSourceInstance.type.name) {
          case "tags":
          case "assetTags":
            const items: TagDataSourceItem[] = dataSourceInstance.result.data;
            const item: TagDataSourceItem = find(items, (item: TagDataSourceItem) => item.TagId == this.widgetInstance.widgetConfig.itemId);
            this.widgetInstance.widgetData.item = item;

            this.valueText.unit = item?.MeasurementUnit?.Symbol ?? item?.MeasurementUnit?.Name;

            if (!this.widgetInstance.widgetConfig.title)
              this.widgetInstance.widgetData.title = item.Tag.Asset.Path + ' - ' + item.Tag.Name;

            this.valueText.showWriteButton = item.Tag.IsWritable;
            if (item.Tag.IsWritable) {
              this.valueText.onClickCallback = () => {
                this.showWritePopup(item.Tag);
              };
            }

            this.valueText.drawTag(item);

            const setpointId = item?.Tag?.SetPointId;
            if (setpointId) {
              this.valueText.sublinePrefix = this.translate.get('i18n:TAG.SETPOINT');
              this.widgetInstance.widgetData.subItem = find(items, (item: TagDataSourceItem) => item.TagId == setpointId);

              // update value
              if (this.valueText)
                this.valueText.setSublineValue(this.widgetInstance.widgetData.subItem);
            }

            const feedbackId = item?.Tag?.MqttWriteFeedbackTagId;
            if (feedbackId) {
              this.valueText.sublinePrefix = this.translate.get('i18n:TAG.FEEDBACK');
              this.widgetInstance.widgetData.subItem = find(items, (item: TagDataSourceItem) => item.TagId == feedbackId);

              // update value
              if (this.valueText)
                this.valueText.setSublineValue(this.widgetInstance.widgetData.subItem);
            }
            break;
          case "energyManagement":

            const energyManagementData: EnergyManagementData = dataSourceInstance.result.data;

            if (this.widgetInstance.widgetConfig.itemId.toLowerCase().includes("ratio")) {
              this.valueText.unit = "%";
            } else if (this.widgetInstance.widgetConfig.itemId.toLowerCase().includes("price")) {
              this.valueText.unit = "€";
            } else if (this.widgetInstance.widgetConfig.itemId.toLowerCase().includes("co2")) {
              this.valueText.unit = energyManagementData.Co2MeasurementUnit?.Symbol ?? "";
            } else {
              this.valueText.unit = energyManagementData.EnergyMeasurementUnit?.Symbol ?? "";
            }

            if (!this.widgetInstance.widgetConfig.title) {
              const dataSourceItems = this.core.translateObjectArray(ENERGY_MANAGEMENT_VALUE_CATEGORIES, 'Display');
              this.widgetInstance.widgetData.title = dataSourceItems.find(x => x.Name === this.widgetInstance.widgetConfig.itemId).Display;
            }

            if (energyManagementData.AnalysisSummaryData.hasOwnProperty(this.widgetInstance.widgetConfig.itemId)) {
              this.valueText.drawSimple(formatValue(energyManagementData.AnalysisSummaryData[this.widgetInstance.widgetConfig.itemId]), "");
            }
            break;
        }
      }
      this.isLoading = false;
    }
  }

  protected updateValue(dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement, e: ItemValueChangedEventArgs) {
    if (e.id == this.widgetInstance.widgetData?.item?.TagId) {
      const item = <TagDataSourceItem>this.widgetInstance.widgetData.item;

      // update value
      if (this.valueText)
        this.valueText.setValue(item);
    } else if (e.id == this.widgetInstance.widgetData?.subItem?.TagId) {
      const item = <TagDataSourceItem>this.widgetInstance.widgetData.subItem;

      // update value
      if (this.valueText)
        this.valueText.setSublineValue(item);
    }
  }

  private showWritePopup(tag: BaseTag): void {
    const dialogRef = this.dialogService.open({
      title: this.translate.get('i18n:WRITE_TAG_VALUE'),
      content: TagValueWritePopupComponent,
      actions: [{ text: this.translate.get('i18n:LABEL.CANCEL') }, { text: this.translate.get('i18n:LABEL.SAVE'), themeColor: 'primary' }],
      preventAction: (ev, dialog) => {
        if ((ev instanceof DialogCloseResult) || (ev.themeColor != 'primary'))
          return false;

        if (dialog.content.instance.isValid()) {
          return false;
        }

        return true;
      }
    });

    let popup: TagValueWritePopupComponent = dialogRef.content.instance;
    popup.dataType = tag.DataType;

    dialogRef.result.subscribe({
      next: (result: DialogResult) => {
        if ((result instanceof DialogCloseResult) || result.themeColor != 'primary')
          return;

        this.api.executeUnboundAction<any>('TagValues', 'WriteValue', {
          TagId: tag.Id,
          TimeStamp: new Date(),
          BooleanValue: popup.formGroup.get('booleanValue').value,
          IntegerValue: popup.formGroup.get('integerValue').value,
          FloatingPointValue: popup.formGroup.get('floatingPointValue').value,
          StringValue: popup.formGroup.get('stringValue').value,
          DateTimeValue: popup.formGroup.get('dateTimeValue').value
        }).subscribe({
          next: () => {
            this.core.showNotification(this.translate.get('i18n:WRITE_TAG_VALUE.SUCCESS'), 'success');
          }, error: (error: any) => {
            this.core.showErrorDetail(error);
          }
        });
      }
    });
  }
}