import { Injectable } from "@angular/core";
import { AbstractDataSourceExporter, DataSourceInstance, CoreService } from "@ats/ats-platform-dashboard";
import { DateTime } from "luxon";
import { BaseTag } from "../domain/entities/baseTag";
import { isString } from "lodash-es";

@Injectable()
export class ProductionConsumptionDataSourceExporter extends AbstractDataSourceExporter {

    constructor (public core: CoreService) {
      super();
    }
    public getAvailableExtensions(dataSourceInstance: DataSourceInstance): {Key: string, Display: string}[] {
        if (dataSourceInstance && dataSourceInstance.result) {
          switch (dataSourceInstance.result.contentType) {
            case 'application/json':
              return    this.core.translateObjectArray([
                { Key: 'json', Display: 'json' },
                { Key: 'csv ,', Display: 'csv (,)' },
                { Key: 'csv ;', Display: 'csv (;)' },
                { Key: 'csv s', Display: 'i18n:CSV.SEPARATOR.SPACE' },
                { Key: 'csv t', Display: 'i18n:CSV.SEPARATOR.TAB' },
                { Key: 'csv |', Display: 'i18n:CSV.SEPARATOR.PIPE' }
            ], 'Display');
          }
        }
    
        return null;
      }
    
  public export(dataSourceInstance: DataSourceInstance, fileName: string, extension: string) {
    let data: Uint8Array;

    if (dataSourceInstance && dataSourceInstance.result) {
      const result = dataSourceInstance.result;

      switch (extension) {
        case 'json': {
          const str = JSON.stringify(result.data, null, 2);
          data = new Uint8Array(str.length);
          data.forEach((value, index, array) => { array[index] = str.charCodeAt(index); });
          break;
        }
        case 'csv ,': { data = this.convertToCsvData(result.data, ","); extension = "csv"; break; }
        case 'csv ;': { data = this.convertToCsvData(result.data, ";"); extension = "csv"; break; }
        case 'csv s': { data = this.convertToCsvData(result.data, " "); extension = "csv"; break; }
        case 'csv t': { data = this.convertToCsvData(result.data, "\t"); extension = "csv"; break; }
        case 'csv |': { data = this.convertToCsvData(result.data, "|"); extension = "csv"; break; }
      }

      if (!data) {
        throw 'No data to export.';
      }

      this.core.generateDownload(data, fileName, extension, result.contentType);
    } else {
      throw 'No data to export.';
    }
  }
 
  private convertToCsvData(resultData: any, separator: string): Uint8Array {
    let csvdata: Uint8Array;
    if (resultData && resultData.DetailData && resultData.DetailData.length && resultData.Tags && resultData.Tags.length) {
      let str = 'TimeStamp';

      resultData.Tags.forEach((tag: BaseTag) => {
        str += (separator + tag.Asset.Path + ' / ' + tag.Name);
      });

      str += '\r\n';

      resultData.DetailData.forEach((detailData: any) => {
        str += '"' + (isString(detailData.TimeStamp) ? DateTime.fromISO(detailData.TimeStamp.toString()).toISO() : DateTime.fromJSDate(detailData.TimeStamp).toISO()) + '"'
        
        resultData.Tags.forEach((tag: BaseTag) => {
          str += (separator + (detailData[tag.Id] ?? ''));
        });

        str += '\r\n';
      });

      csvdata = new Uint8Array(str.length);
      csvdata.forEach((value, index, array) => { array[index] = str.charCodeAt(index); });
    }
    return csvdata;
  }
}