<form class="k-form" [formGroup]="formGroup">
    <fieldset class="k-form-fieldset">
        <kendo-grid [data]="items" [height]="410"
            [selectable]="{ mode: 'multiple', drag: true }" kendoGridSelectBy="Id"
            [(selectedKeys)]="selectedItemKeys" (add)="addHandler($event)" (cellClick)="cellClickHandler($event)" (cellClose)="cellCloseHandler($event)">
            <ng-template kendoGridToolbarTemplate>
                <button kendoButton look="outline" (click)="addItem()">{{ 'i18n:DATASOURCE.TIME_IN_COMFORT.ADD_ITEM' | translate }}</button>
                <button kendoButton look="outline" [disabled]="selectedItemKeys.length == 0" (click)="removeItems()">{{ 'i18n:DATASOURCE.TIME_IN_COMFORT.REMOVE_ITEMS' | translate }}</button>
                <kendo-formerror *ngIf="hasGridErr()">{{gridErr}}</kendo-formerror>
            </ng-template>

            <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.TIME_IN_COMFORT.NAME')" [field]="'Name'"></kendo-grid-column>
            <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.TIME_IN_COMFORT.THERMAL_COMFORT')"  [field]="'ThermalComfortTagId'" [style]="{ 'padding': '0px' }">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
                    <div style="display: flex; width: 100%; height: 100%; align-items: center;">
                        <div style="flex: 1; padding: 8px 4px 8px 12px;">{{ getTagDescription(dataItem, 'ThermalComfortTag') }}</div>
                        <div style="flex: 0;">
                            <button kendoButton look="flat" icon="edit" (click)="openTagSelectionPopup(dataItem, 'ThermalComfortTag', 'i18n:DATASOURCE.TIME_IN_COMFORT.THERMAL_COMFORT')"></button>
                        </div>
                        <div style="flex: 0;" *ngIf="dataItem.ThermalComfortTagId && dataItem.ThermalComfortTagId.length">
                            <button kendoButton look="flat" icon="close" (click)="clearTag(dataItem, 'ThermalComfortTag')"></button>
                        </div>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.TIME_IN_COMFORT.REL_HUMIDITY_COMFORT')"  [field]="'RelHumidityComfortTagId'" [style]="{ 'padding': '0px' }">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
                    <div style="display: flex; width: 100%; height: 100%; align-items: center;">
                        <div style="flex: 1; padding: 8px 4px 8px 12px;">{{ getTagDescription(dataItem, 'RelHumidityComfortTag') }}</div>
                        <div style="flex: 0;">
                            <button kendoButton look="flat" icon="edit" (click)="openTagSelectionPopup(dataItem, 'RelHumidityComfortTag', 'i18n:DATASOURCE.TIME_IN_COMFORT.REL_HUMIDITY_COMFORT')"></button>
                        </div>
                        <div style="flex: 0;" *ngIf="dataItem.RelHumidityComfortTagId && dataItem.RelHumidityComfortTagId.length">
                            <button kendoButton look="flat" icon="close" (click)="clearTag(dataItem, 'RelHumidityComfortTag')"></button>
                        </div>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.TIME_IN_COMFORT.CO2_COMFORT')"  [field]="'CO2ComfortTagId'" [style]="{ 'padding': '0px' }">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
                    <div style="display: flex; width: 100%; height: 100%; align-items: center;">
                        <div style="flex: 1; padding: 8px 4px 8px 12px;">{{ getTagDescription(dataItem, 'CO2ComfortTag') }}</div>
                        <div style="flex: 0;">
                            <button kendoButton look="flat" icon="edit" (click)="openTagSelectionPopup(dataItem, 'CO2ComfortTag', 'i18n:DATASOURCE.TIME_IN_COMFORT.CO2_COMFORT')"></button>
                        </div>
                        <div style="flex: 0;" *ngIf="dataItem.CO2ComfortTagId && dataItem.CO2ComfortTagId.length">
                            <button kendoButton look="flat" icon="close" (click)="clearTag(dataItem, 'COComfortTag')"></button>
                        </div>
                        
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.TIME_IN_COMFORT.OVERALL_COMFORT')"  [field]="'OverallComfortTagId'" [style]="{ 'padding': '0px' }">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
                    <div style="display: flex; width: 100%; height: 100%; align-items: center;">
                        <div style="flex: 1; padding: 8px 4px 8px 12px;">{{ getTagDescription(dataItem, 'OverallComfortTag') }}</div>
                        <div style="flex: 0;">
                            <button kendoButton look="flat" icon="edit" (click)="openTagSelectionPopup(dataItem, 'OverallComfortTag', 'i18n:DATASOURCE.TIME_IN_COMFORT.OVERALL_COMFORT')"></button>
                        </div>
                        <div style="flex: 0;" *ngIf="dataItem.OverallComfortTagId && dataItem.OverallComfortTagId.length">
                            <button kendoButton look="flat" icon="close" (click)="clearTag(dataItem, 'OverallComfortTag')"></button>
                        </div>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.TIME_IN_COMFORT.HVAC_MODE')"  [field]="'HVACModeTagId'" [style]="{ 'padding': '0px' }">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
                    <div style="display: flex; width: 100%; height: 100%; align-items: center;">
                        <div style="flex: 1; padding: 8px 4px 8px 12px;">{{ getTagDescription(dataItem, 'HVACModeTag') }}</div>
                        <div style="flex: 0;">
                            <button kendoButton look="flat" icon="edit" (click)="openTagSelectionPopup(dataItem, 'HVACModeTag', 'i18n:DATASOURCE.TIME_IN_COMFORT.HVAC_MODE')"></button>
                        </div>
                        <div style="flex: 0;" *ngIf="dataItem.HVACModeTagId && dataItem.HVACModeTagId.length">
                            <button kendoButton look="flat" icon="close" (click)="clearTag(dataItem, 'HVACModeTag')"></button>
                        </div>
                    </div>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>       
    </fieldset>
</form>