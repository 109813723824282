import { BaseSvg } from '@ats/ats-platform-dashboard';
import { ScaleBand, ScaleLinear, format, scaleBand, range, interpolateYlOrRd, scaleSequential, scaleLinear, axisBottom, axisLeft, axisRight, rgb, interpolate, interpolateRgb, interpolateHsl, interpolateRgbBasis, ScaleSequential } from 'd3';;
import { floor, padStart, round } from "lodash-es";
import { DateTime } from 'luxon'; 

export class HeatMap extends BaseSvg<HeatMapData> {
  public numberOfDecimals: number;
  public hideLabels: boolean;
  public showZero: boolean;
  public posMinColor: string = '#e1efd9';
  public posMaxColor: string = '#71ae48';
  public negMinColor: string = '#4371c4';
  public negMaxColor: string = '#dae1f4';
  public showBy: 'hour' | 'quarthour' = 'hour';
  public timeOfDayOrientation: 'horizontal' | 'vertical' = 'vertical';

  private dayScale: ScaleBand<string>;
  private timeScale: ScaleBand<string>;
  private zDomain;
  private zColorScale: ScaleSequential<string>;
  private legendScale: ScaleLinear<number, number, never>;
  private formatValue: (n: number | { valueOf(): number }) => string;
  private heatMapData: HeatMapData;

  constructor() {
    super();
  }

  public draw(heatMapData: HeatMapData) {
    if (!heatMapData)
      return;

    this.formatValue = format('.' + this.numberOfDecimals + 'f');
    this.group.selectAll('*').remove();
    this.heatMapData = heatMapData;

    this.createScales();
    this.drawAxis();
    this.drawRect();
    this.drawLegend();
  }

  private createScales() {
    this.dayScale = scaleBand()
      .domain(this.heatMapData.data.map(d => DateTime.fromJSDate(d.timeStamp).toFormat('dd-LL-yyyy')))
      .range(this.timeOfDayOrientation == 'horizontal' ? [this.height - this.bottom, this.top] : [this.left, this.width - this.right]);

    if (this.showBy == 'quarthour') {
      this.timeScale = scaleBand()
        .domain(range(24 * 4).map(d => this.formatTimeOfDay(floor(d / 4), (d % 4) * 15)))
        .range(this.timeOfDayOrientation == 'horizontal' ? [this.left, this.width - this.right] : [this.height - this.bottom, this.top]);
    } else {
      this.timeScale = scaleBand()
        .domain(range(24).map(d => this.formatTimeOfDay(d, 0)))
        .range(this.timeOfDayOrientation == 'horizontal' ? [this.left, this.width - this.right] : [this.height - this.bottom, this.top]);
    }

    this.zDomain = [this.heatMapData.domain.min,this.heatMapData.domain.max];

    if (this.showZero && this.zDomain[0] > 0)
      this.zDomain[0] = 0;

   if (this.showZero && this.zDomain[1] < 0)
      this.zDomain[1] = 0;

    let interpolate: (t: number) => string;

    if (this.zDomain[0] == this.zDomain[1]) {
      interpolate = (t: number) => { return '#FFFFFF'; };
    } else if (this.zDomain[0] >= 0) {
      interpolate = interpolateRgbBasis([this.posMinColor, this.posMaxColor]);
    } else if (this.zDomain[1] <= 0) {
      interpolate = interpolateRgbBasis([this.negMinColor, this.negMaxColor]);
    } else  {
      var z = (-this.zDomain[0]) / (this.zDomain[1] - this.zDomain[0]);
      const interpolateNeg = interpolateRgbBasis([this.negMaxColor, this.negMinColor]);
      const interpolatePos = interpolateRgbBasis([this.posMinColor, this.posMaxColor]);
      interpolate = (t: number): string => {
        return t < z ? interpolateNeg(t) : interpolatePos(t);
      };
    }

    this.zColorScale = scaleSequential(interpolate).domain(this.zDomain);
    
    this.legendScale = scaleLinear()
      .range([this.height - this.bottom, this.top])
      .domain(this.zDomain)
      .nice();
  }

  private formatTimeOfDay(hour: number, minute: number) {
    return padStart(hour.toFixed(0), 2, '0') + ':' + padStart(minute.toFixed(0), 2, '0');
  }

  private drawAxis() {
    let tickReductionXAxis: number;
    let tickReductionYAxis: number;

    if (this.timeOfDayOrientation == 'horizontal') {
      tickReductionXAxis = round(this.timeScale.domain().length / ((this.height - this.top - this.bottom) / 30), 0);
      tickReductionYAxis = round(this.dayScale.domain().length / ((this.width - this.left - this.right) / 20), 0);

      this.group
        .append('g')
        .attr('transform', 'translate( 0,' + (this.height - this.bottom) + ')')
        .call(
          axisBottom(this.timeScale)
            .tickValues(
              this.timeScale.domain().filter(function (d, i) {
                return !(i % tickReductionXAxis);
              })
            )
            .tickPadding(5)
            // .tickSizeInner(0)
            // .tickSizeOuter(0)
        )
        .select(".domain").remove();
  
      this.group
        .append('g')
        .attr('transform', 'translate(' + this.left + ', 0)')
        .call(
          axisLeft(this.dayScale)
            .tickValues(
              this.dayScale.domain().filter(function (d, i) {
                return !(i % tickReductionYAxis);
              })
            )
            .tickPadding(3)
        )
        .select(".domain").remove();
    } else {
      tickReductionXAxis = round(this.dayScale.domain().length / ((this.width - this.left - this.right) / 100), 0);
      tickReductionYAxis = round(this.timeScale.domain().length / ((this.height - this.top - this.bottom) / 20), 0);

      this.group
        .append('g')
        .attr('transform', 'translate( 0,' + (this.height - this.bottom) + ')')
        .call(
          axisBottom(this.dayScale)
            .tickValues(
              this.dayScale.domain().filter(function (d, i) {
                return !(i % tickReductionXAxis);
              })
            )
            .tickPadding(3)
        )
        .select(".domain").remove();
  
      this.group
        .append('g')
        .attr('transform', 'translate(' + this.left + ', 0)')
        .call(
          axisLeft(this.timeScale)
            .tickValues(
              this.timeScale.domain().filter(function (d, i) {
                return !(i % tickReductionYAxis);
              })
            )
            .tickPadding(5)
            .tickSizeInner(0)
            .tickSizeOuter(0)
        )
        .select(".domain").remove();
    }
  }

  private drawRect() {

    let heatMapGroup = this.group
      .append('g');

    var rect = heatMapGroup
      .selectAll('rect')
      .data(this.heatMapData.data)
      .enter()
      .append('rect')
      .attr('fill', (d) => this.zColorScale(d.value));

    if (this.timeOfDayOrientation == 'horizontal') {
      rect = rect
        .attr('y', (d) => this.dayScale(DateTime.fromJSDate(d.timeStamp).toFormat('dd-LL-yyyy')))
        .attr('x', (d) => this.timeScale(this.formatTimeOfDay(d.timeStamp.getHours(), d.timeStamp.getMinutes())))
        .attr('height', this.dayScale.bandwidth())
        .attr('width', this.timeScale.bandwidth())
    } else {
      rect = rect
        .attr('x', (d) => this.dayScale(DateTime.fromJSDate(d.timeStamp).toFormat('dd-LL-yyyy')))
        .attr('y', (d) => this.timeScale(this.formatTimeOfDay(d.timeStamp.getHours(), d.timeStamp.getMinutes())))
        .attr('width', this.dayScale.bandwidth())
        .attr('height', this.timeScale.bandwidth())
    }

    rect.on("mouseover", (e: any, d: any) => {

        // #region tooltip
        let totalValueText = '';
        if (this.heatMapData.unit) {
          totalValueText = this.formatValue(d.value) + ' (' + this.heatMapData.unit + ')';
        } else {
          totalValueText = this.formatValue(d.value);
        }
        //Remove all child elements
        var tooltipContainer = document.getElementById("tooltipContainer");
        //global style
        tooltipContainer.style.position = 'absolute';
        tooltipContainer.style.borderStyle = 'solid';
        tooltipContainer.style.borderColor = 'lightgrey';
        tooltipContainer.style.borderWidth = '1px';
        tooltipContainer.style.padding = '3px';
        tooltipContainer.style.background = 'white';
        tooltipContainer.style.fontSize = '12px';

        //specific style
        tooltipContainer.style.display = "inline";
        tooltipContainer.className = "valueTooltip";

        var tooltipHtml = '';

        //timestamp or label if present
        tooltipHtml += '<div style="text-align: center"><b>' + DateTime.fromJSDate(d.timeStamp).toFormat('dd-LL-yyyy HH:mm') + '</b></div>';

        tooltipHtml += '<table>';

        //color kolom
        var colorRectStyle = 'height: 15px; width: 15px; background-color:' + this.zColorScale(d.value) + '; border:1px solid WhiteSmoke;';
        tooltipHtml += '<td style="width: 20px;">';
        tooltipHtml += "<div style='" + colorRectStyle + "'></div>";
        tooltipHtml += '</td>';
        //value & unit kolom
        tooltipHtml += '<td style="white-space:nowrap;">';
        tooltipHtml += '<div>' + totalValueText + '</div>';
        tooltipHtml += '</td>';

        tooltipHtml += '</table>';
        tooltipContainer.innerHTML = tooltipHtml;
        // #endregion
      })
      .on("mouseout", (e: any, d: any) => {
        var tooltipContainer = document.getElementById("tooltipContainer");
        tooltipContainer.innerHTML = '';
        tooltipContainer.style.display = "none";
      })
      .on("mousemove", (e: any, d: any) => {
        var tooltipContainer = document.getElementById("tooltipContainer");
        tooltipContainer.setAttribute('transform', 'translate(' + e.pageX + ',' + e.pageY + ')');
        this.handleTooltipPosition(tooltipContainer, e);
        tooltipContainer.style.background = 'white';
        tooltipContainer.style.opacity = '0.85';
      })
  }

  private drawLegend() {
    const legendwidth = 20;
    const legendPadding = 10;
    const fontSize = 11;

    const legendGroup = this.group
      .append('g');

    legendGroup
      .append('g')
      .attr(
        'transform',
        'translate(' + (this.width - this.right + legendPadding) + ',' + this.top + ')')
      .selectAll('line')
      .data(range(this.height - this.top - this.bottom))
      .enter()
      .append('line')
      .attr('x1', 0)
      .attr('y1', (d, i) => (this.height - this.top - this.bottom) - i)
      .attr('x2', legendwidth)
      .attr('y2', (d, i) => (this.height - this.top - this.bottom) - i)
      .attr('stroke', (d, i) =>
        this.zColorScale(
          this.zDomain[0] +
          ((this.zDomain[1] - this.zDomain[0]) * i) / (this.height - this.top - this.bottom)
        )
      )
      .attr('stroke-width', 1);

    const legendAxis = legendGroup
      .append('g')
      .attr('id', 'axis')
      .attr(
        'transform',
        'translate(' + (this.width - this.right + legendPadding + legendwidth) + ',0)')
      .call(
        axisRight(this.legendScale)
          .tickPadding(5)
          .tickSizeOuter(0));

    if (!this.hideLabels) {
      legendAxis
        .append('text')
        .attr('transform', 'rotate(-90)')
        .attr('y', 50)
        .attr('x', -((this.height) / 2))
        .attr('dy', '0.32em')
        .attr('fill', '#000000')
        .attr('font-weight', 'bold')
        .attr('text-anchor', 'middle')
        .attr('font-size', fontSize)
        .text(this.heatMapData.unit)
        .call(this.wrap, (this.height - this.top - this.bottom));
    }
  }
}

export class HeatMapData {
  data: {
    timeStamp: Date;
    value: number;
  }[]
  
  domain: {
    min: number; // minimum of 0 or minimum value
    max: number;
  }

  unit: string;
}
