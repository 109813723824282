import { Injectable } from "@angular/core";
import { AbstractDataSourceExporter, DataSourceInstance, CoreService } from "@ats/ats-platform-dashboard";
import { DateTime } from "luxon";
import { TagLineChartData, TagLineValue } from "../domain/dataModels/lineChartData";
import { EVChargingSession } from "../domain/entities/evChargingSession";
import { EV_SESSION_STATUS } from "../domain/enums/evSessionStatus";
import { find, isString, join } from "lodash-es";

@Injectable()
export class EvChargingSessionsDataSourceExporter extends AbstractDataSourceExporter {

  constructor(public core: CoreService) {
    super();
  }
  public getAvailableExtensions(dataSourceInstance: DataSourceInstance): { Key: string, Display: string }[] {
    if (dataSourceInstance && dataSourceInstance.result) {
      switch (dataSourceInstance.result.contentType) {
        case 'application/json':
          return this.core.translateObjectArray([
            { Key: 'json', Display: 'json' },
            { Key: 'csv ,', Display: 'csv (,)' },
            { Key: 'csv ;', Display: 'csv (;)' },
            { Key: 'csv s', Display: 'i18n:CSV.SEPARATOR.SPACE' },
            { Key: 'csv t', Display: 'i18n:CSV.SEPARATOR.TAB' },
            { Key: 'csv |', Display: 'i18n:CSV.SEPARATOR.PIPE' }
          ], 'Display');
      }
    }
    return null;
  }

  public export(dataSourceInstance: DataSourceInstance, fileName: string, extension: string) {
    let data: Uint8Array;

    if (dataSourceInstance && dataSourceInstance.result) {
      const result = dataSourceInstance.result;

      switch (extension) {
        case 'json': {
          const str = JSON.stringify(result.data, null, 2);
          data = new Uint8Array(str.length);
          data.forEach((value, index, array) => { array[index] = str.charCodeAt(index); });
          break;
        }
        case 'csv ,': { data = this.convertToCsvData(<EVChargingSession[]>result.data, ","); extension = "csv"; break; }
        case 'csv ;': { data = this.convertToCsvData(<EVChargingSession[]>result.data, ";"); extension = "csv"; break; }
        case 'csv s': { data = this.convertToCsvData(<EVChargingSession[]>result.data, " "); extension = "csv"; break; }
        case 'csv t': { data = this.convertToCsvData(<EVChargingSession[]>result.data, "\t"); extension = "csv"; break; }
        case 'csv |': { data = this.convertToCsvData(<EVChargingSession[]>result.data, "|"); extension = "csv"; break; }
      }

      if (!data) {
        throw 'No data to export.';
      }

      this.core.generateDownload(data, fileName, extension, result.contentType);
    } else {
      throw 'No data to export.';
    }
  }

  private convertToCsvData(sessiondata: EVChargingSession[], separator: string): Uint8Array {
    let csvdata: Uint8Array;

    if (sessiondata && sessiondata.length) {
      let evChargingSessionStatuses = this.core.translateObjectArray(EV_SESSION_STATUS, 'Display');
      let str = join( ['AssetPath', 'Start', 'End', 'Charging end', 'Status', 'Last updated', 'Charged energy', 'Pauze duration', 'Min current', 'Max current', 'Use L1', 'Use L2', 'Use L3', 'EV userName', 'SessionId', 'AuthId', 'Est depart time', 'Requested energy', '\r\n'], separator);

      sessiondata.forEach((session: EVChargingSession) => {
        str += '"' + session.Asset.Path + '"' + separator;
        str += '"' + this.convertDateToISO(session.StartDateTime) + '"' + separator;
        str += '"' + (session.EndDateTime ? this.convertDateToISO(session.EndDateTime) : '') + '"' + separator;
        str += '"' + (session.ChargingEndTime ? this.convertDateToISO(session.ChargingEndTime) : '') + '"' + separator;
        str += '"' + (find(evChargingSessionStatuses, { 'Key': session.Status })?.Display ?? '') + '"' + separator;
        str += '"' + this.convertDateToISO(session.LastUpdated) + '"' + separator;

        str += (session.ChargedEnergy ?? '') + separator;
        str += (session.PauseDuration ?? '') + separator;
        str += (session.MinCurrent ?? '') + separator;
        str += (session.MaxCurrent ?? '') + separator;
        str += (session.UseL1 ?? '') + separator;
        str += (session.UseL2 ?? '') + separator;
        str += (session.UseL3 ?? '') + separator;

        str += '"' + (session.EVUser ? session.EVUser.Name : '') + '"' + separator;
        str += '"' + (session.CPOSessionId ?? '') + '"' + separator;
        str += '"' + (session.CPOAuthId ?? '') + '"' + separator;
        str += '"' + (session.EstimatedDepartureTime ? this.convertDateToISO(session.EstimatedDepartureTime) : '') + '"' + separator;
        str += (session.RequestedEnergy ?? '') + '\r\n';
      });
     
      csvdata = new Uint8Array(str.length);
      csvdata.forEach((value, index, array) => { array[index] = str.charCodeAt(index); });
    } 

    return csvdata;
  }

  private convertDateToISO(date: Date):string
  {
    return isString(date) ? DateTime.fromISO(date.toString()).toISO() : DateTime.fromJSDate(date).toISO();
  }
}