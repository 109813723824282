<form class="k-form" [formGroup]="formGroup">
    <fieldset class="k-form-fieldset">
        <kendo-formfield *ngIf="dataType == 0">
            <kendo-switch formControlName="booleanValue"></kendo-switch>
            <kendo-formerror *ngIf="formGroup.controls['booleanValue'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield *ngIf="dataType == 1 || dataType == 2 || dataType == 8 || dataType == 9">
            <kendo-numerictextbox formControlName="integerValue" [decimals]="0" [format]="'n0'"></kendo-numerictextbox>
            <kendo-formerror *ngIf="formGroup.controls['integerValue'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield *ngIf="dataType == 3">
            <kendo-numerictextbox formControlName="floatingPointValue" [decimals]="4" [format]="'n4'"></kendo-numerictextbox>
            <kendo-formerror *ngIf="formGroup.controls['floatingPointValue'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield *ngIf="dataType == 4">
            <kendo-textbox formControlName="stringValue"></kendo-textbox>
            <kendo-formerror *ngIf="formGroup.controls['stringValue'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield *ngIf="dataType == 5">
            <kendo-datepicker formControlName="dateTimeValue" format="dd/MM/yyyy"></kendo-datepicker>
            <kendo-formerror *ngIf="formGroup.controls['dateTimeValue'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield *ngIf="dataType == 6">
            <kendo-timepicker formControlName="dateTimeValue" format="HH:mm:ss"></kendo-timepicker>
            <kendo-formerror *ngIf="formGroup.controls['dateTimeValue'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield *ngIf="dataType == 7">
            <kendo-datetimepicker formControlName="dateTimeValue" format="dd/MM/yyyy HH:mm:ss"></kendo-datetimepicker>
            <kendo-formerror *ngIf="formGroup.controls['dateTimeValue'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
    </fieldset>
</form>