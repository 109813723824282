<div class="report-config" #reportContainer>
  <form class="k-form" [formGroup]="formGroup">
    <fieldset class="k-form-fieldset">
      <legend class="k-form-legend">{{ 'REPORT.GENERAL' | translate }}</legend>
      <kendo-formfield>
        <kendo-label [for]="name" text="{{ 'DATASOURCE.NAME' | translate }}"></kendo-label>
        <input #name formControlName="name" kendoTextBox />
        <kendo-formerror *ngIf="formGroup.controls['name'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <kendo-label [for]="reportDefinitionId" text="{{ 'REPORT.REPORTDEFINITION' | translate }}"></kendo-label>
        <ats-platform-entity-lookup-textbox #reportDefinitionId formControlName="reportDefinitionId" [settings]="reportDefinitionSelectionPopupSettings" [formatter]="reportDefinitionFormatter"
        (entityChange)="onReportDefinitionChanged($event)"></ats-platform-entity-lookup-textbox>
        <kendo-formerror *ngIf="formGroup.controls['reportDefinitionId'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <kendo-label [for]="folderId" text="{{ 'DATASOURCE.FOLDERID' | translate }}"></kendo-label>
        <ats-platform-entity-lookup-textbox #folderId formControlName="folderId" [settings]="folderSelectionPopupSettings" [formatter]="folderFormatter"></ats-platform-entity-lookup-textbox>
        <kendo-formerror *ngIf="formGroup.controls['folderId'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
      </kendo-formfield>
    </fieldset>
  </form>
  <kendo-tabstrip>
    <kendo-tabstrip-tab [selected]="true" title="{{ 'i18n:REPORT.PARAMETERS' | translate }}">
      <ng-template kendoTabContent>
        <div class="report-parameters">
          <form *ngIf="reportDefinition && reportDefinition.Parameters" class="k-form" [formGroup]="parametersFormGroup">
            <div *ngFor="let parameter of reportDefinition.Parameters">
              <kendo-formfield>
                <kendo-label [for]="parameter.Id" text="{{ parameter.Name }}"></kendo-label>

                <input *ngIf="parameter.Type == 0" id="{{ parameter.Id }}" [formControlName]="parameter.Id" kendoTextBox />
                <kendo-numerictextbox *ngIf="parameter.Type == 1" id="{{ parameter.Id }}" [formControlName]="parameter.Id" [decimals]="0" [format]="'n0'"></kendo-numerictextbox>
                <kendo-numerictextbox *ngIf="parameter.Type == 2" id="{{ parameter.Id }}" [formControlName]="parameter.Id"></kendo-numerictextbox>
                <ats-platform-date-time-box *ngIf="parameter.Type == 3" id="{{ parameter.Id }}" [formControlName]="parameter.Id" [showTime]="true"></ats-platform-date-time-box>
                <kendo-switch *ngIf="parameter.Type == 4" id="{{ parameter.Id }}" [formControlName]="parameter.Id"></kendo-switch>
                <ats-platform-date-time-box *ngIf="parameter.Type == 5" id="{{ parameter.Id }}" [formControlName]="parameter.Id" [showTime]="false"></ats-platform-date-time-box>

                <kendo-formerror *ngIf="parametersFormGroup.controls[parameter.Id].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
              </kendo-formfield>
            </div>
          </form>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="{{ 'i18n:REPORT.DATASOURCES' | translate }}">
      <ng-template kendoTabContent>
        <div class="report-data-sources">
          <form *ngIf="reportDefinition && reportDefinition.DataSources" class="k-form" [formGroup]="dataSourcesFormGroup">
            <div *ngFor="let dataSource of reportDefinition.DataSources">
              <kendo-formfield>
                <kendo-label [for]="dataSource.Id" text="{{ dataSource.Name }}"></kendo-label>
                <ats-platform-entity-lookup-textbox id="{{ dataSource.Id }}" [formControlName]="dataSource.Id" [settings]="dataSourceSelectionPopupSettings[dataSource.Id]" [formatter]="dataSourceFormatter">
                </ats-platform-entity-lookup-textbox>
                <kendo-formerror *ngIf="dataSourcesFormGroup.controls[dataSource.Id].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
              </kendo-formfield>
            </div>
          </form>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
    <kendo-tabstrip-tab title="{{ 'i18n:SECURITY.SECURITY' | translate }}">
      <ng-template kendoTabContent>
        <ats-platform-security-tab [accessRights] = "items" ></ats-platform-security-tab>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>
