import { Injectable } from "@angular/core";
import { AbstractDataSourceExporter, DataSourceInstance, CoreService } from "@ats/ats-platform-dashboard";
import { DateTime } from "luxon";
import { TagLineChartData, TagLineValue } from "../domain/dataModels/lineChartData";
import { isString } from "lodash-es";

@Injectable()
export class TagsHistoryDataSourceExporter extends AbstractDataSourceExporter {

  constructor(public core: CoreService) {
    super();
  }
  public getAvailableExtensions(dataSourceInstance: DataSourceInstance): { Key: string, Display: string }[] {
    if (dataSourceInstance && dataSourceInstance.result) {
      switch (dataSourceInstance.result.contentType) {
        case 'application/json':
          return this.core.translateObjectArray([
            { Key: 'json', Display: 'json' },
            { Key: 'csv ,', Display: 'csv (,)' },
            { Key: 'csv ;', Display: 'csv (;)' },
            { Key: 'csv s', Display: 'i18n:CSV.SEPARATOR.SPACE' },
            { Key: 'csv t', Display: 'i18n:CSV.SEPARATOR.TAB' },
            { Key: 'csv |', Display: 'i18n:CSV.SEPARATOR.PIPE' }
          ], 'Display');
      }
    }
    return null;
  }

  public export(dataSourceInstance: DataSourceInstance, fileName: string, extension: string) {
    let data: Uint8Array;

    if (dataSourceInstance && dataSourceInstance.result) {
      const result = dataSourceInstance.result;

      switch (extension) {
        case 'json': {
          const str = JSON.stringify(result.data, null, 2);
          data = new Uint8Array(str.length);
          data.forEach((value, index, array) => { array[index] = str.charCodeAt(index); });
          break;
        }
        case 'csv ,': { data = this.convertToCsvData(<TagLineChartData[]>result.data, ","); extension = "csv"; break; }
        case 'csv ;': { data = this.convertToCsvData(<TagLineChartData[]>result.data, ";"); extension = "csv"; break; }
        case 'csv s': { data = this.convertToCsvData(<TagLineChartData[]>result.data, " "); extension = "csv"; break; }
        case 'csv t': { data = this.convertToCsvData(<TagLineChartData[]>result.data, "\t"); extension = "csv"; break; }
        case 'csv |': { data = this.convertToCsvData(<TagLineChartData[]>result.data, "|"); extension = "csv"; break; }
      }

      if (!data) {
        throw 'No data to export.';
      }

      this.core.generateDownload(data, fileName, extension, result.contentType);
    } else {
      throw 'No data to export.';
    }
  }

  private convertToCsvData(tagLineChartData: TagLineChartData[], separator: string): Uint8Array {
    let csvdata: Uint8Array;

    if (tagLineChartData && tagLineChartData.length) {
      let str = 'TimeStamp' + separator + 'Asset' + separator + 'Tag' + separator + 'UnitOfMeasurement' + separator + 'FloatingPointValue' + separator + 'IntegerValue' + separator + 'BooleanValue' + separator + 'MeanValue' + separator + 'MinimumValue' + separator + 'MaximumValue' + separator + 'Count\r\n';

      tagLineChartData.forEach((tagEntry: TagLineChartData) => {
        if (tagEntry.Values && tagEntry.Values.length) {
          const assetPath = tagEntry.Tag?.Asset?.Path ?? '';
          const tagName = tagEntry.Tag?.Name ?? '';
          const uom = tagEntry.MeasurementUnit?.Symbol ?? tagEntry.MeasurementUnit?.Name ?? '';

          tagEntry.Values.forEach((value: TagLineValue) => {          
              str += ('"' + (isString(value.TimeStamp) ? DateTime.fromISO(value.TimeStamp.toString()).toISO() : DateTime.fromJSDate(value.TimeStamp).toISO()) + '"' + separator + '"' +
              assetPath + '"' + separator + '"' +
              tagName + '"' + separator + '"' +
              uom + '"' + separator + '' +
              (value.FloatingPointValue ?? '') + separator +
              (value.IntegerValue ?? '') + separator +
              (value.BooleanValue ?? '') + separator +
              (value.MeanValue ?? '') + separator +
              (value.MinimumValue ?? '') + separator +
              (value.MaximumValue ?? '') + separator +
              (value.Count ?? '') + '\r\n');
          });
        }
      });
      csvdata = new Uint8Array(str.length);
      csvdata.forEach((value, index, array) => { array[index] = str.charCodeAt(index); });
    }
    return csvdata;
  }
}