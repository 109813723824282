<div class="chart-menu">
  <kendo-treelist
    [idField]="idField"
    [data]="treeData"
    [loading]="loading"
    [fetchChildren]="fetchChildren"
    [hasChildren]="hasChildren"
    [filterable]="true"
    [filter]="filter"
    (filterChange)="onTreeFilterChange($event)"
    kendoTreeListSelectable
    [selectable]="selectableSettings"
    [(selectedItems)]="selectedItems"
    (selectedItemsChange)="selectedItemsChange($event)"
    kendoTreeListExpandable
    [initiallyExpanded]="true"
    >
    <ng-template kendoTreeListToolbarTemplate>
      <button kendoButton look="flat" icon="folder-add" (click)="addFolder()"></button>
      <button kendoButton look="flat" icon="folder-more" (click)="updateFolder()" [disabled] ="selectedItem.Type != 0"></button>
      <button kendoButton look="flat" icon="delete" (click)="deleteFolder()" [disabled] ="selectedItem.Type != 0"></button>
    </ng-template>


    <kendo-treelist-column
      [expandable]="true"
      field="Name"
      title="translate.get('i18n:DASHBOARD.NAME')"
    >
    <ng-template kendoTreeListCellTemplate let-dataItem>
      <span
        class="k-icon k-i-{{
          dataItem.Type === 0 ? 'folder' : 'file'
        }}"
      ></span>
      {{ dataItem.Name }}
    </ng-template>
    </kendo-treelist-column>
  </kendo-treelist>

</div>