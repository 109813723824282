<form class="k-form" [formGroup]="formGroup">
    <fieldset class="k-form-fieldset">
        <kendo-formfield>
            <kendo-label [for]="EnergyMeasurementUnitId"
                text="{{ 'i18n:DATASOURCE.BUILDING_ENERGY.ENERGYMEASUREMENTUNITID' | translate }}"></kendo-label>
            <ats-platform-entity-lookup-textbox #EnergyMeasurementUnitId formControlName="EnergyMeasurementUnitId"
                [settings]="measurementUnitSelectionPopupSettings" [formatter]="measurementUnitFormatter">
            </ats-platform-entity-lookup-textbox>
            <kendo-formerror *ngIf="formGroup.controls['EnergyMeasurementUnitId'].errors?.['required']">{{
                'i18n:MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="EmissionMeasurementUnitId"
                text="{{ 'i18n:DATASOURCE.BUILDING_ENERGY.EMISSIONMEASUREMENTUNITID' | translate }}"></kendo-label>
            <ats-platform-entity-lookup-textbox #EmissionMeasurementUnitId formControlName="EmissionMeasurementUnitId"
                [settings]="measurementUnitSelectionPopupSettings" [formatter]="measurementUnitFormatter">
            </ats-platform-entity-lookup-textbox>
            <kendo-formerror *ngIf="formGroup.controls['EmissionMeasurementUnitId'].errors?.['required']">{{
                'i18n:MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="SurfaceMeasurementUnitId"
                text="{{ 'i18n:DATASOURCE.BUILDING_ENERGY.SURFACEMEASUREMENTUNITID' | translate }}"></kendo-label>
            <ats-platform-entity-lookup-textbox #SurfaceMeasurementUnitId formControlName="SurfaceMeasurementUnitId"
                [settings]="measurementUnitSelectionPopupSettings" [formatter]="measurementUnitFormatter">
            </ats-platform-entity-lookup-textbox>
            <kendo-formerror *ngIf="formGroup.controls['SurfaceMeasurementUnitId'].errors?.['required']">{{
                'i18n:MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="SurfaceParameterDefinitionId"
                text="{{ 'i18n:DATASOURCE.BUILDING_ENERGY.SURFACEPARAMETERDEFINITIONID' | translate }}"></kendo-label>
            <ats-platform-entity-lookup-textbox #SurfaceParameterDefinitionId
                formControlName="SurfaceParameterDefinitionId" [settings]="parameterDefinitionSelectionPopupSettings"
                [formatter]="parameterDefinitionFormatter"></ats-platform-entity-lookup-textbox>
            <kendo-formerror *ngIf="formGroup.controls['SurfaceParameterDefinitionId'].errors?.['required']">{{
                'i18n:MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
    </fieldset>
    <fieldset class="k-form-fieldset">
        <legend class="k-form-legend">{{ 'i18n:DATASOURCE.TAGS' | translate }}</legend>
        <kendo-grid [data]="items" [height]="410"  
            [selectable]="{ mode: 'multiple', drag: true }" kendoGridSelectBy="AssetId"
            [(selectedKeys)]="selectedItemKeys" (add)="addHandler($event)" (remove)="removeHandler($event)"
            [navigable]="true">
            <ng-template kendoGridToolbarTemplate>
                <button kendoGridAddCommand>{{ 'i18n:DATASOURCE.BUILDING.ADD_BUILDING' | translate }}</button>
                <kendo-formerror *ngIf="hasGridErr()">{{gridErr}}</kendo-formerror>
            </ng-template>
            <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.BUILDING_ENERGY.ASSET')" [field]="'AssetId'"
                [style]="{ 'padding': '0px' }">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
                    <div style="display: flex; width: 100%; height: 100%; align-items: center;">
                        <div style="flex: 1; padding: 8px 4px 8px 12px;">{{ getAssetDescription(dataItem) }}
                            
                        </div>
                        <div style="flex: 0;">
                            <button kendoButton look="flat" icon="edit"
                                (click)="openAssetSelectionPopup(dataItem)"></button>
                        </div>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.BUILDING_ENERGY.DEGREE_DAY')"
                [field]="'DegreeDayTagId'" [style]="{ 'padding': '0px' }">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex >
                    <div style="display: flex; width: 100%; height: 100%; align-items: center;" >
                        <div style="flex: 1; padding: 8px 4px 8px 12px;" >{{ getDegreeDayTagDescription(dataItem) }}
                        </div>
                        <div style="flex: 0;">
                            <button kendoButton look="flat" icon="edit"
                                (click)="openDegreeDaySelectionPopup(dataItem)"></button>
                        </div>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <div *kendoGridDetailTemplate="let dataItemParent">
                <kendo-grid [data]="dataItemParent.Tags" [height]="200" 
                    [selectable]="{ mode: 'multiple', drag: true }" kendoGridSelectBy="TagId"
                    (cellClick)="gridDetailCellClick($event)" (cellClose)="gridDetailCellClose($event)"
                    [(selectedKeys)]="selectedItemKeys">
                    <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.BUILDING_ENERGY.TAG')"
                        [field]="'TagId'">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
                            {{ getTagDescription(dataItem) }}
                        </ng-template>
                    </kendo-grid-column>

                <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.BUILDING_ENERGY.CATEGORY')"
                    [field]="'Category'">
                </kendo-grid-column>
                <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.BUILDING_ENERGY.ENERGY_FACTOR')"
                    [field]="'ConversionFactorEnergy'" editor="numeric">
                </kendo-grid-column>
                <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.BUILDING_ENERGY.EMISSION_FACTOR')"
                    [field]="'ConversionFactorEmission'" editor="numeric">
                </kendo-grid-column>
                <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.BUILDING_ENERGY.PRIMARY_FACTOR')"
                    [field]="'ConversionFactorPrimary'" editor="numeric">
                </kendo-grid-column>

                    <kendo-grid-command-column width="200px">
                        <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem>
                            <button kendoButton look="outline" [disabled]="selectedItemKeys.length == 0"
                                (click)="removeItems(dataItem, dataItemParent)">{{
                                'i18n:DATASOURCE.TAGS.REMOVE' | translate }}</button>
                        </ng-template>
                    </kendo-grid-command-column>
                </kendo-grid>
            </div>
            <kendo-grid-command-column width="300px">
                <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem>
                    <button kendoGridRemoveCommand [primary]="true">Remove Building</button>
                    <button kendoButton look="outline" (click)="openTagSelectionPopup(dataItem)">{{
                        'i18n:DATASOURCE.TAGS.ADD' | translate }}</button>
                </ng-template>
            </kendo-grid-command-column>
        </kendo-grid>
    </fieldset>
</form>