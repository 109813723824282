export class EnergyDistributionData {
  Grid: EnergyDistributionItem;
  Solar: EnergyDistributionItem;
  Wind: EnergyDistributionItem;
  Battery: EnergyDistributionItem;
  EVCharger: EnergyDistributionItem;
  Building: EnergyDistributionItem;
}

export class EnergyDistributionItem {
    HasMeters: boolean;
    DisplayName: string;
    InValue: any;
    OutValue: any;
    CurrentValue: any;
    Icon: any;
    Y: number;
    X: number;
    G: d3.Selection<SVGGElement, unknown, HTMLElement, unknown>;
    TargetId: string;
}

