import { Component, Inject, Injector } from '@angular/core';
import { keyBy, flatten, find } from "lodash-es";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CoreService, DashboardWidgetDocument, DataSource, DataSourceType, DATASOURCETYPES, EntitySelectionPopupSettings, TranslateService, WidgetConfigComponent } from '@ats/ats-platform-dashboard';
import { TagConfig } from '../../domain/entities/tagConfig';
import { ENERGY_MANAGEMENT_ANALYSIS_CATEGORIES } from '../../domain/enums/energy-management-categories';

@Component({
  selector: 'ats-smart-tool-tag-pie-chart-widget-config',
  templateUrl: './tag-pie-chart-widget-config.component.html',
  styleUrls: ['./tag-pie-chart-widget-config.component.scss']
})
export class TagPieChartWidgetConfigComponent implements WidgetConfigComponent {

  public dataSourceSelectionPopupSettings: EntitySelectionPopupSettings;
  public dataSourceFormatter = (dataSource: DataSource) => dataSource ? dataSource.Folder.Path + ' / ' + dataSource.Name : null;

  public fg: FormGroup;
  public formGroup: FormGroup;
  public categories: any[] = [];
  public dataSourceItemsLoading: boolean = false;

  private dataSourceTypes: { [type: string]: DataSourceType };

  constructor(public core: CoreService, public translate: TranslateService, @Inject(DATASOURCETYPES) dataSourceTypes: DataSourceType[][], private injector: Injector) {

    this.dataSourceTypes = keyBy(flatten(dataSourceTypes), (type: DataSourceType) => type.name);

    this.formGroup = new FormGroup({
      title: new FormControl(''),
      dataSourceId: new FormControl(null, Validators.required),
      selectedCategory: new FormControl(null, Validators.required),
      numberOfDecimals: new FormControl(0, Validators.compose([Validators.required, Validators.min(0),Validators.max(9)])),
      widgetType: new FormControl(translate.get('DONUT_CHART_WIDGET'))
    });

    this.dataSourceSelectionPopupSettings = {
      title: translate.get('i18n:SELECT_DATASOURCE'),
      entitySet: 'DataSources',
      filter: {
        logic: 'or',
        filters: [
          { field: 'Type', operator: 'eq', value: 'productionConsumption' }, { field: 'Type', operator: 'eq', value: 'energyManagement' },
        ]
      },
      includes: 'Configs,Folder',
      sort: [{ field: 'Folder.Path', dir: 'asc' },{ field: 'Name', dir: 'asc' }],
      multiSelect: false,
      selectionRequired: true,
      columns: [{ field: 'Folder.Path', title: this.translate.get('i18n:FOLDER.PATH'), filterable: true },{ field: 'Name', title: translate.get('i18n:DATASOURCE.NAME'), filterable: true }], 
      info: translate.get('i18n:WIDGET.SUPPORTED_DATATYPES') +': '+ translate.get('i18n:DATASOURCE.PRODUCTION_CONSUMPTION')+', '+ translate.get('i18n:DATASOURCE.ENERGY_MANAGEMENT')
    };
  }

  public onDataSourceChange = (dataSource: DataSource) => {
    if (dataSource) {
      const dataSourceType: DataSourceType = this.dataSourceTypes[dataSource.Type];
      if (dataSourceType) {

        switch (dataSourceType.name) {
          case "productionConsumption":
            var tagConfigJson = dataSource.Configs.filter(x => x.Name === "Tags")[0].Value;
            var tagConfig: TagConfig[] = JSON.parse(tagConfigJson);
            this.categories =  [...new Map(tagConfig.map(x => ({Name: x.Category, Display: x.Category})).map(item => [item["Name"], item])).values()];
            break;
          case "energyManagement":
            this.categories = this.core.translateObjectArray(ENERGY_MANAGEMENT_ANALYSIS_CATEGORIES, 'Display');
            break;
          default:
            this.categories = [];
            this.formGroup.get('selectedCategory').setValue(null);
        }
      } else {
        this.categories = [];
        this.formGroup.get('selectedCategory').setValue(null);
      }
    } else {
      this.categories = [];
      this.formGroup.get('selectedCategory').setValue(null);
    }

    if (this.formGroup.get('selectedCategory').value && (!this.categories || !this.categories.length || !find(this.categories, c => c.Name == this.formGroup.get('selectedCategory').value))) {
      this.formGroup.get('selectedCategory').setValue(null);
    }
  };

  public isValid(): boolean {
    this.formGroup.markAllAsTouched();
    return this.formGroup.valid;
  }

  public getConfig(): any {
    return {
      title: this.formGroup.get('title').value,
      dataSourceId: this.formGroup.get('dataSourceId').value,
      selectedCategory: this.formGroup.get('selectedCategory').value,
      numberOfDecimals: this.formGroup.get('numberOfDecimals').value,
    };
  }

  public getDocuments(): DashboardWidgetDocument[] {
    return null;
  }

  public setData(config: any, documents: DashboardWidgetDocument[]) {
    this.formGroup.get('title').setValue(config?.title ?? '');
    this.formGroup.get('dataSourceId').setValue(config?.dataSourceId);
    this.formGroup.get('selectedCategory').setValue(config?.selectedCategory);
    this.formGroup.get('numberOfDecimals').setValue(config?.numberOfDecimals);
  }
}
