import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CellClickEvent, CellCloseEvent } from "@progress/kendo-angular-grid";
import { ApiService, ApiQueryOptions, DataSource, DataSourceConfig, DataSourceComponent, TranslateService, EntitySelectionPopupSettings, CoreService } from "@ats/ats-platform-dashboard";

import { find, findIndex } from "lodash-es";
import { forkJoin, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { v4 as uuidv4 } from 'uuid';
import { Asset } from "../../domain/entities/asset";
import { BaseTag } from "../../domain/entities/baseTag";
import { MeasurementUnit } from "../../domain/entities/measurementUnit";
import { ParameterDefinition } from "../../domain/entities/parameterDefinition";

@Component({
    selector: 'ats-smarttool-datasource-buildingenergy',
    templateUrl: './datasource-buildingenergy.component.html',
    styleUrls: ['./datasource-buildingenergy.component.scss']
})
export class DataSourceBuildingEnergyComponent implements DataSourceComponent {

    dataSource: DataSource;

    public formGroup: FormGroup;
    public items: DataSourceBuildingEnergyItem[];
    public selectedItemKeys: string[] = [];

    public measurementUnitSelectionPopupSettings: EntitySelectionPopupSettings;
    public parameterDefinitionSelectionPopupSettings: EntitySelectionPopupSettings;
    public measurementUnitFormatter = (task: MeasurementUnit) => task?.Name;
    public parameterDefinitionFormatter = (task: ParameterDefinition) => task?.Code;

    public gridErr: string="";

    constructor(public translate: TranslateService, private api: ApiService, private core: CoreService) {
        this.measurementUnitSelectionPopupSettings = {
            title: translate.get('i18n:SELECT_MEASUREMENTUNIT'),
            entitySet: 'MeasurementUnits',
            includes: 'Measurement',
            sort: [{ field: 'Measurement.Name', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
            multiSelect: false,
            selectionRequired: true,
            columns: [
                { field: 'Measurement.Name', title: translate.get('i18n:MEASUREMENT_UNIT.MEASUREMENT'), filterable: true },
                { field: 'Name', title: translate.get('i18n:MEASUREMENT_UNIT.NAME'), filterable: true },
                { field: 'Symbol', title: translate.get('i18n:MEASUREMENT_UNIT.SYMBOL'), filterable: true }
            ]
        }

        this.parameterDefinitionSelectionPopupSettings = {
            title: translate.get('i18n:SELECT_PARAMETERD_DEFINITION'),
            entitySet: 'ParameterDefinition',
            includes: 'MeasurementUnit',
            sort: [{ field: 'Code', dir: 'asc' }],
            multiSelect: false,
            selectionRequired: true,
            columns: [
                { field: 'Code', title: translate.get('i18n:PARAMETER_DEFINITION.CODE'), filterable: true },
                { field: 'Description', title: translate.get('i18n:PARAMETER_DEFINITION.DESCRIPTION'), filterable: true },
            ]
        }
    }

    setDataSource(dataSource: DataSource, formGroup: FormGroup): FormGroup {
        this.dataSource = dataSource;

        if (formGroup) {
            this.formGroup = formGroup;
        } else {

        const configs = this.dataSource?.Configs;
        const energyMeasurementUnitIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'EnergyMeasurementUnitId');
        const emissionMeasurementUnitIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'EmissionMeasurementUnitId');
        const surfaceMeasurementUnitIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'SurfaceMeasurementUnitId');
        const surfaceParameterDefinitionIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'SurfaceParameterDefinitionId');
        const buildingConfig = find(configs, (x: DataSourceConfig) => x.Name === 'Buildings');
        this.items = buildingConfig ? buildingConfig.Data : []; 

        this.formGroup = new FormGroup({           
            EnergyMeasurementUnitId: new FormControl(energyMeasurementUnitIdConfig ? energyMeasurementUnitIdConfig.Value : null, Validators.required),
            EmissionMeasurementUnitId: new FormControl(emissionMeasurementUnitIdConfig ? emissionMeasurementUnitIdConfig.Value : null, Validators.required),
            SurfaceMeasurementUnitId: new FormControl(surfaceMeasurementUnitIdConfig ? surfaceMeasurementUnitIdConfig.Value : null, Validators.required),
            SurfaceParameterDefinitionId: new FormControl(surfaceParameterDefinitionIdConfig ? surfaceParameterDefinitionIdConfig.Value : null, Validators.required),
        });
      }

        return this.formGroup;
    }

    getDataSource(): DataSource {
        const dataSource = new DataSource();      
        dataSource.Configs = [];

        const configs = this.dataSource?.Configs;
        const energyMeasurementUnitIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'EnergyMeasurementUnitId');
        const emissionMeasurementUnitIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'EmissionMeasurementUnitId');
        const surfaceMeasurementUnitIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'SurfaceMeasurementUnitId');
        const surfaceParameterDefinitionIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'SurfaceParameterDefinitionId');
        const buildingConfig = find(configs, (x: DataSourceConfig) => x.Name === 'Buildings');

        dataSource.Configs.push({ Id: energyMeasurementUnitIdConfig ? energyMeasurementUnitIdConfig.Id : uuidv4(), Name: 'EnergyMeasurementUnitId', Value: this.formGroup.get('EnergyMeasurementUnitId').value, Encrypt: false, Encrypted: false, Data: null });
        dataSource.Configs.push({ Id: emissionMeasurementUnitIdConfig ? emissionMeasurementUnitIdConfig.Id : uuidv4(), Name: 'EmissionMeasurementUnitId', Value: this.formGroup.get('EmissionMeasurementUnitId').value, Encrypt: false, Encrypted: false, Data: null });
        dataSource.Configs.push({ Id: surfaceMeasurementUnitIdConfig ? surfaceMeasurementUnitIdConfig.Id : uuidv4(), Name: 'SurfaceMeasurementUnitId', Value: this.formGroup.get('SurfaceMeasurementUnitId').value, Encrypt: false, Encrypted: false, Data: null });
        dataSource.Configs.push({ Id: surfaceParameterDefinitionIdConfig ? surfaceParameterDefinitionIdConfig.Id : uuidv4(), Name: 'SurfaceParameterDefinitionId', Value: this.formGroup.get('SurfaceParameterDefinitionId').value, Encrypt: false, Encrypted: false, Data: null });
        dataSource.Configs.push({
            Id: buildingConfig ? buildingConfig.Id : uuidv4(), Name: 'Buildings', Value: JSON.stringify(this.items.filter(
                (function (item) {
                    if (item.AssetId === null || item.AssetId === undefined || item.DegreeDayTagId == null || item.DegreeDayTagId === undefined) { return false; } //Skip empty elements
                    else { return true; }
                })).map((item: DataSourceBuildingEnergyItem) => {
                    item.Tags.forEach(x => { x.Tag = null });
                    return { AssetId: item.AssetId, DegreeDayTagId: item.DegreeDayTagId, Tags: item.Tags };
                })), Encrypt: false, Encrypted: false, Data: null
        });

        return dataSource;
    }

    markAllAsTouched() {
        this.formGroup.get('EnergyMeasurementUnitId').markAsTouched();
        this.formGroup.get('EmissionMeasurementUnitId').markAsTouched();
        this.formGroup.get('SurfaceMeasurementUnitId').markAsTouched();
        this.formGroup.get('SurfaceParameterDefinitionId').markAsTouched();
    }

    isValid(): boolean {
        return this.formGroup.valid  &&  this.gridErr.length == 0;
    }

    hasGridErr(): boolean {
        this.gridErr = this.getFirstGridErrorLoc();
        return this.gridErr.length > 0;
    }

    getFirstGridErrorLoc(): string {
        var isRequired = ' ' + this.translate.get('i18n:MESSAGE.FIELD_IS_REQUIRED');

        for (let i = 0; i < this.items?.length; ++i) {

            if (this.items[i].AssetId == null) return this.translate.get('i18n:DATASOURCE.BUILDING_ENERGY.ASSET') + isRequired;
            if (this.items[i].DegreeDayTagId == null) return this.translate.get('i18n:DATASOURCE.BUILDING_ENERGY.DEGREE_DAY') + isRequired;

            for (let j = 0; j < this.items[i].Tags?.length; ++j) {
                if (this.items[i].Tags[j].Category.trim().length == 0) return this.translate.get('i18n:DATASOURCE.BUILDING_ENERGY.CATEGORY') + isRequired;
            }
        }
        return '';
    }

    getDegreeDayTagDescription(item: DataSourceBuildingEnergyItem): string {
        if (item.DegreeDayTag) {
            return item.DegreeDayTag.Asset.Path + ' - ' + item.DegreeDayTag.Name;
        }
        else if (item.DegreeDayTagId) {
            return this.translate.get('i18n:LABEL.LOADING');
        } else {
            return '';
        }
    }

    getAssetDescription(item: DataSourceBuildingEnergyItem): string {
        if (item.Asset) {
            return item.Asset.Path;
        } else {
            return '';
        }
    }

    getTagDescription(item: DataSourceBuildingEnergyItemTag): string {
        if (item && item.Tag && item.Tag.Asset !== null && item.Tag.Asset !== undefined) {
            return item.Tag.Asset.Path + ' - ' + item.Tag.Name;
        }
        else if (item?.Tag?.Id) {
            return this.translate.get('i18n:LABEL.LOADING');
        } else {
            return '';
        }
    }

    public addHandler({ sender }) {
        this.items.push(new DataSourceBuildingEnergyItem());
    }

    public addTagHandler({ dataItem }) {
        dataItem.tags.push(new DataSourceBuildingEnergyItemTag());
    }

    public removeHandler({ sender, rowIndex }) {
        this.items.splice(rowIndex, 1);
    }

    public removeItems(dataItem: DataSourceBuildingEnergyItemTag, dataItemParent: DataSourceBuildingEnergyItem) {
        var parentIndex = this.items.findIndex(x => x.AssetId == dataItemParent.AssetId && x.DegreeDayTagId == dataItemParent.DegreeDayTagId);
        var indexToDelete = this.items[parentIndex].Tags.findIndex(x => x.Id === dataItem.Id);
        this.items[parentIndex].Tags.splice(indexToDelete, 1);
    }

    public cancelHandler({ sender, rowIndex }) {
        sender.closeRow(rowIndex);
    }

    openDegreeDaySelectionPopup(item: DataSourceBuildingEnergyItem) {
        this.core.showEntitySelectionPopup({
            title: this.translate.get('i18n:LABEL.TAG_SELECTION'),
            entitySet: 'UserBaseTags',
            singleEntitySet: 'BaseTags',
            includes: 'Asset.AssetType,MeasurementUnit.Measurement',
            sort: [{ field: 'Asset.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
            multiSelect: false,
            selectionRequired: false,
            columns: [
                { field: 'Asset.Path', title: this.translate.get('i18n:TAG.ASSET'), filterable: true },
                { field: 'Name', title: this.translate.get('i18n:TAG.NAME'), filterable: true },
                { field: 'MeasurementUnit.Name', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT'), filterable: true },
                { field: 'MeasurementUnit.Symbol', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT.SYMBOL'), filterable: true }
            ]
        }).subscribe({
            next: result => {
                if (result && result.length == 1) {
                    item.DegreeDayTag = result[0] as BaseTag;
                    item.DegreeDayTagId = item.DegreeDayTag.Id;
                }
            }
        });
    }

    openTagSelectionPopup(dataItemParent: DataSourceBuildingEnergyItem) {
        let parentIndex = this.items.findIndex(x => x.AssetId == dataItemParent.AssetId && x.DegreeDayTagId == dataItemParent.DegreeDayTagId);

        this.core.showEntitySelectionPopup({
            title: this.translate.get('i18n:LABEL.TAG_SELECTION'),
            entitySet: 'UserBaseTags',
            singleEntitySet: 'BaseTags',
            includes: 'Asset.AssetType,MeasurementUnit.Measurement',
            sort: [{ field: 'Asset.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
            multiSelect: true,
            selectionRequired: false,
            columns: [
                { field: 'Asset.Path', title: this.translate.get('i18n:TAG.ASSET'), filterable: true },
                { field: 'Name', title: this.translate.get('i18n:TAG.NAME'), filterable: true },
                { field: 'MeasurementUnit.Name', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT'), filterable: true },
                { field: 'MeasurementUnit.Symbol', title: this.translate.get('i18n:TAG.MEASUREMENT_UNIT.SYMBOL'), filterable: true }
            ]
        }).subscribe({
            next: result => {
                if (result && result.length) {
                    result.forEach((tag: BaseTag) => {
                        if (findIndex(this.items[parentIndex].Tags, (item: DataSourceBuildingEnergyItemTag) => item.TagId == tag.Id) == -1) {
                            this.items[parentIndex].Tags.push({
                                Id: new uuidv4(),
                                TagId: tag.Id,
                                Tag: tag,
                                Category: '',
                                ConversionFactorEmission: 0,
                                ConversionFactorEnergy: 0,
                                ConversionFactorPrimary: 0
                            });
                        }
                    });
                }
            }
        });
    }

    openAssetSelectionPopup(item: DataSourceBuildingEnergyItem) {
        console.log("test");
        this.core.showEntitySelectionPopup({
            title: this.translate.get('i18n:LABEL.ASSET_SELECTION'),
            entitySet: 'UserAssets',
            singleEntitySet: 'Assets',
            includes: 'AssetType',
            sort: [{ field: 'Path', dir: 'asc' }],
            multiSelect: false,
            selectionRequired: false,
            columns: [
                { field: 'Path', title: this.translate.get('i18n:ASSET.PATH'), filterable: true },
                { field: 'Description', title: this.translate.get('i18n:ASSET.DESCRIPTION'), filterable: true },
                { field: 'AssetType.Name', title: this.translate.get('i18n:ASSET.TYPE'), filterable: true }
            ]
        }).subscribe({
            next: result => {
                if (result && result.length == 1) {
                    item.Asset = result[0] as Asset;
                    item.AssetId = item.Asset.Id;
                }
            }
        });
    }

    public gridDetailCellClick(event: CellClickEvent) {
        if (!event.isEdited) {
            event.sender.editCell(event.rowIndex, event.columnIndex, this.createSubFormGroup(event.dataItem));
        }
    }

    public gridDetailCellClose(event: CellCloseEvent) {
        const formControl: FormControl = event.formGroup.get(event.column.field);
        if (formControl) {
            if (!formControl.valid) {
                event.preventDefault();
            } else if (formControl.dirty) {
                event.dataItem[event.column.field] = formControl.value;
            }
        }
    }

    public createSubFormGroup(dataItem: DataSourceBuildingEnergyItemTag) {
        return new FormGroup({
            Category: new FormControl(dataItem.Category, Validators.required),
            ConversionFactorEnergy: new FormControl(dataItem.ConversionFactorEnergy, Validators.required),
            ConversionFactorEmission: new FormControl(dataItem.ConversionFactorEmission, Validators.required),
            ConversionFactorPrimary: new FormControl(dataItem.ConversionFactorPrimary, Validators.required),
        });
    }

}

export class DataSourceBuildingEnergyItem {
    DegreeDayTagId: string;
    DegreeDayTag: BaseTag;
    AssetId: string;
    Asset: Asset;
    Tags: DataSourceBuildingEnergyItemTag[];

    constructor() {
        this.Tags = [];
    }
}

export class DataSourceBuildingEnergyItemTag {
    Id: string;
    TagId: string;
    Tag: BaseTag;
    Category: string;
    ConversionFactorEnergy: number;
    ConversionFactorEmission: number;
    ConversionFactorPrimary: number;
}