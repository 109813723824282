import { Component, Inject, Injector } from '@angular/core';
import { keyBy, flatten, find } from "lodash-es";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CoreService, DashboardWidgetDocument, DataSource, DataSourceType, DATASOURCETYPES, EntitySelectionPopupSettings, TranslateService, WidgetConfigComponent } from '@ats/ats-platform-dashboard';
import { ENERGY_MANAGEMENT_VALUE_CATEGORIES } from '../../domain/enums/energy-management-categories';

@Component({
  selector: 'ats-smart-tool-tag-value-widget-config',
  templateUrl: './tag-value-widget-config.component.html',
  styleUrls: ['./tag-value-widget-config.component.scss']
})
export class TagValueWidgetConfigComponent implements WidgetConfigComponent {

  public dataSourceSelectionPopupSettings: EntitySelectionPopupSettings;
  public dataSourceFormatter = (dataSource: DataSource) => dataSource ? dataSource.Folder.Path + ' / ' + dataSource.Name : null;

  public formGroup: FormGroup;
  public dataSourceItems: any[] = [];
  public dataSourceItemsLoading: boolean = false;

  private dataSourceTypes: { [type: string]: DataSourceType };

  constructor(public core: CoreService, public translate: TranslateService, @Inject(DATASOURCETYPES) dataSourceTypes: DataSourceType[][], private injector: Injector) {

    this.dataSourceTypes = keyBy(flatten(dataSourceTypes), (type: DataSourceType) => type.name);

    this.formGroup = new FormGroup({
      title: new FormControl(''),
      dataSourceId: new FormControl('', Validators.required),
      itemId: new FormControl('', Validators.required),
      fontSize: new FormControl('', Validators.min(1)),
      subFontSize: new FormControl('', Validators.min(1)),
      numberOfDecimals: new FormControl(0, Validators.min(0)),
      dateTimeFormat: new FormControl('dd/MM/yyyy HH:mm'),
      widgetType: new FormControl(translate.get('TAG_VALUE_WIDGET')),
      selectedCategory: new FormControl(null)
    });

    this.dataSourceSelectionPopupSettings = {
      title: translate.get('i18n:SELECT_DATASOURCE'),
      entitySet: 'DataSources',
      filter: {
        logic: 'or',
        filters: [
          { field: 'Type', operator: 'eq', value: 'tags' },
          { field: 'Type', operator: 'eq', value: 'assetTags' },
          { field: 'Type', operator: 'eq', value: 'energyManagement' }
        ]
      },
      includes: 'Configs,Folder',
      sort: [{ field: 'Folder.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
      multiSelect: false,
      selectionRequired: true,
      columns: [{ field: 'Folder.Path', title: this.translate.get('i18n:FOLDER.PATH'), filterable: true },{ field: 'Name', title: translate.get('i18n:DATASOURCE.NAME'), filterable: true }],
      info: translate.get('i18n:WIDGET.SUPPORTED_DATATYPES') +': '+ translate.get('i18n:DATASOURCE.TAGS.TAG')+', '+ translate.get('i18n:DATASOURCE.ASSET')+', '+ translate.get('i18n:DATASOURCE.ENERGY_MANAGEMENT')
    };
  }

  public onDataSourceChange = (dataSource: DataSource) => {
    if (dataSource) {
      const dataSourceType: DataSourceType = this.dataSourceTypes[dataSource.Type];
      if (dataSourceType) {
        switch (dataSourceType.name) {
          case "tags":
          case "assetTags":
            const loader = this.injector.get(dataSourceType.loader);
            this.dataSourceItemsLoading = true;
            loader.loadItems(dataSource).subscribe({
              next: (items: { id: string; name: string }[]) => {
                this.dataSourceItemsLoading = false;
                this.dataSourceItems = items.map(item => {
                  return { Name: item.id, Display: item.name }
                });
              }, error: (err: any) => {
                this.dataSourceItemsLoading = false;
                this.core.showErrorDetail(err);
              }
            });
            break;

          case "energyManagement":
            this.dataSourceItems = this.core.translateObjectArray(ENERGY_MANAGEMENT_VALUE_CATEGORIES, 'Display');
            break;
          default:
            this.dataSourceItems = [];
            this.formGroup.get('selectedCategory').setValue([]);
        }
      } else {
        this.dataSourceItems = [];
        this.formGroup.get('selectedCategory').setValue([]);
      }
    } else {
      this.dataSourceItems = [];
      this.formGroup.get('selectedCategory').setValue([]);
    }

    if (this.formGroup.get('selectedCategory').value && (!this.dataSourceItems || !this.dataSourceItems.length || !find(this.dataSourceItems, c => c.Name == this.formGroup.get('selectedCategory').value))) {
      this.formGroup.get('selectedCategory').setValue(null);
    }
  };

  public isValid(): boolean {
    this.formGroup.markAllAsTouched();
    return this.formGroup.valid;
  }

  public getConfig(): any {
    return {
      title: this.formGroup.get('title').value,
      dataSourceId: this.formGroup.get('dataSourceId').value,
      itemId: this.formGroup.get('itemId').value,
      fontSize: this.formGroup.get('fontSize').value,
      subFontSize: this.formGroup.get('subFontSize').value,
      numberOfDecimals: this.formGroup.get('numberOfDecimals').value,
      dateTimeFormat: this.formGroup.get('dateTimeFormat').value
    };
  }

  public getDocuments(): DashboardWidgetDocument[] {
    return null;
  }

  public setData(config: any, documents: DashboardWidgetDocument[]) {
    this.formGroup.get('title').setValue(config?.title ?? '');
    this.formGroup.get('dataSourceId').setValue(config?.dataSourceId);
    this.formGroup.get('itemId').setValue(config?.itemId);
    this.formGroup.get('fontSize').setValue(config?.fontSize);
    this.formGroup.get('subFontSize').setValue(config?.subFontSize);
    this.formGroup.get('numberOfDecimals').setValue(config?.numberOfDecimals);
    this.formGroup.get('dateTimeFormat').setValue(config?.dateTimeFormat);
  }
}
