<div class="tag-pie-chart-widget">
    <kendo-loader *ngIf="isLoading" [type]="'converging-spinner'" [themeColor]="'primary'" [size]="'large'">
    </kendo-loader>

    <div #svgContainer [ngClass]="{ 'hidden': error, 'svg-container': true }">
        <!-- <div #pieChartTooltip id="pieChartTooltip" class="chart-tooltip"></div> -->
        <svg width="100%" height="100%" viewBox="0 0 500 500" preserveAspectRatio="xMidYMid meet"></svg>
    </div>

    <span *ngIf="error && !isLoading" class="widget-error">{{error}}</span>
</div>