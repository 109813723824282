import { Routes } from '@angular/router';
import { AuthGuard } from '@ats/ats-platform-dashboard';
import { ChartComponent } from './components/chart/chart.component';
import { ChartNavGuard } from './nav/chart.nav.guard';
import { ChartResolver } from './services/chart-resolver.service';

export const ATS_SMARTTOOL_ROUTES: Routes = [{
    path: 'chart',
    loadChildren: () => import('./components/chart/chart.module').then(m => m.ChartModule),
    data: {
      privilege: 'TagChart'
    },
    resolve: {
      chart: ChartResolver
    },
    canActivate: [AuthGuard],
    canDeactivate: [ChartNavGuard]
  }, {
    path: 'chart/:chartId',
    loadChildren: () => import('./components/chart/chart.module').then(m => m.ChartModule),
    data: {
      privilege: 'TagChart'
    },
    resolve: {
      chart: ChartResolver
    },
    canActivate: [AuthGuard],
    canDeactivate: [ChartNavGuard],
    runGuardsAndResolvers: 'always',
  }, {
    path: 'chart/:chartId/asset/:assetId',
    loadChildren: () => import('./components/chart/chart.module').then(m => m.ChartModule),
    data: {
      privilege: 'TagChart'
    },
    resolve: {
      chart: ChartResolver
    },
    canActivate: [AuthGuard],
    canDeactivate: [ChartNavGuard],
    runGuardsAndResolvers: 'always',
  }
];
