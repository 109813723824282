import { Component, Inject, Injector } from '@angular/core';
import { keyBy, flatten } from "lodash-es";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CoreService, DashboardWidgetDocument, DataSource, DataSourceType, DATASOURCETYPES, EntitySelectionPopupSettings, TranslateService, WidgetConfigComponent } from '@ats/ats-platform-dashboard';
import { DAYFILTERKEYS } from "../../domain/enums/dayFilterKeys";
import { BuildingEnergyValueType, BUILDING_ENERGY_VALUE_TYPES } from '../../domain/enums/building-energy-value-types';

@Component({
  selector: 'ats-smart-tool-building-energy-horiz-stacked-bar-chart-widget-config',
  templateUrl: './building-energy-horiz-stacked-bar-chart-widget-config.component.html',
  styleUrls: ['./building-energy-horiz-stacked-bar-chart-widget-config.component.scss']
})
export class BuildingEnergyHorizStackedBarChartWidgetConfigComponent implements WidgetConfigComponent {

  public dataSourceSelectionPopupSettings: EntitySelectionPopupSettings;
  public dataSourceFormatter = (dataSource: DataSource) => dataSource ? dataSource.Folder.Path + ' / ' + dataSource.Name : null;
  
  public fg: FormGroup;
  public formGroup: FormGroup;
  public dataSourceItems: { id: string, name: string }[] = [];
  public dataSourceItemsLoading: boolean = false;
  public dayFilterKeys: { Key: number, Name: string, Display: string }[];

  private dataSourceTypes: { [type: string]: DataSourceType };

  public valueTypes: { Key: BuildingEnergyValueType, Display: string }[];

  constructor(public core: CoreService, public translate: TranslateService, @Inject(DATASOURCETYPES) dataSourceTypes: DataSourceType[][], private injector: Injector) {


    this.dataSourceTypes = keyBy(flatten(dataSourceTypes), (type: DataSourceType) => type.name);
    this.dayFilterKeys = core.translateObjectArray(DAYFILTERKEYS, 'Display');
    this.valueTypes = core.translateObjectArray(BUILDING_ENERGY_VALUE_TYPES, 'Display');


    this.formGroup = new FormGroup({
      title: new FormControl(''),
      widgetType: new FormControl(translate.get('BUILDING_ENERGY_HORIZ_STACKED_BAR_CHART_WIDGET')),
      dataSourceId: new FormControl(null, Validators.required),
      valueType: new FormControl(null, Validators.required),
      leftMargin: new FormControl(null, Validators.compose([Validators.required, Validators.min(1)])),
      rightMargin: new FormControl(null, Validators.compose([Validators.required, Validators.min(1)])),
      topMargin: new FormControl(null, Validators.compose([Validators.required, Validators.min(1)])),
      bottomMargin: new FormControl(null, Validators.compose([Validators.required, Validators.min(1)])),
      numberOfDecimals: new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
      hideLabels: new FormControl(true),
    });

    this.dataSourceSelectionPopupSettings = {
      title: translate.get('i18n:SELECT_DATASOURCE'),
      entitySet: 'DataSources',
      filter: {
        logic: 'or',
        filters: [
          { field: 'Type', operator: 'eq', value: 'buildingEnergy' },
        ]
      },
      includes: 'Configs,Folder',
      sort: [{ field: 'Folder.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
      multiSelect: false,
      selectionRequired: true,
      columns: [{ field: 'Folder.Path', title: this.translate.get('i18n:FOLDER.PATH'), filterable: true },{ field: 'Name', title: translate.get('i18n:DATASOURCE.NAME'), filterable: true }],
      info: translate.get('i18n:WIDGET.SUPPORTED_DATATYPES') +': '+ translate.get('i18n:DATASOURCE.BUILDINGENERGY')
    };
  }

  public onDataSourceChange = (dataSource: DataSource) => {
    if (dataSource) {
      const dataSourceType: DataSourceType = this.dataSourceTypes[dataSource.Type];
      if (dataSourceType) {
        const loader = this.injector.get(dataSourceType.loader);
        this.dataSourceItemsLoading = true;
        loader.loadItems(dataSource).subscribe({
          next: (items: { id: string; name: string }[]) => {
            this.dataSourceItemsLoading = false;
            this.dataSourceItems = items;
          }, error: (err: any) => {
            this.dataSourceItemsLoading = false;
            this.core.showErrorDetail(err);
          }
        });
      } else {
        this.dataSourceItems = [];
      }
    } else {
      this.dataSourceItems = [];
    }
  };

  public isValid(): boolean {
    this.formGroup.markAllAsTouched();
    return this.formGroup.valid;
  }

  public getConfig(): any {
    return {
      title: this.formGroup.get('title').value,
      dataSourceId: this.formGroup.get('dataSourceId').value,
      valueType: this.formGroup.get('valueType').value,
      leftMargin: this.formGroup.get('leftMargin').value,
      rightMargin: this.formGroup.get('rightMargin').value,
      topMargin: this.formGroup.get('topMargin').value,
      bottomMargin: this.formGroup.get('bottomMargin').value,
      numberOfDecimals: this.formGroup.get('numberOfDecimals').value,
      hideLabels: this.formGroup.get('hideLabels').value,
    };
  }

  public getDocuments(): DashboardWidgetDocument[] {
    return null;
  }

  public setData(config: any, documents: DashboardWidgetDocument[]) {
    this.formGroup.get('title').setValue(config?.title ?? '');
    this.formGroup.get('dataSourceId').setValue(config?.dataSourceId);
    this.formGroup.get('valueType').setValue(config?.valueType);
    this.formGroup.get('leftMargin').setValue(config?.leftMargin);
    this.formGroup.get('rightMargin').setValue(config?.rightMargin);
    this.formGroup.get('topMargin').setValue(config?.topMargin);
    this.formGroup.get('bottomMargin').setValue(config?.bottomMargin);
    this.formGroup.get('numberOfDecimals').setValue(config?.numberOfDecimals);
    this.formGroup.get('hideLabels').setValue(config?.hideLabels);
  }

}