import { Selection, format, pie, arc } from 'd3';
import { PieChartStackData } from '../domain/dataModels/pieChartData';
import { capitalize } from "lodash-es";
import { BaseSvg } from '@ats/ats-platform-dashboard';


export class PieChart extends BaseSvg<PieChartStackData[]> {

  private d3Arc;
  private formatValue: (n: number | { valueOf(): number }) => string;
  private innerRadius: number;
  private outerRadius: number; 

  public data: PieChartStackData[] = [];
  public numberOfDecimals: number;
  public selectedCategory: string = '';
  public measurementUnit: string = '';
  public categoryTotal: number = 0;
  public centerText: string = '';
  public init(group: Selection<SVGGElement, unknown, HTMLElement, unknown>) {
    this.group = group;
  }

  public draw() {
    let dataPresent = false;
    this.data.forEach(d => dataPresent = d.Value !== 0 ? true : dataPresent );
    if(!dataPresent){
      return
    }

    this.group.selectAll("*").remove();
    this.group.selectChildren('*').remove();
    this.group.html("");

    this.formatValue = format('.' + this.numberOfDecimals + 'f');

    this.drawAxis();
    this.drawPie();
  }

  private drawAxis() {
    this.innerRadius = Math.min(this.width, this.height) / 4;
    this.outerRadius = Math.min(this.width, this.height) / 2 - 1;
    this.d3Arc = arc()
      .innerRadius(this.innerRadius)
      .outerRadius(this.outerRadius);
  }

  private drawPie() {
    let d3Pie = pie<any>().value((d: any) => Number(d.Value));
    const localInnerRadius = this.innerRadius;

    const pieDrawing = this.group
      .selectAll('pieces')
      .data(d3Pie(this.data))
      .enter()
      .append("path")
      .attr('d', this.d3Arc)
      .attr('id', d => d.data.Id)
      .on("mouseover", (d: any, selectedData: any) => {
        // #region tooltip

        // //Remove all child elements
        var tooltipContainer = document.getElementById("tooltipContainer");
        //global style
        tooltipContainer.style.position = 'absolute';
        tooltipContainer.style.borderStyle = 'solid';
        tooltipContainer.style.borderColor = 'lightgrey';
        tooltipContainer.style.borderWidth = '1px';
        tooltipContainer.style.padding = '3px';
        tooltipContainer.style.background = 'white';
        tooltipContainer.style.fontSize = '12px';

        //specific style
        tooltipContainer.style.display = "inline";
        tooltipContainer.className = "valueTooltip";

        var tooltipHtml = '';
        //timestamp or label if present
        tooltipHtml += '<div style="text-align: center"><b>' + capitalize(this.selectedCategory) + ': </b></div>';

        tooltipHtml += '<table>';

        this.data.forEach(x => {
          var elementTagPathArray = x.Path.split(' / ');
          elementTagPathArray.push(x.Name);
          var elementTagPathArrayLength = elementTagPathArray.length;
          var elementTagName = elementTagPathArray[elementTagPathArrayLength - 2] ? elementTagPathArray[elementTagPathArrayLength - 2] + ' / ' + elementTagPathArray[elementTagPathArrayLength - 1] : elementTagPathArray[elementTagPathArrayLength - 1];
          var selectedTag = (x.Id == selectedData.data.Id) ? true : false;
          var percent = '%';

          tooltipHtml += '<tr>';
          //color kolom
          var colorRectStyle = 'height: 15px; width: 15px; background-color:' + x.Color + '; border:1px solid WhiteSmoke;';
          tooltipHtml += '<td style="width: 20px;">';
          tooltipHtml += "<div style='" + colorRectStyle + "'></div>";
          tooltipHtml += '</td>';
          //property or name kolom
          tooltipHtml += '<td style="white-space:nowrap;">';
          tooltipHtml += '<div>' + (selectedTag ? elementTagName.bold() : elementTagName) + ' : </div>';
          tooltipHtml += '</td>';
          //value & unit kolom
          tooltipHtml += '<td style="white-space:nowrap;">';
          tooltipHtml += '<div>' + (selectedTag ? this.formatValue(x.Value).bold() + ' ' + this.measurementUnit.bold() : this.formatValue(x.Value) + ' ' + this.measurementUnit) + '</div>';
          tooltipHtml += '</td>';
          //percentage kolom
          tooltipHtml += '<td style="white-space:nowrap;">';
          tooltipHtml += '<div>(' + (selectedTag ? this.formatValue((x.Value / this.categoryTotal) * 100).bold() + percent.bold() : this.formatValue((x.Value / this.categoryTotal) * 100) + percent) + ')</div>';
          tooltipHtml += '</td>';
          tooltipHtml += '</tr>';
        })
        tooltipHtml += '</table>';
        tooltipHtml += '<div style="text-align: center"><b>Total: ' + this.formatValue(this.categoryTotal) + ' ' + this.measurementUnit + '</b></div>';
        tooltipContainer.innerHTML = tooltipHtml;
        // #endregion
      })
      .on("mouseout", (e: any, data: any) => {
        var tooltipContainer = document.getElementById("tooltipContainer");
        tooltipContainer.innerHTML = '';
        tooltipContainer.style.display = "none";
      })
      .on("mousemove", (e: any, data: any) => {
        var tooltipContainer = document.getElementById("tooltipContainer");
        tooltipContainer.setAttribute('transform', 'translate(' + e.pageX + ',' + e.pageY + ')');
        this.handleTooltipPosition(tooltipContainer, e);
        tooltipContainer.style.opacity = '0.95';
      })
      .attr("fill", (d, i) => (d.data.Color))
      .attr("stroke", "white")
      .style("stroke-width", "1px");

    const pieTotal = this.group.append("text")
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle")
      .text(this.centerText)
      .style("font-size", function(d) { return (localInnerRadius * 0.85 * 2 / this.getComputedTextLength()) * 12 + "px"; }); //12 is dpi conversion to pixel
  }
}