<div class="tags-value-widget">
    <kendo-loader *ngIf="isLoading" [type]="'converging-spinner'" [themeColor]="'primary'" [size]="'large'">
    </kendo-loader>
  
    <div #svgContainer [ngClass]="{ 'hidden': error, 'svg-container': true }">
      <kendo-tilelayout [autoFlow]="'row-dense'" [columns]="numberOfRows" [rowHeight]="rowHeight" [gap]="{ rows: 8, columns: 8 }">
        <kendo-tilelayout-item *ngFor="let item of items$ | async" [reorderable]="false" [resizable]="false">
          <kendo-tilelayout-item-header (pointerdown)="cancel($event)" (resize)="cancel($event)" (reorder)="cancel($event)">
            <div class="widget-header">{{ item.Tag.Asset.Path }} - {{ item.Tag.Name }}
              <!-- TODO: add link to chart?? <fa-icon [icon]="['fas', 'pen']" (click)="onWidgetEditClicked(item)"></fa-icon> 
              <fa-icon [icon]="['fas', 'trash']" (click)="onWidgetDeleteClicked(item)"></fa-icon>  -->
            </div>
          </kendo-tilelayout-item-header>
          <kendo-tilelayout-item-body>
            <div atsPlatformDynamicComponent [componentType]="svgContainerType" (instance)="onSvgContainerCreated($event, item)"></div>
          </kendo-tilelayout-item-body>
        </kendo-tilelayout-item>
      </kendo-tilelayout>

    </div>
    
    <span *ngIf="error && !isLoading" class="widget-error">{{error}}</span>
  </div>