import { AfterViewInit, Component, OnDestroy } from "@angular/core";
import { CompositeFilterDescriptor, filterBy } from "@progress/kendo-data-query";
import { Subscription } from "rxjs";
import { DialogCloseResult, DialogRef, DialogResult, DialogService } from '@progress/kendo-angular-dialog';
import { TagChart } from '../../domain/entities/tagChart';
import { ApiQueryOptions, ApiService, CoreService, Folder, FolderPopupComponent, TranslateService, TreeItem } from '@ats/ats-platform-dashboard';
import { ChartService } from '../../services/chart.service';
import { SelectableSettings, SelectionItem } from '@progress/kendo-angular-treelist';
import { Router } from '@angular/router';

@Component({
  selector: 'ats-smart-tool-chart-menu',
  templateUrl: './chart-menu.component.html',
  styleUrls: ['./chart-menu.component.scss']
})
export class ChartMenuComponent implements AfterViewInit, OnDestroy {

  public dialogRef: DialogRef;
  public selectableSettings: SelectableSettings;
  public error: string;
  public idField: string = "Id";
  public loading: boolean = true;
  public actionLoading: boolean = false;
  public selectedItem: TreeItem = new TreeItem();
  public selectedItems: { 'itemKey': TreeItem }[] = [];
  public filter: CompositeFilterDescriptor;
  private cache: any = new Map();
  private subscriptions: Subscription[];
  public treeData: TreeItem[];
  public loadedData: TreeItem[];

  constructor(protected router: Router, private api: ApiService, private core: CoreService, public translate: TranslateService, private dialogService: DialogService, public chartService: ChartService) {
    this.selectableSettings = { mode: "row", drag: false, multiple: false, enabled: true, readonly: false };

    this.filter = {
      logic: "or",
      filters: [
      ]
    };
  }

  ngAfterViewInit(): void {
    this.loadData();
    this.subscriptions = [];
    this.subscriptions.push(this.chartService.chartAdded.subscribe({ next: () => { this.loadData() } }));
    this.subscriptions.push(this.chartService.chartUpdate.subscribe({ next: () => { this.loadData() } }));
    this.subscriptions.push(this.chartService.chartDeleted.subscribe({ next: () => { this.loadData() } }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
      this.subscriptions = null;
    }
  }

  private loadData() {
    this.loading = true;
    this.api.executeUnboundFunction('TagChartTree', 'GetTree').subscribe({
      next: (result: TreeItem[]) => {
        this.treeData = result;
        this.loadedData = result;
        this.loading = false;
      }, error: (error: any) => {
        this.loading = false;
        this.core.showErrorDetail(error);
      }
    });
  }

  private findSelectedItem(treeItem: TreeItem, treeItemId: string) {
    if (!treeItem) {
      for (let rootItem of this.loadedData) {
        var result = this.findSelectedItem(rootItem, treeItemId);
        if (result) {
          return result;
        }
      }
    } else if (treeItem.Id === treeItemId) {
      return treeItem;
    } else if (treeItem.Children) {
      for (let child of treeItem.Children) {
        var newChild = this.findSelectedItem(child, treeItemId);
        if (newChild)
          return newChild;
      }
    };
  }

  public fetchChildren = (treeItem?: TreeItem): TreeItem[] => {
    if (this.cache.get(treeItem)) {
      return this.cache.get(treeItem);
    }
    let result;
    const items = treeItem ? treeItem.Children : this.loadedData;
    if (this.filter && this.filter.filters.length && items) {
      result = filterBy(items, {
        logic: "or",
        filters: [
          this.filter,
          {
            // matches the current filter or a child matches the filter
            operator: (item: any) => {
              if (item.Children) {
                const children = this.fetchChildren(item);
                return children && children.length;
              }
              return undefined;
            },
          },
        ],
      });
    } else {
      result = items;
    }

    this.cache.set(treeItem, result);

    return result;
  }

  public hasChildren = (treeData: TreeItem): boolean => {
    return treeData.Children && treeData.Children.length > 0;
  }

  public selectedItemsChange(selectedItems: SelectionItem[]) {
    this.selectedItem = this.findSelectedItem(null, selectedItems[0].itemKey);
    if (selectedItems) {
      if (this.selectedItem.Type === 0) // SelectedItem = folder
      {
        this.chartService.currentFolderId = this.selectedItem.Id;
      } else if (this.selectedItem.Type === 3) {
        this.chartService.currentFolderId = this.selectedItem.ParentId;
        this.core.navigate(['chart', this.selectedItem.Id]);

      }
    }
  }

  public onTreeFilterChange(filter: CompositeFilterDescriptor) {
    this.filter = filter;
    this.cache.clear();
    this.treeData = this.fetchChildren();
  }

  public closePopup() {
    this.dialogRef.close();
    this.dialogRef = null;
  }

  public addFolder() {
    this.loading = true;
    this.showFolderPopup(this.selectedItem, null);
  }

  public updateFolder() {
    this.loading = true;
    this.actionLoading = true;
    this.api.getSingleResult('Folders', this.selectedItem.Id, new ApiQueryOptions({ include: 'AccessRights.User,AccessRights.Role' })).subscribe({
      next: (folder: Folder) => {
        this.showFolderPopup(this.selectedItem, folder);
        this.actionLoading = false;
      }, error: (error: any) => {
        this.actionLoading = false;
        this.loading = false;
        this.core.showErrorDetail(error)
      }
    });
  }

  public deleteFolder() {
    this.core.showConfirmation(this.translate.get('i18n:MESSAGE.DELETE_FOLDER'), this.translate.get('i18n:MESSAGE.CONFIRM_DELETE_SELECTED_FOLDER')).subscribe({
      next: (confirmed: boolean) => {
        if (confirmed) {
          // delete
          this.loading = true;
          this.api.removeResult('Folders', this.selectedItem.Id, new ApiQueryOptions({})).subscribe({
            next: () => {
              this.loadData();
              this.core.showNotification(this.translate.get('i18n:MESSAGE.FOLDER_DELETED'), "success");
            }, error: (err: any) => {
              this.loading = false;
              this.core.showErrorDetail(err);
            }
          });
        }
      }
    });
  }

  private showFolderPopup(treeItem: TreeItem, folder: Folder) {

    this.dialogRef = this.dialogService.open({
      content: FolderPopupComponent,
      title: this.translate.get('i18n:FOLDER'),
      height: '90vh',
      width: '90vw',
      minHeight: '90vh',
      minWidth: '90vw',
      actions: [{ text: this.translate.get('i18n:LABEL.CANCEL') }, { text: this.translate.get('i18n:LABEL.OK'), themeColor: 'primary' }],
      preventAction: (ev, dialog) => {
        if ((ev instanceof DialogCloseResult) || (ev.themeColor != 'primary'))
          return false;

        if (dialog.content.instance.isValid()) {
          return false;
        }
        return true;
      }
    });

    const popup: FolderPopupComponent = this.dialogRef.content.instance;
    popup.defaultType = 3;

    if (folder && folder.Id) {
      // update
      popup.setFolder(folder.ParentId, folder);
    } else if (treeItem && treeItem.Type == 0) {
      // add new folder to selected folder
      popup.setFolder(treeItem.Id, folder);
    } else if (treeItem && treeItem.Type == 3) {
      // add new folder to the folder of the selected folderItem
      popup.setFolder(treeItem.ParentId, folder);
    } else {
      popup.setFolder(null, folder);
    }

    this.dialogRef.result.subscribe((r: DialogResult) => {
      if (!(r instanceof DialogCloseResult) && (r.themeColor == 'primary')) {

        if (folder) {
          this.actionLoading = true;
          this.api.updateItem('Folders', folder.Id, popup.getFolder(), new ApiQueryOptions({ include: 'AccessRights' })).subscribe({
            next: (savedFolder: Folder) => {
              this.loadData();
              var urlArray = this.router.url.split('/');
              this.core.navigate(urlArray); //changes in the folder can have influence on current chart
              this.actionLoading = false;
              this.core.showNotification(this.translate.get('i18n:MESSAGE.FOLDER_UPDATED'), "success")
            }, error:
              (error: any) => {
                this.actionLoading = false;
                this.loading = false;
                this.core.showErrorDetail(error)
              }
          });
        } else {
          this.actionLoading = true;
          this.api.addNewItem('Folders', popup.getFolder(), new ApiQueryOptions({ include: 'AccessRights' })).subscribe({
            next: (savedFolder: Folder) => {
              this.loadData();
              this.actionLoading = false;
              this.core.showNotification(this.translate.get('i18n:MESSAGE.FOLDER_ADDED'), "success")
            }, error: (error: any) => {
              this.actionLoading = false;
              this.loading = false;
              this.core.showErrorDetail(error)
            }
          });
        }
      }
      else {
        this.loading = false;
      }
    });
  }
}
