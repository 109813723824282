<div class="chart-to-report" #chartToReportContainer>
  <form class="k-form" [formGroup]="formGroup">
    <fieldset class="k-form-fieldset">
      <legend class="k-form-legend">{{ 'REPORT.GENERAL' | translate }}</legend>
      <kendo-formfield>
        <kendo-label [for]="name" text="{{ 'COMMON.NAME' | translate }}"></kendo-label>
        <input #name formControlName="name" kendoTextBox />
        <kendo-formerror *ngIf="formGroup.controls['name'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' | translate
          }}</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <kendo-label [for]="reportDefinitionId" text="{{ 'REPORT.REPORTDEFINITION' | translate }}"></kendo-label>
        <ats-platform-entity-lookup-textbox #reportDefinitionId formControlName="reportDefinitionId"
          [settings]="reportDefinitionSelectionPopupSettings" [formatter]="reportDefinitionFormatter"
          (entityChange)="onReportDefinitionChanged($event)"></ats-platform-entity-lookup-textbox>
        <kendo-formerror *ngIf="formGroup.controls['reportDefinitionId'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' |
          translate }}</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
        <kendo-label [for]="chartName" text="{{ 'COMMON.CHART' | translate }}"></kendo-label>
        <input #chartName formControlName="chartName" kendoTextBox readonly = "true" />
      </kendo-formfield>
    </fieldset>
    <fieldset *ngIf="reportDefinition && reportDefinition.Parameters" class="k-form-fieldset">
      <legend class="k-form-legend">{{ 'REPORT.PARAMETERS' | translate }}</legend>
      <div *ngFor="let parameter of reportDefinition.Parameters">
        <kendo-formfield>
          <kendo-label [for]="parameter.Id" text="{{ parameter.Name }}"></kendo-label>
          <input *ngIf="parameter.Type == 0" id="{{ parameter.Id }}" [formControlName]="parameter.Id" kendoTextBox />
          <kendo-numerictextbox *ngIf="parameter.Type == 1" id="{{ parameter.Id }}" [formControlName]="parameter.Id"
            [decimals]="0" [format]="'n0'"></kendo-numerictextbox>
          <kendo-numerictextbox *ngIf="parameter.Type == 2" id="{{ parameter.Id }}"
            [formControlName]="parameter.Id"></kendo-numerictextbox>
          <ats-platform-date-time-box *ngIf="parameter.Type == 3" id="{{ parameter.Id }}"
            [formControlName]="parameter.Id" [showTime]="true"></ats-platform-date-time-box>
          <kendo-switch *ngIf="parameter.Type == 4" id="{{ parameter.Id }}"
            [formControlName]="parameter.Id"></kendo-switch>
          <ats-platform-date-time-box *ngIf="parameter.Type == 5" id="{{ parameter.Id }}"
            [formControlName]="parameter.Id" [showTime]="false"></ats-platform-date-time-box>
          <kendo-formerror *ngIf="formGroup.controls[parameter.Id].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' |
            translate }}</kendo-formerror>
        </kendo-formfield>
      </div>
    </fieldset>
  </form>
</div>