import { Selection, ScaleLinear, format, scaleLinear, range } from 'd3';;
import { isNumber, orderBy } from "lodash-es";

export class LinearGauge {

  private scale: ScaleLinear<number, number, never>;
  private formatValue: (n: number | { valueOf(): number }) => string;

  private valuePointerGroup: Selection<SVGGElement, unknown, HTMLElement, unknown>;
  private setpointGroup: Selection<SVGGElement, unknown, HTMLElement, unknown>;
  private valueText: Selection<SVGTextElement, unknown, HTMLElement, unknown>;

  constructor(public settings: LinearGaugeSettings) {
    this.formatValue = format('.' + settings.numberOfDecimals + 'f');
  }

  public draw(group: Selection<SVGGElement, unknown, HTMLElement, unknown>) {
    group.selectAll('g').remove();

    const g = group.append('g');

    this.scale = scaleLinear()
      .domain([this.settings.minValue, this.settings.maxValue]);

    switch (this.settings.direction) {
      case 'lefttoright':
        this.scale = this.scale.range([10, this.settings.width - 10]);
        g.attr('transform', 'rotate(-90) translate(' + -this.settings.height / 2 + ',0)');
        break;
      case 'righttoleft':
        this.scale = this.scale.range([this.settings.width - 10, 10]);
        g.attr('transform', 'rotate(-90) translate(' + -this.settings.height / 2 + ',1)');
        break;
      case 'toptobottom':
        this.scale = this.scale.range([10, this.settings.height - 10]);
        g.attr('transform', 'translate(' + this.settings.width / 2 + ',0)');
        break;
      case 'bottomtotop':
        this.scale = this.scale.range([this.settings.height - 10, 10]);
        g.attr('transform', 'translate(' + this.settings.width / 2 + ',0)');
        break;
    }

    this.drawRanges(g);
    this.drawTicks(g);
    this.drawSetpointLine(g);
    this.drawValuePointer(g);
  }

  private drawRanges(g: Selection<SVGGElement, unknown, HTMLElement, unknown>) {
    const ranges = this.createRanges();

    switch (this.settings.direction) {
      case 'lefttoright':
      case 'toptobottom':
        g.selectAll('rect')
          .remove()
          .data(ranges)
          .enter()
          .append('rect')
          .attr('fill', d => d.color)
          .attr('y', d => this.scale(d.startValue))
          .attr('height', d => this.scale(d.endValue) - this.scale(d.startValue) - 1)
          .attr('x', -this.settings.barWidth / 2)
          .attr('width', this.settings.barWidth);
        break;
      case 'righttoleft':
      case 'bottomtotop':
        g.selectAll('rect')
          .remove()
          .data(ranges)
          .enter()
          .append('rect')
          .attr('fill', d => d.color)
          .attr('y', d => this.scale(d.endValue))
          .attr('height', d => this.scale(d.startValue) - this.scale(d.endValue) - 1)
          .attr('x', -this.settings.barWidth / 2)
          .attr('width', this.settings.barWidth);
        break;
    }
  }

  private drawTicks(g: Selection<SVGGElement, unknown, HTMLElement, unknown>) {
    const minorTicksGroup = g.append('g');
    const majorTicksGroup = g.append('g');

    const minorTicks = this.createTicks(this.settings.numberOfMinorTicks);
    const majorTicks = this.createTicks(this.settings.numberOfMajorTicks);

    switch (this.settings.scalePosition) {
      case 'right':
      case 'top': {
        minorTicksGroup
          .selectAll('g')
          .data(minorTicks)
          .enter()
          .append('g')
          .attr('transform', d => 'translate(0,' + this.scale(d) + ')')
          .append('line')
          .attr('x1', 0)
          .attr('x2', 4)
          .attr('stroke', this.settings.minorTicksColor);

        const majorTickGroup = majorTicksGroup
          .selectAll('g')
          .data(majorTicks)
          .enter()
          .append('g')
          .attr('transform', d => 'translate(0,' + this.scale(d) + ')');

        majorTickGroup
          .append('line')
          .attr('x1', 0)
          .attr('x2', 6)
          .attr('stroke', this.settings.majorTicksColor)
          .attr('stroke-width', 2);

        majorTickGroup
          .append('text')
          .attr('x', 10)
          .attr('fill', this.settings.majorTicksColor)
          .attr('dominant-baseline', 'middle')
          .attr('text-anchor', 'start')
          .attr('font-size', 10)
          .attr('font-family', 'sans-serif')
          .text(d => this.formatValue(d) + (this.settings.unit ? ' ' + this.settings.unit : ''));

        minorTicksGroup.attr('transform', 'translate(' + this.settings.barWidth / 2 + ',0)');
        majorTicksGroup.attr('transform', 'translate(' + this.settings.barWidth / 2 + ',0)');
        break;
      }
      case 'left':
      case 'bottom': {
        minorTicksGroup
          .selectAll('g')
          .data(minorTicks)
          .enter()
          .append('g')
          .attr('transform', d => 'translate(0,' + this.scale(d) + ')')
          .append('line')
          .attr('x1', 0)
          .attr('x2', -4)
          .attr('stroke', this.settings.minorTicksColor);

        const majorTickGroup = majorTicksGroup
          .selectAll('g')
          .data(majorTicks)
          .enter()
          .append('g')
          .attr('transform', d => 'translate(0,' + this.scale(d) + ')');

        majorTickGroup
          .append('line')
          .attr('x1', 0)
          .attr('x2', -6)
          .attr('stroke', this.settings.majorTicksColor)
          .attr('stroke-width', 2);

        majorTickGroup
          .append('text')
          .attr('x', -10)
          .attr('fill', this.settings.majorTicksColor)
          .attr('dominant-baseline', 'middle')
          .attr('text-anchor', 'end')
          .attr('font-size', 10)
          .attr('font-family', 'sans-serif')
          .text(d => this.formatValue(d) + (this.settings.unit ? ' ' + this.settings.unit : ''));

        minorTicksGroup.attr('transform', 'translate(' + -this.settings.barWidth / 2 + ',0)');
        majorTicksGroup.attr('transform', 'translate(' + -this.settings.barWidth / 2 + ',0)');
        break;
      }
    }
  }

  private drawSetpointLine(g: Selection<SVGGElement, unknown, HTMLElement, unknown>) {
    this.setpointGroup = g
      .append('g')
      .attr('transform', 'translate(0,' + this.scale(this.settings.minValue) + ')')
      .attr('opacity', 0);

    this.setpointGroup
      .append('line')
      .attr('stroke', this.settings.setpointColor)
      .attr('stroke-width', 4)
      .attr('x1', -(this.settings.barWidth / 2) - 4)
      .attr('x2', this.settings.barWidth / 2 + 4);
  }

  private drawValuePointer(g: Selection<SVGGElement, unknown, HTMLElement, unknown>) {
    this.valuePointerGroup = g.append('g').attr('opacity', 0);

    switch (this.settings.scalePosition) {
      case 'bottom':
      case 'left':
        this.valuePointerGroup
          .append('path')
          .attr('fill', this.settings.valuePointerColor)
          .attr('d', 'M 0,0 12,-8 12,8 z');

        this.valueText = this.valuePointerGroup
          .append('text')
          .attr('fill', this.settings.valueColor)
          .attr('x', 16)
          .attr('y', 1)
          .attr('dominant-baseline', 'middle')
          .attr('text-anchor', 'start')
          .attr('font-size', 12)
          .attr('font-family', 'sans-serif')
          .text('---');

        this.valuePointerGroup
          .attr('transform', 'translate(' + this.settings.barWidth / 2 + ',' + this.scale(this.settings.minValue) + ')');
        break;
      case 'top':
      case 'right':
        this.valuePointerGroup
          .append('path')
          .attr('fill', this.settings.valuePointerColor)
          .attr('d', 'M 0,0 -12,-8 -12,8 z');

        this.valueText = this.valuePointerGroup
          .append('text')
          .attr('fill', this.settings.valueColor)
          .attr('x', -16)
          .attr('y', 1)
          .attr('dominant-baseline', 'middle')
          .attr('text-anchor', 'end')
          .attr('font-size', 12)
          .attr('font-family', 'sans-serif')
          .text('---');

        this.valuePointerGroup
          .attr('transform', 'translate(' + -this.settings.barWidth / 2 + ',' + this.scale(this.settings.minValue) + ')');
        break;
    }
  }

  public setValue(value: number) {
    if (this.valuePointerGroup) {
      if (isNumber(value) && value >= this.settings.minValue && value <= this.settings.maxValue) {
        this.valuePointerGroup
          .attr('opacity', 1);
        switch (this.settings.scalePosition) {
          case 'bottom':
          case 'left':
            this.valuePointerGroup
              .transition()
              .duration(900)
              .attr('transform', 'translate(' + this.settings.barWidth / 2 + ',' + this.scale(value) + ')');
            break;
          case 'top':
          case 'right':
            this.valuePointerGroup
              .transition()
              .duration(900)
              .attr('transform', 'translate(' + -this.settings.barWidth / 2 + ',' + this.scale(value) + ')');
            break;
        }
      } else {
        this.valuePointerGroup
          .attr('opacity', 0);
        switch (this.settings.scalePosition) {
          case 'bottom':
          case 'left':
            this.valuePointerGroup
              .attr('transform', 'translate(' + this.settings.barWidth / 2 + ',' + this.scale(this.settings.minValue) + ')');
            break;
          case 'top':
          case 'right':
            this.valuePointerGroup
              .attr('transform', 'translate(' + -this.settings.barWidth / 2 + ',' + this.scale(this.settings.minValue) + ')');
            break;
        }
      }
    }

    if (this.valueText) {
      if (isNumber(value)) {
        this.valueText.text(this.formatValue(value) + (this.settings.unit ? ' ' + this.settings.unit : ''));
      } else {
        this.valueText.text('---' + (this.settings.unit ? ' ' + this.settings.unit : ''));
      }
    }
  }

  public setSetpoint(value: number) {
    if (this.setpointGroup) {
      if (isNumber(value) && value >= this.settings.minValue && value <= this.settings.maxValue) {
        this.setpointGroup
          .attr('opacity', 0.7)
          .transition()
          .duration(900)
          .attr('transform', 'translate(0,' + this.scale(value) + ')');
      } else {
        this.setpointGroup
          .attr('opacity', 0)
          .attr('transform', 'translate(0,' + this.scale(this.settings.minValue) + ')');
      }
    }
  }

  private createRanges(): { startValue: number; endValue: number; color: string; }[] {
    const ranges: { startValue: number; endValue: number; color: string; }[] = [];

    let lastEndValue = this.settings.minValue;
    let startValue: number;
    let endValue: number;

    if (this.settings.ranges && this.settings.ranges.length) {
      this.settings.ranges = orderBy(this.settings.ranges, r => r.startValue);

      for (let i = 0; i < this.settings.ranges.length; i++) {
        startValue = this.settings.ranges[i].startValue;
        endValue = this.settings.ranges[i].endValue;

        if (
          endValue <= this.settings.minValue ||
          startValue >= this.settings.maxValue
        ) {
          // completly outside the range
          continue;
        }

        startValue = Math.max(this.settings.minValue, startValue, lastEndValue);
        endValue = Math.min(this.settings.maxValue, endValue);

        if (startValue > lastEndValue) {
          // add arc with default color
          ranges.push({ startValue: lastEndValue, endValue: startValue, color: this.settings.defaultRangeColor });
          lastEndValue = startValue;
        }

        if (startValue >= endValue) {
          continue;
        }

        ranges.push({ startValue: startValue, endValue: endValue, color: this.settings.ranges[i].color });
        lastEndValue = endValue;
      }
    }

    if (lastEndValue < this.settings.maxValue) {
      ranges.push({ startValue: lastEndValue, endValue: this.settings.maxValue, color: this.settings.defaultRangeColor });
    }

    return ranges;
  }

  private createTicks(numberOfTicks: number): number[] {
    const ticks = range(numberOfTicks - 1).map(t => {
      return (this.settings.minValue + ((this.settings.maxValue - this.settings.minValue) * t) / (numberOfTicks - 1));
    });

    ticks.push(this.settings.maxValue);

    return ticks;
  }
}

export class LinearGaugeSettings {
  width: number;
  height: number;
  direction: 'lefttoright' | 'righttoleft' | 'toptobottom' | 'bottomtotop';
  scalePosition: 'top' | 'bottom' | 'left' | 'right';
  barWidth: number;
  minValue: number;
  maxValue: number;
  defaultRangeColor: string;
  valueColor: string;
  valuePointerColor: string;
  setpointColor: string;
  minorTicksColor: string;
  majorTicksColor: string;
  ranges: { startValue: number, endValue: number, color: string }[];
  numberOfMajorTicks?: number;
  numberOfMinorTicks?: number;
  numberOfDecimals: number;
  unit?: string;
}