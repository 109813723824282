<form class="k-form" [formGroup]="formGroup">
  <fieldset class="k-form-fieldset">
      <legend class="k-form-legend">{{ 'LABEL.GENERAL' | translate}}</legend>
      <kendo-formfield>
          <kendo-label [for]="title" text="{{ 'WIDGET.TITLE' | translate }}"></kendo-label>
          <input #title formControlName="title" kendoTextBox />
      </kendo-formfield>
      <kendo-formfield>
          <kendo-label [for]="widgetType" text="{{ 'WIDGET.TYPE' | translate }}"></kendo-label>
          <kendo-textbox #widgetType formControlName="widgetType"  [readonly]="true" ></kendo-textbox>
      </kendo-formfield>
      <kendo-formfield>
        <kendo-label [for]="historyOrActive" text="{{ 'EV_CHARGING_SESSIONS.HISTORY_OR_ACTIVE' | translate }}"></kendo-label>
        <kendo-combobox #historyOrActive formControlName="historyOrActive" [data]="historyOrActiveItems" textField="text" valueField="value" [valuePrimitive]="true"></kendo-combobox>
      </kendo-formfield>
      <kendo-formfield>
          <kendo-label [for]="assetId" text="{{ 'WIDGET.ASSET' | translate }}"></kendo-label>
          <ats-platform-entity-lookup-textbox #assetId formControlName="assetId" [formatter]="assetFormatter" [settings]="assetSelectionPopupSettings"></ats-platform-entity-lookup-textbox>
      </kendo-formfield>
      <kendo-formfield>
          <kendo-label [for]="dataSourceId" text="{{ 'WIDGET.DATASOURCE' | translate }}"></kendo-label>
          <ats-platform-entity-lookup-textbox #dataSourceId formControlName="dataSourceId" [formatter]="dataSourceFormatter" [settings]="dataSourceSelectionPopupSettings"></ats-platform-entity-lookup-textbox>
          <kendo-formerror *ngIf="formGroup.controls['dataSourceId'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
          <kendo-label [for]="chartId" text="{{ 'CHARTWIDGET.CHART' | translate }}"></kendo-label>
          <ats-platform-entity-lookup-textbox #chartId formControlName="chartId" [settings]="chartSelectionPopupSettings" [formatter]="chartFormatter"></ats-platform-entity-lookup-textbox>
      </kendo-formfield>
  </fieldset>
</form>