import { Component, ViewChild, ViewContainerRef } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Report } from "../../domain/entities/report";
import { AccessRights, CoreService, DataSource, EntitySelectionPopupSettings, Folder, TranslateService } from "@ats/ats-platform-dashboard";
import { ReportDefinition } from "../../domain/entities/reportDefinition";
import { ReportParameter } from "../../domain/entities/reportParameter";
import { ReportParameterValue } from "../../domain/entities/reportParameterValue";
 
import { ReportDefinitionDataSource } from "../../domain/entities/reportDefinitionDataSource";
import { ReportDataSource } from "../../domain/entities/reportDataSource";


@Component({
    selector: 'ats-smart-tool-reports-popup',
    templateUrl: './reports-popup.component.html',
    styleUrls: ['./reports-popup.component.scss']
})
export class ReportsPopupComponent {
    
    public report: Report;
    public reportDefinition: ReportDefinition;

    public isNew: boolean;
    public folderId: string;
    public formGroup: FormGroup;
    public parametersFormGroup: FormGroup;
    public dataSourcesFormGroup: FormGroup;
    public dataSourceSelectionPopupSettings: { [key: string]: EntitySelectionPopupSettings };   
    public dataSourceFormatter = (dataSource: DataSource) => dataSource ? dataSource?.Folder?.Path + ' / ' + dataSource?.Name: null;
    public folderSelectionPopupSettings: EntitySelectionPopupSettings;
    public folderFormatter = (folder: Folder) => folder.Path;
    public reportDefinitionSelectionPopupSettings: EntitySelectionPopupSettings;
    public reportDefinitionFormatter = (reportDefinition: ReportDefinition) => reportDefinition.Name;
    public items : AccessRights[];

    @ViewChild('reportContainer', {read: ViewContainerRef}) public reportContainer: ViewContainerRef;

    constructor(private core: CoreService, public translate: TranslateService) { 
        this.formGroup = new FormGroup({
          name: new FormControl(null, Validators.required),
          reportDefinitionId: new FormControl(null, Validators.required),
          folderId: new FormControl(null, Validators.required)
        });

        this.parametersFormGroup = new FormGroup({});
        this.dataSourcesFormGroup = new FormGroup({});

        this.folderSelectionPopupSettings = {
          title: this.translate.get('i18n:SELECT_FOLDER'),
          entitySet: 'Folders',
          includes: null,
          sort: [{ field: 'Path', dir: 'asc' },{ field: 'Name', dir: 'asc' }],
          filter: { field: 'Type', operator: 'eq', value: 4},
          multiSelect: false,
          selectionRequired: true,
          columns: [{ field: 'Path', title: this.translate.get('i18n:FOLDER.PATH'), filterable: true },{ field: 'Name', title: this.translate.get('i18n:FOLDER.NAME'), filterable: true }]
        }; 

        this.reportDefinitionSelectionPopupSettings = {
          title: this.translate.get('i18n:SELECT_REPORT_DEFINITION'),
          entitySet: 'ReportDefinitions',
          filter: { field: 'DataType', operator: 'neq', value: 2},
          includes: 'Parameters,DataSources',
          sort: [{ field: 'Name', dir: 'asc' }],
          multiSelect: false,
          selectionRequired: true,
          columns: [{ field: 'Name', title: this.translate.get('i18n:FOLDER.NAME'), filterable: true }] // TODO: add other columns
        };
    }

    public init(report: Report, isNew: boolean, folderId: string) {
      this.parametersFormGroup = new FormGroup({});
      this.dataSourcesFormGroup = new FormGroup({});
      this.dataSourceSelectionPopupSettings = {};

      if(report) {
        this.isNew = isNew;
        this.report = report;
        this.reportDefinition = report.ReportDefinition;
        this.folderId = report.FolderId;
        this.formGroup.get('name').setValue(report.Name);
        this.formGroup.get('reportDefinitionId').setValue(report.ReportDefinitionId);
        this.formGroup.get('folderId').setValue(report.FolderId);
        this.items = report.AccessRights ? report.AccessRights : []; 
        this.updateParametersAndDataSources(report.ReportDefinition, report);
        
      } else {
        this.folderId = folderId;
        this.items = [];
        this.formGroup.get('name').setValue("New report");
        this.formGroup.get('reportDefinitionId').setValue(null);
        this.formGroup.get('folderId').setValue(folderId);
        this.parametersFormGroup = new FormGroup({});
        this.dataSourcesFormGroup = new FormGroup({});
      }
    }

    public onReportDefinitionChanged(reportDefinition: ReportDefinition) {
      this.reportDefinition = reportDefinition;
      this.updateParametersAndDataSources(reportDefinition, null);
    }

    private updateParametersAndDataSources(reportDefinition: ReportDefinition, report: Report)
    {
      if (reportDefinition && reportDefinition.Parameters && reportDefinition.Parameters.length) {
        reportDefinition.Parameters.forEach((parameter: ReportParameter) => {
          if (!this.parametersFormGroup.get(parameter.Id))
            this.parametersFormGroup.addControl(parameter.Id, new FormControl(null, parameter.IsRequired ? Validators.required : null));
        });

        if (report && report.ParameterValues && report.ParameterValues.length) {
          report.ParameterValues.forEach((parameterValue: ReportParameterValue) => {
            const formControl = this.parametersFormGroup.get(parameterValue.ReportParameterId);
            if (formControl && parameterValue.Value) {
              const value = JSON.parse(parameterValue.Value);

              switch (parameterValue.ReportParameter?.Type) {
                case 1:
                  formControl.setValue(value.intValue);
                  break;
                case 2:
                  formControl.setValue(value.floatValue);
                  break;
                case 3:
                case 5:
                  formControl.setValue(value);
                  break;
                case 4:
                  formControl.setValue(value.boolValue);
                  break;
                default:
                case 0:
                  formControl.setValue(value.text);
                  break;
              }
            }
          });
        }
      }

      if (reportDefinition && reportDefinition.DataSources && reportDefinition.DataSources.length) {
        reportDefinition.DataSources.forEach((dataSource: ReportDefinitionDataSource) => {
          if (!this.dataSourcesFormGroup.get(dataSource.Id))
            this.dataSourcesFormGroup.addControl(dataSource.Id, new FormControl(null, dataSource.IsRequired ? Validators.required : null));

          this.dataSourceSelectionPopupSettings[dataSource.Id] = {
            title: dataSource.Name,
            entitySet: 'DataSources',
            includes: 'Folder',
            filter: { field: 'Type', operator: 'eq', value: dataSource.DataSourceType },
            sort: [{ field: 'Folder.Path', dir: 'asc'}, { field: 'Name', dir: 'asc' }],
            multiSelect: false,
            selectionRequired: dataSource.IsRequired,
            columns: [
                { field: 'Folder.Path', title: this.translate.get('i18n:FOLDER.PATH'), filterable: true },
                { field: 'Name', title: this.translate.get('i18n:DATASOURCE.NAME'), filterable: true }
            ]
          };
        });

        if (report && report.DataSources && report.DataSources.length) {
          report.DataSources.forEach((dataSource: ReportDataSource) => {
            const formControl = this.dataSourcesFormGroup.get(dataSource.ReportDefinitionDataSourceId);
            if (formControl)
              formControl.setValue(dataSource.DataSourceId);
          });
        }
      }
    }

    public getNewReport(): Report {
        let newReport = new Report();
        newReport.Name = this.formGroup.get('name').value;
        newReport.ReportDefinitionId = this.formGroup.get('reportDefinitionId').value;
        newReport.FolderId = this.formGroup.get('folderId').value;
        newReport.AccessRights = this.items;
        newReport.ParameterValues = [];
        newReport.DataSources = [];
        
        if (this.reportDefinition && this.reportDefinition.Parameters && this.reportDefinition.Parameters.length) {
          this.reportDefinition.Parameters.forEach((parameter: ReportParameter) => {
            const reportParameterValue = new ReportParameterValue();
            reportParameterValue.ReportParameterId = parameter.Id;
            reportParameterValue.ReportParameter = parameter;

            const formControl = this.parametersFormGroup.get(parameter.Id);
            switch (parameter.Type) {
              case 1:
                reportParameterValue.Value = JSON.stringify({ dataType: parameter.Type, reportParameterId: parameter.Id, intValue: formControl?.value });
                break;
              case 2:
                reportParameterValue.Value = JSON.stringify({ dataType: parameter.Type, reportParameterId: parameter.Id, floatValue: formControl?.value });
                break;
              case 3:
              case 5:
                const value = formControl?.value ?? {};
                value.dataType = parameter.Type;
                value.reportParameterId = parameter.Id;
                reportParameterValue.Value = JSON.stringify(value);
                break;
              case 4:
                reportParameterValue.Value = JSON.stringify({ dataType: parameter.Type, reportParameterId: parameter.Id, boolValue: formControl?.value });
                break;
              default:
                reportParameterValue.Value = JSON.stringify({ dataType: parameter.Type, reportParameterId: parameter.Id, text: formControl.value?.toString() });
                break;
            }

            newReport.ParameterValues.push(reportParameterValue);
          });
        }

        if (this.reportDefinition && this.reportDefinition.DataSources && this.reportDefinition.DataSources.length) {
          this.reportDefinition.DataSources.forEach((dataSource: ReportDefinitionDataSource) => {
            const reportDataSource = new ReportDataSource();
            reportDataSource.ReportDefinitionDataSource = dataSource;
            reportDataSource.ReportDefinitionDataSourceId = dataSource.Id;

            const formControl = this.dataSourcesFormGroup.get(dataSource.Id);
            if (formControl)
              reportDataSource.DataSourceId = formControl.value?.toString();

            newReport.DataSources.push(reportDataSource);
          })
        }

        return newReport;
    }

    public markAllAsTouched() {
      this.formGroup.markAllAsTouched();
      this.parametersFormGroup.markAllAsTouched();
       this.dataSourcesFormGroup.markAllAsTouched();
    }

    public isValid(): boolean {
        return this.formGroup.valid && this.parametersFormGroup.valid && this.dataSourcesFormGroup.valid;
    }
}