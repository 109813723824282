import { DataSource } from "@ats/ats-platform-dashboard";
import { Report } from "./report";
import { ReportDefinitionDataSource } from "./reportDefinitionDataSource";

export class ReportDataSource {
    Id: string;
    ReportId: string;
    Report: Report;
    ReportDefinitionDataSourceId: string;
    ReportDefinitionDataSource: ReportDefinitionDataSource;
    DataSourceId: string;
    DataSource: DataSource;
}