<form class="k-form" [formGroup]="formGroup">
    <fieldset class="k-form-fieldset">      
        <kendo-grid [data]="items" [height]="410" style="margin-bottom: 16px;" [selectable]="{ mode: 'multiple', drag: true }" kendoGridSelectBy="TagId" [(selectedKeys)]="selectedItemKeys">
            <ng-template kendoGridToolbarTemplate>
                <button kendoButton look="outline" (click)="openTagSelectionPopup()">{{ 'i18n:DATASOURCE.TAGS.ADD' | translate }}</button>
                <button kendoButton look="outline" [disabled]="selectedItemKeys.length == 0" (click)="removeItems()">{{ 'i18n:DATASOURCE.TAGS.REMOVE' | translate }}</button>
            </ng-template>

            <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.TAGS.TAG')" [field]="'TagId'">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
                    {{ getTagDescription(dataItem) }}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.TAGS.MEASUREMENT_UNIT')" [field]="'MeasurementUnitId'" [style]="{ 'padding': '0px' }">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
                    <div style="display: flex; width: 100%; height: 100%; align-items: center;">
                        <div style="flex: 1; padding: 8px 4px 8px 12px;">{{ getMeasurementUnitDescription(dataItem) }}</div>
                        <div style="flex: 0;">
                            <button kendoButton look="flat" icon="edit" (click)="openMeasurementSelectionPopup(dataItem)"></button>
                        </div>
                        <div style="flex: 0;" *ngIf="dataItem.MeasurementUnitId && dataItem.MeasurementUnitId.length">
                            <button kendoButton look="flat" icon="close" (click)="clearMeasurementUnit(dataItem)"></button>
                        </div>
                    </div>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>      
    </fieldset>
</form>