import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, observable, Observable } from "rxjs";
import { debounceTime, map, switchMap, tap } from "rxjs/operators";
import { forEach, find } from 'lodash-es';
import { ApiQueryOptions, ApiService, BASE_URL, CoreService, DataSource, DataSourceConfig, DataSourceInstance, DataSourceResult, GenericDataSourceLoader, PageResult } from "@ats/ats-platform-dashboard";
import { TagHubService } from "./tag-hub.service";
import { TagValue } from "../domain/tagValue";
import { TagDataSourceItem } from "./tagsDataSourceLoader";
import { HttpClient } from "@angular/common/http";
import { EVChargingConstraintData } from "../domain/dataModels/evChargingConstraintData";
import { Asset } from "../domain/entities/asset";
import { EVChargingConstraintDataPoint } from "../domain/dataModels/evChargingConstraintDataPoint";

@Injectable({
 providedIn: 'root',
})
export class EVChargingConstraintDataSourceLoader extends GenericDataSourceLoader {

    private dataSourceInstances: { [id: string]: DataSourceInstance } = {};

    constructor(protected override api: ApiService, protected override core: CoreService, private tagHub: TagHubService, protected override http: HttpClient, @Inject(BASE_URL) protected override baseUrl: string) {
        super(api, core, http, baseUrl);        

        this.tagHub.on('broadcastTagValue', this.onBroadcastTagValue);
    }

    public override load(from: Date, to: Date, instance: DataSourceInstance): Observable<DataSourceResult> {
        if (!this.dataSourceInstances[instance.id]) {
            this.dataSourceInstances[instance.id] = instance;

            return this.tagHub.invoke<TagDataSourceItem[]>('SubscribeDataSource', instance.id).pipe(switchMap((result: TagDataSourceItem[]) => {
                var observer = super.load(from, to, instance);
                return this.processResult(instance, observer);
            }));
        } else {
            var observer = super.load(from, to, instance);
            return this.processResult(instance, observer);
        }
    }

    protected processResult(instance: DataSourceInstance, observable: Observable<DataSourceResult>): Observable<DataSourceResult> {
        return observable.pipe(tap((result: DataSourceResult) => {
            var constraints: EVChargingConstraintData[] = result.data;
              forEach(constraints, (constraint: EVChargingConstraintData) => {

                if (constraint.Points) {
                      forEach(constraint.Points, (point: EVChargingConstraintDataPoint) => {
                        if (!(point.TimeStamp instanceof Date))
                            point.TimeStamp = new Date(point.TimeStamp);
                    });
                }

                constraint.LatestPointSubject = new BehaviorSubject(constraint.LatestPoint);
                constraint.LatestPoint$ = constraint.LatestPointSubject.asObservable();
                constraint.LatestPoint$.pipe(debounceTime(50)).subscribe((point: EVChargingConstraintDataPoint) => {
                    instance.fireItemValueChangeEvent(constraint.Asset.Id, point);
                });
            });

            result.data = constraints;
        }));
    }

    public override unload(id: string): Observable<void> {
        var instance = this.dataSourceInstances[id];
        var constraints: EVChargingConstraintData[] = instance?.result?.data;
            forEach(constraints, (constraint: EVChargingConstraintData) => {
            constraint.LatestPointSubject = new BehaviorSubject(constraint.LatestPoint);
            constraint.LatestPointSubject.complete();
        });

        delete this.dataSourceInstances[id];

        return this.tagHub.invoke<any>('UnsubscribeDataSource', id);
    }

    override hasItems(): boolean {
        return true;
    }

    override loadItems(dataSource: DataSource): Observable<{ id: string; name: string; }[]> {
        const assetIdConfig: DataSourceConfig = find(dataSource.Configs, (config: DataSourceConfig) => config.Name == 'assetId');

        return this.api.executeFunction('EVCharging', 'GetEVChargingConstraints', assetIdConfig.Value, new ApiQueryOptions({ }))
            .pipe(map((pageResult: PageResult<Asset>) => pageResult.Items.map((item: Asset) => {
                return { id: item.Id, name: item.Path };
            })));
    }

    onBroadcastTagValue = (dataSourceId: string, tagValue: TagValue) => {
        const instance = this.dataSourceInstances[dataSourceId];
        if (instance && instance.result) {
              forEach(<EVChargingConstraintData[]>instance.result.data, (item: EVChargingConstraintData) => {
                if (tagValue.TagId == item.MaxCurrentTagId) {
                    item.LatestPoint.MaxCurrent = tagValue.FloatingPointValue;
                    item.LatestPoint.TimeStamp = tagValue.TimeStamp;
                    item.LatestPointSubject?.next(item.LatestPoint);
                    return false;
                } else if (tagValue.TagId == item.ChargePointsCurrentL1TagId) {
                    item.LatestPoint.ChargePointsCurrentL1 = tagValue.FloatingPointValue;
                    item.LatestPoint.TimeStamp = tagValue.TimeStamp;
                    item.LatestPointSubject?.next(item.LatestPoint);
                    return false;
                } else if (tagValue.TagId == item.ChargePointsCurrentL2TagId) {
                    item.LatestPoint.ChargePointsCurrentL2 = tagValue.FloatingPointValue;
                    item.LatestPoint.TimeStamp = tagValue.TimeStamp;
                    item.LatestPointSubject?.next(item.LatestPoint);
                    return false;
                } else if (tagValue.TagId == item.ChargePointsCurrentL3TagId) {
                    item.LatestPoint.ChargePointsCurrentL3 = tagValue.FloatingPointValue;
                    item.LatestPoint.TimeStamp = tagValue.TimeStamp;
                    item.LatestPointSubject?.next(item.LatestPoint);
                    return false;
                } else if (tagValue.TagId == item.TargetMaxCurrentL1TagId) {
                    item.LatestPoint.TargetMaxCurrentL1 = tagValue.FloatingPointValue;
                    item.LatestPoint.TimeStamp = tagValue.TimeStamp;
                    item.LatestPointSubject?.next(item.LatestPoint);
                    return false;
                } else if (tagValue.TagId == item.TargetMaxCurrentL2TagId) {
                    item.LatestPoint.TargetMaxCurrentL2 = tagValue.FloatingPointValue;
                    item.LatestPoint.TimeStamp = tagValue.TimeStamp;
                    item.LatestPointSubject?.next(item.LatestPoint);
                    return false;
                } else if (tagValue.TagId == item.TargetMaxCurrentL3TagId) {
                    item.LatestPoint.TargetMaxCurrentL3 = tagValue.FloatingPointValue;
                    item.LatestPoint.TimeStamp = tagValue.TimeStamp;
                    item.LatestPointSubject?.next(item.LatestPoint);
                    return false;
                } else if (tagValue.TagId == item.CurrentL1TagId) {
                    item.LatestPoint.CurrentL1 = tagValue.FloatingPointValue;
                    item.LatestPoint.TimeStamp = tagValue.TimeStamp;
                    item.LatestPointSubject?.next(item.LatestPoint);
                    return false;
                } else if (tagValue.TagId == item.CurrentL2TagId) {
                    item.LatestPoint.CurrentL2 = tagValue.FloatingPointValue;
                    item.LatestPoint.TimeStamp = tagValue.TimeStamp;
                    item.LatestPointSubject?.next(item.LatestPoint);
                    return false;
                } else if (tagValue.TagId == item.CurrentL3TagId) {
                    item.LatestPoint.CurrentL3 = tagValue.FloatingPointValue;
                    item.LatestPoint.TimeStamp = tagValue.TimeStamp;
                    item.LatestPointSubject?.next(item.LatestPoint);
                    return false;
                }
                return true;
            });
        }
    }
}