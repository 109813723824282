<form class="k-form" [formGroup]="formGroup">
    <fieldset class="k-form-fieldset">
        <legend class="k-form-legend">{{ 'LABEL.GENERAL' | translate}}</legend>
        <kendo-formfield>
            <kendo-label [for]="title" text="{{ 'WIDGET.TITLE' | translate }}"></kendo-label>
            <input #title formControlName="title" kendoTextBox />
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="widgetType" text="{{ 'WIDGET.TYPE' | translate }}"></kendo-label>
            <kendo-textbox #widgetType formControlName="widgetType" [readonly]="true" ></kendo-textbox>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="dataSourceId" text="{{ 'WIDGET.DATASOURCE' | translate }}"></kendo-label>
            <ats-platform-entity-lookup-textbox #dataSourceId formControlName="dataSourceId"
                [settings]="dataSourceSelectionPopupSettings" [formatter]="dataSourceFormatter"
                (entityChange)="onDataSourceChange($event)"></ats-platform-entity-lookup-textbox>
            <kendo-formerror *ngIf="formGroup.controls['dataSourceId'].errors?.['required']">{{
                'MESSAGE.FIELD_IS_REQUIRED' |
                translate }}</kendo-formerror>
        </kendo-formfield>
    </fieldset>

    <fieldset class="k-form-fieldset">
        <legend class="k-form-legend">{{ 'LABEL.SETTINGS' | translate }}</legend>
        <kendo-formfield>
            <kendo-label class="k-checkbox-label" [for]="yAxisMeasurementUnit"
                text="{{ 'TAG_WIDGET.Y_AXIS_MEASUREMENTUNIT' | translate }}"></kendo-label>
            <input type="checkbox" formControlName="yAxisMeasurementUnit" #yAxisMeasurementUnit kendoCheckBox />
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label class="k-checkbox-label" [for]="verticalGrid" text="{{ 'WIDGET.VERTICAL_GRID' | translate }}">
            </kendo-label>
            <input type="checkbox" formControlName="verticalGrid" #verticalGrid kendoCheckBox />
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label class="k-checkbox-label" [for]="horizontalGrid"
                text="{{ 'WIDGET.HORIZONTAL_GRID' | translate }}"></kendo-label>
            <input type="checkbox" formControlName="horizontalGrid" #horizontalGrid kendoCheckBox />
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="marginLeft" text="{{ 'WIDGET.MARGIN_LEFT' | translate }}"></kendo-label>
            <kendo-numerictextbox #marginLeft formControlName="marginLeft" [min]="0" [decimals]="0" [format]="'n0'">
            </kendo-numerictextbox>
            <kendo-formerror *ngIf="formGroup.controls['marginLeft'].errors?.['required']">{{
                'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="marginTop" text="{{ 'WIDGET.MARGIN_TOP' | translate }}"></kendo-label>
            <kendo-numerictextbox #marginTop formControlName="marginTop" [min]="0" [decimals]="0" [format]="'n0'">
            </kendo-numerictextbox>
            <kendo-formerror *ngIf="formGroup.controls['marginTop'].errors?.['required']">{{
                'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="marginRight" text="{{ 'WIDGET.MARGIN_RIGHT' | translate }}"></kendo-label>
            <kendo-numerictextbox #marginRight formControlName="marginRight" [min]="0" [decimals]="0" [format]="'n0'">
            </kendo-numerictextbox>
            <kendo-formerror *ngIf="formGroup.controls['marginRight'].errors?.['required']">{{
                'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="marginBottom" text="{{ 'WIDGET.MARGIN_BOTTOM' | translate }}"></kendo-label>
            <kendo-numerictextbox #marginBottom formControlName="marginBottom" [min]="0" [decimals]="0" [format]="'n0'">
            </kendo-numerictextbox>
            <kendo-formerror *ngIf="formGroup.controls['marginBottom'].errors?.['required']">{{
                'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>

    </fieldset>

    <fieldset class="k-form-fieldset">
        <legend class="k-form-legend">{{ 'i18n:DATASOURCE.TAGS' | translate }}</legend>
        <kendo-grid [data]="items" [height]="410" style="margin-bottom: 16px;" [loading]="itemsLoading"
            [selectable]="{ mode: 'multiple', drag: true }" kendoGridSelectBy="tagId" (cellClick)="cellClick($event)"
            (cellClose)="cellClose($event)">
            <kendo-grid-column [title]="translate.get('i18n:TAG_WIDGET.TAG')" [field]="'tagId'">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
                    {{ getTagDescription(dataItem) }}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="translate.get('i18n:TAG_WIDGET.SHOW_RANGE')" [field]="'showRange'"
                [editor]="'boolean'">
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                    <div>{{ dataItem.showRange ? translate.get('i18n:LABEL.YES') : translate.get('i18n:LABEL.NO') }}
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="translate.get('i18n:TAG_WIDGET.LINE_TYPE')" [field]="'lineType'">
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                    <div>{{ getLineTypeDescription(dataItem) }}</div>
                </ng-template>
                <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column"
                    let-formGroup="formGroup">
                    <kendo-combobox [data]="lineTypes" [formControl]="formGroup.get('lineType')" textField="Display"
                        valueField="Key" [valuePrimitive]="true"></kendo-combobox>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="translate.get('i18n:TAG_WIDGET.MINIMUM_VALUE')" [field]="'minimumValue'"
                [editor]="'number'"></kendo-grid-column>
            <kendo-grid-column [title]="translate.get('i18n:TAG_WIDGET.MAXIMUM_VALUE')" [field]="'maximumValue'"
                [editor]="'number'"></kendo-grid-column>
        </kendo-grid>
    </fieldset>
</form>