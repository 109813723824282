import { Injectable } from "@angular/core";
import { AbstractDataSourceExporter, DataSourceInstance, CoreService } from "@ats/ats-platform-dashboard";
import { EVChargingConstraintData } from "../domain/dataModels/evChargingConstraintData";
import { isString, join } from "lodash-es";
import { DateTime } from "luxon";
import { EVChargingConstraintDataPoint } from "../domain/dataModels/evChargingConstraintDataPoint";


@Injectable()
export class EVChargingConstraintDataSourceExporter extends AbstractDataSourceExporter {

  constructor(public core: CoreService) {
    super();
  }
  public getAvailableExtensions(dataSourceInstance: DataSourceInstance): { Key: string, Display: string }[] {
    if (dataSourceInstance && dataSourceInstance.result) {
      switch (dataSourceInstance.result.contentType) {
        case 'application/json':
          return this.core.translateObjectArray([
            { Key: 'json', Display: 'json' },
            { Key: 'csv ,', Display: 'csv (,)' },
            { Key: 'csv ;', Display: 'csv (;)' },
            { Key: 'csv s', Display: 'i18n:CSV.SEPARATOR.SPACE' },
            { Key: 'csv t', Display: 'i18n:CSV.SEPARATOR.TAB' },
            { Key: 'csv |', Display: 'i18n:CSV.SEPARATOR.PIPE' }
          ], 'Display');
      }
    }
    return null;
  }

  public export(dataSourceInstance: DataSourceInstance, fileName: string, extension: string) {
    let data: Uint8Array;

    if (dataSourceInstance && dataSourceInstance.result) {
      const result = dataSourceInstance.result;

      switch (extension) {
        case 'json': {
          const str = JSON.stringify(result.data,
            (key: string, value: any): any => {
              if (key == "LatestPointSubject" || key == "LatestPoint$")
                return undefined;
              return value;
            }, 2);
          data = new Uint8Array(str.length);
          data.forEach((value, index, array) => { array[index] = str.charCodeAt(index); });
          break;
        }
        case 'csv ,': { data = this.convertToCsvData(<EVChargingConstraintData[]>result.data, ","); extension = "csv"; break; }
        case 'csv ;': { data = this.convertToCsvData(<EVChargingConstraintData[]>result.data, ";"); extension = "csv"; break; }
        case 'csv s': { data = this.convertToCsvData(<EVChargingConstraintData[]>result.data, " "); extension = "csv"; break; }
        case 'csv t': { data = this.convertToCsvData(<EVChargingConstraintData[]>result.data, "\t"); extension = "csv"; break; }
        case 'csv |': { data = this.convertToCsvData(<EVChargingConstraintData[]>result.data, "|"); extension = "csv"; break; }
      }

      if (!data) {
        throw 'No data to export.';
      }

      this.core.generateDownload(data, fileName, extension, result.contentType);
    } else {
      throw 'No data to export.';
    }
  }

  private convertToCsvData(evChargingConstraintData: EVChargingConstraintData[], separator: string): Uint8Array {
    let csvdata: Uint8Array;

    if (evChargingConstraintData && evChargingConstraintData.length) {

      let str = join(['TimeStamp', 'Asset', 'CurrentL1', 'CurrentL2', 'CurrentL3', 'ChargePointsCurrentL1', 'ChargePointsCurrentL2', 'ChargePointsCurrentL3', 'TargetMaxCurrentL1', 'TargetMaxCurrentL2', 'TargetMaxCurrentL3', 'MaxCurrent', '\r\n'], separator);

      evChargingConstraintData.forEach((data: EVChargingConstraintData) => {
        if (data.Points && data.Points.length) {
          let assetPath = data.Asset.Path ?? '';
          data.Points.forEach((point: EVChargingConstraintDataPoint) => {

            str += '"' + (isString(point.TimeStamp) ? DateTime.fromISO(point.TimeStamp.toString()).toISO() : DateTime.fromJSDate(point.TimeStamp).toISO()) + '"' + separator;
            str += '"' + assetPath + '"' + separator;

            str += (point.CurrentL1 ?? '') + separator;
            str += (point.CurrentL2 ?? '') + separator;
            str += (point.CurrentL3 ?? '') + separator;
            str += (point.ChargePointsCurrentL1 ?? '') + separator;
            str += (point.ChargePointsCurrentL2 ?? '') + separator;
            str += (point.ChargePointsCurrentL3 ?? '') + separator;

            str += (point.TargetMaxCurrentL1 ?? '') + separator;
            str += (point.TargetMaxCurrentL2 ?? '') + separator;
            str += (point.TargetMaxCurrentL3 ?? '') + separator;
            str += (point.MaxCurrent ?? '') + '\r\n';
          });
        }
      });

      csvdata = new Uint8Array(str.length);
      csvdata.forEach((value, index, array) => { array[index] = str.charCodeAt(index); });
    } 

    return csvdata;
  }
}