<form class="k-form" [formGroup]="formGroup">
    <fieldset class="k-form-fieldset">
        <kendo-formfield>
            <kendo-label [for]="MeasurementUnitId"
                text="{{ 'i18n:DATASOURCE.PRODUCTION_CONSUMPTION.MEASUREMENTUNITID' | translate }}"></kendo-label>
            <ats-platform-entity-lookup-textbox #MeasurementUnitId formControlName="MeasurementUnitId"
                [settings]="measurementUnitSelectionPopupSettings" [formatter]="measurementUnitFormatter">
            </ats-platform-entity-lookup-textbox>
            <kendo-formerror *ngIf="formGroup.controls['MeasurementUnitId'].errors?.['required']">
                {{'i18n:MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
    </fieldset>
    <fieldset class="k-form-fieldset">
        <legend class="k-form-legend">{{ 'i18n:DATASOURCE.TAGS' | translate }}</legend>     
        <kendo-grid [data]="items" [height]="410"  style="margin-bottom: 16px;"
            [selectable]="{ mode: 'multiple', drag: true }" kendoGridSelectBy="TagId"
            [(selectedKeys)]="selectedItemKeys" (cellClick)="cellClick($event)" (cellClose)="cellClose($event)">
            <ng-template kendoGridToolbarTemplate>
                <button kendoButton look="outline" (click)="openTagSelectionPopup()">{{ 'i18n:DATASOURCE.TAGS.ADD' |
                    translate }}</button>
                <button kendoButton look="outline" [disabled]="selectedItemKeys.length == 0"
                    (click)="removeItems()">{{ 'i18n:DATASOURCE.TAGS.REMOVE' | translate }}</button>
                <kendo-formerror *ngIf="hasGridErr()">{{gridErr}}</kendo-formerror>
            </ng-template>

            <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.PRODUCTION_CONSUMPTION.TAG')" [field]="'TagId'">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
                    {{ getTagDescription(dataItem) }}
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.PRODUCTION_CONSUMPTION.CATEGORY')"
                [field]="'Category'">
            </kendo-grid-column>
            <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.PRODUCTION_CONSUMPTION.COLOR')"
                [field]="'Color'">
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                    <div class="selected-color" [ngStyle]="{ 'background-color': dataItem.Color }"></div>
                </ng-template>
                <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column"
                    let-formGroup="formGroup">
                    <kendo-colorpicker activeView="palette" [format]="'hex'" [formControl]="formGroup.get('Color')">
                    </kendo-colorpicker>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>      
    </fieldset>
</form>