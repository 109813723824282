import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Type, ViewChild } from "@angular/core";
import { HubConnection } from "@aspnet/signalr";
import { DashboardService, DataSourceInstance, ItemValueChangedEventArgs, TranslateService, WidgetComponent, WidgetInstance, SvgContainerComponent, WidgetUpdater, DataSource } from "@ats/ats-platform-dashboard";
import { BehaviorSubject, Observable, Subscription } from "rxjs";

import { find, orderBy, isEqual } from "lodash-es";
import { select } from 'd3';
import { TagDataSourceItem } from "../../services/tagsDataSourceLoader";
import { TagValueText, TAG_VALUE_TEXT_DEFAULT_FONT_SIZE, TAG_VALUE_TEXT_DEFAULT_SUB_FONT_SIZE } from "../../svg/tag-value-text";

@Component({
    selector: 'ats-smart-tool-tags-value-widget',
    templateUrl: './tags-value-widget.component.html',
    styleUrls: ['./tags-value-widget.component.scss']
})
export class TagsValueWidgetComponent implements WidgetComponent, OnInit, AfterViewInit, OnDestroy {
    @Input() hubConnection: HubConnection;

    public widgetInstance: WidgetInstance;
    public isLoading = false;
    public error: string;
    public rowHeight: number = 100;
    public numberOfRows = 2;

    private widgetConfigSubscription: Subscription;
    private widgetUpdater: WidgetUpdater;

    private valueTexts: { [key: string]: TagValueText } = {};
    private svgSubscriptions: Subscription[];

    public svgContainerType: Type<SvgContainerComponent> = SvgContainerComponent;

    @ViewChild('svgContainer', { static: false }) svgContainer: ElementRef;

    private itemsSubject: BehaviorSubject<TagDataSourceItem[]>;
    public items$: Observable<TagDataSourceItem[]>;

    constructor(private dashboardService: DashboardService, private translate: TranslateService) {
        this.itemsSubject = new BehaviorSubject<TagDataSourceItem[]>([]);
        this.items$ = this.itemsSubject.asObservable();
    }

    public setWidgetInstance(widgetInstance: WidgetInstance) {
        this.widgetInstance = widgetInstance;
    }

    ngOnInit() {
        this.widgetConfigSubscription = this.widgetInstance.changed.subscribe({
            next: (instance: WidgetInstance) => {
                this.updateData(instance);
            }
        });
    }

    ngAfterViewInit() {
        setTimeout(() => { this.updateData(this.widgetInstance); }, 0);
    }

    ngOnDestroy() {
        this.clearSvgSubscriptions();

        if (this.widgetConfigSubscription) {
            this.widgetConfigSubscription.unsubscribe();
            this.widgetConfigSubscription = null;
        }

        if (this.widgetUpdater) {
            this.widgetUpdater.destroy();
            this.widgetUpdater = null;
        }
    }

    protected clearSvgSubscriptions() {
        if (this.svgSubscriptions && this.svgSubscriptions.length) {
            this.svgSubscriptions.forEach((svgSubscription: Subscription) => svgSubscription.unsubscribe());
        }

        this.svgSubscriptions = [];
    }

    protected updateData(instance: WidgetInstance) {
        instance.widgetData = {
            title: instance.widgetConfig.title
        };

        if (this.widgetUpdater) {
            this.widgetUpdater.destroy();
            this.widgetUpdater = null;
        }

        const dataSourceInstance = find(this.dashboardService.dataSourceInstances, (dataSourceInstance: DataSourceInstance) => dataSourceInstance.id == instance.widgetConfig.dataSourceId);
        if (dataSourceInstance) {
            if (!instance.widgetData.title)
                instance.widgetData.title = dataSourceInstance.name;

            this.widgetUpdater = new WidgetUpdater(dataSourceInstance, this.svgContainer.nativeElement,
                (dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement) => this.update(dataSourceInstance, nativeElement),
                (dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement, e: ItemValueChangedEventArgs) => this.updateValue(dataSourceInstance, nativeElement, e));
        }
    }

    protected update(dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement) {
        this.isLoading = dataSourceInstance?.result?.isLoading;

        if (!this.widgetInstance.widgetData.title)
            this.widgetInstance.widgetData.title = dataSourceInstance.name;

        this.rowHeight = 61 + (this.widgetInstance.widgetConfig.fontSize ?? TAG_VALUE_TEXT_DEFAULT_FONT_SIZE) + (this.widgetInstance.widgetConfig.subFontSize ?? TAG_VALUE_TEXT_DEFAULT_SUB_FONT_SIZE);
        this.numberOfRows = Math.floor((nativeElement.getBoundingClientRect().width - 8) / ((this.widgetInstance.widgetConfig.width ?? 250) + 8));

        if (dataSourceInstance.result && dataSourceInstance.result.data) {
            const oldItems = orderBy(<TagDataSourceItem[]>this.itemsSubject.value, [x => x.Tag?.Asset?.Path, x => x.Tag?.Name]);
            const newItems = orderBy(<TagDataSourceItem[]>dataSourceInstance.result.data, [x => x.Tag?.Asset?.Path, x => x.Tag?.Name]);
            if (!isEqual(oldItems, newItems)) {
                this.valueTexts = {};
                this.clearSvgSubscriptions();
                this.itemsSubject.next(newItems);
            }
        }
    }

    protected updateValue(dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement, e: ItemValueChangedEventArgs) {
        const item = <TagDataSourceItem>e.value;
        if (this.valueTexts && this.valueTexts[e.id]) {
            this.valueTexts[e.id].setValue(item);
        }
    }

    public cancel(e: any) {
        e.preventDefault();
        e.cancelBubble = true;
    }

    public onSvgContainerCreated(instance: SvgContainerComponent, item: TagDataSourceItem) {
        if (instance) {
            this.svgSubscriptions.push(instance.svgInitialized.subscribe({
                next: (svg: SVGSVGElement) => {
                    const d3svg = select(svg);
                    d3svg.selectAll('g').remove();
                    d3svg.attr('viewBox', '0 0 ' + svg.getBoundingClientRect().width + ' ' + svg.getBoundingClientRect().height);

                    const valueText = new TagValueText(this.translate);
                    valueText.numberOfDecimals = this.widgetInstance.widgetConfig.numberOfDecimals;
                    valueText.dateTimeFormat = this.widgetInstance.widgetConfig.dateTimeFormat;
                    valueText.fontSize = this.widgetInstance.widgetConfig.fontSize;
                    valueText.subFontSize = this.widgetInstance.widgetConfig.subFontSize;
                    valueText.unit = item?.MeasurementUnit?.Symbol ?? item?.MeasurementUnit?.Name;
                    valueText.width = svg.getBoundingClientRect().width;
                    valueText.height = svg.getBoundingClientRect().height;
                    valueText.group = d3svg.append('g');
                    valueText.drawTag(item);

                    this.valueTexts[item.TagId] = valueText;
                }
            }));
        }
    }
}