<form class="k-form" [formGroup]="formGroup">
  <fieldset class="k-form-fieldset">
    <legend class="k-form-legend">{{ 'i18n:DATASOURCE.DEVICES' | translate }}</legend>
    <kendo-formfield>
    <kendo-grid [data]="items" [height]="410" [selectable]="{ mode: 'multiple', drag: true }" kendoGridSelectBy="Name"
      [(selectedKeys)]="selectedItemKeys" (remove)="removeHandler($event)" (add)="addHandler($event)"
      (cellClick)="cellClickHandler($event)" (cellClose)="cellCloseHandler($event)">
      <ng-template kendoGridToolbarTemplate>
        <button kendoGridAddCommand>{{ 'i18n:DATASOURCE.TOPOLOGY.ADD_DEVICE' | translate }}</button>
        <kendo-formerror *ngIf="hasGridErr()">{{gridErr}}</kendo-formerror>
      </ng-template>
      <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.DEVICE_TOPOLOGY.NAME')" [field]="'Name'">
      </kendo-grid-column>
      <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.DEVICE_TOPOLOGY.POWERTAG')" [field]="'PowerTagId'"
        [style]="{ 'padding': '0px' }">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
          <div class="k-grid-ignore-click" style="display: flex; width: 100%; height: 100%; align-items: center;">
            <div style="flex: 1; padding: 8px 4px 8px 12px;">{{ getTagDescription(dataItem, 'Power') }}
            </div>
            <div style="flex: 0;">
              <button kendoButton look="flat" icon="edit" (click)="openTagSelectionPopup(dataItem, 'Power')"></button>
            </div>
            <div style="flex: 0;" *ngIf="dataItem.PowerTagId && dataItem.PowerTagId.length">
              <button kendoButton look="flat" icon="close" (click)="clearTagCell(dataItem, 'Power')"></button>
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.DEVICE_TOPOLOGY.ENERGYINTAG')"
        [field]="'EnergyInTagId'" [style]="{ 'padding': '0px' }">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
          <div class="k-grid-ignore-click" style="display: flex; width: 100%; height: 100%; align-items: center;">
            <div style="flex: 1; padding: 8px 4px 8px 12px;">{{ getTagDescription(dataItem, 'EnergyIn') }}
            </div>
            <div style="flex: 0;">
              <button kendoButton look="flat" icon="edit"
                (click)="openTagSelectionPopup(dataItem, 'EnergyIn')"></button>
            </div>
            <div style="flex: 0;" *ngIf="dataItem.EnergyInTagId && dataItem.EnergyInTagId.length">
              <button kendoButton look="flat" icon="close" (click)="clearTagCell(dataItem, 'EnergyIn')"></button>
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.DEVICE_TOPOLOGY.ENERGYOUTTAG')"
        [field]="'EnergyOutTagId'" [style]="{ 'padding': '0px' }">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
          <div class="k-grid-ignore-click" style="display: flex; width: 100%; height: 100%; align-items: center;">
            <div style="flex: 1; padding: 8px 4px 8px 12px;">{{ getTagDescription(dataItem, 'EnergyOut') }}
            </div>
            <div style="flex: 0;">
              <button kendoButton look="flat" icon="edit"
                (click)="openTagSelectionPopup(dataItem, 'EnergyOut')"></button>
            </div>
            <div style="flex: 0;" *ngIf="dataItem.EnergyOutTagId && dataItem.EnergyOutTagId.length">
              <button kendoButton look="flat" icon="close" (click)="clearTagCell(dataItem, 'EnergyOut')"></button>
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.DEVICE_TOPOLOGY.PARENTDEVICE')"
        [field]="'ParentDeviceName'">
        <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-formGroup="formGroup">
          <kendo-dropdownlist #ParentDeviceName [formControl]="formGroup.get('ParentDeviceName')"
            [valuePrimitive]="true" [defaultItem]="defaultItem" [data]="items" [textField]="'Name'" [valueField]="'Name'">
          </kendo-dropdownlist>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem.ParentDeviceName }}
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.DEVICE_TOPOLOGY.CHART')" [field]="'ChartId'"
        [style]="{ 'padding': '0px' }">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
          <div class="k-grid-ignore-click" style="display: flex; width: 100%; height: 100%; align-items: center;">
            <div style="flex: 1; padding: 8px 4px 8px 12px;">{{getTagChartDescription(dataItem)}}
            </div>
            <div style="flex: 0;">
              <button kendoButton look="flat" icon="edit" (click)="openChartSelectionPopup(dataItem)"></button>
            </div>
            <div style="flex: 0;" *ngIf="dataItem.ChartId && dataItem.ChartId.length">
              <button kendoButton look="flat" icon="close" (click)="clearChartCell(dataItem)"></button>
            </div>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.DEVICE_TOPOLOGY.ICON')" [field]="'IconName'">
      </kendo-grid-column>
      <kendo-grid-command-column width="150">
        <ng-template kendoGridCellTemplate let-isNew="isNew" let-dataItem>
          <button kendoGridRemoveCommand [primary]="true">{{
            'i18n:DATASOURCE.DEVICE_TOPOLOGY.REMOVE' | translate }}</button>
        </ng-template>
      </kendo-grid-command-column>
    </kendo-grid>
    </kendo-formfield>
  </fieldset>
</form>