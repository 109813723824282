import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DialogCloseResult, DialogResult, DialogService } from '@progress/kendo-angular-dialog';
import { TagChart } from '../domain/entities/tagChart';
import { ApiQueryOptions, ApiService, AuthService, CoreService, FileSaveService, TranslateService } from '@ats/ats-platform-dashboard';
import { ChartPopupComponent } from '../components/chart-popup/chart-popup.component';
import { ChartToReportPopupComponent } from "../components/chart-to-report-popup/chart-to-report-popup.component";
import { TagChartData } from '../domain/tagChartData';
import { TagsChartData } from '../domain/tagsChartData';
import { TagValueHistory } from '../domain/tagValueHistory';
import { DateTime } from 'luxon';


@Injectable({
  providedIn: "root",
})
export class ChartService {

  public chartAdded: EventEmitter<TagChart> = new EventEmitter<TagChart>();
  public chartUpdate: EventEmitter<TagChart> = new EventEmitter<TagChart>();
  public chartDeleted: EventEmitter<string> = new EventEmitter<string>();

  private currentChartSubject: BehaviorSubject<TagChart> = new BehaviorSubject(null);
  public currentChart$: Observable<TagChart>;
  private currentFolderIdSubject: BehaviorSubject<string> = new BehaviorSubject(null);
  public currentFolderId$: Observable<string>;

  public chartConfigChanged: boolean = false;

  public get currentChart(): TagChart {
    return this.currentChartSubject.value;
  }

  public set currentChart(chart: TagChart) {
    this.currentChartSubject.next(chart);
    
    if (chart && chart.FolderId)
      this.currentFolderIdSubject.next(chart.FolderId)
  }

  public get currentFolderId(): string {
    return this.currentFolderIdSubject.value;
  }

  public set currentFolderId(folderId: string) {
    this.currentFolderIdSubject.next(folderId)
  }

  private currentChartDataSubject: BehaviorSubject<TagsChartData> = new BehaviorSubject(null);
  public currentChartData$: Observable<TagsChartData>;

  public periodFrom: Date;
  public periodTo: Date;

  constructor(private api: ApiService, private authService: AuthService, private core: CoreService, private translate: TranslateService, private dialogService: DialogService,
    private fileSaveService: FileSaveService) {
    this.currentChart$ = this.currentChartSubject.asObservable();
    this.currentChartData$ = this.currentChartDataSubject.asObservable();
    this.currentFolderId$ = this.currentFolderIdSubject.asObservable();
  }

  public save(chart: TagChart, callback?: (chart: TagChart) => void) {
    this.saveAction('Save', chart, callback);
    this.chartConfigChanged = false;
  }

  public saveAsCopy(chart: TagChart, callback?: (chart: TagChart) => void) {
    this.saveAction('SaveAsCopy', chart, callback);
    this.chartConfigChanged = false;
  }

  private saveAction(action: string, chart: TagChart, callback: (chart: TagChart) => void) {
    this.core.setBusy(true);
      this.api.executeUnboundAction('UserCharts', action, chart, new ApiQueryOptions({ include: 'Folder,Items,Asset,AssetType,AccessRights.User,AccessRights.Role' })).subscribe({
          next: (savedChart: TagChart) => {
              this.core.setBusy(false);

              if (!chart.Id || (chart.Id.toLowerCase() !== savedChart.Id.toLowerCase())) {
                  this.chartAdded.emit(savedChart);
              } else {
                  this.chartUpdate.emit(savedChart);
              }

              this.core.showNotification(this.translate.get('i18n:MESSAGE.CHART_SAVED'), 'success');

              if (callback) {
                  callback(savedChart);
              }

              if (this.currentChart && this.currentChart.Id == savedChart.Id) {
                  this.currentChart = savedChart;
              } else {
                  this.core.navigate(['chart', savedChart.Id]);
              }
          }, error: (error: any) => {
              this.core.setBusy(false);
              this.core.showErrorDetail(error);
          }
    });
  }

  public deleteChart(id: string) {
    this.core.showConfirmation(this.translate.get('i18n:CHART.DELETE'), this.translate.get('i18n:CONFIRMATION.CHART_DELETE')).subscribe({
      next: (confirmed: boolean) => {
        if (confirmed) {
          this.core.setBusy(true);
          this.api.removeResult('TagCharts', id, new ApiQueryOptions({})).subscribe({
            next: () => {
              this.core.showNotification(this.translate.get('i18n:MESSAGE.CHART_DELETED'), 'success');

              this.chartDeleted.emit(id);

              this.core.navigate(['chart', 'new']);
            }, error: (error: any) => {
              this.core.setBusy(false);
              this.core.showErrorDetail(error);
            }
          });
        }
      }
    });
  }

  public getChart(id: string, options: ApiQueryOptions = new ApiQueryOptions({})): Observable<TagChart> {
    return this.api.getSingleResult('UserCharts', id, options);
  }

  public showChartPopup(chart: TagChart, folderId: string) {
    const dialogRef = this.dialogService.open({
      content: ChartPopupComponent,
      title: this.translate.get('i18n:CHART'),
      minHeight: '90vh',
      minWidth: '90vw',
      actions: [{ text: this.translate.get('i18n:LABEL.CANCEL') }, { text: this.translate.get('i18n:LABEL.OK'), themeColor: 'primary' }],
      preventAction: (ev, dialog) => {
        if ((ev instanceof DialogCloseResult) || (ev.themeColor != 'primary'))
          return false;

        if (dialog.content.instance.isValid()) {
          return false;
        }

        return true;
      }
    });

    const popup: ChartPopupComponent = dialogRef.content.instance;
    popup.init(chart, !chart?.Id, folderId);

    dialogRef.result.subscribe({
      next: (r: DialogResult) => {     
            if (!(r instanceof DialogCloseResult) && (r.themeColor == 'primary')) {
          if (r.text == 'OK'){
            this.chartConfigChanged = true;
          }
          if (chart) {
            chart.Name = popup.formGroup.get('name').value;
            chart.FolderId = popup.formGroup.get('folderId').value;
            chart.AssetTypeId = popup.formGroup.get('assetTypeId').value;
            chart.TemplateId = popup.formGroup.get('templateId').value;
            chart.Items = popup.items;
            chart.AccessRights = popup.accessRights;
            chart.FillTimeInterval = popup.formGroup.get('aggregationWindow').value;

            var config = chart.Config ? JSON.parse(chart.Config) : {};
            config.scales = popup.scales;
            config.fixedValues = popup.fixedValues;
            config.maxPoints = popup.formGroup.get('maxPoints').value;
            config.chartHeight = popup.formGroup.get('chartHeight').value;
            chart.Config = JSON.stringify(config, (key, value) => {
              switch (key) {
                case 'MeasurmentUnit':
                case 'measurementUnit':
                  return null;
                default:
                  return value;
              }
            });

            this.currentChart = chart;
          } else {
            this.core.navigate(['chart', 'new'], {
              state: {
                Name: popup.formGroup.get('name').value,
                FolderId: popup.formGroup.get('folderId').value,
                AccessRights: popup.accessRights
              }
            });
          }
        }
      }
    });
  }

  public showFileSavePopup(chart: TagChart) {
    if (chart && chart.Items.length) {

      let extensions = this.core.translateObjectArray([
        { Key: 'json', Display: 'json' },
        { Key: 'csv ,', Display: 'csv (,)' },
        { Key: 'csv ;', Display: 'csv (;)' },
        { Key: 'csv s', Display: 'i18n:CSV.SEPARATOR.SPACE' },
        { Key: 'csv t', Display: 'i18n:CSV.SEPARATOR.TAB' },
        { Key: 'csv |', Display: 'i18n:CSV.SEPARATOR.PIPE' }
      ], 'Display');

      this.fileSaveService.showFileSavePopup(chart.Name, extensions).subscribe(result => {
        this.export(result.fileName, result.extension);
      });
    }
  }

  public showChartToReportPopup() {
    const dialogRef = this.dialogService.open({
      content: ChartToReportPopupComponent, 
      title: this.translate.get('i18n:CHART_TO_REPORT'),
      minHeight: '90vh',
      minWidth: '90vw',
      actions: [{ text: this.translate.get('i18n:LABEL.CANCEL') }, { text: this.translate.get('i18n:LABEL.DOWNLOAD'), themeColor: 'primary' }],
      preventAction: (ev, dialog) => {
        if ((ev instanceof DialogCloseResult) || (ev.themeColor != 'primary'))
          return false;

        if (dialog.content.instance.isValid()) {
          return false;
        }

        return true;
      }
    });

    const popup: ChartToReportPopupComponent = dialogRef.content.instance;
    popup.init(this.currentChart);

    dialogRef.result.subscribe((r: DialogResult) => {
      if (!(r instanceof DialogCloseResult) && (r.themeColor == 'primary')) {
        const command = popup.getCommand();
        command.Chart = this.currentChart; 
        this.core.setBusy(true);
        this.api.executeUnboundAction('ReportDefinitions', 'Download', command , new ApiQueryOptions({}), { responseType: "arraybuffer" }).subscribe({
          next: (result: ArrayBuffer) => {
            dialogRef.close();
            const data: Uint8Array = new Uint8Array(result);
            this.core.generateDownload(data, command.Name + '.pdf', 'pdf', "application/pdf");
            this.core.setBusy(false);
          },  error:  (error) => {
            this.core.setBusy(false);
            this.core.showErrorDetail(error, popup.chartToReportContainer);
          }
        });
      }
    });
  }

  private export(fileName: string, extension: string) {
    const result = this.currentChartDataSubject.value;
    let data: Uint8Array;

      if (result) {
        switch (extension) {
          case 'json': {
            const str = JSON.stringify(result.Data, null, 2);
            data = new Uint8Array(str.length);
            data.forEach((value, index, array) => { array[index] = str.charCodeAt(index); });
            break;
          }
          case 'csv ,': { data = this.convertToCsvData(<TagChartData[]>result.Data, ","); extension = "csv"; break; }
          case 'csv ;': { data = this.convertToCsvData(<TagChartData[]>result.Data, ";"); extension = "csv"; break; }
          case 'csv s': { data = this.convertToCsvData(<TagChartData[]>result.Data, " "); extension = "csv"; break; }
          case 'csv t': { data = this.convertToCsvData(<TagChartData[]>result.Data, "\t"); extension = "csv"; break; }
          case 'csv |': { data = this.convertToCsvData(<TagChartData[]>result.Data, "|"); extension = "csv"; break; }
        }

        if (!data) {
          throw 'No data to export.';
        }
  
        this.core.generateDownload(data, fileName, extension, "application/json");
      } else {
        throw 'No data to export.';
      }
  }

private convertToCsvData(tagChartData: TagChartData[], separator: string): Uint8Array
{
  let csvdata: Uint8Array;

  if (tagChartData && tagChartData.length) {
    let str = 'TimeStamp'+ separator + 'Asset'+ separator + 'Tag'+ separator + 'UnitOfMeasurement'+ separator + 'FloatingPointValue'+ separator + 'IntegerValue'+ separator + 'BooleanValue'+ separator + 'MeanValue'+ separator + 'MinimumValue'+ separator + 'MaximumValue'+ separator + 'Count\r\n';
    tagChartData.forEach((tagEntry: TagChartData) => {
      if (tagEntry.Values && tagEntry.Values.length) {
        const assetPath = tagEntry.Tag?.Asset?.Path ?? '';
        const tagName = tagEntry.Tag?.Name ?? '';
        const uom = tagEntry.Unit?.Symbol ?? tagEntry.Unit?.Name ?? '';

        tagEntry.Values.forEach((value: TagValueHistory) => {
          str += ('"' + DateTime.fromJSDate(value.TimeStamp).toISO() + '"' + separator + '"' +
            assetPath + '"' + separator + '"' +
            tagName + '"' + separator + '"' +
            uom + '"' + separator + '' +
            (value.FloatingPointValue ?? '') + separator +
            (value.IntegerValue ?? '') + separator +
            (value.BooleanValue ?? '') + separator +
            (value.MeanValue ?? '') + separator +
            (value.MinimumValue ?? '') + separator +
            (value.MaximumValue ?? '') + separator +
            (value.Count ?? '') + '\r\n');
        });
      }
    });
    csvdata = new Uint8Array(str.length);
    csvdata.forEach((value, index, array) => { array[index] = str.charCodeAt(index); });
  } 
  return csvdata;
}

  public reload() {
    this.currentChartDataSubject.next(null);

    if (this.currentChart && ((this.currentChart.Items && this.currentChart.Items.length) || this.currentChart.TemplateId)) {
      this.core.setBusy(true, this.translate.get('COMMON.LOADING'));
      this.api.executeUnboundAction('TagValueHistory', 'GetChartData', this.currentChart, new ApiQueryOptions({})).subscribe({
        next: (result: TagsChartData) => {
          this.periodFrom = new Date(result.From);
          this.periodTo = new Date(result.To);
          this.currentChartDataSubject.next(result);
          this.core.setBusy(false);
        }, error: (error) => {
          this.core.showErrorDetail(error);
          this.core.setBusy(false);
        }
      });
    }
  }
}