import { BaseSvg, TranslateService } from '@ats/ats-platform-dashboard';
import { Selection, format } from 'd3';;
import { DateTime } from 'luxon';
import { TagValueList } from '../domain/entities/tagValueList';
import { TagDataSourceItem } from '../services/tagsDataSourceLoader';

export const TAG_VALUE_TEXT_DEFAULT_FONT_SIZE = 18;
export const TAG_VALUE_TEXT_DEFAULT_SUB_FONT_SIZE = 10;

export class TagValueText extends BaseSvg<TagDataSourceItem> {
    public unit: string;
    public fontSize: number;
    public subFontSize: number;
    public numberOfDecimals: number;
    public dateTimeFormat: string;
    public sublinePrefix: string;
    public showWriteButton: boolean;

    public onClickCallback: Function;

    private formatValue: (n: number | { valueOf(): number }) => string;
    private valueText: Selection<SVGTextElement, unknown, HTMLElement, unknown>;
    private sublineText: Selection<SVGTextElement, unknown, HTMLElement, unknown>;
    private timeText: Selection<SVGTextElement, unknown, HTMLElement, unknown>;
    private writeButton: Selection<SVGGElement, unknown, HTMLElement, unknown>;

    constructor(private translate: TranslateService) {
        super();

        this.left = 10;
        this.top = 10;
        this.right = 10;
        this.bottom = 10;
    }

    public drawSimple(valueText: string, timePeriodText: string) {
        this.draw(valueText, timePeriodText);
    }

    public drawTag(data: TagDataSourceItem) {
        this.draw(this.getValueText(data), this.getTimeStampText(data));
    }

    public draw(valueText: string, timeText: string) {

        this.group.selectAll('text').remove();

        this.timeText = this.group.append('text')
            .attr('font-family', 'sans-serif')
            .attr('font-size', 10)
            .attr('x', this.width - this.right)
            .attr('y', this.height - this.bottom)
            .attr('text-anchor', 'end')
            .attr('dominant-baseline', 'text-top')
            .text(timeText);

        this.valueText = this.group.append('text')
            .attr('font-family', 'sans-serif')
            .attr('font-size', this.fontSize ?? TAG_VALUE_TEXT_DEFAULT_FONT_SIZE)
            .attr('x', this.width - this.right)
            .attr('y', this.top)
            .attr('text-anchor', 'end')
            .attr('dominant-baseline', 'hanging')
            .text(valueText + (this.unit ? ' ' + this.unit : ''));

        this.sublineText = this.group.append('text')
            .attr('font-family', 'sans-serif')
            .attr('font-size', this.subFontSize ?? TAG_VALUE_TEXT_DEFAULT_SUB_FONT_SIZE)
            .attr('x', this.width - this.right)
            .attr('y', this.top + 5 + (this.fontSize ?? TAG_VALUE_TEXT_DEFAULT_FONT_SIZE))
            .attr('text-anchor', 'end')
            .attr('dominant-baseline', 'hanging')
            .attr('opacity', 0)
            .text('---' + (this.unit ? ' ' + this.unit : ''));

        if (this.showWriteButton) {
            this.writeButton = this.group.append('g')
                .attr('transform', 'translate(' + (this.left) + ',' + (this.height - this.bottom - 20) + ')');

            this.writeButton.append('rect').attr('x', 0).attr('y', 0).attr('width', 20).attr('height', 20).attr('fill', 'white').attr('fill-opacity', 0.8).attr('cursor', 'pointer');
            this.writeButton.append('path').attr('fill', 'black').attr('cursor', 'pointer').attr('transform', 'translate(' + (12.0 * 20.0 / 600.0) + ',' + (44.0 * 20.0 / 600.0) + ') scale(' + (20.0 / 600.0) + ')')
                .attr('d', 'M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z');

            this.writeButton.on('click', (e) => {
                e.preventDefault();
                e.cancelBubble = true;

                if (this.onClickCallback)
                    this.onClickCallback();
            });
        }
    }

    public setValue(item: TagDataSourceItem) {
        if (this.valueText)
            this.valueText.text(this.getValueText(item) + (this.unit ? ' ' + this.unit : ''));

        if (this.timeText)
            this.timeText.text(this.getTimeStampText(item));
    }

    public setSublineValue(item: TagDataSourceItem) {
        if (this.sublineText)
            this.sublineText
                .attr('opacity', 1)
                .text((this.sublinePrefix ? this.sublinePrefix + ': ' : '') + this.getValueText(item) + (this.unit ? ' ' + this.unit : ''));
    }

    public getValueText(item: TagDataSourceItem) {
        this.formatValue = format('.' + (this.numberOfDecimals ?? 0) + 'f');

        if ((item == null) || (item.LatestValue == null))
            return '---';

        if (item.Tag.Discriminator == 'Tag' && item.Tag.IsCumulative == true) {
            //  cumulative value
            return this.formatValue(item.LatestValue.CumulativeValue);
        }
        else {
            // tag value translation list
            switch (item.Tag.DataType) {
                case 0:
                    if (item.Tag.TagValueList) {
                        return this.getTagValueTranslation(item.Tag.TagValueList, item.LatestValue.BooleanValue ? 1 : 0);
                    }
                    else {
                        switch (item.LatestValue.BooleanValue) {
                            case true:
                                return this.translate.get('i18n:LABEL.YES');
                            default:
                                return this.translate.get('i18n:LABEL.NO');
                        }
                    }
                case 1:
                case 2:
                case 8:
                case 9:
                    if (item.Tag.TagValueList) {
                        return this.getTagValueTranslation(item.Tag.TagValueList, item.LatestValue.IntegerValue);
                    } else {
                        return this.formatValue(item.LatestValue.IntegerValue);
                    }
                case 3:
                    if (item.Tag.TagValueList) {
                        return this.getTagValueTranslation(item.Tag.TagValueList, item.LatestValue.FloatingPointValue);
                    } else {
                        return this.formatValue(item.LatestValue.FloatingPointValue);
                    }
                case 4:
                    return item.LatestValue.StringValue;
                case 5:
                    return DateTime.fromISO(item.LatestValue.DateTimeValue.toString()).toFormat(this.dateTimeFormat ?? "dd/LL/yyyy");
                case 6:
                    return DateTime.fromISO(item.LatestValue.DateTimeValue.toString()).toFormat(this.dateTimeFormat ?? "HH:mm:ss");
                case 7:
                    return DateTime.fromISO(item.LatestValue.DateTimeValue.toString()).toFormat(this.dateTimeFormat ?? "dd/LL/yyyy HH:mm:ss");
                default:
                    return '---';
            }
        }
    }

    private getTagValueTranslation(tagValueList: TagValueList, value: number): string {
        let textToReturn = "";
        if (tagValueList.TagValueListItems) {
            tagValueList.TagValueListItems.some((element) => {
                if (tagValueList.TagValueListType === 0) {
                    if (element.Maximum === value) {
                        textToReturn = element.DisplayText;
                        return true;
                    }
                    return false;
                } else {
                    if (value !== null && element.Minimum === null && value < element.Maximum) {
                        textToReturn = element.DisplayText;
                        return true;
                    }

                    if (value !== null && value >= element.Minimum && element.Maximum === null) {
                        textToReturn = element.DisplayText;
                        return true;
                    }

                    if (value !== null && value >= element.Minimum && value < element.Maximum) {
                        textToReturn = element.DisplayText;
                        return true;
                    }

                    return false;
                }
            });
        }

        if (textToReturn !== "") {
            return textToReturn;
        } else {
            return this.formatValue(value);
        }
    }

    getTimeStampText(item: TagDataSourceItem): string {
        if (item && item.LatestValue) {
            const timeStampWrapper = DateTime.fromISO(item.LatestValue.TimeStamp.toString());
            return timeStampWrapper.toFormat("dd/LL/yyyy HH:mm:ss.SSS");
        } else {
            return '---';
        }
    }
}