import { Injectable, Inject } from '@angular/core';
import { AbstractHubService, BASE_URL, TranslateService } from '@ats/ats-platform-dashboard';

@Injectable({
 providedIn: 'root',
})
export class TagHubService extends AbstractHubService {

  constructor(@Inject(BASE_URL) public baseUrl: string, translate: TranslateService) {
    super(translate);

    this.init(this.baseUrl, 'tagHub');
  }
}
