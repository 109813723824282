import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DialogService } from "@progress/kendo-angular-dialog";
import { ApiService, CoreService, DataSource, DataSourceConfig, DataSourceComponent, TranslateService, EntitySelectionPopupSettings } from "@ats/ats-platform-dashboard";
import { find } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import { Asset } from "../../domain/entities/asset";

@Component({
    selector: 'ats-smarttool-datasource-ev-charging-sessions',
    templateUrl: './datasource-ev-charging-sessions.component.html',
    styleUrls: ['./datasource-ev-charging-sessions.component.scss']
})
export class DataSourceEvChargingSessionsComponent implements DataSourceComponent {

    dataSource: DataSource;

    public assetSelectionPopupSettings: EntitySelectionPopupSettings;
    public assetFormatter = (task: Asset) => task?.Path;

    public formGroup: FormGroup;

    constructor(private core: CoreService, public translate: TranslateService, private api: ApiService, private dialogService: DialogService) { 
        this.assetSelectionPopupSettings = {
            title: translate.get('i18n:SELECT_ASSET'),
            entitySet: 'Assets',
            includes: 'AssetType',
            sort: [{ field: 'Path', dir: 'asc' }],
            multiSelect: false,
            selectionRequired: false,
            columns: [
              { field: 'Path', title: translate.get('i18n:ASSET.PATH'), filterable: true },
              { field: 'AssetType.Name', title: translate.get('i18n:ASSET.TYPE'), filterable: true }
            ]
        }
    }

    setDataSource(dataSource: DataSource, formGroup: FormGroup): FormGroup {
        this.dataSource = dataSource;

        if (formGroup) {
          this.formGroup = formGroup;
        } else {
          const configs = this.dataSource?.Configs;
          const assetIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'assetId');

          this.formGroup = new FormGroup({
              assetId: new FormControl(assetIdConfig ? assetIdConfig.Value : null),
          });
        }

        return this.formGroup;  
    }

    getDataSource(): DataSource {
        const dataSource = new DataSource();  
        dataSource.Configs = [];

        const configs = this.dataSource?.Configs;
        const assetIdConfig = find(configs, (x: DataSourceConfig) => x.Name == 'assetId');

        dataSource.Configs.push({ Id: assetIdConfig ? assetIdConfig.Id : uuidv4(), Name: 'assetId', Value: this.formGroup.get('assetId').value, Encrypt: false, Encrypted: false, Data: null });
        
        return dataSource;
    }

    markAllAsTouched() {
        this.formGroup.get('assetId').markAllAsTouched();
    }

    isValid(): boolean {  
        return this.formGroup.valid;
    }
}