<form class="k-form" [formGroup]="formGroup">
    <fieldset class="k-form-fieldset">
        <kendo-formfield>
            <kendo-label [for]="aggregationWindow"
                text="{{ 'i18n:DATASOURCE.TAGS_HISTORY.AGGREGATION_WINDOW' | translate }}"></kendo-label>
            <kendo-combobox #aggregationWindow formControlName="aggregationWindow" [data]="timeIntervals"
                [textField]="'Display'" [valueField]="'Key'" [valuePrimitive]="true" [clearButton]="false">
            </kendo-combobox>
            <kendo-formerror *ngIf="formGroup.controls['aggregationWindow'].errors?.['required']">{{
                'i18n:MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label [for]="maxPoints" text="{{ 'i18n:DATASOURCE.TAGS_HISTORY.MAX_POINTS' | translate }}">
            </kendo-label>
            <kendo-numerictextbox #maxPoints formControlName="maxPoints" [min]="1" [max]="1000000" [decimals]="0"
                [format]="'n0'"></kendo-numerictextbox>
            <kendo-formerror *ngIf="formGroup.controls['maxPoints'].errors?.['required']">{{
                'i18n:MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
            <kendo-formerror *ngIf="formGroup.controls['maxPoints'].errors?.['minError']">{{
                translate.get('i18n:MESSAGE.VALIDATION_MINIMUM', formGroup.controls.columnCount.errors.minError) }}
            </kendo-formerror>
            <kendo-formerror *ngIf="formGroup.controls['maxPoints'].errors?.['maxError']">{{
                translate.get('i18n:MESSAGE.VALIDATION_MAXIMUM', formGroup.controls.columnCount.errors.maxError) }}
            </kendo-formerror>
        </kendo-formfield>
    </fieldset>
    <fieldset class="k-form-fieldset">
        <legend class="k-form-legend">{{ 'i18n:DATASOURCE.TAGS' | translate }}</legend>     
            <kendo-grid [data]="items" [height]="410" [selectable]="{ mode: 'multiple', drag: true }" kendoGridSelectBy="TagId" [(selectedKeys)]="selectedItemKeys" 
                (cellClick)="cellClick($event)" (cellClose)="cellClose($event)">
                <ng-template kendoGridToolbarTemplate>
                    <button kendoButton look="outline" (click)="openTagSelectionPopup()">{{ 'i18n:DATASOURCE.TAGS.ADD' | translate }}</button>
                    <button kendoButton look="outline" [disabled]="selectedItemKeys.length == 0" (click)="removeItems()">{{ 'i18n:DATASOURCE.TAGS.REMOVE' | translate }}</button>
                </ng-template>

                <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.TAGS_HISTORY.TAG')" [field]="'TagId'">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
                        {{ getTagDescription(dataItem) }}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.TAGS_HISTORY.MEASUREMENT_UNIT')" [field]="'MeasurementUnitId'" [style]="{ 'padding': '0px' }">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex>
                        <div style="display: flex; width: 100%; height: 100%; align-items: center;">
                            <div style="flex: 1; padding: 8px 4px 8px 12px;">{{ getMeasurementUnitDescription(dataItem) }}</div>
                            <div style="flex: 0;">
                                <button kendoButton look="flat" icon="edit" (click)="openMeasurementSelectionPopup(dataItem)"></button>
                            </div>
                            <div style="flex: 0;" *ngIf="dataItem.MeasurementUnitId && dataItem.MeasurementUnitId.length">
                                <button kendoButton look="flat" icon="close" (click)="clearMeasurementUnit(dataItem)"></button>
                            </div>
                            <kendo-formerror *ngIf="hasGridErr()">{{gridErr}}</kendo-formerror>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.TAGS_HISTORY.AGGREGATE')" [field]="'Aggregate'" [editor]="'boolean'">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                        <div>{{ dataItem.Aggregate ? translate.get('i18n:LABEL.YES') : translate.get('i18n:LABEL.NO') }}</div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.TAGS_HISTORY.AGGREGATE_FILL_OPTION')" [field]="'AggregateFillOption'">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                        <div>{{ getFillOptionDescription(dataItem) }}</div>
                    </ng-template>
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
                        <kendo-combobox [data]="fillOptions" [formControl]="formGroup.get('AggregateFillOption')" [textField]="'Display'" [valueField]="'Name'" [valuePrimitive]="true"></kendo-combobox>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column [title]="translate.get('i18n:DATASOURCE.TAGS_HISTORY.DEFAULT_COLOR')" [field]="'DefaultColor'">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                        <div class="selected-color" [ngStyle]="{ 'background-color': dataItem.DefaultColor }"></div>
                    </ng-template>
                    <ng-template kendoGridEditTemplate let-dataItem="dataItem" let-column="column" let-formGroup="formGroup">
                        <kendo-colorpicker activeView="palette" [format]="'hex'" [formControl]="formGroup.get('DefaultColor')"></kendo-colorpicker>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>       
    </fieldset>
</form>