import { Component, Inject, Injector } from '@angular/core';
import { keyBy, flatten } from "lodash-es";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiQueryOptions, ApiService, CoreService, DashboardWidgetDocument, DataSource, DataSourceType, DATASOURCETYPES, EntitySelectionPopupSettings, TranslateService, WidgetConfigComponent } from '@ats/ats-platform-dashboard';
import { LineType } from '../../domain/enums/lineType';
import { LINETYPEKEYS } from '../../domain/enums/lineTypeKeys';
import { BaseTag } from '../../domain/entities/baseTag';
import { forkJoin, Observable, tap } from 'rxjs';
import { CellClickEvent, CellCloseEvent } from '@progress/kendo-angular-grid';

@Component({
  selector: 'ats-smart-tool-tag-line-chart-widget-config',
  templateUrl: './tag-line-chart-widget-config.component.html',
  styleUrls: ['./tag-line-chart-widget-config.component.scss']
})
export class TagLineChartWidgetConfigComponent implements WidgetConfigComponent {

  public dataSourceSelectionPopupSettings: EntitySelectionPopupSettings;
  public dataSourceFormatter = (dataSource: DataSource) => dataSource ? dataSource.Folder.Path + ' / ' + dataSource.Name : null;

  public formGroup: FormGroup;

  public items: TagLineConfigItem[];
  public itemsLoading: boolean = false;

  private initialLoad: boolean;

  private dataSourceTypes: { [type: string]: DataSourceType };
  public lineTypes: { Key: LineType, Display: string }[];

  constructor(public core: CoreService, public translate: TranslateService, private api: ApiService, @Inject(DATASOURCETYPES) dataSourceTypes: DataSourceType[][], private injector: Injector) {

    this.dataSourceTypes = keyBy(flatten(dataSourceTypes), (type: DataSourceType) => type.name);
    this.lineTypes = core.translateObjectArray(LINETYPEKEYS, 'Display');

    this.formGroup = new FormGroup({
      title: new FormControl(''),
      dataSourceId: new FormControl(null, Validators.required),
      yAxisMeasurementUnit: new FormControl(false),
      marginLeft: new FormControl(60),
      marginTop: new FormControl(10),
      marginRight: new FormControl(10),
      marginBottom: new FormControl(40),
      verticalGrid: new FormControl(false),
      horizontalGrid: new FormControl(false),
      widgetType: new FormControl(translate.get('TAG_LINE_CHART_WIDGET'))
    });

    this.dataSourceSelectionPopupSettings = {
      title: translate.get('i18n:SELECT_DATASOURCE'),
      entitySet: 'DataSources',
      filter: {
        logic: 'or',
        filters: [
          { field: 'Type', operator: 'eq', value: 'tagsHistory' }
        ]
      },
      includes: 'Configs,Folder',
      sort: [{ field: 'Folder.Path', dir: 'asc' }, { field: 'Name', dir: 'asc' }],
      multiSelect: false,
      selectionRequired: true,
      columns: [{ field: 'Folder.Path', title: this.translate.get('i18n:FOLDER.PATH'), filterable: true }, { field: 'Name', title: translate.get('i18n:DATASOURCE.NAME'), filterable: true }],
      info: translate.get('i18n:WIDGET.SUPPORTED_DATATYPES') + ': ' + translate.get('i18n:DATASOURCE.TAGS_HISTORY')
    };
  }

  public onDataSourceChange = (dataSource: DataSource) => {
    if (dataSource) {
      const dataSourceType: DataSourceType = this.dataSourceTypes[dataSource.Type];
      if (this.initialLoad) {
        this.initialLoad = false;
        return;
      }

      if (dataSourceType) {
        const loader = this.injector.get(dataSourceType.loader);
        loader.loadItems(dataSource).subscribe({
          next: (items: { id: string; name: string }[]) => {
            this.items = [];
            if (items && items.length) {
              const observables: Observable<any>[] = [];
              this.itemsLoading = true;

              items.forEach((item: { id: string; name: string }) => {
                observables.push(this.api.getSingleResult<BaseTag>('BaseTags', item.id, new ApiQueryOptions({ include: 'Asset.AssetType,MeasurementUnit.Measurement' })).pipe(tap((tag: BaseTag) => {
                  this.items.push(new TagLineConfigItem(tag.Id, tag));
                })));
              });

              if (observables && observables.length) {
                forkJoin(observables).subscribe({ next: () => this.itemsLoading = false });
              } else {
                this.itemsLoading = false;
              }
            }

          }, error: (err: any) => {
            this.core.showErrorDetail(err);
          }
        });
      } else {
        this.itemsLoading = false;
        this.items = [];
      }
    } else {
      this.itemsLoading = false;
      this.items = [];
    }
  };

  public isValid(): boolean {
    this.formGroup.markAllAsTouched();
    return this.formGroup.valid;
  }

  public getConfig(): any {
    this.items.map(x => x.tag = null);
    return {
      title: this.formGroup.get('title').value,
      dataSourceId: this.formGroup.get('dataSourceId').value,
      yAxisMeasurementUnit: this.formGroup.get('yAxisMeasurementUnit').value,
      marginLeft: this.formGroup.get('marginLeft').value,
      marginTop: this.formGroup.get('marginTop').value,
      marginRight: this.formGroup.get('marginRight').value,
      marginBottom: this.formGroup.get('marginBottom').value,
      verticalGrid: this.formGroup.get('verticalGrid').value,
      horizontalGrid: this.formGroup.get('horizontalGrid').value,
      tagConfig: JSON.stringify(this.items)
    };
  }

  public getDocuments(): DashboardWidgetDocument[] {
    return null;
  }

  public setData(config: any, documents: DashboardWidgetDocument[]) {
    this.initialLoad = true;
    this.formGroup.get('title').setValue(config?.title ?? '');
    this.formGroup.get('dataSourceId').setValue(config?.dataSourceId);
    this.formGroup.get('yAxisMeasurementUnit').setValue(config?.yAxisMeasurementUnit);
    this.formGroup.get('marginLeft').setValue(config?.marginLeft ?? 60);
    this.formGroup.get('marginTop').setValue(config?.marginTop ?? 10);
    this.formGroup.get('marginRight').setValue(config?.marginRight ?? 10);
    this.formGroup.get('marginBottom').setValue(config?.marginBottom ?? 40);
    this.formGroup.get('verticalGrid').setValue(config?.verticalGrid);
    this.formGroup.get('horizontalGrid').setValue(config?.horizontalGrid);

    if (config && config.tagConfig) {
      this.items = JSON.parse(config.tagConfig);
    } else {
      this.items = [];
      this.initialLoad = false;
    }

    if (config?.dataSourceId) {
      this.api.getSingleResult<DataSource>('DataSources', config?.dataSourceId, new ApiQueryOptions({ include: 'Configs' })).subscribe({
        next: (dataSource: DataSource) => {
          const dataSourceType: DataSourceType = this.dataSourceTypes[dataSource.Type];

          if (dataSourceType) {
            this.itemsLoading = true;
            const loader = this.injector.get(dataSourceType.loader);
            loader.loadItems(dataSource).subscribe({
              next: (dataSourceItems: { id: string; name: string }[]) => {
                if (this.items && this.items.length) {
                  if (dataSourceItems && dataSourceItems.length) {
                    this.itemsLoading = true;

                    const lengthItems = this.items.length;
                    for (let i = lengthItems - 1; i > -1; i--) {
                      if (!dataSourceItems.find(dataSourceItem => this.items[i].tagId === dataSourceItem.id)) {
                        this.items.splice(i, 1);
                      }
                    }

                    dataSourceItems.forEach((dataSourceItem) => {
                      if (!this.items.find(x => dataSourceItem.id === x.tagId)) {
                        var newItem = new TagLineConfigItem(dataSourceItem.id, null);
                        newItem.lineType = LineType.Linear;
                        newItem.showRange = false;
                        this.items.push(newItem);
                      }
                    });

                    const observables: Observable<any>[] = [];

                    this.items.forEach((item: TagLineConfigItem) => {
                      observables.push(this.api.getSingleResult<BaseTag>('BaseTags', item.tagId, new ApiQueryOptions({ include: 'Asset.AssetType' })).pipe(tap((tag: BaseTag) => {
                        item.tag = tag;
                      })));
                    });

                    if (observables && observables.length) {
                      forkJoin(observables).subscribe({ next: (value: any[]) => this.itemsLoading = false });
                    } else {
                      this.itemsLoading = false;
                    }
                  }
                }
                this.itemsLoading = false;
              }, error: (err: any) => {
                this.core.showErrorDetail(err);
              }
            });
          } else {
            this.itemsLoading = false;
            this.items = [];
          }
        }
      });
    } else {
      this.itemsLoading = false;
      this.items = [];
    }
  }

  public cellClick(event: CellClickEvent) {
    if (!event.isEdited) {
      event.sender.editCell(event.rowIndex, event.columnIndex, this.createFormGroup(event.dataItem));
    }
  }

  public createFormGroup(dataItem: TagLineConfigItem) {
    return new FormGroup({
      showRange: new FormControl(dataItem.showRange, Validators.required),
      lineType: new FormControl(dataItem.lineType, Validators.required),
      minimumValue: new FormControl(dataItem.minimumValue),
      maximumValue: new FormControl(dataItem.maximumValue),
    });
  }

  public cellClose(event: CellCloseEvent) {
    const formControl: FormControl = event.formGroup.get(event.column.field);
    if (formControl) {
      if (!formControl.valid) {
        event.preventDefault();
      } else if (formControl.dirty) {
        event.dataItem[event.column.field] = formControl.value;
      }
    }
  }

  getTagDescription(item: TagLineConfigItem): string {
    if (item.tag) {
      return item.tag.Asset.Path + ' - ' + item.tag.Name;
    }
    else if (item.tagId) {
      return this.translate.get('i18n:LABEL.LOADING');
    } else {
      return '';
    }
  }

  getLineTypeDescription(item: TagLineConfigItem): string {
    return this.lineTypes.filter(x => x.Key === item.lineType)[0].Display;
  }
}


export class TagLineConfigItem {
  tagId: string;
  tag: BaseTag;
  showRange: boolean;
  lineType: LineType;
  minimumValue: number;
  maximumValue: number;

  /**
   *
   */
  constructor(tagId: string, tag: BaseTag) {
    this.tagId = tagId;
    this.tag = tag;
    this.showRange = false;
    this.lineType = LineType.Linear;
  }
}
