import { TagChart } from "../entities/tagChart";

export class downloadReportCommand {
  ReportDefinitionId: string;
  ReportId?: string;

  Name?: string;

  UseDashboardDateTime: boolean;
  From: Date;
  To: Date;

  ParameterValues: { [key: string]: any };
  DataSources: { [key: string]: string };
  Chart: TagChart;
}