<div class="ev-charging-sessions-widget">
  <kendo-loader *ngIf="isLoading" [type]="'converging-spinner'" [themeColor]="'primary'" [size]="'large'">
  </kendo-loader>

  <kendo-grid *ngIf="!error && !isLoading && widgetInstance.widgetData?.sessions" [data]="gridData" [filterable]="'menu'" [sortable]="sortSettings" [sort]="sort" [filter]="filter" (filterChange)="filterChange($event)" [columnMenu]="menuSettings">
    <kendo-grid-column field="StartDateTime" title="{{ 'EV_CHARGING_SESSION.START_DATE_TIME' | translate }}" filter="date" format="dd/MM/yyyy HH:mm:ss" [width]="200"></kendo-grid-column>
    <kendo-grid-column field="ChargingEndTime" title="{{ 'EV_CHARGING_SESSION.CHARGING_END_DATE_TIME' | translate }}" filter="date" format="dd/MM/yyyy HH:mm:ss" [width]="200" [hidden]="true" />
    <kendo-grid-column field="EndDateTime" title="{{ 'EV_CHARGING_SESSION.END_DATE_TIME' | translate }}" filter="date" format="dd/MM/yyyy HH:mm:ss" [width]="200" [hidden]="true" />
    <kendo-grid-column field="ChargedEnergy" title="{{ 'EV_CHARGING_SESSION.CHARGED_ENERGY' | translate }}" filter="numeric" format="{0:n2}" [width]="150" />
    <kendo-grid-column field="RequestedEnergy" title="{{ 'EV_CHARGING_SESSION.REQUESTED_ENERGY' | translate }}" filter="numeric" format="{0:n2}" [width]="150" [hidden]="true"/>
    <kendo-grid-column field="EstimatedDepartureTime" title="{{ 'EV_CHARGING_SESSION.ESTIMATED_DEPARTURE' | translate }}" filter="date" [width]="200" format="dd/MM/yyyy HH:mm:ss" />
    <kendo-grid-column field="Asset.Path" title="{{ 'EV_CHARGING_SESSION.EVSE' | translate }}" />
    <kendo-grid-column field="EVUser.Name" title="{{ 'EV_CHARGING_SESSION.EV_USER' | translate }}" [hidden]="true" />
    <kendo-grid-column field="Status" title="{{ 'EV_CHARGING_SESSION.STATUS' | translate }}" [width]="150" [hidden]="true">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ getStatusDescription(dataItem.Status) }}
      </ng-template>
      <ng-template kendoGridFilterMenuTemplate let-filter="filter" let-filterService="filterService">
        <ul class="ev-charging-status">
          <li #itemElement *ngFor="let item of evChargingSessionStatuses; let i = index" [ngClass]="{ 'k-selected': isStatusSelected(item) }" (click)="onStatusSelectionChange(item, filter, filterService)">
            <input type="checkbox" #notification kendoCheckBox [checked]="isStatusSelected(item)" />
            <kendo-label class="k-checkbox-label" [for]="notification" [text]="item.Display"></kendo-label>
          </li>
        </ul>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column [width]="70" *ngIf="widgetInstance?.widgetConfig?.chartId">
      <ng-template kendoGridCellTemplate let-dataItem>
        <kendo-button icon="chart-line" (click)="onChartClick(dataItem)"></kendo-button>
      </ng-template>
    </kendo-grid-command-column>

    <ng-template kendoGridDetailTemplate let-dataItem>
      <div class="k-form">
        <div class="k-form-fieldset">
          <div class="k-form-legend">{{ 'i18n:COMMON.GENERAL' | translate }}</div>
          <div class="k-form-field">
            <kendo-label [for]="asset" text="{{ 'EV_CHARGING_SESSION.ASSET' | translate }}"></kendo-label>
            <kendo-textbox #asset [readonly]="true" [value]="dataItem.Asset?.Path"></kendo-textbox>
          </div>
          <div class="k-form-field">
            <kendo-label [for]="startDateTime" text="{{ 'EV_CHARGING_SESSION.START_DATE_TIME' | translate }}"></kendo-label>
            <kendo-dateinput #startDateTime [readonly]="true" [value]="dataItem.StartDateTime" format="dd/MM/yyyy HH:mm:ss"></kendo-dateinput>
          </div>
          <div class="k-form-field">
            <kendo-label [for]="endDateTime" text="{{ 'EV_CHARGING_SESSION.END_DATE_TIME' | translate }}"></kendo-label>
            <kendo-dateinput #endDateTime [readonly]="true" [value]="dataItem.EndDateTime" format="dd/MM/yyyy HH:mm:ss"></kendo-dateinput>
          </div>
          <div class="k-form-field">
            <kendo-label [for]="chargingEndTime" text="{{ 'EV_CHARGING_SESSION.CHARGING_END_DATE_TIME' | translate }}"></kendo-label>
            <kendo-dateinput #endDateTime [readonly]="true" [value]="dataItem.ChargingEndTime" format="dd/MM/yyyy HH:mm:ss"></kendo-dateinput>
          </div>
          <div class="k-form-field">
            <kendo-label [for]="status" text="{{ 'EV_CHARGING_SESSION.STATUS' | translate }}"></kendo-label>
            <kendo-textbox #status [readonly]="true" [value]="getStatusDescription(dataItem.Status)"></kendo-textbox>
          </div>
          <div class="k-form-field">
            <kendo-label [for]="lastUpdated" text="{{ 'EV_CHARGING_SESSION.LAST_UPDATED' | translate }}"></kendo-label>
            <kendo-dateinput #lastUpdated [readonly]="true" [value]="dataItem.LastUpdated" format="dd/MM/yyyy HH:mm:ss"></kendo-dateinput>
          </div>
        </div>
        <div class="k-form-fieldset">
          <div class="k-form-legend">{{ 'i18n:EV_CHARGING_SESSION.EV_USER_DETAILS' | translate }}</div>
          <div class="k-form-field">
            <kendo-label [for]="evUser" text="{{ 'EV_CHARGING_SESSION.EV_USER' | translate }}"></kendo-label>
            <kendo-textbox #evUser [readonly]="true" [value]="dataItem.EVUser?.Name"></kendo-textbox>
          </div>
          <div class="k-form-field">
            <kendo-label [for]="sessionId" text="{{ 'EV_CHARGING_SESSION.CPO_SESSION_ID' | translate }}"></kendo-label>
            <kendo-textbox #sessionId [readonly]="true" [value]="dataItem.CPOSessionId"></kendo-textbox>
          </div>
          <div class="k-form-field">
            <kendo-label [for]="authId" text="{{ 'EV_CHARGING_SESSION.CPO_AUTH_ID' | translate }}"></kendo-label>
            <kendo-textbox #authid [readonly]="true" [value]="dataItem.CPOAuthId"></kendo-textbox>
          </div>
          <div class="k-form-field">
            <kendo-label [for]="estimatedDepartureTime" text="{{ 'EV_CHARGING_SESSION.ESTIMATED_DEPARTURE' | translate }}"></kendo-label>
            <kendo-dateinput #estimatedDepartureTime [readonly]="true" [value]="dataItem.EstimatedDepartureTime" format="dd/MM/yyyy HH:mm:ss"></kendo-dateinput>
          </div>
          <div class="k-form-field">
            <kendo-label [for]="requestedEnergy" text="{{ 'EV_CHARGING_SESSION.REQUESTED_ENERGY' | translate }}"></kendo-label>
            <kendo-numerictextbox #requestedEnergy [readonly]="true" [value]="dataItem.RequestedEnergy" format="n2" [spinners]="false"></kendo-numerictextbox>
          </div>
        </div>
        <div class="k-form-fieldset">
          <div class="k-form-legend">{{ 'i18n:EV_CHARGING_SESSION.EV_USER_DETAILS' | translate }}</div>
          <div class="k-form-field">
            <kendo-label [for]="chargedEnergy" text="{{ 'EV_CHARGING_SESSION.CHARGED_ENERGY' | translate }}"></kendo-label>
            <kendo-numerictextbox #chargedEnergy [readonly]="true" [value]="dataItem.ChargedEnergy" format="n2" [spinners]="false"></kendo-numerictextbox>
          </div>
          <div class="k-form-field">
            <kendo-label [for]="pauseDuration" text="{{ 'EV_CHARGING_SESSION.PAUSE_DURATION' | translate }}"></kendo-label>
            <kendo-numerictextbox #pauseDuration [readonly]="true" [value]="dataItem.PauseDuration" format="n0" [spinners]="false"></kendo-numerictextbox>
          </div>
          <div class="k-form-field">
            <kendo-label [for]="minCurrent" text="{{ 'EV_CHARGING_SESSION.MIN_CURRENT' | translate }}"></kendo-label>
            <kendo-numerictextbox #requestedEnergy [readonly]="true" [value]="dataItem.MinCurrent" format="n2" [spinners]="false"></kendo-numerictextbox>
          </div>
          <div class="k-form-field">
            <kendo-label [for]="maxCurrent" text="{{ 'EV_CHARGING_SESSION.MAX_CURRENT' | translate }}"></kendo-label>
            <kendo-numerictextbox #maxCurrent [readonly]="true" [value]="dataItem.MaxCurrent" format="n2" [spinners]="false"></kendo-numerictextbox>
          </div>
          <div class="k-form-field">
            <kendo-label [for]="useL1" text="{{ 'EV_CHARGING_SESSION.USE_L1' | translate }}"></kendo-label>
            <kendo-switch #useL1 [readonly]="true" [checked]="dataItem.UseL1"></kendo-switch>
          </div>
          <div class="k-form-field">
            <kendo-label [for]="useL2" text="{{ 'EV_CHARGING_SESSION.USE_L2' | translate }}"></kendo-label>
            <kendo-switch #useL2 [readonly]="true" [checked]="dataItem.UseL2"></kendo-switch>
          </div>
          <div class="k-form-field">
            <kendo-label [for]="useL3" text="{{ 'EV_CHARGING_SESSION.USE_L3' | translate }}"></kendo-label>
            <kendo-switch #useL3 [readonly]="true" [checked]="dataItem.UseL3"></kendo-switch>
          </div>
        </div>
      </div>
    </ng-template>
  </kendo-grid>
  
  <span *ngIf="error && !isLoading" class="widget-error">{{error}}</span>
</div>