<ats-platform-entity-lookup-textbox *ngIf="isAssetFilterShow" [entityId]="chartService.currentChart.AssetId" (entityIdChange)="onAssetIdChanged($event)" [settings]="assetSelectionPopupSettings" [formatter]="assetFormatter"></ats-platform-entity-lookup-textbox>
<span *ngIf="isAssetFilterShow" class="k-appbar-separator"></span>
<ats-platform-date-time-period-box [config]="chartService.currentChart" (configChange)="onConfigChanged($event)" [fromField]="'From'" [toField]="'To'" [periodField]="'Period'" 
    [(actualFrom)]="chartService.periodFrom" [(actualTo)]="chartService.periodTo">
</ats-platform-date-time-period-box>
<button kendoButton icon="reload" (click)="onReloadClicked()"></button>
<span *ngIf="authService.canCreate('TagChart') || authService.canUpdate('TagChart') || authService.canDelete('TagChart') && isWebPage()" class="k-appbar-separator"></span>
<button *ngIf="authService.canCreate('TagChart') && isWebPage()" kendoButton icon="file-add" (click)="onChartAddClicked()"></button>
<button *ngIf="authService.canUpdate('TagChart') && isWebPage()" kendoButton icon="gear" (click)="onChartEditClicked()"></button>
<button *ngIf="authService.canUpdate('TagChart') && isWebPage()" kendoButton icon="download" (click)="onChartDownloadClicked()"></button>
<button *ngIf="authService.canUpdate('TagChart') && isWebPage()" kendoButton icon="pdf" (click)="onChartToReportClicked()"></button>
<kendo-dropdownbutton *ngIf="authService.canUpdate('TagChart') && isWebPage()" [data]="saveButtons" icon="save"></kendo-dropdownbutton>
<button *ngIf="authService.canDelete('TagChart') && isWebPage()" kendoButton icon="delete" (click)="onChartDeleteClicked()"></button>