<form class="k-form" [formGroup]="formGroup">
    <fieldset class="k-form-fieldset">
        <kendo-formfield>
            <kendo-label [for]="assetId" text="{{ 'i18n:DATASOURCE.ASSET_TAGS.ASSET' | translate }}"></kendo-label>
            <ats-platform-entity-lookup-textbox #assetId formControlName="assetId" [settings]="assetSelectionPopupSettings" [formatter]="assetFormatter"></ats-platform-entity-lookup-textbox>
            <kendo-formerror *ngIf="formGroup.controls['assetId'].errors?.['required']">{{ 'MESSAGE.FIELD_IS_REQUIRED' | translate }}</kendo-formerror>
        </kendo-formfield>
    </fieldset>
</form>

