import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Type, ViewChild } from "@angular/core";
import { HubConnection } from "@aspnet/signalr";
import { DashboardService, DataSourceInstance, WidgetComponent, WidgetInstance, SvgContainerComponent, WidgetUpdater, ItemValueChangedEventArgs } from "@ats/ats-platform-dashboard";
import { Subscription } from "rxjs";

import { find } from "lodash-es";
import { select } from 'd3';
import { LineChart } from "../../svg/line-chart";
import { TagLineChartData } from "../../domain/dataModels/lineChartData";

@Component({
    selector: 'ats-smart-tool-tag-line-chart-widget',
    templateUrl: './tag-line-chart-widget.component.html',
    styleUrls: ['./tag-line-chart-widget.component.scss']
})
export class TagLineChartWidgetComponent implements WidgetComponent, OnInit, AfterViewInit, OnDestroy {

    //https://codepen.io/vkbansal/pen/QjJXOe      -- Stepline chart
    //https://www.d3-graph-gallery.com/graph/area_lineDot.html  -- Area line

    @Input() hubConnection: HubConnection;

    public widgetInstance: WidgetInstance;
    public isLoading = false;
    public error: string;

    private widgetConfigSubscription: Subscription;
    private widgetUpdaters: WidgetUpdater[];
    private svg: SVGSVGElement;
    private div: HTMLDivElement;
    private drawer: LineChart;

    public svgContainerType: Type<SvgContainerComponent> = SvgContainerComponent;

    @ViewChild('svgContainer', { static: false }) svgContainer: ElementRef;

    constructor(private dashboardService: DashboardService) {
    }

    public setWidgetInstance(widgetInstance: WidgetInstance) {
        this.widgetInstance = widgetInstance;
    }

    ngOnInit() {
        this.widgetConfigSubscription = this.widgetInstance.changed.subscribe({
            next: (instance: WidgetInstance) => {
                this.updateData(instance);
            }
        });
    }

    ngAfterViewInit() {
        setTimeout(() => { this.updateData(this.widgetInstance); }, 0);
    }

    ngOnDestroy() {
        if (this.widgetConfigSubscription) {
            this.widgetConfigSubscription.unsubscribe();
            this.widgetConfigSubscription = null;
        }

        if (this.widgetUpdaters) {
            this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
            this.widgetUpdaters = null;
        }
    }

    protected updateData(instance: WidgetInstance) {
        instance.widgetData = {
            title: instance.widgetConfig.title
        };

        if (!this.svgContainer)
            return;

        this.div = this.svgContainer.nativeElement;
        const svgs = this.div.getElementsByTagName('svg');
        this.svg = svgs && svgs.length ? svgs.item(0) : null;
        if (!this.svg)
            return;

        const d3svg = select(this.svg);

        d3svg.selectAll('g').remove();

        const g = d3svg.append('g');

        this.drawer = new LineChart();
        this.drawer.init(g);

        if (this.widgetUpdaters) {
            this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
        }

        const dataSourceInstance = find(this.dashboardService.dataSourceInstances, (dataSourceInstance: DataSourceInstance) => dataSourceInstance.id == instance.widgetConfig.dataSourceId);

        if (dataSourceInstance) {
            if (!instance.widgetData.title)
                instance.widgetData.title = dataSourceInstance.name;

            this.widgetUpdaters = [];
            this.widgetUpdaters.push(
                new WidgetUpdater(dataSourceInstance, this.svgContainer.nativeElement,
                    (dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement) => {
                        this.isLoading = dataSourceInstance?.result?.isLoading;

                        if (!this.widgetInstance.widgetData.title)
                            this.widgetInstance.widgetData.title = dataSourceInstance.name;

                        if (dataSourceInstance.result && dataSourceInstance.result.data) {
                            d3svg.attr('viewBox', '0 0 ' + (this.div.getBoundingClientRect().width) + ' ' + (this.div.getBoundingClientRect().height));
                            var parsedResult: TagLineChartData[] = <TagLineChartData[]>(dataSourceInstance.result.data);

                            this.drawer.from = this.dashboardService.periodFrom;
                            this.drawer.to = this.dashboardService.periodTo;
                            this.drawer.bottom = instance.widgetConfig.marginBottom;
                            this.drawer.top = instance.widgetConfig.marginTop;
                            this.drawer.left = instance.widgetConfig.marginLeft;
                            this.drawer.right = instance.widgetConfig.marginRight;
                            this.drawer.width = this.div.getBoundingClientRect().width;
                            this.drawer.height = this.div.getBoundingClientRect().height;
                            this.drawer.showHorizontalGrid = instance.widgetConfig.horizontalGrid;
                            this.drawer.showVerticalGrid = instance.widgetConfig.verticalGrid;
                            this.drawer.showYAxisMeasurementUnit = instance.widgetConfig.yAxisMeasurementUnit;
                            this.drawer.tagConfig = JSON.parse(instance.widgetConfig.tagConfig);
                            this.drawer.draw(parsedResult);
                        } else {
                            g.selectAll('g').remove();
                        }
                    },
                    (dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement, event: ItemValueChangedEventArgs) => {
                        //No need to change if data from item changed
                    }));
        }
    }

    public cancel(e: any) {
        e.preventDefault();
        e.cancelBubble = true;
    }
}