import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Type, ViewChild } from "@angular/core";
import { HubConnection } from "@aspnet/signalr";
import { DashboardService, DataSourceInstance, TranslateService, WidgetComponent, WidgetInstance, SvgContainerComponent, WidgetUpdater, DataSourceResult, CoreService } from "@ats/ats-platform-dashboard";
import { Subscription } from "rxjs";
import { select, max } from 'd3';
import { find, findIndex } from "lodash-es";
import { BuildingEnergyData } from "../../domain/dataModels/buildingEnergyData"
import { DayFilter } from "../../domain/enums/dayFilter";
import { BuildingEnergyValueType, BUILDING_ENERGY_VALUE_TYPES } from "../../domain/enums/building-energy-value-types";
import { VertStackedBarChart, StackedBarChartData } from "../../svg/vert-stacked-bar-chart";

@Component({
  selector: 'ats-smart-tool-building-energy-vert-stacked-bar-chart-widget',
  templateUrl: './building-energy-vert-stacked-bar-chart-widget.component.html',
  styleUrls: ['./building-energy-vert-stacked-bar-chart-widget.component.scss']
})
export class BuildingEnergyVertStackedBarChartWidgetComponent implements WidgetComponent, OnInit, AfterViewInit, OnDestroy {
  @Input() hubConnection: HubConnection;

  public valueTypes: { Key: BuildingEnergyValueType, Display: string }[];

  public widgetInstance: WidgetInstance;
  public isLoading = false;
  public error: string;
  public buildingEnergyData: BuildingEnergyData;

  private widgetConfigSubscription: Subscription;
  private widgetUpdaters: WidgetUpdater[];
  private svg: SVGSVGElement;

  private drawer: VertStackedBarChart;

  @ViewChild('svgContainer', { static: false }) svgContainer: ElementRef;

  constructor(private dashboardService: DashboardService, private translate: TranslateService, public core: CoreService) {
    this.valueTypes = core.translateObjectArray(BUILDING_ENERGY_VALUE_TYPES, 'Display');
  }

  public setWidgetInstance(widgetInstance: WidgetInstance) {
    this.widgetInstance = widgetInstance;
  }

  ngOnInit() {
    this.widgetConfigSubscription = this.widgetInstance.changed.subscribe({
      next: (instance: WidgetInstance) => {
        this.updateData(instance);
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => { this.updateData(this.widgetInstance); }, 0);
  }

  ngOnDestroy() {
    if (this.widgetConfigSubscription) {
      this.widgetConfigSubscription.unsubscribe();
      this.widgetConfigSubscription = null;
    }

    if (this.widgetUpdaters) {
      this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
      this.widgetUpdaters = null;
    }
  }

  protected updateData(instance: WidgetInstance) {
    instance.widgetData = {
      title: instance.widgetConfig.title
    }

    if (!this.svgContainer)
      return;

    const div: HTMLDivElement = this.svgContainer.nativeElement;
    const svgs = div.getElementsByTagName('svg');
    this.svg = svgs && svgs.length ? svgs.item(0) : null;
    if (!this.svg)
      return;

    // init drawer
    this.drawer = new VertStackedBarChart();
    this.drawer.left = instance.widgetConfig.leftMargin;
    this.drawer.right = instance.widgetConfig.rightMargin;
    this.drawer.top = instance.widgetConfig.topMargin;
    this.drawer.bottom = instance.widgetConfig.bottomMargin;
    this.drawer.numberOfDecimals = instance.widgetConfig.numberOfDecimals;
    this.drawer.hideLabels = instance.widgetConfig.hideLabels;
    this.drawer.valueTypeLabel = find(this.valueTypes, (t: any) => t.Key == this.widgetInstance.widgetConfig.valueType).Display;

    if (this.widgetUpdaters) {
      this.widgetUpdaters.forEach((widgetUpdater: WidgetUpdater) => widgetUpdater.destroy());
      this.widgetUpdaters = null;
    }

    const dataSourceInstance = find(this.dashboardService.dataSourceInstances, (dataSourceInstance: DataSourceInstance) => dataSourceInstance.id == instance.widgetConfig.dataSourceId);
    if (dataSourceInstance) {
      this.widgetUpdaters = [];
      this.widgetUpdaters.push(new WidgetUpdater(dataSourceInstance, div, (dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement) => this.update(dataSourceInstance, nativeElement), null));
    }
  }

  protected update(dataSourceInstance: DataSourceInstance, nativeElement: HTMLElement) {
    this.isLoading = dataSourceInstance?.result.isLoading;

    if (dataSourceInstance.result && dataSourceInstance.result.data) {
      const d3svg = select(this.svg);
      d3svg.attr('viewBox', '0 0 ' + (nativeElement.getBoundingClientRect().width) + ' ' + (nativeElement.getBoundingClientRect().height));

      this.buildingEnergyData = <BuildingEnergyData>(dataSourceInstance.result.data);
      const buildingIndex = findIndex(this.buildingEnergyData.BuildingData, (buildingData) => { return (buildingData as any).Asset.Id == this.widgetInstance.widgetConfig.buildingIndex });
      const dayFilterDayData: any[] = this.buildingEnergyData.BuildingData[buildingIndex].DayData.filter((b: any) => { return this.filterByDate(this.widgetInstance.widgetConfig.dayFilterKey, new Date(b.TimeStamp)) });

      const data: StackedBarChartData = new StackedBarChartData();
      data.categories = this.buildingEnergyData.Categories;
      data.data = dayFilterDayData.map(d => {
        const item = { key: d.TimeStamp.toString(), values: {} };
        this.buildingEnergyData.Categories.forEach((category: string) => item.values[category] = this.convertValue(d, this.widgetInstance.widgetConfig.valueType, this.buildingEnergyData.BuildingData[buildingIndex].FloorSurface, category));
        return item;
      })
      data.maxValue = max(this.buildingEnergyData.BuildingData[buildingIndex].DayData.map(b => this.convertValue(b, this.widgetInstance.widgetConfig.valueType, this.buildingEnergyData.BuildingData[buildingIndex].FloorSurface)));

      // update value
      if (this.drawer) {
        const d3svg = select(this.svg);
        d3svg.selectAll('g').remove();
        d3svg.attr('viewBox', '0 0 ' + nativeElement.getBoundingClientRect().width + ' ' + nativeElement.getBoundingClientRect().height);

        this.drawer.group = d3svg.append('g');
        this.drawer.unit = this.getUoM(this.widgetInstance.widgetConfig.valueType);
        this.drawer.width = nativeElement.getBoundingClientRect().width;
        this.drawer.height = nativeElement.getBoundingClientRect().height;
        this.drawer.draw(data);
      }
    }
    //generate title if not present, update happens twice first time title gets filled in with null as unit, then with correct unit value. this is the reason widgetconfig.title does not get updated
    if (!this.widgetInstance.widgetConfig.title) {
      this.widgetInstance.widgetData.title = this.translate.get('i18n:BUILDING_ENERGY_VERT_STACKED_BAR_CHART_WIDGET') + ' (' + this.drawer.unit + ')';
    }
  }

  public cancel(e: any) {
    e.preventDefault();
    e.cancelBubble = true;
  }

  protected filterByDate(dayFilterKey: any, d: Date) {
    switch (dayFilterKey) {
      case DayFilter.WeekDays:
        return d.getDay() != 0 && d.getDay() != 6;
      case DayFilter.WeekendDays:
        return d.getDay() == 0 || d.getDay() == 6;
      default:
        return true;
    }
  }
  protected convertValue(data: any, type: BuildingEnergyValueType, floorSurface: number, category: string = null): number {
    switch (type) {
      case BuildingEnergyValueType.AbsoluteEnergy:
        return data[(category ? category + "$$" : "") + "energy"];
      case BuildingEnergyValueType.AbsoluteEmission:
        return data[(category ? category + "$$" : "") + "emission"];
      case BuildingEnergyValueType.AbsolutePrimaryEnergy:
        return data[(category ? category + "$$" : "") + "primaryEnergy"];
      case BuildingEnergyValueType.RelativeEnergyToFloorSurface:
        if (floorSurface) {
          return data[(category ? category + "$$" : "") + "energy"] / floorSurface;
        } else {
          return 0;
        }
      case BuildingEnergyValueType.RelativeEmissionToFloorSurface:
        if (floorSurface) {
          return data[(category ? category + "$$" : "") + "emission"] / floorSurface;
        } else {
          return 0;
        }
      case BuildingEnergyValueType.RelativePrimaryEnergyToFloorSurface:
        if (floorSurface) {
          return data[(category ? category + "$$" : "") + "primaryEnergy"] / floorSurface;
        } else {
          return 0;
        }
      case BuildingEnergyValueType.RelativeEnergyToDegreeDays:
        if (data["degreedays"]) {
          return data[(category ? category + "$$" : "") + "energy"] / data["degreedays"];
        } else {
          return 0;
        }
      case BuildingEnergyValueType.RelativeEmissionToFloorSurface:
        if (data["degreedays"]) {
          return data[(category ? category + "$$" : "") + "emission"] / data["degreedays"];
        } else {
          return 0;
        }
      case BuildingEnergyValueType.RelativePrimaryEnergyToFloorSurface:
        if (data["degreedays"]) {
          return data[(category ? category + "$$" : "") + "primaryEnergy"] / data["degreedays"];
        } else {
          return 0;
        }
      case BuildingEnergyValueType.RelativeEnergyToFloorSurfaceAndDegreeDays:
        if (floorSurface && data["degreedays"]) {
          return data[(category ? category + "$$" : "") + "energy"] / floorSurface / data["degreedays"];
        } else {
          return 0;
        }
      case BuildingEnergyValueType.RelativeEmissionToFloorSurfaceAndDegreeDays:
        if (floorSurface && data["degreedays"]) {
          return data[(category ? category + "$$" : "") + "emission"] / floorSurface / data["degreedays"];
        } else {
          return 0;
        }
      case BuildingEnergyValueType.RelativePrimaryEnergyToFloorSurfaceAndDegreeDays:
        if (floorSurface && data["degreedays"]) {
          return data[(category ? category + "$$" : "") + "primaryEnergy"] / floorSurface / data["degreedays"];
        } else {
          return 0;
        }
      default:
        return 0;
    }
  }

  protected getUoM(type: BuildingEnergyValueType): string {
    switch (type) {
      case BuildingEnergyValueType.AbsoluteEnergy:
      case BuildingEnergyValueType.AbsolutePrimaryEnergy:
        return this.buildingEnergyData.EnergyUnit?.Symbol;
      case BuildingEnergyValueType.AbsoluteEmission:
        return this.buildingEnergyData.EmissionUnit?.Symbol;
      case BuildingEnergyValueType.RelativeEnergyToFloorSurface:
      case BuildingEnergyValueType.RelativePrimaryEnergyToFloorSurface:
        return this.buildingEnergyData.EnergyUnit?.Symbol + ' / ' + this.buildingEnergyData.SurfaceUnit?.Symbol;
      case BuildingEnergyValueType.RelativeEmissionToFloorSurface:
        return this.buildingEnergyData.EmissionUnit?.Symbol + ' / ' + this.buildingEnergyData.SurfaceUnit?.Symbol;
      case BuildingEnergyValueType.RelativeEnergyToDegreeDays:
      case BuildingEnergyValueType.RelativePrimaryEnergyToDegreeDays:
        return this.buildingEnergyData.EnergyUnit?.Symbol + ' / degreedays';
      case BuildingEnergyValueType.RelativeEmissionToDegreeDays:
        return this.buildingEnergyData.EmissionUnit?.Symbol + ' / degreedays';
      case BuildingEnergyValueType.RelativeEnergyToFloorSurfaceAndDegreeDays:
      case BuildingEnergyValueType.RelativePrimaryEnergyToFloorSurfaceAndDegreeDays:
        return this.buildingEnergyData.EnergyUnit?.Symbol + ' / ' + this.buildingEnergyData.SurfaceUnit?.Symbol + ' / degreedays';
      case BuildingEnergyValueType.RelativeEmissionToFloorSurfaceAndDegreeDays:
        return this.buildingEnergyData.EmissionUnit?.Symbol + ' / ' + this.buildingEnergyData.SurfaceUnit?.Symbol + ' / degreedays';
      default:
        return '';
    }
  }
}
