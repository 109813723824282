import { Component } from "@angular/core";
import { clone, remove } from 'lodash-es';
import { Asset } from "../../domain/entities/asset";
import { ApiService, CoreService, DataSourceConfig, EntitySelectionPopupSettings, SystemEventFilterComponent, TranslateService } from "@ats/ats-platform-dashboard";

@Component({
  selector: 'ats-platform-system-event-asset-filter',
  templateUrl: './system-event-asset-filter.component.html',
  styleUrls: ['./system-event-asset-filter.component.scss']
})
export class SystemEventAssetFilterComponent implements SystemEventFilterComponent {

  public assetSelectionPopupSettings: EntitySelectionPopupSettings;
  public assetFormatter = (task: Asset) => task?.Path;
  public selectedAssetIds: string[] = [];
  public assets: Asset[] = [];
  public assetsLoading: boolean = false;

  constructor(private core: CoreService, public translate: TranslateService, private api: ApiService) {
    this.assetSelectionPopupSettings = {
      title: translate.get('i18n:SELECT_ASSET'),
      entitySet: 'UserAssets',
      singleEntitySet: 'Assets',
      includes: 'AssetType',
      sort: [{ field: 'Path', dir: 'asc' }],
      multiSelect: false,
      selectionRequired: false,
      columns: [
        { field: 'Path', title: translate.get('i18n:ASSET.PATH'), filterable: true },
        { field: 'AssetType.Name', title: translate.get('i18n:ASSET.TYPE'), filterable: true }
      ],
      width: '300px',
      placeholder: translate.get('i18n:SELECT_ASSET')
    };
  }

  setFilterConfig(configData: Asset[]): void {
    this.assets = configData ?? [];
  }

  getFilterConfig(dataSourceConfig: DataSourceConfig): void {
    dataSourceConfig.Data = this.assets;
    dataSourceConfig.Value = JSON.stringify(this.assets.map(x => x.Id));
  }

  public addAssets() {
    this.core.showEntitySelectionPopup<Asset>(this.assetSelectionPopupSettings).subscribe({
      next: (selection: Asset[]) => {
        if (selection && selection.length) {
          selection.forEach((asset: Asset) => {
            if (!this.assets.some((x: Asset) => x.Id === asset.Id)) {
              this.assets.push(asset);
            }
          });
        };
      }
    });
  }

  public removeAssets() {
    if (this.assets && this.assets.length && this.selectedAssetIds && this.selectedAssetIds.length) {
      const ids: string[] = clone(this.selectedAssetIds);
      ids.forEach((id: string) => {
        remove(this.assets, (item: Asset) => item.Id == id);
      });
      this.selectedAssetIds = [];
    }
  }

  isValid(): boolean {
    return true;
  }
}